import React, { useState } from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import HostSliderSection2 from '../assets/HostSliderSection2.png';
import HostSliderSection21 from '../assets/HostSliderSection2.1.png';
import HostSliderSection22 from '../assets/HostSliderSection2.2.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "Block",color:"#fff" }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "Block",color:"#fff" }}
        onClick={onClick}
      />
    );
  }
  


export default function ActiveSlider() {

  const [oneShow, setOneShow] = useState(false);
  const [twoShow, setTwoShow] = useState(false);
  const [threeShow, setThreeShow] = useState(false);
  const [fourShow, setFourShow] = useState(false);
  const [fiveShow, setFiveShow] = useState(false);
  const [sixShow, setSixShow] = useState(false);


        var  settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        centerMode: true,
        centerPadding: '100px',
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '40px',
              slidesToShow: 3
            }
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '40px',
              slidesToShow: 1
            }
          }
        ]
        };  
      return (
        <div>
           <div className='row'>
                    <div className="container p-0 w-75 host-slider font-face-gb">
          <Slider {...settings}>
          

            <div>
              <div className="slider-sec text-start acs-slider shadow-md rounded-4 py-5">
                <LazyLoadImage
                  alt="HostSliderSection2"
                  src={HostSliderSection2}
                  className="logoban w-100 rounded-4"
                />
                  {/* <img className="logoban w-100 rounded-4" src={require('../assets/HostSliderSection2.png')} ></img> */}
                  
              </div>
            </div>

            <div>
              <div className="slider-sec acs-slider text-startshadow-md rounded-4 py-5">
                  <LazyLoadImage
                  alt="HostSliderSection21"
                  src={HostSliderSection21}
                  className="logoban w-100 rounded-4"
                />
                  {/* <img className="logoban w-100 rounded-4" src={require('../assets/HostSliderSection2.1.png')} ></img> */}
                  
              </div>
            </div>

            <div>
              <div className="slider-sec acs-slider text-start shadow-md rounded-4 py-5">
                  <LazyLoadImage
                  alt="HostSliderSection22"
                  src={HostSliderSection22}
                  className="logoban w-100 rounded-4"
                />

                  {/* <img className="logoban w-100 rounded-4" src={require('../assets/HostSliderSection2.2.png')} ></img> */}
                  
              </div>
            </div>

            <div>
              <div className="slider-sec acs-slider text-start shadow-md rounded-4 py-5">
                 <LazyLoadImage
                  alt="HostSliderSection2"
                  src={HostSliderSection2}
                  className="logoban w-100 rounded-4"
                />
                  {/* <img className="logoban w-100 rounded-4" src={require('../assets/HostSliderSection2.png')} ></img> */}
                  
              </div>
            </div>

            <div>
              <div className="slider-sec acs-slider text-start shadow-md rounded-4 py-5">
                  <LazyLoadImage
                  alt="HostSliderSection21"
                  src={HostSliderSection21}
                  className="logoban w-100 rounded-4"
                />

                  {/* <img className="logoban w-100 rounded-4" src={require('../assets/HostSliderSection2.1.png')} ></img> */}
                  
              </div>
            </div>

            <div>
              <div className="slider-sec acs-slider text-start shadow-md rounded-4 py-5">
                <LazyLoadImage
                  alt="HostSliderSection22"
                  src={HostSliderSection22}
                  className="logoban w-100 rounded-4"
                />
                  {/* <img className="logoban w-100 rounded-4" src={require('../assets/HostSliderSection2.2.png')} ></img> */}
                  
              </div>
            </div>

            

            
          </Slider>
        </div>
                    </div>
        </div>
      );
    }
  
import { React, useState, useEffect } from 'react'
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { Cart } from '../components/Cart';
import { CartProvider, useCart } from "react-use-cart";
// import StripeForm from './CheckoutForm';
import { toast, ToastContainer } from 'react-toastify';
import { MyCart } from '../components/Cart';
import secureLocalStorage from 'react-secure-storage';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { Home_Images } from '../components/images';
import Moment from 'moment';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { CountryDropdown } from 'react-country-region-selector';
import CountryData from '../components/CountryData'
import AddressLoader from '../components/AddressLoader';

export default function Checkout() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [fname, setfName] = useState('');
  const [lname, setlName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setAddress2] = useState('');
  const [timer, setTimer] = useState(null);
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [addressLoader, setAddressLoader] = useState(false);
  const [postcode, setPostcode] = useState('');
  const [country, setCountry] = useState('');
  const [mobile, SetMobile] = useState('');
  const { cartTotal } = useCart();
  const [userObject, setUserObject] = useState("");
  const [oneShow, setOneShow] = useState(false);
  const [isLoggedin, setisLoggedin] = useState(false);
  const [password, setPassword] = useState('');
  const [consent3, setConsent3] = useState(false);

  const [receiveUpdates, setReceiveUpdates] = useState(0);
  const [isReceiveUpdatesChecked, setIsReceiveUpdatesChecked] = useState(false);
  const [signedUpForStoriesAndInspiration, setSignedUpForStoriesAndInspiration] = useState(0);
  const [isSignUpForStoriesAndInspirationChecked, setIsSignUpForStoriesAndInspirationChecked] = useState(false);


  let userDetail = secureLocalStorage.getItem("LogObject");
  let userObjectData = secureLocalStorage.getItem("userObject");
  let APIURL = process.env.REACT_APP_API_URL;

function handleSingUPForStoriesAndInspiration (){
  setIsSignUpForStoriesAndInspirationChecked(!isSignUpForStoriesAndInspirationChecked);
  if (isSignUpForStoriesAndInspirationChecked) {
    setSignedUpForStoriesAndInspiration(0);
  } else {
    setSignedUpForStoriesAndInspiration(1)
  }
}

function handleReceiveUpdates (){
  setIsReceiveUpdatesChecked(!isReceiveUpdatesChecked);
  if (isReceiveUpdatesChecked) {
    setReceiveUpdates(0);
  } else {
    setReceiveUpdates(1);
  }
}



  async function ValidateUser() {
    if (email.trim().length === 0 && password.trim().length === 0) {
      toast.error(`Please Enter Your valid Email and Password`);
    } else if (password.trim().length === 0) {
      toast.error(`Please Enter password`);
    } else if (email.trim().length === 0) {
      toast.error(`Please Enter Your Email`);
    } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
      toast.error(`Please Enter Your valid Email Address`);
    } else {
      try {
        const response = await toast.promise(axios.post(
          `${APIURL}/auth/local`,
          {
            userEmail: email,
            userPassword: password
          },
          {
            withCredentials: true,
            credentials: "same-origin"
          }
        ),
          {
            pending: 'Please wait...',
          }
        );
        console.log(response);

        if (response.data.status === true && response.data.error === null) {
          toast("Successfully Authenticated", { type: "success" });
          secureLocalStorage.setItem("LogObject", { userID: response.data.content.userID, email: email, loggedin: true })
          // navigate('/checkout');
          window.location.reload()
        } else {
          toast("Something went wrong While authenticating", { type: "error" });
        }
      } catch (error) {
        console.log(error);
        if (error.response.status === 403) {
          toast.error(`${error.response.data.content.message}`);
        } else {
          toast.error(`${error.message}`);
        }
      }
    }

  }

  const handleCountryChange = (val) => {
    setCountry(val);
    const _countryData = CountryData.find(Data => Data.name.trim().toLocaleLowerCase() === val.trim().toLocaleLowerCase());
    const callingCode = _countryData?.code;
    SetMobileNumber(`+${callingCode} `);
  }

  const updateAddress = (enteredAddress) => {
    const splitAddress = enteredAddress.split(',')
    setAddress(splitAddress[0] + " " + splitAddress[1])
    setAddress2(splitAddress[2])

  }
  const updatePostcode = (enteredPostcode) => {
    setPostcode(enteredPostcode);
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      setAddressLoader(true)
      axios.request({
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://ws.postcoder.com/pcw/PCWW5-FVLAY-K6FGB-KSTYM/autocomplete/${country}/${enteredPostcode}?format=json`,
      })
        .then((response) => {
          const predictions = response?.data?.predictions || [];
          let allSuggests = [];
          predictions.map(address => {
            console.log("address", address)
            if (address.complete) {
              const splitAddress = address.prediction.split(',')
              setAddress(splitAddress[0] + " " + splitAddress[1])
              setAddress2(splitAddress[2])
              allSuggests.push(address.prediction)
              console.log("allSuggests", allSuggests)
              setAddressSuggestions(allSuggests);
            }
          }
          )
          setAddressLoader(false);
        })
        .catch((error) => {
          console.log(error);
          setAddressLoader(false);
        });
    }, 500)
    setTimer(newTimer);
  };

  function SetMobileNumber(val) {
    SetMobile(val);
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    if ((userDetail) && secureLocalStorage.getItem("userObject") == null) {
      axios.get(`${APIURL}/users/${userDetail.userID}`, {
        withCredentials: true,
        credentials: "same-origin"
      }).then(res => {
        secureLocalStorage.setItem('userObject', res.data.content[0])
        let temp = res.data.content[0];
        setUserObject(temp)
        setEmail(temp.userEmail);
        setEmail(temp.userEmail);
        setfName(temp.userFirstName);
        setlName(temp.userSurname);
        setAddress(temp.userAddressLine1);
        setAddress2(temp.userAddressLine2);
        SetMobileNumber(temp.userPhoneNumber);
        setPostcode(temp.userAddressPostcode);
        setCountry(temp.userAddressCountryLocaleID == 77 ? 'United Kingdom' : '');
        setisLoggedin(true);
      });
    } else if (secureLocalStorage.getItem("userObject") != null) {
      setEmail(userObjectData.userEmail);
      setfName(userObjectData.userFirstName);
      setlName(userObjectData.userSurname);
      setAddress(userObjectData.userAddressLine1);
      setAddress2(userObjectData.userAddressLine2);
      setPostcode(userObjectData.userAddressPostcode);
      setCountry(userObjectData.userAddressCountryLocaleID == 77 ? 'United Kingdom' : '');
      SetMobileNumber(userObjectData.userPhoneNumber);
      setisLoggedin(false);
      // setOneShow(true);
    } else {
      setEmail('');
      setfName('');
      setlName('');
      setAddress('');
      setAddress2('');
      setPostcode('');
      setCountry('');
      SetMobileNumber('');
      setisLoggedin(false);
      setOneShow(true);
    }
  }, [])

  const checkConsents = () => {
    if (!consent3) {
      toast("Please confirm you are over 18 and agree with our terms and conditions", { type: "error" });
    }
  }


  return (
    <div className='bg-checkout'>

      <div className='container'>

        <div className="row flex-reverse py-2">
          <CartProvider>
            <Cart
              name={fname + ' ' + lname}
              email={email}
              city={city}
              address={address}
              postcode={postcode}
              country={country}
              amount={cartTotal}
              userPhoneNumber={mobile}
              consent3={consent3}
              receiveUpdates={receiveUpdates}
              signedUpForStoriesAndInspiration={signedUpForStoriesAndInspiration}
            />
          </CartProvider>

          <div className="col-md-6 order-md-1">
            <Link to={'/'} className="" >
              <img src={Home_Images.Logo} style={{ width: '40%' }} />
            </Link>

            <div className='color-grey pt-3 text-muted'>
              <p className='btn text-muted d-flex' onClick={() => navigate(-1)}>&#9666; Go back</p>Need support?  <i className='ps-3 fa fa-envelope'></i> <a className='text-decoration-none text-muted' href='mailto:hello@playfundwin.com'> <span>hello@playfundwin.com</span></a>
            </div >
            <p className='color-grey text-muted'> Returning customer? <Link to={'/login'} className='text-decoration-none branding3' > <span>Click here to login</span></Link></p  >
            <h3>Customer Information</h3>

            <form className="needs-validation" noValidate>

              <div className="row pe-3">

                <div className="mb-3 form-label-group">
                  {/* <label htmlFor="email">Email *</label> */}
                  <input type="email" className="form-control py-3 px-4 rounded-pill" id="email" placeholder="Email *" onChange={event => setEmail(event.target.value)} value={email} />
                  <div className="invalid-feedback">
                    Please enter a valid email address for shipping updates.
                  </div>
                </div>
                <div className="col-md-6 mb-3 form-label-group">
                  {/* <label htmlFor="firstName">First name *</label> */}
                  <input type="text" className="form-control py-3 px-4 rounded-pill" id="firstName" placeholder='First name *' onChange={event => setfName(event.target.value)} value={fname} />
                  <div className="invalid-feedback">
                    Valid first name is required.
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  {/* <label htmlFor="lastName">Last name *</label> */}
                  <input type="text" className="form-control py-3 px-4 rounded-pill" id="lastName" placeholder='Last name *' required onChange={event => setlName(event.target.value)} value={lname} />
                  <div className="invalid-feedback">
                    Valid last name is required.
                  </div>
                </div>


                <div className="col-md-6 mb-3 country-picker">
                  {/* <label htmlFor="country">Country*</label> */}

                  {/* <select className="custom-select d-block w-100 py-3 px-4 form-control rounded-pill"
                   onChange={event => setCountry(event.target.value)} id="country" required>
                  <option value='Zambia'>Zambia</option>
                  <option value='Zimbabwe'>Zimbabwe</option>
                  </select> */}

                  <CountryDropdown
                    classes="custom-select d-block w-100 py-3 px-4 form-control rounded-pill"
                    value={country}
                    onChange={(val) => handleCountryChange(val)}

                  ></CountryDropdown>

                  <div className="invalid-feedback">
                    Country is required.
                  </div>
                </div>

                <div className="col-md-6 mb-3">
                  {/* <label htmlFor="postcode">Postcode*</label> */}
                  <input type="text" className="form-control py-3 px-4 rounded-pill" placeholder='Postcode*' id="postcode" onChange={event => updatePostcode(event.target.value)} value={postcode} />
                  <div className="invalid-feedback">
                    postcode is required.
                  </div>
                </div>



                {/* {
                  addressSuggestions.length ?
                    <div className="mb-3">
                      <label className='position-relative w-100'>
                        {addressLoader ?
                          <AddressLoader /> : <></>}
                        <select className='address-suggestion-picker form-control w-100' onChange={(e) => updateAddress(e.target.value)}>
                          {addressSuggestions.map((address, index) => <option key={index} value={address}>{address}</option>)}
                        </select>

                      </label>
                    </div> : <></>
                } */}

                <div className="col-md-6 mb-3">
                  <label className='position-relative w-100'>
                    {addressLoader ?
                      <AddressLoader /> : <></>}
                    <input type="text" className="form-control py-3 px-4 rounded-pill" placeholder='Street Address*' id="address" onChange={event => setAddress(event.target.value)} value={address} />
                    <div className="invalid-feedback">
                      address is required.
                    </div>
                  </label>
                </div>
                <div className="col-md-6 mb-3">
                  <label className='position-relative w-100'>
                    {addressLoader ?
                      <AddressLoader /> : <></>}
                    <input type="text" className="form-control py-3 px-4 rounded-pill" placeholder='Town / City' id="city" onChange={event => setAddress2(event.target.value)} value={city} />
                    <div className="invalid-feedback">
                      City is required.
                    </div>
                  </label>
                </div>


                <div className="mb-3">
                  {/* <label htmlFor="phone">Phone Number (optional) </label> */}
                  {/* <input type="text"
                   className="form-control py-3 px-4 rounded-pill" 
                   id="phone" placeholder='Phone Number (optional)'
                   onChange={event => setPhone(event.target.value)} /> */}

                  <PhoneInput
                    placeholder="Phone Number"
                    defaultCountry="GB"
                    countryCallingCodeEditable={false}
                    international
                    value={`${mobile}`}
                    name="mobile_number"
                    className="form-control rounded-pill align-self-center w-100 px-4 py-3 bg-white phone-number-input"
                    onChange={(e) => SetMobileNumber(`${e}`)}
                  />
                </div>

              </div>

              {/* <br /> */}
              <div className='d-none'>
                <h3>Payment Information</h3>
                <p className='text-muted fs-6'>We accept debit cards only. All transactions are secure and encrypted, card information is never stored on our servers.</p>

                <div className='border border-1'>
                  <div className='border border-1 m-0 p-3'>
                    <input id="debit" name="paymentMethod" type="radio" className="custom-control-input" required /><span className='p-2 px-3'>Card Payment</span>
                  </div>

                  <div className='border border-0 p-3 bg-f2'>
                    <div className="d-block my-3">
                      <p className='text-muted p-0 fs-6'>Cards accepted: Visa, MasterCard, Maestro and more.</p>
                    </div>
                    <div className="row">

                      <div className="col-md-12 mb-3">
                        <label htmlFor="cc-number">Card number</label>
                        <input type="text" className="form-control" id="cc-number" placeholder="•••• •••• •••• ••••" />
                        <div className="invalid-feedback">
                          Credit card number is required
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label htmlFor="cc-expiration">Expiration</label>
                        <input type="text" className="form-control" id="cc-expiration" placeholder='MM / YY' />
                        <div className="invalid-feedback">
                          Expiration date required
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="cc-expiration">CVV</label>
                        <input type="text" className="form-control" id="cc-cvv" placeholder='CVV' />
                        <div className="invalid-feedback">
                          Security code required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <p className='text-muted py-3'>Your personal data will be used to process your order, support your experience throughout this
                website, and for other purposes described in our privacy policy.</p>

              <div className="custom-control custom-checkbox d-flex align-items-start py-1">
                <input type="checkbox" checked={consent3} onChange={() => setConsent3(!consent3)} className="mt-2 custom-control-input" id="tandc-1" />
                <label className="custom-control-label px-3  text-muted" htmlFor="tandc-1">Please click to confirm you are over 18 and agree with our terms and conditions *</label>
              </div>

              <div className="custom-control custom-checkbox d-flex align-items-start py-1">
                <input type="checkbox" className="mt-2 custom-control-input" id="tandc-2" 
                  checked={isSignUpForStoriesAndInspirationChecked}  onChange={handleSingUPForStoriesAndInspiration} />
                <label className="custom-control-label px-3  text-muted" htmlFor="tandc-2">Sign up to find out about fundraising good news stories, inspiration and other draws which you may wish to support. </label>
              </div>

              <div className="custom-control custom-checkbox d-flex align-items-start py-1">
                <input type="checkbox" className="mt-2 custom-control-input" id="tandc-3" 
                  checked={isReceiveUpdatesChecked}  onChange={handleReceiveUpdates}
                />
                <label className="custom-control-label px-3  text-muted" htmlFor="tandc-3">I am happy to receive information from my draw organiser.</label>
              </div>

              <p className='text-muted fst-italic'>If you change your mind, you can unsubscribe at any time. </p>

              <div className='d-flex justify-content-center pb-4'>
              </div>
            </form>

            <div className='d-flex justify-content-center bg-f2 p-2 m-2 '>
              <p className='text-muted mb-0'>We Respect Your Privacy & Information </p>
            </div>


          </div>
        </div>
        <div className="row">
          <footer className='px-2 col-md-6'>
            <div className="footer-second-inner">
              <div className='row'>
                <div className="col"><img className="w-100 px-2 py-2" src={require('../assets/images/Chartered_Institite_of_Fundraising-1-300x106.jpg')} decoding="async" /></div>
                <div className="col"><img className="w-100 px-2 py-2" src={require('../assets/images/logo_4-300x72.png')} decoding="async" /></div>
                <div className="col"><img className="w-100 px-2 py-2" src={require('../assets/images/begambleawareorg_black_png-300x39.png')} decoding="async" /></div>
              </div>

              <div className=' p-lg-2 d-flex justify-content-center'>
                <div className='px-2'><Link to={'/legal'} className='text-muted text-decoration-none '>legal</Link></div>
                <div className='px-2'><Link to={'/prize-draw-terms-conditions'} className='text-muted text-decoration-none '>Player Terms & Conditions</Link></div>
                <div className='px-2'><Link to={'/privacy-policy'} className='text-muted text-decoration-none '>Privacy Policy</Link></div>
              </div>
              <div className='py-2 d-flex justify-content-center'><p className='text-muted text-center'> © {Moment().format('YYYY')} Play Fund Win All rights reserved</p></div>
            </div>
          </footer>
          <div className='col-md-6 bg-f2' ></div>
        </div>
      </div>
      {/* 
      <Modal show={oneShow} size="lg"  centered>
        <Modal.Header >
        </Modal.Header>
        <Modal.Body className='bg-f2 rounded-5'>
            <div className="container h-100 bg-f2 ">
              <div className="d-flex align-items-center justify-content-center h-100 py-5">
                <div className="d-flex flex-column col-lg-6 col-md-6 col-12">
                  <p className='fs-3'>Please Login Before Checkout</p>
                  <Link to={"/find-a-draw"} className="border-0 rounded-pill btn pfw-button-red secbutton py-2 px-5 mt-4 mb-2 font-face-gb-semibold" style={{lineHeight: 2}}>Play as a Guest</Link> 
                  <label className='mt-4' htmlFor="username ">Email</label>
                  <input type="text" onChange={(event) => setEmail(event.target.value)} name="email" placeholder="Email" className='rounded-pill align-self-center w-100 px-3 p-3 bg-white border-0' />

                  <label className='mt-2' htmlFor="password ">Password</label>
                  <input type="password" onChange={(event) => setPassword(event.target.value)} placeholder='*********' name="password" className='rounded-pill align-self-center w-100 px-3 p-3 bg-white border-0' />

                  <button onClick={ValidateUser} className=" mt-4 btn pfw-button-red rounded-pill align-self-center w-100 px-3 p-2 fs-5 fw-bold" type="button" name="button">Login</button>

                  <div className='d-flex align-items-center justify-content-center pt-5 w-100'>
                    <Link to={'/user-registration'} className='branding3  fw-bold'>Register</Link><span className='px-2 fw-bold'> | </span><Link to={'/forget-password'} className='branding3  fw-bold'>Lost Password</Link>
                  </div>
                </div>
              </div>
            </div>
        </Modal.Body>
      </Modal>
*/}

      <ToastContainer />
    </div>
  )
}

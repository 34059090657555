import React, { useState } from 'react'
import Siteheader from '../components/header'
import SiteFooter from '../components/footer'
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';

export default function UserEditPassword() {
  const [Currentpass, setCurrentpass] = useState('');
  const [password, setPassword] = useState('');
  const [Confirmpassword, setConfirmpassword] = useState('');

  async function ValidateUser() {
    if(Currentpass.trim().length === 0 && password.trim().length === 0){
      toast.error('Please Enter Password and New Password')
    }else if(Currentpass.trim().length === 0 ){
      toast.error('Please Enter Current Password ')
    }else if(password.trim().length === 0){
      toast.error('Please Enter New Password')
    }else if(password != Confirmpassword){
      toast.error('Invalid Confirm Password')
    }else{
      toast.success('Validation Success Waiting for api');
    }
   
  }
  return (
    <>
    <Helmet>‍
      <title>Edit Password | Play Fund Win</title>‍
      <meta name="description" content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities." />
    </Helmet>
          <Siteheader />
      <section className="h-100 w-100 bg-f2 container px-lg-5 py-5 position-relative bg-before-after">
        <div className="container h-100 p-lg-5 ">
            <div className="find-draw-form text-center">
                <h2 className='branding1 display-4 font-face-gb-semibold'>New Password</h2>
            </div>
          <div className="d-flex align-items-center justify-content-center h-50 p-lg-0 ">
            <div className="d-flex flex-column p-xl-5 col-lg-6 col-md-6 col-12">
              
              <input type="text" onChange={(event) => setCurrentpass(event.target.value)}  placeholder="Current Password" className='rounded-pill align-self-center w-100 px-3 p-3 bg-white border-0' />

              <input type="password" onChange={(event) => setPassword(event.target.value)} placeholder="New Password"  className='mt-3 rounded-pill align-self-center w-100 px-3 p-3 bg-white border-0' />

              <input type="password" onChange={(event) => setConfirmpassword(event.target.value)}placeholder="Confirm New Password" className='mt-3 rounded-pill align-self-center w-100 px-3 p-3 bg-white border-0' />

              <button onClick={ValidateUser} className=" mt-4 pfw-button-green rounded-pill align-self-center w-25 px-3 p-2 fs-5 fw-bold" type="button" name="button">Save</button>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
      <SiteFooter />
    </>
  )
}

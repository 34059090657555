import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import { BlogData } from '../Data/Blog-Data';
import { BlogDataSession } from '../components/Cart';
import { Link, useNavigate } from "react-router-dom";
import moment from 'moment';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getAllPosts } from '../Data/actionMethods';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="custom-arrow next-arrow"
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="custom-arrow prev-arrow"
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

export default function PostSlider(props) {

  const navigate = useNavigate();
  const [blogData, setBlogData] = useState([]);

  const decodeHTMLEntities = (input) => {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent.replace(/&#9;/g, ' ').replace(/\s+/g, ' ').trim();
  };

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  }

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  }

  useEffect(() => {
    getAllPosts().then((res) => {
      setBlogData(res.data.content ? res.data.content : []);
    });
  }, []);

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplaySpeed: 2500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          arrows: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          dots: false,
          arrows: false,
        }
      },

    ]
  }
  return (
    <div>
      <div className="post-slider">
        <Slider {...settings}>
          {blogData.slice(0, 10).map((p) => {
            let featuredImage;
            try {
              featuredImage = JSON.parse(p.postMeta).featuredImage;
            } catch (error) {
              featuredImage = "";//require('../assets/images/placeholder.png');              
            }
            return (
              <div key={p.postID}>
                <div className="font-face-gb mb-5 m-3">
                  <div
                    className={props.border ? `bg-white h-100` : 'bg-branding1 h-100'}
                    style={{ borderRadius: 10 }}
                  >
                    <Link
                      to={{
                        pathname: `/blog/${p.postSlug}`
                      }}
                      onClick={() => {
                        navigate(`/blog/${p.postSlug}`);
                      }}
                    >
                      <LazyLoadImage
                        src={featuredImage}
                        alt={p.postName}
                        effect="blur" // You can add an optional loading effect
                        className="post-image background-image"
                        style={{
                          borderTopLeftRadius: 10,
                          borderTopRightRadius: 10,

                          width: '100%',
                          // Add any additional styling here if needed
                        }}
                      />
                    </Link>
                    <div className='text-end position-absolute d-flex' style={{ right: '28px', marginTop: '-13px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                      {p.taxonomy.filter(t => t.taxanomyType === 'blog-category').map((t, index, array) => (
                        <React.Fragment key={t.taxanomySlug}>
                          <Link
                            to={{
                              pathname: `/category/${t.taxanomySlug}`
                            }}
                            onClick={() => {
                              navigate(`/category/${t.taxanomySlug}`);
                            }}
                            className='text-decoration-none text-light pfw-button-green rounded-pill px-3'
                            style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}
                          >
                            {t.taxanomyName}
                          </Link>
                          {index !== array.length - 1 && <div style={{ width: '10px', height: '10px' }}></div>}
                        </React.Fragment>
                      ))}
                    </div>
                    <div
                      className={
                        props.color
                          ? `post-short-content ${props.color}`
                          : 'post-short-content bg-branding1'
                      }
                      style={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10, padding: 35 }}
                    >
                      <Link
                        className="text-decoration-none"
                        to={{
                          pathname: `/blog/${p.postSlug}`
                        }}
                        onClick={() => {
                          navigate(`/blog/${p.postSlug}`);
                        }}
                      >
                        <h3
                          className={
                            props.text1
                              ? `post-title ${props.text1}  fs-5 font-face-gb-semibold fw-normal mb-3`
                              : 'post-title color-yellow fs-5 font-face-gb-semibold fw-normal mb-3'
                          }
                          style={{ minHeight: 50 }}
                        >
                          {truncateText(decodeHTMLEntities(p.postName), 70)}
                        </h3>
                      </Link>
                      <div
                        className={
                          props.text2
                            ? `publishdate ${props.text2} mb-3`
                            : 'publishdate text-light mb-3'
                        }
                      >
                        {moment(p.postPublishedDate).format('Do MMMM YYYY')}
                      </div>
                      {/* <div className={props.text3 ?  `post-excerpt ${props.text3}`:'post-excerpt text-white'}>{p.excerpt.substring(0, 130)}</div> */}
                      <Link
                        className="border-0 rounded-pill btn pfw-button-red secbutton py-2 px-4 mt-4 mb-2 font-face-gb-semibold"
                        style={{ lineHeight: 2 }}
                        to={{
                          pathname: `/blog/${p.postSlug}`
                        }}
                        onClick={() => {
                          navigate(`/blog/${p.postSlug}`);
                        }}
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    </div>
  );
  // return (
  //   <div>
  //     <div className="post-slider">
  //     <Slider {...settings}>
  //     {BlogData.slice(0,4).map((p) => (
  //           <div key={p.id}>
  //             <div className='font-face-gb mb-5 m-3'>
  //                 <div className={props.border ? `bg-white h-100`:'bg-branding1 h-100'} style={{borderRadius: 10}}>
  //                     <Link to={"/blog/" + p.slug} onClick={() => SetBlogDetails(p)}><div className='post-image background-image' style={{backgroundImage: `url(${require(`../assets/images/blogs/${p.postImage}`)})`, borderTopLeftRadius: 10, borderTopRightRadius: 10, height: 233}}></div></Link>
  //                     <div className={props.color ? `post-short-content ${props.color}`: 'post-short-content bg-branding1'} style={{borderBottomLeftRadius: 10, borderBottomRightRadius: 10, padding:35}}>
  //                         <Link className='text-decoration-none' to={"/blog/" + p.slug} onClick={() => SetBlogDetails(p)}><h3 className={props.text1 ? `post-title ${props.text1}  fs-5 font-face-gb-semibold fw-normal mb-3`:'post-title color-yellow fs-5 font-face-gb-semibold fw-normal mb-3'} style={{minHeight: 50}}>{p.name}</h3></Link>
  //                         <div className={props.text2 ? `publishdate ${props.text2} mb-3`:'publishdate text-light mb-3'}>{p.publishdate}</div>
  //                         {/* <div className={props.text3 ?  `post-excerpt ${props.text3}`:'post-excerpt text-white'}>{p.excerpt.substring(0, 130)}</div> */}
  //                         <Link to={"/blog/" + p.slug} onClick={() => SetBlogDetails(p)} className="border-0 rounded-pill btn pfw-button-red secbutton py-2 px-4 mt-4 mb-2 font-face-gb-semibold" style={{lineHeight: 2}}>Read More</Link>
  //                     </div>
  //                 </div>
  //             </div>
  //           </div>
  //         ))}
  //     </Slider>
  //   </div>
  //   </div>
  // );
}


import {
    Elements,
    CardElement,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import axios from "axios";
import apiClient from "../api.config";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import { addPaymentInfo, purchase } from "../utils/meta/metaPixelEvent";




const StripeForm = (props) => {
    const [email, setEmail] = useState("");
    const [amount, setAmount] = useState("");
    // const [transactionID, setTransactionID] = useState("");


    useEffect(() => {
        setEmail(props.email);
        setAmount(props.amount);
    }, [props]);
    const handleSubmit = (stripe) => async () => {
        let log = secureLocalStorage.getItem('LogObject') ? secureLocalStorage.getItem('LogObject') : [];

        try {
            const cardElement = elements.getElement(CardElement);

            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });
            if (error) {
                console.log('[error While Creating Element]', error);
            } else {
                props.hideModal();   
                addPaymentInfo();          
                // console.log('PaymentMethod', paymentMethod);
                // if(!paymentMethod.livemode){
                // alert("It is a test Mode Payment");
                // }
                // console.log('PaymentMethod', paymentMethod.id);
                const res = await toast.promise(apiClient.post(`${process.env.REACT_APP_API_URL}/transactions/stripe3ds/checkout`, {
                    pageType: "checkout",
                    amount: amount,
                    userEmail: email,
                    name:props.name,
                    address1:props.address1,
                    address2:props.address2,
                    userPhoneNumber:props.userPhoneNumber,
                    postcode:props.postcode,
                    stripeToken: paymentMethod.id,
                    transactionItems: props.items.map(el => {
                        let elm = {};
                        elm.drawID = el.drawID
                        elm.drawCauseID = el.drawHostCauseID
                        elm.ticketPrice = el.price
                        // elm.ticketQuantity = el.quantity
                        elm.ticketQuantity = el.isBundle ? el.bundleQuantity : el.quantity
                        elm.isSubscription = el.subscription
                        elm.subscriptionPeriod = "month" //Or year
                        elm.itemTotal = el.itemTotal
                        elm.isBundle = el.isBundle
                        if(el.isBundle){
                            elm.bundleTotal = el.itemTotal
                        }
                        return elm
                    }),
                    transactionUserID: log.userID ? log.userID : "",
                    "transactionSiteID": '2cf8d2b9-6907-48e5-a8ce-bdbba4771750',
                    product: props.items.map(el => {
                        let elm = {}
                        elm.name = el.name
                        elm.price = el.price
                        elm.description = el.name
                        elm.metadata = {
                            userEmail: props.email
                        }
                        elm.data = [{
                            id: el.drawID,
                            name: el.name,
                            drawID: el.drawID,
                            drawCauseID: el.drawHostCauseID,
                            price: el.price,
                            quantity: el.quantity,
                            slug: el.slug,
                            subscription: el.subscription,
                            subscriptionPeriod : "month",
                            itemTotal: el.itemTotal,
                        }]
                        return elm

                    })
                }, {
                    withCredentials: true,
                    credentials: "same-origin"
                }).catch((e)=>{
                    if(e.code == "ERR_BAD_REQUEST")
                    console.log('Something went wrong',e);
                    toast.error(e.response.data.message); 
                }), {
                    pending: 'Please wait...',
                    error: 'Something went wrong'
                });
                try {
                    if (res.status == 200) {      
                        for (const item of props.items) {
                            const body = {
                            causeID: item.drawHostCauseID,
                            userID: res.data.userID,
                            signedUpForStoriesAndInspiration: props.signedUpForStoriesAndInspiration || 0,
                            receiveUpdates: props.receiveUpdates || 0,
                            };
                        
                            const existingPreferences = await axios.get(
                            `${process.env.REACT_APP_API_URL}/causes/cause-user-marketing-preferences/${item.drawHostCauseID}/${res.data.userID}`
                            );
                        
                            if (existingPreferences.status === 200) {
                                console.log('existingPreferences =>', existingPreferences);
                            
                                if (existingPreferences.data.content.causeMarketingPreferencesCount > 0) {
                                    const updatedUserPreference = await axios.put(
                                    `${process.env.REACT_APP_API_URL}/causes/cause-marketing-preferences`,
                                    body
                                    );
                                    console.log('updatedUserPreference =>', updatedUserPreference);
                                } else {
                                    const userPreferenceResp = await axios.post(
                                    `${process.env.REACT_APP_API_URL}/causes/cause-marketing-preferences`,
                                    body
                                    );
                                    console.log('userPreferenceResp =>', userPreferenceResp);
                                }
                            }
                        }
                    }
                } catch (error) {
                    console.log(`Failed to add user marketing preference: ${error}`);
                }
                // setTransactionID(res.data.transactionID);
                // console.log("ResponseData",res.data.transactionID);
                // await props.timeout(1);
                let transactionID = res.data.transactionID;
                // console.log("transactionID",transactionID);
                if (res.data.actionRequired) {
                    // We perform 3D Secure authentication
                    const { paymentIntent, error } = await toast.promise(stripe.confirmCardPayment(
                        res.data.clientSecret
                    ), {
                        pending: 'Please wait...',
                        error: '3D Secure Failed'
                    });
                    if (error) {
                        await apiClient.post(`${process.env.REACT_APP_API_URL}/transactions/stripe3ds/updateStatus`, {
                            transactionProcessorID: error.payment_intent.id,
                            status: 4
                        });
                        // console.log("3ds",error);
                        toast("3d Secure Failed.", { type: "error" });

                    };
                    if (paymentIntent.status === "succeeded")
                        console.log(paymentIntent);
                        try {
                            const res2 = await apiClient.post(`${process.env.REACT_APP_API_URL}/transactions/stripe3ds/updateStatus`, {
                                transactionProcessorID: paymentIntent?.id,
                                sendMail:true,
                                mailData:res.data.mailData,
                                status: 3
                            });
                            props.emptyCart();
                            toast("Payment Done Successfully", { type: "success" });
                            const currency = 'GBP';
                            const value = amount;
                            purchase(currency, value);
                            await props.timeout(2000);
                            console.log(`/payment-success/${transactionID.toString()}`);
                            props.navigate(`/payment-success/${transactionID.toString()}`);
                        } catch (error) {
                            console.log("3dsError Not Subs",error);
                        }finally{
                            props.emptyCart();
                            toast("Payment Done Successfully", { type: "success" });
                            const currency = 'GBP';
                            const value = amount;
                            purchase(currency, value);
                            await props.timeout(2000);
                            console.log(`/payment-success/${transactionID.toString()}`);
                            props.navigate(`/payment-success/${transactionID.toString()}`);

                        }
                } else {
                    props.emptyCart();
                    toast("Payment Done Successfully", { type: "success" });
                    const currency = 'GBP';
                    const value = amount;
                    purchase(currency, value);
                    await props.timeout(2000);
                    console.log(`/payment-success/${transactionID.toString()}`);

                    props.navigate(`/payment-success/${transactionID.toString()}`);
                }
            }
        } catch (error) {
            console.error(error);
            return toast("Payment failed!", { type: "error" });
        }
    };

    const stripe = useStripe();
    const elements = useElements();

    return (
        <>
            {stripe && stripe._keyMode === 'live' ? '' :
                <div className="d-flex justify-content-center mb-2">
                    <span className="text-white text-center bg-danger px-3 py-1" style={{ marginTop: '-25px' }}>Stripe : {stripe?._keyMode} Mode</span>
                </div>
            }
            <h3 className="branding1 text-center">Play Fund Win</h3>
            <span className="text-muted text-center d-flex justify-content-center mt-3">{props.email}</span>
            <div className="p-3 my-3 border rounded-4" >
                <CardElement
                    options={{
                        style: {
                            base: {
                                fontSize: "16px",
                                color: "#424770",
                                "::placeholder": {
                                    color: "#aab7c4",
                                },
                            },
                            invalid: {
                                color: "#9e2146",
                            },
                        },
                    }}
                />

            </div>
            <button className="pfw-button-red rounded-4 px-3 w-100 py-3" onClick={handleSubmit(stripe, elements)}>Pay Now <strong> £{amount} </strong></button>
            <span className="text-muted text-center d-flex justify-content-center mt-3">* 100% Secure & Safe Payment *</span>
        </>
    );
}


export default StripeForm;
import React from 'react'
import { Link } from "react-router-dom";
import Siteheader from '../components/header';
import SiteFooter from '../components/footer';
import { UseEffect } from 'react';
import FooterCta from '../components/footer-cta';
import Innerheader from '../components/inner-header';
import { Helmet } from 'react-helmet';

export default function PlayerTermsConditions(props) {
 
  return (
    <>
    <Helmet>‍
      <title>Player Terms and Conditions | Play Fund Win</title>‍
      <meta name="description" content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities." />
    </Helmet>
    <Siteheader/>
    <Innerheader title="Player Terms and Conditions"/>

  <div className='common-content-area anchor-hover-grn container pt-5 pb-5 my-3 px-lg-5 rounded-4 w-92' style={{backgroundColor: '#f2f2f2'}}>
    <div className="row px-lg-5">
       <div className="col-md-12 align-middle text-left px-lg-5 mt-lg-0 mt-3">
            <p><Link to={'/'}>playfundwin.com</Link> – Player terms and conditions of use and draw rules</p>
            <p><strong>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS SITE</strong></p>
            <p>These terms set out the basis upon which you may use playfundwin.com (our site) and explains how the prize draws (draws) we manage on behalf of societies (including charities) and local authorities are operated.</p>
            <p><strong>1. Who we are and how to contact us</strong></p>
            <p>1.1. <Link to={'/'}>www.playfundwin.com </Link>is a site operated by PFW Holdings Ltd (we, us), a company registered in England and Wales under company number 12267104 and have our registered office at PFW Holdings Ltd 2 & 3 Westfield House Millfield Lane Nether Poppleton York YO26 6GA.</p>
            {/* <p>1.2. We are regulated by the Gambling Commission in Great Britain under licence number 057889 as a remote External Lottery Manager. A link to the Gambling Commission’s register showing our licensed status can be found <a href="https://www.gamblingcommission.gov.uk/public-register/business/detail/57889" target="_blank">https://www.gamblingcommission.gov.uk/public-register/business/detail/57889</a></p> */}
            <p>1.2. These terms of use set out the rules for the draws which are promoted and managed by us on behalf of and for the benefit of various non-commercial societies or local authorities (which we refer to as hosts). For lotteries, all hosts are required to be licensed by the Gambling Commission or local authority to enable them to promote lotteries. Where applicable, we will ensure that all hosts are correctly licensed, before we allow them to promote draws on our website.</p>
            <p>1.3. To contact us, please email <a href="mailto:hello@playfundwin.com">hello@playfundwin.com</a>.</p>
            <p><strong>1. By using our site you accept these terms</strong></p>
            <p>By registering on our site as a player and/or buying a ticket for a draw, you confirm that you accept these terms of use and that you agree to comply with them. If you do not agree with any of these terms then you may not access or use our website, register for an account, buy a ticket for a draw or use any of the services. We recommend that you print a copy of these terms for future reference.</p>
            <p><strong>2. There are other terms that may apply to you</strong></p>
            <p>2.1. These terms of use refer to the following additional terms, which also apply to your use of our site:</p>
            <p>2.2. Our Privacy Policy <Link to={"/privacy-policy"}>Privacy Policy</Link>. See further under ‘How we may use your personal information’.</p>
            <p>2.3.Our Cookie Policy <a href="/cookie-policy-in-the-website-terms-of-use">Our Cookie Policy</a>, which sets out information about the cookies on our site.</p>
            <p><strong>3. We may make changes to these terms</strong></p>
            <p>We amend these terms from time to time without notice. Every time you wish to use our site, please check these terms to ensure you understand the terms that apply at that time. If you need, we are happy to provide you with a copy of these terms by post. If you would like us to do so, please send a stamped addressed envelope to our address set out above.</p>
            <p><strong>4. We may make changes to our site</strong></p>
            <p>We may update and change our site from time to time to reflect changes to our products, our users’ needs and our business priorities or as a result of regulatory requirements.</p>
            <p><strong>5. Our site is only for users in the UK</strong></p>
            <p>Our site is directed and targeted at people residing in the United Kingdom. It is not intended to encourage people outside of the UK to participate in draws on playfundwin.com and nothing on the site is intended as an offer or invitation by us to any person to do so. You must only access your account when you are physically located in the UK. We do not represent that content available on or through our site is appropriate for use or available in other locations.</p>
            <p><strong>6. Registration and account creation</strong></p>
            <p>6.1. In order to use our services and participate in a draw, you will either need to use the “guest checkout” function or register for and open a player account. You can do this via our website by following the on-screen instructions that are provided after clicking the “create account” button or link.</p>
            <p>6.2. To open a player account and/or buy a ticket for a draw, you must be aged 18 or over. If you are under the age of 18 and you open an account and/or buy a ticket for a draw you may be committing a criminal offence. Prior to making any payment to you, your age will be verified by one of our intermediaries on our behalf. In the event that a person under the age of 18 registers an account and/or participates in a draw we may withhold any payments which would otherwise have been due to that person and instead pay them to a registered charity or social enterprise of our choice.</p>
            <p>6.3. You may deposit money into the ‘wallet’ on your account on our website in accordance with these terms and conditions. You are not permitted to deposit more than £1,000 into your ‘wallet’ or make any deposits into your wallet such that the amount in your wallet is greater than £1,000. We may, if we consider it necessary to do so, impose cooling-off periods on players’ use of deposited monies. If we do so in relation to your account or a certain deposit then we will notify you that we have done so.</p>
            <p><strong>7. You must keep your account details safe</strong></p>
            <p>7.1. If you create a player account you will be required to choose a user identification code and a password as part of our security procedures. You must treat such information as confidential and you must not disclose it to any third party.</p>
            <p>7.2. We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these terms of use.</p>
            <p>7.3. If you know or suspect that anyone other than you learns of your user identification code or password, you must promptly notify us at <a href="mailto:hello@playfundwin.com">hello@playfundwin.com.</a></p>
            <p><strong>8. KYC checks</strong></p>
            <p>8.1. We may be obliged by our regulator, by law or by our own internal policies or processes to carry out identity verification checks before a draw entry can be accepted and/or prior to transferring any payment. These checks are undertaken for the purpose of preventing money laundering and terrorist financing. We take these obligations very seriously. In the event that any player is unable to satisfy our anti-money laundering and terrorist financing checks we will be obliged by law to withhold any payments which would otherwise have been due to that player and we will instead pay them to a registered charity or social enterprise of our choice.</p>
            <p>8.2.&nbsp;When a player wins a prize on our site they may be contacted by us or our verification partner to verify the player’s identity and to cross reference their identity against various national crime databases. Our verification partner will provide a copy of their privacy policy and will process all personal data in accordance with the GDPR and all relevant data privacy laws. A copy of their privacy policy is available on request. We will not be sent any additional personal information about the player in question by our verification partner, other than a statement as to whether the player in question passed or failed the verification process and anti-money laundering checks.</p>
            <p><strong>9. Participating in draws</strong></p>
            <p>9.1. By creating an account, players are not under any obligation to participate in any draw. Any draws that you enter or participate in, you do so entirely at your own choice.</p>
            <p>9.2. <span data-doc-id={5180277000000112001} data-doc-type="writer">Players </span>may enter a draw by selecting a draw on our website, clicking “Buy Now” and following the entry process that is presented. The draw parameters will be set out on each draw’s page, including the duration of the draw period; the name of the draw; the categorisation of the draw; the description; the ticket price; the net percentage of the ticket sale proceeds which will be distributed as a prize; the net percentage of the ticket sale proceeds which will be paid to the host; and any fundraising ceiling. All of these parameters are subject to any limitations that we may create from time to time in our absolute discretion.</p>
            <p>9.3. Your entry to a draw will be deemed to be accepted when we email you a document to confirm to you that your entry has been successful. The document (which you may print out if required) will identify the promoting society and (for licensed draws) state the name and address of the designated person at the society responsible for promoting the lottery, state the date of the draw (or how that date can be determined) and the price of the entry. If we are unable to accept your entry we will inform you of this in writing and will not charge you for the ticket. We might be unable to accept your entry for a number of reasons, including but not limited to us identifying an error in the ticket price or description of the draw or the draw fundraising ceiling having been reached. If your entry is successful you will be provided with ticket numbers for each of the tickets that you have successfully purchased. Until we have confirmed to you that your entry is successful, we owe you no obligations in respect of that draw and no contract will exist between us.</p>
            <p>9.4. Please note that once you have purchased a ticket and entered a draw, you do not have any right to change your mind or to withdraw your entry, except as is set out within these terms and conditions.</p>
            <p>9.5. We do not guarantee that you will be able to enter any draw listed on our website. We may, at any time where in our opinion it is reasonable to do so (including but not limited to the withdrawal or cancellation of any necessary local authority registration), cancel any draw and cancel, return or otherwise unwind any transactions or ticket purchases that have been made in respect of that draw.</p>
            <p>9.6. Our only binding obligations in respect of draws is limited to:</p>
            <p className="drop-event">9.6.1. conducting checks into the identity of hosts and players as set out at paragraph 8.</p>
            <p className="drop-event">9.6.2. arranging payments pursuant to and subject to paragraphs 11 and 15 to the host and to any winners of a successfully completed draw, provided that such winners and/or host have passed any KYC checks required as is set out at paragraph 8.</p>
            <p>9.7. In the event that a player breaches these terms and conditions or any specific rules relating to a draw, we will have a right to cancel the contract that exists between us and that player. If we choose to cancel that contract, the player’s ticket will be cancelled and no refund will be made.</p>
            <p>9.8. In the event that a player enters a draw, wins a prize, but does not provide the KYC documents (as required further to paragraph 8 above) for any reason whatsoever then no prize will be transferred to that winner and no refund of that winner’s stake will be given. We will then re-run the draw in accordance with paragraph 10.5 below. If you cannot (or if you are unwilling to) provide the necessary KYC documents then you should not enter any draws. Please contact us if you have any questions about this requirement.</p>
            <p>9.9. In accordance with paragraph 11.1.1 below, a minimum of 20% of the proceeds of any lottery managed us will be paid to the society and applied for a purpose for which the promoting society is conducted. The actual percentage paid to the society will be stated on the promotional material for each draw. Details of the cause that you are supporting by participating in a draw can be found on the webpage for each draw and you can find a list of all of the societies that are currently running a draw here: <Link to={"/find-a-draw"}>https://playfundwin.com/find-a-draw/</Link></p>
            <p><strong>10. How we run draws</strong></p>
            <p>10.1. When a draw goes live on our site, you may purchase a ticket or tickets to the draw, subject to the terms of the draw, these terms and conditions and any limits set by a host during its creation or that we place upon players. The limits and any additional terms which apply to a draw will be set out on that draw’s webpage. Tickets will be available to purchase until the earlier of (i) the end of the draw period; or (ii) such time as the draw limit is reached. Once a ticket is purchased by a player it is entered into the draw immediately and is not refundable. In the event that a payment is reversed or charged back by a card issuer or payment processor for any reason, any tickets relating to that payment will be voided and any prize in relation to such a ticket will not be paid or, if a prize payment has already been made, it shall become immediately repayable to us.</p>
            <p>10.2. <strong>PROTECTION OF CUSTOMER FUNDS</strong> We are required by our licence to inform customers about what happens to funds which we hold on account for you in the event of insolvency <a href="http://www.gamblingcommission.gov.uk/consumers/protection_of_customer_funds.aspx" target="_blank">www.gamblingcommission.gov.uk/consumers/protection_of_customer_funds.aspx</a> All of our lottery ticket sales will be held by us on behalf of societies whose lotteries we are managing in a segregated bank account. This account is used for holding the proceeds of ticket sales only and is separate from the account used for our day-to-day business expenses. These funds are not protected in the event of insolvency.</p>
            <p>10.3. Within 7 days of the end of a draw, we will select the winner(s) of that draw at random from all of the tickets that were purchased in respect of that draw. The winner(s) are selected entirely at random using a certified and encrypted random number generator. The result of a draw is final and may not be appealed or petitioned against. If a draw is interrupted due to equipment failure or for any other reason, then it will be rescheduled and take place as soon as reasonably possible after the originally scheduled date. We may, where we consider it reasonable to do so, postpone a draw. If such a delay occurs, we have no obligation to notify the host or any players.</p>
            <p>10.4. The selected winner(s) will be contacted by us via email. The selected winner(s) must respond to the email and provide the relevant information, including providing their bank account details or debit card details. If any winner(s) does not respond to our initial email notification, we will make at least two further attempts to reach them using the contact details that they have provided. If the winner cannot be contacted and/or does not claim a prize within 14 days, that winner will be treated as a non-responsive winner and the draw will be re-run in accordance with paragraph 10.5 below.</p>
            <p>10.5. If we are still unable to contact a winner(s), or if any given winner(s) fails to pass our identity and anti-money laundering verification checks as detailed at paragraph 8 or fails to complete the process set out at paragraph 10.4 we will remove all tickets purchased by that/those winner(s) and re-run the draw in respect of that prize to select a new winner(s). This process will be reiterated until a qualifying winner is found. If a re-run becomes impossible due to all of the tickets having been removed due to failure(s) to pass our anti-money laundering checks or the winners being uncontactable then any payment that would have been made to a winner(s) will instead be paid to a registered charity or social enterprise of our choice.</p>
            <p>10.6. In the event that a winner dies before they are able to claim their prize, a person can claim on the behalf their estate if they can prove that they are entitled to make the claim. A grant of probate may be required by us as proof. The person claiming will need to submit any reasonably required documents to us within 14 days of the draw winning email notification being sent.</p>
            <p>10.7. We may need to make minor changes to draws from time to time to reflect changes in relevant laws and regulatory requirements or to implement minor technical adjustments and improvements, for example to address a security threat. If we need to make any major changes to a draw or to these terms or cancel a draw then we will notify you and you will have a right with us to end your participation in a draw and claim a refund for and tickets purchased.</p>
            <p><strong>Our fees and payments on completion of draws</strong></p>
            <p>11.1 Upon the successful completion of a draw:</p>
            <div className="drop-event">
                <p>11.1.1. subject to the completion of any necessary KYC, verification and identity checks which we are required to or may need to carry out, a sum calculated in accordance with the ‘Draw Beneficiary Percentage’ value set out on the relevant webpage for the draw (less any transaction costs) will be sent to the host. This sum will be at least 20% of the total proceeds of the ticket sales and this sum will be paid to the society to be applied towards the purpose for which the promoting society is conducted;11.1.2. subject to the completion of any necessary KYC, verification and identity checks as are set out in paragraph 8, a sum calculated in accordance with the ‘Prize Percentage’ value set out on the relevant webpage for the draw (being the net value of the ticket sales multiplied by the winner’s percentage, less the cost of any KYC checks which we may carry out in respect of the relevant winning player) will be sent to the debit card or bank account that the winning player provided within its user profile area or on checkout; and</p>
                <p>11.1.3. a sum equal to between 15% and 20% (as is set out on the relevant webpage for the draw) of the total proceeds of the ticket sales will be transferred to us to cover our costs of staging the draw and our fee.</p>
            </div>
            <p>11.2. All payments will be sent to the debit card or bank account that the winner has provided. It is your responsibility to ensure that your bank and debit card details are correct and kept up to date. We do not accept any liability for any loss that may arise from your failure to do so.</p>
            <p>11.3. Any debit card details that are provided by players will be held by a secure SSL encrypted third party payment processor. We will not have access to this information, other than the name on the card and the last four digits of the card number which we may use for the purpose of identity confirmation.</p>
            <p>11.4. In participating in a draw and when claiming or accepting a prize, players warrant that they are not a director or employee of us, one of our website developers, suppliers or contractors, nor are they claiming on the behalf of such a person nor are they a spouse or dependent of such a person nor are they otherwise ineligible to participate in a draw or win a prize under any other of these terms.</p>
            <p><strong>12. Cancelled draws</strong></p>
            <p>As is set out elsewhere in these terms of use, we may from time to time have to cancel a draw. In the event that a draw is cancelled we will refund any ticket purchases that have been made in respect of that draw. Any such refund given will be sent directly back to the bank account or card that was used to make the payment. No interest will be paid on any refunds made.</p>
            <p><strong>13. Excluding yourself from participating in draws</strong></p>
            <p>13.1. You may self-exclude from participating in draws with us. You can self-exclude by emailing <a href="mailto:hello@playfundwin.com">hello@playfundwin.com</a>. In the event that you decide to self-exclude from gambling with us, all monies held by us in your player wallet will be returned to you and your online account will be suspended.</p>
            <p>13.2. You may choose the length of your self-exclusion. There is a minimum period of six months self-exclusion applicable. During the period of your self-exclusion, you will not be permitted to access your online account, nor will you be able to enter any draws using that account.</p>
            <p>13.3. We will not contact you at the expiration of your self-exclusion, which will remain in place for a minimum period of 7 years. You may take positive steps to re-open your account by contacting us, although this is subject to a 24 hour a cooling-off period before your account will becomes active again.</p>
            <p>13.4. On self-exclusion, your details will be removed from our marketing database and no marketing material will be sent to you. You may also opt-out from receiving marketing materials from us. You can do this through an unsubscribe link on any automated email sent by us. For more details, please see our privacy policy.</p>
            <p>13.5. We are not required by our licence conditions to participate in the national multi-operator self-exclusion scheme (GAMSTOP). For this reason, it is important that you self-exclude directly with us if you wish to cease participating in our draws.</p>
            <p>13.6. Players should participate in draws responsibly. Information on how to gamble responsibly and how to access information about problem gambling can be found on <a href="https://www.begambleaware.org" target="_blank">https://www.begambleaware.org</a>.</p>
            <p><strong>14. Complaints and dispute resolution</strong></p>
            <p>14.1. If you have a complaint about how the site or a draw has been (or is being run) or about anything else then please email us at <a href="mailto:hello@playfundwin.com">hello@playfundwin.com</a> or visit our complaints procedure <Link to={"/complaints-procedure"}>https://playfundwin.com/complaints-procedure/</Link>.</p>
            <p>14.2. If we cannot resolve your complaint to your satisfaction then you may refer your complaint to the independent adjudicator for further investigation and review. The independent adjudicator, namely the Independent Betting Adjudication Service (IBAS), is specialised in the resolution of gaming disputes and their contact details are as follows:<br />
                Independent Betting Adjudication Service<br />
                PO Box 62639<br />
                London<br />
                EC3P 3AS<br />
                Telephone: 020 7347 5883<br />
                Email: <a href="mailto:ibasteam@ibas-uk.co.uk">ibasteam@ibas-uk.co.uk</a><br />
                Website: <a href="http://www.ibas-uk.com" target="_blank">www.ibas-uk.com</a><br />
                The independent adjudicator will consider your complaint on an impartial basis and we are bound by their decision.</p>
            <p><strong>15. Our responsibility for loss or damage suffered by you</strong></p>
            <p>15.1. In creating a player account and/or buying a ticket for a draw, you represent and warrant to us that you are acting on your own account as a consumer and not on the behalf of someone else or on the behalf of a business.</p>
            <p>15.2. We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.</p>
            <p>15.3. You are responsible for any decision made by you based on information on this site. We accept no liability for loss or damage (including, without limitation, any special, direct, indirect or consequential loss or damage or other losses or damage of whatever kind) that arise out of or relate to the use of this site or its contents.</p>
            <p>15.4. We exclude all implied conditions, warranties, representations or other terms that may apply to our site or any content on it to the maximum extent permissible by law.</p>
            <p>15.5. We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with use of, or inability to use, our site, or use of or reliance on any content displayed on our site. In particular, as you are acting as a consumer we will not be liable to you for loss of profits; sales; business; revenue; loss of anticipated savings; loss of opportunity; loss of goodwill; or loss of reputation.</p>
            <p>15.6. We will not be liable to any person for:</p>
            <div className="drop-event">
                <p>15.6.1. the failure or destruction of, or damage to, all or part of our IT systems or records, including any systems operated by third parties upon which we rely (including any failure, error or erroneous communication caused by a virus, DDOS attack, hack, worm or a software or hardware error);15.6.2. any refusal to allow a person to enter a draw for any reason;</p>
                <p>15.6.3. any errors caused by incorrect or inaccurate automatic processing, including the selection of a winner of a draw, or our records or any third party records upon which we rely;</p>
                <p>15.6.4. any errors caused by a data entry error, a manual error (including incorrectly entered details or ticket pricing information) or where problems occur in the software or hardware used by us to run our website or a draw, and in particular where such an error does occur:</p>
                <div className="drop-event-drop">
                    <p>(a) we shall not be liable to pay any prizes in respect of any draws affected by such an error and we may cancel a draw and/or deem any affected tickets void and/or refund any affected tickets;b) where we notice such an error before the draw is due to take place where possible we will attempt to inform all affected players and allow them to choose whether to continue with their entry upon the corrected terms, or to have their ticket refunded;</p>
                    <p>(c) any monies which are paid to you in respect of such an error shall be deemed to be held by you on trust for us and shall be immediately repaid to us when a demand for payment is made by us to you.</p>
                </div>
                <p>15.6.5. any events beyond our reasonable control (including but not limited to terrorism, fire, flood, drought, power cut and/or failure or obstruction of any network, pandemic, epidemic, any public health emergency, broadcasting or telecommunications service, war, strike, lockout, industrial action);</p>
                <p>15.6.6. delays, losses or mistakes on the part of any email system, courier, the postal service or any bank; or</p>
                <p>15.6.7. a failure or refusal to make any payment where it would be unlawful to do so or where we are prohibited from doing so by any law, regulation or regulatory body.</p>
                <p><strong>16. Do not rely on information on this site</strong></p>
                <p>16.1. The content on our site is provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on our site.</p>
                <p>16.2. Although we make reasonable efforts to update the information on our site, we make no representations, warranties or guarantees, whether express or implied, that the content on our site is accurate, complete or up to date.</p>
                <p><strong>17. How we may use your personal information</strong></p>
                <p>We will only such personal information as you provide to us in accordance with our privacy policy <Link to={"/privacy-policy"}>https://playfundwin.com/privacy-policy/</Link>.</p>
                <p><strong>18. Acceptable use and uploading content to our site</strong></p>
                <p>18.1. Whenever you make use of a feature that allows you to upload content to our site, or to make contact with other users of our site, you must comply with the following content standards (our <strong>Acceptable Use Policy</strong>):</p>
                <div className="drop-event">
                    <p>18.1.1. You may not use our site in any way that breaches any applicable local, national or international law or regulation;</p>
                    <p>18.1.2. You may not use our site in any way that is unlawful or fraudulent or has any unlawful or fraudulent purpose or effect;</p>
                    <p>18.1.3. You may not use our site for the purpose of harming or attempting to harm minors in any way;</p>
                    <p>18.1.4. You may not use our site to bully, insult, intimidate or humiliate any person;</p>
                    <p>18.1.5. You may not use our site to send, knowingly receive, upload, download, use or re-use any material which:</p>
                    <div className="drop-event-drop">
                        <p>(a) is inaccurate (where it states facts);</p>
                        <p>(b) is not genuinely held (where it states opinions);</p>
                        <p>(c) is defamatory of any person;</p>
                        <p>(d) is obscene, offensive, hateful or inflammatory;</p>
                        <p>(e) promotes or includes any pornographic or sexually explicit material;</p>
                        <p>(f) includes child sexual abuse material;</p>
                        <p>(g) promotes violence;</p>
                        <p>(h) promotes discrimination based on race, sex, religion, nationality, disability, sexual orientation or age;</p>
                        <p>(i) infringes any copyright, database right or trade mark of any other person;</p>
                        <p>(j) is likely to deceive any person;</p>
                        <p>(k) breaches any legal duty owed to a third party, such as a contractual duty or a duty of confidence;</p>
                        <p>(l) promotes any illegal content or activity;</p>
                        <p>(m) is in contempt of court;</p>
                        <p>(n) is threatening, abuse or invade another’s privacy, or cause annoyance, inconvenience or needless anxiety;</p>
                        <p>(o) is likely to harass, upset, embarrass, alarm or annoy any other person;</p>
                        <p>(p) impersonates any person or misrepresent your identity or affiliation with any person;</p>
                        <p>(q) advocated, promote, incite any party to commit, or assist any unlawful or criminal act such as (by way of example only) copyright infringement or computer misuse;</p>
                        <p>(r) contains a statement which you know or believe, or have reasonable grounds for believing, that members of the public to whom the statement is, or is to be, published are likely to understand as a direct or indirect encouragement or other inducement to the commission, preparation or instigation of acts of terrorism;</p>
                        <p>(s) contains any advertising or promote any services or web links to other sites;</p>
                    </div>
                    <p>18.1.6. You may not use our site to transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam);</p>
                    <p>18.1.7. You may not use our site to knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware;</p>
                    <p>18.1.8. You agree not to reproduce, duplicate, copy or re-sell any part of our site except as is expressly permitted elsewhere within these terms of use; and</p>
                    <p>18.1.9. You agree not to access without authority, interfere with, damage or disrupt:</p>
                </div>
                <div className="drop-event-drop">
                    <p>(a) any part of our site;</p>
                    <p>(b) any equipment or network on which our site is stored;</p>
                    <p>(c) any software used in the provision of our site; or</p>
                    <p>(d) any equipment or network or software owned or used by any third party.</p>
                </div>
                <p>18.2. You warrant that any such content contribution or uploaded material complies with these standards, and you will be liable to us and indemnify us in full on demand for any breach of this warranty, including our legal costs on an indemnity basis. This means you will be responsible for any loss or damage we suffer as a result of your breach of this warranty.</p>
                <p>18.3. Any content you upload to our site will be considered non-confidential and non-proprietary. You retain all of your ownership rights in your content, but as a condition of uploading any content you hereby agree to grant us a limited licence to use, store and copy that content and to distribute and make it available to third parties. The rights you license to us are described in Rights you are giving us to use material you upload.</p>
                <p>18.4. We also have the right to disclose your identity to any third party who is claiming that any content posted or uploaded by you to our site constitutes a violation of their intellectual property rights, or of their right to privacy.</p>
                <p>18.5. We have an absolute right to remove any postings or content uploaded to our site if, in our opinion, your post or content does not comply with the content standards set out above.</p>
                <p>18.6. You are solely responsible for securing and backing up your content.</p>
                <p><strong>19. Rights you are giving us to use material you upload</strong></p>
                <p>When you upload or post content to our site, you grant us a worldwide, perpetual, irrevocable, non-exclusive, royalty-free, transferable licence to use, reproduce, distribute, prepare derivative works of, display, and perform that user-generated content in connection with the service provided by the website and across different media including to promote the site or the service.</p>
                <p><strong>20. How you may use material on our site</strong></p>
                <p>20.1. Except where otherwise indicated, we are the owner or the licensee of all intellectual property rights in our site, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.</p>
                <p>20.2. You may print off one copy, and may download extracts, of any page(s) from our site for your personal use and you may draw the attention of others within your organisation to content posted on our site.</p>
                <p>20.3. You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</p>
                <p>20.4. Our status (and that of any identified contributors) as the authors of content on our site must always be acknowledged.</p>
                <p>20.5. You must not use any part of the content on our site for commercial purposes without obtaining a licence to do so from us or our licensors.</p>
                <p>20.6. If you print off, copy or download any part of our site in breach of these terms of use, your right to use our site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.</p>
                <p><strong>21. We are not responsible for viruses and you must not introduce them</strong></p>
                <p>21.1. We do not guarantee that our site will be secure or free from bugs or viruses. You are responsible for configuring your information technology, computer programmes and platform to access our site. You should use your own virus protection software.</p>
                <p>21.2. You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful. You must not attempt to gain unauthorised access to our site, the server on which our site is stored or any server, computer or database connected to our site. You must not attack our site via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our site will cease immediately.</p>
                <p><strong>22. Rules about linking to our site</strong></p>
                <p>22.1. You may link to our home page, your player profile and the page for any draw that you are participating in or have a connection with provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.</p>
                <p>22.2. You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists. You must not establish a link to our site in any website that is not owned by you.</p>
                <p>22.3. Our site must not be framed on any other site, nor may you create a link to any part of our site other than the home page.</p>
                <p>22.4. We reserve the right to withdraw linking permission without notice.</p>
                <p>22.5. Any website which you link to must comply in all respects with the content standards set out in our Acceptable Use Policy.</p>
                <p>22.6. If you wish to link to or make any use of content on our site other than that set out above, please contact <a href="mailto:hello@playfundwin.com">hello@playfundwin.com</a>.</p>
                <p><strong>23. We are not responsible for any websites we link to</strong></p>
                <p>23.1. Where our site contains links to other sites and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them.</p>
                <p>23.2. We have no control over the contents of those sites or resources.</p>
                <p><strong>24. User-generated content is not approved by us</strong></p>
                <p>This website may include information and materials uploaded by other users of the site, including to bulletin boards and chat rooms. This information and these materials have not been verified or approved by us. The views expressed by other users on our site do not represent our views or values.</p>
                <p><strong>25. How to complain about content uploaded by other users</strong></p>
                <p>If you wish to complain about content uploaded by other users, please contact us on <a href="mailto:hello@playfundwin.com">hello@playfundwin.com</a>.</p>
                <p><strong>26. We may suspend or withdraw our site</strong></p>
                <p>26.1. You may use our site and log-in to your user area free of charge.</p>
                <p>26.2. We do not guarantee that our site, or any content on it, will always be available or be uninterrupted. We may suspend or withdraw or restrict the availability of all or any part of our site for business and operational reasons. We will try to give you reasonable notice of any suspension or withdrawal.</p>
                <p>26.3. You are also responsible for ensuring that all persons who access our site through your internet connection are aware of these terms of use and other applicable terms and conditions, and that they comply with them.</p>
                <p><strong>27. Dormant accounts</strong></p>
                <p>In the event that a player’s account is not accessed for 36 months, we will contact the holder of that account by email informing them that if they do not log-in within the next three months their account will be deactivated and/or deleted. If the account holder does not log in within the said three-month period, their account will be duly deactivated and/or deleted. In this situation any unclaimed funds will be donated to a charity of our choice.</p>
                <p><strong>28. Our trademarks are registered</strong></p>
                <p>PLAY FUND WIN, <Link to={'/'}>playfundwin.com </Link>and the Play Fund Win logo are the UK registered trademarks of PFW Holdings Ltd. You are not permitted to use them without our prior written approval, unless they are part of material you are using as permitted under How you may use material on our site.</p>
                <p><strong>29. General</strong></p>
                <p>29.1. By using this website and/or creating a draw you agree to be bound by the provisions of any applicable legislation, these terms of use, our privacy policy and any other rules or procedures, statements or explanations we may issue.</p>
                <p>29.2. If any part of this agreement is ruled by a court of competent jurisdiction to be void and/or unenforceable, that decision will only affect the particular provision (or part of the provision) and will not make the other provisions void or unenforceable.</p>
                <p>29.3. We may transfer our rights and obligations under these terms to another organisation. We will always tell you in writing if this happens and we will ensure that the transfer will not affect your rights under the contract. You may not assign or otherwise transfer your obligations or your rights under these terms of use.</p>
                <p>29.4. Any breach of this agreement by you may result in us suspending or terminating your account and/or tickets that you have purchased.</p>
                <p>29.5. A person who is not a party to these Rules has no rights under the Contracts (Rights of Third Parties) Act 1999 (as amended or re-enacted from time to time, and any subordinate legislation made under that Act) or otherwise to enforce any provision of these Rules.</p>
                <p>29.6. These terms of use, their subject matter and their formation, are governed by English law. You and we both agree that the courts of England and Wales will have exclusive jurisdiction.</p>
                <p>PFW terms and conditions last updated October 2023</p>
            </div>
       </div>
    </div>
  </div>

  <FooterCta/>
  <SiteFooter/>
    </>
  )
}

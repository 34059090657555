import React from 'react'
import { Link } from "react-router-dom";
import Siteheader from '../components/header';
import SiteFooter from '../components/footer';
import { UseEffect } from 'react';
import FooterCta from '../components/footer-cta';
import Innerheader from '../components/inner-header';
import { Helmet } from 'react-helmet';

export default function Legal(props) {
 
  return (
    <>
    <Helmet>‍
      <title>Legal | Play Fund Win</title>‍
      <meta name="description" content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities." />
    </Helmet>
    <Siteheader/>
    <Innerheader title="Legal"/>

  <div className='container pt-5 pb-5 my-3 px-lg-5 rounded-4 w-92 anchor-hover-grn' style={{backgroundColor: '#f2f2f2'}}>
    <div className="row px-lg-5">
       <div className="col-md-12 align-middle text-left px-lg-5 mt-lg-0 mt-3">
			<p>Play Fund Win are members of the Chartered Institute of Fundraising, National Outdoor Event Association and the Association of Festival Organisers.</p>
            <p>Play Fund Win are registered as data controllers with the Information Commissioners Office.</p>
            <p>Always play responsibly, if you need to talk to someone about your gambling please contact <a className='branding3 fw-bold' href="http://www.gambleaware.co.uk/" target="_blank">Gamble Aware</a></p>
            <p>Please find the following links to our other legal documents:</p>
            <ul>
                <li><Link className='branding3 fw-bold' to={"/player-terms-and-conditions/"}>Player Terms and Conditions</Link></li>
                <li><Link className='branding3 fw-bold' to={"/host-terms-and-conditions/"}>Host Terms and Conditions</Link></li>
                <li><Link className='branding3 fw-bold' to={"/prize-draw-terms-conditions/"}>Prize Draw Terms &amp; Conditions</Link></li>
                <li><Link className='branding3 fw-bold' to={"/prize-competition-terms-and-conditions"}>Prize Competitions Terms & Conditions</Link></li>
                <li><Link className='branding3 fw-bold' to={"/privacy-policy/"}>Website Privacy Policy</Link></li>
                <li><Link className='branding3 fw-bold' to={"/cookie-policy-in-the-website-terms-of-use"}>Website Terms of Use</Link></li>
                <li><Link className='branding3 fw-bold' to={"/acceptable-use-policy"}>Acceptable Use Policy</Link></li>
                <li><Link className='branding3 fw-bold' to={"/identity-verification-policy"}>Identity Verification Policy</Link></li>
                <li><Link className='branding3 fw-bold' to={"/complaints-procedure/"}>Complaints Procedure</Link></li>
            </ul>
       </div>
    </div>
  </div>

  <FooterCta/>
  <SiteFooter/>
    </>
  )
}

import React from 'react'
import SiteFooter from '../components/footer'
import Siteheader from '../components/header'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export default function ClubEditContactDetails() {
  return (
    <>
    <Helmet>‍
      <title>Edit Contact Details | Play Fund Win</title>‍
      <meta name="description" content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities." />
    </Helmet>
        <Siteheader/>
        <section className="register-section edit-contact-details h-100 w-100">
        <div className="container h-100 p-xl-5 py-5 bg-f2 position-relative bg-before-after" style={{borderRadius: 10}}>
            <div className="d-flex align-items-center justify-content-center h-100 pb-5 pt-4">
            <div className="d-flex flex-column col-xl-9 col-lg-9 col-md-12 col-12 row">
              <div className='align-items-center'>
                <h1 className='branding1 display-4 font-face-gb-semibold'>Contact</h1>
              </div>
              <div className='row mt-5'>

                  <div className='col-sm mb-md-0 mb-3'>
                    <input type="text" name="website" placeholder="Website" className='rounded-pill align-self-center w-100 px-4 p-3 bg-white border-0' />
                  </div>
                    
                  <div className='col-sm'>
                    <input type="text" name="instagram" placeholder="Instagram Username (@username)" className='rounded-pill align-self-center w-100 px-4 p-3 bg-white border-0' />
                  </div>
                
                </div>

              <div className='row mt-5'>

                  <div className='col-sm mb-md-0 mb-3'>
                    <input type="text" name="facebook" placeholder="Facebook URL" className='rounded-pill align-self-center w-100 px-4 p-3 bg-white border-0' />
                  </div>
                    
                  <div className='col-sm'>
                    <input type="text" name="twitter" placeholder="Twitter Username (@username)" className='rounded-pill align-self-center w-100 px-4 p-3 bg-white border-0' />
                  </div>
                
                </div><div className='row mt-5'>
                  
                  <div className='col-sm mb-md-0 mb-3'>
                    <input type="text" name="clubtown" placeholder="Club Town" className='rounded-pill align-self-center w-100 px-4 p-3 bg-white border-0' />
                  </div>
                  
                  <div className='col-sm'>
                    <input type="text" name="clubcounty" placeholder="Club County" className='rounded-pill align-self-center w-100 px-4 p-3 bg-white border-0' />
                  </div>

                  </div><div className='row mt-5'>
                  
                  <div className='col-sm'>
                    <input type="text" name="clubpostcode" placeholder="Club Postcode" className='rounded-pill align-self-center w-100 px-4 p-3 bg-white border-0' />
                  </div>
              </div>
              <div className='pt-5'>
                <input className="btn pfw-button-green rounded-pill align-self-center px-5 py-2 fs-6 fw-bold" type="submit" name="submit" value="Save" style={{lineHeight: 2}} />
              </div>
            </div>
            </div>
        </div>
        </section>


        <SiteFooter/>
    </>
  )
}

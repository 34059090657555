import React, { Component, useEffect, useState } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { DrawDataSession } from './Cart';
import Moment from 'moment';
import CountdownTimer from './CountdownTimer';
import { GetDrawList } from '../Data/actionMethods';
import { LogoLoader } from './imageLoader';

function SetDrawDetails(p) {
  DrawDataSession.data = p;
}

export default function UpcomingDrawsSlider() {
  const [DrawData, SetDrawData] = useState([]);

  const Redirectable = {
    '744cc8c0-f0d7-4417-be01-3c21a9d65b0b': 'https://beescommunitydraw.com',
    '857148fe-20e1-456f-b6b0-335dfc04aad1': 'https://supportersdraw.com',
    '1cde020d-0a33-4cb4-b6d7-167e7c6c1471': 'https://superhoopsdraw.com',
    'f8ec196d-3ce4-42ab-9bd4-c9c7669fe913': 'https://chelsealuckyblues.com',
    'acbb97ec-5fba-490d-83b2-c1dfd6b4f959': 'https://themightydraw.com',
    '642b4dd2-b393-4144-ab3e-62d2940f70e6': 'https://parkrunforever.com',
    'fc52bb5a-c0d0-479f-b4ed-abd1833d0319': 'https://fulhamflutter.com',
    'a2f3c98f-e60e-4766-b9af-aa00682a1f2b': 'https://nufoundationdraw.com',
    '4ebdcd53-3a47-4562-b848-0c98eb3dfde6': 'https://palacesuperdraw.com',
    '786ba4b3-c961-453d-b665-a9d4aa5fe402': 'https://saintssuperdraw.com'
  };
  const FunderData = {
    '744cc8c0-f0d7-4417-be01-3c21a9d65b0b': ['bees-community-draw-Logo .webp', '#c61d23', '#000'],
    '857148fe-20e1-456f-b6b0-335dfc04aad1': ['supporters-draw-logo.webp', '#cc0000', '#cc0000'],
    '1cde020d-0a33-4cb4-b6d7-167e7c6c1471': ['super-hoops-draw-logo.webp', '#082d53', '#082d53'],
    'f8ec196d-3ce4-42ab-9bd4-c9c7669fe913': ['chelsea-fc-foundation-draw-logo.webp', '#001489', '#001489'],
    'acbb97ec-5fba-490d-83b2-c1dfd6b4f959': ['the-mighty-draw-logo.webp', '#c80550', '#130944'],
    '642b4dd2-b393-4144-ab3e-62d2940f70e6': ['parkrunforever-logo.webp', '#2b233d', '#ffa304'],
    'fc52bb5a-c0d0-479f-b4ed-abd1833d0319': ['Fulham-Foundation_logo.webp', '#c4122e', '#000'],
    'a2f3c98f-e60e-4766-b9af-aa00682a1f2b': ['newcastle-united-foundation-logoTransparent.png', '#1b88c5', '#000000'],
    '4ebdcd53-3a47-4562-b848-0c98eb3dfde6': ['palace-for-life.png', '#db2b27', '#0a417a'],
    '786ba4b3-c961-453d-b665-a9d4aa5fe402': ['SFC_Saints_Foundation_Stacked_White.png', '#ff0028', '#ff0028']
  };

  useEffect(() => {
    GetDrawList().then((res) => {
      // console.log(res.data.content);
      let dt = [];
      let data = res.data.content;
      // console.log(data);
      for (let i = 0; i < data.length; i++) {
        if (!data[i].drawContent) {
          continue;
        }
        
        data[i].drawContent = data[i].drawContent.length == 0 ? [] : JSON.parse(data[i].drawContent);

        console.log('data i', data[i]);
        console.log('data i', data[i].drawContent);
        if (data[i].drawContent.length == 0) {
          // console.log("Here will be undefined",data[i])
          let elm = {};
          elm.id = i;
          elm.drawID = data[i].drawID;
          elm.name = data[i].drawName + ' Need Draw Content';
          elm.price = 5;
          elm.slug = data[i].slug;
          elm.logoUrl = FunderData[data[i].drawID] ? FunderData[data[i].drawID][0] : 'admin.png';
          elm.HeaderImage = data[i].HeaderImage;
          elm.FeaturedUrl = data[i].FeaturedUrl;
          elm.HeaderLeftImage = '';
          elm.BrandingColor1 = FunderData[data[i].drawID] ? FunderData[data[i].drawID][1] : data[i].drawBrandPrimary;
          elm.BrandingColor2 = FunderData[data[i].drawID] ? FunderData[data[i].drawID][2] : data[i].drawBrandSecondary;
          elm.content = '';
          elm.PlayContent = '-';
          elm.drawEndDate = data[i].drawEndDate;
          elm.drawStartDate = data[i].drawStartDate;
          elm.FundContent = data[i].FundContent;
          elm.WinContent = data[i].WinContent;
          elm.ticketCountLabel = data[i].ticketCountLabel;
          elm.subscription = data[i].subscription;
          elm.template = data[i].template;
          elm.prizeSlider = data[i].prizeSlider;
          dt.push(elm);
        } else {
          data[i].drawContent.drawHostID = data[i].drawHostID;
          data[i].drawContent.drawHostCauseID = data[i].drawHostCauseID;
          data[i].drawContent.drawStartDate = data[i].drawStartDate;
          data[i].drawContent.drawEndDate = data[i].drawEndDate;
          data[i].drawContent.drawIsActive = data[i].drawIsActive;
          data[i].drawContent.drawIsActive = data[i].drawIsActive;
          dt.push(data[i].drawContent);
        }
      }
      let activeDraw = dt.filter(
        (item) => Moment() < Moment(item.drawEndDate) && Moment() > Moment(item.drawStartDate)
      );
      SetDrawData(activeDraw);
      // console.log("Draw Data",dt)
    });
  }, []);

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2500,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  return (
    <div>
      <div className="container upcoming-draws-slider mt-5">
        <div className="">
          <Slider {...settings}>
            {DrawData.map((p) => (
              <div className="col-lg-4 col-md-4 col-sm-12 font-face-gb px-2" key={p.id}>
                <div
                  className={`slider-sec mt-5 text-center mb-5 bg-white m-1 shadow-sm draw-id-${p.drawID}`}
                  style={{ borderRadius: 12 }}
                >
                  <div
                    className="colour-left-right"
                    style={{
                      background: `linear-gradient( to right, ${p.BrandingColor1} 50%, ${p.BrandingColor2} 50%)`,
                      height: 23,
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10
                    }}
                  />

                  <div
                    className="draw-logo mx-auto rounded-pill"
                    style={{
                      marginTop: -70,
                      width: 120,
                      height: 120,
                      background: `linear-gradient( to right, ${p.BrandingColor1} 50%, ${p.BrandingColor2} 50%)`,
                      padding: 10
                    }}
                  >
                    <div
                      className="draw-logo-wrap p-2 rounded-pill w-100 h-100"
                      style={{ backgroundColor: `${p.BrandingColor1}`, border: `3px solid #fff` }}
                    >
                      {/* <img className="rounded-circle" src={require(`../assets/images/logo/${p.logoUrl}`)} style={{ width: '100%', height: '100%', objectFit: 'contain' }}></img> */}
                      <LogoLoader
                        className="rounded-circle"
                        src={p.logoUrl}
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                      />
                    </div>
                  </div>
                  <div className="px-4 pb-2">
                    <h4 className="pt-3 fs-5 font-face-gb branding1" style={{ minHeight: 64 }}>
                      {p.name}
                    </h4>
                    <hr className="bg-branding2 mx-auto my-3" style={{ width: 28, height: 3, opacity: 1 }} />
                    <div className="draw-countdown">
                      <p className="branding1">Draw Countdown</p>
                      <div className="date-countdown d-md-flex flex-column gap-3 justify-content-center">
                        <div className="draw-date branding1 m-md-0 m-3">
                          {Moment(p.drawEndDate).format('DD MMMM YYYY')}
                        </div>
                        {p.subscription == false ? (
                          <div className="countdown-timer">
                            <div
                              className="draw-counts speed1"
                              data-time="1664578740"
                              data-lotteryid="12337"
                              data-format="dhm"
                            >
                              <span className="demo12337 branding1 px-3 py-2 rounded-pill border thirbutton-border">
                                <CountdownTimer time={p.drawEndDate}></CountdownTimer>
                              </span>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    {Redirectable[p.drawID] ? (
                      <a
                        href={Redirectable[p.drawID]}
                        target="_blank"
                        className="border-0 rounded-pill btn pfw-button-red secbutton py-2 px-3 my-4 w-100 font-face-gb-semibold"
                        style={{ lineHeight: 2 }}
                      >
                        Enter Draw
                      </a>
                    ) : p.Redirection ? (
                      <a
                        href={p.RedirectionUrl}
                        target="_blank"
                        className="border-0 rounded-pill btn pfw-button-red secbutton py-2 px-3 my-4 w-100 font-face-gb-semibold"
                        style={{ lineHeight: 2 }}
                      >
                        Enter Draw
                      </a>
                    ) : (
                      <Link
                        to={'/draw/' + p.slug}
                        onClick={() => SetDrawDetails(p)}
                        className="border-0 rounded-pill btn pfw-button-red secbutton py-2 px-3 my-4 w-100 font-face-gb-semibold"
                        style={{ lineHeight: 2 }}
                      >
                        Enter Draw
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

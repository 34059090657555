import React, { useState } from 'react'
import { Link, useParams } from "react-router-dom";
import Siteheader from '../components/header';
import SiteFooter from '../components/footer';
import { useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import loading from '../assets/images/loading.gif'
import secureLocalStorage from 'react-secure-storage';
import ImageLoader, { LogoLoader } from '../components/imageLoader';

export default function PaymentSuccess(props) {
  const params = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isValid, setisValid] = useState(false);
  const [isLoggedin, setIsLoggedin] = useState(secureLocalStorage.getItem("LogObject") ? true:false);
  const [transactionContent, setTransactionContent] = useState({});
  const [entriesContent, setEntriesContent] = useState([]);
  const [entryContent, setEntryContent] = useState([]);
  const [entryData, setEntryData] = useState([]);
  const [entriesData, setEntriesData] = useState([]);
  const [activeEntry, setActiveEntry] = useState(0);
  const [tickets, setTickets] = useState('');
  const TransactionID = params.transactionID;
  const [causeName, setCauseName] = useState('');
  const [entryDrawData, setEntryDrawData] = useState([]);
  const userData = secureLocalStorage.getItem('LogObject');

  const WaitForProcess = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/transactions/paymentSuccess/${TransactionID}`, {
      withCredentials: true,
      credentials: "same-origin"
    }).then(res => {
      if (res.data.content.transaction && res.data.content.entries[0]) {
        setisValid(true);
        // console.log("API Response", res.data.content);
        setTransactionContent(res.data.content.transaction);
        setEntriesContent(res.data.content.entries);
        setEntryContent(JSON.parse(res.data.content.entries[0].draw.content.contentBody));
        setEntryData(res.data.content.entries[0]);
        setEntriesData(res.data.content.entries);
        setEntryDrawData(res.data.content?.entries[0]?.draw);
        let t = '';
        {
          res.data.content.entries[0].tickets?.map((e, index) => {
            t += `${e.ticketNumber}${res.data.content.entries[0]?.tickets?.length == index + 1 ? "" : ","}`;
          })
        }

        if (res.data.content.entries[0]) {
          const causeID = res.data.content.entries[0].drawCauseID;
          // Use the causeID as needed
         // console.log('Cause ID from entriesContent:', causeID);
          // ...

                 // console.log("All Entries",causeID);

          //const causeID = entriesContent.drawCauseID;

          const prefixURL = 'https://api.playfundwin.com/api/v1';
          const url = `${prefixURL}/causes/${causeID}`;

          fetch(url)
            .then(response => response.json())
            .then(data => {
              if (data && data.content && data.content.length > 0) {
                const causeName = data.content[0].causeName;
                setCauseName(causeName);
              } else {
                console.log('Cause data not found.');
              }
            })
            .catch(error => {
              console.error('Error fetching causes:', error);
            });

          setCauseName(causeName);
        }

        setTickets(t);
      } else {
        setisValid(false);
        console.log("Invalid URL");
      }
      setIsLoaded(true);
    })
  }, [])

   //console.log("entry Content",entryContent);
   

  async function ChangeDrops(arrayIndex, entriesData) {
    setEntryContent(JSON.parse(entriesContent[arrayIndex].draw.content.contentBody));
    setEntryData(entriesData[arrayIndex]);
    let t = '';
    {
      entriesData[arrayIndex].tickets?.map((e, index) => {
        t += `${e.ticketNumber} ${entriesData[arrayIndex]?.tickets?.length == index + 1 ? "" : ","}`;
      })
    }
    setTickets(t);
  }

  // Data Layer Push for Purchase event
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      transaction_id: TransactionID,
      value: entryData.drawEntryTotal,
      tax: '0',
      shipping: '0',
      currency: 'GBP',
      coupon: '',
      items: [
        {
          item_id: entryDrawData.drawID,
          item_name: entryDrawData.drawName,
          affiliation: 'Online',
          item_brand: 'Sport',
          price: entryData.drawEntryTotal,
          quantity: entryData.drawEntries,
          item_category: entryDrawData.drawCategoryID === 1 ? 'Fixed Cash Prize' : entryDrawData.drawCategoryID === 2 ? 'Split Cash Prize' : entryDrawData.drawCategoryID === 3 ? 'Experiential' : entryDrawData.drawCategoryID === 4 ? 'Hybrid' : '',
          item_variant: entryData.drawEntryType != 1 ? 'Subscription' : 'One-off'
        }
      ],
      item_id: entryDrawData.drawID,
      item_name: entryDrawData.drawName,
      user_id: userData?.userID ? userData?.userID : 'Guest User',
      user_email: userData?.email ? userData?.email : 'Guest User'
    }
  });

  return (
    <>
      <Helmet>
        <title>Thank You - Play Fund Win</title>
        <meta name="description" content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities." />
      </Helmet>
      <Siteheader />
      {!isLoaded ? <>
        <div className='container'>
          <div className='text-center '>
            <img src={loading} className="loader" alt="Loading..." />
          </div>
        </div>
      </> : !isValid ? 
      <>
      <div className='container'>
        <div className='text-center '>
          {/* <img src={loading} className="loader" alt="Loading..." /> */}
          <h2>Invalid Url</h2>
        </div>
      </div>
    </>:

        <div>
          <div className={`container-fluid home-left-side mb-md-0 position-relative thankyou-header-bg draw-id-${entryContent.drawID}`} style={{ background: `linear-gradient(#0e3160a8,#0e3160a8), ${entryContent.HeaderImage.includes("http")
                            ? `url(${entryContent.HeaderImage})`
                            : `url(${require('../assets/images/draws/' + entryContent.HeaderImage)})`
                            }`, paddingTop: 90, paddingBottom: 90, zIndex: 9 }}>
            <div className='container'>
              <div className="row align-items-center mx-auto" style={{ width: '100%' }}>
                <div className="col-md-9 align-middle pb-md-0 pb-4 font-face-gb thank-you-title">
                  <h1>
                    <strong className='font-face-gb-semibold display-1 text-white'>Thank You!</strong></h1>
                    {entryContent.drawID === "9c25d3a4-763b-40e3-bffd-681bcad8e7c6" ? 
                    <h3 className="branding2 fw-normal text-white lh-base">For entering the Manchester City v Inter Milan Champions League Final Draw</h3> 
                     :
                     <h3 className="branding2 fw-normal text-white lh-base">For entering the {entryContent.name}</h3>
                    }
                  {/* <h3 className="branding2 fw-normal text-white lh-base">For entering the {entryContent.name}</h3> */}
                </div>
                <div className="col-md-3 align-middle pb-md-0 pb-4 font-face-gb logo-thank-you position-relative" style={{ marginBottom: -330 }}>
                  <div className='share-icons w-50' style={{ margin: '0 auto', paddingBottom: 30, marginTop: -40 }}>
                    {/* <img src={require('../assets/images/sharethanks.png')} width={'100%'} style={{ paddingBottom: 20 }}></img>
                    <div className='d-flex'>
                      <a href="#" className="mx-1"><img src={require('../assets/images/facebook-thank.png')} width={'100%'}></img></a>
                      <a href="#" className="mx-1"><img src={require('../assets/images/twitter-thank.png')} width={'100%'}></img></a>
                      <a href="#" className="mx-1"><img src={require('../assets/images/linkedin-thank.png')} width={'100%'}></img></a>
                      <a href="#" className="mx-1"><img src={require('../assets/images/whatsapp-thank.png')} width={'100%'}></img></a>
                    </div> */}
                    {/* <div className="social-share-img">
                    </div> */}

                      <img src={require('../assets/images/Group-72-1.png')} width={'100%'} style={{ paddingBottom: 10 }} className="" />
                    <div className="d-flex">

                    {entryContent.drawID === "9c25d3a4-763b-40e3-bffd-681bcad8e7c6" ? 
                      <a href={`http://www.facebook.com/sharer.php?u=https://playfundwin.com/draw/${entryContent.slug}&quote=${entryContent.facebookShareText ? entryContent.facebookShareText : `I have entered the Manchester City v Inter Milan Champions League Final draw to raise essential funds for the NHS and Grassroots sports. Why not join me by buying a ticket here: https://playfundwin.com/draw/${entryContent.slug}. Please do get involved and share the draw with friends, family and colleagues to support this amazing cause! PlayFundWin #Fundraising`}`} target="_blank">
                        <img className="social-share-icon-img mx-1 " src={require('../assets/images/Icon-awesome-facebook-square.png')} />
                      </a>
                      :
                      <a href={`http://www.facebook.com/sharer.php?u=https://playfundwin.com/draw/${entryContent.slug}&quote=${entryContent.facebookShareText ? entryContent.facebookShareText : `I have entered the ${entryContent.name} to raise essential funds for ${causeName}. Why not join me by buying a ticket here: https://playfundwin.com/draw/${entryContent.slug}. Please do get involved and share the draw with friends, family and colleagues to support this amazing cause! PlayFundWin #Fundraising`}`} target="_blank">
                      <img className="social-share-icon-img mx-1 " src={require('../assets/images/Icon-awesome-facebook-square.png')} />
                    </a>
                      }

                      {entryContent.drawID === "9c25d3a4-763b-40e3-bffd-681bcad8e7c6" ? 
                      <a href={`https://twitter.com/share?url=https://playfundwin.com/draw/${entryContent.slug}.&text=${entryContent.twitterShareText ? entryContent.twitterShareText : `I just helped Manchester City v Inter Milan Champions League Final draw to raise essential funds by entering the Manchester City v Inter Milan Champions League Final draw via @playfundwin Why not join me by entering the draw here: https://playfundwin.com/draw/${entryContent.slug}`}`} target="_blank">
                        <img className="social-share-icon-img mx-1 " src={require('../assets/images/Icon-awesome-twitter-square.png')} /></a>
                        :
                        <a href={`https://twitter.com/share?url=https://playfundwin.com/draw/${entryContent.slug}.&text=${entryContent.twitterShareText ? entryContent.twitterShareText : `I just helped ${causeName} to raise essential funds by entering the ${entryContent.name} via @playfundwin Why not join me by entering the draw here: https://playfundwin.com/draw/${entryContent.slug}`}`} target="_blank">
                        <img className="social-share-icon-img mx-1 " src={require('../assets/images/Icon-awesome-twitter-square.png')} /></a>
                      }

                        {entryContent.drawID === "9c25d3a4-763b-40e3-bffd-681bcad8e7c6" ? 
                      <a href={`https://www.linkedin.com/shareArticle?url=https://playfundwin.com/draw/${entryContent.slug}&title=${entryContent.name}&summary ${entryContent.linkedInShareText ? entryContent.linkedInShareText : `I’m helping Manchester City v Inter Milan Champions League Final draw to raise essential funds for the NHS and Grassroots sports. All net proceeds will go to supporting to ${entryContent.name}. You can join me in supporting this brilliant cause by buying a ticket here: https://playfundwin.com/draw/${entryContent.slug}. Please feel free to share. PlayFundWin #Fundraising&source=playfunwin.com`}`} target="_blank">
                        <img className="social-share-icon-img mx-1 " src={require('../assets/images/linkedin-logo.png')} style={{ width: 36 }} /></a>
                        :
                        <a href={`https://www.linkedin.com/shareArticle?url=https://playfundwin.com/draw/${entryContent.slug}&title=${entryContent.name}&summary ${entryContent.linkedInShareText ? entryContent.linkedInShareText : `I’m helping ${entryContent.name} to raise essential funds for ${causeName}. All net proceeds will go to supporting to $${causeName}. You can join me in supporting this brilliant cause by buying a ticket here: https://playfundwin.com/draw/${entryContent.slug}. Please feel free to share. PlayFundWin #Fundraising&source=playfunwin.com`}`} target="_blank">
                        <img className="social-share-icon-img mx-1 " src={require('../assets/images/linkedin-logo.png')} style={{ width: 36 }} /></a>
                        }

                        {entryContent.drawID === "9c25d3a4-763b-40e3-bffd-681bcad8e7c6" ? 
                        <a href={`https://wa.me/?text=${entryContent.whatsappShareText ? entryContent.whatsappShareText : `Hi! I just entered the Manchester City v Inter Milan Champions League Final draw to raise essential funds for the NHS and Grassroots sports! enter here: https://playfundwin.com/draw/man-city-vs-inter-milan. Please do get involved and share the draw with friends, family and colleagues to support these amazing causes!`}`} target="_blank"><img className="social-share-icon-img mx-1 " src={require('../assets/images/Icon-awesome-whatsapp-square.png')} /></a>
                        :
                        <a href={`https://wa.me/?text=${entryContent.whatsappShareText ? entryContent.whatsappShareText : `Hi! I just entered the ${entryContent.name} to raise essential funds for ${causeName}. Your support could make a huge difference, enter here: https://playfundwin.com/draw/${entryContent.slug}. Please do get involved and share the draw with friends, family and colleagues to support this amazing cause!`}`} target="_blank"><img className="social-share-icon-img mx-1 " src={require('../assets/images/Icon-awesome-whatsapp-square.png')} /></a>
                        
                        }

                        
                    </div>
                  </div>
                  <div className={`box-logo bg-white rounded-5 bg-f2 p-5 text-center shadow-sm position-relative pay-draw-id-${entryContent.drawID}`}>
                    <LogoLoader src={entryContent.logoUrl} className="px-2" width={'100%'} />
                  </div>
                </div>
              </div>


            </div>
          </div>
          <div className="container-fluid thank-home home-left-side mb-md-0" style={{ paddingTop: 400, paddingBottom: 90, backgroundColor: '#F2F2F2' }}>
            <div className='container ticket-section'>
              <div className='ticket-data col-md-9' style={{ marginTop: -350 }}>
                <h2 className='branding1 display-3 py-2 font-face-gb-semibold'><strong>You're in the draw!</strong></h2>
                <div className='draws-listing position-relative'>
                  <select onChange={e => ChangeDrops(e.target.value, entriesData)} className="form-select my-4 py-4 px-5 rounded-4 w-75 font-face-gb-semibold display-6">
                    {entriesContent.map((e, index) => {
                      return (
                        <option key={index} value={index}>{JSON.parse(e.draw.content.contentBody).name}</option>
                      )
                    })}
                  </select>
                </div>
                <h3 className="display-6 py-2" style={{ opacity: 0.5 }}>Your ticket numbers are:</h3>
                <h3 className="display-6 branding1 py-2" style={{ lineHeight: 1.5,wordWrap: 'break-word' }}>
                  {tickets}
                </h3>
                <div className='border-bottom py-2 border-dark'></div>
                {entryData.drawEntryTotal === 0 ?
                <h3 className="display-6 py-4" style={{ color: '#8f9193' }}><span className='position-relative thankyou-amount branding3 display-5 font-face-gb-semibold' style={{ opacity: 1 }} ><strong>Free Entry</strong></span></h3>
:
<h3 className="display-6 py-4" style={{ color: '#8f9193' }}>Amount : <span className='position-relative thankyou-amount branding3 display-5 font-face-gb-semibold' style={{ opacity: 1 }} ><strong>£{entryData.drawEntryTotal}</strong></span></h3>
}
                <p className="pb-2 fs-3" style={{ color: '#8f9193' }}>You're in the draw, good luck, fingers crossed you are the lucky winner!</p>
                <div className='border-bottom pb-3 border-dark'></div>
                <p className="py-4 fs-3" style={{ color: '#8f9193' }}>Keep an eye out on your emails and our socials to see if you have won !</p>
                {isLoggedin?
                 <Link to={"/purchase-history"} className="border-0 fs-5 thankyou-button position-relative rounded-pill btn pfw-button-red secbutton py-2 px-3 font-face-gb-semibold" style={{ lineHeight: 2 }}>View yours tickets</Link>
                 :
                 <Link to={"/login"} className="border-0 fs-5 thankyou-button position-relative rounded-pill btn pfw-button-red secbutton py-2 px-3 font-face-gb-semibold" style={{ lineHeight: 2 }}>Login to view your tickets</Link>
                }
              </div>
            </div>
          </div>

        </div>

      }
      <SiteFooter />
    </>
  )
}

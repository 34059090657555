import React,{Component} from 'react'
import Slider from "react-slick";
import { Link } from "react-router-dom";


export default function HeroSlider() {
        var  settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 100,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay:false,
        };  
      return (
        <div className="container-fluid hero-section font-face-gb px-0" style={{backgroundImage: `url(${require(`../assets/images/hero-banner.jpg`)})`}}>
          <Slider {...settings}>
          <div>
            <div className='container hero-first-slide position-relative' style={{paddingTop: 230, paddingBottom: 230, height: 840}}>
                <div className="row align-items-center" >
                <div className="col-md-7 align-middle">
                    <h1 className="text-white font-face-gb-semibold display-4 mb-5">The revolutionary digital fundraising platform that makes raising money simple for sports clubs, events and charities.</h1>
                    <Link to='/host' className="border-0 rounded-pill btn pfw-button-green pributton py-2 ps-5 pe-4 hero-host-btn position-relative" style={{lineHeight: 2.5,fontSize: 18}}>Host a draw</Link>
                    <Link to='/find-a-draw' className="border-0 rounded-pill btn pfw-button-red secbutton py-2 ps-5 pe-4 ms-3 hero-play-btn position-relative" style={{lineHeight: 2.5,fontSize: 18}}>Buy a ticket</Link>
                </div>
                {/* <div className="col-md-5 text-center d-flex align-items-ends"> 
                    <img className='top-player-img' src={require('../assets/images/Component-img1.png')} />
                </div> */}
                </div>
            </div>
            </div>
            
            <div>
            <div className='container hero-second-slide position-relative' style={{paddingTop: 230, paddingBottom: 230, height: 840}}>
                <div className="row align-items-center" >
                <div className="col-md-7 align-middle">
                    <h1 className="text-white font-face-gb-semibold display-4 mb-5">Raise essential funds, with 100% of net proceeds going directly to your cause</h1>
                    <Link to='/host' className="border-0 rounded-pill btn pfw-button-green pributton py-2 ps-5 pe-4 hero-host-btn position-relative" style={{lineHeight: 2.5,fontSize: 18}}>Host a draw</Link>
                    <Link to='/find-a-draw' className="border-0 rounded-pill btn pfw-button-red secbutton py-2 ps-5 pe-4 ms-3 hero-play-btn position-relative" style={{lineHeight: 2.5,fontSize: 18}}>Buy a ticket</Link>
                </div>
                {/* <div className="col-md-5 text-center"> 
                    <img className='top-player-img' src={require('../assets/images/Component-img2.png')} />
                </div> */}
                </div>
            </div>
            </div>

            <div>
            <div className='container hero-third-slide position-relative' style={{paddingTop: 230, paddingBottom: 230,height: 840}}>
                <div className="row align-items-center" >
                <div className="col-md-7 align-middle">
                    <h1 className="text-white font-face-gb-semibold display-4 mb-5">Join our revolutionary fundraising platform</h1>
                    <Link to='/host' className="border-0 rounded-pill btn pfw-button-green pributton py-2 ps-5 pe-4 hero-host-btn position-relative" style={{lineHeight: 2.5,fontSize: 18}}>Host a draw</Link>
                    <Link to='/find-a-draw' className="border-0 rounded-pill btn pfw-button-red secbutton py-2 ps-5 pe-4 ms-3 hero-play-btn position-relative" style={{lineHeight: 2.5,fontSize: 18}}>Buy a ticket</Link>
                </div>
                {/* <div className="col-md-5 text-center"> 
                    <img className='top-player-img' src={require('../assets/images/Component-img3.png')} />
                </div> */}
                </div>
            </div>
            </div>
        </Slider>
        </div>
      );
    }
  
import React from 'react';
import { Link } from 'react-router-dom';
import Siteheader from '../components/header';
import SiteFooter from '../components/footer';
import { UseEffect } from 'react';
import FooterCta from '../components/footer-cta';
import Innerheader from '../components/inner-header';
import { Helmet } from 'react-helmet';

export default function PrizeCompetitionTermsAndConditionsAddtionalClause(props) {
  return (
    <>
      <Helmet>
        ‍<title>Prize Competitions Terms and Conditions Additional Clauses | Play Fund Win</title>‍
        <meta
          name="description"
          content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities."
        />
      </Helmet>
      <Siteheader />
      <Innerheader title="Prize Competition Terms & Conditions Additional Clauses" />

      <div className="container pt-5 pb-5 my-3 px-lg-5 rounded-4 w-92" style={{ backgroundColor: '#f2f2f2' }}>
        <div className="row px-lg-5">
          <div className="col-md-12 align-middle text-left px-lg-5 mt-lg-0 mt-3 term-condition">
          <p><strong>Example additional clauses where the prize is a house, flat or other property.</strong></p>
            <ul>
              <li><p>
              All Entrants are responsible for making their own enquiries and carrying out their own due diligence
              relating to the Prize, their eligibility to enter the competition, their rights and eligibility to take
              ownership of the Prize and their eligibility to enter or live in the country where the Prize is located
              etc. All costs, fees or expenses incurred by the Entrant in making such enquiries or carrying out such due
              diligence shall be the Entrant’s sole responsibility.
            </p></li>
              <li><p>
              The winner of the Prize will be required to enter into a legally binding contract with either the Promoter
              or Supplier in order to transfer the legal title of the Prize to the winner. The terms of the contract are
              non-negotiable. If the winner refuses to sign or is unable to enter into any of the legal documents
              required to transfer ownership of the Prize to the winner within a reasonable time, the winner will be
              disqualified from the competition and the Prize will be forfeited. The Promoter will then be entitled to
              offer the prize to the next eligible Entrant selected from the correct entries that were received before
              the Closing Date.
            </p></li>

            <li>
            <p>
              Due to the nature of the Prize, the date of transfer of ownership cannot be guaranteed but the Promoter
              will take all reasonable steps to complete the transfer of ownership as soon as reasonably possible.
            </p>
            </li>
            </ul>
            

            <p><strong>Example additional clauses where the prize is a holiday</strong></p>
            <ul>
              <li><p>
              The holiday is for [NUMBER OF PEOPLE] to travel by [CLASS OF TRANSPORT AND TYPE OF CARRIER] to [TRAVEL
              DESTINATION] for [NUMBER OF NIGHTS] between [DATE] and [DATE] (excluding between [DATES]). The prize does
              not include travel insurance, the cost of transfers to and from airports or stations, food and drink,
              spending money, tax or personal expenses. Any other costs incurred in addition to those set out above and
              that are incidental to the fulfilment of the prize are the responsibility of the winner(s).</p>
            </li>

            <li>
              <p>You will be responsible for ensuring that you and any person travelling with you are available to travel
              and hold valid passports, any necessary visas and travel documents for the holiday in question on the
              travel dates specified. [Dates once notified to the travel agent cannot be changed].</p>
            </li>
</ul>
            <p><strong>Example additional clauses where the prize is a car or other type of vehicle</strong></p>
            <ul>
              <li><p>If the Prize is a vehicle:</p></li>
              <ul>
              <li><p>the Promoter will, unless otherwise stated, ensure it comes with a valid MOT (if required);</p></li>
              <li>
              <p>no insurance is included with the Prize and it is the Winner’s responsibility to ensure the vehicle is
                adequately insured prior to taking it on the public roads (if it is legal to do so);</p>
              </li>
              <li>
              <p>the Promoter has no responsibility for the Prize once it has been delivered. The Winner is solely
                responsible for complying with all relevant laws and regulations relating to the vehicle, its operation
                and ensuring they operate it in a safe and responsible manner;</p>
              </li>
              <li><p>no vehicle/road tax is included;</p></li>
              <li>
              <p>the Winner is responsible for ensuring they have the necessary licences, qualification, knowledge and
                experience to operate the vehicle safely and legally;</p>
              </li>
              <li>
              <p>the Winner is solely responsible for ensuring they have all necessary safety equipment and clothing (for
                example, helmets, boots and gloves) and for wearing them whilst operating the vehicle.</p>
              </li>
            </ul>
            </ul>
          </div>
        </div>
      </div>

      <FooterCta />
      <SiteFooter />
    </>
  );
}

import FindDrawBg from '../assets/images/find-a-draw-bg.png'
import FindDrawBg1 from '../assets/images/find-a-draw-bg.png'
import FindDrawBg2 from '../assets/images/find-a-draw-bg.png'
import FindDrawBg3 from '../assets/images/find-a-draw-bg.png'
import FindDrawBg4 from '../assets/images/find-a-draw-bg.png'
import InnerHeaderBg from '../assets/images/inner-header-bg.png'
import Logo from '../assets/images/logo.svg'

const Find_a_Draw_Images = {
    FindDrawBg,FindDrawBg1,FindDrawBg2,
}

const Home_Images = {
    FindDrawBg3,FindDrawBg4,Logo
}

const Inner_Header_Images = {
    InnerHeaderBg
}

export {Find_a_Draw_Images,Home_Images,Inner_Header_Images}
import React from 'react';
import { Link } from 'react-router-dom';
import Siteheader from '../components/header';
import SiteFooter from '../components/footer';
import HostSlider from '../components/hostSlider';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import secureLocalStorage from 'react-secure-storage';
import HeroSliderHome from '../components/heroSliderHome';
import LogoBottom from '../assets/images/logo/logoBottom.svg';
import GetDrawList from '../components/getDrawList';
import branding from '../assets/images/branding.png';
import Volunteer from '../assets/images/Component.png';
import whoweare from '../assets/images/who-we.jpg';
import parkrunforever from '../assets/images/parkrunforever.jpg';
import fundIcon from '../assets/images/Fund_icon.png';
import Artboard2 from '../assets/images/Artboard-2.png';
import Artboard1 from '../assets/images/Artboard-1.png';
import Artboard3 from '../assets/images/logoMark-CompoundPathItem.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function Home(props) {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmParams = {
      utm_source: urlParams.get('utm_source'),
      utm_medium: urlParams.get('utm_medium'),
      utm_campaign: urlParams.get('utm_campaign'),
      utm_content: urlParams.get('utm_content')
    };
    secureLocalStorage.setItem('utmParams', JSON.stringify(utmParams));
    console.log('utmParams', utmParams);
  }, []);

  return (
    <div className="homepage">
      <Helmet>
        ‍<title>The revolutionary digital fundraising platform - Play Fund Win</title>‍
        <meta
          name="description"
          content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities."
        />
      </Helmet>
      <Siteheader />

      <div>
        <HeroSliderHome />
        <GetDrawList />
        <div className="container-fluid py-md-0 home-left-side p-0 mb-md-0">
          <div className="py-0">
            <div className="row align-items-center mx-auto" style={{ width: '100%' }}>
              <div className="col-md-6 p-0 align-middle pe-md-5 pb-md-0 px-5 pb-4 font-face-gb">
                <div className="we-are-section px-5 text-left">
                  <img className="mb-3" src={branding} alt="playfundwin brand logo" />
                  <h2 className="branding1 display-6 fw-normal mb-4 pb-2">
                    <strong className="font-face-gb-semibold display-6">We are Play Fund Win</strong>
                  </h2>
                  <h5 className="branding2 fw-normal lh-base fs-3 w-75">
                    The revolutionary digital fundraising platform that makes raising money simple for sports clubs,
                    events and charities.
                  </h5>
                  <Link
                    to={'/host'}
                    className="border-0 rounded-pill btn pfw-button-red secbutton py-3 px-4 mt-4 mb-2 font-face-gb-semibold"
                    style={{ lineHeight: 2 }}
                  >
                    Learn More
                  </Link>
                </div>
              </div>
              <div className="col-md-6 p-0 text-center">
                <div className="position-relative">
                  <LazyLoadImage className="w-100" src={whoweare} style={{ objectFit: 'cover' }} alt="whoweare" />
                  {/* <img className='w-100' src={require('../assets/images/who-we.jpg')} style={{ objectFit: 'cover'}}/> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="what-pfw-section container-fluid pb-0 px-0 mb-0 text-center font-face-gb">
          <div className="" style={{ width: '100%' }}>
            <div className="row align-items-center mx-auto" style={{ width: '100%' }}>
              <div className="col-md-6 p-0 text-center">
                <div className="position-relative">
                  <LazyLoadImage
                    className="w-100"
                    src={parkrunforever}
                    alt="parkrunforever"
                    style={{ objectFit: 'cover' }}
                    width="100%"
                  />
                </div>
              </div>
              <div className="col-md-6 p-0 align-middle pe-md-5 pb-md-0 px-5 pb-4 font-face-gb">
                <div className="we-are-section px-5 text-left" style={{ textAlign: 'left' }}>
                  <img className="mb-3" src={branding} />
                  <h2 className="branding1 display-6 fw-normal mb-4 pb-2">
                    <strong className="font-face-gb-semibold display-6">What is Play Fund Win?</strong>
                  </h2>
                  <h5 className="branding2 fw-normal lh-base fs-3 w-75">
                    It’s time to tear up those paper raffle slips and join the revolution – raise thousands for your
                    club, charity or good cause using our simple online raffle platform.
                  </h5>
                  <p>
                    We make fundraising easy by taking the hassle out of licensing, allowing sports clubs, events and
                    charities of all sizes to raise money. Our draws ensure a minimum of half the profits go to
                    charities orgood causes, and that every draw has a prize.
                  </p>
                  <Link
                    to={'/host'}
                    className="border-0 rounded-pill btn pfw-button-red secbutton py-3 px-4 mt-4 mb-2 font-face-gb-semibold"
                    style={{ lineHeight: 2 }}
                  >
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid py-md-4 text-center secbutton font-face-gb cta-section-two position-relative">
          <div className="container py-5">
            <h2 className="text-white font-face-gb-semibold display-4 w-75 mx-auto mb-4">
              Want to find out more about raising money for your cause?
            </h2>
            <Link
              to={'https://www.playfundwin.com/contact'}
              className="border-0 rounded-pill btn py-3 px-4 my-2 mx-2 font-face-gb-semibold pfw-button-white"
              style={{ lineHeight: 1.9 }}
            >
              Sign-up and we'll be in touch!
            </Link>
            <p className="text-white pt-3">
              Don’t worry, we’ll only message you occasionally about Play Fund Win, <strong>we hate spam too.</strong>
            </p>
          </div>
        </div>

        <div
          className="about-banner position-relative"
          style={{ zIndex: 9, marginTop: '-40px', backgroundColor: '#F2F2F2' }}
        >
          <LazyLoadImage src={Volunteer} alt="volunteer" width="100%" style={{ maxWidth: '100%' }} />
        </div>

        <div className="host-slider-section pb-5 pt-0 rounded-3 font-face-gb" style={{ backgroundColor: '#F2F2F2' }}>
          <div className="py-4 container">
            <div className="row align-items-center">
              <div className="col-md-1 text-center">
                <img
                  className="w-100"
                  src={fundIcon}
                  alt="fund Icon"
                  style={{ width: '100%', height: 50, objectFit: 'contain', objectPosition: 'right' }}
                />
              </div>
              <div className="col-md-10 text-white p-0" style={{ marginLeft: 10 }}>
                {' '}
                <h2 className="branding1 fw-normal display-6">
                  <strong className="fw-normal font-face-gb-semibold">Who can host Play Fund Win draws?</strong>
                </h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center pb-5">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <HostSlider />
            </div>
          </div>
        </div>

        <div className="container-fluid pt-5 pb-4 px-0 how-it-works fon-face-gb" style={{ backgroundColor: '#f2f2f2' }}>
          <div className="container py-5">
            <div className="logo-bottom text-center py-5">
              <Link to={'/'}>
                <img src={LogoBottom} style={{ width: '450px' }} />
              </Link>
            </div>

            <div className="text-center d-flex" style={{ justifyContent: 'center' }}>
              <div className="data-title w-75">
                <h2 className="hiw-title branding1 text-center font-face-gb-semibold fs-2 mb-5">
                  The revolutionary digital fundraising platform that makes raising money simple for sports clubs,
                  events and charities.
                </h2>
              </div>
            </div>

            <div className="row ">
              <div className="col-md-4 px-5">
                <div className="py-3 d-flex align-items-center justify-content-center">
                  <img src={Artboard1} alt="pfw art board logo" />
                  <h2 className="branding1 mn-0 ms-4 font-face-gb-semibold display-4">play</h2>
                </div>
                <p className="text-center">Play a draw by visiting Play Fund Win and finding your favourite cause.</p>
              </div>
              <div className="col-md-4 px-5">
                <div className="py-3 d-flex align-items-center justify-content-center">
                  <img src={Artboard2} alt="pfw art board logo" />
                  <h2 className="text-left branding1 mb-0 ms-4 font-face-gb-semibold display-4">fund</h2>
                </div>
                <p className="text-center">
                  Fund good causes and grassroots sports, as minimum 50% of net proceeds go to charity or good causes.
                </p>
              </div>
              <div className="col-md-4 px-5">
                <div className="py-3 d-flex align-items-center justify-content-center">
                  <img src={Artboard3} alt="pfw art board logo" />
                  <h2 className="text-left branding1 mb-0 ms-4 font-face-gb-semibold display-4">win</h2>
                </div>
                <p className="text-center">
                  The draw host determines what % of the entry fee forms the jackpot prize which is guaranteed to be won
                  on every draw
                </p>
              </div>
            </div>
            <p className="text-center mt-4 pt-2">
              Based on the traditional 50/50 model, contact us to discuss the best split for your fundraising.
            </p>
            <div className="text-center">
              <Link
                to={'https://www.playfundwin.com/contact'}
                className="border-0 rounded-pill btn pfw-button-red secbutton py-3 px-3 mt-4 mb-2 font-face-gb-semibold"
                style={{ lineHeight: 2 }}
              >
                Speak to us
              </Link>
            </div>
          </div>
        </div>

        <div className="container-fluid cta-section-full bg-branding2 font-face-gb position-relative">
          <div className="container py-lg-4 text-center">
            <div className="py-5">
              <h2 className="text-white display-4 font-face-gb-semibold pt-2">Thinking of starting a draw?</h2>
              <p className="text-white my-4">
                The revolutionary digital raffle platform that makes fundraising simple for sports clubs, events and
                charities
              </p>
              <Link
                to="https://www.playfundwin.com/start-fundraising"
                className="border-0 rounded-pill btn bg-white secbutton red-hover py-3 px-4 my-2 mx-2 font-face-gb-semibold"
                style={{ lineHeight: 2 }}
              >
                Start a draw
              </Link>
              <p className="text-white my-4">
                Don’t worry, we’ll only message you occasionally about Play Fund Win, we hate spam too.
              </p>
            </div>
          </div>
        </div>
      </div>
      <SiteFooter />
    </div>
  );
}

import Home from './pages/home';
import Draw from './pages/draw';
import Draws from './pages/draws';
import NotFound from './pages/404';
import AboutUs from './pages/about-us';
import Host2 from './pages/host2';
import Host from './pages/host';
import Find_a_Draw from './pages/find-a-draw';
import Help_centre from './pages/help-centre';
import ScrollToTop from './scrollToTop';
import Checkout from './pages/checkout';
import Login from './pages/login';
import Register from './pages/register';
import WorkWithUs from './pages/work-with-us';
import ComplaintProcedure from './pages/complaints-procedure';
import Legal from './pages/legal';
import IdentityVerificationPolicy from './pages/identity-verification-policy';
import PrizeDrawTermsConditions from './pages/prize-draw-terms-conditions';
import PoolDrawTermsConditions from './pages/pool-draw-terms-conditions';
import PrivacyPolicy from './pages/privacy-policy';
import Blog from './pages/blog';
import BlogDetail from './pages/blog-detail';
import BlogCategory from './pages/blog-category';
import BlogTag from './pages/blog-tag';
import ClubEditContactDetails from './pages/club-edit-contact-details';
import Dashboard from './pages/dashboard';
import DanSchofield from './pages/dan-schofield';
import SteveLightfoot from './pages/steve-lightfoot';
import DeanLynn from './pages/dean-lynn';
import JamesLester from './pages/james-lester';
import MattHill from './pages/matt-hill';
import DavidBroadbent from './pages/david-broadbent';
import SimonRutter from './pages/simon-rutter';
import DavidGray from './pages/david-gray';
import OliviaCork from './pages/olivia-cork';
import EleanorForbes from './pages/eleanor-forbes';
import RaymondGrant from './pages/raymond-grant';
import ChangePassword from './pages/changepassword';
import ForgetPassword from './pages/resetpassword';
import PlayerTermsConditions from './pages/player-terms-and-conditions';
import HostTermsConditions from './pages/host-terms-and-conditions';
import FindDrawForm from './pages/find-a-draw-form';
import CreateDraw from './pages/create-a-draw';
import ClubViewDraws from './pages/club-view-draws';
import DrawsReports from './pages/draws-reports';
import ClubEditProfile from './pages/club-edit-profile';
import EditSponsors from './pages/edit-sponsors';
import PurchaseHistory from './pages/purchase-history';
import Careers from './pages/careers';
import UserEditContactDetails from './pages/user-edit-contact-details';
import BillingPaymentMethods from './pages/billing-payment-methods';
import BillingSubscriptions from './pages/billing-subscriptions';
import UserEditPassword from './pages/user-edit-password';
import AccountEditPassword from './pages/account-edit-password';
import GrassrootsSportsClubs from './pages/grassroots-sports-clubs';
import Charities from './pages/charities';
import PrizesWon from './pages/prizes-won';
import ThankYou from './pages/thankyou';
import HostThankYou from './pages/hostthankyou';
import PaymentSuccess from './pages/payment-success';
// import Cause from './pages/cause';
import DrawCategory from './pages/draw-category';
import Grassroots from './pages/grassroots';
import DraftDrawPage from './pages/draftDraws';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import CookiePolicy from './pages/cookie-policy';
import AcceptableUsePolicy from './pages/acceptable-use-policy';
import PrizeCompetitionTermsConditions from './pages/prize-competition-terms-and-conditions';
import PrizeCompetitionTermsAndConditionsAddtionalClause from './pages/prize-competitions-terms-and-conditions-additional-clauses';
import FAQ from './pages/faq';
import LukeTyler from './pages/luke-tyler';

function SiteRoutes() {
  return (
    <div className="SiteRoutes">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/user-registration" element={<Register />} />
          <Route exact path="/forget-password" element={<ForgetPassword />} />
          <Route exact path="/user-reset-password" element={<ChangePassword />} />
          <Route exact path="/draw/:name" element={<Draws />} />
          <Route exact path="/manage/:name" element={<Draws />} />
          <Route exact path="/draw2/:name" element={<Draws />} />
          <Route exact path="/draw" element={<Draw />} />
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route exact path="/host2" element={<Host2 />} />
          <Route exact path="/host" element={<Host />} />
          <Route exact path="/find-a-draw" element={<Find_a_Draw />} />
          <Route exact path="/help-centre" element={<Help_centre />} />
          <Route exact path="/checkout" element={<Checkout />} />
          <Route exact path="/cart" element={<Checkout />} />
          <Route exact path="/work-with-us" element={<WorkWithUs />} />
          <Route exact path="/complaints-procedure" element={<ComplaintProcedure />} />
          <Route exact path="/identity-verification-policy" element={<IdentityVerificationPolicy />} />
          <Route exact path="/legal" element={<Legal />} />
          <Route exact path="/prize-draw-terms-conditions" element={<PrizeDrawTermsConditions />} />
          <Route exact path="/pool-draw-terms-conditions" element={<PoolDrawTermsConditions />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/blog/:postSlug" element={<BlogDetail />} />
          <Route exact path="/category/:taxanomySlug" element={<BlogCategory />} />
          <Route exact path="/tag/:taxanomySlug" element={<BlogTag />} />
          <Route exact path="/club-edit-contact-details" element={<ClubEditContactDetails />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/dan-schofield" element={<AboutUs />} />
          <Route exact path="/steve-lightfoot" element={<SteveLightfoot />} />
          <Route exact path="/dean-lynn" element={<DeanLynn />} />
          <Route exact path="/james-lester" element={<AboutUs />} />
          <Route exact path="/matt-hill" element={<AboutUs />} />
          <Route exact path="/david-broadbent" element={<AboutUs />} />
          <Route exact path="/simon-rutter" element={<SimonRutter />} />
          <Route exact path="/david-gray" element={<DavidGray />} />
          <Route exact path="/olivia-cork" element={<AboutUs />} />
          <Route exact path="/eleanor-forbes" element={<AboutUs />} />
          <Route exact path="/raymond-grant" element={<RaymondGrant />} />
          <Route exact path="/player-terms-and-conditions" element={<PlayerTermsConditions />} />
          <Route exact path="/host-terms-and-conditions" element={<HostTermsConditions />} />
          <Route exact path="/find-a-draw-form" element={<FindDrawForm />} />
          <Route exact path="/create-a-draw" element={<CreateDraw />} />
          <Route exact path="/club-view-draws" element={<ClubViewDraws />} />
          <Route exact path="/draws-reports" element={<DrawsReports />} />
          <Route exact path="/club-edit-profile" element={<ClubEditProfile />} />
          <Route exact path="/edit-sponsors" element={<EditSponsors />} />
          <Route exact path="/purchase-history" element={<PurchaseHistory />} />
          <Route exact path="/careers" element={<Careers />} />
          <Route exact path="/user-edit-contact-details" element={<UserEditContactDetails />} />
          <Route exact path="/billing-payment-methods" element={<BillingPaymentMethods />} />
          <Route exact path="/billing-subscriptions" element={<BillingSubscriptions />} />
          <Route exact path="/forget-password" element={<UserEditPassword />} />
          <Route exact path="/account-edit-password" element={<AccountEditPassword />} />
          <Route exact path="/grassroots-sports-clubs" element={<GrassrootsSportsClubs />} />
          <Route exact path="/charities" element={<Charities />} />
          <Route exact path="/prizes-won" element={<PrizesWon />} />
          <Route exact path="/payment-success/:transactionID" element={<PaymentSuccess />} />
          <Route exact path="/host-thank-you" element={<HostThankYou />} />
          {/* <Route exact path="/cause" element={<Cause/>} /> */}
          <Route exact path="/draft/:name" element={<DraftDrawPage />} />
          <Route exact path="/draw-category" element={<DrawCategory />} />
          <Route exact path="/thankyou" element={<ThankYou />} />
          <Route exact path="*" element={<NotFound />} />
          <Route exact path="/draw/petrus-community-draw" element={<NotFound />} />
          <Route exact path="/cookie-policy-in-the-website-terms-of-use" element={<CookiePolicy />} />
          <Route exact path="/acceptable-use-policy" element={<AcceptableUsePolicy />} />
          <Route exact path="/prize-competition-terms-and-conditions" element={<PrizeCompetitionTermsConditions />} />
          <Route exact path="/prize-competition-terms-and-conditions-additional-clauses" element={<PrizeCompetitionTermsAndConditionsAddtionalClause />} />
          <Route exact path="/faq" element={<FAQ />} />
          <Route exact path="/grassroots" element={<Grassroots />} />
          <Route exact path="/luke-tyler" element={<LukeTyler />} />
          {/* <Route exact path="/draw/petrus-subscription-draw" element={<NotFound/>} /> */}
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default SiteRoutes;

import React, { useState } from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import grassrootSportTeam from '../assets/images/grassroots-sports-team.png';
import grassrootSportTeamPopup from '../assets/images/grassroots-sports-team-popup.webp';
import professionalTeams from '../assets/images/professional-teams.png';
import professionalTeamsPopup from '../assets/images/professional-teams-popup.webp';
import musicians from '../assets/images/musicians.png';
import musiciansPopup from '../assets/images/musicians-popup.webp';
import venues from '../assets/images/venues.png';
import venuesPopup from '../assets/images/venues-popup.webp';
import eventOrganisers from '../assets/images/event-organisers.png';
import eventOrganisersPopup from '../assets/images/event-organisers-popup.webp';
import schoolYouthGroups from '../assets/images/school-youth-groups.png';
import schoolYouthGroupsPopup from '../assets/images/school-youth-groups-popup.webp';

import { img } from 'react-lazy-load-image-component';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="custom-arrow next-arrow"
      style={{ ...style, display: "block"}}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="custom-arrow prev-arrow"
      style={{ ...style, display: "block"}}
      onClick={onClick}
    />
  );
}
  


export default function HostSlider() {

  const [oneShow, setOneShow] = useState(false);
  const [twoShow, setTwoShow] = useState(false);
  const [threeShow, setThreeShow] = useState(false);
  const [fourShow, setFourShow] = useState(false);
  const [fiveShow, setFiveShow] = useState(false);
  const [sixShow, setSixShow] = useState(false);


        var  settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              dots: false,
              arrows: false,
            }
          },
          {
              breakpoint: 320,
              settings: {
                slidesToShow: 1,
              }
            }
          
        ]
        };  
      return (
        <div>
          <div className="container p-0 w-75 host-slider font-face-gb">
          <Slider {...settings}>
          <div>
              <div className="slider-sec text-start bg-white border-dark-grey position-relative shadow-md bg-white rounded-4 mx-3 mb-2">
                 <img 
                  className="logoban w-100 "
                 src={grassrootSportTeam}
                 alt='grassroot Sport Team'
                 />
                  {/* <img className="logoban w-100 " src={require('../assets/images/grassroots-sports-team.png')} ></img> */}
                  <div className='px-3 py-2 host-data' style={{minHeight: 251}}>
                  <h4 className='pt-4 branding-4 text-start font-face-gb-semibold fw-normal' style={{fontSize: 22}}>Grassroots Sports Teams</h4>
                  <div className="pt-2 text-start  ">
                      <p>Raising funds to offset operating costs and encourage participation in sport.</p>
                  </div>
                  <Button variant="primary" onClick={() => setOneShow(true)} className="modal-btn font-face-gb-semibold">
                    Learn more
                  </Button>

                  <Modal show={oneShow} onHide={() => setOneShow(false)} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                      <div className='row host-ctn-popup'>
                        <div className='col-lg-5 col-md-5 col-sm-12'>
                          <div className='left-img'>
                            <img
                              className="w-100 "
                              src={grassrootSportTeamPopup}
                              alt='grassroot Sport Team Popup'
                            />
                            {/* <img className="w-100 " src={require('../assets/images/grassroots-sports-team-popup.webp')} ></img> */}
                          </div>
                        </div>
                        <div className='col-lg-7 col-md-7 col-sm-12'>
                          <div className="right-content">
                            <h2 className='branding1 font-face-gb-semibold fs-38 lh-1 mt-4'>Grassroots Sports Teams</h2>
                            <p>Raising funds to offset operating costs and encourage participation in sport.</p>
                            <h5 className='bg-branding2 text-light d-inline-block fs-6 font-face-gb-semibold px-2'>Example:</h5>
                            <h3>Rotherham Town Juniors FC (RTJFC)</h3>
                            <ul>
                              <li>RTJFC have 11 teams, ranging from under 7s, to under 17s.</li>
                              <li>The club has 165 members.</li>
                              <li>Draws are staged at every home game with tickets costing £2 to enter.</li>
                              <li>Total entries = <strong>c250 entries per draw</strong><br />
                                (including family, friends and opposition).</li>
                              <li>Total money retained by RTJFC = <strong>c£212.50.</strong></li>
                              <li>Total prize money won per draw = <strong>c£212.50.</strong></li>
                            </ul>
                            <h4>If the club stage 26 draws a year, they would raise £5,525 from Play Fund Win which is enough to cover all pitches, referees, U11s, nets and the majority of new kits.</h4>
                          </div>

                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                  </div>
              </div>
            </div>

            <div>
              <div className="slider-sec text-start bg-white border-dark-grey shadow-md bg-white rounded-4 mx-3 mb-2">
                 <img
                 className='logoban w-100 '
                  src={professionalTeams}
                  alt='professional Teams'
                  />
                  {/* <img className="logoban w-100 " src={require('../assets/images/professional-teams.png')} ></img> */}
                  <div className='px-3 py-2 host-data' style={{minHeight: 251}}>
                  <h4 className='pt-4 branding-4 text-start font-face-gb-semibold fw-normal' style={{fontSize: 22}}>Professional Teams</h4>
                  <div className="pt-2 text-start  ">
                      <p>Raising funds for the clubs charitable foundations or junior academies associated with them.</p>
                  </div>
                  <Button variant="primary" onClick={() => setTwoShow(true)} className="modal-btn font-face-gb-semibold">
                    Learn more
                  </Button>

                  <Modal show={twoShow} onHide={() => setTwoShow(false)} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                      <div className='row host-ctn-popup'>
                        <div className='col-lg-5 col-md-5 col-sm-12'>
                          <div className='left-img'>
                            <img
                              className="w-100 "
                              src={professionalTeamsPopup}
                              alt='professional Teams Popup'
                            />
                            {/* <img className="w-100 " src={require('../assets/images/professional-teams-popup.webp')} ></img> */}
                          </div>
                        </div>
                        <div className='col-lg-7 col-md-7 col-sm-12'>
                          <div className="right-content">
                            <h2 className='branding1 font-face-gb-semibold fs-38 lh-1 mt-4'>Professional Teams</h2>
                            <p><strong>Raising funds for the club’s charitable foundation or junior academy.</strong></p>
                            <h5 className='bg-branding2 text-light d-inline-block fs-6 font-face-gb-semibold px-2'>Example:</h5>
                            <h3>Manchester United</h3>
                            <ul>
                              <li>Manchester United’s average home attendance is <strong>c70,000</strong> at every match.</li>
                              <li>In this example <strong>20% of the crowd purchase a £5 ticket for a draw staged at half time.</strong></li>
                              <li>Total entries = <strong>c14,000.</strong></li>
                              <li>Total money retained by Manchester United Foundation. =<strong> c£32,375.</strong></li>
                              <li>Total prize money won by somebody in the Stadium = <strong>c£32,375.</strong></li>
                            </ul>
                            <h4>If the club stages 19 draws a year (at all Premier League home games), they would raise £565,250 from Play Fund Win per season… and that is just from people in the stadium. With the global reach of Manchester United their entries-per-draw could be significantly higher.</h4>
                          </div>

                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                  </div>
              </div>
            </div>

            <div>
              <div className="slider-sec text-start bg-white border-dark-grey shadow-md bg-white rounded-4 mx-3 mb-2">
                  <img
                  className='logoban w-100'
                  src={musicians}
                  alt='musicians'
                  />
                  {/* <img className="logoban w-100 " src={require('../assets/images/musicians.png')} ></img> */}
                  <div className='px-3 py-2 host-data' style={{minHeight: 251}}>
                  <h4 className='pt-4 branding-4 text-start font-face-gb-semibold fw-normal' style={{fontSize: 22}}>Musicians</h4>
                  <div className="pt-2 text-start  ">
                      <p>Raising funds for charities they are patrons of, or that they want to support.</p>
                  </div>
                  <Button variant="primary" onClick={() => setThreeShow(true)} className="modal-btn font-face-gb-semibold">
                    Learn more
                  </Button>

                  <Modal show={threeShow} onHide={() => setThreeShow(false)} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                      <div className='row host-ctn-popup'>
                        <div className='col-lg-5 col-md-5 col-sm-12'>
                          <div className='left-img'>
                          <img
                              className="w-100 "
                              src={musiciansPopup}
                              alt='musicians Popup'
                            />
                            {/* <img className="w-100 " src={require('../assets/images/musicians-popup.webp')} ></img> */}
                          </div>
                        </div>
                        <div className='col-lg-7 col-md-7 col-sm-12'>
                          <div className="right-content">
                            <h2 className='branding1 font-face-gb-semibold fs-38 lh-1 mt-4'>Musicians</h2>
                            <p>Looking to raise funds for charities they want to support.</p>
                            <h5 className='bg-branding2 text-light d-inline-block fs-6 font-face-gb-semibold px-2'>Example:</h5>
                            <h3>Ed Sheeran Tour</h3>
                            <ul>
                              <li>Ed Sheeran’s 2018 UK tour was attended by 1,000,000 people.</li>
                              <li>He played 20 nights in 6 cities (avg. 50,000 per night).</li>
                              <li>In this example, a draw is staged each night with each entry being £10. 25% of attendees purchase a ticket.</li>
                              <li>Ed will nominate a local charity of his choice. The chosen charity will be the beneficiaries of that nights funds raised.</li>
                              <li>Total entries per night – <strong>12,500 (total revenue per night – £125,000).</strong></li>
                              <li>Prize won by somebody in the stadium, per night – <strong>£53,125.</strong></li>
                              <li>Funds raised for Ed’s charities, per night – <strong>£53,125.</strong></li>
                              <li><strong>Total funds raised for Ed’s charities across the tour</strong><br />
                                <strong> – £1,062,500. </strong></li>
                            </ul>
                            <h4>Somebody in the crowd wins £53,125 every night!</h4>
                          </div>

                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                  </div>
              </div>
            </div>

            <div>
              <div className="slider-sec text-start bg-white border-dark-grey shadow-md bg-white rounded-4 mx-3 mb-2">
                <img
                  className='logoban w-100 '
                  src={venues}
                  alt='venues'
                  />

                  {/* <img className="logoban w-100 " src={require('../assets/images/venues.png')} ></img> */}
                  <div className='px-3 py-2 host-data' style={{minHeight: 251}}>
                  <h4 className='pt-4 branding-4 text-start font-face-gb-semibold fw-normal' style={{fontSize: 22}}>Venues</h4>
                  <div className="pt-2 text-start  ">
                      <p>Raising funds to support local good causes.</p>
                  </div>
                  <Button variant="primary" onClick={() => setFourShow(true)} className="modal-btn font-face-gb-semibold">
                    Learn more
                  </Button>

                  <Modal show={fourShow} onHide={() => setFourShow(false)} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                      <div className='row host-ctn-popup'>
                        <div className='col-lg-5 col-md-5 col-sm-12'>
                          <div className='left-img'>
                            <img
                              className="w-100 "
                              src={venuesPopup}
                              alt='venues Popup'
                              />
                            {/* <img className="w-100 " src={require('../assets/images/venues-popup.webp')} ></img> */}
                          </div>
                        </div>
                        <div className='col-lg-7 col-md-7 col-sm-12'>
                          <div className="right-content">
                            <h2 className='branding1 font-face-gb-semibold fs-38 lh-1 mt-4'>Venues</h2>
                            <p>That want to raise funds to support local good causes. </p>
                            <h5 className='bg-branding2 text-light d-inline-block fs-6 font-face-gb-semibold px-2'>Example:</h5>
                            <h3>Manchester Arena (ASM Global) </h3>
                            <ul>
                              <li>Manchester Arena welcomes c1,000,000 paying visitors annually. </li>
                              <li>In this example, 25% of attendees take part, paying £5 each to enter. </li>
                              <li>Manchester Arena nominates a number of charities which it wishes to support (on some occasions they would also let the artist playing nominate charities too).</li>
                              <li>Total entries at Manchester Arena draws per year = <strong>250,000.</strong></li>
                              <li>Total entry fees = <strong> £1,250,000.</strong></li>
                              <li><strong>Total monies for good causes = £531,250. </strong></li>
                              <li><strong>If done at 100 events per year, somebody in the arena walks away with over £5,000 per night. </strong></li>
                            </ul>
                          </div>

                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                  </div>
              </div>
            </div>

            <div>
              <div className="slider-sec text-start bg-white border-dark-grey shadow-md bg-white rounded-4 mx-3 mb-2">
                 <img
                  className='logoban w-100 '
                  src={eventOrganisers}
                  alt='event Organisers'
                  />

                  {/* <img className="logoban w-100 " src={require('../assets/images/event-organisers.png')} ></img> */}
                  <div className='px-3 py-2 host-data' style={{minHeight: 251}}>
                  <h4 className='pt-4 branding-4 text-start font-face-gb-semibold fw-normal' style={{fontSize: 22}}>Event Organisers</h4>
                  <div className="pt-2 text-start  ">
                      <p>Using the events they run as a vehicle for raising funds for local charities they support.</p>
                  </div>
                  <Button variant="primary" onClick={() => setFiveShow(true)} className="modal-btn font-face-gb-semibold">
                    Learn more
                  </Button>

                  <Modal show={fiveShow} onHide={() => setFiveShow(false)} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                      <div className='row host-ctn-popup'>
                        <div className='col-lg-5 col-md-5 col-sm-12'>
                          <div className='left-img'>
                           <img
                              className="w-100 "
                              src={eventOrganisersPopup}
                              alt='event Organisers Popup'
                              />

                            {/* <img className="w-100 " src={require('../assets/images/event-organisers-popup.webp')} ></img> */}
                          </div>
                        </div>
                        <div className='col-lg-7 col-md-7 col-sm-12'>
                          <div className="right-content">
                            <h2 className='branding1 font-face-gb-semibold fs-38 lh-1 mt-4'>Event Organisers</h2>
                            <p>Raising funds to support amazing charities. </p>
                            <h5 className='bg-branding2 text-light d-inline-block fs-6 font-face-gb-semibold px-2'>Example:</h5>
                            <h3>Glastonbury Festival</h3>
                            <ul>
                              <li>Glastonbury Festival welcomes c200,000 paying visitors annually and supports a wide range of charities, including Oxfam and Water Aid. Each day the nominated charity would host the draw.</li>
                              <li>In this example, the entry fee for the Glastonbury Festival draw would be £10, and 25% of attendees take part.</li>
                              <li>Total entries = <strong>50,000.</strong></li>
                              <li>Total entry fees = <strong>£500,000.</strong></li>
                              <li>Total monies for good causes = <strong>£425,000.</strong></li>
                              <li><strong>Total prize fund to be won by festival attendees = £425,000. </strong></li>
                            </ul>
                          </div>

                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                  </div>
              </div>
            </div>

            <div>
              <div className="slider-sec text-start bg-white border-dark-grey shadow-md bg-white rounded-4 mx-3 mb-2">
                  <img
                  className='logoban w-100 '
                  src={schoolYouthGroups}
                  alt='school Youth Groups'
                  />

                  {/* <img className="logoban w-100 " src={require('../assets/images/school-youth-groups.png')} ></img> */}
                  <div className='px-3 py-2 host-data' style={{minHeight: 251}}>
                  <h4 className='pt-4 branding-4 text-start font-face-gb-semibold fw-normal' style={{fontSize: 22}}>Schools and Youth Groups</h4>
                  <div className="pt-2 text-start  ">
                      <p>Organisations who want to host digital draws to raise vital funds.</p>
                  </div>
                  <Button variant="primary" onClick={() => setSixShow(true)} className="modal-btn font-face-gb-semibold">
                    Learn more
                  </Button>

                  <Modal show={sixShow} onHide={() => setSixShow(false)} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                      <div className='row host-ctn-popup'>
                        <div className='col-lg-5 col-md-5 col-sm-12'>
                          <div className='left-img'>
                            <img
                              className="w-100 "
                              src={schoolYouthGroupsPopup}
                              alt='school Youth Groups Popup'
                              />

                            {/* <img className="w-100 " src={require('../assets/images/school-youth-groups-popup.webp')} ></img> */}
                          </div>
                        </div>
                        <div className='col-lg-7 col-md-7 col-sm-12'>
                          <div className="right-content">
                            <h2 className='branding1 font-face-gb-semibold fs-38 lh-1 mt-4'>Youth Organisations</h2>
                            <p>Helping youth groups raise vital funds to support their incredible work with young people.</p>
                            <h5 className='bg-branding2 text-light d-inline-block fs-6 font-face-gb-semibold px-2'>Example:</h5>
                            <h3>Girlguiding UK</h3>
                            <ul>
                              <li>Girlguiding UK has over <strong>500,000 paying members.</strong> For administrative purposes, the country is split in to 6 regions.</li>
                              <li>For this example, each region hosts a monthly draw, which gets <strong>50,000 entries @ £5 per entry.</strong></li>
                              <li>Regional revenue, per month = <strong>£250,000.</strong></li>
                              <li>Total monthly revenue (multiplied by 6 regions) = <strong>£1.5m per month.</strong></li>
                              <li>Total monies for good causes = <strong>£637,500.</strong></li>
                              <li>Total monies retained by Girlguiding UK per month = <strong>£637,500.</strong></li>
                              <li>Annual amount raised for Girlguiding UK = <strong>£637,500.</strong></li>
                            </ul>
                          </div>
                          
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                  </div>
              </div>
            </div>

            
          </Slider>
        </div>
        </div>
      );
    }
  
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Breadcrumb = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(segment => segment !== '');

  const defaultCrumbs = [
    { label: 'Home', path: '/' },
    { label: 'My Account', path: '/dashboard' },
  ];

  const dynamicCrumbs = pathSegments.map((segment, index) => {
    const path = `/${pathSegments.slice(0, index + 1).join('/')}`;
    return { label: segment, path, isActive: index === pathSegments.length - 1 };
  });

  const breadcrumbs = [...defaultCrumbs, ...dynamicCrumbs];

  return (
    <div className="breadcrumb">
      {breadcrumbs.map((crumb, index) => (
        <React.Fragment key={index}>
          {index > 0 && <span className="breadcrumb-separator">/</span>}
          {crumb.path ? (
            <Link
              to={crumb.path}
              className={`breadcrumb-link branding1 mx-1 text-decoration-none ${crumb.isActive ? 'active' : ''}`}
            >
              {crumb.label.replace(/-/g, ' ').replace(/\b\w/g, c => c.toUpperCase())}
            </Link>
          ) : (
            <span className={`breadcrumb-label branding1 mx-1 text-decoration-none ${crumb.isActive ? 'active' : ''}`}>
              {crumb.label.replace(/-/g, ' ').replace(/\b\w/g, c => c.toUpperCase())}
            </span>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Breadcrumb;

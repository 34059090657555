import React from 'react';
import StripeCheckout from 'react-stripe-checkout';
import { useCart } from "react-use-cart";
import axios from "axios";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import secureLocalStorage from 'react-secure-storage';
import { useNavigate, Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import StripeForm from './StripeForm';
import { Elements, useElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Modal from 'react-bootstrap/Modal';
import Moment from "moment";
import { LogoLoader } from "./imageLoader";
const stripePromise = loadStripe(process.env.REACT_APP_API_STRIPE_PK_KEY);

export const MyCart = React.createContext(0);
export const DrawDataSession = React.createContext(0);
export const HomeDrawDataSession = React.createContext(0);
export const BlogDataSession = React.createContext(0);
export function Cart(props) {

  let userDetail = secureLocalStorage.getItem("LogObject");
  let APIURL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [isStripe, setStripe] = useState(false);
  const [oneShow, setOneShow] = useState(false);
  const [productRemoveMessage, SetProductRemoveMessage] = useState([])
  const {
    isEmpty,
    totalUniqueItems,
    items,
    cartTotal,
    updateItemQuantity,
    removeItem,
    emptyCart,
  } = useCart();


  items.forEach(element => {
    if (element.subscription) {
      // console.log('Subscription');
    }
  });
  // console.log("items", totalUniqueItems, cartTotal,items)

  useEffect(() => {
    let removeITeam = [];
    let expireDraw = items.filter(item => (Moment() > Moment(item.drawEndDate) || Moment() < Moment(item.drawStartDate)) && item.subscription === false)
    //let expireDraw = items
    let message = [];
    for (let i = 0; i < expireDraw.length; i++) {
      message.push(expireDraw[i].name)
      removeItem(expireDraw[i].id);
    }
    if (expireDraw.length > 0) {
      // console.log(message)
      SetProductRemoveMessage(message)
      setTimeout(function () {
        SetProductRemoveMessage([])
      }, 7000)
    }

    // console.log("Expired Draw", expireDraw)
    //removeItem(item.id)

  }, [])

  const [product] = useState({
    name: props.name,
    price: cartTotal,
    description: items.map(e => e.name).join(', '),
    metadata: { orderid: 1234, Total_Order_Price: cartTotal, Cust_Name: props.name },
    data: items
  });

  function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  if (isEmpty) return (

    <div className="col-md-5 bg-f2 order-md-2 py-5 mb-4">
      <h4 className="d-flex justify-content-center align-items-center mb-3">
        <span className="text-muted text-center">You have no tickets in your checkout. <br /><Link to={'/find-a-draw'} className='branding3 branding3-hover'>Find a Draw</Link></span>
      </h4>
    </div>
  );

  async function handleToken(token, addresses) {
    try {

      if (userDetail || !userDetail) {

        let log = secureLocalStorage.getItem('LogObject') ? secureLocalStorage.getItem('LogObject') : [];

        //"transactionTypeID":props.period=='year'?4:3,
        const response = await toast.promise(axios.post(
          `${process.env.REACT_APP_API_URL}/transactions/stripe/checkout`,
          {
            "transactionUserID": log.userID ? log.userID : "",
            "transactionSiteID": '54efd01b-3d32-4cab-a7e0-f7fbc783e0d0',
            "transactionTypeID": items.length == 1 ? items[0].subscription ? 3 : 2 : 3,
            "transactionItems": items.map(el => {
              let elm = {};
              elm.drawID = el.drawID
              elm.drawCauseID = el.drawHostCauseID
              elm.ticketPrice = el.price
              elm.ticketQuantity = el.quantity
              elm.isSubscription = el.subscription
              return elm
            })
            ,
            "product":
              items.map(el => {
                let elm = {}
                elm.name = el.name
                elm.price = el.price
                elm.description = el.name
                elm.metadata = {
                  email: props.email
                }
                elm.data = [{
                  id: el.drawID,
                  name: el.name,
                  drawID: el.drawID,
                  drawCauseID: el.drawHostCauseID,
                  price: el.price,
                  quantity: el.quantity,
                  slug: el.slug,
                  subscription: el.subscription,
                  itemTotal: el.itemTotal,
                }]
                return elm

              })
            ,
            token
          },
          {
            withCredentials: true,
            credentials: "same-origin"
          }
        ), {
          pending: 'Please wait...',
          error: 'Payment Failed'
        }
        );

        if (response.data.content.status === 'success') {
          toast("Payment Done Successfully", { type: "success" });
          emptyCart();
          await timeout(2000);
          navigate('/dashboard');
        } else {
          toast("Something went wrong", { type: "error" });
        }

      } else {
        //register
        //checkout
      }

    } catch (error) {
      // console.log(error);
      toast.error(`${error.message} Node Not found`);
    }
  }
  function validateCheck() {
    // console.log(props)
    if (props.email.trim().length === 0) {
      toast.error(`Please Enter Your Email`)
      setStripe(false);
    } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(props.email)) {
      toast.error(`Invalid Email`)
      setStripe(false);
    } else if (props.name.trim().length === 0) {
      setStripe(false);
      toast.error(`Please Enter Your Name`)
    } else if (props.address.trim().length === 0) {
      setStripe(false);
      toast.error(`Please Enter Your Address`)
    } else if (props.city.trim().length === 0) {
      setStripe(false);
      toast.error(`Please Enter Your City`)
    } else if (props.postcode.trim().length === 0) {
      setStripe(false);
      toast.error(`Please Enter Your Postcode`)
    } else if (props.userPhoneNumber.trim().length === 0) {
      setStripe(false);
      toast.error(`Please Enter Your Phone Number`)
    } else if (!props.consent3) {
      toast("Please confirm you are over 18 and agree with our terms and conditions", { type: "error" });
    } else {
      setStripe(true);
      setOneShow(true);
    }

  }
  function hideModal() {
    setOneShow(false);
  }
  // console.log("ITEM", items)
  // console.log(items[0].subscription)
  return (
    <>
      {window.innerWidth <= 767 ?
        <>
          {isStripe && props.email != '' && props.name.trim().length != 0 && /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(props.email) ?
            oneShow ? <></> : <button className="pfw-button-red rounded-4 px-3 w-100 py-3" onClick={() => validateCheck()}>Buy Tickets</button>
            : oneShow ? <></> : <button className="pfw-button-red rounded-4 px-3 w-100 py-3" onClick={() => validateCheck()}>Buy Tickets</button>
          }
          <Accordion className='p-0' >
            <Accordion.Item eventKey="0">
              <Accordion.Header >Show Order Summary</Accordion.Header>
              <Accordion.Body>
                <div className="col-md-5 bg-f2 order-md-2 py-5 mb-4">
                  <h4 className="d-flex bg-f2 justify-content-between align-items-center mb-3">
                    {/* <span className="text-muted">Your cart ({totalUniqueItems})</span> */}
                  </h4>

                  {productRemoveMessage.map(item => {
                    return <li>{item} is Removed from Cart</li>
                  })}
                  <ul className="list-group mb-3">
                    {items.map((item) => (
                      <li key={item.id} id={`draw-${item.id}`} className="bg-f2 border-0 border-bottom list-group-item d-flex justify-content-between lh-condensed cart-layout">
                        <div className='d-flex cart-details'>
                          <div className='float-start cart-logoss d-flex p-2 rounded-4' style={{ backgroundColor: `${item?.DrawData?.BrandingColor1}` }}>
                            <LogoLoader className='object-contain' style={{ height: '70px', width: '70px' }} src={item.Drawlogo} alt="" />
                          </div>
                          <div className='cart-data position-relative'>
                          <h6 className="d-flex px-3 pb-1 my-0">{item.name}</h6>
                         
                          { item.isCampaign ? 
                          <div className='disable-quantity'>
                            <p className="mb-0 top-promotion-checkout fs-6 text-white" style={{marginLeft : 15}}> <img
                                                    src={require("../assets/images/playicon.png")} style={{marginTop : -3,marginRight : 3}}
                                                    width="16px"
                                                /> 2for1 Offer</p>
                          <div className='d-flex flex-column'>
                           <small className="text-muted px-3">Ticket Price {item.price} </small>
                           <small className="text-muted px-3">{item.subscription ? "Subscription £" + item.price * item.quantity + "/Month" : ''} </small>
                          </div>
                         <span className='float-start p-2'>
                           {item.DrawData?.ticketCountLabel?.split(",")[0] < item.quantity ?
                             <button disabled={true} className='btn-sm border-1 border mx-2'
                               onClick={() => updateItemQuantity(item.id, item.quantity - 1)}>
                               -
                             </button> : <button disabled={true} className='btn-sm border-1 border mx-2 disabled' data-toggle="tooltip" data-placement="top" title="Minimum tickets buy">
                               -
                             </button>
                           }
                           <small className="text-muted  "> {item.quantity}</small>
                           <button disabled={true} className='btn-sm border-1 border mx-2'
                             onClick={() => updateItemQuantity(item.id, item.quantity + 1)}>
                             +
                           </button>
                         </span>
                         </div>  : item.isBundle ?
                          <>
                           <div className='d-flex flex-column'>
                            <small className="text-muted px-3">Ticket Price {item.price} </small>
                            <small className="text-muted px-3">{item.subscription ? "Subscription £" + item.price + "/Month" : ''} </small>
                           </div>
                          <span className='float-start p-2'>
                            {item.DrawData?.ticketCountLabel?.split(",")[0] < item.quantity ?
                              <button className='btn-sm border-1 border mx-2'
                                disabled={true}

                                // onClick={() => updateItemQuantity(item.id, item.bundleQuantity - 1)}
                                >
                                -
                              </button> : <button className='btn-sm border-1 border mx-2 disabled' data-toggle="tooltip" data-placement="top" title="Minimum tickets buy">
                                -
                              </button>
                            }
                            <small className="text-muted  "> {item.bundleQuantity}</small>
                            <button className='btn-sm border-1 border mx-2'
                              disabled={true}

                              // onClick={() => updateItemQuantity(item.id, item.bundleQuantity + 1)}
                              >
                              +
                            </button>
                          </span>
                          </>
                          :
                          <>
                           <div className='d-flex flex-column'>
                            <small className="text-muted px-3">Ticket Price {item.price} </small>
                            <small className="text-muted px-3">{item.subscription ? "Subscription £" + item.price * item.quantity + "/Month" : ''} </small>
                           </div>
                          <span className='float-start p-2'>
                            {item.DrawData?.ticketCountLabel?.split(",")[0] < item.quantity ?
                              <button className='btn-sm border-1 border mx-2'
                                onClick={() => updateItemQuantity(item.id, item.quantity - 1)}>
                                -
                              </button> : <button className='btn-sm border-1 border mx-2 disabled' data-toggle="tooltip" data-placement="top" title="Minimum tickets buy">
                                -
                              </button>
                            }
                            <small className="text-muted  "> {item.quantity}</small>
                            <button className='btn-sm border-1 border mx-2'
                              onClick={() => updateItemQuantity(item.id, item.quantity + 1)}>
                              +
                            </button>
                          </span>
                          </>
                          }
                          </div>

                        </div>

                        {
                  item.isBundle ?
                <span className=" text-muted mx-2 float-end ">
                £{ item.price}
                  <button className='border d-flex bg-f2 rounded-circle border-1 text-muted  mt-2 mb-2 align-items-center' style={{ width: '20px', height: '20px' }} onClick={() => removeItem(item.id)}>&times;</button>
                </span>
                :
                <span className=" text-muted mx-2 float-end ">
                £{ item.price * item.quantity}
                  <button className='border d-flex bg-f2 rounded-circle border-1 text-muted  mt-2 mb-2 align-items-center' style={{ width: '20px', height: '20px' }} onClick={() => removeItem(item.id)}>&times;</button>
                </span>
}


                      </li>
                    ))}

                    <li className="bg-f2 border-0 fs-4 border-top list-group-item d-flex justify-content-between">
                      <span>Total   </span>
                      <span className='fw-semibold' >£{cartTotal}</span>
                    </li>
                  </ul>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </>
        :

        <div className="col-md-5 bg-f2 order-md-2 py-5 mb-4">
          <h4 className="d-flex bg-f2 justify-content-between align-items-center mb-3">
            {/* <span className="text-muted">Your cart ({totalUniqueItems})</span> */}
          </h4>
          <ul className="list-group mb-3">
            {productRemoveMessage.map(item => {
              return <li key={item}
                style={{ "marginLeft": "90px", "fontWeight": "bold", "color": "#a63e3e" }} id={`draw-${item.id}`}
                className="bg-f2 border-0 border-bottom list-group-item d-flex justify-content-between lh-condensed cart-layout">{item} is Removed from Cart</li>
            })}

          </ul>
          <ul className="list-group mb-3">
            {items.map((item) => (
              <li key={item.id} id={`draw-${item.id}`} className="bg-f2 border-0 border-bottom list-group-item d-flex justify-content-between lh-condensed cart-layout">
                <div className='d-flex cart-details'>
                  <div className='logo-cart-data'>
                  <div className='float-start cart-logo cart-logoss d-flex p-2 rounded-4' style={{ backgroundColor: `${item?.DrawData?.BrandingColor1}` }}>
                    {item.Drawlogo != '' ?
                      <LogoLoader className='object-contain' style={{ height: '70px', width: '70px' }} src={item.Drawlogo} alt="" /> : ""
                    }
                  </div>
                  </div>
                  <div className='cart-data position-relative'>

                  
                  <h6 className="d-flex px-3 pb-1 my-0">{item.name}</h6>
                  
                  { item.isCampaign ? 
                  
                  <div className='disable-quantity'>
                    <p className="mb-0 top-promotion-checkout fs-6 text-white" style={{marginLeft : 15}}> <img
                                                    src={require("../assets/images/playicon.png")} style={{marginTop : -3,marginRight : 3}}
                                                    width="18px"
                                                /> 2for1 Offer</p>
                  <div className='d-flex flex-column'>
                  <small className="text-muted px-3">Ticket Price {item.price} </small>
                  <small className="text-muted px-3">{item.subscription ? "Subscription £" + item.price * item.quantity + "/Month" : ''} </small>
                </div>
                  <span className='float-start p-2'>
                    {item.DrawData?.ticketCountLabel?.split(",")[0] < item.quantity ?
                      <button disabled={true} className='btn-sm border-1 border mx-2'
                        onClick={() => updateItemQuantity(item.id, item.quantity - 1)}>
                        -
                      </button> : <button disabled={true} className='btn-sm border-1 border mx-2 disabled' data-toggle="tooltip" data-placement="bottom" title="Minimum tickets buy">
                        -
                      </button>
                    }
                    <small className="text-muted  "> {item.quantity}</small>
                    <button disabled={true} className='btn-sm border-1 border mx-2'
                      onClick={() => updateItemQuantity(item.id, item.quantity + 1)}>
                      +
                    </button>
                  </span>
                  </div>
                   : item.isBundle ?
                   <>
                    <div className='d-flex flex-column'>
                     <small className="text-muted px-3">Ticket Price {item.price} </small>
                     <small className="text-muted px-3">{item.subscription ? "Subscription £" + item.price + "/Month" : ''} </small>
                    </div>
                   <span className='float-start p-2'>
                     {item.DrawData?.ticketCountLabel?.split(",")[0] < item.quantity ?
                       <button className='btn-sm border-1 border mx-2'
                       disabled={true}
                         // onClick={() => updateItemQuantity(item.id, item.bundleQuantity - 1)}
                         >
                         -
                       </button> : <button disabled={true} className='btn-sm border-1 border mx-2 disabled' data-toggle="tooltip" data-placement="top" title="Minimum tickets buy">
                         -
                       </button>
                     }
                     <small className="text-muted  "> {item.bundleQuantity}</small>
                     <button className='btn-sm border-1 border mx-2'
                       disabled={true}
 
                       // onClick={() => updateItemQuantity(item.id, item.bundleQuantity + 1)}
                       >
                       +
                     </button>
                   </span>
                   </>
                  :
                  <>
                  <div className='d-flex flex-column'>
                  <small className="text-muted px-3">Ticket Price {item.price} </small>
                  <small className="text-muted px-3">{item.subscription ? "Subscription £" + item.price * item.quantity + "/Month" : ''} </small>
                </div>
                  <span className='float-start p-2'>
                    {item.DrawData?.ticketCountLabel?.split(",")[0] < item.quantity ?
                      <button className='btn-sm border-1 border mx-2'
                        onClick={() => updateItemQuantity(item.id, item.quantity - 1)}>
                        -
                      </button> : <button className='btn-sm border-1 border mx-2 disabled' data-toggle="tooltip" data-placement="bottom" title="Minimum tickets buy">
                        -
                      </button>
                    }
                    <small className="text-muted  "> {item.quantity}</small>
                    <button className='btn-sm border-1 border mx-2'
                      onClick={() => updateItemQuantity(item.id, item.quantity + 1)}>
                      +
                    </button>
                  </span>
                  </>
                    }
                  </div>

                </div>

                {
                item.isBundle ?
                <span className=" text-muted mx-2 float-end ">
                £{ item.price}
                  <button className='border d-flex bg-f2 rounded-circle border-1 text-muted  mt-2 mb-2 align-items-center' style={{ width: '20px', height: '20px' }} onClick={() => removeItem(item.id)}>&times;</button>
                </span>
                :     
                <span className=" text-muted mx-2 float-end ">
                £{ item.price * item.quantity}
                  <button className='border d-flex bg-f2 rounded-circle border-1 text-muted  mt-2 mb-2 align-items-center' style={{ width: '20px', height: '20px' }} onClick={() => removeItem(item.id)}>&times;</button>
                </span>
            }

              </li>
            ))}

            <li className="bg-f2 border-0 fs-4 border-top list-group-item d-flex justify-content-between">
              <span>Total   </span>
              <span className='fw-semibold' >£{cartTotal}</span>
            </li>
          </ul>

          {props.email != '' && props.name.trim().length != 0 && /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(props.email) ?
            <button className="pfw-button-red rounded-4 px-3 w-100 py-3" onClick={() => validateCheck()}>Buy Tickets</button>
            :
            <button className="pfw-button-red rounded-4 px-3 w-100 py-3" onClick={() => validateCheck()}>Buy Tickets</button>
          }
        </div>
      }
      {isStripe ?
        <Modal show={oneShow} onHide={() => setOneShow(false)} size="md" centered>
          <Modal.Header closeButton>

          </Modal.Header>
          <Modal.Body>
            <Elements stripe={stripePromise}>
              <StripeForm
                show={true}
                email={props.email}
                name={props.name}
                address1={props.address}
                address2={props.city}
                userPhoneNumber={props.userPhoneNumber}
                postcode={props.postcode}
                amount={cartTotal}
                items={items}
                transactionTypeID={items.length == 1 ? items[0].subscription ? 3 : 2 : 3}
                hideModal={hideModal}
                emptyCart={emptyCart}
                navigate={navigate}
                timeout={timeout}
                receiveUpdates={props.receiveUpdates}
                signedUpForStoriesAndInspiration={props.signedUpForStoriesAndInspiration}
              />
            </Elements>
          </Modal.Body>
        </Modal>
        : ""}
    </>
  );
}
export const menuGuest=[
    {
        title:"Login / Sign-up",
        path:"/login",
    },
    {
        title:"Find A Draw",
        path:"/login",
    },
    {
        title:"Login / Sign-up",
        path:"/login",
    },
    {
        title:"Login / Sign-up",
        path:"/login",
    },{
        title:"Login / Sign-up",
        path:"/login",
    }
]
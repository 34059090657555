import React from 'react';
import GoToTop from '../scrollToTop';
import { Link } from 'react-router-dom';
import { Home_Images } from './images';
import Moment from 'moment';

export default function SiteFooter() {
  return (
    <div className="my-5 container">
      {/* Footer */}
      <footer className="footer-section text-center text-lg-start text-dark bg">
        {/* Section: Social media */}
        {/* Section: Links  */}
        <section>
          <div className="container text-center text-md-start mt-5">
            {/* Grid row */}
            <div className="row mt-3">
              {/* Grid column */}
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                {/* Content */}
                <img src={Home_Images.Logo} style={{ width: '100%' }} />
                <p className="py-2 pt-5 footer-description">
                  The revolutionary digital fundraising platform that makes raising money simple for sports clubs,
                  events and charities.
                </p>
                <div className="socials py-4 pt-0 footer">
                  <a href="https://facebook.com/PlayFundWin" target="_blank">
                    <img src={require('../assets/images/fb.png')} className="px-2" />
                  </a>
                  <a href="https://twitter.com/PlayFundWin" target="_blank">
                    <img src={require('../assets/images/twitter.png')} className="px-2" />
                  </a>
                  <a href="https://www.instagram.com/playfundwin/" target="_blank" rel="noopener">
                    <img src={require('../assets/images/insta.png')} className="px-2" />
                  </a>
                  <a href="https://www.linkedin.com/company/playfundwin/" target="_blank" rel="noopener">
                    <img src={require('../assets/images/link.png')} className="px-2 " />
                  </a>
                </div>
              </div>
              {/* Grid column */}
              {/* Grid column */}
              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <p className="branding2">Learn More</p>
                <p>
                  <Link to={'https://www.playfundwin.com/about'} className="text-dark text-decoration-none">
                    About us
                  </Link>
                </p>
                <p>
                  {' '}
                  <Link to={'https://www.playfundwin.com/contact'} className="text-dark text-decoration-none">
                    Help Center
                  </Link>
                </p>
                <p>
                  <Link to={'https://www.playfundwin.com/blog'} className="text-dark text-decoration-none">
                    Blog / Media
                  </Link>
                </p>
                <p>
                  <Link to={'https://www.playfundwin.com/about'} className="text-dark text-decoration-none">
                    Work For Us
                  </Link>
                </p>
                <p>
                  <Link to={'https://www.playfundwin.com/faqs'} className="text-dark text-decoration-none">
                    FAQ
                  </Link>
                </p>
              </div>
              {/* Grid column */}
              {/* Grid column */}
              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <p className="branding2">Resources</p>
                <p>
                  <Link to={'/login'} className="text-dark text-decoration-none">
                    Login / Sign-up
                  </Link>
                </p>
                <p>
                  <Link to={'https://www.playfundwin.com/start-fundraising'} className="text-dark text-decoration-none">
                    Host a Draw
                  </Link>
                </p>
                <p>
                  <Link
                    to={'https://www.playfundwin.com/legal-and-compliance'}
                    className="text-dark text-decoration-none"
                  >
                    Complaints Procedure
                  </Link>
                </p>
                <p>
                  <Link
                    to={'https://www.playfundwin.com/legal-and-compliance'}
                    className="text-dark text-decoration-none"
                  >
                    Identity Verification Policy
                  </Link>
                </p>
                <p>
                  <Link
                    to={'https://www.playfundwin.com/legal-and-compliance'}
                    className="text-dark text-decoration-none"
                  >
                    Acceptable Use Policy
                  </Link>
                </p>
              </div>
              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <p className="branding2">Information</p>
                <p>
                  <Link
                    to={'https://www.playfundwin.com/legal-and-compliance'}
                    className="text-dark text-decoration-none"
                  >
                    Legal
                  </Link>
                </p>
                <p>
                  <Link
                    to={'https://www.playfundwin.com/legal-and-compliance'}
                    className="text-dark text-decoration-none"
                  >
                    Prize Draw Terms &amp; Conditions
                  </Link>
                </p>
                <p>
                  <Link
                    to={'https://www.playfundwin.com/legal-and-compliance'}
                    className="text-dark text-decoration-none"
                  >
                    Privacy Policy
                  </Link>
                </p>
                <p>
                  <Link
                    to={'https://www.playfundwin.com/legal-and-compliance'}
                    className="text-dark text-decoration-none"
                  >
                    Website Terms of Use
                  </Link>
                </p>
                <p>
                  <Link
                    to={'https://www.playfundwin.com/legal-and-compliance'}
                    className="text-dark text-decoration-none"
                  >
                    Prize Competitions Terms &amp; Conditions
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </section>
        <div className="row copyrights">
          <div className="col-md-7">
            <div className="row px-5 text-center align-items-center">
              <div className="col-md-4 py-2 px-1 mb-md-0 mb-3">
                <img src={require('../assets/images/begambleawareorg_black_png-300x39.png')} />
              </div>
              <div className="col-md-4 py-2 px-1 mb-md-0 mb-3">
                <img src={require('../assets/images/logo_4-300x72.png')} />
              </div>
              <div className="col-md-4 py-2 px-1">
                <img src={require('../assets/images/Chartered_Institite_of_Fundraising-1-300x106.jpg')} height={60} />
              </div>
            </div>
          </div>
          <div className="col-md-5" style={{ textAlign: 'right' }}>
            <div className="text-right p-3">©{Moment().format('YYYY')} Play Fund Win All rights reserved</div>
          </div>
        </div>

        {/* Copyright */}
      </footer>
      <GoToTop />
      {/* Footer */}
    </div>
  );
}

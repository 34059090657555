import React from 'react'
import { Link } from "react-router-dom";
import Siteheader from '../components/header';
import SiteFooter from '../components/footer';
import { UseEffect } from 'react';
import FooterCta from '../components/footer-cta';
import Innerheader from '../components/inner-header';
import { Helmet } from 'react-helmet';

export default function PrizeCompetitionTermsAndConditions(props) {
 
  return (
    <>
    <Helmet>‍
      <title>Prize Competition Terms and Conditions | Play Fund Win</title>‍
      <meta name="description" content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities." />
    </Helmet>
    <Siteheader/>
    <Innerheader title="Prize Competition Terms & Conditions"/>

    <div className="container pt-5 pb-5 my-3 px-lg-5 rounded-4 w-92" style={{ backgroundColor: '#f2f2f2' }}>
        <div className="row px-lg-5">
          <div className="col-md-12 align-middle text-left px-lg-5 mt-lg-0 mt-3 term-condition">
            <ol style={{ listStyle: 'number' }} className='ol-list'>
              <li>
                <p className="title-sub">The Promoter</p>
                
                  <p>The promoter is: Play Fund Win/PFW Holdings Ltd of 2 & 3 Westfield House Millfield Lane, Nether
                  Poppleton, York, England, YO26 6GA.</p>
                

                
                  <p>Our correspondence address is 2 & 3 Westfield House Millfield Lane, Nether Poppleton, York, England,
                  YO26 6GA.</p>
                

                
                  <p>If you wish to contact us for any reason, please email{' '}
                  <a href="mailto:hello@playfundwin.com">hello@playfundwin.com</a>.</p>
                
              </li>

              <li>
                <p className="title-sub">The Competition</p>
                <ol>
                  <li>
                    
                      These terms and conditions apply to all competitions listed on the Promoter’s website at{' '}
                      <a href="https://playfundwin.com/">https://playfundwin.com/</a> (the “<b>Website</b>”)
                    
                  </li>

                  <li>
                    
                      The Promoter may offer different formats to the competitions. Subject to clause 2.3, an entry fee
                      is payable each time you enter.
                    
                  </li>

                  <li>
                    
                      To be in with a chance of winning, everyone who enters the competition (an “<b>Entrant”</b>) will be
                      required to correctly answer a question or solve a problem set by the Promoter (the “<b>Competition Question</b>
                      ”). Where the Promoter offers an easy or multiple-choice question or, where the Promoter
                      does not ask a Competition Question, a free entry route is available.
                    
                  </li>
                </ol>
              </li>

              <li>
                <p className="title-sub">How to Enter</p>

                <ol>
    <li>
        The competition will run from and including the opening and closing dates specified on the Website. These dates shall be referred to as the "<b>Opening Date</b>" and "<b>Closing Date</b>" respectively. All times and dates referred to are the times and dates in London, England.
    </li>

    <li>
        If it is absolutely necessary to do so, the Promoter reserves the right to change the Opening and Closing Dates. If the Promoter does change the Opening Date and/or the Closing Date of a competition, the new details will be displayed on the Website. The Promoter will not extend the Closing Date simply to sell more entries.
    </li>

    <li>
        All competition entries must be received by the Promoter by no later than the specified time on the Closing Date. All competition entries received after the specified time on the Closing Date are automatically disqualified and no refunds will be given.
    </li>

    <li>
    The maximum number entries to the competition will be stated on the Website. The number of entries you are able to make may be limited if the maximum number of entries is reached.
    </li>

    <li>
    Entrants can enter the competition as many times as they wish until the maximum number of entries have been received. Entrants submitting free entries must submit each entry separately. Bulk entries, if received, will not be accepted and will only be counted as one single entry.
    </li>

    <li>
        To enter the competition:
        <ul>
            <li>
                go to the Website and view the Competition Question, if there is one;
            </li>
            <li>
                complete and submit the online entry form; then
            </li>
            <li>
                purchase the required number of entries; then
            </li>
            <li>
                when you have purchased your entries, submit your answer to the Competition Question, if there is one.
            </li>
        </ul>
    </li>

    <li>
        All entries must be submitted in the English language. Entries in languages other than English will automatically be disqualified and no refund will be given.
    </li>

    <li>
    Unless you are using the free entry method, the Promoter will send confirmation that your entry has been received and confirm whether or not your answer to the Competition Question is correct if applicable. 
    </li>

    <li>
        The Promoter will <b>not</b> accept responsibility for competition entries that are not successfully completed, are lost or are delayed regardless of cause, including, for example, as a result of any equipment failure, technical malfunction, systems, satellite, network, server, computer hardware or software failure of any kind.
    </li>

    <li>
        By purchasing entries and submitting a competition entry, you are entering into a contract with the Promoter and are agreeing to be bound by these terms and conditions.
    </li>

    <li>
        You may enter the competition for free by complying with the following conditions:
        <ul>
            <li>
                send your entry by first or second class post to the Promoter at the following address: 2 & 3 Westfield House Millfield Lane, Nether Poppleton, York, England, YO26 6GA 
            </li>
            <li>
                hand delivered entries will not be accepted and will not be entered into the random draw;
            </li>
            <li>
                include with your entry the following information:
                <ul>
                    <li>
                        your full name;
                    </li>
                    <li>
                        your address;
                    </li>
                    <li>
                        a contact telephone number and email address;
                    </li>
                    <li>
                        your answer to the Competition Question (if there is one).
                    </li>
                </ul>
            </li>
            <li>
                incomplete or illegible entries will be disqualified;
            </li>
            <li>
                you may make multiple free entries for any competition (up to any limit placed on entries by the Promoter) but each free entry must be submitted and posted to the Promoter separately. Bulk entries in one envelope will not be accepted as multiple entries and if a bulk entry is received, it will be counted as one single entry;
            </li>
            <li>
                by entering the competition, you are confirming that you are eligible to enter and accept these terms and conditions;
            </li>
            <li>
                your entry must be received by the Promoter prior to the Closing Date. Entries received after the Closing Date will not be entered into the random draw. Proof of posting does not guarantee that you will be entered into the random draw;
            </li>
            <li>
                the Promoter will not acknowledge receipt of your entry nor confirm if your answer to the Competition Question is correct;
            </li>
            <li>
                if the number of entries received reaches any cap or limit before your free entry is received, you will not be entered into the random draw.
            </li>
        </ul>
    </li>
</ol>

              </li>

              <li>
                <p className="title-sub">Choosing a winner</p>

                <ol>
                  <li>
                    
                      All Entrants who correctly answer the Competition Question will be placed into a draw and the
                      winner will be chosen by random draw. If no Competition
                      Question is asked, all Entrants will be entered into the random draw. The random draw will take
                      place as soon as reasonably possible and, in any event, within 7days of the Closing Date ("<b>Draw
                      Date</b>").
                    
                  </li>

                  <li>
                    
                      All Entrants will have their names and entry numbers include on a spreadsheet which may be
                      published on the Website and may be visible during the live draw. If you do not wish to have your
                      name included on this spreadsheet you must contact the Promoter via email at <a href="mailto:hello@playfundwin.com"> hello@playfundwin.com </a>
                      as soon as reasonably possible after you have completed your entry and in any event, at least 48
                      hours before the live draw takes place.
                    
                  </li>

                  <li>
                    
                      For help with entries, please email us at <a href="mailto:hello@playfundwin.com">hello@playfundwin.com</a>
                    
                  </li>
                </ol>
              </li>

              <li>
                <p className="title-sub">Eligibility</p>

                <ol>
                  <li>
                    
                      The competition is only open to all residents in the United Kingdom aged 18 years or over, <b>except</b>:
                    
                    <ul>
                      <li>
                        employees of the Promoter;
                      </li>
                      <li>
                        
                          employees of agents or suppliers of the Promoter, who are professionally connected with the
                          competition or its administration; or
                        
                      </li>
                      <li>
                        members of the immediate families or households of (a) and (b) above.
                      </li>
                    </ul>
                  </li>

                  <li>
                    
                      By entering the competition, you confirm that you are eligible to do so and eligible to claim any
                      prize you may win. The Promoter may require you to provide proof that you are eligible to enter
                      the competition and claim the prize. If you fail to provide the Promoter with any such proof or
                      other information that they may require within a reasonable time, you may be disqualified from the
                      competition.
                    
                  </li>

                  <li>
                    The Promoter will not accept competition entries that are:
                    <ul>
                      <li>
                        automatically generated by computer; or
                      </li>
                      <li>
                        incomplete.
                      </li>
                    </ul>
                  </li>

                  <li>
                    
                      The Promoter reserves all rights to disqualify you if your conduct is contrary to the spirit or
                      intention of the prize competition. This includes if you are rude or abusive to the Promoter or
                      anyone associated with them.
                    
                  </li>

                  <li>
                    No refunds of the entry fee will be given in any event, including;
                    <ul>
                      <li>
                        
                          if, following your entry into the competition, you subsequently find out that you are not
                          eligible to enter the competition or claim the Prize;
                        
                      </li>
                      <li>
                        
                          if, following your entry into the competition the eligibility criteria for entering the
                          competition or claiming the Prize changes and you are no longer eligible; or
                        
                      </li>
                      <li>
                        if you are disqualified from the competition by the Promoter for any reason.
                      </li>
                    </ul>
                  </li>

                  <li>
                    If the Entrant engages in:
                    <ul>
                      <li>
                        any form of fraud (actual or apparent);
                      </li>
                      <li>
                        fraudulent misrepresentation;
                      </li>
                      <li>
                        fraudulent concealment;
                      </li>
                      <li>
                        hacking or interference with the proper functioning of the Website; or
                      </li>
                      <li>
                        amending, or unauthorised use of, any of the code that constitutes the website
                      </li>
                    </ul>
                    
                      all of their entries will be declared void, no refunds will be given and they may be prevented
                      from participating in any future competitions.
                    
                  </li>
                </ol>
              </li>

              <li>
                <p className="title-sub">The prize</p>

                <ol>
                  <li>
                    
                      The prize for each competition is described on the Website (the “<b>Prize</b>”). Details of the Prize
                      are, to the best of the Promoter’s knowledge, information and belief, correct as at the Opening
                      Date.
                    
                  </li>

                  <li>
                    
                      Prizes are subject to availability. The Promoter reserves the right to substitute any prize with a
                      prize of equal or greater value. If any details of the Prize change, the Promoter will endeavour
                      to update the Website as soon as reasonably possible.
                    
                  </li>

                  <li>
                    
                      The Promoter makes no representations and gives no warranties about the Prize, its value, its
                      condition or any other information provided on the Website. The Promoter makes no representations
                      and gives no warranties that the information provide on the Website is accurate, complete or up to
                      date.
                    
                  </li>

                  <li>
                    
                      The Prize may be supplied by a third-party supplier (the “Supplier”). Details of the Supplier (if
                      any) will be provided on the Website.
                    
                  </li>

                  <li>
                  The Promoter reserves the right to substitute the Prize for an alternative cash prize (“Cash Prize”) in the following circumstances:
                  <br/>
                    <ul>
                      <li>
                        the Prize becomes unavailable;
                      </li>
                      <li>
                        
                      other circumstances beyond the reasonable control of the Promoter make it necessary to do so.
                        
                      </li>
                    </ul>
                    
                  </li>
                  <li>The prize is not negotiable or transferable.</li>
                </ol>
              </li>

              <li>
                <p className="title-sub">Winners</p>

                <ol>
                  <li>
                    
                      The decision of the Promoter is final and no correspondence or discussion will be entered into.
                    
                  </li>

                  <li>
                    
                      The Promoter will contact the winner personally as soon as practicable after the Draw Date, using
                      the email address provided with the competition entry. If the winner cannot be contacted or is not available, or has not claimed the Prize within 28 of days of the Draw Date, the Promoter reserves the right to offer the Prize to the next eligible Entrant selected from the
                      correct entries that were received before the Closing Date.
                    
                  </li>

                  <li>
                    
                  The Promoter must either publish or make available information that indicates that a valid award took place. To comply with this obligation the Promoter will publish the surname and county of major prize winners on the Website.
                  </li>

                  <li>
                    
                      If you object to any or all of your surname, county and winning entry being published or made
                      available, please contact the Promoter at <a href="mailto:hello@playfundwin.com"> hello@playfundwin.com</a> prior to the Closing Date. In such
                      circumstances, the Promoter must still provide the information to the Advertising Standards
                      Authority on request.
                    
                  </li>
                </ol>
                </li>

                  <li>
                    <p className="title-sub">Claiming the prize</p>

                    <ol>
                      <li>
                        
                          You must claim the Prize personally. The Prize may not be claimed by a third party on your
                          behalf. Details of how the Prize will be delivered to you (or made available for collection)
                          are published on the Website.
                        
                      </li>

                      <li>
                        
                          If your personal details, including contact information, changes at any time you should notify
                          the Promoter as soon as reasonably possible. Notifications should be sent to the Promoter via
                          email to <a href="mailto:hello@playfundwin.com">hello@playfundwin.com</a>. Notifications must include details of the competition you have
                          entered, your old details and your new details. If your details change within 10 days of the
                          Closing Date, the Promoter will use your old details if it needs to try to contact you.
                        
                      </li>

                      <li>
                        
                          Any Cash Prize will be transferred directly to the winners nominated bank account. The winner
                          must provide evidence that it is the sole or joint beneficiary of the bank account. Failure to do so within 28 days will result in disqualification from the competition and the winner forfeiting the prize. In such circumstances, the Promoter reserves the right to offer the
                          prize to the next eligible Entrant selected from the correct entries that were received before
                          the Closing Date.
                        
                      </li>

                      <li>
                        
                          The Promoter does not accept any responsibility and is not liable to pay any compensation if
                          you are unable to or do not take up the prize.
                        
                      </li>
                    </ol>
                  </li>
                
               
                  <li>
                    <p className="title-sub">Limitation of liability</p>

                    
                      <p>Insofar as is permitted by law, the Promoter, its agents or distributors will not in any
                      circumstances be responsible or liable to compensate the winner or accept any liability for any
                      loss, damage, personal injury or death occurring as a result of taking up the prize except where
                      it is caused by the negligence of the Promoter, its agents or distributors or that of their
                      employees. Your statutory rights are not affected.</p>
                    
                  </li>

                
                  <li>
                    <p className="title-sub">Data protection and publicity</p>

                    <ol>
                      <li>By entering the competition, you agree that any personal information provided by you with the
                          competition entry may be held and used only by the Promoter or its agents and suppliers to
                          administer the competition or as otherwise set out in the Promoter’s Privacy Policy{' '}
                          <a href="https://playfundwin.com/privacy-policy">https://playfundwin.com/privacy-policy</a>, a
                          copy of which is available on the Website.
                        
                      </li>

                      <li>
                        
                          If you are the winner of the competition, you agree that the Promoter may use your name, image
                          and town or county of residence to announce the winner of this competition. You further agree
                          to participate in any reasonable publicity required by the Promoter.
                        
                      </li>

                      <li>
                        
                          If you do not wish to participate in any publicity, you must notify the Promoter prior to the
                          Closing Date. This will not affect your chances of winning the Prize. If you do not agree to
                          participate in any publicity about the competition we may still provide your details to the
                          Advertising Standards Authority. This is a legal requirement that we must comply with to prove
                          that the competition has been properly administered and the Prize awarded.
                        
                      </li>

                      <li>
                        
                          If you are the winner of the competition, you may be required to provide further personal
                          information and proof of your identity in order to confirm your eligibility to claim the Prize
                          and transfer ownership of the Prize to you. You consent to the use of your information in this
                          way. You are entitled to request further details about how your personal information is being
                          used. You may also withdraw your consent to your personal information being used in such way
                          but by doing so you may prevent the Prize being transferred to you. In such circumstances, you
                          will be deemed to have withdrawn from the competition and forfeit the Prize. You will not be
                          entitled to any refund of your entry fee. The Promoter reserves the right to offer the Prize
                          to the next eligible Entrant selected from the correct entries that were received before the
                          Closing Date.
                        
                      </li>

                      <li>
                        
                          Please note that under data protection laws you are entitled to request that the Promoter does
                          not contact you and removes your details from its database. If you make such a request you
                          will be withdrawing from the competition as it will not be possible to contact you in the
                          event that you are the winner. You will not be entitled to any refund of any entry fee if you
                          withdraw from the competition. If you do not wish any of your personal details to be used by
                          the Promoter for promotional purposes, please email the Promoter at <a href="mailto:hello@playfundwin.com">hello@playfundwin.com</a> prior to the Closing Date.
                        
                      </li>
                    </ol>
                  </li>


                  <li>
                    <p className="title-sub">General</p>

                    <ol>
                      <li>
                        
                          The Promoter reserves the right to amend these terms and conditions from time to time. The
                          latest version of these terms and conditions will be available on the Website.
                        
                      </li>

                      <li>
                        
                          If there is any reason to believe that there has been a breach of these terms and conditions,
                          the Promoter may, at its sole discretion, reserve the right to exclude you from participating
                          in the competition and any future competitions.
                        
                      </li>

                      <li>
                        
                          The Promoter reserves the right to hold void, suspend, cancel, or amend the prize competition
                          where it becomes necessary to do so. There is no minimum number of entries and the Promoter
                          will not hold void, suspend, cancel, or amend the prize competition due to a lack of entries.
                        
                      </li>

                      <li>
                        
                          The competitions on the Website are in no way sponsored, endorsed, administered by or
                          associated with Facebook. By entering the competitions, Entrants agree that Facebook has no
                          liability and is not responsible for the administration or promotion of the competitions.
                        
                      </li>

                      <li>
                        
                          These terms and conditions shall be governed by English law, and the parties submit to the
                          exclusive jurisdiction of the courts of England and Wales.
                        
                      </li>

                      <li>
                        You should print a copy of these terms and conditions and keep them for your records.
                      </li>
                    </ol>
                  </li>
            </ol>
          </div>
        </div>
      </div>

  <FooterCta/>
  <SiteFooter/>
    </>
  )
}

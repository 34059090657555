import React, { useEffect, useState, Component } from "react";
import Siteheader from "../components/header";
import SiteFooter from "../components/footer";
import { Inner_Header_Images } from "../components/images";
import FooterCta from "../components/footer-cta";
import Accordion from "react-bootstrap/Accordion";
import MonthlySlider from "../components/monthlySlider";
import { Helmet } from "react-helmet";
import HostSliderSection from "../components/hostSlider-section-grassroots";
import MarketingSupport from "../components/marketingSupport";
import ActiveSlider from "../components/activeSlider";
import { toast, Toast, ToastContainer } from "react-toastify";
import moment from "moment";
import axios from "axios";
import HostImgSliderNew from "../components/GrassrootSlider";

import Slider from "react-slick";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import {
    MyCart,
    Cart,
    HomeDrawDataSession,
    DrawDataSession,
} from "../components/Cart";
import { CartProvider, useCart } from "react-use-cart";
import { contact } from "../utils/meta/metaPixelEvent";
//import { DrawData } from "../Data/Draw-data";

function SetDrawDetails(p) {
    DrawDataSession.data = p;
}

export default function Grassroots() {
    const [fullname, SetFullName] = useState("");
    const [causename, SetCauseName] = useState("");
    const [email, SetEmail] = useState("");
    const [message, SetMessage] = useState("");
    const [ageconsent, SetAgeConsent] = useState(false);

    function timeout(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    //const navigate =useNavigate();
    let APIURL = process.env.REACT_APP_API_URL;
    let siteID = process.env.REACT_APP_API_SITE_ID;

    async function hostFormHandler() {
        if (email.trim().length === 0) {
            toast.error(`Please Enter Your Email`);
        } else if (
            !/^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9-]+(?:\.[a-z0-9-]+)+(?:\.[a-z0-9-]+)*$/.test(
                email
            )
        ) {
            toast.error(`Please Enter Your valid Email Address`);
        } else if (fullname.trim().length === 0) {
            toast.error(`Please Enter Full Name`);
        } else if (causename.trim().length === 0) {
            toast.error(`Please Enter Cause or Club Name`);
        } else if (message.trim().length === 0) {
            toast.error(`Please Enter about your Cause and your fundraising`);
        } else if (!ageconsent) {
            console.log(ageconsent);
            toast.error(`Please Confirm you are 18 years or over`);
        } else {
            try {
                const response = await toast.promise(
                    axios.post(
                        `${APIURL}/form/grassroot`,
                        {
                            siteID: siteID,
                            grassRootFullName: fullname,
                            entryDate: moment().format("YYYY-MM-DD hh:mm:ss"),
                            grassRootCauseOrClubName: causename,
                            grassRootEmail: email,
                            grassRootAboutCauseDetails: message,
                            grassRootAgeVerified: ageconsent,
                            userMeta: {
                                website: "PlayFundWin",
                            },
                        },
                        {
                            withCredentials: true,
                            credentials: "same-origin",
                        }
                    ),
                    {
                        pending: "Please wait...",
                    }
                );
                console.log(response);

                if (
                    response.data.status === true &&
                    response.data.error === null
                ) {
                    toast(
                        "Thank you for your message, it has been sent, we will contact you shortly",
                        { type: "success" }
                    );
                    contact();
                    await timeout(1000);

                    window.location.replace("/host-thank-you");
                } else {
                    toast("Something went wrong While authenticating", {
                        type: "error",
                    });
                }
            } catch (error) {
                console.log(error);
                if (error.response.status === 403) {
                    toast.error(`${error.response.data.content.message}`);
                } else {
                    toast.error(`${error.message}`);
                }
            }
        }
    }

    return (
        <>
            <Helmet>
                ‍<title>Grassroots Sports Clubs | Play Fund Win</title>‍
                <meta
                    name="description"
                    content="Play Fund Win’s digital fundraising platform is a great way for sports clubs to raise money, through prize draws or offering cash prizes to supporters"
                />
            </Helmet>
            <Siteheader />
            <div className="grassroots">
                <div
                    className="blog-header-section container font-face-gb px-5 py-5 bg-branding1"
                    style={{
                        backgroundImage: `linear-gradient(to top, #0e3160, rgba(14, 49, 96, 0.79)) , url(${require("../assets/images/featured-wigton.png")})`,
                        backgroundSize: "cover",
                    }}
                >
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-12 align-middle header-left-ctn">
                            {/* <div className='publishdate color-yellow text-uppercase fw-bolder fs-5'>PlayFundWin</div> */}
                            <h1 className="post-title text-white fs-1 fw-normal mt-2 mb-4 font-face-gb-semibold">
                                WE <span className="color-yellow">WANT</span>{" "}
                                <span className="branding3">YOU</span>{" "}
                                <span className="color-yellow">IN</span>{" "}
                                <span className="branding2">OUR</span> TEAM!
                            </h1>
                            <div className="short-desc text-light fs-5">
                                Play Fund Win is a revolutionary platform
                                designed to support grassroots clubs and
                                non-league clubs in their fundraising endeavors.
                                Recognizing the financial challenges faced by
                                these clubs, Play Fund Win offers a unique
                                opportunity to generate much-needed funds
                                without any costs.
                            </div>
                            <div className="grassroots-tag my-3">
                                <p>
                                    <span className="color-yellow">#</span>
                                    NonLeagueClubs
                                </p>
                                <p>
                                    <span className="color-yellow">#</span>
                                    CommunitySupport
                                </p>
                                <p>
                                    <span className="color-yellow">#</span>
                                    Fundraising
                                </p>
                                <p>
                                    <span className="color-yellow">#</span>
                                    GrassrootsClubs
                                </p>
                            </div>
                            {/* <a className="border-0 rounded-pill bg-branding3 text-white btn pfw-button-white py-2 px-5 font-face-gb-semibold mt-4" style={{lineHeight : 2}} href="/host">Host a Draw</a> */}
                            <div className="text-center">
                                <img
                                    width="50%"
                                    style={{
                                        borderRadius: "1rem",
                                        marginTop: "0px",
                                    }}
                                    src={require("../assets/images/wewantyou.png")}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 align-middle p-md-5 p-2 about-left-grass position-relative">
                            {/* <div className='post-image star-before background-image w-100 position-relative' style={{backgroundImage: `url(${require(`../assets/images/grassroot-header.png`)})`, borderRadius: 10, height: 360, top: 60, marginTop: '-40px', zIndex : 9}}></div> */}
                            <div
                                className="row d-flex justify-content-end position-relative"
                                style={{ zIndex: 9 }}
                            >
                                <div className="col-lg-11 col-md-12 col-sm-12 p-lg-5 p-3 bg-f2 rounded-3">
                                    <p className="display-6 fw-bolder branding1 text-center">
                                        Enquiry Form
                                    </p>
                                    <form className="">
                                        <div className="my-3">
                                            <label className="w-100 mb-2 fw-bold form-label-group branding1 text-left">
                                                {" "}
                                                Full Name
                                            </label>
                                            <input
                                                type="text"
                                                className="w-100 form-control  border-0 py-2"
                                                name="fullname"
                                                value={fullname}
                                                onChange={(e) =>
                                                    SetFullName(e.target.value)
                                                }
                                            />
                                        </div>
                                        <div className="my-3">
                                            <label className="w-100 mb-2 fw-bold form-label-group branding1">
                                                Email Address
                                            </label>
                                            <input
                                                type="text"
                                                className="w-100 form-control  border-0 py-2"
                                                name="email"
                                                value={email}
                                                onChange={(e) =>
                                                    SetEmail(e.target.value)
                                                }
                                            />
                                        </div>
                                        <div className="my-3">
                                            <label className="w-100 mb-2 fw-bold form-label-group branding1">
                                                {" "}
                                                Cause or Club Name
                                            </label>
                                            <input
                                                type="text"
                                                className="w-100 form-control  border-0 py-2"
                                                name="cause_name"
                                                value={causename}
                                                onChange={(e) =>
                                                    SetCauseName(e.target.value)
                                                }
                                            />
                                        </div>
                                        <div className="my-3">
                                            <label className="w-100 mb-2 fw-bold form-label-group branding1">
                                                Tell us about your Cause and
                                                your fundraising needs?
                                            </label>
                                            <textarea
                                                type="textarea"
                                                className="w-100 form-control  border-0 py-2"
                                                name="message"
                                                value={message}
                                                onChange={(e) =>
                                                    SetMessage(e.target.value)
                                                }
                                                style={{ height: 100 }}
                                            />
                                        </div>
                                        <div className="my-3 d-flex align-items-start">
                                            <input
                                                type="radio"
                                                className="mt-2 border-0 "
                                                name="tandc"
                                                checked={ageconsent}
                                                onChange={() =>
                                                    SetAgeConsent(!ageconsent)
                                                }
                                                value="10"
                                            />
                                            <label className="mx-2  px-2 form-label-group branding1">
                                                I am aged 18 years or over and I
                                                am happy to be contracted by
                                                Play Fund Win
                                            </label>
                                        </div>
                                        <div className="my-3 ">
                                            <Link
                                                to={"/privacy-policy"}
                                                className="branding2 fw-bold px-4"
                                            >
                                                Privacy-policy
                                            </Link>
                                        </div>
                                        <div className="my-3 w-100 text-center">
                                            {/* <input type="submit" className='pfw-button-yellow rounded-pill p-2 px-3' /> */}
                                            <button
                                                className="btn pfw-button-yellow rounded-pill align-self-center px-5 p-2 fs-6 fw-bold"
                                                type="button"
                                                name="button"
                                                onClick={hostFormHandler}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="p-5 slider-logos">
                    <HostImgSliderNew />
                </section>

                <section className="pb-5 pt-2 position-relative host-sliders container">
                    <div className="slider-data-host">
                        <HostSliderSection />
                    </div>
                </section>

                <section className="p-5 bg-branding2 position-relative container sec1">
                    <div className="content text-center">
                        <p className="text-white py-3">
                            With a passion for nurturing and strengthening local
                            communities, Play Fund Win provides an accessible
                            and user-friendly platform that empowers clubs to
                            engage their supporters, members, and wider
                            communities in a fun and interactive way. By
                            leveraging the power of online fundraising, Play
                            Fund Win helps clubs unlock their full potential and
                            secure vital financial resources.
                        </p>
                        <div className="grassroots-tag my-3">
                            <p>
                                <span className="color-yellow">#</span>
                                OnlineFundraising
                            </p>
                            <p>
                                <span className="color-yellow">#</span>
                                CommunityEngagement
                            </p>
                            <p>
                                <span className="color-yellow">#</span>
                                InteractivePlatform
                            </p>
                            {/* <p><span className='color-yellow'>#</span>GrassrootsClubs</p> */}
                        </div>
                    </div>
                </section>

                <div className="what-pfw-section container pb-0 px-0 mb-0 text-center font-face-gb">
                    <div className="" style={{ width: "100%" }}>
                        <div
                            className="row align-items-center mx-auto"
                            style={{ width: "100%" }}
                        >
                            <div className="col-md-6 p-0 text-center">
                                <div className="position-relative">
                                    <img
                                        className="w-100"
                                        src={require("../assets/images/sec1.png")}
                                        style={{ objectFit: "cover" }}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 p-0 align-middle pe-md-5 pb-md-0 px-5 pb-4 font-face-gb">
                                <div
                                    className="we-are-section px-5 text-left"
                                    style={{ textAlign: "left" }}
                                >
                                    <p>
                                        Unlike traditional fundraising methods
                                        that can be time-consuming and
                                        expensive, Play Fund Win operates on a
                                        simple yet effective concept. Clubs can
                                        create their own online campaigns,
                                        inviting individuals to participate by
                                        entering into exciting prize draws and
                                        competitions. These prizes are
                                        generously donated by Play Fund Win's
                                        network of partners and sponsors,
                                        ensuring that clubs can offer enticing
                                        rewards to their supporters.
                                    </p>
                                    <div className="grassroots-tag-sec my-3">
                                        <p>
                                            <span className="color-yellow">
                                                #
                                            </span>
                                            PrizeDraws
                                        </p>
                                        <p>
                                            <span className="color-yellow">
                                                #
                                            </span>
                                            GenerousPartners
                                        </p>
                                        <p>
                                            <span className="color-yellow">
                                                #
                                            </span>
                                            Competitions
                                        </p>
                                        <p>
                                            <span className="color-yellow">
                                                #
                                            </span>
                                            Sponsors
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="what-pfw-section container pb-0 px-0 mb-0 text-center font-face-gb sec3">
                    <div className="" style={{ width: "100%" }}>
                        <div
                            className="row align-items-center mx-auto"
                            style={{ width: "100%" }}
                        >
                            <div className="col-md-6 p-0 align-middle pe-md-5 pb-md-0 px-5 pb-4 font-face-gb">
                                <div
                                    className="we-are-section px-5 text-left"
                                    style={{ textAlign: "left" }}
                                >
                                    <p>
                                        The beauty of Play Fund Win lies in its
                                        zero-cost model. Clubs can use the
                                        platform for free, eliminating any
                                        financial burden or risk typically
                                        associated with fundraising efforts.
                                        Instead, Play Fund Win generates revenue
                                        through a small percentage of the ticket
                                        sales, ensuring that clubs can focus on
                                        their primary objective—raising funds to
                                        support their activities,
                                        infrastructure, and development
                                        programs.
                                    </p>
                                    <div className="grassroots-tag-sec my-3">
                                        <p>
                                            <span className="color-yellow">
                                                #
                                            </span>
                                            ZeroCostModel
                                        </p>
                                        <p>
                                            <span className="color-yellow">
                                                #
                                            </span>
                                            RevenueGeneration
                                        </p>
                                        <p>
                                            <span className="color-yellow">
                                                #
                                            </span>
                                            SupportingActivities
                                        </p>
                                        {/* <p><span className='color-yellow'>#</span>Sponsors</p> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 p-0 text-center">
                                <div className="position-relative">
                                    <img
                                        className="w-100"
                                        src={require("../assets/images/sec2.png")}
                                        style={{ objectFit: "cover" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="p-5 position-relative container sec1">
                    <div className="content text-center">
                        <p className="text-black py-3">
                            With a passion for nurturing and strengthening local
                            communities, Play Fund Win provides an accessible
                            and user-friendly platform that empowers clubs to
                            engage their supporters, members, and wider
                            communities in a fun and interactive way. By
                            leveraging the power of online fundraising, Play
                            Fund Win helps clubs unlock their full potential and
                            secure vital financial resources.
                        </p>
                        <div className="grassroots-tag-sec my-3">
                            <p>
                                <span className="color-yellow">#</span>
                                SupportingActivities
                            </p>
                            <p>
                                <span className="color-yellow">#</span>
                                AudienceEngagement
                            </p>
                            <p>
                                <span className="color-yellow">#</span>
                                CommunitySupport
                            </p>
                            {/* <p><span className='color-yellow'>#</span>GrassrootsClubs</p> */}
                        </div>
                    </div>
                </section>

                <section
                    className="p-0 pb-5 position-relative container sec1"
                    style={{ backgroundColor: "#f2f2f2" }}
                >
                    <img
                        className="w-100"
                        src={require("../assets/images/sec4.png")}
                        style={{ marginTop: "-50px" }}
                    />
                    <div className="px-5 what-pfw-section container pb-0 px-0 mb-0 text-center font-face-gb sec4">
                        <div className="" style={{ width: "100%" }}>
                            <div
                                className="row align-items-center mx-auto"
                                style={{ width: "100%" }}
                            >
                                <div className="col-md-4 p-0 text-center">
                                    <div className="position-relative">
                                        <img
                                            className="w-100"
                                            src={require("../assets/images/sec5.png")}
                                            style={{ objectFit: "cover" }}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-8 p-0 align-middle pe-md-5 pb-md-0 px-5 pb-4 font-face-gb">
                                    <div
                                        className="we-are-section px-5 text-left"
                                        style={{ textAlign: "left" }}
                                    >
                                        <p>
                                            Play Fund Win understands that every
                                            grassroots club and non-league club
                                            is unique, with its own set of goals
                                            and aspirations. That's why the
                                            platform offers flexibility,
                                            allowing clubs to tailor their
                                            campaigns and choose the fundraising
                                            model that best suits their needs.
                                            Whether it's a one-off event or an
                                            ongoing campaign, Play Fund Win
                                            provides the tools and support to
                                            make it a success.
                                        </p>
                                        <div className="grassroots-tag-sec my-3">
                                            <p>
                                                <span className="color-yellow">
                                                    #
                                                </span>
                                                TailoredCampaigns
                                            </p>
                                            <p>
                                                <span className="color-yellow">
                                                    #
                                                </span>
                                                FlexibleFundraising
                                            </p>
                                            <p>
                                                <span className="color-yellow">
                                                    #
                                                </span>
                                                ClubGoals
                                            </p>
                                            {/* <p><span className='color-yellow'>#</span>Sponsors</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="what-pfw-section container pb-0 px-0 mb-5 text-center font-face-gb sec5">
                    <div className="" style={{ width: "100%" }}>
                        <div
                            className="row align-items-center mx-auto"
                            style={{ width: "100%", marginTop: "-90px" }}
                        >
                            <div className="col-md-6 p-0 align-middle pe-md-5 pb-md-0 px-5 pb-4 font-face-gb">
                                <div
                                    className="we-are-section px-0 text-left"
                                    style={{ textAlign: "left" }}
                                >
                                    <p>
                                        Join the Play Fund Win community today
                                        and unlock the potential of your
                                        grassroots or non-league club. With our
                                        platform, you can raise funds, engage
                                        your supporters, and build a brighter
                                        future for your club and its members.
                                        Together, let's play, fund, and win!
                                    </p>
                                    <div className="grassroots-tag-sec my-3">
                                        <p>
                                            <span className="color-yellow">
                                                #
                                            </span>
                                            JoinTheCommunity
                                        </p>
                                        <p>
                                            <span className="color-yellow">
                                                #
                                            </span>
                                            RaiseFunds
                                        </p>
                                        <p>
                                            <span className="color-yellow">
                                                #
                                            </span>
                                            SupportYourClub
                                        </p>
                                        {/* <p><span className='color-yellow'>#</span>Sponsors</p> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 p-0 px-2 text-center">
                                <div className="position-relative">
                                    <img
                                        className="w-100"
                                        src={require("../assets/images/sec6.png")}
                                        style={{ objectFit: "cover" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FooterCta />
            <SiteFooter />
        </>
    );
}

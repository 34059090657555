import React, { useState } from 'react'
import Siteheader from '../components/header'
import SiteFooter from '../components/footer'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import apiClient from '../api.config';
import secureLocalStorage from 'react-secure-storage'
import Breadcrumb from '../components/breadcrum';


export default function AccountEditPassword() {
  const userData = secureLocalStorage.getItem('LogObject');
  // const loginID = secureLocalStorage.getItem('LogObjectAdmin').userID;
  // const Urlparams = useLocation().search;
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  // const Params_userID = new URLSearchParams(Urlparams).get("userID");
  // const Params_resetToken = new URLSearchParams(Urlparams).get("resetToken");
  const navigate = useNavigate();

  // console.log(Params_userID,Params_resetToken);
  function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  async function updatePassWordHandler() {
    let prefixURL = process.env.REACT_APP_API_URL;

    if (oldPassword.trim().length === 0 && newPassword.trim().length === 0 && confirmPassword.trim().length === 0) {
      toast.error('Please Enter Old Password and New Password')
    } else if (oldPassword.trim().length === 0) {
      toast.error('Please Enter Old Password')
    } else if (newPassword.trim().length === 0) {
      toast.error('Please Enter New Password')
    } else if (confirmPassword.trim().length === 0) {
      toast.error('Please Enter Confirm Password')
    } else if (newPassword != confirmPassword) {
      toast.error('Invalid Confirm Password')
    } else {
      try {
        const response = await toast.promise(apiClient.post(
          `${prefixURL}/auth/change`,
          {
            "userID": userData.userID,
            "oldPassword": oldPassword,
            "newPassword": confirmPassword,
          },
          {
            withCredentials: true,
            credentials: "same-origin"
          }
        ),
          {
            pending: 'Please wait...',
          }
        );


        if (response.data.status === true && response.data.error === null) {
          toast("Successfully Updated Password", { type: "success" });
          setTimeout(() => {
            navigate('/login');
          }, 4000);
        } else {
          toast("Something went wrong While resetting", { type: "error" });
        }
      } catch (error) {

        if (error.response.status === 403) {
          toast.error(`${error.response.data.content.message}`);
        } else {
          toast.error(`${error.message}`);
        }
      }
    }
  }


  return (
    <>
      <Helmet>‍
        <title>Edit Password | Play Fund Win</title>‍
        <meta name="description" content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities." />
      </Helmet>
      <Siteheader />
      <section className="h-100 w-100 bg-f2 container px-lg-5 py-5 position-relative bg-before-after">
      <Breadcrumb/>
        <div className="container h-100 p-lg-5 ">
          <div className="find-draw-form text-center">
            <h2 className='branding1 display-4 font-face-gb-semibold'>Set New Password</h2>
          </div>
          <div className="d-flex align-items-center justify-content-center h-50 p-lg-0 ">
            <div className="d-flex flex-column p-xl-5 col-lg-6 col-md-6 col-12">
              <input type="password" onChange={(event) => setOldPassword(event.target.value)} placeholder="Enter Old Password" className='mt-3 rounded-pill align-self-center w-100 px-4 p-3 bg-white border-0' />
              <input type="password" onChange={(event) => setNewPassword(event.target.value)} placeholder="Enter New Password" className='mt-3 rounded-pill align-self-center w-100 px-4 p-3 bg-white border-0' />
              <input type="password" onChange={(event) => setConfirmPassword(event.target.value)} placeholder="Confirm New Password" className='mt-3 rounded-pill align-self-center w-100 px-4 p-3 bg-white border-0' />

              <button onClick={updatePassWordHandler} className=" mt-4 pfw-button-green rounded-pill align-self-center w-25 px-3 p-2 fs-5 fw-bold" type="button" name="button">Save</button>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
      <SiteFooter />
    </>
  )
}

import React, { useState, useEffect } from 'react';
import SiteFooter from '../components/footer';
import Siteheader from '../components/header';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast, Toast, ToastContainer } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import apiClient from '../api.config';
import { Helmet } from 'react-helmet';
const UI_PREFIXURL = process.env.REACT_APP_UI_PREFIXURL;
// const UI_PREFIXURL = 'http://localhost:3000'
const APIURL = process.env.REACT_APP_API_URL;
// const APIURL = 'http://localhost:5000/api/v1';
export default function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  let userDetail = secureLocalStorage.getItem('LogObject');
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmParams = {
      utm_source: urlParams.get('utm_source'),
      utm_medium: urlParams.get('utm_medium'),
      utm_campaign: urlParams.get('utm_campaign'),
      utm_content: urlParams.get('utm_content')
    };
    secureLocalStorage.setItem('utmParams', JSON.stringify(utmParams));
    console.log('utmParams', utmParams);
  }, []);

  useEffect(() => {
    console.log('userDetail', userDetail);
    if (userDetail && userDetail.loggedin) {
      navigate('/dashboard');
    }
  }, []);

  const handleOAuthLogin = () => {
    const encodedSite = encodeURIComponent(UI_PREFIXURL);
    const oauthUrl = `${APIURL}/auth/google?UI_PREFIXURL=${encodedSite}`;
    window.location.href = oauthUrl; // Use window.location.href to perform the redirect
  };

  async function ValidateUser() {
    if (email.trim().length === 0 && password.trim().length === 0) {
      toast.error(`Please Enter Your valid Email and Password`);
    } else if (password.trim().length === 0) {
      toast.error(`Please Enter password`);
    } else if (email.trim().length === 0) {
      toast.error(`Please Enter Your Email`);
    } else if (!/^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9-]+(?:\.[a-z0-9-]+)+(?:\.[a-z0-9-]+)*$/.test(email)) {
      toast.error(`Please Enter Your valid Email Address`);
    } else {
      try {
        const response = await toast.promise(
          apiClient.post(
            `${APIURL}/auth/local`,
            {
              userEmail: email,
              userPassword: password
            },
            {
              withCredentials: true,
              credentials: 'same-origin'
            }
          ),
          {
            pending: 'Please wait...'
          }
        );
        console.log(response);

        if (response.data.status === true && response.data.error === null) {
          toast('Successfully Authenticated', { type: 'success' });
          secureLocalStorage.setItem('LogObject', {
            userID: response.data.content.userID,
            email: email,
            loggedin: true
          });
          navigate('/dashboard');
          const userID = response.data.content.userID;

          // For sign-in
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'user_signed_in',
            'user_id': userID,
            'user_email': email
          });
        } else {
          toast('Something went wrong While authenticating', {
            type: 'error'
          });
        }
      } catch (error) {
        console.log(error);
        if (error.response.status === 403) {
          toast.error(`${error.response.data.content.message}`);
        } else {
          toast.error(`${error.message}`);
        }
      }
    }
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const action = searchParams.get('action');
    if (action === 'login') {
      const userString = searchParams.get('user');
      const userSuccess = searchParams.get('success');
      const userFailed = searchParams.get('failed');
      if (userFailed === 'true') {
        setFailed(true);
      }
      if (userSuccess === 'true') {
        setSuccess(true);
      }
      if (userString) {
        setUser(JSON.parse(decodeURIComponent(userString)));
      }
    }
  }, [location.search]);

  useEffect(() => {
    if (failed) {
      toast.error('Failed to login please try with valid Gmail-ID!');
      navigate('/login');
      return;
    }
    if (user) {
      secureLocalStorage.setItem('userID', user.userID);
      secureLocalStorage.setItem('LogObject', {
        userID: user.userID,
        email: user.userEmail,
        loggedin: true
      });
        navigate('/dashboard');
        toast.success('Logged In successfully!');
    }
    else {
      if(success && user === undefined){
        toast.error('Failed to login please try with valid Gmail-ID!');
        navigate('/login');
      }
    }
  }, [user, failed]);
  return (
    <>
      <Helmet>
        ‍<title>Login | Play Fund Win</title>‍
        <meta
          name="description"
          content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities."
        />
      </Helmet>
      {userDetail === undefined || userDetail === null ? (
        <>
          <Siteheader />
          <section className="h-100 w-100 bg-f2 px-lg-5 py-5 position-relative bg-before-after">
            <div className="container h-100 p-lg-5 ">
              <div className="d-flex align-items-center justify-content-center h-100 p-lg-5 py-5">
                <div className="d-flex flex-column p-xl-5 col-lg-6 col-md-6 col-12">
                  {/* <Link to={"/find-a-draw"} className="border-0 rounded-pill btn pfw-button-red secbutton py-2 px-5 mt-4 mb-2 font-face-gb-semibold" style={{lineHeight: 2}}>Play as a Guest</Link> */}
                  <label className="mt-4" htmlFor="username ">
                    Email
                  </label>
                  <input
                    type="text"
                    onChange={(event) => setEmail(event.target.value)}
                    name="email"
                    placeholder="Email"
                    className="rounded-pill align-self-center w-100 px-3 p-3 bg-white border-0"
                  />

                  <label className="mt-2" htmlFor="password ">
                    Password
                  </label>
                  <input
                    type="password"
                    onChange={(event) => setPassword(event.target.value)}
                    placeholder="*********"
                    name="password"
                    className="rounded-pill align-self-center w-100 px-3 p-3 bg-white border-0"
                  />

                  <button
                    onClick={ValidateUser}
                    className=" mt-4 btn pfw-button-red rounded-pill align-self-center w-100 px-3 p-2 fs-5 fw-bold"
                    type="button"
                    name="button"
                  >
                    Login
                  </button>
                  <div className="d-flex justify-content-center ">
                    <div className="pfw-awesome-divider" data-label="or"></div>
                  </div>
                  <button
                    onClick={handleOAuthLogin}
                    className="btn pfw-button-blue google-button rounded-pill d-flex align-items-center justify-content-center w-100 px-3 py-2 fs-5 fw-bold position-relative"
                  >
                    <div
                      className="position-absolute d-flex align-items-center justify-content-center"
                      style={{
                        width: '40px',
                        height: '40px',
                        background: 'white',
                        borderRadius: '50%',
                        border: '2px solid #4285F4',
                        left: '20%',
                        transform: 'translateX(-50%)'
                      }}
                    >
                      <img
                        src={require('../assets/images/google_logo.png')}
                        alt="Google"
                        style={{ height: '85%', width: '85%', objectFit: 'contain' }} // Increase the logo's size by 20%
                      />
                    </div>
                    <span>Login with Google</span>
                  </button>

                  <div className="d-flex align-items-center justify-content-center pt-5 w-100">
                    <Link to={'/user-registration'} className="branding3  fw-bold">
                      Register
                    </Link>
                    <span className="px-2 fw-bold"> | </span>
                    <Link to={'/forget-password'} className="branding3  fw-bold">
                      Lost Password
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <ToastContainer />

          <SiteFooter />
        </>
      ) : (
        navigate('/dashboard')
      )}
    </>
  );
}

import React, { useEffect, useState, Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
//import { DrawData } from "../Data/Draw-data";
import { DrawDataSession } from "./Cart";
import { GetDrawList } from '../Data/actionMethods'
import Moment from "moment";
import CountdownTimer from './CountdownTimer';
import { LogoLoader } from "./imageLoader";


function SetDrawDetails(p) {
  DrawDataSession.data = p;
}

export default function HomeDrawSliderMonthly({ slicedData }) {
  const [DrawData, SetDrawData] = useState([]);
  const [drawisActive, setDrawisActive] = useState(false);
  
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className="custom-arrow next-arrow"
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className="custom-arrow prev-arrow"
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  }
  

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
          infinite: true,
          autoplay: true,
        }
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          infinite: true,
          autoplay: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          arrows: false,
          infinite: true,
          autoplay: true,
        }
      },


    ]
  };

  return (
    <div className="bg-branding1 pb-5"> 
      <div className="container">
 <div className='row align-items-center pt-5 py-3 mb-2 mobile-view'>
            <div className='col-md-6 draw-title-data'>
              <div className='row align-items-center'>
                <div className="col-md-1"><img src={require('../assets/images/playicon.png')} style={{ width : '100%' }} /></div>
                <div className="col-md-10 text-white p-0" style={{ }}><h3 className="m-0 p-0"><span className='branding3 font-face-gb-semibold'>Draws In Focus</span> on Play Fund Win</h3></div>
              </div>
            </div>
            <div className='col-md-6 text-right d-flex view-all-button' style={{ justifyContent: "right" }}><Link to="/find-a-draw/" className="border-0 rounded-pill btn pfw-button-red secbutton py-3 px-4 my-4 font-face-gb-semibold">View all</Link></div>
          </div>
          </div>
      <div className="monthly-slider px-5" style={{ width: '100%' }}>
        <div className='container'>
         
          <Slider {...settings} className="py-4">
            {slicedData.slice(0, 3).map((p, index) => {

if(p.TestDraw === 1) {
                  
} else {

              
              return (



                <div className='col-lg-3 col-md-3 col-sm-12 font-face-gb px-5 position-relative w-100' key={p.id} id={`id-${index}`}>

                  {!!(p.HeaderImage) ?
                    <>
                      {p.Redirection ? <a href={p.RedirectionUrl} className="text-decoration-none" target={"_blank"}>
                        <div className="shadow-sm bg-white draw-slider-data" style={{ borderRadius: 20 }}><div className={`main-header animation-shine-draw text-center draw-id-${p.drawID}`} style={{
                          padding: '60px 0px', backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.62),${p.BrandingColor2}), ${p.HeaderImage.includes("http")
                            ? `url(${p.HeaderImage})`
                            : `url(${require('../assets/images/draws/' + p.HeaderImage)})`
                            }`, backgroundSize: 'cover', borderTopLeftRadius: 15, borderTopRightRadius: 15
                        }}>
                          <LogoLoader src={p.logoUrl} style={{ width: '100%', height: '100px', objectFit: 'contain' }} />
                          {/* <img className="" src={require(`../assets/images/logo/${p.logoUrl}`)} style={{ width: '100%', height: '100px', objectFit: 'contain' }}></img> */}
                        </div>
                          <div className="draws-title bg-white text-center" style={{ borderBottomLeftRadius: 15, borderBottomRightRadius: 15 }}>
                            <h4 className="pt-3 fs-5 font-face-gb branding1" style={{ minHeight: 64 }}>{p.name}</h4>
                          </div>
                        </div>
                      </a>

                        :
                        <Link to={"/draw/" + p.slug} onClick={() => SetDrawDetails(p)} className="text-decoration-none">
                          <div className="shadow-sm bg-white draw-slider-data" style={{ borderRadius: 20 }}><div className={`main-header animation-shine-draw text-center draw-id-${p.drawID}`} style={{
                            padding: '60px 0px', backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.62),${p.BrandingColor2}), ${p.HeaderImage.includes("http")
                              ? `url(${p.HeaderImage})`
                              : `url(${require('../assets/images/draws/' + p.HeaderImage)})`
                              }`, backgroundSize: 'cover', borderTopLeftRadius: 15, borderTopRightRadius: 15
                          }}>
                            <LogoLoader src={p.logoUrl} style={{ width: '100%', height: '100px', objectFit: 'contain' }} />
                            {/* <img className="" src={require(`../assets/images/logo/${p.logoUrl}`)} style={{ width: '100%', height: '100px', objectFit: 'contain' }}></img> */}
                          </div>
                            <div className="draws-title bg-white text-center" style={{ borderBottomLeftRadius: 15, borderBottomRightRadius: 15 }}>
                              <h4 className="pt-3 fs-5 font-face-gb branding1" style={{ minHeight: 64 }}>{p.name}</h4>
                            </div>
                          </div>
                        </Link>
                      }
                    </>
                    :
                    <>
                      {p.Redirection ? <a href={p.RedirectionUrl} className="text-decoration-none" target={"_blank"}>
                        <div className="shadow-sm bg-white draw-slider-data" style={{ borderRadius: 20 }}><div className={`main-header animation-shine-draw text-center draw-id-${p.drawID}`} style={{ padding: '60px 0px', backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.62),${p.BrandingColor2}),url(${require(`../assets/images/hero-banner.jpg`)})`, backgroundSize: 'cover', borderTopLeftRadius: 15, borderTopRightRadius: 15 }}>
                          <LogoLoader src={p.logoUrl} style={{ width: '100%', height: '100px', objectFit: 'contain' }} />
                          {/* <img className="" src={require(`../assets/images/logo/${p.logoUrl}`)} style={{ width: '100%', height: '100px', objectFit: 'contain' }}></img> */}
                        </div>
                          <div className="draws-title bg-white text-center" style={{ borderBottomLeftRadius: 15, borderBottomRightRadius: 15 }}>
                            <h4 className="pt-3 fs-5 font-face-gb branding1" style={{ minHeight: 64 }}>{p.name}</h4>
                          </div>
                        </div>
                      </a>
                        :
                        <Link to={"/draw/" + p.slug} onClick={() => SetDrawDetails(p)} className="text-decoration-none">
                          <div className="shadow-sm bg-white draw-slider-data" style={{ borderRadius: 20 }}><div className={`main-header animation-shine-draw text-center draw-id-${p.drawID}`} style={{ padding: '60px 0px', backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.62),${p.BrandingColor2}),url(${require(`../assets/images/hero-banner.jpg`)})`, backgroundSize: 'cover', borderTopLeftRadius: 15, borderTopRightRadius: 15 }}>
                            <LogoLoader src={p.logoUrl} style={{ width: '100%', height: '100px', objectFit: 'contain' }} />
                            {/* <img className="" src={require(`../assets/images/logo/${p.logoUrl}`)} style={{ width: '100%', height: '100px', objectFit: 'contain' }}></img> */}
                          </div>
                            <div className="draws-title bg-white text-center" style={{ borderBottomLeftRadius: 15, borderBottomRightRadius: 15 }}>
                              <h4 className="pt-3 fs-5 font-face-gb branding1" style={{ minHeight: 64 }}>{p.name}</h4>
                            </div>
                          </div>

                        </Link>


                      }
                    </>
                  }
                  
                </div>
          
              );
                }
            })}
          </Slider>
        </div>
      </div>
    </div>
  );

}

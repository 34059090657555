import { React, useState, useEffect } from 'react';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { Cart } from '../components/Cart';
import { CartProvider, useCart } from 'react-use-cart';
// import StripeForm from './CheckoutForm';
import { toast, ToastContainer } from 'react-toastify';
import { MyCart } from '../components/Cart';
import secureLocalStorage from 'react-secure-storage';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { Home_Images } from '../components/images';
import Moment from 'moment';
import { CountryDropdown } from 'react-country-region-selector';
import CountryData from '../components/CountryData'
import AddressLoader from '../components/AddressLoader';
import { set } from 'lodash';

export default function Onpagecheckout(props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [fname, setfName] = useState('');
  const [lname, setlName] = useState('');
  const [address, setAddress] = useState('');
  const [postcode, setPostcode] = useState('');
  const [city, setAddress2] = useState('');
  const [country, setCountry] = useState('');
  const [phone, setPhone] = useState('');
  const { cartTotal } = useCart();
  const [userObject, setUserObject] = useState('');
  const [oneShow, setOneShow] = useState(false);
  const [isLoggedin, setisLoggedin] = useState(false);
  const [password, setPassword] = useState('');
  const [consent3, setConsent3] = useState(false);
  const [clubsupport, setClubSupport] = useState('');
  const [club, setClub] = useState('');
  const [mobile, SetMobile] = useState('');


  const [clubsupportError, setClubSupportError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [fnameError, setfNameError] = useState('');
  const [lnameError, setlNameError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [address2Error, setAddress2Error] = useState('');
  const [postcodeError, setPostcodeError] = useState('');
  const [clubError, setClubError] = useState('');

  const [cityError, setCityError] = useState('');
  const [drawID, setDrawID] = useState('');

  const [timer, setTimer] = useState(null);
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [addressLoader, setAddressLoader] = useState(false);

  const [receiveUpdates, setReceiveUpdates] = useState(0);
  const [isReceiveUpdatesChecked, setIsReceiveUpdatesChecked] = useState(false);
  const [signedUpForStoriesAndInspiration, setSignedUpForStoriesAndInspiration] = useState(0);
  const [isSignUpForStoriesAndInspirationChecked, setIsSignUpForStoriesAndInspirationChecked] = useState(false);


  const [countryError, setCountryError] = useState(false);
  const [countryErrorShown, setCountryErrorShown] = useState(false);

  


  let userDetail = secureLocalStorage.getItem('LogObject');
  let userObjectData = secureLocalStorage.getItem('userObject');
  let APIURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (userDetail && secureLocalStorage.getItem('userObject') == null) {
      axios
        .get(`${APIURL}/users/${userDetail.userID}`, {
          withCredentials: true,
          credentials: 'same-origin'
        })
        .then((res) => {
          secureLocalStorage.setItem('userObject', res.data.content[0]);
          let temp = res.data.content[0];
          setUserObject(temp);
          setEmail(temp.userEmail);
          setEmail(temp.userEmail);
          setfName(temp.userFirstName);
          setlName(temp.userSurname);
          setAddress(temp.userAddressLine1);
          setPostcode(temp.userAddressPostcode);
          // setClubSupport(temp.fcSupport);
          setisLoggedin(true);
        });
    } else if (secureLocalStorage.getItem('userObject') != null) {
      setEmail(userObjectData.userEmail);
      setfName(userObjectData.userFirstName);
      setlName(userObjectData.userSurname);
      setAddress(userObjectData.userAddressLine1);
      setPostcode(userObjectData.userAddressPostcode);
      // setClubSupport(userObjectData.fcSupport);
      setisLoggedin(false);
      // setOneShow(true);
    } else {
      setEmail('');
      setfName('');
      setlName('');
      setAddress('');
      setPostcode('');
      setisLoggedin(false);
      setOneShow(true);
    }
  }, []);

  useEffect(() => {
if(props.drawID) {
  setDrawID(props.drawID)
}
  },[props]);

  const validateForm = () => {
    let valid = true;

    if(!club) {
      setClubError(true);
      valid = false;
    } else {
      setClubError(false);
    }

    if (!email) {
      //toast.error('Email is required');
      valid = false;
      setEmailError(true);
    } else {
      setEmailError('');
     
    }

    if (!fname) {
      //toast.error('First name is required');
      valid = false;
     setfNameError(true);
    } else {
      setfNameError('');
     
    }

    if (!lname) {
      //toast.error('Last name is required');
      valid = false;
     setlNameError(true);
    } else {
      setlNameError('');
     
    }

    if (!postcode) {
      //toast.error('Postcode is required');
      valid = false;
      setPostcodeError(true);
  } else {
      setPostcodeError('');
  }

  if (!country) {
    //toast.error('Country is required');
    valid = false;
    setCountryError(true); // Set the countryError state to true if country is not selected
  } else {
    setCountryError(false); // Set the countryError state to false if country is selected
  }

    if (!city) {
      //toast.error('City is required');
      valid = false;
      setAddress2Error(true);
    } else {
      setCityError('');
    }
   
    // if (!clubsupport) {
    //   //toast.error('Club Support is required');
    //   valid = false;
    //  setClubSupportError(true);
    // } 

    if (!address) {
      //toast.error('Address is required');
      valid = false;
      setAddressError(true);
    } else {
      setAddressError('');
    }

   

    return valid;
  };

  const updatePostcode = (enteredPostcode) => {
    setPostcode(enteredPostcode);
    clearTimeout(timer);

    if (!country && !countryErrorShown) {
      toast.error('Please select a country first');
      setCountryError(true);
      setCountryErrorShown(true); // Set countryErrorShown to true
      return;
    }
    

    const newTimer = setTimeout(() => {

     
      
      setAddressLoader(true)
      axios.request({
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://ws.postcoder.com/pcw/PCWW5-FVLAY-K6FGB-KSTYM/autocomplete/${country}/${enteredPostcode}?format=json`,
      })
        .then((response) => {
          const predictions = response?.data?.predictions || [];
          let allSuggests = [];
          predictions.map(address => {
            console.log("address", address)
            if (address.complete) {
              const splitAddress = address.prediction.split(',')
              setAddress(splitAddress[0] + " " + splitAddress[1])
              setAddress2(splitAddress[2])
              allSuggests.push(address.prediction)
              console.log("allSuggests", allSuggests)
              setAddressSuggestions(allSuggests);
            }
          }
          )
          setAddressLoader(false);
        })
        .catch((error) => {
          console.log(error);
          setAddressLoader(false);
        });
    }, 500)
    setTimer(newTimer);
  };

  function SetMobileNumber(val) {
    SetMobile(val);
  }

  const buynow = async () => {
    if (validateForm()) {
      try {
       
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/transactions/checkout/free`, {
          drawID: drawID,
          userEmail: email,
          quantity: 1,
          userFirstName: fname,
          userSurname: lname,
          userAddressPostcode: postcode,
          userAddressLine1: address,
          userAddressLine2: city,
          // fcSupport : clubsupport,
          club: club,
        });


        console.log('response' , response);
       
        if (response.status === 200) {
          
        
          if(response.data.content.transactionID) {
            navigate(`/payment-success/${response.data.content.transactionID.toString()}`);
          } else {
            toast.success('Free Entry Successful');
          }
          
        } else {
          
          toast.error('Free Entry Failed');
        }
      } catch (e) {

        console.log('e',e.response.data)
        {e.response.data.message === "You have already entered this draw" ?
        toast.error('You already have Free Entry')
        :
        toast.error('Please Enter Valid Email Address')
        setEmailError(true)
      }
      }
    } else {
      toast.error('Please fill in all required fields');
    }
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError(false);
  };

  const handleFnameChange = (event) => {
    setfName(event.target.value);
    setfNameError(false);
  };

  const handleLnameChange = (event) => {
    setlName(event.target.value);
    setlNameError(false);
  };

  // const hanldeClubSupportChange = (event) => {
  //   setClubSupport(event.target.value);
  //   setClubSupportError(false);
  // };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
    setAddressError(false);
  };

  const handleAddress2Change = (event) => {
    setAddress2(event.target.value);
    setAddress2Error(false);
  };

  const handlePostCodeChange = (event) => {
    updatePostcode(event.target.value);
    setPostcodeError(false);
  };

  const handleClubChange = (event) => {
    setClub(event.target.value);
    setClubError(false);
  };

  const handleCountryChange = (val) => {
    setCountry(val);

    if (val) {
      setCountryError(false);
    } else {
      setCountryError(true);
    }

    const _countryData = CountryData.find(Data => Data.name.trim().toLocaleLowerCase() === val.trim().toLocaleLowerCase());
    const callingCode = _countryData?.code;
    SetMobileNumber(`+${callingCode} `);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    buynow();
  };

  return (
    <div className="bg-checkout-onpage mb-4">
      <div className="container">
        <div className="row flex-reverse py-2">
          <div className="col-md-12 order-md-1">
           

            <form className="needs-validation" noValidate onSubmit={handleSubmit}>
              <div className="row pe-3">
                <div className="mb-3 form-label-group mobile-fields">
                 
                  <input
                    type="email"
                    className={`form-control py-3 px-4 rounded-pill ${emailError ? 'is-invalid' : ''}`}
                    id="email"
                    placeholder="Email *"
                    onChange={(event) => handleEmailChange(event)}
                    value={email}
                  />
                  {emailError ? <div className="invalid-feedback">Please Enter your Email Address</div> : " " }
                </div>

                <div className="mb-3 form-label-group mobile-fields">

                    <select onChange={(event) => handleClubChange(event)} className={`form-control py-3 px-4 rounded-pill ${clubError ? 'is-invalid' : ''}`} id="premierLeagueClubs">
                      <option value="" disabled selected>Choose your favorite premier league club *</option>
                      <option value="Arsenal">Arsenal</option>
                      <option value="Aston Villa FC">Aston Villa FC</option>
                      <option value="Bournemouth AFC">Bournemouth AFC</option>
                      <option value="Brentford">Brentford</option>
                      <option value="Brighton & Hove Albion">Brighton & Hove Albion</option>
                      <option value="Burnley FC">Burnley FC</option>
                      <option value="Chelsea FC">Chelsea FC</option>
                      <option value="Crystal Palace FC">Crystal Palace FC</option>
                      <option value="Everton FC">Everton FC</option>
                      <option value="Fulham FC">Fulham FC</option>
                      <option value="Liverpool FC">Liverpool FC</option>
                      <option value="Luton Town FC">Luton Town FC</option>
                      <option value="Manchester City FC">Manchester City FC</option>
                      <option value="Manchester United FC">Manchester United FC</option>
                      <option value="Newcastle United FC">Newcastle United FC</option>
                      <option value="Nottingham Forest FC">Nottingham Forest FC</option>
                      <option value="Sheffield United FC">Sheffield United FC</option>
                      <option value="Tottenham Hotspur FC">Tottenham Hotspur FC</option>
                      <option value="West Ham United FC">West Ham United FC</option>
                      <option value="Wolverhampton Wanderers">Wolverhampton Wanderers</option>
                    </select>

                    {clubError ? <div className="invalid-feedback">Please Select the Premier League Shirt of your choice</div> : " " }

                </div>
                
                <div className="col-md-6 mb-3 form-label-group">
                  
                  <input
                    type="text"
                    className={`form-control py-3 px-4 rounded-pill ${fnameError ? 'is-invalid' : ''}`}
                    id="firstName"
                    placeholder="First name *"
                    onChange={(event) => handleFnameChange(event)}
                    value={fname}
                  />
                  <div className="invalid-feedback">Please Enter your First Name </div>
                </div>
                <div className="col-md-6 mb-3">
                  
                  <input
                    type="text"
                    className={`form-control py-3 px-4 rounded-pill ${lnameError ? 'is-invalid' : ''}`}
                    id="lastName"
                    placeholder="Last name *"
                    required
                    onChange={(event) =>  handleLnameChange(event)}
                    value={lname}
                  />
                  <div className="invalid-feedback">Please Enter your Last Name</div>
                </div>

                <div className={`col-md-6 mb-3`}>
                <CountryDropdown
                  classes={`custom-select d-block w-100 py-3 px-4 form-control rounded-pill ${countryError ? 'is-invalid' : ''}`}
                  value={country}
                  onChange={(val) => handleCountryChange(val)}
                ></CountryDropdown>

                {countryError && <div className="invalid-feedback">Please Select your Country</div>}
              </div>

                <div className="col-md-6 mb-3">
                 
                <input type="text" className={`form-control py-3 px-4 rounded-pill ${postcodeError ? 'is-invalid' : ''} `}  placeholder='Postcode *' id="postcode" onChange={event => handlePostCodeChange(event) } value={postcode} />
                {postcodeError ? <div className="invalid-feedback">
                Please Enter your Postcode
                  </div> : "" }
                </div>
                

                <div className="col-md-6 mb-3 position-relative">
                 
                {addressLoader ?
                      <AddressLoader /> : <></>}
                    <input type="text" className={`form-control py-3 px-4 rounded-pill ${addressError ? 'is-invalid' : ''} `} placeholder='Street Address *' id="address" onChange={event => handleAddressChange(event)} value={address} />
                    {addressError ? <div className="invalid-feedback">
                    Please Enter your Street Address
                    </div> : "" }
                </div>
                <div className="col-md-6 mb-3 position-relative">
                  
                {addressLoader ?
                      <AddressLoader /> : <></>}
                    <input type="text" className={`form-control py-3 px-4 rounded-pill ${address2Error ? 'is-invalid' : "" } `} placeholder='Town / City *' id="city" onChange={event => handleAddress2Change(event) } value={city} />
                    {address2Error ? <div className="invalid-feedback">
                    Please Enter your City
                    </div> : "" }
                </div>

                
                
                {/* <div className="mb-3 mobile-fields">
                  
                  <input
                    type="text"
                    className={`form-control py-3 px-4 rounded-pill ${clubsupportError ? 'is-invalid' : ''}`}
                    id="clubsupport"
                    placeholder="Football Club you Support *"
                    onChange={(event) => hanldeClubSupportChange(event)}
                    value={clubsupport}
                  />
                  {clubsupportError ? <div className="invalid-feedback">Please Enter your Football Club you are Supporting.</div> : " " }
                </div> */}
              </div>

              
            </form>

            <p className="link-text text-white text-left alternative-text mt-3 mb-3" style={{lineHeight : '22px'}}>
                        By entering the draw, you accept our{' '}
                        <Link
                          className="branding2 text-decoration-none green-hover"
                          to={`/prize-draw-terms-conditions`}
                        >
                          terms and conditions.
                        </Link>{' '}<br/>
                        Alternative entry routes are available.
                      </p>

          </div>
        </div>
        <div className="button free-checkout">
          <button className="border-0 text-white rounded-pill button-temp4 btn bg-branding3 my-0 mb-0 mx-0 font-face-gb-semibold" style={{ lineHeight: 2, padding: '16px 24px' }} onClick={() => buynow()}>
            Enter Draw
          </button>
        </div>  
      </div>

      <ToastContainer />
    </div>
  );
}

import React, { useEffect, useState, Component } from 'react';
import Slider from 'react-slick';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { MyCart, Cart, HomeDrawDataSession, DrawDataSession } from '../components/Cart';
import { CartProvider, useCart } from 'react-use-cart';
//import { DrawData } from "../Data/Draw-data";
import { GetDrawListLimit, GetDrawListHeroSlider } from '../Data/actionMethods';
import Moment from 'moment';
import CountdownTimer from './CountdownTimer';
import { LogoLoader } from './imageLoader';
import SkeletonLoader from './SkeletonLoader';
import { toast, ToastContainer } from 'react-toastify';
import HomeDrawSliderTrending from './HomeDrawSliderTrending';
import HomeDrawSlider from './HomeDrawSlider';
import HeroSliderHome from './heroSliderHome';
import HomeDrawSliderMonthly from './HomeDrawSliderMonthly';

function GetDrawListSlider() {
  const [DrawData, SetDrawData] = useState([]);
  const [DrawDataHero, SetDrawDataHero] = useState([]);
  const [drawisActive, setDrawisActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [Fetched, setFetched] = useState(false);
  const params = useParams();
  const [ItemCount, setItemCount] = useState();
  const [AmountText, setAmountText] = useState();
  const [isSubscription, setSubscription] = useState(true);
  const { AddInCart, addItem } = useCart();
  const Navigate = useNavigate();
  const { isEmpty, items } = useCart();

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return <div className="custom-arrow next-arrow" style={{ ...style, display: 'block' }} onClick={onClick} />;
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return <div className="custom-arrow prev-arrow" style={{ ...style, display: 'block' }} onClick={onClick} />;
  }
  useEffect(() => {
    const DrawName = params.name;

    if (DrawName === 'petrus-subscription-draw') {
      window.location.replace('https://playfundwin.com/draw/petrus-community-draw');
    }

    GetDrawListLimit().then((res) => {
      // ////console.log(res.data.content);
      let dt = [];

      if (res.status && res.data && res.data.content) {
        let data = res.data.content;

        for (let i = 0; i < data.length; i++) {
          data[i].drawContent = data[i].drawContent.length == 0 ? [] : JSON.parse(data[i].drawContent);
          // ////console.log("data i",data[i].drawContent);
          if (data[i].drawContent.length == 0) {
            // ////console.log("Here will be undefined",data[i])
            let elm = {};
            //please create object here
            elm.id = i;
            elm.drawID = data[i].drawID;
            elm.name = data[i].drawName;
            elm.price = 5;
            elm.slug = data[i].slug;
            elm.logoUrl = data[i].logoUrl ? data[i].logoUrl : 'admin.png';
            elm.HeaderImage = data[i].HeaderImage;
            elm.FeaturedUrl = data[i].FeaturedUrl;
            elm.HeaderLeftImage = '';
            elm.BrandingColor1 = data[i].drawBrandPrimary;
            elm.BrandingColor2 = data[i].drawBrandSecondary;
            elm.content = '';
            elm.PlayContent = '-';
            elm.drawEndDate = data[i].drawEndDate;
            elm.drawStartDate = data[i].drawStartDate;
            elm.FundContent = data[i].FundContent;
            elm.WinContent = data[i].WinContent;
            elm.ticketCountLabel = data[i].ticketCountLabel;
            elm.subscription = data[i].subscription;
            elm.Redirection = data[i].Redirection;
            elm.RedirectionUrl = data[i].RedirectionUrl;
            elm.template = data[i].template;
            elm.prizeSlider = data[i].prizeSlider;
            //Hero Slider Data
            // elm.heroSliderTitle = data[i].heroSliderTitle;
            // elm.heroSliderDescription = data[i].heroSliderDescription;
            // elm.heroSlider = data[i].heroSlider;
            // elm.SliderCharityName = data[i].SliderCharityName;

            dt.push(elm);
          } else {
            data[i].drawContent.drawHostID = data[i].drawHostID;
            data[i].drawContent.drawHostCauseID = data[i].drawHostCauseID;
            data[i].drawContent.drawStartDate = data[i].drawStartDate;
            data[i].drawContent.drawEndDate = data[i].drawEndDate;
            data[i].drawContent.drawIsActive = data[i].drawIsActive;
            data[i].drawContent.drawIsActive = data[i].drawIsActive;
            data[i].drawContent.winnerData = data[i].drawWinners ? data[i].drawWinners : [];
            dt.push(data[i].drawContent);
          }
        }
      }

      const activeDraw = dt.filter((item) => {
        //if (item.heroSlider === true) {
        //   //console.log("heroSlider: ", true);
        //   return Moment() < Moment(item.drawEndDate) && Moment() > Moment(item.drawStartDate) && item.heroSlider;
        // } else {
        //   //console.log("heroSlider: ", false);
        return Moment() < Moment(item.drawEndDate) && Moment() > Moment(item.drawStartDate);
        //}
      });

      console.log('activeDraw', activeDraw);
      SetDrawData(activeDraw);
      //SetDrawData(activeDraw);
      setIsLoading(true);

      // SetDrawData(activeDraw);
      // setIsLoading(false);

      setFetched(true);
    });

    GetDrawListHeroSlider().then((res) => {
      // ////console.log(res.data.content);
      let dt = [];

      if (res.status && res.data && res.data.content) {
        let data = res.data.content;

        for (let i = 0; i < data.length; i++) {
          data[i].drawContent = data[i].drawContent.length == 0 ? [] : JSON.parse(data[i].drawContent);
          // ////console.log("data i",data[i].drawContent);
          if (data[i].drawContent.length == 0) {
            // ////console.log("Here will be undefined",data[i])
            let elm = {};
            //please create object here
            elm.id = i;
            elm.drawID = data[i].drawID;
            elm.name = data[i].drawName;
            elm.price = 5;
            elm.slug = data[i].slug;
            elm.logoUrl = data[i].logoUrl ? data[i].logoUrl : 'admin.png';
            elm.HeaderImage = data[i].HeaderImage;
            elm.FeaturedUrl = data[i].FeaturedUrl;
            elm.HeaderLeftImage = '';
            elm.BrandingColor1 = data[i].drawBrandPrimary;
            elm.BrandingColor2 = data[i].drawBrandSecondary;
            elm.content = '';
            elm.PlayContent = '-';
            elm.drawEndDate = data[i].drawEndDate;
            elm.drawStartDate = data[i].drawStartDate;
            elm.FundContent = data[i].FundContent;
            elm.WinContent = data[i].WinContent;
            elm.ticketCountLabel = data[i].ticketCountLabel;
            elm.subscription = data[i].subscription;
            elm.Redirection = data[i].Redirection;
            elm.RedirectionUrl = data[i].RedirectionUrl;
            elm.template = data[i].template;
            elm.prizeSlider = data[i].prizeSlider;
            //Hero Slider Data
            elm.heroSliderTitle = data[i].heroSliderTitle;
            elm.heroSliderDescription = data[i].heroSliderDescription;
            elm.heroSlider = data[i].heroSlider;
            elm.SliderCharityName = data[i].SliderCharityName;

            dt.push(elm);
          } else {
            data[i].drawContent.drawHostID = data[i].drawHostID;
            data[i].drawContent.drawHostCauseID = data[i].drawHostCauseID;
            data[i].drawContent.drawStartDate = data[i].drawStartDate;
            data[i].drawContent.drawEndDate = data[i].drawEndDate;
            data[i].drawContent.drawIsActive = data[i].drawIsActive;
            data[i].drawContent.drawIsActive = data[i].drawIsActive;
            data[i].drawContent.winnerData = data[i].drawWinners ? data[i].drawWinners : [];
            dt.push(data[i].drawContent);
          }
        }
      }

      const activeDrawHero = dt.filter((item) => {
        //if (item.heroSlider === true) {
        //   //console.log("heroSlider: ", true);
        //   return Moment() < Moment(item.drawEndDate) && Moment() > Moment(item.drawStartDate) && item.heroSlider;
        // } else {
        //   //console.log("heroSlider: ", false);
        return Moment() < Moment(item.drawEndDate) && Moment() > Moment(item.drawStartDate);
        //}
      });

      console.log('activeDrawHero', activeDrawHero);
      SetDrawDataHero(activeDrawHero);
      //SetDrawData(activeDraw);
      setIsLoading(true);

      // SetDrawData(activeDraw);
      // setIsLoading(false);

      setFetched(true);
    });

  }, []);

  const settingsHero = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false
        }
      }
    ]
  };

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 500,
    centerMode: true,
    centerPadding: '120px',
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false
        }
      }
    ]
  };
  //console.log('GetheroDatas', DrawData);
  ////console.log('GetheroDatasLoad', isLoading);

  const WaitForProcess = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  function updateSubscription() {
    setSubscription(!isSubscription);
  }

  //const template = HomeDrawDataSession.data.template;
  const parser = new DOMParser();

  //   const template = HomeDrawDataSession.data.template;
  //   const parser = new DOMParser();
  ////console.log("Active Draw", DrawData)
  const slicedData = DrawData;

  // let index= DrawData.findIndex((item) => item.drawID == '64ad5f16-ce1a-4313-b5d7-17e198349e89');

  //       if(index > -1){
  //           let tenmp = DrawData[0];
  //           let drawPush =DrawData[index];
  //           DrawData[0] = drawPush;
  //           DrawData[index] = tenmp;
  //           //console.log("acive draw rearrange",activeDraw)
  //       }

  //       DrawData.splice(index,1);
  //       console.log("Hero Draw", DrawData,index);

  //const activeDrawHeros = DrawData.filter((item) => item.heroSlider === true);

  const slicedDataHero = DrawDataHero;
  ////console.log('slicedData old ', slicedData);

  if (isLoading === false || DrawData.length === 0 || DrawDataHero.length === 0) {
    return (
      <div>
        <div className="home-slider bg-branding1 mobile-slider-hero getlist" style={{ width: '100%' }}>
          <div className="">
            <Slider {...settingsHero}>
              {['#e64662', '#59b8b2', '#f9bf00', '#a1194f', '#0e3160']
                .sort(() => Math.random() - 0.5)
                .map((p) => {
                  return (
                    <SkeletonLoader
                      className="skaletons"
                      width="100%"
                      height={window.innerHeight > 800 ? '95vh' : '800px'}
                      borderRadius="10px"
                      bgColor="#e64662"
                      fgColor="#59b8b2"
                    />
                  );
                })}
            </Slider>
          </div>
        </div>

        <div className="position-relative">
          <div className="container logo-slider pt-5 pb-0">
            <div className="row align-items-center mb-2">
              <div className="col-md-6 draw-title-data">
                <div className="row align-items-center">
                  <div className="col-md-1">
                    <img src={require('../assets/images/playicon.png')} style={{ marginRight: 15 }} />
                  </div>
                  <div className="col-md-10 text-white" style={{ marginLeft: 10 }}>
                    <h3 className="m-0 p-0">
                      <span className="branding-4 font-face-gb-semibold">Popular</span> on Play Fund Win
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-md-6 text-right d-flex view-all-button" style={{ justifyContent: 'right' }}>
                <Link
                  to="/find-a-draw/"
                  className="border-0 rounded-pill btn pfw-button-red secbutton py-2 px-3 my-4 font-face-gb-semibold"
                >
                  View all
                </Link>
              </div>
            </div>
          </div>
          <div className="" style={{ width: '100%' }}>
            <div className="transparent-item hover-slider">
              <Slider {...settings}>
                {['#e64662', '#59b8b2', '#f9bf00', '#a1194f', '#0e3160']
                  .sort(() => Math.random() - 0.5)
                  .map((p) => {
                    return (
                      <div className="col-lg-3 col-md-3 col-sm-12 font-face-gb px-2 position-relative" key={p.id}>
                        <SkeletonLoader width="100%" height="250px" borderRadius="10px" bgColor="#fff" fgColor={p} />{' '}
                        <div
                          className="draws-title bg-white text-center"
                          style={{ borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}
                        >
                          <h4 className="pt-3 fs-5 font-face-gb branding1 pb-3" style={{ minHeight: 64 }}></h4>
                        </div>
                      </div>
                    );
                  })}
              </Slider>{' '}
            </div>
            <ToastContainer />
          </div>
        </div>

        {/* <div className="position-relative bg-branding1">
      
      <div className="container pt-5 pb-0">
      <div className='row align-items-center mb-2'>
        <div className='col-md-6 draw-title-data container trend-slider pt-5 pb-0'>
          <div className='row align-items-center'>
            <div className="col-md-1"><img src={require('../assets/images/playicon.png')} style={{ width: "100%" }} /></div>
            <div className="col-md-10 text-white px-0"><h3 className="m-0 p-0 popular-title"><span className='branding2 font-face-gb-semibold'>Trending</span> on Play Fund Win</h3></div>
          </div>
        </div>
        <div className='col-md-6 text-right d-flex view-all-button' style={{ justifyContent: "right" }}><Link to="/find-a-draw/" className="border-0 rounded-pill btn pfw-button-red secbutton py-3 px-4 my-4 font-face-gb-semibold">View all</Link></div>
      </div>
    </div>
    <div className="" style={{ width: '100%' }}>
      <div className="trending-slider transparent-item hover-slider">
    <Slider {...settings}>
            {["#e64662", "#59b8b2", "#f9bf00", "#a1194f", "#0e3160"].sort(() => Math.random() - 0.5).map((p) => {

              return (<div className='col-lg-3 col-md-3 col-sm-12 font-face-gb px-2 position-relative' key={p.id}><SkeletonLoader width="100%" height="250px" borderRadius="10px" bgColor="#fff" fgColor={p} /> <div className="draws-title bg-white text-center" style={{ borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}>
                <h4 className="pt-3 fs-5 font-face-gb branding1 pb-3" style={{ minHeight: 64 }}></h4>
              </div></div>)
            })}
          </Slider> </div>
        <ToastContainer/>
      </div>
    </div> */}
      </div>
    );
  } else {
    return (
      <>
        <HeroSliderHome heroData={slicedDataHero} isLoading={isLoading} />
        <HomeDrawSlider slicedDataDraw={slicedData} fetch={Fetched} />
        {/* <HomeDrawSliderTrending slicedData={slicedData} fetch={Fetched} orderBy="drawEndDate" /> */}
        <HomeDrawSliderMonthly slicedData={slicedData} fetch={Fetched} orderBy="drawEndDate" />
      </>
    );
  }

  // else {

  //     return (
  //       <div className="position-relative">

  //         <div className="container logo-slider pt-5 pb-0">
  //           <div className='row align-items-center mb-2'>
  //             <div className='col-md-6 draw-title-data'>
  //               <div className='row align-items-center'>
  //                 <div className="col-md-1"><img src={require('../assets/images/playicon.png')} style={{ marginRight: 15 }} /></div>
  //                 <div className="col-md-10 text-white" style={{ marginLeft: 10 }}><h3 className="m-0 p-0"><span className='branding-4 font-face-gb-semibold'>Popular</span> on Play Fund Win</h3></div>
  //               </div>
  //             </div>
  //             <div className='col-md-6 text-right d-flex view-all-button' style={{ justifyContent: "right" }}><Link to="/find-a-draw/" className="border-0 rounded-pill btn pfw-button-red secbutton py-2 px-3 my-4 font-face-gb-semibold">View all</Link></div>
  //           </div>
  //         </div>
  //         <div className="" style={{ width: '100%' }}>
  //           <div className="transparent-item hover-slider">
  //       <Slider {...settings}>
  //               {["#e64662", "#59b8b2", "#f9bf00", "#a1194f", "#0e3160"].sort(() => Math.random() - 0.5).map((p) => {

  //                 return (<div className='col-lg-3 col-md-3 col-sm-12 font-face-gb px-2 position-relative' key={p.id}><SkeletonLoader width="100%" height="250px" borderRadius="10px" bgColor="#fff" fgColor={p} /> <div className="draws-title bg-white text-center" style={{ borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}>
  //                   <h4 className="pt-3 fs-5 font-face-gb branding1 pb-3" style={{ minHeight: 64 }}></h4>
  //                 </div></div>)
  //               })}
  //             </Slider> </div>
  //           <ToastContainer/>
  //         </div>
  //       </div>
  //     )

  // }
}

export default function () {
  return (
    <>
      <CartProvider>
        <GetDrawListSlider />
      </CartProvider>
    </>
  );
}

import React from 'react'
import { Inner_Header_Images } from './images'

export default function Innerheader(props) {
  return (
    <div className="inner-header container py-5 bg-image-np" style={{backgroundImage: `url(${Inner_Header_Images.InnerHeaderBg})`, borderRadius: 10}}>
   <div className='container py-5'>
    <div className="row align-items-center py-4" >
      <div className="col-xl-12 col-lg-12 col-md-12 col-12 align-middle text-center m-auto">
        <h1 className="text-white display-3 font-face-gb-semibold">{props.title}</h1>
       </div>
    </div>
    </div>
  </div>
  )
}

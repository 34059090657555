import React, { useState } from 'react'
import Siteheader from '../components/header'
import SiteFooter from '../components/footer'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import apiClient from '../api.config';


export default function ChangePassword() {
  const Urlparams = useLocation().search;
  let APIURL = process.env.REACT_APP_API_URL;
  const [password, setPassword] = useState('');
  const [Confirmpassword, setConfirmpassword] = useState('');
  const Params_userID = new URLSearchParams(Urlparams).get("userID");
  const Params_resetToken = new URLSearchParams(Urlparams).get("resetToken");
  const navigate = useNavigate();

  // console.log(Params_userID,Params_resetToken);
  function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  async function ValidateUser() {
    if (Params_userID.trim().length === 0 && Params_resetToken.trim().length === 0) {
      toast.error('Invalid Reset token');
    } else if (Confirmpassword.trim().length === 0 && password.trim().length === 0) {
      toast.error('Please Enter Password and Confirm Password')
    } else if (password.trim().length === 0) {
      toast.error('Please Enter Password')
    } else if (Confirmpassword.trim().length === 0) {
      toast.error('Please Enter Confirm Password')
    } else if (password != Confirmpassword) {
      toast.error('Invalid Confirm Password')
    } else {
      try {

        const response = await toast.promise(apiClient.post(
          `${APIURL}/auth/reset`,
          {
            "userID": Params_userID,
            "userPasswordToken": Params_resetToken,
            "userPassword": Confirmpassword
          },
          {
            withCredentials: true,
            credentials: "same-origin"
          }
        ), {
          pending: 'Please wait...',
        }
        );

        if (response.data.content === 'Token Matches. Password Reset Succesfully') {
          toast("Passwort Reset Successfully", { type: "success" });
          await timeout(2000);
          navigate('/login');
        } else {
          toast("Passwort Reset Fail", { type: "error" });
        }
      } catch (error) {
        console.log(error);
        toast.error(`${error.message} Node Not found`);
      }
    }

  }
  return (
    <>
      <Helmet>‍
        <title>Reset Password | Play Fund Win</title>‍
        <meta name="description" content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities." />
      </Helmet>
      <Siteheader />
      <section className="h-100 w-100 bg-f2 container px-lg-5 py-5 position-relative bg-before-after">
        <div className="container h-100 p-lg-5 ">
          <div className="find-draw-form text-center">
            <h2 className='branding1 display-4 font-face-gb-semibold'>New Password</h2>
          </div>
          <div className="d-flex align-items-center justify-content-center h-50 p-lg-0 ">
            <div className="d-flex flex-column p-xl-5 col-lg-6 col-md-6 col-12">


              <input type="password" onChange={(event) => setPassword(event.target.value)} placeholder="New Password" className='mt-3 rounded-pill align-self-center w-100 px-3 p-3 bg-white border-0' />

              <input type="password" onChange={(event) => setConfirmpassword(event.target.value)} placeholder="Confirm New Password" className='mt-3 rounded-pill align-self-center w-100 px-3 p-3 bg-white border-0' />

              <button onClick={ValidateUser} className=" mt-4 pfw-button-green rounded-pill align-self-center w-25 px-3 p-2 fs-5 fw-bold" type="button" name="button">Save</button>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
      <SiteFooter />
    </>
  )
}

import React, { useEffect, useState } from 'react';
import moment from 'moment';
// import 'moment-timezone';


export default function TimeZoneSetting() {
  const [timezone, setTimezone] = useState('');
  useEffect(() => {
    const getTimezone = async () => {
      try {
        const { timeZone } = Intl.DateTimeFormat()  .resolvedOptions();
        const offset = new Date().getTimezoneOffset();
        const timezone = moment.tz.zone(timeZone).abbr(offset);
        setTimezone(timezone);
        if(timezone == 'BST'){
          console.log("timeZone:",timezone);
          moment.tz.setDefault('Etc/GMT');
        }else{
          console.log("timeZone:",timezone);
          moment.tz.setDefault(moment.tz.guess(true));
        }
      } catch (error) {
        console.error(error);
        moment.tz.setDefault(moment.tz.guess(true));
      }
    };
    getTimezone();
  }, []);

  return ( <pre className='d-none'>Your timezone is: {timezone}</pre>  ) }

  
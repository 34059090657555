import React from 'react';
import Siteheader from '../components/header';
import SiteFooter from '../components/footer';
import { useState, useEffect } from 'react';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import Moment from 'moment';
import { useNavigate, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Breadcrumb from '../components/breadcrum';

export default function PurchaseHistory(props) {
  const [login, setLogin] = useState(
    secureLocalStorage.getItem('LogObject') ? secureLocalStorage.getItem('LogObject').loggedin : false
  );
  const [PurchaseData, SetPurchaseData] = useState([]);
  const [PurchaseDataDefault, SetPurchaseDataDefault] = useState([]);
  const navigate = useNavigate();
  const user = secureLocalStorage.getItem('LogObject');
  let APIURL = process.env.REACT_APP_API_URL;
  let SITE_ID = process.env.REACT_APP_API_SITE_ID;
  const [sortKey, setSortKey] = useState('');
  const [sort, setSort] = useState('desc');
  const [search, setSearch] = useState('');
  const [filterEntries, setFilterEntries] = useState([]);
  const [displayDataActive, SetDisplayData] = useState([]);
  const [isLoaded, setisLoaded] = useState(false);
  const [timer, setTimer] = useState(null);
  const status = [
    '',
    'AWAITING_PAYMENT',
    'PROCESSING',
    'PAID',
    'FAILED',
    'CANCELLED',
    'REFUND_PARTIAL',
    'REFUND_FULL',
    'PRIZE_PAYOUT'
  ];
  const colorHex = ['', '#e64662', '#e64662', '#59b8b2', '#e64662', '#e64662', '#4a4a4a', '#4a4a4a', '#59b8b2'];
  const entrytype = ['', 'One-Off', 'Recurring', 'Subscription'];

  let userDetail = secureLocalStorage.getItem('LogObject');

  useEffect(() => {
    if (!login) {
      window.location.replace('/login');
    }
    fetchPurchaseData(user.userID);
  }, []);

  function fetchPurchaseData(userID) {
    axios
      .get(`${APIURL}/users/${userID}/data/${SITE_ID}`, {
        withCredentials: true,
        credentials: 'same-origin'
      })
      .then((res) => {
        console.log('res', res.data.content);
        res.data.content.drawEntries.forEach((element) => {
          element.transactionEndDate = res.data.content.transactions
            .filter((el) => el.transactionDrawEntriesID == element.drawEntryID)
            .map((e) => e.transactionDateTime)
            .join();
          element.transactionStatus = res.data.content.transactions
            .filter((el) => el.transactionDrawEntriesID == element.drawEntryID)
            .map((e) => e.transactionStatus)
            .join();
        });
        setisLoaded(true);
        SetPurchaseData(res.data.content.drawEntries);
        SetDisplayData(res.data.content.drawEntries);
        console.log('entry', res.data.content.drawEntries);
        SetPurchaseDataDefault(res.data.content.drawEntries);
      });
  }

  // function searchUserHandler(e) {
  //   setSearch(e.target.value);
  //   clearTimeout(timer);
  //   const newTimer = setTimeout(() => {
  //     if (e.target.value.length > 0) {
  //       axios.get(`${APIURL}/draws/${user.userID}/entriesfilter?search=${search}`,
  //         {
  //           withCredentials: true,
  //           credentials: "same-origin"
  //         }).then((res) => {
  //           if (res.status) {
  //             setFilterEntries(res.content);
  //           }
  //         });
  //     } else {
  //       axios.get(`${APIURL}/draws/${user.userID}/entriesfilter?search=${search}`,
  //         {
  //           withCredentials: true,
  //           credentials: "same-origin"
  //         }).then((res) => {
  //           setFilterEntries(res.content);
  //         });
  //     }
  //   }, 500);
  //   setTimer(newTimer);
  // }

  useEffect(() => {
    console.log('SearchWala', PurchaseData);
    if (search.trim().length >= 1 || search.trim().length == 0) {
      SetDisplayData(
        PurchaseData.filter((item) => {
          if (item.drawName.toLowerCase().includes(search.toLowerCase())) {
            return item;
          }
        })
      );
    }
  }, [search]);

  return (
    <>
      <Helmet>
        ‍<title>Purchase History | Play Fund Win</title>‍
        <meta
          name="description"
          content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities."
        />
      </Helmet>
      <Siteheader />

      {userDetail ? (
        <section className="purchase-history-page h-100 w-100 bg-f2 container px-lg-5 py-5 position-relative bg-before-after rounded-4">
          <Breadcrumb />
          <div className="container h-100 p-lg-5 pt-lg-3">
            <div className="find-draw-form text-center">
              <h2 className="branding1 display-4 font-face-gb-semibold">Purchase History</h2>
            </div>
          </div>
          {isLoaded ? (
            <div className="">
              {PurchaseData.length > 0 ? (
                <div className="container purchase-history">
                  <div className="d-flex justify-content-end mb-3">
                    <div role="search" className="draw-search position-relative" style={{ width: 300, marginTop: -36 }}>
                      <input
                        type="search"
                        id="draw-search-field"
                        className="search-field bg-white pe-4 py-2 rounded-pill border-0 fs-6 w-100"
                        placeholder="Search"
                        name="s"
                        style={{ height: 50, paddingLeft: 30 }}
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                      />

                      <input type="hidden" name="post_type" defaultValue="draw" />
                    </div>
                  </div>

                  <table className="text-left mx-auto bg-white rounded-4 w-100">
                    <thead className="bg-branding3" style={{ borderBottom: '2px solid #fff' }}>
                      <th className="p-3 text-white draw-name">Draw Name</th>
                      <th className="p-3 text-white purchase-datetime">Purchased Date & time</th>
                      <th className="p-3 text-white ticket-valid">Ticket valid till</th>
                      <th className="p-3 text-white entry-type">Entry Type</th>
                      <th className="p-3 text-white total-entries">Total Entries</th>
                      <th className="p-3 text-white total-price">Total Price</th>
                      <th className="p-3 text-white status">Status</th>
                    </thead>
                    {displayDataActive != 0 ? (
                      <tbody className="bg-white">
                        {displayDataActive.map((item, index) => {
                          return (
                            <tr key={item.id + index} style={{ borderBottom: '1px solid rgb(217, 217, 217)' }}>
                              <td className="p-3 draw-name">{item.drawName}</td>
                              <td className="p-3 purchase-datetime">{Moment(item.transactionEndDate).format('LLL')}</td>
                              <td className="p-3 ticket-valid">{Moment(item.drawEntryValidToDate).format('LLL')}</td>
                              <td className="p-3 entry-type">{entrytype[item.drawEntryType]}</td>
                              <td className="p-3 total-entries">{item.drawEntries}</td>
                              <td className="p-3 total-price">{item.drawEntryTotal}</td>
                              <td className="p-3 status" style={{ color: colorHex[item.transactionStatus] }}>
                                {status[item.transactionStatus]}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    ) : (
                      <tbody className="bg-white w-100 text-center py-3 d-block">
                        Not found with matching your query!
                      </tbody>
                    )}
                  </table>
                </div>
              ) : (
                <div className="text-center">
                  <p class="branding1 fs-5 text-center mb-0">You are not participating in live draws!</p>
                  <Link
                    to={'/find-a-draw'}
                    className="border-0 rounded-pill btn pfw-button-red secbutton py-2 px-5 my-4 font-face-gb-semibold"
                    style={{ lineHeight: 2 }}
                  >
                    Find a Draw
                  </Link>
                </div>
              )}
            </div>
          ) : (
            <p className="branding1 fs-5 text-center mb-0">Loading Data...</p>
          )}
        </section>
      ) : (
        ''
      )}

      <SiteFooter />
    </>
  );
}

import React, { useState, useEffect } from 'react'
import SiteFooter from '../components/footer'
import Siteheader from '../components/header'
import { Helmet } from 'react-helmet'
import { toast, ToastContainer } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage'
import axios from 'axios'
import apiClient from '../api.config'
import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment';
import Breadcrumb from '../components/breadcrum';
import localesData from '../assets/pfw_locales.json';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

export default function UserEditContactDetails() {


  const [website, SetWebsite] = useState("")
  const [instagram, SetInstagram] = useState("")
  const [facebook, SetFacebook] = useState("")
  const [twitter, SetTwitter] = useState("")
  const [country, setCountry] = useState("")
  const [userPhoneNumber, setUserPhoneNumber] = useState("")
  const [address1, setAddressLine1] = useState("")
  const [addressLine2, setAddressLine2] = useState("")
  const [postcode, SetPostcode] = useState("")
  const [fname, SetFname] = useState("")
  const [email, SetEmail] = useState("")
  const [Lname, SetLname] = useState("")
  const [dob, SetDOB] = useState(new Date())
  const [Zip, SetZip] = useState('')
  const userData = secureLocalStorage.getItem('LogObject');
  const navigate = useNavigate();
  const [SendDate, setSendDate] = useState();
  const [accountNo, setAccountNo] = useState('');
  const [sortCode, setSortCode] = useState('');
  const [payPalEmail, setPayPalEmail] = useState('');
  const [checkAccount, setCheckAccount] = useState({});
  const [defaultAccount, setDefaultAccount] = useState({});
  const [defaultPaymentDetails, setDefaultPaymentDetails] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [refresh, setRefresh] = useState(0);
  let APIURL = process.env.REACT_APP_API_URL;
  const siteId = process.env.REACT_APP_API_SITE_ID;




  useEffect(() => {
    if (!userData) {
      navigate('/login');
    } else {
      axios.get(`${APIURL}/users/${userData.userID}/data`, {
        withCredentials: true,
        credentials: "same-origin"
      }
      ).then(res => {
        console.log(res.content);
        SetEmail(res.data.content?.userEmail)
        SetFname(res.data.content?.userFirstName)
        SetLname(res.data.content?.userSurname)
        setAddressLine1(res.data.content?.userAddressLine1)
        setUserPhoneNumber(res.data.content?.userPhoneNumber)
        setAddressLine2(res.data.content?.userAddressLine2)
        SetPostcode(res.data.content?.userAddressPostcode)
        setCountry(res.data.content?.userAddressCountryLocaleID)
        let usermeta = res.data.content.userMeta ? JSON.parse(res.data.content.userMeta) : [];
        SetFacebook(usermeta?.facebook)
        SetWebsite(usermeta?.website)
        SetInstagram(usermeta?.instagram)
        SetTwitter(usermeta?.twitter)
        SetDOB(moment(res.data.content?.userDateOfBirth).format('YYYY-MM-DD'))

        setDefaultAccount({
          "userID": userData.userID,
          "userFirstName": res.data.content?.userFirstName,
          "userSurname": res.data.content?.userSurname,
          "userPhoneNumber": res.data.content?.userPhoneNumber,
          "userAddressLine1": res.data.content?.userAddressLine1,
          "userAddressLine2": res.data.content?.userAddressLine2,
          "userAddressCountryLocaleID": res.data.content?.userAddressCountryLocaleID,
          "userAddressPostcode": res.data.content?.userAddressPostcode,
          "userDateOfBirth": moment(res.data.content?.userDateOfBirth).format('YYYY-MM-DD'),
          "facebook": usermeta?.facebook,
          "website": usermeta?.website,
          "instagram": usermeta?.instagram,
          "twitter": usermeta?.twitter
        })
      }
      );
      axios.get(`${APIURL}/users/accountDetails/${userData.userID}`, {
        withCredentials: true,
        credentials: "same-origin"
      }
      ).then(res => {
        // console.log("account details........", res.data.content);
        setAccountNo(res.data.content[0]?.userAccountNumber)
        setSortCode(res.data.content[0]?.userSortCode)
        setPayPalEmail(res.data.content[0]?.userPaypalEmail);
        if (res.data.content[0]?.userAccountNumber || res.data.content[0]?.userSortCode || res.data.content[0]?.userPaypalEmail) {
          setCheckAccount(true);
        }
        setDefaultPaymentDetails(
          {
            "userAccountNumber": res.data.content[0]?.userAccountNumber,
            "userSortCode": res.data.content[0]?.userSortCode,
            "userPaypalEmail": res.data.content[0]?.userPaypalEmail
          }
        )

      });
    }
  }, [refresh])

  //check If Any Field Update
  useEffect(() => {
    if (defaultAccount.userFirstName !== fname
      || defaultAccount.userSurname !== Lname
      || defaultAccount.userPhoneNumber !== userPhoneNumber
      || defaultAccount.userAddressLine1 !== address1
      || defaultAccount.userAddressLine2 !== addressLine2
      || defaultAccount.userAddressCountryLocaleID !== country
      || defaultAccount.userAddressPostcode !== postcode
      || defaultAccount.userDateOfBirth !== dob
      || defaultAccount.facebook !== facebook
      || defaultAccount.website !== website
      || defaultAccount.instagram !== instagram
      || defaultAccount.twitter !== twitter) {
      setIsUpdated(true);
    } else if (defaultPaymentDetails.userAccountNumber !== accountNo
      || defaultPaymentDetails.userSortCode !== sortCode
      || defaultPaymentDetails.userPaypalEmail !== payPalEmail) {
      setIsUpdated(true);
    } else {
      setIsUpdated(false);
    }
  }, [fname, Lname, address1, addressLine2, country, userPhoneNumber, postcode, dob, accountNo, sortCode, payPalEmail, facebook, website, instagram, twitter])

  function isValidEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }
  async function SaveHandler() {
    if (fname == "" || fname.trim().length == 0) {
      toast.error("First Name is Required")
    } else if (Lname == "" || Lname.trim().length == 0) {
      toast.error("Last Name is Required")
    } else if (address1 == "" || address1.trim().length == 0) {
      toast.error("Address Line 1 is Required")
    } else if (postcode == "" || postcode.trim().length == 0) {
      toast.error("Postcode is Required")
    } else if (dob.trim().length === 0) {
      toast.error(`Please Enter Date of Birth`);
    } else if (accountNo && isNaN(accountNo)) {
      toast.error(`Please Enter Valid Account Number`);
    }
    else if (sortCode && isNaN(sortCode)) {
      toast.error(`Please Enter Valid Sort Code`);
    }
    else if (payPalEmail && !isValidEmail(payPalEmail)) {
      toast.error(`Please Enter Valid PayPal Email`);
    }
    else {
      try {
        let isAccountDetailsUpdate = false;
        let isPaymentDetailsUpdate = false;

        //check if current details is updated
        if (defaultAccount.userFirstName !== fname
          || defaultAccount.userSurname !== Lname
          || defaultAccount.userPhoneNumber !== userPhoneNumber
          || defaultAccount.userAddressLine1 !== address1
          || defaultAccount.userAddressLine2 !== addressLine2
          || defaultAccount.userAddressCountryLocaleID !== country
          || defaultAccount.userAddressPostcode !== postcode
          || defaultAccount.userDateOfBirth !== dob
          || defaultAccount.facebook !== facebook
          || defaultAccount.website !== website
          || defaultAccount.instagram !== instagram
          || defaultAccount.twitter !== twitter) {
          isAccountDetailsUpdate = true;
        }
        if (defaultPaymentDetails.userAccountNumber !== accountNo
          || defaultPaymentDetails.userSortCode !== sortCode
          || defaultPaymentDetails.userPaypalEmail !== payPalEmail) {
          isPaymentDetailsUpdate = true;
        }

        if (isAccountDetailsUpdate) {
          await toast.promise
            (apiClient.put(`${APIURL}/users`,
              {
                "userID": userData.userID,
                "userFirstName": fname,
                "userSurname": Lname,
                "userPhoneNumber": userPhoneNumber,
                "userAddressLine1": address1,
                "userAddressLine2": addressLine2,
                "userAddressPostcode": postcode,
                "userDateOfBirth": dob,
                "userAddressCountryLocaleID": country
              },
              {
                withCredentials: true,
                credentials: "same-origin"
              }
            ).then((e) => {
              setRefresh((prev)=>prev+1);
              toast.success('Your details updated Successfully');
            })
              .catch(
                (e) => {
                  console.log("e", e.response.data.error);
                  if (e.response.data.error.msg) {
                    toast.warn(e.response.data.error.msg);
                  } else {
                    toast.warn("Something went wrong while updating bank details");
                  }
                }), {
              pending: 'Please wait...',
              error: 'something went wrong'
            }
            );
        }

        if (isPaymentDetailsUpdate) {

          if (!checkAccount) {
            let accObject = {
              "userID": userData.userID,
              "userFirstName": fname,
              "userSurname": Lname,
              "userLegalName": fname + " " + Lname,
              "userAccountNumber": accountNo,
              "userSortCode": sortCode,
              "userPaypalEmail": payPalEmail
            }
            if (accountNo) accObject.userAccountNumber = accountNo;
            if (sortCode) accObject.userSortCode = sortCode;
            if (payPalEmail) accObject.userPaypalEmail = payPalEmail;
            await toast.promise
              (apiClient.post
                (`${APIURL}/users/addAccountDetails`, accObject,
                  {
                    withCredentials: true,
                    credentials: "same-origin"
                  }
                ),
              );
          } else {
            await toast.promise
              (apiClient.put
                (`${APIURL}/users/updateAccountDetails`, {
                  "userID": userData.userID,
                  "userAccountNumber": accountNo,
                  "userSortCode": sortCode,
                  "userPaypalEmail": payPalEmail,
                },
                  {
                    withCredentials: true,
                    credentials: "same-origin"
                  }
                ).then((e) => {
                  setRefresh((prev)=>prev+1);
                  toast.success('Payment details Updated Successfully');
                }).catch(
                  (e) => {
                    console.log("e", e.response.data.error);
                    if (e.response.data.error.msg) {
                      toast.warn(e.response.data.error.msg);
                    } else {
                      toast.warn("Something went wrong while updating bank details");
                    }
                  }),
                {
                  pending: 'Updating Payment details...',
                }
              );
          }

        }

      } catch (error) {
        console.log(error);
      }

    }
  }


  return (
    <>
      <Helmet>‍
        <title>User Edit Profile Details | Play Fund Win</title>‍
        <meta name="description" content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities." />
      </Helmet>
      <Siteheader />
      <ToastContainer />
      <section className="register-section h-100 w-100">

        <div className="container h-100 p-xl-5 py-5 bg-f2 position-relative bg-before-after" style={{ borderRadius: 10 }}>
          <Breadcrumb />
          <div className="d-flex align-items-center justify-content-center h-100 pb-5 pt-4">
            <div className="d-flex flex-column col-xl-9 col-lg-9 col-md-12 col-12 row">
              <div className='align-items-center'>
                <h1 className='branding1 display-4 font-face-gb-semibold'>Profile Detail</h1>
              </div>

              {/* Basic */}
              <div className='row mt-5'>
                <div className='col-sm mb-md-0 mb-3'>
                  <label htmlFor="firstname profile-form-field">First Name</label>
                  <input type="text" id="firstname" name="firstname" value={fname} onChange={(e) => SetFname(e.target.value)} placeholder="First Name" className='rounded-pill align-self-center w-100 px-4 p-3 bg-white border-0' />
                </div>
                <div className='col-sm'>
                  <label htmlFor="lname">Last Name</label>
                  <input type="text" id="lname" name="lname" value={Lname} onChange={(e) => SetLname(e.target.value)} placeholder="Last Name" className='rounded-pill align-self-center w-100 px-4 p-3 bg-white border-0' />
                </div>
              </div>


              <div className='row mt-5'>
                <div className='col-sm mb-md-0 mb-3'>
                  <label>Date of Birth *</label>
                  <input type="date" placeholder="Date of birth (DD/MM/YYYY)*" value={moment(dob).format('YYYY-MM-DD')} onInput={(event) => SetDOB(event.target.value)} name="date of birth" className='rounded-pill align-self-center w-100 px-3 p-3 bg-white border-0' />
                </div>
                <div className='col-sm'>
                  <label htmlFor="userPhoneNumber">Phone Number</label>
                  <PhoneInput
                    placeholder="+44 0000000000"
                    defaultCountry="GB"
                    countryCallingCodeEditable={false}
                    international
                    value={`${userPhoneNumber}`}
                    name="userPhoneNumber"
                    className="rounded-pill align-self-center w-100 px-3 p-3 bg-white border-0 phone-number-input"
                    onChange={(e) => setUserPhoneNumber(`${e}`)}
                  />
                  {/* <input type="text" id="userPhoneNumber" name="userPhoneNumber" value={userPhoneNumber} onChange={(e) => setUserPhoneNumber(e.target.value)} placeholder="eg. https://www.instagram.com/yourusername" className='rounded-pill align-self-center w-100 px-4 p-3 bg-white border-0' /> */}
                </div>
              </div>
              {/* Basic End */}

              {/* Address */}
              <div className='row mt-5'>
                <div className='col-sm mb-md-0 mb-3'>
                  <label htmlFor="address1">Address Line 1</label>
                  <input type="text" id="address1" name="address1" value={address1} onChange={(e) => setAddressLine1(e.target.value)} placeholder="Address Line 1" className='rounded-pill align-self-center w-100 px-4 p-3 bg-white border-0' />
                </div>
                <div className='col-sm'>
                  <label htmlFor="addressLine2">Town / City </label>
                  <input type="text" id="addressLine2" name="addressLine2" value={addressLine2} onChange={(e) => setAddressLine2(e.target.value)} placeholder="Town / City" className='rounded-pill align-self-center w-100 px-4 p-3 bg-white border-0' />
                </div>
              </div>
              <div className='row mt-5'>
                <div className='col-sm mb-md-0 mb-3'>
                  <label htmlFor="Country">Country</label>
                  {/* <input type="text"  onChange={(e) => setCountry(e.target.value)} placeholder="Country" className='rounded-pill align-self-center w-100 px-4 p-3 bg-white border-0' /> */}

                  <select
                    onChange={(e) => setCountry(e.target.value)}
                    value={country}
                    className='rounded-pill align-self-center w-100 px-4 p-3 bg-white border-0'
                  >
                    {localesData.map(locale => (
                      <option key={locale.id} value={locale.id}>
                        {locale.localeName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='col-sm  mb-md-0 mb-3'>
                  <label htmlFor="postcode">Postcode</label>
                  <input type="text" id="postcode" name="postcode" placeholder="Postcode" value={postcode} onChange={(e) => SetPostcode(e.target.value)} className='rounded-pill align-self-center w-100 px-4 p-3 bg-white border-0' />
                </div>
              </div>
              {/* Address End */}


              {/* Social */}
              <div className='row mt-5'>
                <div className='col-sm mb-md-0 mb-3'>
                  <label htmlFor="facebook">Facebook</label>
                  <input type="text" id="facebook" name="facebook" value={facebook} onChange={(e) => SetFacebook(e.target.value)} placeholder="eg. https://www.facebook.com/yourpage" className='rounded-pill align-self-center w-100 px-4 p-3 bg-white border-0' />
                </div>
                <div className='col-sm'>
                  <label htmlFor="twitter">Twitter</label>
                  <input type="text" id="twitter" name="twitter" value={twitter} onChange={(e) => SetTwitter(e.target.value)} placeholder="eg. https://twitter.com/yourusername" className='rounded-pill align-self-center w-100 px-4 p-3 bg-white border-0' />
                </div>
              </div>
              <div className='row mt-5'>
                <div className='col-sm'>
                  <label htmlFor="instagram">Instagram</label>
                  <input type="text" id="instagram" name="instagram" value={instagram} onChange={(e) => SetInstagram(e.target.value)} placeholder="eg. https://www.instagram.com/yourusername" className='rounded-pill align-self-center w-100 px-4 p-3 bg-white border-0' />
                </div>
                <div className='col-sm mb-md-0 mb-3'>
                  <label htmlFor="website">Website</label>
                  <input type="text" id="website" name="website" value={website} onChange={(e) => SetWebsite(e.target.value)} placeholder="Website" className='rounded-pill align-self-center w-100 px-4 p-3 bg-white border-0' />
                </div>
              </div>
              {/* Social End */}

              <div className="row mt-5">
                <label htmlFor="accountNo" className='AccDetails'>Account Details</label>
                <div className="col-sm mb-md-0 mb-3">
                  <label htmlFor="accountNo">Account Number</label>
                  <input type='text' id="accountNo" name="accountNo" placeholder="Account Number" value={accountNo} onChange={(e) => setAccountNo(e.target.value)} className='rounded-pill align-self-center w-100 px-4 p-3 bg-white border-0' />
                </div>
                <div className="col-sm">
                  <label htmlFor="sortCode">Sort Code</label>
                  <input type='text' id="sortCode" name="sortCode" placeholder="Sort Code" value={sortCode} onChange={(e) => setSortCode(e.target.value)} className='rounded-pill align-self-center w-100 px-4 p-3 bg-white border-0' />
                </div>
              </div>
              <div className='row mt-5'>


                <div className="col-sm">
                  <label htmlFor="paypalemail">Paypal Email</label>
                  <input type="email" id="paypalemail" name="paypalemail" placeholder='Paypal Email' value={payPalEmail} onChange={(e) => setPayPalEmail(e.target.value)} className='rounded-pill align-self-center w-100 px-4 p-3 bg-white border-0' />
                </div>
                <hr className='px-5 mt-5 color-grey' style={{ opacity: '0.5' }} />
              </div>

              <div className='pt-5'>
                <input disabled={!isUpdated} className={`btn ${isUpdated ? '' : 'disabled'} pfw-button-green rounded-pill align-self-center px-5 py-2 fs-6 fw-bold`} onClick={() => SaveHandler()} type="submit" name="submit" value="Update and Save" style={{ lineHeight: 2 }} />
              </div>
            </div>
          </div>
        </div>
      </section>


      <SiteFooter />
    </>
  )
}

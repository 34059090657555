import React, { Component } from "react";
import Slider from "react-slick";
// import ImageLoader from "./imageLoader";


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="custom-arrow next-arrow"
      style={{ ...style, display: "block"}}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="custom-arrow prev-arrow"
      style={{ ...style, display: "block"}}
      onClick={onClick}
    />
  );
}

export default function PrizeSliderTemp3(props) {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        slidesToShow: 1,
        autoplaySpeed: 2500,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
            }
          },
        ]
      };
      const parser = new DOMParser();
      console.log("padding",props.isPadding);
  return (
    <div className="prize-block-inner px-0 py-5 pt-0">
     <Slider {...settings}>
          {props.prizeData.map(item => {
console.log("itemPrizeData",item);

return (

    

      <div className={`prize-block ${item.content}`}  key={item.id + item.image}>
            <div>
            { (item.image != 'image') ?

              <div className="prize-photo bg-white col-sm-12 col-md-12 col-lg-12 text-center p-5 position-relative" style={{height: 230, 
                backgroundImage: `${item.image ? item.image.includes("http")
                ? `url(${item.image})`
                : `url(${require('../assets/images/draws/' + item.image)})`
                : `url(${require('../assets/images/prize.png')})`
                }`, backgroundSize: "cover"   }}>
                
                  {/* <span className="display-1 text-white"></span> */}
              </div>
               : ""
            }
              <div className="prize-block-testimonial bg-white text-center py-4 col-sm-12 px-4 col-md-12 col-lg-12" style={{borderBottomLeftRadius : 8 ,borderBottomRightRadius : 8 }}>
              {/* { item.content === "2x VIP Hospitality Tickets for Peter Kay at Leeds Arena" ? */}

                {/* <h4 className="fw-normal fs-5 branding1 mt-0 mb-0 px-2">
                  <strong>Play</strong> our draws, <strong>Fund</strong> good causes, <strong>Win</strong> great prizes
                </h4>
              : */}
              {/* {item.content} */}
             
                <h4 className="fw-normal fs-5 branding1 mt-0 mb-0 px-2"  dangerouslySetInnerHTML={{ __html: `${item.content}` }}></h4>

              {/* } */}
              </div>
           </div> 
          </div>
          
)
          })}

        </Slider>
    </div>
  );
}

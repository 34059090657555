import React, { useState } from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Fulham from '../assets/images/fulhamsupport.png';
import WAFC from '../assets/images/WAFC.png';
import Titan from '../assets/images/titan.png';
import Marketing1 from '../assets/images/marketing-1.png';
import Parkrun from '../assets/images/parkrunsupport.png';
import Marketing2 from '../assets/images/marketing-2.png';
import England from '../assets/images/england.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "Block",color:"#fff" }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "Block",color:"#fff" }}
        onClick={onClick}
      />
    );
  }
  


export default function MarketingSupport() {

  const [oneShow, setOneShow] = useState(false);
  const [twoShow, setTwoShow] = useState(false);
  const [threeShow, setThreeShow] = useState(false);
  const [fourShow, setFourShow] = useState(false);
  const [fiveShow, setFiveShow] = useState(false);
  const [sixShow, setSixShow] = useState(false);


        var  settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            }
          },
          {
              breakpoint: 320,
              settings: {
                slidesToShow: 1,
              }
            }
          
        ]
        };  
      return (
        <div>
           <div className='row'>
                    <div className="container p-0 w-75 host-slider position-relative font-face-gb">
          <Slider {...settings}>
          

            <div>
              <div className="slider-sec text-start shadow-md rounded-4 mx-3 mb-5">
<LazyLoadImage
  className='logoban w-100'
  src={Fulham}
  alt='Fulham Support'
/>
                  {/* <img className="logoban w-100 " src={require('../assets/images/fulhamsupport.png')} ></img> */}
                  
              </div>
            </div>

            

            <div>
              <div className="slider-sec text-start shadow-md rounded-4 mx-3 mb-5">
<LazyLoadImage
  className='logoban w-100'
  src={WAFC}
  alt='WAFC'
/>
                  {/* <img className="logoban w-100 " src={require('../assets/images/WAFC.png')} ></img> */}
                  
              </div>
            </div>

            <div>
              <div className="slider-sec text-start shadow-md rounded-4 mx-3 mb-5">
<LazyLoadImage
  className='logoban w-100'
  src={Titan}
  alt='Titan'
/>
                  {/* <img className="logoban w-100 " src={require('../assets/images/titan.png')} ></img> */}
                  
              </div>
            </div>

            <div>
              <div className="slider-sec text-start shadow-md rounded-4 mx-3 mb-5">
<LazyLoadImage
  className='logoban w-100'
  src={Marketing1}
  alt='Marketing 1'
/>

                  {/* <img className="logoban w-100 " src={require('../assets/images/marketing-1.png')} ></img> */}
                  
              </div>
            </div>

            <div>
              <div className="slider-sec text-startshadow-md rounded-4 mx-3 mb-5">
                  <LazyLoadImage
  className='logoban w-100'
  src={Parkrun}
  alt='Parkrun'
/>
                  {/* <img className="logoban w-100 " src={require('../assets/images/parkrunsupport.png')} ></img> */}
                  
              </div>
            </div>

            <div>
              <div className="slider-sec text-start shadow-md rounded-4 mx-3 mb-5">
<LazyLoadImage
  className='logoban w-100'
  src={Marketing2}
  alt='Marketing 2'
/>

                  {/* <img className="logoban w-100 " src={require('../assets/images/marketing-2.png')} ></img> */}
                  
              </div>
            </div>

            <div>
              <div className="slider-sec text-start shadow-md rounded-4 mx-3 mb-5">
<LazyLoadImage
  className='logoban w-100'
  src={England}
  alt='England'
/>
                  {/* <img className="logoban w-100 " src={require('../assets/images/england.png')} ></img> */}
                  
              </div>
            </div>

            

            
          </Slider>
        </div>
                    </div>
        </div>
      );
    }
  
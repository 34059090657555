import React, { useEffect, useState, Component } from 'react';
import Slider from 'react-slick';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { MyCart, Cart, HomeDrawDataSession, DrawDataSession } from '../components/Cart';
import { CartProvider, useCart } from 'react-use-cart';
import { GetDrawListLimit, GetDrawListHeroSlider } from '../Data/actionMethods';
import Moment from 'moment';
import CountdownTimer from './CountdownTimer';
import { LogoLoader } from './imageLoader';
import SkeletonLoader from './SkeletonLoader';
import { toast, ToastContainer } from 'react-toastify';
import HomeDrawSliderTrending from './HomeDrawSliderTrending';
import HomeDrawSlider from './HomeDrawSlider';
import HeroSliderHome from './heroSliderHome';
import AllDrawsSlider from './alldrawsSlider';

function GetDrawListSlider() {
  const [DrawData, SetDrawData] = useState([]);
  const [DrawDataHero, SetDrawDataHero] = useState([]);
  const [drawisActive, setDrawisActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [Fetched, setFetched] = useState(false);
  const params = useParams();
  const [ItemCount, setItemCount] = useState();
  const [AmountText, setAmountText] = useState();
  const [isSubscription, setSubscription] = useState(true);
  const { AddInCart, addItem } = useCart();
  const Navigate = useNavigate();
  const { isEmpty, items } = useCart();

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return <div className="custom-arrow next-arrow" style={{ ...style, display: 'block' }} onClick={onClick} />;
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return <div className="custom-arrow prev-arrow" style={{ ...style, display: 'block' }} onClick={onClick} />;
  }
  useEffect(() => {
    const DrawName = params.name;

    if (DrawName === 'petrus-subscription-draw') {
      window.location.replace('https://playfundwin.com/draw/petrus-community-draw');
    }

    GetDrawListLimit().then((res) => {
      let dt = [];

      if (res.status && res.data && res.data.content) {
        let data = res.data.content;

        for (let i = 0; i < data.length; i++) {
          data[i].drawContent = data[i].drawContent.length == 0 ? [] : JSON.parse(data[i].drawContent);
          if (data[i].drawContent.length == 0) {
            let elm = {};
            elm.id = i;
            elm.drawID = data[i].drawID;
            elm.name = data[i].drawName;
            elm.price = 5;
            elm.slug = data[i].slug;
            elm.logoUrl = data[i].logoUrl ? data[i].logoUrl : 'admin.png';
            elm.HeaderImage = data[i].HeaderImage;
            elm.FeaturedUrl = data[i].FeaturedUrl;
            elm.HeaderLeftImage = '';
            elm.BrandingColor1 = data[i].drawBrandPrimary;
            elm.BrandingColor2 = data[i].drawBrandSecondary;
            elm.content = '';
            elm.PlayContent = '-';
            elm.drawEndDate = data[i].drawEndDate;
            elm.drawStartDate = data[i].drawStartDate;
            elm.FundContent = data[i].FundContent;
            elm.WinContent = data[i].WinContent;
            elm.ticketCountLabel = data[i].ticketCountLabel;
            elm.subscription = data[i].subscription;
            elm.Redirection = data[i].Redirection;
            elm.RedirectionUrl = data[i].RedirectionUrl;
            elm.template = data[i].template;
            elm.prizeSlider = data[i].prizeSlider;

            dt.push(elm);
          } else {
            data[i].drawContent.drawHostID = data[i].drawHostID;
            data[i].drawContent.drawHostCauseID = data[i].drawHostCauseID;
            data[i].drawContent.drawStartDate = data[i].drawStartDate;
            data[i].drawContent.drawEndDate = data[i].drawEndDate;
            data[i].drawContent.drawIsActive = data[i].drawIsActive;
            data[i].drawContent.drawIsActive = data[i].drawIsActive;
            data[i].drawContent.winnerData = data[i].drawWinners ? data[i].drawWinners : [];
            dt.push(data[i].drawContent);
          }
        }
      }

      const activeDraw = dt.filter((item) => {
        return Moment() < Moment(item.drawEndDate) && Moment() > Moment(item.drawStartDate);
        //}
      });

      console.log('activeDraw', activeDraw);
      SetDrawData(activeDraw);
      setIsLoading(true);
      setFetched(true);
    });

    GetDrawListHeroSlider().then((res) => {
      let dt = [];

      if (res.status && res.data && res.data.content) {
        let data = res.data.content;

        for (let i = 0; i < data.length; i++) {
          data[i].drawContent = data[i].drawContent.length == 0 ? [] : JSON.parse(data[i].drawContent);
          if (data[i].drawContent.length == 0) {
            let elm = {};
            elm.id = i;
            elm.drawID = data[i].drawID;
            elm.name = data[i].drawName;
            elm.price = 5;
            elm.slug = data[i].slug;
            elm.logoUrl = data[i].logoUrl ? data[i].logoUrl : 'admin.png';
            elm.HeaderImage = data[i].HeaderImage;
            elm.FeaturedUrl = data[i].FeaturedUrl;
            elm.HeaderLeftImage = '';
            elm.BrandingColor1 = data[i].drawBrandPrimary;
            elm.BrandingColor2 = data[i].drawBrandSecondary;
            elm.content = '';
            elm.PlayContent = '-';
            elm.drawEndDate = data[i].drawEndDate;
            elm.drawStartDate = data[i].drawStartDate;
            elm.FundContent = data[i].FundContent;
            elm.WinContent = data[i].WinContent;
            elm.ticketCountLabel = data[i].ticketCountLabel;
            elm.subscription = data[i].subscription;
            elm.Redirection = data[i].Redirection;
            elm.RedirectionUrl = data[i].RedirectionUrl;
            elm.template = data[i].template;
            elm.prizeSlider = data[i].prizeSlider;
            //Hero Slider Data
            elm.heroSliderTitle = data[i].heroSliderTitle;
            elm.heroSliderDescription = data[i].heroSliderDescription;
            elm.heroSlider = data[i].heroSlider;
            elm.SliderCharityName = data[i].SliderCharityName;

            dt.push(elm);
          } else {
            data[i].drawContent.drawHostID = data[i].drawHostID;
            data[i].drawContent.drawHostCauseID = data[i].drawHostCauseID;
            data[i].drawContent.drawStartDate = data[i].drawStartDate;
            data[i].drawContent.drawEndDate = data[i].drawEndDate;
            data[i].drawContent.drawIsActive = data[i].drawIsActive;
            data[i].drawContent.drawIsActive = data[i].drawIsActive;
            data[i].drawContent.winnerData = data[i].drawWinners ? data[i].drawWinners : [];
            dt.push(data[i].drawContent);
          }
        }
      }

      const activeDrawHero = dt.filter((item) => {
        return Moment() < Moment(item.drawEndDate) && Moment() > Moment(item.drawStartDate);
        //}
      });

      console.log('activeDrawHero', activeDrawHero);
      SetDrawDataHero(activeDrawHero);
      setIsLoading(true);

      setFetched(true);
    });

  }, []);

  const settingsHero = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false
        }
      }
    ]
  };

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 500,
    centerMode: true,
    centerPadding: '120px',
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false
        }
      }
    ]
  };

  const WaitForProcess = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  function updateSubscription() {
    setSubscription(!isSubscription);
  }
  const parser = new DOMParser();

  const slicedData = DrawData;


  const slicedDataHero = DrawDataHero;

  if (isLoading === false || DrawData.length === 0 || DrawDataHero.length === 0) {
    return (
      <div>
       

        

      </div>
    );
  } else {
    return (
      <>
       
        <AllDrawsSlider slicedData={slicedData} fetch={Fetched} orderBy="drawEndDate" />
      </>
    );
  }

}

export default function () {
  return (
    <>
      <CartProvider>
        <GetDrawListSlider />
      </CartProvider>
    </>
  );
}

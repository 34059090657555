import React from 'react'
import { Link } from 'react-router-dom'

export default function FooterCta() {
  return (
    
        <div className="container py-lg-4 text-center secbutton footer-cta cta-section font-face-gb position-relative" style={{borderRadius: 10, marginBottom: 90}}>
            <div className="cta-sec-inner py-5">
                <h1 className="text-white display-4 font-face-gb-semibold pt-2">Thinking of starting a draw?</h1>
                <p className="text-white my-4">The revolutionary digital raffle platform that makes fundraising simple for<br/>
                sports clubs, events and charities</p>
                <Link to="/host" className="border-0 rounded-pill btn pfw-button-white py-2 px-5 my-2 mx-2 font-face-gb-semibold" style={{lineHeight: 2}}>Start a draw</Link>
            </div>
        </div>

  )
}

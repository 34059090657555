import React from 'react'
import { Link } from "react-router-dom";
import Siteheader from '../components/header';
import SiteFooter from '../components/footer';
import { UseEffect } from 'react';
import FooterCta from '../components/footer-cta';
import Innerheader from '../components/inner-header';
import { Helmet } from 'react-helmet';

export default function ComplaintProcedure(props) {
 
  return (
    <>
    <Helmet>‍
      <title>Complaints Procedure | Play Fund Win</title>‍
      <meta name="description" content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities." />
    </Helmet>
    <Siteheader/>
    <Innerheader title="Complaints Procedure"/>

  <div className='container pt-5 pb-5 my-3 px-lg-5 rounded-4 w-92' style={{backgroundColor: '#f2f2f2'}}>
    <div className="row px-lg-5">
       <div className="col-md-12 align-middle text-left px-lg-5 mt-lg-0 mt-3">
			<p>At Play Fund Win, we always aim to provide the highest possible standards of service. If you feel at any time that the service you have received is below the expected level, please reach out to us using the details below. We continuously strive to meet and exceed the expectations of our players and hosts, and we welcome feedback on how our services can be improved.</p>
            <p>You can contact Play Fund Win directly via the following methods:</p>
            <p>Via email</p>
            <p><a className='branding3 fw-bold' href="mailto:hello@playfundwin.com">hello@playfundwin.com</a></p>
            <p>Via post</p>
            <p>Play Fund Win</p>
            <p>PFW Holdings Ltd Suite#300,</p>
            <p>4 Blenheim Court,</p>
            <p>Peppercorn Close,</p>
            <p>Peterborough, PE1 2DU</p>
            <p>We aim to respond to all written complaints within five working days of receipt.</p>
			<p>Via&nbsp;WebChat</p>
            <p>Please visit our website and use the chat function to speak to a member of our team.</p>
            <p>In the unlikely event that you feel your complaint has not been resolved to your satisfaction; you can request that it be escalated to a Senior Manager for review. We aim to investigate and respond to escalated complaints within 10 working days.</p>
            <p>As a final stage, if you feel we have not dealt with the complaint in a satisfactory manor you can direct your complaint to one of the below.</p>
            <p>Complaints that relate to the promotion of the draw should be directed to the Advertising Standards Authority (ASA). You can find information related to the ASA’s complaint process here: <a className='branding3 fw-bold' href="https://www.asa.org.uk/make-a-complaint.html">make-a-complaint</a></p>
            <p>Complaints that relate to fundraising practices should be directed to the Fundraising Regulator. You can find information related to the Fundraising Regulator’s complaint process here:<a className='branding3 fw-bold' href="https://www.fundraisingregulator.org.uk/more-from-us/resources/complaints-process">complaints-process</a></p>
            <p>Our nominated independent ADR is:</p>
            <p>Independent Betting Adjudication Service</p>
            <p>PO Box 62639</p>
            <p>London</p>
            <p>EC3P 3AS</p>
            <p>Telephone: 020 7347 5883</p>
            <p>Email:&nbsp;<a className='branding3 fw-bold' href="mailto:adjudication@ibas-uk.co.uk">adjudication@ibas-uk.co.uk</a></p>
			<p>PFW Holdings Ltd (trading as Play Fund Win) is regulated as an External Lottery Manager by the Gambling Commission. Our account number is 057889.</p>
            <p>If you feel that we have not dealt with your complaint properly or that we have not followed our own published complaints procedure, you can complain to the Gambling Commission about our failure to operate a proper complaints process.</p>
            <p>The Gambling Commission is a regulator and not a complaint handling body and they will not investigate the facts of your complaint, nor will they alter the decision that we have made in our internal complaints process. The Commission reviews whether we have adhered to the terms of our operating licence. It does not investigate consumer complaints, rule on prize disputes, pay compensation or provide legal advice.</p>
            <p>The contact details of the Commission are as follows:</p>
            <p>Consumer Protection</p>
            <p>Gambling Commission</p>
            <p>4th Floor</p>
            <p>Victoria Square House</p>
            <p>Victoria Square</p>
            <p>Birmingham</p>
            <p>B2 4BP</p>
            <p>Telephone: 0121 230 6666</p>
            <p>Email:&nbsp;<a className='branding3 fw-bold' href="mailto:info@gamblingcommission.gov.uk">info@gamblingcommission.gov.uk</a></p>
       </div>
    </div>
  </div>

  <FooterCta/>
  <SiteFooter/>
    </>
  )
}

import React from 'react';
import { Link } from "react-router-dom";
import Siteheader from '../components/header';
import SiteFooter from '../components/footer';
import { Inner_Header_Images } from '../components/images';
import FooterCta from '../components/footer-cta';
import Accordion from 'react-bootstrap/Accordion';
import MonthlySlider from "../components/monthlySlider";
import { Helmet } from 'react-helmet';
import HostSliderSection from "../components/hostSlider-section";
import MarketingSupport from '../components/marketingSupport';
import ActiveSlider from '../components/activeSlider';
import pricingTicket from '../assets/images/pricing-ticket.png';
import partners from '../assets/images/partner.png';
import basedDraws from  '../assets/images/based-draws.png';
import challenge from '../assets/images/challenge.png';
import charityBanner from '../assets/images/charity-banner.png';
import grassrootsSportsClubprize from  '../assets/images/grassroots-sports-clubs-prize .png';
import grassrootsSportsClubsFundraising from '../assets/images/grassroots-sports-clubs-fundraising.png';
import hands from '../assets/images/hands.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';


export default function Charities() {

   

    return (
        <>
            <Helmet>‍
                <title>Charities | Play Fund Win</title>‍
                <meta name="description" content="Play Fund Win’s digital fundraising platform is a great way for sports clubs to raise money, through prize draws or offering cash prizes to supporters" />
            </Helmet>
            <Siteheader />
<div className='grassroots'>
            <div className="blog-header-section container font-face-gb px-5 py-1 bg-branding1" style={{ marginBottom: '120px' }}>
                <div className="row align-items-center" style={{marginBottom: '-50px' }}>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12 align-middle header-left-ctn" style={{marginTop: '-50px' }}>
                        {/* <div className='publishdate color-yellow text-uppercase fw-bolder fs-5'>PlayFundWin</div> */}
                        <h1 className="post-title text-white fs-1 fw-normal mt-2 mb-4">Charities</h1>
                        <div className='short-desc text-light fs-5'>Charities can benefit from hosting regular or one-off fundraising draws through our digital fundraising platform, as the named beneficiary of other draws.</div>
                        <a className="border-0 rounded-pill bg-branding3 text-white btn pfw-button-white py-2 px-5 font-face-gb-semibold mt-4" style={{lineHeight : 2}} href="/host">Host a Draw</a>
                    </div>
                    <div className="col-md-6 align-middle p-md-5 p-2 text-center about-left-charities position-relative">
                        <div className='post-image star-before background-image w-100 position-relative' style={{backgroundImage: `url(${hands})`, borderRadius: 10, height: 360, top: 60, marginTop: '-40px', zIndex : 9}}>     
                        </div>
         

                       
                        {/* <LazyLoadImage
  src={require(`../assets/images/hands.png`)}
  alt="Hands"
  effect="blur" // Optional: Add a loading effect if desired
  className="post-image star-before background-image w-100 position-relative"
  style={{
    borderRadius: 10,
    height: 360,
    top: 60,
    marginTop: '-40px',
    zIndex: 9,
  }}
/> */}

                    </div>
                </div>
            </div>

            {/* <div className="container pt-5 pb-5 rounded-4 bg-image-np about-header position-relative" style={{ backgroundImage: `url(${Inner_Header_Images.InnerHeaderBg})` }}>
                <div className='container pt-5 pb-5'>
                    <div className="row align-items-center mb-5" >
                        <div className="col-xl-8 col-lg-8 col-md-8 col-12 align-middle text-center pt-lg-5 px-lg-5 m-auto">
                            <h1 className="text-white font-face-gb-semibold fw-normal display-3 mb-3">Grassroots Sports Clubs</h1>
                            <p className='text-white mb-5 px-lg-5 fs-5'>Grassroots sports clubs can benefit from hosting regular fundraising draws through our digital fundraising platform.</p>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className='about-content-area container px-5 py-5 pb-5 px-0 position-relative' style={{ backgroundColor: '#f2f2f2', marginTop: '-120px' }}>
                <div className="row mx-auto py-3 align-items-center" style={{ width: '100%' }}>

                    <div className="col-md-7 align-middle text-left mt-lg-0 mt-3 about-right-ctn position-relative">
                        <h2 className="display-4 branding1 mb-3">Your <strong className='font-face-gb-semibold'>draw</strong></h2>
                        <p>Your draw is exactly that. You are free to customise your draw page with your club colours, logo, images and information about the draw.</p>
                        <p>You can also determine the price and pre-set ticket quantities visible on the page. Most importantly, you can decide the time and date when you want your draw to close! We will let entrants know if they have won.</p>
                        <p>Our Business Development Managers will be there to support you with every step, to set you up for success in raising funds for your grassroots sport club.</p>
                        <p>We look after all of the draw administration, alleviating volunteers or club staff of time-consuming or laborious work and allowing them to focus on assisting you in other ways.</p>
                        <a className="border-0 rounded-pill bg-branding2 text-white btn pfw-button-white py-2 px-5 font-face-gb-semibold" style={{lineHeight : 2}} href="/host">Host a Draw</a>
                    </div>

                    <div className="col-md-5 align-middle text-center pt-5 position-relative">
                      <LazyLoadImage
                      alt='pricing-ticket'
                      src={pricingTicket}
                        width="100%"
                        style={{ borderRadius: '1rem', marginTop: '0px' }}
                    
                        />
                        {/* <img width="100%" style={{ borderRadius: '1rem', marginTop: '0px' }} src={require('../assets/images/pricing-ticket.png')} /> */}
                    </div>

                   
                </div>
            </div>

            <div className='left-right-ctn-sec container px-0 position-relative' style={{ backgroundColor: '#E64662' }}>
                <div className="row mx-auto">
                    <div className="left-ctn months col-md-12 align-middle position-relative text-center px-5 py-5 mt-4 mb-5" style={{ paddingLeft: 0 }}>
                        <h2 className="display-4 text-light mb-3">Monthly <strong className='font-face-gb-semibold'>draws</strong></h2>
                        <p className='text-light'>Your draw is exactly that. You are free to customise your draw page with your club colours, logo, images and information about the draw.</p>
                        <p className='text-light'>You can also determine the price and pre-set ticket quantities visible on the page. Most importantly, you can decide the time and date when you want your draw to close! We will let entrants know if they have won.</p>
                        <p className='text-light'>Our Business Development Managers will be there to support you with every step, to set you up for success in raising funds for your grassroots sport club.</p>
                        <p className='text-light'>We look after all of the draw administration, alleviating volunteers or club staff of time-consuming or laborious work and allowing them to focus on assisting you in other ways.</p>
                    </div>
                    <div className='charity-data' style={{ zIndex: 9 }}>
                        <MonthlySlider/>
                    </div>
                    <div className="col-md-12 align-middle text-left ps-lg-5 pt-5 pe-0 mt-lg-0 mt-3 position-relative">
                        {/* <img className='position-absolute end-0' src={require('../assets/images/draws/north-walkden-fc-monthly-draw-Funding-Image.jpg')} width="25%" style={{ borderTopLeftRadius: '1rem', borderBottomLeftRadius: '1rem', }} /> */}
                    </div>
                </div>
            </div>

            <div className='left-right-ctn-sec reverse-sec container px-5 py-2 position-relative'>
                <div className="row mx-auto align-items-center">
                    <div className="right-ctn col-md-7 align-middle position-relative py-5 mt-4 ps-4" style={{  }}>
                        <h2 className="display-4 mb-3 branding1">Partner <strong className='font-face-gb-semibold'>support</strong></h2>
                        <p className='text-dark'>Draws in aid of your cause or charity are not exclusive to hosting by yourself. Our partner programme has been used by charities to engage with brand and corporate supporters to fundraise in support of their cause. Any number of draws can be created with your supporters to raise funds, and this provides an amazing opportunity to engage the partners’ own network or audience to aid your work.</p>
                        
                    </div>
                    <div className="col-md-5 align-middle event-based text-left pe-0 mt-lg-0 mt-3 position-relative">
                        <LazyLoadImage
                        alt='partners'
                        src={partners}
                        width="85%"
                        style={{ borderTopRightRadius: '1rem', borderBottomRightRadius: '1rem' }}
                        className='start-0'
                        />
                        {/* <img className='start-0' src={require('../assets/images/partner.png')} width="85%" style={{ borderTopRightRadius: '1rem', borderBottomRightRadius: '1rem' }} /> */}
                    </div>
                </div>
            </div>

            <div className='left-right-ctn-sec container px-5 py-2 position-relative'>
                <div className="row mx-auto align-items-center">
                    <div className="col-md-5 align-middle event-based text-left pe-0 mt-lg-0 mt-3 position-relative">
                        <LazyLoadImage
                        alt='basedDraws'
                        src={basedDraws}
                        width="85%"
                        style={{ borderTopRightRadius: '1rem', borderBottomRightRadius: '1rem' }}
                        className='start-0'
                        />
                        {/* <img className='start-0' src={require('../assets/images/based-draws.png')} width="85%" style={{ borderTopRightRadius: '1rem', borderBottomRightRadius: '1rem' }} /> */}
                    </div>
                    <div className="right-ctn col-md-7 align-middle position-relative py-5 mt-4 ps-4" style={{  }}>
                        <h2 className="display-4 mb-3 branding1">Event-based <strong className='font-face-gb-semibold'>draws</strong></h2>
                        <p className='text-dark'>Do you have any key events taking place such as gala dinners, coffee mornings or bake sales? Why not try replacing your paper-based raffle with an online prize draw. Businesses looking to support a charity could host a draw during conferences, awards nights or other events which take place within the year.  </p>
                        <p className='text-dark'>The beauty of an online draw is that you can reach a wider audience, and promote it prior to, and following, the event.</p>
                        <p className='text-dark'>To set up a prize-based draw, we would require a list of the prizes from you in advance of the draw going live. </p>
                        <p className='text-dark'>Once the draw is complete, we will pass on details of the winners so you may contact them to distribute their prizes. Please be aware, we are unable to organise delivery of your winners’ prizes. We recommend that you ask them to collect them from your offices, where possible. </p>
                    </div>
                </div>
            </div>

            <div className='left-right-ctn-sec reverse-sec container px-5 py-2 position-relative'>
                <div className="row mx-auto align-items-center">
                <div className="right-ctn col-md-7 align-middle position-relative py-5 mt-4 ps-4" style={{  }}>
                        <h2 className="display-4 mb-3 branding1">Fundraising <strong className='font-face-gb-semibold'>challenges</strong></h2>
                        <p className='text-dark'>Another option for charities could be to present Play Fund Win as a resource to prospective fundraisers. The beauty of offering cash or physical prizes to entrants is the increased appear this provides for those considering entry to the draw. Rather than another donation ask, those willing to part with their cash have the opportunity to win something back for themselves. It’s human nature to want to feel rewarded, though we do find that many of our cash jackpot winners donate their prize to the charity (though there’s no pressure on entrants to do so!)</p>
                        <p className='text-dark'>We recommend suggesting Play Fund Win as an alternative to those who may be planning to raise funds through activities such as sponsored walks, marathons, gaming challenges, fun runs or other challenges.</p>
                    </div>
                    <div className="col-md-5 align-middle event-based text-left pe-0 mt-lg-0 mt-3 position-relative">
                        <LazyLoadImage
                        alt='challenge'
                        src={challenge}
                        width="85%"
                        style={{ borderTopRightRadius: '1rem', borderBottomRightRadius: '1rem' }}
                        className='start-0'
                        />
                        
                        {/* <img className='start-0' src={require('../assets/images/challenge.png')} width="85%" style={{ borderTopRightRadius: '1rem', borderBottomRightRadius: '1rem' }} /> */}
                    </div>
                    
                </div>
            </div>

            <div className='left-right-ctn-sec full-banner container text-center px-0  rounded-4 position-relative'>
                <div className="row mx-auto">
                    {/* <div className="left-ctn col-md-12 px-5 align-middle position-relative">
                        <h2 className="display-4 text-dark mb-3">Membership <strong className='font-face-gb-semibold'>draws</strong></h2>
                        <p className='text-dark'>Another option you could explore for your draw is to tie entry into your club membership fees. This may allow you to slightly increase fees, and associated revenue into the club, whilst offering cash or other prizes to reward your supporters.</p>
                    </div> */}

                     <div className="col-md-12 align-middle position-relative">
                        <LazyLoadImage
                        alt='charity-banner'
                        src={charityBanner}
                        width="100%"
                        className='end-0'
                        />
                        {/* <img className=' end-0' src={require('../assets/images/charity-banner.png')} width="100%"  /> */}
                    </div> 
                </div>
            </div>

            {/* <div className='left-right-ctn-sec reverse-sec container p-5 rounded-4 position-relative'>
                <div className="row mx-auto align-items-center">
                <div className="right-ctn col-md-7 align-middle p-0 position-relative">
                        <h2 className="display-4 text-dark mb-3">Matchday <strong className='font-face-gb-semibold'>draws</strong></h2>
                        <p className='text-dark'>Grassroots clubs with large a large supporter-base could harness their loyalty by hosting matchday draws at fixtures. A digital prize draw widens the audience beyond attendees, with potential to promote via emails or social media to those who cannot be at the fixture.</p>
                        <p className='text-dark'>Draws can be scheduled to close on the day of a match or fixture, on the day following – or whenever you like! The end time and date on your draw is completely up to you to decide.</p>
                    </div>
                    <div className="col-md-5 align-middle matchday text-center pe-0 mt-lg-0 mt-3 position-relative">
                        <img className=' start-0' src={require('../assets/images/matchday.png')} width="80%" style={{ borderTopRightRadius: '1rem', borderBottomRightRadius: '1rem' }} />
                    </div>
                    
                </div>
            </div> */}

                 <div className='prizes-fundraising-sec container px-5 py-5 pb-5 position-relative'>
                    <div className="row mx-auto">
                       <div className="col-md-6 align-middle text-left pe-4 p-0 position-relative">
                        <div className="prizes row mx-auto align-items-center">
                            <div className="prize-image prize-image-grassroots p-0 m-0">
                            <LazyLoadImage
                            alt='grassroots Sports Club prize'
                            src={grassrootsSportsClubprize}
                            width="100%"
                            className='start-0'
                            />
                                {/* <img width="100%" className='start-0' src={require('../assets/images/grassroots-sports-clubs-prize .png')} /> */}
                                </div>

                        </div>
                        
                        <p>You may wish to keep things simple with a cash-based draw. Here, you are free to determine the split between funds raised and jackpot offered. We usually recommend a 50:50 draw split as most people are familiar with this model, and it provides a good balance of funds raised and an attractive cash prize offered.</p>
                        <p>Alternatively, you could opt for a prize-draw. To do so, we would just need to know the prizes you plan to offer prior to the start of the draw period – then we can get these set up on your page. Your club could award each prize to a different winner, or a prize package to one very lucky victor!</p>
                        <p>Winners are drawn via our random number generator, and we look after all the administration of contacting winners and providing any jackpot funds to them.</p>
                    </div>
                    <div className="col-md-6 align-middle position-relative pe-5 prize-top-main">
                    <div className="prizes row mx-auto align-items-center">
                            <div className="prize-image prize-image-grassroots p-0 m-0">
                            <LazyLoadImage
                            alt='grassroots Sports Clubs Fundraising'
                            src={grassrootsSportsClubsFundraising}
                            width="100%"
                            className='start-0'

                            />
                                {/* <img width="100%" className='start-0' src={require('../assets/images/grassroots-sports-clubs-fundraising.png')} /> */}
                                </div>
                            
                        </div>
                        
                        <p>Funds raised will be sent to the club following the conclusion of your draw. In order to transfer the money, we will require the club’s bank account details. You will most likely be asked to provide these to us prior to the draw commencing, or during the draw period. This is to avoid delay in allocating your funds. Please note, we deduct 15% from the draw’s total income to cover our platform operating costs and card transaction fees.</p>
                    </div>
                </div>
            </div>

            <div className="marketing-support-sec container-fluidtext-center">
                <div className="container px-5 py-5" style={{ backgroundColor: '#f2f2f2' }}>
                <div className="container pb-5 mb-5 text-center ">
                    <h2 className="display-4 branding1 mb-3">Marketing <strong className='font-face-gb-semibold'>Support</strong></h2>
                    <p>Part of our service is to provide promotional materials to help you spread the word about your draw to your supporters.</p>
                    <p>Draw hosts will be invited to complete a marketing materials request form as part of the sign-up process. Within this, you will be able to select the materials which you would like to receive to help promote your draw from those listed. We are always open to new ideas, so if there is anything you would like us to support with that you feel would really connect with your supporters then please let us know and we will do our best to facilitate this.</p>
                    <p>Please note, we do not provide physical materials – any print files requested will be the digital file only.</p>
                </div>
                <div className='monthly-slider'>
                    <MarketingSupport/>
                </div>
                </div>
            </div>

            {/* <div className="container pt-3 pb-5 px-lg-5 mb-4 text-center">
                <div className='row'>
                    <HostSliderSection />
                </div>
            </div> */}

            <div className="container p-5 px-lg-5 text-center" style={{ backgroundColor: '#59B8B2' }}>
                <div className='row align-items-center'>
                <div className='col-sm-12 col-md-12 col-lg-6 mt-lg-0 mt-4'>
                        <div className='row'>
                            <div className='col-12'>
                                <h2 className='display-4 text-light fw-normal text-start'>Who has <strong>Benefitted</strong></h2>
                                <p className='fw-normal text-light text-start'>
                                    Grassroots Sports are a huge driving force behind what is, Play Fund Win. By hosting a draw on our digital platform, you are not only broadening the size of your target audience, but you are enabling your club or organisation to fundraise quicker and simpler using our revolutionary technology. At Play Fund Win, we pride ourselves in keeping Grassroots Sports thriving through a time of crisis and allowing community members of each organisation, charity, or club to win prizes whilst supporting grassroots sports.
                                </p><p className='fw-normal text-light text-start'>
                                    We give YOUR team the exact same platform and exposure as we do our other partners in the Premier League, RFU & the RFL, because without grassroots sports there are no big leagues, and we truly believe that legends and legacies are created through grassroots sports.
                                </p><p className='fw-normal text-light text-start'>
                                    By hosting a draw with Play Fund Win, YOUR team, charity, or organisation will be exposed to the thousands of social media followers we have, with regular branded content being tailored around your club and cause, spreading your message further via an engaging and growing online presence and specialist social media marketing techniques directed towards the good cause of your choice.
                                </p><p className='fw-normal text-light text-start'>
                                    Grassroot Sports are engraved into the core foundations behind Play Fund Win, and we aim to give YOU the exposure you deserve by hosting a draw using our platform. Our easy to use platform generates a website which is bespoke to YOUR cause, alongside your logo, partners and cause, you will be able to view the analysis live as well as use a cutting edge app for both Apple and Android to ensure that your Grassroots Sports organisation draw is raising the money you require to continue your growth.
                                </p>
                            </div>
                        </div>
                        {/* <div className='p-lg-4 p-2'></div> */}
                    </div>
                    <div className='col-sm-12 col-md-12 col-lg-6 '>
                    <ActiveSlider />
                    {/* <div>
                                    <img width={'100%'} src={require('../assets/HostSliderSection2.1.png')} />
                                </div> */}
                    </div>
                    
                        {/* <div className='row'>
                            <div className='col-lg-6 col-md-6 col-sm-12 mb-3'>
                                <div>
                                    <img width={'100%'} src={require('../assets/HostSliderSection2.1.png')} />
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-12 mb-2'>
                                <div>
                                    <img width={'100%'} src={require('../assets/HostSliderSection2.2.png')} />
                                </div>
                            </div>
                        </div> */}
                </div>
            </div>

            <div className="faqs-section container-fluid p-5 px-lg-5">
                <div className="container">
                    <div className='faqs-wrap mx-auto' style={{ maxWidth: 900 }}>
                        <h2 className="display-4 branding1 mb-3 text-center"><strong className='font-face-gb-semibold'>FAQs</strong></h2>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className='font-face-gb-semibold faq-bg-color rounded-4 m-2'>What does it cost us?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Play Fund Win was established to raise much needed finds for sports clubs, charities, and other good causes across the UK. 100% of net proceeds go to these causes, with deductions consisting of the contribution to the cash jackpot (determined by the draw host) any marketing costs which the draw host may choose to include, and PFW’s operating costs and platform fee – which is 15%. This is split into 10% to cover administrative costs, licensing and transaction fees. 5% is the fee which Play Fund Win receives to support our ongoing operations.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header className='font-face-gb-semibold faq-bg-color rounded-4 m-2'>Does the cost change depending on how many tickets we sell?</Accordion.Header>
                                <Accordion.Body>
                                    <p>No. Our 15% fee is deducted at source and will not change regardless of how many or few tickets are sold on your draw.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header className='font-face-gb-semibold faq-bg-color rounded-4 m-2'>Is the money safe?</Accordion.Header>
                                <Accordion.Body>
                                    <p>In short, yes. All payments are processed through our secure payment gateway. We are regulated by the Gambling Commission who set minimum security levels for the acceptance of card transactions.  Recent changes to UK legislation mean that credit cards cannot be used for prize draw, gambling, or lottery transactions.</p>
                                    <p>As part of our anti-money laundering process, we must ensure that funds are not being misappropriated. We ask draw administrators to keep us updated on where funds are being utilised and share success stories with Play Fund Win members as and when you achieve them. The funds paid to the cause by Play Fund Win will only be credited into a cause’s bank account – and these are verified during the sign-up process. No personal accounts will be accepted.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header className='font-face-gb-semibold faq-bg-color rounded-4 m-2'>How do you pay us?</Accordion.Header>
                                <Accordion.Body>
                                    <p>All revenues due to the cause will be paid via bank transfer within 28 days from the close of each draw, but in most cases payments are sent more quickly than this. The delay in payment is to ensure all funds and fees are balanced in arrears prior to payments being made, and any donations from prize winners are included.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header className='font-face-gb-semibold faq-bg-color rounded-4 m-2'>How long is the contract?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Unless otherwise stated, there are no contractual obligations with using Play Fund Win. We ask for a level of commitment prior to working on developing your webpage or any promotional materials.</p>
                                    <p>As you may have entrants on your draw, we are unable to end a draw prior to the publicised end date – and this applies to monthly subscription draws too.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header className='font-face-gb-semibold faq-bg-color rounded-4 m-2'>Can I see how many tickets we sell?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Yes - we provide full reporting on ticket sales, and in the coming months this information will become visible within your host dashboard.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header className='font-face-gb-semibold faq-bg-color rounded-4 m-2'>How is the draw done?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Every Play Fund Win ticket contains a unique, randomly generated ticket number. At the time of the draw, Play Fund Win use our certified random number generator to select one winner per prize at random from all the entrants in that draw. This number is the winning entry in that particular draw.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7">
                                <Accordion.Header className='font-face-gb-semibold faq-bg-color rounded-4 m-2'>IDo we need to have any licenses in place?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Play Fund Win look after all licensing and compliance on your draw. Play Fund Win draws are administered by PFW Holdings Ltd. (Company no. 12267104), we run both licenced draws (lotteries) and unlicenced Prize Draws and we are a registered External Lottery Manager in the UK. Our UK Gambling Commission licence number is 057889-R-333420-001.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="8">
                                <Accordion.Header className='font-face-gb-semibold faq-bg-color rounded-4 m-2'>How long does it take to get set up?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Once we have an agreement in place together with the materials that we need from the club to enable page set up then we aim to have your page ready to go live within 72 hours</p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <p className='text-center mt-4'>For our full list of frequently asked questions, please <Link className='text-decoration-none branding3' to={'/help-centre'}>click here</Link></p>
                    </div>
                </div>
                </div>
            </div>

            <FooterCta />
            <SiteFooter />
        </>
    )
}

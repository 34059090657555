import Slider from "react-slick";
import ImageLoader from "./imageLoader";
import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import axios from "axios";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="custom-arrow next-arrow"
      style={{ ...style, display: "block"}}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="custom-arrow prev-arrow"
      style={{ ...style, display: "block"}}
      onClick={onClick}
    />
  );
}

export default function PrizeSliderTemp3Bottom(props) {

  

  const APIURL = process.env.REACT_APP_API_URL;

  const [DrawData, setDrawData] = useState([]);
  const [PrizeData, setPrizeData] = useState([]);
  const [totalQuantities, setTotalQuantities] = useState(0);

  const [open, setOpen] = useState(false);

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        centerMode: false,
    centerPadding: '120px',
        slidesToShow: 1,
        slidesToScroll: 1,
        slidesToShow: 1,
        autoplaySpeed: 2500,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 1,
              centerMode: false,
              centerPadding: '120px'
            }
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 1,
              centerMode: false,
              centerPadding: '120px'
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              centerMode: false,
              centerPadding: '120px'
            }
          },
        ]
      };
      const parser = new DOMParser();
      console.log("padding",props.isPadding);

      function calculateTotalQuantity(PrizeData) {
        let sum = 0;
        {PrizeData.forEach(item => {
          sum += item.prizeQuantity;
        })}
        return sum;
      }
    
      useEffect(() => {
        axios.get(APIURL + `/draws/${props.drawID}/prizes` , {
          withCredentials: true,
          credentials: "same-origin"
        }).then(res => {
          setPrizeData(res?.data?.content);
          setTotalQuantities(calculateTotalQuantity(res?.data?.content));
     });
     }, [])

     return (
      <div className="prize-block-inner px-0 py-5 pt-0">
       <Slider {...settings}>
       {PrizeData?.map(item => {
          return (
        <div className='prize-block px-3'>
              <div>
                <div className="prize-photo bg-white col-sm-12 col-md-12 col-lg-12 text-center p-5 position-relative" style={{height: 330, 
                  backgroundImage: `url(${item.prizeImageURL})`, backgroundSize: "cover"   }}>
                </div>

                <div className="prize-block-testimonial single-inner text-left py-3 col-sm-12 px-2 col-md-12 col-lg-12" style={{borderBottomLeftRadius : 8 ,borderBottomRightRadius : 8 , backgroundColor : '#ffffff' , boxShadow : 'rgba(0, 0, 0, 0.2) 0px 0px 3px'}}>
                <h4 className="font-face-sh-bold title-slider text-center py-2 fs-5 branding1 mt-0 mb-0 px-3" dangerouslySetInnerHTML={{ __html: `${item.prizeName}` }}></h4>
                {/* <h4 className="fw-normal fs-5 branding1 mt-0 mb-0 px-2"dangerouslySetInnerHTML={{ __html: parser.parseFromString(`${item.content}`, 'text/html').body.textContent }}></h4> */}
                <div id="example-collapse-text">
                  {/* <h4 className="fw-normal fs-5 branding1 mt-0 mb-0 px-3" dangerouslySetInnerHTML={{ __html: `${item.prizeDescription}` }}></h4> */}

                  {item.prizeDescription
                  .replace(/&nbsp;/g, '')
                  .replace(/&#10;/g, '\n')
                  .replace(/&#39;/g, "'")
                  .replace(/&lsquo;/g, "'")
                  .replace(/&rsquo;/g, "'")
                  .replace(/&#8239;/g, " ")
                  .split('\n').map((paragraph, index) => {
                  const trimmedParagraph = paragraph.trim();
                  if (trimmedParagraph !== '') {
                    return (
                    <>
                        {index !== 0 ?  <li key={index} className= {`fw-normal fs-5 branding1 mt-0 mb-0 ms-5 ${index}`}>{trimmedParagraph}</li> : <h4 className="fw-normal fs-5 branding1 mt-0 mb-2 px-3">{trimmedParagraph}</h4>} {/* Add 'li' tag for the first sentence */}
                        </>
                    );
                  }
                  return null; // Exclude empty sentences from being displayed as li items
                })}


                </div>

                <div className="text-center">
                {/* <Button className='btn text-center collaps-button-prize bg-branding2 border-0 rounded-pill py-2 mt-0 lh-2'
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                >
                  {open?"Hide":"What's Included"}
                </Button> */}
                </div>
  
                
                </div>
             </div> 
            </div>
              )
            })}



  
          </Slider>
      </div>
    );

//   return (
//     <div className="prize-block-inner px-0 py-5 pt-0">
//      <Slider {...settings}>
//           {props.prizeData.map(item => {

// console.log("item",item);
// return (

    

//       <div className={`prize-block px-3 ${item.content}`} key={item.id + item.image}>
//             <div>
//             { (item.image != 'image') ?

//               <div className="prize-photo bg-white col-sm-12 col-md-12 col-lg-12 text-center p-5 position-relative" style={{height: 330, 
//                 backgroundImage: `${item.image.includes("http")
//                 ? `url(${item.image})`
//                 : `url(${require('../assets/images/draws/' + item.image)})`
//                 }`, backgroundSize: "cover"   }}>
                
//                   {/* <span className="display-1 text-white"></span> */}
//               </div>
//                : ""
//             }
//               <div className="prize-block-testimonial text-left py-3 col-sm-12 px-2 col-md-12 col-lg-12" style={{borderBottomLeftRadius : 8 ,borderBottomRightRadius : 8 , backgroundColor : '#ffffffe5' }}>
//               <h4 className="font-face-sh-bold title-slider text-center py-2 fs-5 branding1 mt-0 mb-0 px-3" dangerouslySetInnerHTML={{ __html: `${item.title}` }}></h4>
//               {/* <h4 className="fw-normal fs-5 branding1 mt-0 mb-0 px-2"dangerouslySetInnerHTML={{ __html: parser.parseFromString(`${item.content}`, 'text/html').body.textContent }}></h4> */}
//               <div id="example-collapse-text" className={!open?"collapse":"active"}>
//                 <h4 className="fw-normal fs-5 branding1 mt-0 mb-0 px-3" dangerouslySetInnerHTML={{ __html: `${item.content}` }}></h4>
//               </div>
//               {/* </Collapse> */}
//               <div className="text-center">
//               <Button className='btn text-center collaps-button-prize bg-branding2 border-0 rounded-pill py-2 mt-0 lh-2'
//                 onClick={() => setOpen(!open)}
//                 aria-controls="example-collapse-text"
//                 aria-expanded={open}
//               >
//                 {open?"Hide":"What's Included"}
//               </Button>
//               </div>

              
//               </div>
//            </div> 
//           </div>
          
// )
//           })}

//         </Slider>
//     </div>
//   );
}

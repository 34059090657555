import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Home_Images } from './images';
import { ReactComponent as IconTicket } from '../assets/images/noun-ticket-5509649-FFFFFF.svg';
import Accordion from 'react-bootstrap/Accordion';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useCart } from 'react-use-cart';

export default function Siteheader(props) {
  const [scroll, setScroll] = React.useState(false);
  const { pathname } = useLocation();
  const [Sidebar, setSidebar] = React.useState(false);
  const [template, setTemplate] = React.useState(props?.template ? props.template : 1);
  const navigate = useNavigate();
  let userDetail = secureLocalStorage.getItem('LogObject');
  let APIURL = process.env.REACT_APP_API_URL;
  const { isEmpty, totalUniqueItems, items, cartTotal, updateItemQuantity, removeItem, emptyCart } = useCart();
  let CartDetail = localStorage.getItem('react-use-cart') ? JSON.parse(localStorage.getItem('react-use-cart')) : [];

  React.useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 10);
    });
  }, []);
  useEffect(() => {
    setTemplate(props?.template ? props.template : 1);
  }, [props]);

  function logoutHanlder(e) {
    e.preventDefault();

    axios.delete(`${APIURL}/auth/logout`).then((res) => {
      secureLocalStorage.clear();
      navigate('/');
    });
  }

  return (
    <>
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <header id="header">
        <div className="trust-bar bg-branding3 py-2">
          <div className="container">
            <div className="trust-bar-inner d-flex align-items-center justify-content-between">
              <p className="trust-bar-left text-white text-uppercase m-0" style={{ fontSize: 18, paddingTop: 1 }}>
                <strong>1 Raffle & Prize</strong> Draw Platform
              </p>
              <p className="trust-bar-center text-white text-uppercase m-0" style={{ fontSize: 18, paddingTop: 1 }}>
                <strong>250,000+</strong> Winners
              </p>
              <p className="trust-bar-right text-white text-uppercase m-0" style={{ fontSize: 18, paddingTop: 1 }}>
                <strong>£1 Million+</strong> Raised for Good Causes
              </p>
            </div>
          </div>
        </div>
        {
          pathname === '/' || pathname === '/host' ? (
            <nav
              className={
                scroll
                  ? 'bg-light navbar navbar-expand-sm bg-light navbar-dark fixed-top py-3'
                  : 'bg-transparent navbar navbar-expand-sm bg-light navbar-dark fixed-top py-3'
              }
            >
              <div className="container w-100 px-4">
                <div className="col-6">
                  <Link to={'/'} className="navbar-brand">
                    <img
                      src={scroll ? Home_Images.Logo : require('../assets/images/pfw-logo-white.png')}
                      style={{ width: '260px' }}
                    />
                  </Link>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <ul className="unstyled list-unstyled d-flex mb-0 py-1 align-items-center">
                    <li className="responsive-menu-data">
                      <Link
                        to={'/find-a-draw'}
                        className={
                          scroll
                            ? 'p-2 menu-item px-3 fs-6 text-decoration-none d-block branding1 red-hover'
                            : 'p-2 menu-item px-3 fs-6 text-decoration-none d-block color-white red-hover'
                        }
                      >
                        Live Draws
                      </Link>
                    </li>
                    <li className="responsive-menu-data">
                      <Link
                        to={'https://www.playfundwin.com/start-fundraising'}
                        className={
                          scroll
                            ? 'p-2 menu-item px-3 fs-6 text-decoration-none d-block branding1 red-hover'
                            : 'p-2 menu-item px-3 fs-6 text-decoration-none d-block color-white red-hover'
                        }
                      >
                        Start Fundraising
                      </Link>
                    </li>
                    {/* {userDetail?<Link to={'/careers'} className='p-2 menu-item px-5 fs-6 fw-bold text-decoration-none d-block color-white red-hover'>Careers</Link>:""} */}
                    {/* <li className='responsive-menu-data'><Link to={"/legal"} className={scroll ? 'p-2 menu-item px-3 fs-6 text-decoration-none d-block branding1 red-hover' : 'p-2 menu-item px-3 fs-6 text-decoration-none d-block color-white red-hover'}>Legal</Link></li> */}
                    {userDetail ? (
                      <li className="responsive-menu-data">
                        <a
                          href="/"
                          className={
                            scroll
                              ? 'p-2 menu-item px-3 fs-6 text-decoration-none d-block branding1 red-hover'
                              : 'p-2 menu-item px-3 fs-6 text-decoration-none d-block color-white red-hover'
                          }
                          onClick={(e) => logoutHanlder(e)}
                        >
                          Logout
                        </a>
                      </li>
                    ) : (
                      ''
                    )}

                    {/* <a href="#" className='p-2 menu-item  px-5 fs-6 fw-bold text-decoration-none d-block color-white '>Logout</a> */}

                    {userDetail ? (
                      <>
                        {/* <li className='responsive-menu-data'><a href="/" className={scroll ? 'p-2 menu-item px-3 fs-6 text-decoration-none d-block branding1 red-hover' : 'p-2 menu-item px-3 fs-6 text-decoration-none d-block color-white red-hover'} onClick={(e) => logoutHanlder(e)}>Logout</a></li> */}
                      </>
                    ) : (
                      <>
                        <div
                          className={
                            scroll
                              ? 'bg-branding1 rounded-pill d-flex align-items-center px-4 py-2 mx-3'
                              : 'bg-white rounded-pill d-flex align-items-center px-4 py-1 mx-3'
                          }
                        >
                          <li className="responsive-menu-data">
                            <Link
                              to={'/login'}
                              className={
                                scroll
                                  ? 'p-2 menu-item px-1 fs-6 text-decoration-none d-block text-white red-hover'
                                  : 'p-2 menu-item px-1 fs-6 text-decoration-none d-block branding1 red-hover'
                              }
                            >
                              Login
                            </Link>
                          </li>
                          <span className={scroll ? 'text-white p-1' : 'branding1 p-1'}>/</span>
                          <li className="responsive-menu-data">
                            <Link
                              to={'/user-registration'}
                              className={
                                scroll
                                  ? 'p-2 menu-item px-1 fs-6 text-decoration-none d-block text-white red-hover'
                                  : 'p-2 menu-item px-1 fs-6 text-decoration-none d-block branding1 red-hover'
                              }
                            >
                              Sign up
                            </Link>
                          </li>
                        </div>
                      </>
                    )}

                    {/* <li className=''>
                    <Link to={'/find-a-draw'} className="right-menu secbutton-border rounded-circle btn w-16 h-16" style={{ backgroundImage: `url(${require(`../assets/images/Artboard-1.png`)})`, padding: 24 }} ></Link>
                  </li>
                  <li className='ms-3'>
                    <Link to={'/host'} className="right-menu pributton-border rounded-circle btn w-16 h-16" style={{ backgroundImage: `url(${require(`../assets/images/Artboard-2.png`)})`, padding: 24 }}></Link>
                  </li> */}
                    <li className="ms-3" style={{ position: 'relative' }}>
                      <span className="overCart">{CartDetail.totalItems ? CartDetail.totalItems : 0}</span>
                      <Link
                        to={'/checkout'}
                        className="right-menu right-menu-First right-menu-First-blue  rounded-circle btn w-16 h-16"
                        style={{ padding: 24 }}
                      >
                        <IconTicket />
                      </Link>
                    </li>
                    <li className="ms-3">
                      <button
                        className="right-menu rounded-circle btn pributton w-16 h-16"
                        onClick={() => setSidebar(true)}
                        style={{ backgroundImage: `url(${require(`../assets/images/menu.png`)})`, padding: 24 }}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          ) : (
            //: pathname.includes('/manage') ?

            <nav
              className={
                scroll
                  ? 'bg-light navbar navbar-expand-sm bg-light fixed-top navbar-dark py-3'
                  : 'bg-light navbar navbar-expand-sm bg-light navbar-dark py-3'
              }
            >
              <div className="container w-100 px-4">
                <div className="col-6">
                  <Link to={'/'} className="navbar-brand">
                    <img src={scroll ? Home_Images.Logo : Home_Images.Logo} style={{ width: '260px' }} />
                  </Link>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <ul className="unstyled list-unstyled d-flex mb-0 py-1 align-items-center">
                    <li className="responsive-menu-data">
                      <Link
                        to={'/find-a-draw'}
                        className={
                          scroll
                            ? 'p-2 menu-item px-3 fs-6 text-decoration-none d-block branding1 red-hover'
                            : 'p-2 menu-item px-3 fs-6 text-decoration-none d-block branding1 red-hover'
                        }
                      >
                        Live Draws
                      </Link>
                    </li>
                    <li className="responsive-menu-data">
                      <Link
                        to={'https://www.playfundwin.com/start-fundraising'}
                        className={
                          scroll
                            ? 'p-2 menu-item px-3 fs-6 text-decoration-none d-block branding1 red-hover'
                            : 'p-2 menu-item px-3 fs-6 text-decoration-none d-block branding1 red-hover'
                        }
                      >
                        Start Fundraising
                      </Link>
                    </li>
                    {/* {userDetail?<Link to={'/careers'} className='p-2 menu-item px-5 fs-6 fw-bold text-decoration-none d-block color-white red-hover'>Careers</Link>:""} */}
                    {/* <li className='responsive-menu-data'><Link to={"/legal"} className={scroll ? 'p-2 menu-item px-3 fs-6 text-decoration-none d-block branding1 red-hover' : 'p-2 menu-item px-3 fs-6 text-decoration-none d-block color-white red-hover'}>Legal</Link></li> */}
                    {userDetail ? (
                      <li className="responsive-menu-data">
                        <a
                          href="/"
                          className={
                            scroll
                              ? 'p-2 menu-item px-3 fs-6 text-decoration-none d-block branding1 red-hover'
                              : 'p-2 menu-item px-3 fs-6 text-decoration-none d-block branding1 red-hover'
                          }
                          onClick={(e) => logoutHanlder(e)}
                        >
                          Logout
                        </a>
                      </li>
                    ) : (
                      ''
                    )}

                    {/* <a href="#" className='p-2 menu-item  px-5 fs-6 fw-bold text-decoration-none d-block color-white '>Logout</a> */}

                    {userDetail ? (
                      <>
                        {/* <li className='responsive-menu-data'><a href="/" className={scroll ? 'p-2 menu-item px-3 fs-6 text-decoration-none d-block branding1 red-hover' : 'p-2 menu-item px-3 fs-6 text-decoration-none d-block color-white red-hover'} onClick={(e) => logoutHanlder(e)}>Logout</a></li> */}
                      </>
                    ) : (
                      <>
                        <div
                          className={
                            scroll
                              ? 'bg-branding1 rounded-pill d-flex align-items-center px-4 py-2 mx-3'
                              : 'bg-branding1 rounded-pill d-flex align-items-center px-4 py-2 mx-3'
                          }
                        >
                          <li className="responsive-menu-data">
                            <Link
                              to={'/login'}
                              className={
                                scroll
                                  ? 'p-2 menu-item px-1 fs-6 text-decoration-none d-block text-white red-hover'
                                  : 'p-2 menu-item px-1 fs-6 text-decoration-none d-block text-white red-hover'
                              }
                            >
                              Login
                            </Link>
                          </li>
                          <span className={scroll ? 'text-white p-1' : 'text-white p-1'}>/</span>
                          <li className="responsive-menu-data">
                            <Link
                              to={'/user-registration'}
                              className={
                                scroll
                                  ? 'p-2 menu-item px-1 fs-6 text-decoration-none d-block text-white red-hover'
                                  : 'p-2 menu-item px-1 fs-6 text-decoration-none d-block text-white red-hover'
                              }
                            >
                              Sign up
                            </Link>
                          </li>
                        </div>
                      </>
                    )}

                    {/* <li className=''>
                    <Link to={'/find-a-draw'} className="right-menu secbutton-border rounded-circle btn w-16 h-16" style={{ backgroundImage: `url(${require(`../assets/images/Artboard-1.png`)})`, padding: 24 }} ></Link>
                  </li>
                  <li className='ms-3'>
                    <Link to={'/host'} className="right-menu pributton-border rounded-circle btn w-16 h-16" style={{ backgroundImage: `url(${require(`../assets/images/Artboard-2.png`)})`, padding: 24 }}></Link>
                  </li> */}
                    <li className="ms-3" style={{ position: 'relative' }}>
                      <span className="overCart">{CartDetail.totalItems ? CartDetail.totalItems : 0}</span>
                      <Link
                        to={'/checkout'}
                        className="right-menu right-menu-First right-menu-First-blue  rounded-circle btn w-16 h-16"
                        style={{ padding: 24 }}
                      >
                        <IconTicket />
                      </Link>
                    </li>
                    <li className="ms-3">
                      <button
                        className="right-menu rounded-circle btn pributton w-16 h-16"
                        onClick={() => setSidebar(true)}
                        style={{ backgroundImage: `url(${require(`../assets/images/menu.png`)})`, padding: 24 }}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          )
          // :
          // <nav className={scroll ? "bg-light navbar navbar-expand-sm bg-light navbar-dark py-3" : "bg-transparent navbar navbar-expand-sm bg-light navbar-dark py-3"}>
          //   <div className='container'>
          //     <div className='col-6'>
          //       <Link to={'/'} className="navbar-brand" >
          //         <img src={Home_Images.Logo} style={{ width: '260px' }} />
          //       </Link>
          //     </div>
          //     <div className='col-6 d-flex justify-content-end'>
          //       <ul className='unstyled list-unstyled d-flex mb-0'>

          //         <li className=''>
          //           <Link to={'/find-a-draw'} className="right-menu secbutton-border rounded-circle btn w-16 h-16" style={{ backgroundImage: `url(${require(`../assets/images/Artboard-1.png`)})`, padding: 22 }} ></Link>
          //         </li>
          //         <li className='ms-3'>
          //           <Link to={'/host'} className="right-menu pributton-border rounded-circle btn w-16 h-16" style={{ backgroundImage: `url(${require(`../assets/images/Artboard-2.png`)})`, padding: 22 }}></Link>
          //         </li>

          //         <li className='ms-3' style={{ position: "relative" }}>
          //           <span className='overCart'>{CartDetail.totalItems ? CartDetail.totalItems : 0}</span>
          //           <Link to={'/checkout'} className="right-menu right-menu-First right-menu-First-blue  rounded-circle btn w-16 h-16" style={{ padding: 22 }} ><IconTicket /></Link>
          //         </li>

          //         <li className='ms-3'>
          //           <button className="right-menu rounded-circle btn pributton w-16 h-16 p-4" onClick={() => setSidebar(true)} style={{ backgroundImage: `url(${require(`../assets/images/menu.png`)})`, padding: 22 }} />

          //         </li>

          //       </ul>
          //     </div>
          //   </div></nav>
        }

        <div
          id="mySidebar"
          className={
            Sidebar
              ? 'open sidebar thirbutton  h-100 top-0 end-0 position-fixed pt-2 ease-in-out overflow-hidden'
              : 'sidebar h-100 w-0 end-0 position-fixed pt-2 ease-in-out overflow-hidden'
          }
        >
          <a
            className="text-decoration-none lh-base border-0  btn-default closebtn position-absolute top-0 end-0 me-3 fs-2 color-white red-hover"
            onClick={() => setSidebar(false)}
            style={{ cursor: 'pointer' }}
          >
            x
          </a>
          <div className="p-3 d-block "></div>
          {userDetail == null ? (
            <>
              <Link
                to={'/login'}
                className="p-2 menu-item px-5 fs-6 fw-bold text-decoration-none d-block color-white red-hover"
              >
                Login / Sign-up
              </Link>
              <Link
                to={'/find-a-draw'}
                className="p-2 menu-item  px-5 fs-6 fw-bold text-decoration-none d-block color-white red-hover"
              >
                Live Draws
              </Link>
              <Link
                to={'https://www.playfundwin.com/start-fundraising'}
                className="p-2 menu-item px-5 fs-6 fw-bold text-decoration-none d-block color-white red-hover"
              >
                Start Fundraising
              </Link>
            </>
          ) : (
            <>
              {/*User Navigation*/}
              <Link
                to={'/find-a-draw'}
                className="p-2 menu-item  px-5 fs-6 fw-bold text-decoration-none d-block color-white red-hover"
              >
                Live Draws
              </Link>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="font-face-gb-semibold">Security</Accordion.Header>
                  <Accordion.Body>
                    <Link
                      className="text-light text-decoration-none red-hover w-100 d-block mb-3"
                      to={'/account-edit-password'}
                    >
                      Change Password
                    </Link>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="font-face-gb-semibold">Purchase history</Accordion.Header>
                  <Accordion.Body>
                    <Link
                      className="text-light text-decoration-none red-hover w-100 d-block mb-3"
                      to={'/purchase-history'}
                    >
                      Purchase History
                    </Link>
                    <Link className="text-light text-decoration-none red-hover w-100 d-block mb-3" to={'/prizes-won'}>
                      Prizes Won
                    </Link>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header className="font-face-gb-semibold">Billing</Accordion.Header>
                  <Accordion.Body>
                    <Link
                      className="text-light text-decoration-none red-hover w-100 d-block mb-3"
                      to={'/billing-payment-methods'}
                    >
                      Payment Methods
                    </Link>
                    <Link
                      className="text-light text-decoration-none red-hover w-100 d-block mb-3"
                      to={'/billing-subscriptions'}
                    >
                      Subscriptions
                    </Link>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header className="font-face-gb-semibold">My Account</Accordion.Header>
                  <Accordion.Body>
                    <Link
                      className="text-light text-decoration-none red-hover w-100 d-block mb-3"
                      to={'/user-edit-contact-details'}
                    >
                      Contact Details
                    </Link>
                    <Link className="text-light text-decoration-none red-hover w-100 d-block mb-3" to={'/prizes-won'}>
                      Prizes Won
                    </Link>
                    <Link
                      className="text-light text-decoration-none red-hover w-100 d-block mb-3"
                      to={'/billing-payment-methods'}
                    >
                      Payment Methods
                    </Link>
                    <Link
                      className="text-light text-decoration-none red-hover w-100 d-block mb-3"
                      to={'/billing-subscriptions'}
                    >
                      Subscriptions
                    </Link>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              {/* <Link to={'/dashboard'} className='p-2 menu-item px-5 fs-6 fw-bold text-decoration-none d-block color-white red-hover'>My Account</Link> */}

              {/* Admin Navigation 
  <Link to={'/dashboard'} className='p-2 menu-item px-5 fs-6 fw-bold text-decoration-none d-block color-white red-hover'>Dashboard</Link>
  <Accordion>
    <Accordion.Item eventKey="0">
      <Accordion.Header className='font-face-gb-semibold'>Contact</Accordion.Header>
      <Accordion.Body>
        <Link className='text-light text-decoration-none red-hover w-100 d-block mb-3' to={"/club-edit-contact-details"}>Contact details</Link>
      </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="1">
      <Accordion.Header className='font-face-gb-semibold'>Draws</Accordion.Header>
      <Accordion.Body>
        <Link className='text-light text-decoration-none red-hover w-100 d-block mb-3' to={"/create-a-draw"}>Club Create a Draw</Link>
        <Link className='text-light text-decoration-none red-hover w-100 d-block mb-3' to={"/club-view-draws"}>Club View Draws</Link>
        <Link className='text-light text-decoration-none red-hover w-100 d-block mb-3' to={"/draws-reports"}>Draws Reports</Link>
      </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="3">
      <Accordion.Header className='font-face-gb-semibold'>Public Profile</Accordion.Header>
      <Accordion.Body>
        <Link className='text-light text-decoration-none red-hover w-100 d-block mb-3' to={"/club-edit-profile"}>Edit branding</Link>
        <Link className='text-light text-decoration-none red-hover w-100 d-block mb-3' to={"/edit-sponsors"}>Edit Sponsors</Link>
      </Accordion.Body>
    </Accordion.Item>
  </Accordion>
  <Link to={'/purchase-history'} className='p-2 menu-item  px-5 fs-6 fw-bold text-decoration-none d-block color-white red-hover'>Purchase history</Link> */}
            </>
          )}
          <div className="w-75 px-5">
            <hr className="px-5 color-white background-white" style={{ opacity: '1' }} />
          </div>
          <Link
            to={'https://www.playfundwin.com/about'}
            className="p-2 menu-item px-5 fs-6 fw-bold text-decoration-none d-block color-white red-hover"
          >
            About Us
          </Link>
          <Link
            to={'https://www.playfundwin.com/contact'}
            className="p-2 menu-item px-5 fs-6 fw-bold text-decoration-none d-block color-white red-hover"
          >
            Help Center
          </Link>
          <Link
            to={'https://www.playfundwin.com/blog'}
            className="p-2 menu-item px-5 fs-6 fw-bold text-decoration-none d-block color-white red-hover"
          >
            Blog / Media
          </Link>
          <Link
            to={'https://www.playfundwin.com/legal-and-compliance'}
            className="p-2 menu-item px-5 fs-6 fw-bold text-decoration-none d-block color-white red-hover"
          >
            Legal
          </Link>
          {userDetail ? (
            <a
              href="/"
              className="p-2 menu-item px-5 fs-6 fw-bold text-decoration-none d-block color-white red-hover"
              onClick={(e) => logoutHanlder(e)}
            >
              Logout
            </a>
          ) : (
            ''
          )}

          {/* <a href="#" className='p-2 menu-item  px-5 fs-6 fw-bold text-decoration-none d-block color-white '>Logout</a> */}
        </div>
      </header>
    </>
  );
}

import React from 'react'
import { Link } from "react-router-dom";
import Siteheader from '../components/header';
import SiteFooter from '../components/footer';
import { UseEffect } from 'react';
import FooterCta from '../components/footer-cta';
import Innerheader from '../components/inner-header';
import { Helmet } from 'react-helmet';

export default function WorkWithUs(props) {
 
  return (
    <>
    <Helmet>‍
      <title>Work With Us | Play Fund Win</title>‍
      <meta name="description" content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities." />
    </Helmet>
    <Siteheader/>
    <Innerheader title="Work for us"/>

  <div className='container pt-5 pb-5 my-3 px-lg-5 rounded-4 w-92' style={{backgroundColor: '#f2f2f2'}}>
    <div className="row px-lg-5">
      <div className="col-md-5 align-middle text-center about-left-img position-relative">
        <img width="100%" src={require('../assets/images/Team-Feb-22.png')} />
       </div>
       <div className="col-md-7 align-middle text-left px-lg-5 mt-lg-0 mt-3">
        <h2 className="text-white font-weight-bold display-6 mb-3"><strong className='branding1 fw-bolder'>Why work for us?</strong></h2>
        <p>We’re a start-up so we’re small and agile. We’re growing fast and we’re looking for dedicated people who can learn and grow with us. Play Fund Win is on a mission to raise millions of pounds for good causes and charities using our revolutionary digital raffle platform. By joining us you will play a key part in changing the lives of thousands of beneficiaries.</p>
        <p>We believe that every member of our team’s voice should be heard, and so not only will you have the opportunity to develop personally, but you’ll also have the chance to genuinely impact the decisions which we make as an organisation. Check out the roles which we are currently recruiting for below.</p>
        <a className="border-0 rounded-pill bg-branding1 text-white btn pfw-button-white py-2 px-5 font-face-gb-semibold" style={{lineHeight : 2}} href="/host">Get in touch</a>
       </div>
    </div>
  </div>

  <FooterCta/>
  <SiteFooter/>
    </>
  )
}

import React, { useEffect, useState } from 'react'
import SiteFooter from '../components/footer';
import Siteheader from '../components/header';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import UpcomingDrawsSlider from '../components/upcoming-draws-slider';
import { Helmet } from 'react-helmet';
import UserActiveTicketsSlider from '../components/userActiveTicketsSlider';

export default function Dashboard() {

    return (
        <>
            <Helmet>‍
                <title>My Account | Play Fund Win</title>‍
                <meta name="description" content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities." />
            </Helmet>
            <Siteheader />

            <section className="main-section h-100 w-100 font-face-gb">
                <div className="container h-100 px-0 py-5 bg-f2 position-relative bg-before-after" style={{ borderRadius: 10 }}>
                    <div className="font-face-gb pt-4 pb-4">
                        <h1 className="branding1 display-3 text-center">My <strong className='font-face-gb-semibold'>Account</strong></h1>
                    </div>

                    {/* Player Dashboard */}

                    <div className='player-live-draws py-5 mb-' style={{ backgroundColor: '#ebeaea' }}>
                        <h3 className='branding3 font-face-gb-semibold text-center fs-4 position-relative'>Your Live Tickets</h3>
                        <UserActiveTicketsSlider />
                        
                    </div>

                    <div className='row player-dashboard-row mx-auto pb-5 pt-5' style={{ width: '74%' }}>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className="dashboard-nav font-face-gb pb-5">
                                {/* <Accordion defaultActiveKey="0"> */}
                                <div className='row'>
                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                        <Link className='branding1 font-face-gb-semibold text-decoration-none red-hover w-100 d-block mb-4' to={"/user-edit-contact-details"}>Edit Profile Details</Link>
                                        {/* <Accordion.Item eventKey="2">
                                            <Accordion.Header className='font-face-gb-semibold'>Account Setting</Accordion.Header>
                                            <Accordion.Body>
                                            </Accordion.Body>
                                        </Accordion.Item> */}
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                        <Link className='branding1 font-face-gb-semibold text-decoration-none red-hover w-100 d-block mb-4' to={"/account-edit-password"}>Change Password</Link>
                                        {/* <Accordion.Item eventKey="0">
                                            <Accordion.Header className='font-face-gb-semibold'>Security</Accordion.Header>
                                            <Accordion.Body>
                                            </Accordion.Body>
                                        </Accordion.Item> */}
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                        <Link className='branding1 font-face-gb-semibold text-decoration-none red-hover w-100 d-block mb-4' to={"/purchase-history"}>Purchase History</Link>
                                        <Link className='branding1 font-face-gb-semibold text-decoration-none red-hover w-100 d-block mb-4' to={"/prizes-won"}>Prizes Won</Link>
                                        {/* <Accordion.Item eventKey="1">
                                            <Accordion.Header className='font-face-gb-semibold'>Purchase history</Accordion.Header>
                                            <Accordion.Body>
                                            </Accordion.Body>
                                        </Accordion.Item> */}
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                        <Link className='branding1 font-face-gb-semibold text-decoration-none red-hover w-100 d-block mb-4' to={"/billing-payment-methods"}>Payment Methods</Link>
                                        <Link className='branding1 font-face-gb-semibold text-decoration-none red-hover w-100 d-block mb-4' to={"/billing-subscriptions"}>Subscriptions</Link>
                                        {/* <Accordion.Item eventKey="3">
                                            <Accordion.Header className='font-face-gb-semibold'>Billing</Accordion.Header>
                                            <Accordion.Body>
                                            </Accordion.Body>
                                        </Accordion.Item> */}
                                    </div>
                          
                                </div>
                                {/* </Accordion> */}
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='upcoming-draws'>
                                <h3 className='branding3 font-face-gb-semibold text-center fs-4'>Upcoming draws</h3>
                                <UpcomingDrawsSlider />
                            </div>
                        </div>
                    </div>

                    {/* Admin Dashboard 
                <div className="dashboard-nav font-face-gb pb-5">
                    <Accordion defaultActiveKey="0">
                        <div className='row'>
                            <div className='col-lg-4 col-md-4 col-sm-12'>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className='font-face-gb-semibold'>Contact</Accordion.Header>
                                <Accordion.Body>
                                    <Link className='branding1 text-decoration-none red-hover w-100 d-block mb-3' to={"/club-edit-contact-details"}>Contact details</Link>
                                </Accordion.Body>
                            </Accordion.Item>
                            </div>
                            <div className='col-lg-4 col-md-4 col-sm-12'>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header className='font-face-gb-semibold'>Passwords</Accordion.Header>
                                <Accordion.Body>
                                <Link className='branding1 text-decoration-none red-hover w-100 d-block mb-3' to={"/edit-password"}>Edit passwords</Link>
                                </Accordion.Body>
                            </Accordion.Item>
                            </div>
                            <div className='col-lg-4 col-md-4 col-sm-12'>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header className='font-face-gb-semibold'>Draws</Accordion.Header>
                                <Accordion.Body>
                                <Link className='branding1 text-decoration-none red-hover w-100 d-block mb-3' to={"/view-draws"}>View Draws</Link>
                                <Link className='branding1 text-decoration-none red-hover w-100 d-block mb-3' to={"/draws-reports"}>Draws Reports</Link>
                                </Accordion.Body>
                            </Accordion.Item>
                            </div>
                            <div className='col-lg-4 col-md-4 col-sm-12'>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header className='font-face-gb-semibold'>Public Profile</Accordion.Header>
                                <Accordion.Body>
                                <Link className='branding1 text-decoration-none red-hover w-100 d-block mb-3' to={"/edit-branding"}>Edit branding</Link>
                                <Link className='branding1 text-decoration-none red-hover w-100 d-block mb-3' to={"/edit-sponsors"}>Edit Sponsors</Link>
                                </Accordion.Body>
                            </Accordion.Item>
                            </div>
                            <div className='col-lg-4 col-md-4 col-sm-12'>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header className='font-face-gb-semibold'>Purchase history</Accordion.Header>
                                <Accordion.Body>
                                <Link className='branding1 text-decoration-none red-hover w-100 d-block mb-3' to={"/purchase-history"}>Purchase history</Link>
                                </Accordion.Body>
                            </Accordion.Item>
                            </div>
                        </div>
                    </Accordion>
                </div> */}
                </div>
            </section>

            <SiteFooter />
        </>
    )
}

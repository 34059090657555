import React from 'react';
import { Link } from 'react-router-dom';
import Siteheader from '../components/header';
import SiteFooter from '../components/footer';
import { UseEffect } from 'react';
import FooterCta from '../components/footer-cta';
import Innerheader from '../components/inner-header';
import { Helmet } from 'react-helmet';
import Accordion from 'react-bootstrap/Accordion';

export default function FAQ(props) {
  return (
    <>
      <Helmet>
        ‍<title>Frequently Asked Questions | Play Fund Win</title>‍
        <meta
          name="description"
          content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities."
        />
      </Helmet>
      <Siteheader />
      <Innerheader title="Frequently Asked Questions " />

      <div className="container pt-5 pb-5 my-3 px-lg-5 rounded-4 w-92" style={{ backgroundColor: '#f2f2f2' }}>
        <div className="row px-lg-5 privacy-data">
          <div className="col-md-12 align-middle p-left px-lg-5 mt-lg-0 mt-3">
            <Accordion defaultActiveKey="1">
              <Accordion.Item eventKey="1">
                <Accordion.Header className="font-face-gb-semibold faq-bg-color rounded-4 m-2">
                  How do I enter the competition?{' '}
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    On the home page of our website you will see a box which says “Enter now”. Click that and you will
                    be taken to the entry form.{' '}
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>Stage 1:</strong> Answer the competition question by typing your answer in the box.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Stage 2:</strong> Select the number of entries you require. Please remember that these
                        are entries, not attempts to answer the competition question correctly. If your answer is wrong,
                        you will not be able to try again unless you purchase more entries. You will see the question
                        before you choose to enter but you cannot find out if you are right or wrong until after you
                        have purchased your entries.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Stage 3:</strong> Complete your details on the entry form and pay the entry fee. You
                        will need to tick the box to confirm that you accept our terms and conditions. You can read them
                        by clicking <a href="https://playfundwin.com/prize-draw-terms-conditions">here</a>.
                      </p>
                    </li>
                  </ul>

                  <p>
                    When you have paid your entry fee, you will be told if you have answered the question correctly. If
                    you have, your name will be entered in the draw with all of the other correct entries. If you have
                    not answered correctly, you will not be entered into the draw.
                  </p>

                  <p>
                    You will also receive an email confirming whether your answer is right or wrong and confirming if
                    you have been entered into the draw.
                  </p>

                  <p>
                    [There is also a free entry route available. To enter for free, send your name, address, telephone
                    number, email address and answer to the competition question to us at the following address:{' '}
                  </p>

                  <p>2 & 3 Westfield House Millfield Lane, Nether Poppleton, York, England, YO26 6GA </p>

                  <p>
                    Entries must be submitted via first or second class post. Bulk entries are not accepted and if
                    received will count as one single entry. If you wish to enter for free multiple times you may do so
                    up to any limit placed on the number of entries. Each free entry must be submitted separately.]{' '}
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header className="font-face-gb-semibold faq-bg-color rounded-4 m-2">
                  How will I know if I have won?
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                  We will notify the winner via telephone or email within 7 days of the closing date of the competition. If you change any of your contact details prior to the closing date, you must inform us. We will try to contact you using the information you have supplied us with. If we cannot reach you within 28 days of the closing date, we reserve the right to choose another winner or donate back to a charity or good cause and you will lose your right to claim the prize. 
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header className="font-face-gb-semibold faq-bg-color rounded-4 m-2">
                  How long is the competition open for?
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                  The opening and closing date of the competitions are stated on the website. If we have to change either of these dates for any reason, we will update the website accordingly. We will only change the dates if we have to for reasons outside of our control.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header className="font-face-gb-semibold faq-bg-color rounded-4 m-2">
                  Can anyone enter the competition?
                </Accordion.Header>
                <Accordion.Body>
                  <p>The competition is open to residents of the United Kingdom only who are 18 years or older. </p>
                  <p>
                    We do not except entries from anyone outside of these areas as the laws for running competitions
                    vary. This competition has been organised to comply with the laws of England, and Wales.{' '}
                  </p>
                  <p>Also, you cannot enter this competition if you are a relative of any of our suppliers. </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header className="font-face-gb-semibold faq-bg-color rounded-4 m-2">
                  What are the prizes?
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    The prizes are described fully on the website. You can find out more details by clicking here{' '}
                    <a href="https://playfundwin.com/prize-draw-terms-conditions">
                      https://playfundwin.com/prize-draw-terms-conditions
                    </a>
                    .
                  </p>
                  <p>
                    We reserve the right to offer an alternative prize of an equal or higher value if the prize is
                    unavailable for any reason.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header className="font-face-gb-semibold faq-bg-color rounded-4 m-2">
                  Can I sell the prize if I don’t want it?
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    If you are the winner, the prize will be yours. You can do what ever you wish with it, including
                    selling it.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header className="font-face-gb-semibold faq-bg-color rounded-4 m-2">
                  How do you use my personal data?
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    We need to use your data to administer the competition and award prizes. We do not use your data for
                    any other purpose.
                  </p>
                  <p>
                    We do not share your data with any third parties unless this is necessary for administering the
                    competition.
                  </p>

                  <p>
                    Full details of how we use your data are included in our Privacy Policy which you can read{' '}
                    <a href="https://playfundwin.com/privacy-policy/">here</a>.
                  </p>

                  <p>
                    If you are the winner, we may have to share your details with the Advertising Standards Authority to
                    confirm that we have administered the competition and awarded the prizes fairly.
                  </p>

                  <p>
                    You have the right to opt out from us using your data at any time. However, if you do ask us to
                    remove your details from our database prior to the closing date, you will be withdrawing from the
                    competition. You will not be entitled to a refund of any entry fees you have paid.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header className="font-face-gb-semibold faq-bg-color rounded-4 m-2">
                  If I win, do I have to participate in promotional exercises?
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    No, this is not compulsory. However, with your permission, we would love to share your excitement on
                    our website and social media pages.
                  </p>
                  <p>
                    Even if you do not want to participate in any promotional exercises, we may have may have to provide
                    your details to the Advertising Standards Authority to prove we have administered the competition
                    and awarded the prize fairly.{' '}
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9">
                <Accordion.Header className="font-face-gb-semibold faq-bg-color rounded-4 m-2">
                  What happens if I get the question wrong?
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Whilst this may be disappointing, you have to remember that this is a competition and we have
                    deliberately made the question tough to comply with the law. (See more on this below{' '}
                    <a href="https://playfundwin.com/help-centre">https://playfundwin.com/help-centre</a>)
                  </p>

                  <p>
                    If you get the question wrong, you will not be entered into the draw so you will not have the chance
                    to win the prize. You will not be entitled to a refund of your entry fees. If you want to, you can
                    try again.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="10">
                <Accordion.Header className="font-face-gb-semibold faq-bg-color rounded-4 m-2">
                  Can I try again?
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    You can enter the competition as many times as you wish up to any limit we specify. Your entries may
                    be restricted if we reach the maximum number of entries.
                  </p>

                  <p>
                    Whilst this isn’t gambling, we still urge you to keep this fun and not spend more than you can
                    afford.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="11">
                <Accordion.Header className="font-face-gb-semibold faq-bg-color rounded-4 m-2">
                  How is the winner decided?
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Everyone who gets the answer to the question correct will be entered into a draw. The winner will
                    then be chosen at random from all the correct entries.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="12">
                <Accordion.Header className="font-face-gb-semibold faq-bg-color rounded-4 m-2">
                  What are my chances of winning?
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    The maximum number of entries is stated on each competition so your chances of winning will vary
                    from competition to competition. As an example, if entries are capped at a maximum of 3000, this
                    means that if you purchase 1 entry and get the answer correct, your chances of winning will be no
                    worse than 1 in 3,000. 
                  </p>

                  <p>
                    You can increase your chances of winning by purchasing more entries. For example, if you purchase 10
                    entries in the example above and you get the answer correct, your chances of winning will be no
                    worse than 1 in 300.  
                  </p>

                  <p>
                    We say “no worse than” because we expect a significant number of people to get the answer to the
                    question wrong. We cannot predict how many this will be but say 500 people got the answer wrong and
                    they each purchased 1 entry each. Your chances of winning with a single correct entry will now
                    improve to 1 in 2,500. 
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="13">
                <Accordion.Header className="font-face-gb-semibold faq-bg-color rounded-4 m-2">
                  Why is the question so hard?
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    This is not a lottery or a free prize draw. It is a prize competition and the law says that to be in
                    with a chance of winning, you must demonstrate your skill, knowledge or judgement.
                  </p>

                  <p>
                    The law says that the question should be sufficiently difficult that a significant number of people
                    either get the answer wrong or are put off entering. However, this means that the odds of winning
                    are actually increased for those who get the answer correct.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="14">
                <Accordion.Header className="font-face-gb-semibold faq-bg-color rounded-4 m-2">
                  I haven’t received an email confirming whether I am right or wrong.
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    If you haven’t received an email from us confirming your entry and whether you got the question
                    right or wrong, please check your spam folder. If it is not in there, please email us at{' '}
                    <a href="mailto:hello@playfundwin.com">hello@playfundwin.com</a>.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="15">
                <Accordion.Header className="font-face-gb-semibold faq-bg-color rounded-4 m-2">
                  Can I get a refund of my entry fee?
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    We do not offer refunds of entry fees if you get the answer to the question wrong, or if you are
                    disqualified from the competition for any reason.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="16">
                <Accordion.Header className="font-face-gb-semibold faq-bg-color rounded-4 m-2">
                  My question hasn’t been answered here
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    If you have any questions that have not been answered here, please email us at{' '}
                    <a href="mailto:hello@playfundwin.com">hello@playfundwin.com</a> and we will happily answer them for
                    you.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>

      <FooterCta />
      <SiteFooter />
    </>
  );
}

import React,{Component} from 'react'
import Slider from "react-slick";
import FulhamFlutterHost from "../assets/images/Fulham-Flutter-host.png";
import BeesHost from "../assets/images/Bees-host.png";
import SouthamptonHost from "../assets/images/Southampton-host.png";
import HullUnitedHost from "../assets/images/Hull-United-host.png";
import NewcastleTshirtHost from "../assets/images/Newcastle-tshirt-host.png";
import ChaddertonHost from "../assets/images/Chadderton-host.png";
import NewcastleHost from "../assets/images/Newcastle-host.png";
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function HostSliderSection() {
    var  settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
              }
            },
            {
              breakpoint: 700,
              settings: {
                slidesToShow: 3,
                initialSlide: 2,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
              }
            },
            {
                breakpoint: 320,
                settings: {
                  slidesToShow: 1,
                }
              }
            
          ]
        };  
      return (
        <div className='container w-100'>
        <div className='row'>
        <Slider {...settings}>
            
        <div className='p-3 col m-md-3 d-flex align-items-center'>
                <div>
                  <LazyLoadImage
                    width={'100%'}
                    src={FulhamFlutterHost}
                    alt='Fulham Flutter Host'
                  />
                {/* <img width={'100%'}  src={require('../assets/images/Fulham-Flutter-host.png')}/> */}
                </div>
            </div>

            
            <div className='p-3 col m-md-3 d-flex align-items-center'>
                <div>
                  <LazyLoadImage
                    width={'100%'}
                    src={BeesHost}
                    alt='Bees Host'
                  />
                {/* <img width={'100%'}  src={require('../assets/images/Bees-host.png')}/> */}
                </div>
            </div>
            
            
            <div className='p-3 col m-md-3 d-flex align-items-center'>
                <div>
                  <LazyLoadImage
                    width={'100%'}
                    src={SouthamptonHost}
                    alt='Southampton Host'
                  />
                {/* <img width={'100%'}  src={require('../assets/images/Southampton-host.png')}/> */}
                </div>
            </div>

            <div className='p-3 col m-md-3 d-flex align-items-center'>
                <div>
                  <LazyLoadImage
                    width={'100%'}
                    src={HullUnitedHost}
                    alt='Hull United Host'
                  />

{/* <img width={'100%'}  src={require('../assets/images/Hull-United-host.png')}/> */}
                </div>
            </div>

            <div className='p-3 col m-md-3 d-flex align-items-center'>
                <div>
                  <LazyLoadImage
                    width={'100%'}
                    src={NewcastleTshirtHost}
                    alt='Newcastle Tshirt Host'
                  />
                {/* <img width={'100%'}  src={require('../assets/images/Newcastle-tshirt-host.png')}/> */}
                </div>
            </div>

            <div className='p-3 col m-md-3 d-flex align-items-center'>
                <div>
                  <LazyLoadImage
                    width={'100%'}
                    src={ChaddertonHost}
                    alt='Chadderton Host'
                  />


                {/* <img width={'100%'}  src={require('../assets/images/Chadderton-host.png')}/> */}
                </div>
            </div>

            <div className='p-3 col m-md-3 d-flex align-items-center'>
                <div>
                  <LazyLoadImage
                    width={'100%'}
                    src={NewcastleHost}
                    alt='Newcastle Host'
                  />

                {/* <img width={'100%'}  src={require('../assets/images/Newcastle-host.png')}/> */}
                </div>
            </div>
            
        </Slider>
        </div>
        </div>
  )
}

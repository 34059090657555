import React from "react";

const SkeletonLoader = ({ width, height, borderRadius, bgColor, fgColor }) => {
  const style = {
    display: "inline-block",
    width: `${width}`,
    height: `${height}`,
    borderRadius: `${borderRadius}`,
    backgroundColor: `${bgColor}`,
    backgroundImage: `linear-gradient(
      to right,
      ${fgColor} 0%,
      ${bgColor} 50%,
      ${fgColor} 100%
    )`,
    backgroundSize: "200% auto",
    animation: "skeleton-loader 3s ease-in-out infinite",
  };

  return (
    <div style={style}>
      <style>
        {`
          @keyframes skeleton-loader {
            0% {
              background-position: -200%;
            }
            100% {
              background-position: 200%;
            }
          }
        `}
      </style>
    </div>
  );
};

export default SkeletonLoader;

import React, { useEffect,useState } from 'react'
import { Link } from "react-router-dom";
import Siteheader from '../components/header';
import SiteFooter from '../components/footer';
import { UseEffect } from 'react';
import Innerheader from '../components/inner-header';
import { BlogData } from '../Data/Blog-Data';
import FooterCta from '../components/footer-cta';
import { BlogDataSession } from '../components/Cart';
import { Helmet } from 'react-helmet';

export default function PageNotFound() {


    return (
    
        <>

<Helmet>‍
      <title>404 | Page Not Found</title>‍
      <meta name="description" content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities." />
    </Helmet>
    <Siteheader/>

        <section className="w-100 bg-f2  p-5 position-relative notfound-page">
            <div className="container h-100 p-5 notfound-page">
            <div className="d-flex align-items-center justify-content-center h-100 ">
                <div className="d-flex flex-column w-75 row notfound">
                <div className='align-items-center justify-content-center text-center'>
                    <img src={require('../assets/images/404PFW.png')} style={{width : "100%"}}/>
                    <h1 className='display-2 mb-5 font-face-sh-bold'>Ohh... Shoot, this isn't the right place</h1>
                    <h5 className='color-grey'>This page you have navigated doesn't exist or has been moved. Our apologies</h5>
                    <div className='pt-0'>
                    <Link to={'/'} className="btn btn-link btn bg-branding1 text-decoration-none shadow-lg text-white border-0 notfound-button fs-4 w-25 m-3 rounded-pill py-3 mt-5 lh-1">Home</Link>
                    <Link to={'/find-a-draw'} className="btn btn-link btn bg-branding1 text-decoration-none shadow-lg text-white border-0 notfound-button fs-4  w-25 m-3 rounded-pill py-3 mt-5 lh-1">View All Draws</Link>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>

      {/* <FooterCta/> */}
  <SiteFooter/>
      </>
  )
}
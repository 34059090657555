import React from 'react'
import { Link,useParams, useLocation } from "react-router-dom";

export default function Draw(props) {
  const locationState = useLocation();
  console.log(locationState)
  return (
    <>
    <h1>Draw Page {locationState.name}</h1>
    {/* Dont Delete these Comments it will be used for future changes */}
    {/* <Link to={{pathname:"/",state: { name: "hello" }}} >Home</Link> */}
    {/* <Link to={"/draw"} state={{ name: 'DrawName' }}>Draw</Link> */}
    </>
  )
}


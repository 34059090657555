import React from 'react'
import { Link } from "react-router-dom";
import Siteheader from '../components/header';
import SiteFooter from '../components/footer';
import { UseEffect } from 'react';
import FooterCta from '../components/footer-cta';
import Innerheader from '../components/inner-header';
import { Helmet } from 'react-helmet';

export default function PrivacyPolicy(props) {
  
  return (
    <>
    <Helmet>‍
      <title>Privacy Policy | Play Fund Win</title>‍
      <meta name="description" content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities." />
    </Helmet>
    <Siteheader/>
    <Innerheader title="Privacy Policy"/>

  <div className='container pt-5 pb-5 my-3 px-lg-5 rounded-4 w-92' style={{backgroundColor: '#f2f2f2'}}>
    <div className="row px-lg-5 privacy-data">
       <div className="col-md-12 align-middle text-left px-lg-5 mt-lg-0 mt-3">
			<p><strong>Introduction</strong></p>
            {/* <ul className='ps-3 dot-style sub-title'>
            <li>Important information and who we are</li>
            </ul> */}
            <p>Welcome to Play Fund Win/PFW Holdings Ltd's privacy policy.</p>
            <p>Play Fund Win/PFW Holdings Ltd respects your privacy and is committed to protecting your personal data. This privacy policy will inform you as to how we process and look after your personal data when you visit our website (regardless of where you visit it from).</p>
            <p>It also tells you about your privacy rights and how the law protects you.</p>
            <p>You can download a pdf version of the policy here: <a href={require('../assets/Documents/Privacy-policy-template.pdf')} download>https://playfundwin.com/privacy-policy</a>.</p>
            <p>Please also use the Glossary to understand the meaning of some of the terms used in this privacy policy.</p>
            <ul className='ps-3 number-style'>
            <li>1. Important information and who we are</li>
            </ul>
            <ul className='ps-3 dot-style sub-title'>
            <li>Purpose of this privacy policy</li>
            </ul>
            <p>This privacy policy aims to give you information on how Play Fund Win/PFW Holdings Ltd collects and processes your personal data through your use of this website, including any data you may provide through this website when you sign up to our newsletter, purchase any products or services or take part in a prize draw or competition.</p>
            <p>This website is not intended for children and we do not knowingly collect data relating to children.</p>
            <p>It is important that you read this privacy policy together with any other privacy policy or fair processing policy we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This privacy policy supplements other notices and privacy policies and is not intended to override them.</p>
            <ul className='ps-3 dot-style sub-title'>
            <li>Controller</li>
            </ul>
            <p>Play Fund Win/PFW Holdings Ltd is the controller and responsible for your personal data (collectively referred to as Play Fund Win/PFW Holdings Ltd, "we", "us" or "our" in this privacy policy). </p>
            <p>We have appointed a data privacy manager who is responsible for overseeing questions in relation to this privacy policy. If you have any questions about this privacy policy, including any requests to exercise your legal rights, please contact the data privacy manager using the details set out below.  </p>
            <ul className='ps-3 dot-style sub-title'>
            <li>Contact details</li>
            </ul>
            <p>If you have any questions about this privacy policy or our privacy practices, please contact our data privacy manager in the following ways: </p>
            <p>Full name of legal entity: Play Fund Win/PFW Holdings Ltd </p>
            <p>Name of data privacy manager: Simon Rutter </p>
            <p>Email address: <a href="mailto:simon.rutter@playfundwin.com">simon.rutter@playfundwin.com</a> </p>
            <p>Postal address: 2 & 3 Westfield House Millfield Lane, Nether Poppleton, York, England, YO26 6GA </p>
            {/* <p>Telephone number: <a href="tel:07535270229">07535270229</a>  </p> */}
            <p>You have the right to make a complaint at any time to the Information Commissioner's Office (ICO), the UK supervisory authority for data protection issues (www.ico.org.uk). We would, however, appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance.  </p>
            <ul className='ps-3 dot-style sub-title'>
            <li>Changes to the privacy policy and your duty to inform us of changes </li>
            </ul>
            <p>We keep our privacy policy under regular review. This version was last updated on 15/09/2023.</p>  
            <p>It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us. </p>
            <ul className='ps-3 dot-style sub-title'>
            <li>Third-party links</li>
            </ul>
            <p>This website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy policy of every website you visit. </p>
            <ul className='ps-3 number-style'>
            <li>2. The data we collect about you</li>
            </ul>
            <p>Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data). </p>
            <p>We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows: </p>
            <ul className='ps-3 sub dot-style'>
              <li><b>Identity Data includes</b> first name, maiden name, last name, username or similar identifier, marital status, title, date of birth and gender. </li>
              <li><b>Contact Data includes</b> billing address, delivery address, email address and telephone numbers. </li>
              <li><b>Financial Data includes</b> bank account and payment card details. </li>
              <li><b>Transaction Data includes</b> details about payments to and from you and other details of products and services you have purchased from us. </li>
              <li><b>Technical Data includes</b> internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website. </li>
              <li><b>Profile Data includes</b> your username and password, purchases or orders made by you, your interests, preferences, feedback and survey responses.</li>
              <li><b>Usage Data includes</b> information about how you use our website, products and services.</li>
              <li><b>Marketing and Communications Data includes</b> your preferences in receiving marketing from us and our third parties and your communication preferences.</li>
            </ul>
            <p>We also collect, use and share <b>Aggregated Data</b> such as statistical or demographic data for any purpose. Aggregated Data could be derived from your personal data but is not considered personal data in law as this data will not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy policy. </p>
            <p>We do not collect any <b>Special Categories of Personal Data</b> about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health, and genetic and biometric data). Nor do we collect any information about criminal convictions and offences. </p>
            <p>We may collect the minimum amount of personal data to enable you to enter into any prize draw and competition and allow us to run the promotion. If you are a winner, it may be necessary to collect more detailed information from you in order to award your prize to you. You will be notified of this at the time we notify you if you have won. </p>
            <ul className='ps-3 dot-style sub-title'>
            <li>If you fail to provide personal data</li>
            </ul>
            <p>Where we need to collect personal data by law, or under the terms of a contract we have with you, and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with goods or services or to enter you into a competition). In this case, we may have to cancel a prod</p>
            <ul className='ps-3 number-style'>
            <li>3. How is your personal data collected?</li>
            </ul>
            <p>We use different methods to collect data from and about you including through: </p>
            <ul className='ps-3 sub dot-style'>
              <li><b>Direct interactions</b>. You may give us your Proof of Identification, contact details or payment details by filling in forms or by corresponding with us by post, phone, email or otherwise. This includes personal data you provide when you:
                  <ul className='ps-3 sub dot-style'>
                      <li>apply for our products or services;</li>
                      <li>create an account on our website;</li>
                      <li>subscribe to our service or publications;</li>
                      <li>request marketing to be sent to you;</li>
                      <li>enter a competition, promotion or survey; or</li>
                      <li>give us feedback or contact us.</li>
                  </ul>
              </li>
              <li><b>Automated technologies or interactions</b>. As you interact with our website, we will automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies and other similar technologies. We may also receive Technical Data about you if you visit other websites employing our cookies. Please see our cookie policy <a href="/cookie-policy-in-the-website-terms-of-use">https://playfundwin.com/cookie-policy-in-the-website-terms-of-use</a> for further details. <a href="/privacy-policy">https://playfundwin.com/privacy-policy</a></li>
              <li><b>Third parties or publicly available sources</b>. We will receive personal data about you from various third parties as set out below:
                  <ul className='ps-3 sub dot-style'>
                      <li>Technical Data from the following parties:
                          <ul className='ps-3 sub alphabet-style'>
                              <li>analytics providers such as Google based outside the UK;</li>
                              <li> advertising networks such as Facebook, Instagram, TikTok, YouTube, Google, and Bing based outside the UK; and</li>
                              <li>search information providers such as Google and Bing based outside the UK.</li>
                          </ul>
                      </li>
                      <li> Contact, Financial and Transaction Data from providers of technical, payment and delivery services such as Stripe based outside the UK.</li>
                      <li> Identity and Contact Data from data brokers or aggregators such as ASAP Discounts Ltd based inside the UK.</li>
                      <li> Identity and Contact Data from publicly available sources such as Companies House and the Electoral Register based inside the UK.</li>
                      {/* <li>[ANY OTHER WAYS YOU COLLECT PERSONAL DATA].</li> */}
                  </ul>
              </li>
          </ul>
          <ul className='ps-3 number-style'>
            <li>4. How we use your personal data </li>
          </ul>
          <p>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances: </p>
          <ul className='ps-3 sub dot-style sub-title'>
              <li>Where we need to perform the contract we are about to enter into or have entered into with you. (A legally binding contract is formed between us when you purchase goods or services from us or you enter into a competition.) </li>
              <li>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</li>
              <li>Where we need to comply with a legal obligation.</li>
          </ul>    
          <p>To find out more about the types of lawful basis that we will rely on to process your personal data, please see the Glossary below</p>
          <p>Generally, we do not rely on consent as a legal basis for processing your personal data although we will get your consent before sending third party direct marketing communications to you via email or text message. You have the right to withdraw consent to marketing at any time by contacting us.</p>
          <ul className='ps-3 dot-style sub-title'>
              <li>Purposes for which we will use your personal data </li>
          </ul>
          <p>We have set out below, in a table format, a description of all the ways we plan to use your personal data, and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are where appropriate. </p>
          <p>Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your data. Please contact us if you need details about the specific legal ground we are relying on to process your personal data where more than one ground has been set out in the table below. </p>
          <table className='table-privacy'>
              <thead>
                <tr>
                  <th>Purpose/Activity</th>
                  <th>Type of Data</th>
                  <th>Lawful Basis for Processing including Basis of Legitimate Interest</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>To register you as a new customer</td>
                  <td>(a) Identity<br />(b) Contact</td>
                  <td>Performance of a contract with you</td>
                </tr>
                <tr>
                  <td>To process and deliver your order including:<br />(a) Manage payments, fees and charges<br />(b) Collect and recover money owed to us</td>
                  <td>(a) Identity<br />(b) Contact<br />(c) Financial<br />(d) Transaction<br />(e) Marketing and Communications</td>
                  <td>(a) Performance of a contract with you<br />(b) Necessary for our legitimate interests (to recover debts due to us)</td>
                </tr>
                <tr>
                  <td>To manage our relationship with you which will include:<br />(a) Notifying you about changes to our terms or privacy policy<br />(b) Asking you to leave a review or take a survey</td>
                  <td>(a) Identity<br />(b) Contact<br />(c) Profile<br />(d) Marketing and Communications</td>
                  <td>(a) Performance of a contract with you<br />(b) Necessary to comply with a legal obligation<br />(c) Necessary for our legitimate interests (to keep our records updated and to study how customers use our products/services)</td>
                </tr>
                <tr>
                  <td>To enable you to partake in a prize draw, competition or complete a survey</td>
                  <td>(a) Identity<br />(b) Contact<br />(c) Profile<br />(d) Usage<br />(e) Marketing and Communications</td>
                  <td>(a) Performance of a contract with you<br />(b) Necessary for our legitimate interests (to study how customers use our products/services, to develop them and grow our business)</td>
                </tr>
                <tr>
                  <td>To administer and protect our business and this website (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)</td>
                  <td>(a) Identity<br />(b) Contact<br />(c) Technical</td>
                  <td>(a) Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise)<br />(b) Necessary to comply with a legal obligation</td>
                </tr>
                <tr>
                  <td>To deliver relevant website content and advertisements to you and measure or understand the effectiveness of the advertising we serve to you</td>
                  <td>(a) Identity<br />(b) Contact<br />(c) Profile<br />(d) Usage<br />(e) Marketing and Communications<br />(f) Technical</td>
                  <td>Necessary for our legitimate interests (to study how customers use our products/services, to develop them, to grow our business and to inform our marketing strategy)</td>
                </tr>
                <tr>
                  <td>To use data analytics to improve our website, products/services, marketing, customer relationships and experiences</td>
                  <td>(a) Technical<br />(b) Usage</td>
                  <td>Necessary for our legitimate interests (to define types of customers for our products and services, to keep our website updated and relevant, to develop our business and to inform our marketing strategy)</td>
                </tr>
                <tr>
                  <td>To make suggestions and recommendations to you about goods or services that may be of interest to you</td>
                  <td>(a) Identity<br />(b) Contact<br />(c) Technical<br />(d) Usage<br />(e) Profile<br />(f) Marketing and Communications</td>
                  <td>Necessary for our legitimate interests (to develop our products/services and grow our business)</td>
                </tr>
              </tbody>
            </table>

            <p>If you are the winner of the competition you will be invited to participate in publicity exercises relating to the competition. Your participation is not compulsory but would be greatly appreciated by us. If you do not wish to participate your participation in the competition or chances of winning will not be affected in any way.</p>

            <p>However, if you do not wish to participate in any publicity and decline to be named as the winner, we may still have to provide your details to the Advertising Standards Authority (ASA) as proof that the competition has been properly administered and the prize awarded. This is a legal requirement we must comply with. If you require detail of how the ASA will use and process your personal data, please let us know.</p>

            <ul className='ps-3 dot-style sub-title'>
              <li>Marketing</li>
            </ul>  

            <p>We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising.</p>

            <ul className='ps-3 dot-style sub-title'>
              <li>Promotional offers from us</li>
            </ul> 

            <p>We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services and offers may be relevant for you (we call this marketing).</p>

            <p>You will receive marketing communications from us if you have requested information from us or purchased goods or services from us, entered a competition or prize draw and you have not opted out of receiving that marketing.</p>

            <ul className='ps-3 dot-style sub-title'>
              <li>Third-party marketing</li>
            </ul> 

            <p>We will get your express opt-in consent before we share your personal data with any third party for marketing purposes.</p>

            <ul className='ps-3 dot-style sub-title'>
              <li>Opting out</li>
            </ul>

            <p>You can ask us or third parties to stop sending you marketing messages at any time by following the opt-out links on any marketing message sent to you or by contacting us at any time.</p>

            <p>Where you opt out of receiving these marketing messages, this will not apply to personal data provided to us as a result of a product/service purchase, warranty registration, product/service experience or other transactions.</p>

            <ul className='ps-3 dot-style sub-title'>
              <li>Cookies</li>
            </ul>

            <p>You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly. For more information about the cookies we use, please see <a href="/cookie-policy-in-the-website-terms-of-use">https://playfundwin.com/cookie-policy-in-the-website-terms-of-use</a>.</p>

            <ul className='ps-3 dot-style sub-title'>
              <li>Change of purpose</li>
            </ul>

            <p>We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us.</p>

            <p>If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.</p>

            <p>Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.</p>

            <ul className='ps-3 number-style'>
              <li>5. Disclosures of your personal data </li>
            </ul>

            <p>We may share your personal data with the parties set out below for the purposes set out in the table “Purposes for which we will use your personal data” above.</p>

              <ul className='ps-3 sub dot-style'>
                  <li>External Third Parties as set out in the Glossary.</li>
                  <li>Specific third parties listed in the table “Purposes for which we will use your personal data” above.</li>
                  <li>Third parties to whom we may choose to sell, transfer or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy policy.</li>
              </ul>

              <p>We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.</p>


            <ul className='ps-3 number-style'>
              <li>6. International transfers</li>
            </ul>

            <p>Many of our external third parties are based outside the UK so their processing of your personal data will involve a transfer of data outside the UK.</p>

            <p>Whenever we transfer your personal data out of the UK, we ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented:</p>

            <ul>
                <li>We will only transfer your personal data to countries that have been deemed to provide an adequate level of protection for personal data.</li>
                <li>Where we use certain service providers, we may use specific contracts approved for use in the UK which give personal data the same protection it has in the UK.</li>
            </ul>

            <p>Please contact us if you want further information on the specific mechanism used by us when transferring your personal data out of the UK.</p>


            <ul className='ps-3 number-style'>
              <li>7. Data security</li>
            </ul>

            <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.</p>

            <p>We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so. </p>

            <ul className='ps-3 number-style'>
              <li>8. Data retention</li>
            </ul>

            <ul className='ps-3 dot-style sub-title'>
              <li>How long will you use my personal data for? </li>
            </ul>

            <p>We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.</p>

            <p>To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements.</p>

            <p>Details of retention periods for different aspects of your personal data are available in our retention policy which you can request from us.</p>

            <p>In some circumstances you can ask us to delete your data: see “Your legal rights” below for further information.</p>

            <p>In some circumstances we will anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.</p>


            <ul className='ps-3 number-style'>
              <li>9. Your legal rights</li>
            </ul>

            <p>Under certain circumstances, you have rights under data protection laws in relation to your personal data.</p>

            <p>If you wish to exercise any of these rights, please contact us.</p>

            <p><strong>No fee usually required</strong></p>

            <p>You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply with your request in these circumstances.</p>

            <p><strong>What we may need from you</strong></p>

            <p>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</p>

            <p><strong>Time limit to respond</strong></p>

            <p>We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>


            <ul className='ps-3 number-style'>
              <li>10. Glossary</li>
            </ul>

            <p><strong>LAWFUL BASIS</strong></p>

            <p><strong>Legitimate Interest</strong> means the interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us.</p>

            <p><strong>Performance of Contract</strong> means processing your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.</p>

            <p><strong>Comply with a legal obligation</strong> means processing your personal data where it is necessary for compliance with a legal obligation that we are subject to.</p>

            <p><strong>THIRD PARTIES</strong></p>

            <p><strong>External Third Parties</strong></p>

            <ul>
                <li>Service providers acting as processors based in the United Kingdom who provide IT and system administration services.</li>
                <li>Professional advisers acting as processors or joint controllers including lawyers, bankers, auditors and insurers based in the United Kingdom who provide consultancy, banking, legal, insurance and accounting services.</li>
                <li>HM Revenue & Customs, regulators and other authorities such as the Advertising Standards Authority acting as processors or joint controllers based in the United Kingdom who require reporting of processing activities in certain circumstances.</li>
                {/* <li>[DETAILS OF ANY OTHER THIRD PARTIES, FOR EXAMPLE, MARKET RESEARCHERS, FRAUD PREVENTION AGENCIES, PRICE COMPARISON SITES AND SO ON.]</li> */}
            </ul>

            <p><strong>YOUR LEGAL RIGHTS</strong></p>

            <p>You have the right to:</p>

            <ul>
                <li><b>Request access to your personal data</b> (commonly known as a "data subject access request"). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.</li>
                <li>Request correction of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.</li>
                <li><b>Request erasure of your personal data.</b> This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.</li>
                <li><b>Object to processing of your personal data where we are relying on a legitimate interest</b> (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.</li>
                <li><b>Request restriction of processing of your personal data.</b> This enables you to ask us to suspend the processing of your personal data in the following scenarios:
                    <ul className='ps-3 sub alphabet-style'>
                        <li>If you want us to establish the data's accuracy.</li>
                        <li>Where our use of the data is unlawful but you do not want us to erase it.</li>
                        <li>Where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims.</li>
                        <li>You have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</li>
                    </ul>
                </li>
                <li><b>Request the transfer of your personal data to you or to a third party</b>. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you. </li>
                <li><b>Withdraw consent at any time where we are relying on consent to process your personal data</b>. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent. </li>
                </ul>










                      











           
       </div>
    </div>
  </div>

  <FooterCta/>
  <SiteFooter/>
    </>
  )
}

import React from 'react'
import { Link } from "react-router-dom";
import Siteheader from '../components/header';
import HomeSlider from '../components/homeSlider';
import PostSlider from '../components/postslider';
import SiteFooter from '../components/footer';
import HeroSlider from '../components/heroSlider';
import HostSlider from '../components/hostSlider';
import FooterCta from '../components/footer-cta';
import { useEffect } from 'react';
import axios from 'axios';

export default function HostThankYou(props) {

  useEffect(()=>{
axios.get(`${process.env.REACT_APP_API_URL}/sites/9be05da5-dfe9-46b9-9648-8ea669286fa6/draws`,'')
  },[])


  return (
    <>
    
  <Siteheader/>
  
<div>
<div className="inner-header container py-5 bg-image-np" style={{backgroundImage: `url(${require(`../assets/images/inner-header-bg.png`)})` , borderRadius: 10}}>
   <div className='container py-5'>
    <div className="row align-items-center py-4" >
      <div className="col-xl-12 col-lg-12 col-md-12 col-12 align-middle text-center m-auto">
        <h1 className="text-white display-3 font-face-gb-semibold">Thank you for contacting us!</h1>
       </div>
    </div>
    </div>
  </div>
{/* <div className='container pt-5 pb-5 my-3 px-lg-5 rounded-4 w-92' style={{backgroundColor: '#f2f2f2'}}>
    <div className="row px-lg-5">
      <div className="col-md-5 align-middle text-center about-left-img position-relative">
        <img width="100%" src={require('../assets/images/Team-Feb-22.png')} />
       </div>
       <div className="col-md-12 align-middle text-left px-lg-5 mt-lg-0 mt-3">
        {/* <h2 className="text-white font-weight-bold display-6 mb-3 "><strong className='branding1 fw-bolder'>Thank you!</strong></h2> */}
        {/* <p className="mb-3 ">We appreciate your interest in <a href="https://playfundwin.com/">PlayFundWin.com</a>.</p><p>Our team has received your message and we will do our best to get back to you as soon as possible.</p>
        <p className="mb-3 ">In the meantime, feel free to explore our website and learn more about what we have to offer.</p>           
        <p className="mb-3 ">Thank you again for choosing <a href="https://playfundwin.com/">PlayFundWin.com</a>. We look forward to serving you.</p>
        <div className="col-md-12 align-middle text-center px-lg-5 mt-lg-0 mt-3">
        <a className="border-0 rounded-pill bg-branding1 text-white btn pfw-button-white py-2 px-5 font-face-gb-semibold" style={{lineHeight : 2}} href="/">Home</a></div>
       </div>
    </div> 
  </div> */}

  <div className='container pt-5 pb-5 my-3 px-lg-5 rounded-4 w-92 thankyou-data' style={{backgroundColor: '#f2f2f2'}}>
    <div className="row px-lg-5 align-items-center">
      <div className="col-md-5 align-middle text-center about-left-img position-relative">
        <img width="100%" src={require('../assets/images/Cricket.jpeg')} />
       </div>
       <div className="col-md-7 align-middle text-left px-lg-5 mt-lg-0 mt-3">
        {/* <h2 className="text-white font-weight-bold display-6 mb-3"><strong className='branding1 fw-bolder'>Why work for us?</strong></h2> */}
        <p>We appreciate your interest in <a href="https://playfundwin.com/">Play Fund Win</a>.</p><p>Our team has received your message and we will do our best to get back to you as soon as possible.</p>
        <p>In the meantime, feel free to explore our website and learn more about what we have to offer.</p>
        <p>Thank you again for choosing <a href="https://playfundwin.com/">Play Fund Win</a>. We look forward to serving you.</p>
        <a className="border-0 rounded-pill bg-branding1 text-white btn pfw-button-white py-2 px-5 font-face-gb-semibold" style={{lineHeight : 2,marginRight : 20}} href="/">Home Page</a>
        <a className="border-0 rounded-pill bg-branding1 text-white btn pfw-button-white py-2 px-5 font-face-gb-semibold" style={{lineHeight : 2}} href="/find-a-draw">Find a Draw</a>
       </div>
    </div>
  </div>

</div>
<SiteFooter/>
    
    </>
  )
}

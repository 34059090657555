import React, { useState } from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import NUF from '../assets/images/NUF.png';
import Bees from '../assets/images/Bees.png';
import parkrun from '../assets/images/parkrun.png';
import mighty from '../assets/images/mighty.png';
import nhs from '../assets/images/nhs-header-new.png';
import petrus from '../assets/images/petrus-header-new.png';
import slider2 from '../assets/images/slider-2-g.png';
import slider3 from '../assets/images/slider-3-g.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "Block",color:"#fff" }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "Block",color:"#fff" }}
        onClick={onClick}
      />
    );
  }
  


export default function MonthlySlider() {

  const [oneShow, setOneShow] = useState(false);
  const [twoShow, setTwoShow] = useState(false);
  const [threeShow, setThreeShow] = useState(false);
  const [fourShow, setFourShow] = useState(false);
  const [fiveShow, setFiveShow] = useState(false);
  const [sixShow, setSixShow] = useState(false);


        var  settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            }
          },
          {
              breakpoint: 320,
              settings: {
                slidesToShow: 1,
              }
            }
          
        ]
        };  
      return (
        <div>
           <div className='row'>
                    <div className="container p-0 w-75 host-slider position-relative font-face-gb">
          <Slider {...settings}>

            <div>
              <div className="slider-sec text-start shadow-md rounded-4 mx-3 mb-5">
                <LazyLoadImage
                className='logoban w-100'
                src={NUF}
                alt='NUF'
                />
                  {/* <img className="logoban w-100 " src={require('../assets/images/NUF.png')} ></img> */}
                  
              </div>
            </div>

            <div>
              <div className="slider-sec text-start shadow-md rounded-4 mx-3 mb-5">
                  <LazyLoadImage
                className='logoban w-100'
                src={Bees}
                alt='Bees'
                />
                  {/* <img className="logoban w-100 " src={require('../assets/images/Bees.png')} ></img> */}
                  
              </div>
            </div>

            <div>
              <div className="slider-sec text-start shadow-md rounded-4 mx-3 mb-5">
                  <LazyLoadImage
                className='logoban w-100'
                  src={parkrun}
                  alt='parkrun'
                  />

                  {/* <img className="logoban w-100 " src={require('../assets/images/parkrun.png')} ></img> */}
                  
              </div>
            </div>

            <div>
              <div className="slider-sec text-start shadow-md rounded-4 mx-3 mb-5">
                  <LazyLoadImage
                className='logoban w-100'
                  src={mighty}
                  alt='mighty'
                  />

                  {/* <img className="logoban w-100 " src={require('../assets/images/mighty.png')} ></img> */}
                  
              </div>
            </div>

            <div>
              <div className="slider-sec text-start shadow-md rounded-4 mx-3 mb-5">
                  <LazyLoadImage
                className='logoban w-100'
                  src={nhs}
                  alt='nhs'
                  />

                  {/* <img className="logoban w-100 " src={require('../assets/images/nhs-header-new.png')} ></img> */}
                  
              </div>
            </div>

            <div>
              <div className="slider-sec text-start shadow-md rounded-4 mx-3 mb-5">
                 <LazyLoadImage
                className='logoban w-100'
                  src={petrus}
                  alt='petrus'
                  />
                  {/* <img className="logoban w-100 " src={require('../assets/images/petrus-header-new.png')} ></img> */}
                  
              </div>
            </div>

            {/* <div>
              <div className="slider-sec text-start shadow-md rounded-4 mx-3 mb-5">
                  <img className="logoban w-100 " src={require('../assets/images/slider-1-g.png')} ></img>
                  
              </div>
            </div> */}

            <div>
              <div className="slider-sec text-startshadow-md rounded-4 mx-3 mb-5">
                  <LazyLoadImage
                className='logoban w-100'
                src={slider2}
                alt='slider2'
                />
                  {/* <img className="logoban w-100 " src={require('../assets/images/slider-2-g.png')} ></img> */}
                  
              </div>
            </div>

            <div>
              <div className="slider-sec text-start shadow-md rounded-4 mx-3 mb-5">
                <LazyLoadImage
                className='logoban w-100'
                src={slider3}
                alt='slider3'
                />
                  {/* <img className="logoban w-100 " src={require('../assets/images/slider-3-g.png')} ></img> */}
                  
              </div>
            </div>


            
          </Slider>
        </div>
                    </div>
        </div>
      );
    }
  
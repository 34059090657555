import React, { useState,useEffect } from 'react'
import SiteFooter from '../components/footer'
import Siteheader from '../components/header'
import { Link, useNavigate } from 'react-router-dom'
import { toast,ToastContainer } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage'
import axios from 'axios'
import apiClient from '../api.config';
import { Helmet } from 'react-helmet';

export default function Register() {  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  let userDetail = secureLocalStorage.getItem("LogObject");
  const navigate = useNavigate();
  let APIURL = process.env.REACT_APP_API_URL;
 
  useEffect(()=>{
    console.log("userDetail",userDetail)
    if(userDetail && userDetail.loggedin){
      navigate('/dashboard');
    }
  },[])

  


 
  async function ValidateUser() {
    if (email.trim().length === 0 && password.trim().length === 0 ) {
      toast.error(`Please Enter Your valid Email`);
    } else if (!/^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9-]+(?:\.[a-z0-9-]+)+(?:\.[a-z0-9-]+)*$/.test(email)) {
      toast.error(`Please Enter Your valid Email Address`);
    } else { 
      const response = await toast.promise(apiClient.post(
        `${APIURL}/auth/request`,
        {
            userEmail: email,
            "siteID" : "2cf8d2b9-6907-48e5-a8ce-bdbba4771750"
        },
        {
            withCredentials: true,
            credentials: "same-origin"
        }
    ).then(res=>{
      setTimeout(function(){
        navigate('/');
      },5000);
    }),
        {
            pending: 'Please wait...',
            success: 'Thank you! If we have record of a user with your email address, you will receive an email with a link to reset your password.',
            error: 'Something went Wrong'
        }
    );

    }

  }

  return (
    <div>
      <Helmet>‍
      <title>Reset Password | Play Fund Win</title>‍
      <meta name="description" content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities." />
    </Helmet>
      <ToastContainer/>
    {userDetail===undefined || userDetail===null ? 
    <>
      <Siteheader />
      <section className="h-100 w-100 bg-f2 container px-lg-5 py-5 position-relative bg-before-after">
        <div className="container h-100 p-lg-5 ">
          <div className="d-flex align-items-center justify-content-center h-100 p-lg-5 py-5">
            <div className="d-flex flex-column p-xl-5 col-lg-6 col-md-6 col-12">
              
              <label className='mt-4' htmlFor="username ">Email</label>
              <input type="text" onChange={(event) => setEmail(event.target.value)} name="email" placeholder="Email" className='rounded-pill align-self-center w-100 px-3 p-3 bg-white border-0' />

            
              <button onClick={ValidateUser} className=" mt-4 btn pfw-button-red rounded-pill align-self-center w-100 px-3 p-2 fs-5 fw-bold" type="button" name="button">Reset Password</button>

              <div className='d-flex align-items-center justify-content-center pt-5 w-100'>
                <Link to={'/user-registration'} className='branding3  fw-bold'>Register</Link><span className='px-2 fw-bold'> | </span><Link to={'/login'} className='branding3  fw-bold'>Login</Link>
              </div>
            </div>
          </div>
        </div>
      </section>


      <SiteFooter />
    </>
    :
    navigate('/dashboard')
    }



    </div>

  )
}

import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from "react-router-dom";
import Siteheader from '../components/header';
import SiteFooter from '../components/footer';
import { UseEffect } from 'react';
import Innerheader from '../components/inner-header';
import { BlogData } from '../Data/Blog-Data';
import FooterCta from '../components/footer-cta';
import { BlogDataSession } from '../components/Cart';
import PostSlider from '../components/postslider';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { getPostByID } from '../Data/actionMethods';
import { useLocation } from 'react-router-dom';
import loading from '../assets/images/loading.gif';

export default function BlogDetail(props) {
    const params = useParams();
    // const SlugName = params.slug;
    const [blogData, setBlogData] = useState([]);
    const [blogDescContent, setBlogDescContent] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const postSlug = location.pathname.split('/')[2];


    const decodeHTMLEntities = (input) => {
        const doc = new DOMParser().parseFromString(input, 'text/html');
        return doc.documentElement.textContent.replace(/&#9;/g, ' ').replace(/\s+/g, ' ').trim();
    };

    const decodeHTMLContent = (content) => {
        if (!content) return content;

        return content
            .replace(/&nbsp;/g, ' ')
            .replace(/&#10;/g, '\n')
            .replace(/&#39;/g, "'")
            .replace(/❜/g, "'")
            .replace(/❞/g, "'")
            .replace(/&lsquo;/g, "'")
            .replace(/&rsquo;/g, "'")
            .replace(/&#8239;/g, ' ')
            .replace(/&lt;p&gt;/g, '')
            .replace(/&lt;\/p&gt;/g, '\n');
    };

    useEffect(() => {
        getPostByID(postSlug).then((res) => {
            if (res.data.content) {
                if (postSlug !== res.data.content.postSlug) {
                    //Update slug
                    navigate(`/blog/${res.data.content.postSlug}`);
                }
                console.log('Blog-Data', res.data.content);
                
                setBlogData(res.data.content ? res.data.content : []);
                setBlogDescContent(res.data.content.postMeta && JSON.parse(res.data.content.postMeta).content);
            }
        });
    }, [postSlug]);


    

    return (
        <>
            <Helmet>
                <title>{blogData?.postMeta ? `${decodeHTMLEntities(JSON.parse(blogData?.postMeta).seoTitle)} | Play Fund Win` : 'Play Fund Win'}</title>
                <meta name="description" content={blogData?.postMeta ? `${decodeHTMLEntities(JSON.parse(blogData?.postMeta)?.seoMeta)} | Play Fund Win` : 'Play Fund Win'} />
            </Helmet>
            <Siteheader />

            {blogData != '' && blogDescContent != '' ?
                <>
                    <div className="blog-header-section container font-face-gb px-5 pb-5 pt-5 bg-branding1" style={{ borderRadius: 10 }}>
                        <div className="row pt-5 ps-5" >
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12 align-middle ps-5">
                                <div className='publishdate color-yellow fw-bolder fs-5'>{moment(blogData?.postPublishedDate).format('Do MMMM YYYY')}</div>
                                <h1 className="post-title text-white fs-1 fw-normal mt-2 mb-5">{decodeHTMLEntities(blogData?.postName)}</h1>
                                <div className='short-desc text-light fs-5'>{blogData?.postMeta && decodeHTMLEntities(JSON.parse(blogData?.postMeta).introductionText)}</div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12 align-middle ps-5">
                                <div className='post-image background-image w-100 position-relative' style={{ backgroundImage: `url(${blogData?.postMeta && JSON.parse(blogData?.postMeta).featuredImage})`, borderRadius: 10, top: 120, minHeight : 320 }}></div>
                            </div>
                        </div>
                    </div>
                    <div className='blog-content-section container font-face-gb p-5'>
                        <div className='row pt-2'>
                            {/* <div className='col-lg-8 col-md-8 col-sm-12 mb-5 pt-5 pe-5 blog-content-left' dangerouslySetInnerHTML={{ __html: blogDescContent }}>
                    </div> */}
                            <div className='col-lg-8 col-md-8 col-sm-12 mb-5 pt-5 pe-5 blog-content-left'>
                                {(() => {
                                    let decodedContent = blogDescContent; // default to original content
                                    try {
                                        decodedContent = decodeURIComponent(blogDescContent);
                                    } catch (e) {
                                        console.warn("Failed to decode URI content:", e);
                                    }

                                    decodedContent = decodeHTMLContent(decodedContent); // decoding HTML entities

                                    if (typeof decodedContent === 'string' && /<p>.*<\/p>/i.test(decodedContent)) {
                                        return (
                                            <div
                                                className="blog-description text-left mx-auto"
                                                dangerouslySetInnerHTML={{ __html: decodedContent }}
                                            />
                                        );
                                    } else {
                                        return (
                                            <div className="blog-description text-left mx-auto">
                                                {decodedContent.split('\n').map((paragraph, index) => (
                                                    <p key={index}>
                                                        {paragraph.trim()}
                                                    </p>
                                                ))}
                                            </div>
                                        );
                                    }
                                })()}
                            </div>

                            {/* <div className='col-lg-8 col-md-8 col-sm-12 mb-5 pt-5 pe-5 blog-content-left'>
                                {(() => {
                                    let decodedContent = blogDescContent; // default to original content
                                    try {
                                        decodedContent = decodeURIComponent(blogDescContent);
                                    } catch (e) {
                                        console.warn("Failed to decode URI content:", e);
                                    }

                                    if (typeof decodedContent === 'string' && /<p>.*<\/p>/i.test(decodedContent)) {
                                        return (
                                            <div
                                                className="blog-description text-left mx-auto"
                                                dangerouslySetInnerHTML={{ __html: decodedContent }}
                                            />
                                        );
                                    } else {
                                        return (
                                            <div className="blog-description text-left mx-auto">
                                                {decodeHTMLContent(decodedContent).split('\n').map((paragraph, index) => (
                                                    <p key={index}>
                                                        {paragraph.trim()}
                                                    </p>
                                                ))}
                                            </div>
                                        );
                                    }
                                })()}
                            </div> */}

{
    console.log('blogData', blogData.taxonomy.filter(t => t.taxanomyType === 'blog-category'))
}

                            <div className='col-lg-4 col-md-4 col-sm-12 pt-5 blog-sidebar position-relative h-100'>
                                <div className='position-sticky top-0 h-100' style={{ top: '0' }}>
                                    {blogData.taxonomy.length > 0 && blogData.taxonomy.filter(t => t.taxanomyType === 'tag') ?
                                        <div className='blog-tags border-bottom pb-5'>
                                            <h4 className='fs-5 fw-normal text-muted'>Tags</h4>
                                            <div className='pt-3'>
                                                {blogData.taxonomy.filter(t => t.taxanomyType === 'tag').map((t, index, array) => (
                                                    <React.Fragment key={t.taxanomySlug}>
                                                        <Link
                                                            to={{
                                                                pathname: `/tag/${t.taxanomySlug}`
                                                            }}
                                                            onClick={() => {
                                                                navigate(`/tag/${t.taxanomySlug}`);
                                                            }}
                                                            className='fw-bolder branding3 text-decoration-none green-hover me-3 mb-2 d-inline-block'
                                                            style={{ width: 'auto'}}
                                                        >
                                                            {t.taxanomyName}
                                                        </Link>
                                                        {/* {index !== array.length - 1 && <div style={{ width: '10px', height: '10px' }}></div>} */}
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                            {/* <div className='d-flex gap-3 pt-3'>
                                        <a className='fw-bolder branding3 text-decoration-none green-hover' href="/tag/charity/" rel="tag">charity</a>
                                        <a className='fw-bolder branding3 text-decoration-none green-hover' href="/tag/digital/" rel="tag">digital</a>
                                        <a className='fw-bolder branding3 text-decoration-none green-hover' href="/tag/events/" rel="tag">events</a>
                                        <a className='fw-bolder branding3 text-decoration-none green-hover' href="/tag/fundraising/" rel="tag">fundraising</a>
                                    </div> */}
                                        </div>
                                        : ''}
                                    {blogData.taxonomy.length > 0 && blogData.taxonomy.filter(t => t.taxanomyType === 'blog-category') && (blogData.taxonomy.filter(t => t.taxanomyType === 'blog-category')).length > 0 ?
                                        <div className='blog-categories border-bottom pt-5 pb-4'>
                                            <h4 className='fs-5 fw-normal text-muted'>Category</h4>
                                            <ul className='list-unstyled pt-3 d-flex'>
                                                {blogData.taxonomy.filter(t => t.taxanomyType === 'blog-category').map((t, index, array) => (
                                                    <React.Fragment key={t.taxanomySlug}>
                                                        <li className='mb-2'>
                                                            <Link
                                                                to={{
                                                                    pathname: `/category/${t.taxanomySlug}`
                                                                }}
                                                                onClick={() => {
                                                                    navigate(`/category/${t.taxanomySlug}`);
                                                                }}
                                                                // className='fw-bolder branding2 text-decoration-none red-hover'
                                                                className='text-decoration-none text-light pfw-button-green rounded-pill px-3 py-1'
                                                            >
                                                                {t.taxanomyName}
                                                            </Link>
                                                        </li>
                                                        {index !== array.length - 1 && <div style={{ width: '10px', height: '10px' }}></div>}
                                                    </React.Fragment>
                                                ))}
                                            </ul>
                                            {/* <ul className='list-unstyled pt-3'>
                                        <li className='mb-2'><a className='branding2 text-decoration-none red-hover' href="/category/our-thoughts-and-insights/">Our Thoughts and Insights</a></li>
                                        <li className='mb-2'><a className='branding2 text-decoration-none red-hover' href="/category/press-release/">Press Release</a></li>
                                        <li className='mb-2'><a className='branding2 text-decoration-none red-hover' href="/category/thoughts-insights/">Thoughts &amp; Insights</a></li>
                                    </ul> */}
                                        </div>
                                        : ''}
                                    <div className='blog-author pt-4 mt-2 d-flex align-items-center'>
                                        {/* <Link to={`/${BlogDataSession.data.authorLink}`} className='d-flex align-items-center text-decoration-none'> */}
                                        {/* {BlogDataSession.data.authorImage ?
                        <img className='rounded-pill post-author-image' style={{width: 150, height: 150,objectFit : 'cover', objectPosition: 'top'}} src={require(`../assets/images/blogs/${BlogDataSession.data.authorImage}`)} />
                        : */}
                                        {/* <img className='rounded-pill' src={require('../assets/images/avatar.jpg')} /> */}
                                        {blogData?.profileImage ?
                                        <Link to={`${blogData?.authorLink}`} className='d-flex align-items-center text-decoration-none'>
                                            <img className='rounded-pill' src={blogData?.profileImage} style={{ width: 96, height: 96, objectFit: 'cover', objectPosition: 'top' }} />
                                        </Link>
                                            :
                                            <img className='rounded-pill' src={require('../assets/images/avatar.jpg')} />
                                        }
                                        <Link to={`${blogData?.authorLink}`} className='d-flex align-items-center text-decoration-none'>
                                        <h3 className='branding3 fs-5 fw-normal font-face-gb-semibold ms-4 mb-0 ps-2'>{blogData?.userFirstName} {blogData?.userSurname}</h3>
                                        </Link>
                                        {/* </Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                <div className='text-center '>
                    <img className='w-50' src={loading} style={{ opacity: '0.7' }} alt="Loading..." />
                </div>
            }

            <div className='related-articles container bg-f2 font-face-gb py-5' style={{ borderRadius: 10 }}>
                <div className='related-title text-center pt-5'>
                    <h2 className='branding1 fs-1 fw-normal font-face-gb-semibold'>Related Articles</h2>
                    <p>See our thoughts on a variety of subjects below</p>
                </div>
                <div className='related-articles-slider mx-auto pb-5' style={{ width: '88%' }}>
                    <PostSlider />
                </div>
            </div>

            <SiteFooter />
        </>
    )
}

import React, { useEffect, useState } from 'react'
import SiteFooter from '../components/footer';
import Siteheader from '../components/header';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage';
import axios from "axios";
import apiClient from '../api.config';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast, ToastContainer } from 'react-toastify';
import PrizeImageLoader from '../components/imageLoaderPrizes';
import Slider from "react-slick";
import Breadcrumb from '../components/breadcrum';

export default function PrizesWon() {

    const navigate = useNavigate();
    const [isLoaded, setLoadComplete] = useState(false);
    const [PurchaseData, setPurchaseData] = useState([]);
    const userData = secureLocalStorage.getItem('LogObject');
    const siteId = process.env.REACT_APP_API_SITE_ID;
    const parser = new DOMParser();
    const [modalShow, setModalShow] = useState(false);
    const [modalShow2, setModalShow2] = useState(false);
    const [editmodalShow, setEditModalShow] = useState(false);
    const [editmodalShow2, setEditModalShow2] = useState(false);
    const [fullname, setFullName] = useState('');
    const [accountnumber, setAccountNumber] = useState('');
    const [emailUser, setEmailUser] = useState('');
    const [sortcode, setSortCode] = useState('');
    const [paypalemail, setPaypalEmail] = useState('');
    const [bankaccount, setBankAccount] = useState(true);
    const [prizeID, setPrizeID] = useState('');
    const [paypal, setPaypal] = useState(false);
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [postcode, setPostcode] = useState('');
    const [userSurname, setUserSurname] = useState('');
    const [userFirstName, setUserFirstName] = useState('');
    const [modalShow3, setModalShow3] = useState(false);
    const [modalShow4, setModalShow4] = useState(false);
    const [inc, setInc] = useState(0);
    useEffect(() => {
        console.log("UserData", userData);
        if (!userData) {
            navigate('/login');
        } else {
            let mergingArray = [];
            axios.get(`${process.env.REACT_APP_API_URL}/users/${userData.userID}/data`, {
                withCredentials: true,
                credentials: "same-origin"
            }).then(res => {
                console.log("UserData", res.data.content.contact[0]);

                const user = {}

                user.userFirstName = res.data.content?.userFirstName;
                user.userSurname = res.data.content?.userSurname;
                user.userEmail = res.data.content?.userEmail;

                setUserFirstName(user.userFirstName);
                setUserSurname(user.userSurname);
                setEmailUser(user.userEmail)

                if (res.data.content.prizes) {
                    mergingArray.push(res.data.content.prizes)
                }

                //Fetching the User Prize Claim details
                axios.get(`${process.env.REACT_APP_API_URL}/prizes/getClaim/${userData.userID}/`, {
                    withCredentials: true,
                    credentials: "same-origin"
                }).then(innerRes => {

                    console.log("Prize Data", innerRes.data.content);
                    if (innerRes.data.content.length) {
                        const detailMetaString = innerRes.data.content[0].detail_meta;

                        console.log("both value", innerRes.data.content, res.data.content.prizes);
                        res.data.content.prizes.map(el => {
                            let details = innerRes.data.content.find(item => item.prize_id == el.prizeID);
                            el.prizeClaim = details
                            return el;
                        })
                        console.log("after submission", res.data.content.prizes);

                        mergingArray.push(res.data.content.prizes)


                        // Remove the extra quotes from the string
                        const detailMetaWithoutQuotes = detailMetaString.replace(/"/g, '');

                        // Split the string by commas to get key-value pairs
                        const keyValuePairs = detailMetaWithoutQuotes.split(',');

                        // Create an empty object to store the parsed data
                        const detailMetaObject = {};

                        // Process each key-value pair
                        keyValuePairs.forEach((pair) => {
                            const [key, value] = pair.split(':');
                            detailMetaObject[key.trim()] = value.trim();
                        });
                        setLoadComplete(true);
                    }
                }
                ).then(e => {
                    console.log("mergingArray", mergingArray.flat(Infinity));
                    const uniqueArray = mergingArray.flat(Infinity).filter(
                        (item, index, self) =>
                            index ===
                            self.findIndex(
                                (t) =>
                                    t.drawID === item.drawID && t.prizeID === item.prizeID
                            )
                    );
                    setPurchaseData(uniqueArray.flat(Infinity).sort((a, b) => b.id - a.id));
                });
            }
            );
        }


    }, [inc])

    async function ClaimPrize() {
        console.log("prizeID:", prizeID, "UserId:", userData.userID, "bankaccount: ", bankaccount, "accountnumber:", accountnumber, "accountnumber:", accountnumber, "fullname:", fullname)
        if (!bankaccount && paypalemail === '') {
            toast.error(`Please Enter Your Proper paypal email`);
            return;
        } else if ((bankaccount) && fullname === '' && accountnumber === '' && sortcode === '') {
            toast.error(`Please Enter Bank Details`);
        } else if ((bankaccount) && fullname === '') {
            toast.error(`Please Enter Full Name`);
        } else if ((bankaccount) && accountnumber === '') {
            toast.error(`Please Enter Account Number`);
        } else if ((bankaccount) && sortcode === '') {
            toast.error(`Please Enter Sortcode`);
        } else {


            try {
                const response = await toast.promise(apiClient.post(
                    `${process.env.REACT_APP_API_URL}/prizes/claim`,
                    {
                        userID: userData.userID,
                        prize_id: prizeID,
                        PaymentMethod: bankaccount ? "bank" : "paypal",
                        full_name: fullname != '' ? fullname : "paypal",
                        Sortcode: sortcode != '' ? sortcode : "paypal",
                        account_no: accountnumber != '' ? accountnumber : "paypal",
                        userEmail: paypalemail != '' ? paypalemail : "bank",
                        detail_meta: { siteID: siteId },
                        datameta: { email: secureLocalStorage.getItem('LogObject').email },
                        pageType: "Cash prize"
                    },
                    {
                        withCredentials: true,
                        credentials: "same-origin"
                    }
                ),
                    {
                        pending: 'Please wait...',
                    }
                );
                console.log("response", response);
                if (response.data.status === true && response.data.error === null && response.data.content === null) {
                    setModalShow(false)
                    toast("Thank you for submitting, Your prize will be processed in the next 14 - 21 days", { type: "success" });
                } else if (response.data.status === true && response.data.content.message === "Already CLaimed") {
                    setModalShow(false)
                    toast("Prize already claimed!", { type: "info" });
                } else {
                    setModalShow(false)
                    toast("Something went wrong While ", { type: "error" });
                }
            } catch (error) {
                console.log(error);
                if (error?.response?.data?.error?.reason) {
                    toast.warn(error?.response?.data?.error?.reason);
                } else if (error.response.status === 403 && error.response.data?.content?.message && error.response.data?.content?.message !== "") {
                    toast.error(`${error.response.data?.content?.message}`);
                } else {
                    toast.error(`${error.message}`);
                }
            }
            setModalShow4(false)
            setModalShow3(false)
            setInc((prev) => prev + 1);
        }
    }

    async function ClaimPrize2() {
        console.log(`{siteID:${siteId},address1:${address1},address2:${address2},postcode:${postcode}}`, userSurname, userFirstName);
        if (address1 === null || address2 === null || postcode === null) {
            toast.error(`Please Enter Your full Address`);
            return;
        } else {

            try {
                const details_meta = {
                    siteID:siteId,
                    address1,
                    address2,
                    postcode,
                }

                const response = await toast.promise(apiClient.post(
                    `${process.env.REACT_APP_API_URL}/prizes/claim`,
                    {
                        userID: userData.userID,
                        prize_id: prizeID,
                        PaymentMethod: "prize",
                        full_name: userFirstName + " " + userSurname,
                        Sortcode: "-",
                        account_no: "-",
                        emails: emailUser,
                        userEmail: "-",
                        detail_meta:details_meta,
                        datameta: { email: secureLocalStorage.getItem('LogObject').email },
                        pageType: "Prize"
                    },
                    {
                        withCredentials: true,
                        credentials: "same-origin"
                    }
                ),
                    {
                        pending: 'Please wait...',
                    }
                );
                console.log("response", response);
                if (response.data.status === true && response.data.error === null && response.data.content === null) {
                    setModalShow2(false)
                    toast("Thank you for submitting, Your prize will be processed in the next 14 - 21 days", { type: "success" });
                } else if (response.data.status === true && response.data.content.message === "Already CLaimed") {
                    setModalShow2(false)
                    toast("Prize already claimed!", { type: "info" });
                } else {
                    setModalShow2(false)
                    toast("Something went wrong While ", { type: "error" });
                }
            } catch (error) {
                console.log(error);
                if (error?.response?.data?.error?.reason) {
                    toast.warn(error?.response?.data?.error?.reason);
                } else if (error.response.status === 403 && error.response.data?.content?.message && error.response.data?.content?.message !== "") {
                    toast.error(`${error.response.data?.content?.message}`);
                } else {
                    toast.error(`${error.message}`);
                }
            }
            setModalShow4(false)
            setModalShow3(false)
            setInc((prev) => prev + 1);
        }
    }


    function isValidEmail(email) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    }
    async function editClaimPrizeHandler() {
        console.log("We are in update");
        console.log("prizeID:", prizeID, "UserId:", userData.userID, "bankaccount: ", bankaccount, "accountnumber:", accountnumber, "accountnumber:", accountnumber, "fullname:", fullname)
        if (!bankaccount && paypalemail === '-') {
            toast.error(`Please Enter your paypal email`);
            return;
        }
        else if (paypal && !isValidEmail(paypalemail)) {
            toast.error(`Please Enter Valid PayPal Email`);
        } else if ((bankaccount) && (fullname === '-' || fullname === '') && (accountnumber === '-' || accountnumber === '') && (sortcode === '-' || sortcode === '')) {
            toast.error(`Please Enter Bank Details`);
        } else if ((bankaccount) && (fullname === '' || fullname === '-')) {
            toast.error(`Please Enter Full Name`);
        } else if ((bankaccount) && (accountnumber === '-' || accountnumber === '')) {
            toast.error(`Please Enter Account Number`);
        } else if ((bankaccount) && (sortcode === '-' || sortcode === '')) {
            toast.error(`Please Enter Sortcode`);
        } else if (isNaN(accountnumber) && accountnumber !== '-') {
            toast.error(`Please Enter Valid Account Number`);
        }
        else if (bankaccount && isNaN(sortcode)) {
            toast.error(`Please Enter Valid Sort Code`);
        }
        else {

            try {
                const response = await toast.promise(apiClient.put(
                    `${process.env.REACT_APP_API_URL}/prizes/updateClaimDetails/${userData.userID}/`,
                    {
                        userID: userData.userID,
                        prize_id: prizeID,
                        PaymentMethod: bankaccount ? "bank" : "paypal",
                        full_name: fullname != '' ? fullname : "paypal",
                        emails: emailUser,
                        Sortcode: sortcode != '' ? sortcode : "paypal",
                        account_no: accountnumber != '' ? accountnumber : "paypal",
                        userEmail: paypalemail != '' ? paypalemail : "bank",
                        detail_meta: { siteID: siteId },
                        pageType: "Edit Claim Cash prize"
                    },
                    {
                        withCredentials: true,
                        credentials: "same-origin"
                    }
                ),
                    {
                        pending: 'Please wait...',
                    }
                );
                console.log("response", response);
                if (response.data.status === true && response.data.error === null) {

                    // toast("Please Check your registered email inbox to confirm bank details change", { type: "success" });
                    toast("Claim Details Updated Successfully!", { type: "success" });
                    setEditModalShow(false)
                    setInc((prev) => prev + 1);

                }
                else {

                    toast("Something went wrong While ", { type: "error" });
                    setEditModalShow(false)
                    setInc((prev) => prev + 1);

                }
            } catch (error) {
                console.log(error);
                if (error?.response?.data?.error?.reason) {
                    toast.warn(error?.response?.data?.error?.reason);
                } else if (error.response.status === 403 && error.response.data?.content?.message && error.response.data?.content?.message !== "") {
                    toast.error(`${error.response.data?.content?.message}`);
                } else {
                    toast.error(`${error.message}`);
                }
            }
        }

    }

    async function editClaimPrizeHandler2() {
        console.log("We are in update 2");
        console.log("prizeID:", prizeID, "UserId:", userData.userID, "address1: ", address1, "address2:", address2, "postcode:", postcode, "fullname:", fullname)
        if (address1 === null || address2 === null || postcode === null) {
            toast.error(`Please Enter Your full Address`);
            return;
        }

        else {

            const details_meta = {
                siteID:siteId,
                address1,
                address2,
                postcode,
            }

            try {
                const response = await toast.promise(apiClient.put(
                    `${process.env.REACT_APP_API_URL}/prizes/updateClaimAddressDetails/${userData.userID}/`,
                    {
                        userID: userData.userID,
                        prize_id: prizeID,
                        PaymentMethod: "prize",
                        full_name: userFirstName + " " + userSurname,
                        Sortcode: "-",
                        account_no: "-",
                        emails: emailUser,
                        userEmail: "-",
                        siteID: siteId,
                        detail_meta:details_meta,
                        pageType: "Edit Claim Prize"
                    },
                    {
                        withCredentials: true,
                        credentials: "same-origin"
                    }
                ),
                    {
                        pending: 'Please wait...',
                    }
                );
                console.log("response", response);
                if (response.data.status === true && response.data.error === null) {

                    toast("Please Check your registered email inbox to confirm Address change", { type: "success" });
                    setEditModalShow2(false);
                    setInc((prev) => prev + 1);
                }
                else {

                    toast("Something went wrong While ", { type: "error" });
                    setEditModalShow2(false)
                    setInc((prev) => prev + 1);

                }

            } catch (error) {
                console.log(error);
                if (error?.response?.data?.error?.reason) {
                    toast.warn(error?.response?.data?.error?.reason);
                } else if (error.response.status === 403 && error.response.data?.content?.message && error.response.data?.content?.message !== "") {
                    toast.error(`${error.response.data?.content?.message}`);
                } else {
                    toast.error(`${error.message}`);
                }
            }
        }
    }

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className="custom-arrow next-arrow"
                style={{ ...style, display: "block" }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className="custom-arrow prev-arrow"
                style={{ ...style, display: "block" }}
                onClick={onClick}
            />
        );
    }

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 3000,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
        ],
    };

    // const breadcrumbs = [
    //     { label: 'Home', path: '/', isActive: false },
    //     { label: 'My Account', path: '/dashboard' , isActive: false },
    //     { label: 'Prizes Won', path: '/prizes-won' , isActive: true },
    //   ];

    return (
        <>
            <Helmet>‍
                <title>Prizes Won | Play Fund Win</title>‍
                <meta name="description" content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities." />
            </Helmet>
            <Siteheader />



            <section className='prizes-won-section container py-md-5 py-4' style={{ backgroundColor: '#f2f2f2', borderRadius: 10 }}>
                <div>
                    <Breadcrumb />
                    {/* Rest of your page content */}
                </div>
                <div className='prizes-won-row row mx-auto' style={{ width: '88%' }}>
                    <div className="font-face-gb pt-4">
                        <h1 className="branding1 display-3 text-center">Prizes <strong className='font-face-gb-semibold'>Won</strong></h1>
                    </div>
                    {PurchaseData.length === 0 ?
                        <div className='text-center mt-4'>
                            <p className='fs-4 branding3'>You don't have any prize won! <Link to={"/find-a-draw"} className='branding2 red-hover'>Enter A Draw</Link></p>
                        </div>
                        :
                        <>
                            <div className='prizes-sec row d-flex align-item-center justify-content-center'>
                                {PurchaseData.map((item, index) => {
                                    return (
                                        <div className='col-lg-4 col-md-4 col-sm-12 font-face-gb' key={item.userID}>
                                            <div className={`prize-sec mt-3 text-center mb-5 bg-white m-1`} style={{ borderRadius: 12 }}>
                                                <div className='px-4 py-4'>
                                                    <p className='text-center fs-14 m-0' style={{ color: '#302d51' }}><strong><b className='branding-1' dangerouslySetInnerHTML={{ __html: parser.parseFromString(`${item.prizeName}`, 'text/html').body.textContent }}></b></strong><br />
                                                    </p>
                                                    <hr className='bg-branding2 mx-auto my-3' style={{ width: 28, height: 3, opacity: 1 }} />
                                                    {
                                                        item.drawPrizeState === 1 ? (
                                                            item.prizeTypeID === 1 || item.prizeTypeID === 2 ? (
                                                                <Button
                                                                    onClick={() => {
                                                                        setModalShow4(true);
                                                                        setPrizeID(item.prizeID);
                                                                    }}
                                                                    className='py-3 px-5 border border-2 border-white pfw-button-red secbutton rounded-pill text-white text-decoration-none fs-6 lh-1 font-face-sh'
                                                                >
                                                                    Claim Prize
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    onClick={() => {
                                                                        setModalShow3(true);
                                                                        setPrizeID(item.prizeID);
                                                                    }}
                                                                    className='py-3 px-5 border border-2 border-white pfw-button-red secbutton rounded-pill text-white text-decoration-none fs-6 lh-1 font-face-sh'
                                                                >
                                                                    Claim Prize
                                                                </Button>
                                                            )
                                                        ) : item.drawPrizeState === 2 ? (
                                                            item.prizeTypeID === 1 || item.prizeTypeID === 2 ? (
                                                                <>
                                                                    <div>
                                                                        <div className='pfw-button-red text-white px-4 py-2 rounded-pill'>
                                                                            Prize Already Claimed
                                                                        </div>
                                                                        <br />
                                                                    </div>
                                                                    <Button
                                                                        onClick={() => {
                                                                            if (item.prizeClaim) {
                                                                                setAccountNumber(item.prizeClaim.account_no === 'paypal' ? '-' : item.prizeClaim.account_no);
                                                                                setSortCode(item.prizeClaim.Sortcode === 'paypal' ? '-' : item.prizeClaim.Sortcode);
                                                                                setFullName(item.prizeClaim.full_name === 'paypal' ? '-' : item.prizeClaim.full_name);
                                                                                setPaypalEmail(item.prizeClaim.email === 'bank' ? '-' : item.prizeClaim.email);
                                                                            }
                                                                            setModalShow(true);
                                                                            setPrizeID(item.prizeID);
                                                                        }}
                                                                        className='py-3 px-5 border border-2 border-white pfw-button-red secbutton rounded-pill text-white text-decoration-none fs-6 lh-1 font-face-sh'
                                                                    >
                                                                        Edit Bank Details
                                                                    </Button>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div>
                                                                        <div className='pfw-button-red text-white px-4 py-2 rounded-pill'>
                                                                            Prize Already Claimed
                                                                        </div>
                                                                        <br />
                                                                    </div>
                                                                    <Button
                                                                        onClick={() => {
                                                                            console.log(item);
                                                                            if (item.prizeClaim) {
                                                                                console.log("item Testttt if");
                                                                                let prizeClaimData = item.prizeClaim.detail_meta;
                                                                                if (typeof prizeClaimData === 'object') {
                                                                                    prizeClaimData = JSON.stringify(prizeClaimData);
                                                                                    console.log("prizeClaimData object", prizeClaimData);
                                                                                }
                                                                                console.log("test type of", typeof prizeClaimData);
                                                                                if (typeof prizeClaimData === 'string') {
                                                                                    let newVal = prizeClaimData.replaceAll("{", "").replaceAll("}", "").replaceAll(/"/g, "").replaceAll(/`/g, "").replaceAll(/'/g, "");
                                                                                    console.log("newVal", newVal);
                                                                                    let oldRow = newVal.split(",");
                                                                                    for (let i = 0; i < oldRow.length; i++) {
                                                                                        let checkAddress = oldRow[i].split(":");
                                                                                        console.log("checkAddress", checkAddress);
                                                                                        if (checkAddress[0] === "address1") {
                                                                                            setAddress1(checkAddress[1])
                                                                                        }
                                                                                        if (checkAddress[0] === "postcode") {
                                                                                            setPostcode(checkAddress[1])
                                                                                        }
                                                                                        if (checkAddress[0] === "address2") {
                                                                                            setAddress2(checkAddress[1])
                                                                                        }
                                                                                    }
                                                                                } else {
                                                                                    console.log("item Testttt else");
                                                                                    setAddress1(prizeClaimData.address1);
                                                                                    setAddress2(prizeClaimData.address2);
                                                                                    setPostcode(prizeClaimData.postcode);
                                                                                }
                                                                            }
                                                                            setModalShow2(true);
                                                                            setPrizeID(item.prizeID);
                                                                        }}
                                                                        className='py-3 px-5 border border-2 border-white pfw-button-red secbutton rounded-pill text-white text-decoration-none fs-6 lh-1 font-face-sh'
                                                                    >
                                                                        Edit Address Details
                                                                    </Button>
                                                                </>
                                                            )
                                                        ) : item.drawPrizeState === 3 ? (
                                                            <span className='pfw-button-red text-white px-4 py-2 rounded-pill'>
                                                                Prize Already Dispatched
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                    }
                </div>
            </section>
            <Modal
                show={modalShow4} onHide={() => setModalShow4(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='claim-prize-modal'
            >
                <Modal.Header closeButton className='d-flex flex-column justify-content-start'>
                    <Modal.Title id="contained-modal-title-vcenter" className='w-100 text-left'>
                        Please Confirm the  bank details
                    </Modal.Title>

                </Modal.Header>
                <Modal.Body className='pt-0'>
                    <div className='claim-methods d-flex gap-2 mb-3'>
                        <Button onClick={() => { setBankAccount(true); setPaypal(false) }} className={
                            bankaccount ? 'w-50 border-0 rounded-pill btn py-3 px-3 my-4 w-100 font-face-gb-semibold btn-active-green'
                                : 'w-50 bg-transparent rounded-pill branding2 normal-btn border-2 py-3 px-3 my-4 w-100 font-face-gb-semibold'}>
                            Bank Account
                        </Button>
                        <Button onClick={() => { setPaypal(true); setBankAccount(false) }} className={
                            paypal ? 'w-50 border-0 rounded-pill btn py-3 px-3 my-4 w-100 font-face-gb-semibold btn-active-green'
                                : 'w-50 bg-transparent rounded-pill btn branding2 border-2 py-3 px-3 my-4 w-100 font-face-gb-semibold normal-btn'}>
                            PayPal
                        </Button>
                    </div>

                    <div className={bankaccount ? 'd-block' : 'd-none'}>
                        <input type="text" id="fullname" value={fullname} onChange={(event) => setFullName(event.target.value)} placeholder="Full Name" className="rounded-pill align-self-center w-100 px-4 p-3 bg-white border mb-3" />
                        <input type="text" id="accountnumber" value={accountnumber} onChange={(event) => setAccountNumber(event.target.value)} placeholder="Account Number" className="rounded-pill align-self-center w-100 px-4 p-3 bg-white border mb-3" />
                        <input type="text" id="sortcode" value={sortcode} onChange={(event) => setSortCode(event.target.value)} placeholder="Sort Code" className="rounded-pill align-self-center w-100 px-4 p-3 bg-white border" />

                        <button type="submit" id="btn-signup" onClick={ClaimPrize} className="border-0 rounded-pill btn pfw-button-red secbutton py-3 px-3 my-4 w-100 font-face-gb-semibold">Submit</button>
                    </div>

                    <div className={paypal ? 'd-block' : 'd-none'}>
                        <input type="email" id="paypalemail" value={paypalemail} onChange={(event) => setPaypalEmail(event.target.value)} placeholder="Email Address" className="rounded-pill align-self-center w-100 px-4 p-3 bg-white border" />

                        <button type="submit" id="btn-signup" onClick={ClaimPrize} className="border-0 rounded-pill btn pfw-button-red secbutton py-3 px-3 my-4 w-100 font-face-gb-semibold">Submit</button>
                    </div>

                    <div className='trusted-secure text-center my-2'>
                        <img src={require('../assets/images/padlock.png')} className="d-inline-block" style={{ width: 20, height: 'auto' }} />
                        <span className="d-inline-block fw-bold branding-2 ms-1">Trusted Secure</span>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={modalShow} onHide={() => setModalShow(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='claim-prize-modal'
            >
                <Modal.Header closeButton className='d-flex flex-column justify-content-start'>
                    <Modal.Title id="contained-modal-title-vcenter" className='w-100 text-left'>
                        Claim Prize
                    </Modal.Title>
                    <p className='w-100 text-left m-0'>Please Confirm the  bank details</p>
                </Modal.Header>
                <Modal.Body className='pt-0'>
                    <div className='claim-methods d-flex gap-2'>
                        <Button onClick={() => { setBankAccount(true); setPaypal(false) }} className={bankaccount ?
                            'w-50 border-0 rounded-pill btn pfw-button-green py-3 px-3 my-4 w-100 font-face-gb-semibold btn-active-green' : 'w-50 border-0 rounded-pill btn py-3 px-3 my-4 w-100 font-face-gb-semibold normal-btn'}>
                            Bank Account
                        </Button>
                        <Button onClick={() => { setPaypal(true); setBankAccount(false) }} className={paypal ?

                            'w-50 bg-transparent rounded-pill btn branding2 pributton-border pributton-border-hover border-2 py-3 px-3 my-4 w-100 font-face-gb-semibold btn-active-green' : 'w-50 bg-transparent rounded-pill btn branding2 border-2 py-3 px-3 my-4 w-100 font-face-gb-semibold normal-btn'}>
                            PayPal
                        </Button>
                    </div>
                    <div className={bankaccount ? 'd-block' : 'd-none'}>
                        <div className='flex-container mb-3'>
                            <div className='flex-item'>
                                <h5> <label>Full Name</label> </h5>   <span className='content-spaces'> {fullname} </span>
                            </div>
                            <div className='flex-item'>
                                <h5> <label>Account Number</label> </h5>  <span className='content-spaces'> {accountnumber} </span>
                            </div>
                            <div className='flex-item'>
                                <h5> <label>Sort Code</label> </h5> <span className='content-spaces'>{sortcode} </span>
                            </div>
                        </div>
                        <button type="submit" id="btn-signup" onClick={() => {
                            setEditModalShow(true);
                            setModalShow(false);
                        }} className="border-0 rounded-pill btn pfw-button-red secbutton py-3 px-3 my-2 w-100 font-face-gb-semibold">Edit</button>
                    </div>

                    <div className={paypal ? 'd-block' : 'd-none'}>
                        <div className='flex-container mb-3'>
                            <div className='flex-item'>
                                <h5> <label>Email Address</label> </h5>   <span className='content-spaces'> {paypalemail} </span>
                            </div>
                        </div>
                        <button type="submit" id="btn-signup" onClick={() => {
                            setEditModalShow(true);
                            setModalShow(false);
                        }} className="border-0 rounded-pill btn pfw-button-red secbutton py-3 px-3 my-2 w-100 font-face-gb-semibold">Edit</button>

                    </div>

                    <div className='trusted-secure text-center my-2'>
                        <img src={require('../assets/images/padlock.png')} className="d-inline-block" style={{ width: 20, height: 'auto' }} />
                        <span className="d-inline-block fw-bold branding-2 ms-1">Trusted Secure</span>
                    </div>
                </Modal.Body>
            </Modal>
            {/*Edit Account Details Modal*/}

            <Modal
                show={editmodalShow} onHide={() => setEditModalShow(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='claim-prize-modal'
            >
                <Modal.Header closeButton className='d-flex flex-column justify-content-start'>
                    <Modal.Title id="contained-modal-title-vcenter" className='w-100 text-left'>
                        Edit Claim Prize
                    </Modal.Title>
                    <p className='w-100 text-left mt-2' style={{ color: "red" }}>Please Update your Account Details .On click of Update button you will get confirmation email to confirm Account Detail change.</p>
                </Modal.Header>
                <Modal.Body className='pt-0'>
                    <div className='claim-methods d-flex gap-2 mb-3'>
                        <Button onClick={() => { setBankAccount(true); setPaypal(false) }} className={bankaccount ? 'w-50 border-0 rounded-pill py-3 px-3 my-4 w-100 font-face-gb-semibold btn-active-green' : 'w-50 border-0 rounded-pill normal-btn py-3 px-3 my-4 w-100 font-face-gb-semibold'} style={{}}>
                            Bank Account
                        </Button>
                        <Button onClick={() => { setPaypal(true); setBankAccount(false) }} className={paypal ? 'w-50 bg-transparent rounded-pill btn branding2 border-2 py-3 px-3 my-4 w-100 font-face-gb-semibold btn-active-green' : 'w-50 bg-transparent rounded-pill btn branding2 normal-btn border-2 py-3 px-3 my-4 w-100 font-face-gb-semibold'}>
                            PayPal
                        </Button>
                    </div>
                    <div className={bankaccount ? 'd-block' : 'd-none'}>
                        <input type="text" id="fullname" value={fullname} onChange={(event) => setFullName(event.target.value)} placeholder="Full Name" className="rounded-pill align-self-center w-100 px-4 p-3 bg-white border mb-3" />
                        <input type="text" id="accountnumber" value={accountnumber} onChange={(event) => setAccountNumber(event.target.value)} placeholder="Account Number" className="rounded-pill align-self-center w-100 px-4 p-3 bg-white border mb-3" />
                        <input type="text" id="sortcode" value={sortcode} onChange={(event) => setSortCode(event.target.value)} placeholder="Sort Code" className="rounded-pill align-self-center w-100 px-4 p-3 bg-white border" />

                        <button type="submit" id="btn-signup" onClick={editClaimPrizeHandler} className="border-0 rounded-pill btn pfw-button-red secbutton py-3 px-3 my-4 w-100 font-face-gb-semibold">Update</button>
                        <button type="submit" id="btn-signup" onClick={() => setEditModalShow(false)} className="border-0 rounded-pill btn pfw-button-red secbutton py-3 px-3 my-2 w-100 font-face-gb-semibold">Cancel</button>
                    </div>

                    <div className={paypal ? 'd-block' : 'd-none'}>
                        <input type="email" id="paypalemail" value={paypalemail} onChange={(event) => setPaypalEmail(event.target.value)} placeholder="Email Address" className="rounded-pill align-self-center w-100 px-4 p-3 bg-white border" />

                        <button type="submit" id="btn-signup" onClick={editClaimPrizeHandler} className="border-0 rounded-pill btn pfw-button-red secbutton py-3 px-3 my-4 w-100 font-face-gb-semibold">Update</button>
                        <button type="submit" id="btn-signup" onClick={() => setEditModalShow(false)} className="border-0 rounded-pill btn pfw-button-red secbutton py-3 px-3 my-2 w-100 font-face-gb-semibold">Cancel</button>

                    </div>

                    <div className='trusted-secure text-center my-2'>
                        <img src={require('../assets/images/padlock.png')} className="d-inline-block" style={{ width: 20, height: 'auto' }} />
                        <span className="d-inline-block fw-bold branding-2 ms-1">Trusted Secure</span>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={modalShow3} onHide={() => setModalShow3(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='claim-prize-modal'
            >
                <Modal.Header closeButton className='d-flex flex-column justify-content-start'>
                    <Modal.Title id="contained-modal-title-vcenter" className='w-100 text-left'>
                        Please confirm your address below for delivery
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-0'>
                    <div>
                        <input type="text" id="address1" value={address1} onChange={(event) => setAddress1(event.target.value)} placeholder="Address Line 1" className="rounded-pill align-self-center w-100 px-4 p-3 bg-white border mb-3" />
                        <input type="text" id="address2" value={address2} onChange={(event) => setAddress2(event.target.value)} placeholder="Address Line 2" className="rounded-pill align-self-center w-100 px-4 p-3 bg-white border mb-3" />
                        <input type="text" id="postcode" value={postcode} onChange={(event) => setPostcode(event.target.value)} placeholder="Postcode" className="rounded-pill align-self-center w-100 px-4 p-3 bg-white border" />
                        <button type="submit" id="btn-signup" onClick={ClaimPrize2} className="border-0 rounded-pill btn pfw-button-red secbutton py-3 px-3 my-4 w-100 font-face-gb-semibold">Submit</button>    </div>
                    <div className='trusted-secure text-center my-2'>
                        <img src={require('../assets/images/padlock.png')} className="d-inline-block" style={{ width: 20, height: 'auto' }} />
                        <span className="d-inline-block fw-bold branding-2 ms-1">Trusted Secure</span>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={modalShow2} onHide={() => setModalShow2(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='claim-prize-modal'
            >
                <Modal.Header closeButton className='d-flex flex-column justify-content-start'>
                    <Modal.Title id="contained-modal-title-vcenter" className='w-100 text-left'>
                        Claim Prize
                    </Modal.Title>
                    <p className='w-100 text-left m-0'>Please confirm your address below for delivery</p>
                </Modal.Header>
                <Modal.Body className='pt-0'>
                    <div>
                        <div className="flex-container">
                            <div className="flex-item">
                                <h5><label>Street Address:</label></h5> <span className='content-spaces'>{address1}</span>
                            </div>
                            <div className="flex-item">
                                <h5><label>Town/City:</label></h5> <span className='content-spaces'>{address2}</span>
                            </div>
                            <div className="flex-item">
                                <h5><label>Postcode:</label></h5> <span className='content-spaces'>{postcode}</span>
                            </div>
                        </div>
                        <button type="submit" id="btn-signup" onClick={() => {
                            setEditModalShow2(true);
                            setModalShow2(false);
                        }} className="border-0 rounded-pill btn pfw-button-red secbutton py-3 px-3 my-2 w-100 font-face-gb-semibold">Edit</button>
                    </div>

                    <div className='trusted-secure text-center my-2'>
                        <img src={require('../assets/images/padlock.png')} className="d-inline-block" style={{ width: 20, height: 'auto' }} />
                        <span className="d-inline-block fw-bold branding-2 ms-1">Trusted Secure</span>
                    </div>
                </Modal.Body>
            </Modal>

            {/*Edit Address Details Modal*/}
            <Modal
                show={editmodalShow2} onHide={() => setEditModalShow2(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='claim-prize-modal'
            >
                <Modal.Header closeButton className='d-flex flex-column justify-content-start'>
                    <Modal.Title id="contained-modal-title-vcenter" className='w-100 text-left'>
                        Edit Claim Prize
                    </Modal.Title>
                    <p className='w-100 text-left mt-2' style={{ color: "red" }}>Please Update your address below for delivery. On click of Update button you will get confirmation email to confirm address change.</p>
                </Modal.Header>
                <Modal.Body className='pt-0'>
                    <div>
                        <input type="text" id="address1" value={address1} onChange={(event) => setAddress1(event.target.value)} placeholder="Address Line 1" className="rounded-pill align-self-center w-100 px-4 p-3 bg-white border mb-3" />
                        <input type="text" id="address2" value={address2} onChange={(event) => setAddress2(event.target.value)} placeholder="Address Line 2" className="rounded-pill align-self-center w-100 px-4 p-3 bg-white border mb-3" />
                        <input type="text" id="postcode" value={postcode} onChange={(event) => setPostcode(event.target.value)} placeholder="Postcode" className="rounded-pill align-self-center w-100 px-4 p-3 bg-white border" />
                        <button type="submit" id="btn-signup" onClick={editClaimPrizeHandler2} className="border-0 rounded-pill btn pfw-button-red secbutton py-3 px-3 my-4 w-100 font-face-gb-semibold">Update</button>
                        <button type="submit" id="btn-signup" onClick={() => setEditModalShow2(false)} className="border-0 rounded-pill btn pfw-button-red secbutton py-3 px-3 my-2 w-100 font-face-gb-semibold">Cancel</button>
                    </div>

                    <div className='trusted-secure text-center my-2'>
                        <img src={require('../assets/images/padlock.png')} className="d-inline-block" style={{ width: 20, height: 'auto' }} />
                        <span className="d-inline-block fw-bold branding-2 ms-1">Trusted Secure</span>
                    </div>
                </Modal.Body>
            </Modal>

            <SiteFooter />
            <ToastContainer />
        </>
    )
}

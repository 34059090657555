import React, { Component } from "react";
import Slider from "react-slick";

export default function PrizeSlider(props) {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        slidesToShow: 1,
        autoplaySpeed: 2500,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    const parser = new DOMParser();
    console.log("padding", props.isPadding);
    return (
        <div>
            <div
                className="prizes-slider"
                style={props.isPadding ? { paddingTop: 200 } : {}}
            >
                <Slider {...settings}>
                    {props.prizeData.map((item) => {
                        return (
                            <div key={item.id + item.image}>
                                <div
                                    className="prize-slide text-center shadow-lg px-4 py-5 rounded-4 mt-3 mb-5 bg-white mx-auto"
                                    style={{ width: 340 }}
                                >
                                    {item.image != "image" ? (
                                        <img
                                            className="w-100 prize-img"
                                            src={
                                                item.image
                                                    ? item.image.startsWith(
                                                          "https"
                                                      )
                                                        ? item.image
                                                        : require(`../assets/images/draws/${item.image}`)
                                                    : ""
                                            }
                                        />
                                    ) : (
                                        ""
                                    )}
                                    
                                    <div style={{ display: "flex", alignItems: "center" , justifyContent: 'center', marginBottom : 20 , marginTop : 20 }}>
                                            <img className="image-prize"
                                                src={require(`../assets/images/prize-size.png`)}
                                                alt="Prize"
                                                style={{ marginRight: "5px" }}
                                            />
                                            <h4
                                                className="font-face-gb-semibold fw-normal fs-3 branding1 mt-2 mb-0"
                                                dangerouslySetInnerHTML={{
                                                __html: parser.parseFromString(`${item.content}`, "text/html").body
                                                    .textContent,
                                                }}
                                            ></h4>
                                            </div>
                                </div>
                            </div>
                        );
                    })}
                </Slider>
            </div>
        </div>
    );
}

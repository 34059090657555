import React from 'react'

export default function HowItWorks() {
  return (
    <>
    <div className="container py-5 px-0 how-it-works hiw-common fon-face-gb rounded-4" style={{backgroundColor: '#f2f2f2'}}>
      <h2 className="hiw-title branding1 text-center font-face-gb-semibold display-4 mb-5 mt-4">How it works</h2>
      <div className="row mx-auto mb-4" style={{width: '80%'}}>
        <div className="col-md-4 px-md-5">
          <div className="py-3 d-flex align-items-center">
              <img src={require('../assets/images/Group-1462.webp')} />
              <h2 className="branding1 mn-0 ms-4 font-face-gb-semibold display-4">play</h2>
          </div>
          <p className="text-left">Play a draw by visiting <strong>Play Fund Win</strong> and finding your favourite cause.</p>
        </div>
        <div className="col-md-4 px-md-5">
          <div className="py-3 d-flex align-items-center">
              <img src={require('../assets/images/Group-1463.webp')} />
              <h2 className="text-left branding1 mb-0 ms-4 font-face-gb-semibold display-4">fund</h2>
          </div>
          <p className="text-left">Fund good causes and grassroots sports, as minimum <strong>50% of net proceeds go to charity or good causes.</strong></p>
        </div>
        <div className="col-md-4 px-md-5">
          <div className="py-3 d-flex align-items-center">
              <img src={require('../assets/images/Group-1464.webp')} />
              <h2 className="text-left branding1 mb-0 ms-4 font-face-gb-semibold display-4">win</h2>
          </div>
          <p className="text-left">The draw host determines what <strong>% of the entry fee forms the jackpot prize which is guaranteed to be won on every draw</strong></p>
        </div>
      </div>
    </div>
    </>
  )
}

import React,{Component} from 'react'
import Slider from "react-slick";

export default function HostSliderSectionGrassroots() {
    var  settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
              }
            },
            {
              breakpoint: 700,
              settings: {
                slidesToShow: 3,
                initialSlide: 2,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
              }
            },
            {
                breakpoint: 320,
                settings: {
                  slidesToShow: 1,
                }
              }
            
          ]
        };  
      return (
        <div className='container w-100'>
        <div className='row'>
        <Slider {...settings}>
            
        <div className='p-3 col m-md-3 d-flex align-items-center'>
                <div>
                <img width={'100%'}  src={require('../assets/images/5s.png')}/>
                </div>
            </div>

            <div className='p-3 col m-md-3 d-flex align-items-center'>
                <div>
                <img width={'100%'}  src={require('../assets/images/bed.png')}/>
                </div>
            </div>

            <div className='p-3 col m-md-3 d-flex align-items-center'>
                <div>
                <img width={'100%'}  src={require('../assets/images/brook.png')}/>
                </div>
            </div>

        <div className='p-3 col m-md-3 d-flex align-items-center'>
                <div>
                <img width={'100%'}  src={require('../assets/images/bradley.png')}/>
                </div>
            </div>

            
            <div className='p-3 col m-md-3 d-flex align-items-center'>
                <div>
                <img width={'100%'}  src={require('../assets/images/chester.png')}/>
                </div>
            </div>
            
            
            <div className='p-3 col m-md-3 d-flex align-items-center'>
                <div>
                <img width={'100%'}  src={require('../assets/images/dela.png')}/>
                </div>
            </div>

            <div className='p-3 col m-md-3 d-flex align-items-center'>
                <div>
                <img width={'100%'}  src={require('../assets/images/Hull-United-host.png')}/>
                </div>
            </div>

            <div className='p-3 col m-md-3 d-flex align-items-center'>
                <div>
                <img width={'100%'}  src={require('../assets/images/leyland.png')}/>
                </div>
            </div>

            <div className='p-3 col m-md-3 d-flex align-items-center'>
                <div>
                <img width={'100%'}  src={require('../assets/images/Chadderton-host.png')}/>
                </div>
            </div>

            <div className='p-3 col m-md-3 d-flex align-items-center'>
                <div>
                <img width={'100%'}  src={require('../assets/images/pits.png')}/>
                </div>
            </div>

            <div className='p-3 col m-md-3 d-flex align-items-center'>
                <div>
                <img width={'100%'}  src={require('../assets/images/royal.png')}/>
                </div>
            </div>

            <div className='p-3 col m-md-3 d-flex align-items-center'>
                <div>
                <img width={'100%'}  src={require('../assets/images/sen.png')}/>
                </div>
            </div>

            <div className='p-3 col m-md-3 d-flex align-items-center'>
                <div>
                <img width={'100%'}  src={require('../assets/images/tecnica.png')}/>
                </div>
            </div>
            
        </Slider>
        </div>
        </div>
  )
}

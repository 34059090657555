import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { DrawDataSession } from "./Cart";
import { GetDrawList } from '../Data/actionMethods'
import Moment from "moment";
import CountdownTimer from './CountdownTimer';
import { LogoLoader } from "./imageLoader";

function SetDrawDetails(p) {
  DrawDataSession.data = p;
}

export default function AllDrawsSlider({ slicedData }) {
  const [slider, setSlider] = useState(null);

  const nextSlide = () => {
    slider.slickNext();
  }

  const prevSlide = () => {
    slider.slickPrev();
  }

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
          infinite: true,
          autoplay: true,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          infinite: true,
          autoplay: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          arrows: false,
          infinite: true,
          autoplay: true,
        }
      },
    ]
  };

  return (
    <div className="pb-0">
      <div className="container">
      </div>
      <div className="monthly-slider-temp4" style={{ width: '100%' }}>
        <div className='container-slider-temp4'>
          

          <Slider {...settings} className="py-4">
            {slicedData.slice(0, 15).map((p, index) => {

              if (p.TestDraw === 1) {

              } else {


                return (



                  <div className='col-lg-3 col-md-3 col-sm-12 font-face-gb px-0 position-relative w-100' key={p.id}>

                    {!!(p.HeaderImage) ?
                      <>
                        {p.Redirection ? <a href={p.RedirectionUrl} className="text-decoration-none" target={"_blank"}>
                          <div className="shadow-sm bg-white" style={{ borderRadius: 20 }}><div className={`main-header animation-shine-draw text-center draw-id-${p.drawID}`} style={{
                            padding: '60px 0px', backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.62),${p.BrandingColor2}), ${p.HeaderImage.includes("http")
                              ? `url(${p.HeaderImage})`
                              : `url(${require('../assets/images/draws/' + p.HeaderImage)})`
                              }`, backgroundSize: 'cover', borderTopLeftRadius: 15, borderTopRightRadius: 15, height: 300, alignItems: 'center', justifyContent: 'center', display: 'flex'
                          }}>
                            <LogoLoader src={p.logoUrl} style={{ width: '100%', height: '100px', objectFit: 'contain' }} />
                            {/* <img className="" src={require(`../assets/images/logo/${p.logoUrl}`)} style={{ width: '100%', height: '100px', objectFit: 'contain' }}></img> */}
                          </div>
                            <div className="draws-title bg-white text-left" style={{ borderBottomLeftRadius: 15, borderBottomRightRadius: 15, padding: 24 }}>
                              <h4 className="pt-3 mb-0 font-h4 text-dark" style={{ minHeight: 64 }}>{p.name}</h4>
                              <div className="mb-2 p-0 d-flex">
                                <img src={require('../assets/images/arrow_forward.png')} className="pe-1" />
                                <span className="branding3 font-family-light-para">PLAY</span>
                              </div>
                            </div>

                          </div>
                        </a>

                          :
                          <Link to={"/draw/" + p.slug} onClick={() => SetDrawDetails(p)} className="text-decoration-none">
                            <div className="shadow-sm bg-white" style={{ borderRadius: 20 }}><div className={`main-header animation-shine-draw text-center draw-id-${p.drawID}`} style={{
                              padding: '60px 0px', backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.62),${p.BrandingColor2}), ${p.HeaderImage.includes("http")
                                ? `url(${p.HeaderImage})`
                                : `url(${require('../assets/images/draws/' + p.HeaderImage)})`
                                }`, backgroundSize: 'cover', borderTopLeftRadius: 15, borderTopRightRadius: 15, height: 300, alignItems: 'center', justifyContent: 'center', display: 'flex'
                            }}>
                              <LogoLoader src={p.logoUrl} style={{ width: '100%', height: '100px', objectFit: 'contain' }} />
                              {/* <img className="" src={require(`../assets/images/logo/${p.logoUrl}`)} style={{ width: '100%', height: '100px', objectFit: 'contain' }}></img> */}
                            </div>
                              <div className="draws-title bg-white text-left" style={{ borderBottomLeftRadius: 15, borderBottomRightRadius: 15, padding: 24 }}>
                                <h4 className="pt-3 mb-0 font-h4 text-dark" style={{ minHeight: 64 }}>{p.name}</h4>
                                <div className="mb-2 p-0 d-flex">
                                  <img src={require('../assets/images/arrow_forward.png')} className="pe-1" />
                                  <span className="branding3 font-family-light-para">PLAY</span>
                                </div>
                              </div>
                            </div>
                          </Link>
                        }
                      </>
                      :
                      <>
                        {p.Redirection ? <a href={p.RedirectionUrl} className="text-decoration-none" target={"_blank"}>
                          <div className="shadow-sm bg-white" style={{ borderRadius: 20 }}><div className={`main-header animation-shine-draw text-center draw-id-${p.drawID}`} style={{ padding: '60px 0px', backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.62),${p.BrandingColor2}),url(${require(`../assets/images/hero-banner.jpg`)})`, backgroundSize: 'cover', borderTopLeftRadius: 15, borderTopRightRadius: 15, height: 300, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                            <LogoLoader src={p.logoUrl} style={{ width: '100%', height: '100px', objectFit: 'contain' }} />
                            {/* <img className="" src={require(`../assets/images/logo/${p.logoUrl}`)} style={{ width: '100%', height: '100px', objectFit: 'contain' }}></img> */}
                          </div>
                            <div className="draws-title bg-white text-left" style={{ borderBottomLeftRadius: 15, borderBottomRightRadius: 15, padding: 24 }}>
                              <h4 className="pt-3 mb-0 font-h4 text-dark" style={{ minHeight: 64 }}>{p.name}</h4>
                              <div className="mb-2 p-0 d-flex">
                                <img src={require('../assets/images/arrow_forward.png')} className="pe-1" />
                                <span className="branding3 font-family-light-para">PLAY</span>
                              </div>
                            </div>
                          </div>
                        </a>
                          :
                          <Link to={"/draw/" + p.slug} onClick={() => SetDrawDetails(p)} className="text-decoration-none">
                            <div className="shadow-sm bg-white" style={{ borderRadius: 20 }}><div className={`main-header animation-shine-draw text-center draw-id-${p.drawID}`} style={{ padding: '60px 0px', backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.62),${p.BrandingColor2}),url(${require(`../assets/images/hero-banner.jpg`)})`, backgroundSize: 'cover', borderTopLeftRadius: 15, borderTopRightRadius: 15, height: 300, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                              <LogoLoader src={p.logoUrl} style={{ width: '100%', height: '100px', objectFit: 'contain' }} />
                              {/* <img className="" src={require(`../assets/images/logo/${p.logoUrl}`)} style={{ width: '100%', height: '100px', objectFit: 'contain' }}></img> */}
                            </div>
                              <div className="draws-title bg-white text-left" style={{ borderBottomLeftRadius: 15, borderBottomRightRadius: 15, padding: 24 }}>
                                <h4 className="pt-3 mb-0 font-h4 text-dark" style={{ minHeight: 64 }}>{p.name}</h4>
                                <div className="mb-2 p-0 d-flex">
                                  <img src={require('../assets/images/arrow_forward.png')} className="pe-1" />
                                  <span className="branding3 font-family-light-para">PLAY</span>
                                </div>
                              </div>
                            </div>

                          </Link>


                        }
                      </>
                    }

                  </div>

                );
              }
            })}
          </Slider>
        </div>
      </div>
    </div>
  );

}

import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import Siteheader from '../components/header';
import SiteFooter from '../components/footer';
import Innerheader from '../components/inner-header';
import { BlogData } from '../Data/Blog-Data';
import FooterCta from '../components/footer-cta';
import { BlogDataSession } from '../components/Cart';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getAllPosts } from '../Data/actionMethods';
import loading from '../assets/images/loading.gif';
import ScrollToTop from '../scrollToTop';


export default function Blog() {

  const navigate = useNavigate();
  const [blogData, setBlogData] = useState([]);

  const decodeHTMLEntities = (input) => {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent.replace(/&#9;/g, ' ').replace(/\s+/g, ' ').trim();
  };

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  }

  useEffect(() => {
    getAllPosts().then((res) => {
      setBlogData(res.data.content ? res.data.content : []);
    });
  }, []);


  return (
    <>
      <Helmet>‍
        <title>Blog | Play Fund Win</title>‍
        <meta name="description" content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities." />
      </Helmet>
      <Siteheader />
      <Innerheader title="Our Thoughts and Insights" />

      {blogData.length > 0 ?
        <div className='blog-overview-section container py-5 bg-f2 my-4' style={{ borderRadius: 10 }}>
          {/* <div className="sort-section d-flex justify-content-between mx-auto bg-light px-5 py-3 mt-3 mb-5 rounded-pill font-face-gb" style={{width: '81%'}}>
            <p className="m-0 text-black fw-bolder">Tags:</p>
            <ul className="d-flex list-unstyled m-0">
                <li className="mx-5"><a className="text-decoration-none text-muted" href="/category/our-thoughts-and-insights/">Our Thoughts and Insights</a></li>
                <li className="mx-5"><a className="text-decoration-none text-muted" href="/category/press-release/">Press Release</a></li>
                <li className="mx-5"><a className="text-decoration-none text-muted" href="/category/thoughts-insights/">Thoughts &amp; Insights</a></li>
            </ul>
            <select className="desc-asc-order bg-transparent border-0 height-0 lh-0">
                <option value="ASC">Sort A - Z</option>
                <option value="DESC">Sort Z - A</option>
            </select>
            <select className="desc-asc-order bg-transparent border-0 height-0 lh-0">
                <option value="post_date" defaultValue>Order By Date</option>
                <option value="post_title">Order By Title</option>
                <option value="rand">Random Order</option>
            </select>
        </div> */}

          {/* <div className='row mx-auto pt-4' style={{width:'82%'}}>
            {data.map((p) => (
                <div className='col-lg-4 col-md-6 col-sm-12 font-face-gb mb-5 d-flex flex-column' key={p.id}>
                    <div className='bg-branding1 h-100' style={{borderRadius: 10}}>
                        <Link to={"/blog/" + p.slug} onClick={() => SetBlogDetails(p)}><div className='post-image background-image' style={{backgroundImage: `url(${require(`../assets/images/blogs/${p.postImage}`)})`, borderTopLeftRadius: 10, borderTopRightRadius: 10, height: 196}}></div></Link>
                        <div className='post-short-content bg-branding1' style={{borderBottomLeftRadius: 10, borderBottomRightRadius: 10, padding:35}}>
                            <div className='publishdate text-light'>{p.publishdate}</div>
                            <Link className='text-decoration-none' to={"/blog/" + p.slug} onClick={() => SetBlogDetails(p)}><h3 className='post-title color-yellow fs-5 font-face-gb-semibold fw-normal my-3' style={{minHeight: 50}}>{p.name}</h3></Link>
                            <div className='post-excerpt text-white'>{p.excerpt}</div>
                            <Link to={"/blog/" + p.slug} onClick={() => SetBlogDetails(p)} className="border-0 rounded-pill btn pfw-button-red secbutton py-2 px-4 mt-4 mb-2 font-face-gb-semibold" style={{lineHeight: 2}}>Read More</Link>
                        </div>
                    </div>
                </div>
            ))}
        </div> */}
          <div className='row mx-auto pt-4' style={{ width: '82%' }}>
            {blogData.map((p) => {
              let postMeta;
              try {
                postMeta = JSON.parse(p.postMeta);
              } catch (error) {
                postMeta = "";
              }
              return (
                <div className={`col-lg-4 col-md-6 col-sm-12 font-face-gb mb-5 d-flex flex-column ${p.postID}`} key={p.postID}>
                  <div className='bg-branding1 h-100' style={{ borderRadius: 10, overflow: 'hidden' }}>
                    <Link
                      to={{
                        pathname: `/blog/${p.postSlug}`
                      }}
                      onClick={() => {
                        navigate(`/blog/${p.postSlug}`);
                      }}
                    >
                      {/* Use LazyLoadImage for the background-image */}
                      <LazyLoadImage
                        className='post-image background-image w-100 position-relative'
                        alt=''
                        effect='blur' // Add a loading effect if desired
                        src={postMeta.featuredImage}
                        style={{
                          borderTopLeftRadius: 10,
                          borderTopRightRadius: 10
                        }}
                      />
                    </Link>
                    <div className='text-end position-absolute d-flex' style={{ right: '28px', marginTop: '-13px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                      {p.taxonomy.filter(t => t.taxanomyType === 'blog-category').map((t, index, array) => (
                        <React.Fragment key={t.taxanomySlug}>
                          <Link
                            to={{
                              pathname: `/category/${t.taxanomySlug}`
                            }}
                            onClick={() => {
                              // Scroll to the top of the page when a category is clicked
                              window.scrollTo(0, 0);
                              navigate(`/category/${t.taxanomySlug}`);
                            }}
                            className='text-decoration-none text-light pfw-button-green rounded-pill px-3'
                            style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}
                          >
                            {t.taxanomyName}
                          </Link>
                          {index !== array.length - 1 && <div style={{width: '10px', height: '10px'}}></div>}
                        </React.Fragment>
                      ))}
                    </div>
                    <div className='post-short-content bg-branding1' style={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10, padding: 35 }}>

                      <div className='publishdate text-light'>{moment(p.postPublishedDate).format('Do MMMM YYYY')}</div>

                      <Link
                        className='text-decoration-none'
                        to={{
                          pathname: `/blog/${p.postSlug}`
                        }}
                        onClick={() => {
                          navigate(`/blog/${p.postSlug}`);
                        }}
                      >
                        <h3 className='post-title color-yellow fs-5 font-face-gb-semibold fw-normal my-3' style={{ minHeight: 50 }}>
                          {truncateText(decodeHTMLEntities(p.postName), 50)}
                        </h3>
                      </Link>
                      <div className='post-excerpt text-white'>{truncateText(decodeHTMLEntities(postMeta.introductionText), 70)}</div>

                      <Link
                        to={{
                          pathname: `/blog/${p.postSlug}`
                        }}
                        onClick={() => {
                          navigate(`/blog/${p.postSlug}`);
                        }}
                        className='border-0 rounded-pill btn pfw-button-red secbutton py-2 px-4 mt-3 mb-2 font-face-gb-semibold'
                        style={{ lineHeight: 2 }}
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        :
        <div className='text-center '>
          <img className='w-50' src={loading} style={{ opacity: '0.7' }} alt="Loading..." />
        </div>
      }
      <FooterCta />
      <SiteFooter />
      <ScrollToTop onload={blogData.length} />
    </>
  )
}

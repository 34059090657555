import React, { useState, useEffect } from "react";

export default function ImageLoader({ src }) {
    const [imageExists, setImageExists] = useState(false);

    useEffect(() => {
        if (src) {
            if (src.startsWith("http")) {
                fetch(src)
                    .then((response) => {
                        if (response.ok) {
                            setImageExists(true);
                        } else {
                            setImageExists(false);
                        }
                    })
                    .catch(() => setImageExists(false));
            } else {
                const img = new Image();
                img.onload = () => setImageExists(true);
                img.onerror = () => setImageExists(false);
                img.src = require(`../assets/images/draws/${src}`);
            }
        }
    }, [src]);

    return (
        <>
            {imageExists && (
                <img
                    className="rounded-5 w-100"
                    src={
                        src.startsWith("http")
                            ? src
                            : require(`../assets/images/draws/${src}`)
                    }
                    alt=""
                />
            )}
            {!imageExists && src && <></>}
        </>
    );
}

export function LogoLoader({ src }) {
    const [imageExists, setImageExists] = useState(false);

    useEffect(() => {
        if (src) {
            if (src.startsWith("http")) {
                fetch(src)
                    .then((response) => {
                        if (response.ok) {
                            setImageExists(true);
                        } else {
                            setImageExists(false);
                        }
                    })
                    .catch(() => setImageExists(false));
            } else {
                const img = new Image();
                img.onload = () => setImageExists(true);
                img.onerror = () => setImageExists(false);
                img.src = require(`../assets/images/logo/${src}`);
            }
        }
    }, [src]);

    return (
        <>
            {imageExists && (
                <img
                    className="rounded-5 w-75"
                    src={
                        src.startsWith("http")
                            ? src
                            : src
                            ? require(`../assets/images/logo/${src}`)
                            : ""
                    }
                    alt=""
                    style={{
                        width: "100%",
                        height: "100px",
                        objectFit: "contain",
                    }}
                />
            )}
            {!imageExists && src && <>Here </>}
        </>
    );
}

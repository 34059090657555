import React, { useEffect, useState } from 'react';
import SiteFooter from '../components/footer';
import Siteheader from '../components/header';
import { Link } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import apiClient from '../api.config';
import { toast, Toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { CountryDropdown } from 'react-country-region-selector';
import CountryData from '../components/CountryData'
import AddressLoader from '../components/AddressLoader';
import { completeRegistration } from '../utils/meta/metaPixelEvent';

const UI_PREFIXURL = process.env.REACT_APP_UI_PREFIXURL;
// const UI_PREFIXURL = 'http://localhost:3000'
const APIURL = process.env.REACT_APP_API_URL;
// const APIURL = 'http://localhost:5000/api/v1';

export default function Register() {
  // const [username,SetUsername] = useState('')
  const [email, SetEmail] = useState('');
  const [fname, SetFname] = useState('');
  const [lname, SetLname] = useState('');
  const [mobile, SetMobile] = useState('');
  const [address, SetAddress] = useState('');
  const [dob, SetDOB] = useState('');
  const [age, setAge] = useState(0);
  const [SendDate, setSendDate] = useState();
  const [password, setPassword] = useState('');
  const [address2, setAddress2] = useState('');
  const [postcode, setPostcode] = useState('');
  const [country, setCountry] = useState('');
  const [timer, setTimer] = useState(0);
  const [addressLoader, setAddressLoader] = useState(0);
  const [addressSuggestions, setAddressSuggestions] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmParams = {
      utm_source: urlParams.get('utm_source'),
      utm_medium: urlParams.get('utm_medium'),
      utm_campaign: urlParams.get('utm_campaign'),
      utm_content: urlParams.get('utm_content')
    };
    secureLocalStorage.setItem('utmParams', JSON.stringify(utmParams));
    console.log('utmParams', utmParams);
  }, []);

  async function userRegisterHandler() {
    // if (username.trim().length === 0 && username.trim().length === 0 ) {
    //   toast.error(`Please Enter Username Password`);
    // }
    if (email.trim().length === 0) {
      toast.error(`Please Enter Your Email`);
    } else if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+(?:\.[a-zA-Z0-9-]+)*$/.test(email)
    ) {
      toast.error(`Please Enter Your valid Email Address`);
    } else if (fname.trim().length === 0) {
      toast.error(`Please Enter First Name`);
    } else if (lname.trim().length === 0) {
      toast.error(`Please Enter Last Name`);
    } else if (password.trim().length === 0) {
      toast.error(`Please Enter Password`);
    } else if (address.trim().length === 0) {
      toast.error(`Please Enter Address`);
    } else if (postcode.trim().length === 0) {
      toast.error(`Please Enter PostCode`);
    } else if (mobile.trim().length < 5) {
      toast.error(`Mobile Number Is Required`);
    } else if (dob.trim().length === 0) {
      toast.error(`Please Enter Date of Birth`);
    } else if (dob.trim().length === 0) {
      toast.error(`Please Enter Date of Birth`);
    } else if (dob.length < 10) {
      toast.error(`Please Enter Your valid Date of birth`);
    } else if (age <= 18) {
      toast.error(`Sorry. Only users over the age of eighteen are allowed to register`);
    } else {
      //register
      try {
        const response = await toast.promise(
          apiClient.post(
            `${APIURL}/users`,
            {
              userFirstName: fname,
              userSurname: lname,
              userEmail: email,
              userPhoneNumber: mobile,
              userPassword: password,
              userDateOfBirth: moment(dob).format('YYYY-MM-DD'),
              userAddressLine1: address,
              userAddressLine2: address2,
              userAddressCountryLocaleID: 1,
              userAddressPostcode: postcode,
              userDateJoined: moment().format('YYYY-MM-DD hh:mm:ss'),
              userDateUpdated: moment().format('YYYY-MM-DD hh:mm:ss'),
              userRoleID: 1,
              userGender: 1,
              userLanguageID: 5,
              userAccountApproved: 1,
              userSelfExcluded: 0,
              userSelfExcludedDate: moment().format('YYYY-MM-DD hh:mm:ss'),
              userSelfExcludedExpiryDate: moment().format('YYYY-MM-DD hh:mm:ss'),
              userPurchaseLimits: 1000,
              userOptInMarketingPFW: 1,
              userOptInMarketingPartner: 1,
              userOptOutDate: moment().format('YYYY-MM-DD hh:mm:ss'),
              userLastLoggedIn: moment().format('YYYY-MM-DD hh:mm:ss'),
              userMeta: {
                website: 'PlayFundWin'
              }
            },
            {
              withCredentials: true,
              credentials: 'same-origin'
            }
          ),
          {
            pending: 'Please wait...'
          }
        );
        // console.log(response);

        if (response.data.status === true && response.data.error === null) {
          toast('Successfully Authenticated', { type: 'success' });
          secureLocalStorage.setItem('LogObject', {
            userID: response.data.content.userID,
            email: email,
            loggedin: true
          });
          navigate('/dashboard');
          const userID = response.data.content.userID;

          // For sign-up
          window.dataLayer.push({
            'event': 'user_signed_up',
            'user_id': userID,
            'user_email': email
          });

          completeRegistration();
        } else {
          toast('Something went wrong While authenticating', { type: 'error' });
        }
      } catch (error) {
        console.log(error);
        if (error.response.status === 403) {
          toast.error(`${error.response.data.error.validationErrors[0].msg}`);
          toast.error(`${error.response.data.content.message}`);
        } else {
          toast.error(`${error.message}`);
        }
      }
    }
  }

  function dateSet(value, event) {
    // console.log("moment(dob).format('YYYY-MM-DD').toDate()");
    var date = new Date(value.toString());
    var today = new Date();
    var birthDate = new Date(value.toString()); // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - date.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    // console.log('Today :' + today);
    // console.log('Input val :' + birthDate);
    // console.log(age_now);
    setAge(age_now);
  }

  function SetMobileNumber(val) {
    // console.log("Settinbg", val);
    // let newValue = val?.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
    SetMobile(val);
  }


  const updateAddress = (enteredAddress) => {
    const splitAddress = enteredAddress.split(',')
    SetAddress(splitAddress[0] + " " + splitAddress[1])
    setAddress2(splitAddress[2])

  }
  const updatePostcode = (enteredPostcode) => {
    setPostcode(enteredPostcode);
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      setAddressLoader(true)
      axios.request({
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://ws.postcoder.com/pcw/PCWW5-FVLAY-K6FGB-KSTYM/autocomplete/${country}/${enteredPostcode}?format=json`,
      })
        .then((response) => {
          const predictions = response?.data?.predictions || [];
          let allSuggests = [];
          predictions.map(address => {
            // console.log("address", address)
            if (address.complete) {
              const splitAddress = address.prediction.split(',')
              SetAddress(splitAddress[0] + " " + splitAddress[1])
              setAddress2(splitAddress[2])
              allSuggests.push(address.prediction)
              // console.log("allSuggests", allSuggests)
              setAddressSuggestions(allSuggests);
            }
          }
          )
          setAddressLoader(false);
        })
        .catch((error) => {
          // console.log(error);
          setAddressLoader(false);
        });
    }, 500)
    setTimer(newTimer);
  };


  const handleCountryChange = (val) => {
    setCountry(val);
    // Assuming you have a function to get the country calling code
    const _countryData = CountryData.find(Data => Data.name.trim().toLocaleLowerCase() === val.trim().toLocaleLowerCase());
    const callingCode = _countryData?.code;
    SetMobileNumber(`+${callingCode} `);

  }

  const handleOAuthSignUp = () => {
    const encodedSite = encodeURIComponent(UI_PREFIXURL);
    const oauthUrl = `${APIURL}/auth/google?UI_PREFIXURL=${encodedSite}`;
    window.location.href = oauthUrl; // Use window.location.href to perform the redirect
  };

  return (
    <>
      <Helmet>
        <title>Register | Play Fund Win</title>
        <meta
          name="description"
          content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities."
        />
      </Helmet>
      <Siteheader />
      <ToastContainer />
      <section className="register-section h-100 w-100 bg-f2 p-xl-5 py-5 position-relative bg-before-after">
        <div className="container h-100 p-xl-5 py-5">
          <div className="d-flex align-items-center justify-content-center h-100 ">
            <div className="d-flex flex-column col-xl-9 col-lg-9 col-md-12 col-12 row">
              <div className="align-items-center justify-content-center text-center">
                <h1 className="branding1 fw-bolder">Register</h1>
                <h6 className="color-grey">Enter your detail to proceed further</h6>
              </div>
              <div className="row mt-5">
                {/* <div className='col-sm mb-md-0 mb-3'>
                    <input type="text" name="username" placeholder="Username" value={username} onChange={(e)=>SetUsername(e.target.value)} className='rounded-pill align-self-center w-100 px-3 p-3 bg-white border-0' />
                  </div> */}

                <div className="col-sm mb-md-0 mb-3">
                  <input
                    type="text"
                    name="email"
                    placeholder="Email *"
                    value={email}
                    onChange={(e) => SetEmail(e.target.value)}
                    className="rounded-pill align-self-center w-100 px-3 p-3 bg-white border-0"
                  />
                </div>
                <div className="col-sm mb-md-0 mb-3">
                  <input
                    type="password"
                    name="password"
                    placeholder="Password *"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="rounded-pill align-self-center w-100 px-3 p-3 bg-white border-0"
                  />
                </div>
              </div>
              <hr className="px-5 mt-5 color-grey" style={{ opacity: '0.5' }} />

              <div className="row mt-5">
                <div className="col-sm mb-md-0 mb-3">
                  <input
                    type="text"
                    name="firstname"
                    placeholder="First Name *"
                    value={fname}
                    onChange={(e) => SetFname(e.target.value)}
                    className="rounded-pill align-self-center w-100 px-3 p-3 bg-white border-0"
                  />
                </div>

                <div className="col-sm mb-md-0 mb-3">
                  <input
                    type="text"
                    name="lastname"
                    placeholder="Last Name *"
                    value={lname}
                    onChange={(e) => SetLname(e.target.value)}
                    className="rounded-pill align-self-center w-100 px-3 p-3 bg-white border-0"
                  />
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-sm mb-md-0 mb-3 country-picker">
                  <CountryDropdown
                  classes='border-0'
                    value={country}
                    onChange={(val) => handleCountryChange(val)}
                  ></CountryDropdown>
                </div>

                <div className="col-sm">
                  <input
                    type="text"
                    name="postcode"
                    placeholder="Postcode *"
                    value={postcode}
                    onChange={(e) => updatePostcode(e.target.value)}
                    className="rounded-pill align-self-center w-100 px-3 p-3 bg-white border-0"
                  />
                </div>
              </div>

              {
                addressSuggestions.length ?
                  <div className="row mt-5">
                    <label className='position-relative'>
                      {addressLoader ?
                        <AddressLoader /> : <></>}
                      <select className='address-suggestion-picker' onChange={(e) => updateAddress(e.target.value)}>
                        {addressSuggestions.map((address, index) => <option key={index} value={address}>{address}</option>)}
                      </select>

                    </label>
                  </div> : <></>
              }



              <div className="row mt-5">
                <div className="col-md-6 mb-3">
                  <label className='position-relative w-100'>
                    {addressLoader ?
                      <AddressLoader /> : <></>}
                    <input
                      type="text"
                      name="address"
                      placeholder="Address 1 *"
                      value={address}
                      onChange={(e) => SetAddress(e.target.value)}
                      className="rounded-pill align-self-center w-100 px-3 p-3 bg-white border-0"
                    />
                  </label>
                </div>

                <div className="col-md-6 mb-md-0 mb-3 ">
                  <label className='position-relative w-100'>
                    {addressLoader ?
                      <AddressLoader /> : <></>}
                    <input
                      type="text"
                      name="address2"
                      placeholder="Town / City ( Optional )"
                      value={address2}
                      onChange={(e) => setAddress2(e.target.value)}
                      className="rounded-pill align-self-center w-100 px-3 p-3 bg-white border-0"
                    />
                  </label>
                </div>



              </div>

              <div className="row mt-5">
                <div className="col-6 mb-md-0 mb-3">
                  {/* <input type="text" name="date of birth" onFocus={(e)=>{e.currentTarget.type = 'date';e.currentTarget.focus()}} onTouch={(e)=>{e.currentTarget.type = 'date';e.currentTarget.focus()}}   placeholder="Date Of Birth" value={dob} onChange={(e)=>SetDOB(e.target.value)} className='rounded-pill align-self-center w-100 px-3 p-3 bg-white border-0' /> */}
                  <input
                    placeholder="Date of birth (DD/MM/YYYY)*"
                    type="text"
                    onFocus={(e) => {
                      e.currentTarget.type = 'date';
                      e.currentTarget.focus();
                    }}
                    onInput={(event) => {
                      SetDOB(event.target.value, event);
                      dateSet(event.target.value, event);
                    }}
                    name="date of birth"
                    className="rounded-pill align-self-center w-100 px-3 p-3 bg-white border-0"
                  />
                </div>
                <div className="col-sm mb-md-0 mb-3">

                  <PhoneInput
                    placeholder="mobile_number"
                    defaultCountry="GB"
                    countryCallingCodeEditable={false}
                    international
                    value={`${mobile}`}
                    name="mobile_number"
                    className="rounded-pill align-self-center w-100 px-3 p-3 bg-white border-0 phone-number-input"
                    onChange={(e) => SetMobileNumber(`${e}`)}
                  />
                </div>

                <hr className="px-5 mt-5 color-grey" style={{ opacity: '0.5' }} />
              </div>
              <div className="pt-5 d-flex justify-content-center">
                <button
                  className="btn pfw-button-red rounded-pill w-50 px-3 p-2 fs-5 fw-bold"
                  type="button"
                  name="button"
                  onClick={userRegisterHandler}
                >
                  Sign Up
                </button>
              </div>
              <div className="d-flex justify-content-center">
                <div className="pfw-awesome-divider w-50" data-label="or"></div>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  onClick={handleOAuthSignUp}
                  className="btn pfw-button-blue google-register rounded-pill d-flex align-items-center justify-content-center w-50 px-3 py-2 fs-5 fw-bold position-relative"
                >
                  <div
                    className="position-absolute d-flex align-items-center justify-content-center"
                    style={{
                      width: '40px', // Increase the width for the circular part (20% larger)
                      height: '40px', // Increase the height for the circular part (20% larger)
                      background: 'white',
                      borderRadius: '50%', // Make it round
                      border: '2px solid #4285F4', // Blue border color
                      left: '20%', // Center the logo horizontally
                      transform: 'translateX(-50%)' // Move the logo to the center
                    }}
                  >
                    <img
                      src={require('../assets/images/google_logo.png')}
                      alt="Google"
                      style={{ height: '85%', width: '85%', objectFit: 'contain' }}
                    />
                  </div>
                  <span>Sign up with Google</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <SiteFooter />
    </>
  );
}

import {React,useState} from 'react'
import Siteheader from '../components/header';
import SiteFooter from '../components/footer';
import { Link } from 'react-router-dom';
import HostSlider from '../components/hostSlider';


export default function Host2() {
  const [range1, setrange1] = useState(5);
  const [range2, setrange2] = useState("Monthly");
  const [range3, setrange3] = useState(100);

 function handleChange(myvalue) {
  setrange1(myvalue );
  }
  function handleChange2(myvalue) {
    if(myvalue == 1){
      setrange2('Daily' );
    }
    if(myvalue == 2){
      setrange2('Weekly' );
    }
    if(myvalue == 3){
      setrange2('Fortnightly' );
    }
    if(myvalue == 4){
      setrange2('Monthly' );
    }
    if(myvalue == 5){
      setrange2('Quarterly' );
    }
    if(myvalue == 6){
      setrange2('Annually' );
    }
  }
  function handleChange3(myvalue) {
  setrange3(myvalue );
  }
        
  return (
    <>
    <Siteheader/>
    <section className='container background-image rounded-3 position-relative p-5 ' style={{backgroundImage: 'url(https://playfundwin.com/wp-content/uploads/2021/03/Group_1466.jpg)'}}>
      <div className="container  " >
        <div className='row p-1 '>
          <div className='col-md-6'>

            <div className='container  bg-f2 w-75 rounded-3 m-5'>
            <h2 className='branding1 fs-3 pt-5 pb-3 '>How much <span className='fw-bold'>could you raise?</span> </h2>
            <p className='text-muted'>Use our simple calculator to forecast how much your good cause could raise based on the 50:50 split.*</p>
            
            <div><div className='row'>
            <p className=' branding2  col-md-9 fw-bold'>Ticket Price</p>
            <p className=' branding1 fs-4 col-md-3 fw-semibold'>£{range1}</p>
            </div>
            <div className="range">
              <input type="range" min="2" max="20" defaultValue={range1}  onChange={(event) => handleChange(event.target.value)} style={{accentColor: '#2ecc71'}} className="form-range" id="customRange1" />
            </div>
            <div className='row'>
            <p className='text-muted col-md-9 '>£2</p>
            <p className='text-muted col-md-3 '>£20</p>
            </div></div>
            
            <div><div className='row d-flex '>
            <p className=' branding2  col-md-8  fw-bold'>Draw Frequency</p>
            <p className=' branding1 fs-4 col-md-4 fw-semibold'>{range2}</p>
            </div>
            <div className="range">
              <input type="range" min="1" max="6" defaultValue={4} onChange={(event) => handleChange2(event.target.value)} style={{accentColor: '#2ecc71'}} className="form-range" id="customRange2" />
            </div>
            <div className='row'>
            <p className='text-muted col-md-9 '>Daily</p>
            <p className='text-muted col-md-3 '>Annually</p>
            </div></div>

            <div><div className='row'>
            <p className=' branding2  col-md-9  fw-bold'>Number of players</p>
            <p className=' branding1 fs-4 col-md-3 fw-semibold'>{range3}</p>
            </div>
            <div className="range">
              <input type="range" min="100" max="5000" defaultValue={range3}  onChange={(event) => handleChange3(event.target.value)} style={{accentColor: '#2ecc71'}} className="form-range" id="customRange1" />
            </div>
            <div className='row'>
            <p className='text-muted col-md-9 '>100</p>
            <p className='text-muted col-md-3 '>5,000</p>
            </div></div>


            <div>
              <div className='row text-center '>
                  <div className='col-md-6 bg-light pt-0 m-0 border-3 border-end'>
                    <p className=' branding3 fs-6 fw-bold'>Annual amount raised for cause 42.5% of the total amount</p>
                    <p className=' branding3 fs-4 fw-bolder'>£14,025</p>
                  </div>
                  <div className='col-md-6 bg-light pt-0 m-0'>
                    <p className=' branding3 fs-6 fw-bold '>Annual amount raised for cash prizes 42.5% of the total amount</p>
                    <p className=' branding3 fs-4 fw-bolder'>£14,025</p>

                  </div>
              </div> 
            </div>


            </div>
          </div>


          <div className='col-md-6'>
            <div className='container pt-5'>
              <div className='row pt-5'>
                <h1 className=' fw-bolder color-white'>Host A Draw</h1>
              </div>
              <div className='row pt-2'>
                <p className='fs-5 fw-semibold branding2'>The revolutionary digital fundraising platform that makes raising money simple for sports clubs, events and charities.</p>
              </div>
              <div className='row pt-2'>
                <p className='fs-5 fw-semibold color-white'>Play Fund Win make fundraising easy by taking the hassle out of licensing, allowing sports clubs of all sizes to raise money easily.</p>
              </div>
              <div className='row pt-2'>
                <p className='fs-5 fw-semibold color-white'>Our draws ensure a minimum of half of the net proceeds go to good causes, with the remainder won as a cash prize, plus our platform encourages subscriptions so sports clubs can create regular income.</p>
              </div>

              <div className='row pt-2'>
                <div className='col'>
                <a className='text-decoration-none fw-bolder fs-5 btn rounded-pill p-3 px-5 pfw-button-yellow' href='https://form.asana.com/?k=hIBsTlHsnt3PfgTp272bIg&d=1201941169976464'>Setup a draw</a>
                </div>

                <div className='col'>
                <Link to={'help-centre'} className='text-decoration-none fw-bolder fs-5 btn rounded-pill p-3 px-5 pfw-button-red'>Ask A Question</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='pb-5'>
          <h1 className='pb-5 color-white text-center '>Who can host <span className='fw-bolder'>Play Fund Win </span>draws?</h1>
        </div>

        {/* Slider */}
        <div className='pb-5'></div><div className='pb-5'></div>
      <div className=' position-absolute start-0' style={{top:'83%'}} >
      <HostSlider/>
      </div>
      </div>
    </section>

    <div className='py-5'></div><div className='py-5'></div><div className='py-5'></div>

    <section >
      <div className='container'>
      <div className='py-2 my-2 text-center row justify-content-center'  align="center">
        <h1 className='branding1'>The benefits of <span className='fw-bolder'>Play Fund Win</span> draws</h1>
        <p align="center" className='text-muted fw-semibold w-50 p-3 px-5 col-xs-1'>Any organisation can stage Play Fund Win draws, providing the proceeds are not being used for personal or commercial gain.</p>
      </div>
      </div>

      <div className='container'>
        <div className='row py-3' align="center">
          <div className='col-sm-4'>
            <img src="https://playfundwin.com/wp-content/uploads/2021/04/Group-794.png" alt="host page image" />
            <h5 className='branding1 p-3'>Raise funds</h5>
            <p className='w-75 text-muted fw-semibold'>Help to raise essential funds, with a minimum of 50% of net proceeds from all draws going directly to charities, sports clubs or other good causes – more than any other fundraising draw platform.</p>
          </div>
          <div className='col-sm-4'>
          <img src="https://playfundwin.com/wp-content/uploads/2021/04/Group-7941.png" alt="host page image" />
          <h5 className='branding1 p-3'>Free draw hosting</h5>
          <p className='w-75 text-muted fw-semibold'>It is free to host a draw, we have no hidden charges, no minimum player numbers and no set-up costs. We charge a small operating fee to cover our own costs.</p>
          </div>
          <div className='col-sm-4'>
          <img src="https://playfundwin.com/wp-content/uploads/2021/04/Group-79-1.png" alt="host page image" />
          <h5 className='branding1 p-3'>Sign-up today to create your own, bespoke draw page</h5>
          <p className='w-75 text-muted fw-semibold'>Through our simple sign-up process you can create your own draw page hosted on our platform. We’re always available via chat if you have any questions. </p>
          </div>
        </div>

        <div className='row py-3' align="center">
          <div className='col-sm-4'>
            <img src="https://playfundwin.com/wp-content/uploads/2021/04/Group-7947.png" alt="host page image" />
            <h5 className='branding1 p-3'>Marketing support</h5>
            <p className='w-75 text-muted fw-semibold'>Our team can create bespoke marketing materials to assist in promoting your fundraising draw, free of charge.</p>
          </div>
          <div className='col-sm-4'>
          <img src="https://playfundwin.com/wp-content/uploads/2021/06/telephone-100x100.png" alt="host page image" />
          <h5 className='branding1 p-3'>Easily manage communications to your entrants</h5>
          <p className='w-75 text-muted fw-semibold'>We take the hassle out of communicating with entrants by Notifying the winners and distributing the prizes for you.</p>
          </div>
          <div className='col-sm-4'>
          <img src="https://playfundwin.com/wp-content/uploads/2021/04/Group-7950.png" alt="host page image" />
          <h5 className='branding1 p-3'>Reports</h5>
          <p className='w-75 text-muted fw-semibold'>We provide detailed reporting to draw hosts, allowing them to monitor the success of their draw and associated marketing campaigns. </p>
          </div>
        </div>

        <div className='row py-3 justify-content-center'>
        <p align="center" className='text-danger fw-semibold w-50 p-3 px-5 col-xs-1'>Still have more questions? Check out our FAQs or contact us</p>
        </div>
      </div>

      <div className='container bg-branding3 p-3 rounded-3 '>
        <div className='row pt-5 '>
        <h1 className='fw-bolder py-2 text-center display-5 color-white'>Thinking of starting a draw?</h1>
        </div>
        <div className='row '>
          <div className='row justify-content-center'>
        <p align="center" className='fw-bolder p-3 w-50 text-center color-white col-md-5'>The revolutionary digital raffle platform that makes fundraising simple for sports clubs, events and charities.</p>
          </div>
          <div className='row justify-content-center  pb-5'>
            <div className='w-25 col-md-5 text-center'>
        <button className='pfw-button-white px-5 p-3 rounded-pill fw-bold'>Start a draw</button>
            </div>
        </div>
        </div>
      </div>
      
    </section>
    <SiteFooter/>
    </>
  )
}

/* eslint-disable eqeqeq */
import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import Siteheader from '../components/header';
import PrizeSlider from '../components/prizeslider';
import SiteFooter from '../components/footer';
import { MyCart, Cart } from '../components/Cart';
import { CartProvider, useCart } from 'react-use-cart';
import { GetDraftDrawBySlug } from '../Data/actionMethods';
import loading from '../assets/images/loading.gif';
import Moment from 'moment';
import CountdownTimer from '../components/CountdownTimer';
import { Helmet } from 'react-helmet';
// import { ReactSEOMetaTags } from 'react-seo-meta-tags';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import WinnerSelect from '../components/winnerSelect';
import { toast, ToastContainer } from 'react-toastify';
import ImageLoader, { LogoLoader } from '../components/imageLoader';
import PrizeSliderTemp3 from '../components/prizeSliderTemp3';
import PrizeSliderTemp3Play from '../components/prizeSliderTemp3Play';
import PrizeSliderTemp3Bottom from '../components/prizeSliderTemp3Bottom';
import LogoBottom from '../assets/images/logo/logoBottom.svg';
import PrizeSliderTemp3BottomSlide from '../components/prizeSliderTemp3Bottomslide1';

function DraftDrawPage() {
  const [Freemodal, setFreemodal] = useState(false);
  const [Fetched, setFetched] = useState(false);
  const params = useParams();
  const [ItemCount, setItemCount] = useState();
  const [AmountText, setAmountText] = useState();
  const [isSubscription, setSubscription] = useState(true);
  const { AddInCart, addItem } = useCart();
  const [modalShow, setModalShow] = useState(false);
  const [value, setValue] = useState('');
  const [drawisActive, setDrawisActive] = useState(false);
  const Navigate = useNavigate();
  const { isEmpty, items } = useCart();
  const currentURL = window.location.href;
  const [causeName, setCauseName] = useState('');
  // const [isCampaign ,setIsCampaign ] = useState(true );
  const [isCampaign, setIsCampaign] = useState(window.location.href.includes('emailCampaign=true') ? true : false);
  const [DrawDataSession, SetDrawDataSession] = useState({});
  const [drawDetail, SetDrawDetail] = useState([]);

  const handleInputChange = (value) => {};
  //const $hasPTags = '';

  const buttonRef = useRef(null);

  function isArrayStrings(arr) {
    return arr.every((item) => typeof item === 'string');
  }

  function isArrayNumbers(arr) {
    return arr.every((item) => typeof item === 'number');
  }

  useEffect(() => {
    //console.log(params);
    const DrawName = params.name;

    //console.log(window.location);
    GetDraftDrawBySlug(DrawName).then((res) => {
      if (res.data.status) {
        // res.data.content;
      }
      //console.log('response', res, res.data.content);
      // ////console.log("response", res.data.content);
      let dt = [];
      let data = { ...res.data.content };

      data.drawContent = data.drawContent.length == 0 ? {} : JSON.parse(data.drawContent);

      if (data.drawContent.length != 0) {
        data.drawContent.drawHostID = data.drawHostID;
        data.drawContent.drawHostCauseID = data.drawHostCauseID;
        data.drawContent.drawStartDate = data.drawStartDate;
        data.drawContent.drawEndDate = data.drawEndDate;
        data.drawContent.drawIsActive = data.drawIsActive;
        data.drawContent.winnerData = data.drawWinners ? data.drawWinners : [];
        data.template = data.drawContent.template;
        data.HeaderImage = data.drawContent.HeaderImage;
        Object.keys(data.drawContent).forEach((key) => {
          data[key] = data.drawContent[key];
          //console.log(key, data.drawContent[key]);
        });
      }

      //console.log('DrawData 1', data);
      SetDrawDetail(data);

      SetDrawDataSession(data);
      setFetched(true);
    });
  }, []);

  useEffect(() => {
    //console.log('DrawData 2', drawDetail);
  }, [drawDetail]);

  useEffect(() => {
    const DrawName = params.name;
    let dt = DrawDataSession;
    if (DrawDataSession != undefined) {
      //console.log('DrawData 3', DrawDataSession);
      if (DrawDataSession == undefined) {
        dt.forEach((element) => {
          //console.log(element);
          const string1 = element.slug.toLocaleLowerCase();
          const string2 = DrawName.toLocaleLowerCase();
          ////console.log('element:' +  element.slug);

          if (string1 === string2) {
            DrawDataSession = element;

            setSubscription(DrawDataSession.subscription ? true : false);
            const targetTime = new Date(DrawDataSession.drawEndDate).getTime();
            const currentTime = Date.now();
            const timeBetween = targetTime - currentTime;
            if (timeBetween < 0) {
              setDrawisActive(false);
            } else {
              setDrawisActive(true);
            }
            MyCart.ProductCost = DrawDataSession.price;

            let drawTicketLabel;

            if (Array.isArray(DrawDataSession.ticketCountLabel)) {
              // Array is already in [1, 2, 3] format, use it as is
              drawTicketLabel = DrawDataSession.ticketCountLabel;
            } else if (typeof DrawDataSession.ticketCountLabel === 'string') {
              // Array is in "1,2,3" format, split it into [1, 2, 3]
              drawTicketLabel = DrawDataSession.ticketCountLabel.split(',').map((item) => Number(item.trim()));
            } else {
              // Handle the case where ticketCountLabel is neither an array nor a string
              drawTicketLabel = [1, 3, 5, 10, 20, 50];
            }

            // let drawTicketLabel = DrawDataSession
            //     .ticketCountLabel
            //     ?  DrawDataSession.ticketCountLabel.split(",")
            //     : [1, 3, 5];
            setAmountText(drawTicketLabel[0] * DrawDataSession.price);
            let qty = drawTicketLabel[0];
            MyCart.Quantity = parseInt(qty);
          }
        });
        // if (DrawDataSession == undefined) {
        // window.location.replace("/404");
        //} else {

        // }
      } else {
        // Assuming you have the cause ID in the variable 'causeID'
        const causeID = DrawDataSession.drawHostCauseID;
        const prefixURL = 'https://api.playfundwin.com/api/v1';
        const url = `${prefixURL}/causes/${causeID}`;

        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            if (data && data.content && data.content.length > 0) {
              const causeName = data.content[0].causeName;
              setCauseName(causeName);
            } else {
              //console.log('Cause data not found.');
            }
          })
          .catch((error) => {
            console.error('Error fetching causes:', error);
          });

        setSubscription(DrawDataSession.subscription ? true : false);
        const targetTime = new Date(DrawDataSession.drawEndDate).getTime();
        const currentTime = Date.now();
        const timeBetween = targetTime - currentTime;
        if (timeBetween < 0) {
          setDrawisActive(false);
        } else {
          setDrawisActive(true);
        }
        MyCart.ProductCost = DrawDataSession.price;

        ////console.log('Count Label: ' + DrawDataSession.ticketCountLabel.split(","));

        let drawTicketLabel;

        if (Array.isArray(DrawDataSession.ticketCountLabel)) {
          // Array is already in [1, 2, 3] format, use it as is
          drawTicketLabel = DrawDataSession.ticketCountLabel;
        } else if (typeof DrawDataSession.ticketCountLabel === 'string') {
          // Array is in "1,2,3" format, split it into [1, 2, 3]
          drawTicketLabel = DrawDataSession.ticketCountLabel.split(',').map((item) => Number(item.trim()));
        } else {
          // Handle the case where ticketCountLabel is neither an array nor a string
          drawTicketLabel = [1, 3, 5, 10, 20, 50];
        }

        // let drawTicketLabel = DrawDataSession.ticketCountLabel.split(",")
        //     ? '[' +DrawDataSession.ticketCountLabel.split(",")
        //     : [1, 3, 5];
        let BundleAmounts = DrawDataSession.bundlePrice ? DrawDataSession.bundlePrice.split(',') : [5, 15, 25];
        let aboutImages = DrawDataSession.aboutImages ? DrawDataSession.aboutImages.split(',') : [];
        let qty = drawTicketLabel[0];
        MyCart.Quantity = parseInt(qty);

        setAmountText(drawTicketLabel[0] * DrawDataSession.price);
      }
    }
  }, [DrawDataSession, Fetched]);

  if (Fetched && DrawDataSession) {
    const ProductAmount = DrawDataSession.price;
    const isBundle = DrawDataSession?.isBundle ? DrawDataSession.isBundle : false;

    // //console.log('DrawDataSession.ticketCountLabel.split(",")======',DrawDataSession.ticketCountLabel.split(","));
    // let drawTicketLabel = DrawDataSession.ticketCountLabel.split(",")
    //     ?  DrawDataSession.ticketCountLabel.split(",")
    //     : [1, 3, 5];
    let drawTicketLabel;

    if (Array.isArray(DrawDataSession.ticketCountLabel)) {
      // Array is already in [1, 2, 3] format, use it as is
      drawTicketLabel = DrawDataSession.ticketCountLabel;
    } else if (typeof DrawDataSession.ticketCountLabel === 'string') {
      // Array is in "1,2,3" format, split it into [1, 2, 3]
      drawTicketLabel = DrawDataSession.ticketCountLabel.split(',').map((item) => Number(item.trim()));
    } else {
      // Handle the case where ticketCountLabel is neither an array nor a string
      drawTicketLabel = [1, 3, 5, 10, 20, 50];
    }

    let BundleAmounts = DrawDataSession.bundlePrice ? DrawDataSession.bundlePrice.split(',') : [5, 15, 25];

    let aboutImages = DrawDataSession.aboutImages ? DrawDataSession.aboutImages.split(',') : [];
    function handleClick(amount) {
      if (isBundle) {
        // Redirect to the checkout page

        //

        //console.log('Array1', drawTicketLabel);
        ////console.log("Array2", BundleAmounts);
        ////console.log("subscription bundle", amount);
        let index = drawTicketLabel.indexOf(drawTicketLabel[amount]);
        ////console.log(BundleAmounts[index]);
        // Working In Progress...
        setAmountText(BundleAmounts[index]);
        //setItemCount(drawTicketLabel[amount]);
        setItemCount(isCampaign ? Math.max(amount / ProductAmount) * 2 : drawTicketLabel[amount]);
        //MyCart.Quantity = drawTicketLabel[amount];
        MyCart.Quantity = isCampaign ? Math.max(amount / ProductAmount) * 2 : drawTicketLabel[amount];
        MyCart.Amount = BundleAmounts[index];
        MyCart.Product = DrawDataSession.drawName;
        buttonRef.current.click();
      } else {
        // buttonRef.current.click();
        setAmountText(amount);
        // ////console.log(ProductAmount);
        // ////console.log(amount / ProductAmount);
        setItemCount(isCampaign ? Math.max(amount / ProductAmount) * 2 : Math.max(amount / ProductAmount));
        MyCart.Quantity = isCampaign ? Math.max(amount / ProductAmount) * 2 : Math.max(amount / ProductAmount);
        MyCart.Amount = amount;
        MyCart.Product = DrawDataSession.drawName;
        buttonRef.current.click();
      }
    }

    function handleClicks(amount) {
      if (isBundle) {
        // Redirect to the checkout page

        //

        ////console.log("Array1", drawTicketLabel);
        ////console.log("Array2", BundleAmounts);
        ////console.log("subscription bundle", amount);
        let index = drawTicketLabel.indexOf(drawTicketLabel[amount]);
        ////console.log(BundleAmounts[index]);
        // Working In Progress...
        setAmountText(BundleAmounts[index]);
        setItemCount(drawTicketLabel[amount]);
        MyCart.Quantity = drawTicketLabel[amount];
        MyCart.Amount = BundleAmounts[index];
        MyCart.Product = DrawDataSession.drawName;
        // buttonRef.current.click();
      } else {
        // buttonRef.current.click();
        setAmountText(amount);
        // ////console.log(ProductAmount);
        // ////console.log(amount / ProductAmount);
        setItemCount(isCampaign ? Math.max(amount / ProductAmount) * 2 : Math.max(amount / ProductAmount));
        MyCart.Quantity = isCampaign ? Math.max(amount / ProductAmount) * 2 : Math.max(amount / ProductAmount);
        MyCart.Amount = amount;
        MyCart.Product = DrawDataSession.drawName;
        //  buttonRef.current.click();
      }
    }

    const WaitForProcess = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    function updateSubscription() {
      setSubscription(!isSubscription);
    }
    async function BuyNow(data) {
      // ////console.log("buynow", data);
      const finalData = [];
      finalData.id = data.drawID;
      finalData.name = data.name;
      finalData.price = isCampaign ? data.price / 2 : data.price;
      finalData.quantity = isCampaign ? data.quantity * 2 : data.quantity;
      finalData.isCampaign = isCampaign;
      finalData.slug = data.slug;
      finalData.drawID = data.drawID;
      finalData.drawHostID = data.drawHostID;
      finalData.drawHostCauseID = data.drawHostCauseID;
      finalData.subscription = isSubscription;
      finalData.Drawlogo = data.logoUrl;
      finalData.drawEndDate = data.drawEndDate;
      finalData.drawStartDate = data.drawStartDate;
      finalData.DrawData = DrawDataSession;

      if (isEmpty) {
        addItem(finalData, MyCart.Quantity);
        await WaitForProcess(1);
        Navigate('/checkout');
      } else {
        let sub = items.filter((e) => e.subscription === true);
        let oneoff = items.filter((e) => e.subscription === false);
        //console.log('Oneoff:', oneoff, '\nSubs:', sub);
        if (isSubscription && oneoff.length >= 1) {
          toast.error(
            <>
              <p style={{ lineHeight: 1, marginBottom: '10px' }}>
                You already have single product in your cart please checkout them first to add subscription
              </p>
              <p style={{ lineHeight: 1, marginBottom: '10px' }}>
                Please Purchase a draw or remove from your cart{' '}
                <a className="branding3 text-decoration-none green-hover" href={'/checkout'}>
                  click here
                </a>
              </p>
            </>
          );
        } else if (!isSubscription && sub.length >= 1) {
          toast.error(
            <>
              <p style={{ lineHeight: 1, marginBottom: '10px' }}>
                You already have subscription product in your cart please checkout them first to add single product
              </p>
              <p style={{ lineHeight: 1, marginBottom: '10px' }}>
                Please Purchase a draw or remove from your cart{' '}
                <a className="branding3 text-decoration-none green-hover" href={'/checkout'}>
                  click here
                </a>
              </p>
            </>
          );
        } else {
          addItem(finalData, MyCart.Quantity);
          await WaitForProcess(1);
          Navigate('/checkout');
        }
      }
    }
    const template = DrawDataSession.template;
    const parser = new DOMParser();

    //  //console.log("drawSessionData", DrawDataSession);

    return (
      <>
        <Helmet>
          <title>{DrawDataSession.drawName} | Play Fund Win</title>
          <meta
            name="description"
            content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities."
          />
          <meta property="og:locale" content="en_GB" />
          {
          //console.log('in content area', DrawDataSession)
          }
          <meta property="og:title" content={DrawDataSession.drawName} />
          <meta property="og:description" content="" />
          {/* <meta property="og:image" itemprop="image" content={require(`../assets/images/draws/${DrawDataSession.HeaderImage}`)} /> */}
          <meta property="fb:image" itemprop="image" content="" />
          <meta property="og:type" content="article" />
          <meta name="twitter:card" value="summary_large_image" />
          <meta name="twitter:title" value={DrawDataSession.drawName} />
          <meta name="twitter:description" value="" />
          {/* <meta name="twitter:image" value={require(`../assets/images/logo/${DrawDataSession.logoUrl}`)} /> */}
        </Helmet>
        <Siteheader template={template} />
        {/* ‍ */}

        {template === 1 ? (
          <div className={`template-1 draw-id-${DrawDataSession.drawID}`}>
            <section className={`draw-header font-face-gb draw-id-${DrawDataSession.drawID}`}>
              <div className="draw-countdown-sec bg-f8 py-4">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="draw-countdown-left">
                        <p className="text-muted m-0">Draw Countdown</p>
                        <div className="draw-date branding1 display-6">
                          {Moment(DrawDataSession.drawEndDate).format('DD MMMM YYYY')}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 d-flex align-items-center justify-content-end">
                      {DrawDataSession.subscription == false ? (
                        <div className="draw-countdown text-end">
                          <div className="draw-counts branding3 display-6">
                            <CountdownTimer time={DrawDataSession.drawEndDate}></CountdownTimer>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="draw-logo-bar position-relative" style={{ zIndex: 999 }}>
                <div
                  className="colour-left-right"
                  style={{
                    background: `linear-gradient( to right, ${DrawDataSession.BrandingColor1} 50%, ${DrawDataSession.BrandingColor2} 50%)`,
                    height: 56
                  }}
                />
                <div
                  className="draw-logo mx-auto rounded-pill"
                  style={{
                    marginTop: -154,
                    width: 255,
                    height: 255,
                    background: `linear-gradient( to right, ${DrawDataSession.BrandingColor1} 50%, ${DrawDataSession.BrandingColor2} 50%)`,
                    padding: 30
                  }}
                >
                  <div className="bg-light p-3 rounded-pill w-100 h-100 draw-listing-logo">
                    {DrawDataSession.logoUrl !== '' ? (
                      <LogoLoader
                        className="rounded-circle"
                        src={DrawDataSession.logoUrl}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain'
                        }}
                      />
                    ) : (
                      // <img className="rounded-circle" src={require(`../assets/images/logo/${DrawDataSession.logoUrl}`)} style={{ width: '100%', height: '100%', objectFit: 'contain' }}></img>
                      <img
                        className="rounded-circle"
                        src={require(`../assets/images/logo/admin.png`)}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain'
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>

              {/* <div className='draw-featured-img-sec background-image position-relative d-flex flex-column align-items-center justify-content-center' style={{ backgroundImage: `url(${require('../assets/images/draws/' + DrawDataSession.HeaderImage)})`, height: 378, marginTop: -101 }}> */}
              {/* <BackgroundImageLoader src={DrawDataSession.HeaderImage} className="bg-cover bg-center h-full" /> */}
              <div
                className="draw-featured-img-sec background-image position-relative d-flex flex-column align-items-center justify-content-center"
                style={{
                  backgroundImage: `${
                    DrawDataSession.HeaderImage.includes('http')
                      ? `url(${DrawDataSession.HeaderImage})`
                      : `url(${require('../assets/images/draws/' + DrawDataSession.HeaderImage)})`
                  }`,
                  paddingTop: '4rem',
                  paddingBottom: '4rem',
                  height: '378px',
                  marginTop: '-101px',
                  textAlign: 'center'
                }}
              >
                <div
                  className="position-absolute w-100 h-100 top-0 bottom-0 start-0 end-0"
                  style={{
                    backgroundColor: `${DrawDataSession.BrandingColor1}`,
                    opacity: 0.6
                  }}
                ></div>
                <h2
                  className="text-light pb-4 font-face-gb-semibold display-5 mt-5 position-relative"
                  style={{ zIndex: 99 }}
                >
                  {DrawDataSession.drawName}
                </h2>

                <div className="social-share position-relative">
                  <div className="social-share-inner">
                    <div className="social-share-img">
                      <img src={require('../assets/images/Group-72-1.png')} className="ls-is-cached lazyloaded" />
                    </div>

                    <div className="social-share-icon d-flex gap-1" style={{ justifyContent: 'center' }}>
                      <a
                        href={`http://www.facebook.com/sharer.php?u=${window.location.href}&quote=${
                          DrawDataSession.facebookShareText
                            ? DrawDataSession.facebookShareText
                            : `I have entered the ${DrawDataSession.drawName} to raise essential funds for ${causeName}. Why not join me by buying a ticket here: ${window.location.href}. Please do get involved and share the draw with friends, family and colleagues to support this amazing cause! PlayFundWin #Fundraising`
                        }`}
                        target="_blank"
                      >
                        <img
                          className="social-share-icon-img "
                          src={require('../assets/images/Icon-awesome-facebook-square.png')}
                        />
                      </a>
                      <a
                        href={`https://twitter.com/share?url=${window.location.href}.&text=${
                          DrawDataSession.twitterShareText
                            ? DrawDataSession.twitterShareText
                            : `I just helped ${causeName} to raise essential funds by entering the ${DrawDataSession.drawName} via @playfundwin Why not join me by entering the draw here: ${window.location.href}`
                        }`}
                        target="_blank"
                      >
                        <img
                          className="social-share-icon-img "
                          src={require('../assets/images/Icon-awesome-twitter-square.png')}
                        />
                      </a>
                      <a
                        href={`https://www.linkedin.com/shareArticle?url=${window.location.href}&title=${
                          DrawDataSession.drawName
                        }&summary ${
                          DrawDataSession.linkedInShareText
                            ? DrawDataSession.linkedInShareText
                            : `I’m helping ${DrawDataSession.drawName} to raise essential funds for $${causeName}. All net proceeds will go to supporting to ${causeName}. You can join me in supporting this brilliant cause by buying a ticket here: ${window.location.href}. Please feel free to share. PlayFundWin #Fundraising&source=playfunwin.com`
                        }`}
                        target="_blank"
                      >
                        <img
                          className="social-share-icon-img "
                          src={require('../assets/images/linkedin-logo.png')}
                          style={{ width: 36 }}
                        />
                      </a>
                      <a
                        href={`https://wa.me/?text=${
                          DrawDataSession.whatsappShareText
                            ? DrawDataSession.whatsappShareText
                            : `Hi! I just entered the ${DrawDataSession.drawName} to raise essential funds for ${causeName}. Your support could make a huge difference, enter here: ${window.location.href}. Please do get involved and share the draw with friends, family and colleagues to support this amazing cause!`
                        }`}
                        target="_blank"
                      >
                        <img
                          className="social-share-icon-img "
                          src={require('../assets/images/Icon-awesome-whatsapp-square.png')}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="draw-main-section font-face-gb" style={{ backgroundColor: '#eee' }}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 py-5 pe-5 draw-col-left position-relative">
                    <div className={`pe-5 buy-tickets-wrap ${drawisActive ? '' : 'd-none'}`}>
                      <h2 className="branding1 fs-1 font-face-gb-semibold mb-4 pb-2 pt-3">Enter the Draw</h2>
                      <div
                        className="tickets-wrapper border-top pt-3"
                        style={{
                          borderColor: '#c8c8c8'
                        }}
                      >
                        <h4 className="branding1 fs-5 font-face-gb-semibold m-0">
                          How many tickets would you like to buy?
                        </h4>
                        <div className="price-per-ticket branding1" style={{ fontSize: 13 }}>
                          £{ProductAmount}{' '}
                          {DrawDataSession.subscription && isSubscription ? 'per ticket/Month' : 'per ticket'}
                        </div>
                        <ul className="ticket-qty-btns d-flex unstyled list-unstyled mb-1">
                          {drawTicketLabel.map((item) => {
                            if (MyCart.Quantity) {
                              return (
                                <li className="m-2 map" key={'ticket' + item}>
                                  <button
                                    type="button"
                                    onClick={() => handleClick(ProductAmount * item)}
                                    className={
                                      MyCart.Quantity == item
                                        ? 'p-3 pfw-button-red rounded-circle buy-ticket-button font-face-gb-bold add-ticket active'
                                        : 'p-3 pfw-button-red rounded-circle buy-ticket-button font-face-gb-bold add-ticket'
                                    }
                                    style={
                                      MyCart.Quantity == item
                                        ? {
                                            backgroundColor: `${DrawDataSession.BrandingColor2}`,
                                            width: 60,
                                            height: 60
                                          }
                                        : {
                                            backgroundColor: '#bdc5cf',
                                            width: 60,
                                            height: 60
                                          }
                                    }
                                  >
                                    {item}
                                  </button>
                                </li>
                              );
                            } else {
                              return (
                                <li className="m-2 map" key={'ticket' + item}>
                                  <button
                                    type="button"
                                    onClick={() => handleClick(ProductAmount * item)}
                                    className={
                                      'p-3 pfw-button-red rounded-circle buy-ticket-button font-face-gb-bold add-ticket'
                                    }
                                    style={
                                      MyCart.Quantity == item
                                        ? {
                                            backgroundColor: `${DrawDataSession.BrandingColor2}`,
                                            width: 60,
                                            height: 60
                                          }
                                        : item != drawTicketLabel[0]
                                        ? {
                                            backgroundColor: '#bdc5cf',
                                            width: 60,
                                            height: 60
                                          }
                                        : {
                                            backgroundColor: `${DrawDataSession.BrandingColor2}`,
                                            width: 60,
                                            height: 60
                                          }
                                    }
                                  >
                                    {item}
                                  </button>
                                </li>
                              );
                            }
                          })}
                          {/* <li className='ticket-input'>
                            <input type="number" min={1} value={MyCart.Quantity} onChange={(e) => { handleClick(ProductAmount * e.target.value) }} />
                          </li> */}
                        </ul>
                        {DrawDataSession.subscription ? (
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              onChange={updateSubscription}
                              type="checkbox"
                              id="flexSwitchCheckDefault"
                            />
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                              Tick to make a one-off entry into the next draw
                            </label>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="buy-tickets-sec d-flex justify-content-between mt-4 pt-2 pb-5">
                        <div className="total-price d-flex align-items-center border thirbutton-border rounded-pill bg-white px-4">
                          <h5 className="branding1 m-0 fs-2 me-3">Total</h5>
                          <div className="draw-price d-flex">
                            <div className="price-amount fs-2">£{AmountText}</div>
                            <div className="subscription-duration mt-3 ms-1">
                              {DrawDataSession.subscription && isSubscription ? 'per month' : ''}
                            </div>
                          </div>
                        </div>
                        {Moment() < Moment(DrawDataSession.drawStartDate) ? (
                          <button
                            onClick={() => alert('draw Not started')}
                            className="buy-now-btn border-0 rounded-pill btn pfw-button-red secbutton py-2 px-5 font-face-gb-semibold"
                            style={{
                              backgroundColor: `${DrawDataSession.BrandingColor2}`,
                              fontSize: 18
                            }}
                          >
                            {DrawDataSession.subscription && isSubscription ? 'Subscribe' : 'Buy Now'}
                          </button>
                        ) : (
                          <button
                            onClick={() => BuyNow(DrawDataSession)}
                            className="buy-now-btn border-0 rounded-pill btn pfw-button-red secbutton py-2 px-5 font-face-gb-semibold"
                            style={{
                              backgroundColor: `${DrawDataSession.BrandingColor2}`,
                              fontSize: 18
                            }}
                          >
                            {DrawDataSession.subscription && isSubscription ? 'Subscribe' : 'Buy Now'}
                          </button>
                        )}
                      </div>
                    </div>
                    <WinnerSelect
                      drawID={DrawDataSession.drawID}
                      winnerData={DrawDataSession.winnerData}
                      template="1"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 draw-col-right px-5 py-5">
                    <div className="prizestobewon text-center pt-4">
                      {/* <h3 className='fs-3 branding1'>Prizes to be won</h3> */}
                      {/* <h2 className='display-1 branding1 font-face-gb-semibold mt-3 mb-3'>£27.20</h2> */}
                      {/* <h6 className='fs-4 pb-4 mb-4' style={{ color: '#93a4ba' }}>pot increases with every entry</h6> */}
                      <h6 className="fs-1 pb-4 mb-4 branding1">Prize pot increases with every entry</h6>
                      <div
                        className="mx-auto"
                        style={{
                          backgroundColor: '#59b8b2',
                          width: 200,
                          height: 3
                        }}
                      ></div>
                    </div>
                    <div className="hihglights-draw-winner pt-5" style={{ paddingLeft: 200 }}>
                      <ul className="branding1 list-unstyled" style={{ lineHeight: 2 }}>
                        <li className="position-relative">Auto-entry into every draw</li>
                        <li className="position-relative">Guaranteed winner in every draw</li>
                        <li className="position-relative">Regular support for our cause</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="profits-bar-section font-face-gb bg-branding1 py-4">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 terms-conds">
                    <p className="m-0 text-light" style={{ fontSize: 22 }}>
                      Alternative entry routes available,{' '}
                      <Link className="text-light text-decoration-none green-hover" to={`/${DrawDataSession.termsUrl}`}>
                        click here
                      </Link>{' '}
                      for terms.
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 profits-directly-txt d-flex justify-content-end">
                    <p className="m-0 text-light event-entry-fee-txt-second position-relative" style={{ fontSize: 20 }}>
                      100% of net proceeds in this draw fund the club and our partner charities
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section className="about-draw-section font-face-gb py-5 mb-5 custopmSpaceAbout">
              <div className="container mb-5">
                <div className="about-draw-title text-center mb-5 pt-5">
                  <h2 className="branding1 display-5">
                    About <strong className="font-face-gb-semibold">The Draw</strong>
                  </h2>
                </div>
                <div className="row">
                  <div className="about-draw-left col-lg-6 col-md-6 col-sm-12 pe-5">
                    {/<p>(.*?)<\/p>/i.test(DrawDataSession.content) ? (
                      <div
                        className="draw-description text-center mx-auto pe-5"
                        style={{ width: '87%' }}
                        dangerouslySetInnerHTML={{
                          __html: `${DrawDataSession.content}`
                        }}
                      />
                    ) : (
                      <div className="draw-description text-center mx-auto pe-5" style={{ width: '87%' }}>
                        {DrawDataSession.content
                          .replace(/&nbsp;/g, '')
                          .replace(/&#10;/g, '\n')
                          .replace(/&#39;/g, "'")
                          .replace(/&lsquo;/g, "'")
                          .replace(/&rsquo;/g, "'")
                          .replace(/&#8239;/g, ' ')
                          .replace(/&lt;p&gt;/g, '')
                          .replace(/&lt;\/p&gt;/g, '\n')
                          .split('\n')
                          .map((paragraph, index) => (
                            <p key={index}>
                              {paragraph.trim()}
                              {/* 
                                                        &lsquo; &rsquo;
                                                        {index !== DrawDataSession.content.length - 1 && '.'} */}
                            </p>
                          ))}
                      </div>
                    )}
                  </div>
                  <div className="about-draw-right col-lg-6 col-md-6 col-sm-12 ps-0">
                    <div className="draw-featured-img position-relative">
                      {DrawDataSession.FeaturedUrl !== '' ? (
                        <ImageLoader src={DrawDataSession.FeaturedUrl} />
                      ) : (
                        // <img className='rounded-5 w-100' src={require(`../assets/images/draws/${DrawDataSession.FeaturedUrl}`)} />
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="container-fluid py-5 px-0 mt-5 how-it-works fon-face-gb"
              style={{ backgroundColor: '#f2f2f2' }}
            >
              <div className="container py-5">
                <h2 className="branding1 text-center font-face-gb-semibold display-4 mb-5">How it works</h2>
                <div className="row ">
                  <div className="col-md-4 px-5">
                    <div className="py-3 d-flex align-items-center justify-content-center mb-4">
                      <img src={require('../assets/images/Group-1462.webp')} />
                      <h2 className="branding1 mn-0 ms-4 font-face-gb-semibold display-4">play</h2>
                    </div>
                    <p className="text-center branding1">The {DrawDataSession.drawName}</p>
                  </div>
                  <div className="col-md-4 px-5">
                    <div className="py-3 d-flex align-items-center justify-content-center mb-4">
                      <img src={require('../assets/images/Group-1463.webp')} />
                      <h2 className="text-left branding1 mb-0 ms-4 font-face-gb-semibold display-4">fund</h2>
                    </div>
                    <p className="text-center branding1">
                      Fund a good cause with 100% net proceeds of all entry fees directly to{' '}
                      {causeName ? causeName : ''}
                      {/* {DrawDataSession.FundContent} */}
                    </p>
                  </div>
                  <div className="col-md-4 px-5">
                    <div className="py-3 d-flex align-items-center justify-content-center mb-4">
                      <img src={require('../assets/images/Group-1464.webp')} />
                      <h2 className="text-left branding1 mb-0 ms-4 font-face-gb-semibold display-4">win</h2>
                    </div>
                    <p className="text-center branding1">{DrawDataSession.WinContent}</p>
                  </div>
                </div>
              </div>
            </section>

            <section className="container-fluid cta-section-full secbutton font-face-gb position-relative">
              <div className="container py-lg-4 text-center">
                <div className="py-5">
                  <h2 className="text-white display-4 font-face-gb-semibold pt-2">
                    Want to fundraise for your Community Cause?
                  </h2>
                  <p className="text-white my-4">
                    Any good cause can a host a Play Fund Win draw, raising money for themselves or
                    <br />
                    an associated good cause they support - click below for more information
                  </p>
                  <Link
                    to="/host"
                    className="border-0 rounded-pill btn pfw-button-white py-2 px-5 my-2 mx-2 font-face-gb-semibold"
                    style={{ lineHeight: 2 }}
                  >
                    Start a draw
                  </Link>
                </div>
              </div>
            </section>
          </div>
        ) : template === 2 ? (
          <div className={`template-2 draw-id-${DrawDataSession.drawID}`}>
            {DrawDataSession.HeaderLeftImage != '' ? (
              <section className={`draw-header-template2 draw-id-${DrawDataSession.drawID}`}>
                <div
                  className="container rounded-4 background-image position-relative px-0"
                  style={{
                    backgroundImage: `${
                      DrawDataSession.HeaderImage.includes('http')
                        ? `url(${DrawDataSession.HeaderImage})`
                        : `url(${require('../assets/images/draws/' + DrawDataSession.HeaderImage)})`
                    }`,
                    paddingTop: 150
                  }}
                >
                  <div
                    className="position-absolute w-100 h-100 top-0 bottom-0 start-0 end-0 rounded-4 bg-overlay"
                    style={{
                      backgroundColor: `${DrawDataSession.BrandingColor1}`,
                      opacity: 0.7
                    }}
                  ></div>
                  <div className="row align-items-center position-relative">
                    <div className="col-lg-5 col-md-5 col-sm-5 col-6 header-top-left-sec align-middle position-relative">
                      <div className="header-left-img position-absolute" style={{ top: '-208px' }}>
                        {DrawDataSession.HeaderLeftImage != '' ? <img src={DrawDataSession.HeaderLeftImage} /> : ''}
                      </div>
                    </div>
                    <div
                      className="col-lg-7 col-md-7 col-sm-7 col-6 header-top-right-sec"
                      style={{ paddingBottom: 90 }}
                    >
                      <div className="draw-logo-template2 mb-4">
                        {DrawDataSession.logoUrl != '' ? (
                          <LogoLoader src={DrawDataSession.logoUrl} />
                        ) : (
                          <>
                            <img
                              src={require(`../assets/images/logo/admin.png`)}
                              style={{
                                width: '30%'
                              }}
                            />
                          </>
                        )}
                      </div>
                      <h2 className="draw-title text-white pt-5 pb-4 display-6">{DrawDataSession.drawName}</h2>

                      <div className="social-share position-relative d-flex mx-5  float-end">
                        <div className="social-share-inner">
                          <div className="social-share-img">
                            <img src={require('../assets/images/Group-72-1.png')} />
                          </div>
                          <div className="social-share-icon d-flex gap-1">
                            <a
                              href={`http://www.facebook.com/sharer.php?u=${window.location.href}&quote=${
                                DrawDataSession.facebookShareText
                                  ? DrawDataSession.facebookShareText
                                  : `I have entered the ${DrawDataSession.drawName} to raise essential funds for ${causeName}. Why not join me by buying a ticket here: ${window.location.href}. Please do get involved and share the draw with friends, family and colleagues to support this amazing cause! PlayFundWin #Fundraising`
                              }`}
                              target="_blank"
                            >
                              <img
                                className="social-share-icon-img "
                                src={require('../assets/images/Icon-awesome-facebook-square.png')}
                              />
                            </a>
                            <a
                              href={`https://twitter.com/share?url=${window.location.href}.&text=${
                                DrawDataSession.twitterShareText
                                  ? DrawDataSession.twitterShareText
                                  : `I just helped ${causeName} to raise essential funds by entering the ${DrawDataSession.drawName} via @playfundwin Why not join me by entering the draw here: ${window.location.href}`
                              }`}
                              target="_blank"
                            >
                              <img
                                className="social-share-icon-img "
                                src={require('../assets/images/Icon-awesome-twitter-square.png')}
                              />
                            </a>
                            <a
                              href={`https://www.linkedin.com/shareArticle?url=${window.location.href}&title=${
                                DrawDataSession.drawName
                              }&summary ${
                                DrawDataSession.linkedInShareText
                                  ? DrawDataSession.linkedInShareText
                                  : `I’m helping ${DrawDataSession.drawName} to raise essential funds for ${causeName}. All net proceeds will go to supporting to ${causeName}. You can join me in supporting this brilliant cause by buying a ticket here: ${window.location.href}. Please feel free to share. PlayFundWin #Fundraising&source=playfunwin.com`
                              }`}
                              target="_blank"
                            >
                              <img
                                className="social-share-icon-img "
                                src={require('../assets/images/linkedin-logo.png')}
                                style={{
                                  width: 36
                                }}
                              />
                            </a>
                            <a
                              href={`https://wa.me/?text=${
                                DrawDataSession.whatsappShareText
                                  ? DrawDataSession.whatsappShareText
                                  : `Hi! I just entered the ${DrawDataSession.drawName} to raise essential funds for ${causeName}. Your support could make a huge difference, enter here: ${window.location.href}. Please do get involved and share the draw with friends, family and colleagues to support this amazing cause!`
                              }`}
                              target="_blank"
                            >
                              <img
                                className="social-share-icon-img "
                                src={require('../assets/images/Icon-awesome-whatsapp-square.png')}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              <section className={`draw-header-template2-v2 draw-id-${DrawDataSession.drawID}`}>
                <div
                  className="container rounded-4 background-image position-relative px-0"
                  style={{
                    backgroundImage: `${
                      DrawDataSession.HeaderImage.includes('http')
                        ? `url(${DrawDataSession.HeaderImage})`
                        : `url(${require('../assets/images/draws/' + DrawDataSession.HeaderImage)})`
                    }`,
                    paddingTop: '4rem',
                    paddingBottom: '4rem'
                  }}
                >
                  <div
                    className="position-absolute w-100 h-100 top-0 bottom-0 start-0 end-0 rounded-4 bg-overlay"
                    style={{
                      backgroundColor: `${DrawDataSession.BrandingColor1}`,
                      opacity: 0.7
                    }}
                  ></div>
                  <div className="row align-items-center position-relative">
                    <div className="col-lg-2 col-md-2 col-sm-2 col-4">
                      <div className="draw-logo-template2">
                        {DrawDataSession.logoUrl !== '' ? (
                          <LogoLoader src={DrawDataSession.logoUrl} />
                        ) : (
                          <img src={require(`../assets/images/logo/admin.png`)} />
                        )}
                      </div>
                    </div>

                    <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                      <h2 className="draw-title text-white mb-0 display-6 ps-3">{DrawDataSession.drawName}</h2>
                    </div>

                    <div className="col-lg-2 col-md-2 col-sm-2 col-12">
                      <div className="social-share position-relative d-flex float-end">
                        <div className="social-share-inner">
                          <div className="social-share-img">
                            <img src={require('../assets/images/Group-72-1.png')} />
                          </div>

                          <div className="social-share-icon d-flex gap-1">
                            <a
                              href={`http://www.facebook.com/sharer.php?u=${window.location.href}&quote=${
                                DrawDataSession.facebookShareText
                                  ? DrawDataSession.facebookShareText
                                  : `I have entered the ${DrawDataSession.drawName} to raise essential funds for ${causeName}. Why not join me by buying a ticket here: ${window.location.href}. Please do get involved and share the draw with friends, family and colleagues to support this amazing cause! PlayFundWin #Fundraising`
                              }`}
                              target="_blank"
                            >
                              <img
                                className="social-share-icon-img "
                                src={require('../assets/images/Icon-awesome-facebook-square.png')}
                              />
                            </a>
                            <a
                              href={`https://twitter.com/share?url=${window.location.href}.&text=${
                                DrawDataSession.twitterShareText
                                  ? DrawDataSession.twitterShareText
                                  : `I just helped ${causeName} to raise essential funds by entering the ${DrawDataSession.drawName} via @playfundwin Why not join me by entering the draw here: ${window.location.href}`
                              }`}
                              target="_blank"
                            >
                              <img
                                className="social-share-icon-img "
                                src={require('../assets/images/Icon-awesome-twitter-square.png')}
                              />
                            </a>
                            <a
                              href={`https://www.linkedin.com/shareArticle?url=${window.location.href}&title=${
                                DrawDataSession.drawName
                              }&summary ${
                                DrawDataSession.linkedInShareText
                                  ? DrawDataSession.linkedInShareText
                                  : `I’m helping ${DrawDataSession.drawName} to raise essential funds for ${causeName}. All net proceeds will go to supporting to ${causeName}. You can join me in supporting this brilliant cause by buying a ticket here: ${window.location.href}. Please feel free to share. PlayFundWin #Fundraising&source=playfunwin.com`
                              }`}
                              target="_blank"
                            >
                              <img
                                className="social-share-icon-img "
                                src={require('../assets/images/linkedin-logo.png')}
                                style={{
                                  width: 36
                                }}
                              />
                            </a>
                            <a
                              href={`https://wa.me/?text=${
                                DrawDataSession.whatsappShareText
                                  ? DrawDataSession.whatsappShareText
                                  : `Hi! I just entered the ${DrawDataSession.drawName} to raise essential funds for ${causeName}. Your support could make a huge difference, enter here: ${window.location.href}. Please do get involved and share the draw with friends, family and colleagues to support this amazing cause!`
                              }`}
                              target="_blank"
                            >
                              <img
                                className="social-share-icon-img "
                                src={require('../assets/images/Icon-awesome-whatsapp-square.png')}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            <section className="draw-main-section-template2">
              <div className="container">
                <div className="row alti-palti">
                  <div className="col-lg-5 col-md-5 pt-5 draw-main-left">
                    {DrawDataSession.prizeSlider != '' &&
                    !DrawDataSession.PrizeBanner &&
                    DrawDataSession.prizeSlider.length > 1 ? (
                      <>
                        <PrizeSlider
                          prizeData={DrawDataSession.prizeSlider}
                          isPadding={DrawDataSession.HeaderLeftImage == '' ? false : true}
                        />
                      </>
                    ) : (
                      ''
                    )}
                    <div className="prizes mt-5 px-lg-5 pt-4">
                      <ul className="star-before fs-5 branding1 list-unstyled d-flex flex-column gap-3 li-star">
                        {DrawDataSession.PrizeToBewonText && DrawDataSession.PrizeToBewonText != '' ? (
                          <>
                            <h3 className="fs-3 branding1">Prizes to be won : </h3>

                            {DrawDataSession.PrizeToBewonText.map((item) => {
                              return (
                                <li
                                  key={item.id}
                                  className="position-relative"
                                  dangerouslySetInnerHTML={{
                                    __html: parser.parseFromString(`${item}`, 'text/html').body.textContent
                                  }}
                                ></li>
                              );
                            })}
                            {DrawDataSession.drawID === '702133b4-c71e-41d6-ad29-3f67be1ee09f' ? (
                              <img className="rounded-5 w-100" src={require(`../assets/images/SQUAD.jpg`)} />
                            ) : (
                              ''
                            )}
                            {
                              //////console.log('Prize Slider: ' + DrawDataSession.prizeSlider.length)
                            }

                            {DrawDataSession.prizeSlider && DrawDataSession.prizeSlider.length == 1 ? (
                              <ImageLoader
                                src={
                                  DrawDataSession.prizeSlider[0].image &&
                                  DrawDataSession.prizeSlider[0].image !=
                                    'https://s3.eu-west-2.amazonaws.com/pfw.storage.bucket/images/13bfd07d-072a-461d-93b6-c1a4139996da-money-bag.png'
                                    ? DrawDataSession.prizeSlider[0].image
                                    : DrawDataSession.PrizeBanner
                                    ? DrawDataSession.PrizeBanner
                                    : 'https://s3.eu-west-2.amazonaws.com/pfw.storage.bucket/images/13bfd07d-072a-461d-93b6-c1a4139996da-money-bag.png'
                                }
                              />
                            ) : DrawDataSession?.PrizeBanner ? (
                              <ImageLoader src={DrawDataSession.PrizeBanner} />
                            ) : (
                              ''
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-7 ps-0 pt-4 draw-main-right">
                    {drawisActive ? (
                      ''
                    ) : (
                      <h2 className="branding1 fs-1 font-face-gb-semibold mb-4 pb-2 pt-3">Draw Finished</h2>
                    )}
                    <div className={`draw-main-ticket-sec ${drawisActive ? '' : 'd-none'}`}>
                      <div className="top-title-countdown row py-lg-5 py-md-3">
                        <div className="col-lg-7 col-md-7">
                          <h2
                            className="branding1 display-5 font-face-gb-semibold mb-4"
                            style={{
                              color: DrawDataSession.BrandingColor1
                            }}
                          >
                            Enter the Draw
                          </h2>
                        </div>
                        <div className="col-lg-5 col-md-5">
                          <div className="draw-end-date">
                            <p
                              className="fs-5 m-0"
                              style={{
                                color: '#99aabe'
                              }}
                            >
                              Draw Countdown
                            </p>
                            <div
                              className="draw-date fs-4"
                              style={{
                                color: DrawDataSession.BrandingColor2
                              }}
                            >
                              {Moment(DrawDataSession.drawEndDate).format('DD MMMM YYYY')}
                            </div>
                            {DrawDataSession.subscription == false ? (
                              <div className="draw-countdown branding1 fs-4">
                                <CountdownTimer time={DrawDataSession.drawEndDate}></CountdownTimer>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="draw-ticket-sec border-2 border-start">
                        <div className="ticket-qty-wrap row pb-lg-5 mx-0 border-2 border-bottom">
                          <div className="col-md-3 px-0">
                            <h2
                              className="step-txt display-6 px-5"
                              style={{
                                color: DrawDataSession.BrandingColor1
                              }}
                            >
                              Step <br />
                              <strong className="font-face-gb-semibold display-5">01</strong>
                            </h2>
                          </div>
                          <div
                            className="col-md-9 ps-5"
                            style={{
                              borderLeft: `5px solid ${DrawDataSession.BrandingColor1}`
                            }}
                          >
                            <div className="tickets-wrapper">
                              <h4 className="branding1 fs-5 font-face-gb-semibold m-0">
                                How many tickets would you like to buy?
                              </h4>
                              <div
                                className="price-per-ticket"
                                style={{
                                  fontSize: 13,
                                  color: DrawDataSession.BrandingColor2
                                }}
                              >
                                £{ProductAmount}{' '}
                                {DrawDataSession.subscription
                                  ? isSubscription
                                    ? 'per ticket/Month'
                                    : 'per ticket'
                                  : 'per ticket'}{' '}
                              </div>
                              <ul className="ticket-qty-btns d-flex unstyled list-unstyled mt-3 mb-1">
                                {drawTicketLabel.map((item) => {
                                  if (MyCart.Quantity) {
                                    return (
                                      <li className="me-4 map" key={'ticket' + item}>
                                        <button
                                          type="button"
                                          onClick={() => handleClick(ProductAmount * item)}
                                          className={
                                            'p-3 pfw-button-red rounded-circle buy-ticket-button font-face-gb-bold add-ticket'
                                          }
                                          style={
                                            MyCart.Quantity == item
                                              ? {
                                                  backgroundColor: `${DrawDataSession.BrandingColor2}`,
                                                  width: 60,
                                                  height: 60
                                                }
                                              : {
                                                  backgroundColor: '#bdc5cf',
                                                  width: 60,
                                                  height: 60
                                                }
                                          }
                                        >
                                          {item}
                                        </button>
                                      </li>
                                    );
                                  } else {
                                    return (
                                      <li className="m-2 map" key={'ticket' + item}>
                                        <button
                                          type="button"
                                          onClick={() => handleClick(ProductAmount * item)}
                                          className={
                                            'p-3 pfw-button-red rounded-circle buy-ticket-button font-face-gb-bold add-ticket'
                                          }
                                          style={
                                            MyCart.Quantity == item
                                              ? {
                                                  backgroundColor: `${DrawDataSession.BrandingColor2}`,
                                                  width: 60,
                                                  height: 60
                                                }
                                              : item != drawTicketLabel[0]
                                              ? {
                                                  backgroundColor: '#bdc5cf',
                                                  width: 60,
                                                  height: 60
                                                }
                                              : {
                                                  backgroundColor: `${DrawDataSession.BrandingColor2}`,
                                                  width: 60,
                                                  height: 60
                                                }
                                          }
                                        >
                                          {item}
                                        </button>
                                      </li>
                                    );
                                  }
                                })}
                              </ul>
                              <p
                                className="m-0 event-entry-fee-txt-second"
                                style={{
                                  fontSize: 13,
                                  color: DrawDataSession.BrandingColor2
                                }}
                              >
                                {DrawDataSession.ProfitGoesTo}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="buy-now-wrap row py-md-5 py-1 mx-0">
                          <div className="col-md-3 px-0">
                            <h2
                              className="step-txt display-6 px-5 m-0"
                              style={{
                                color: DrawDataSession.BrandingColor1
                              }}
                            >
                              Step <br />
                              <strong className="font-face-gb-semibold display-5">02</strong>
                            </h2>
                          </div>
                          <div
                            className="col-md-9 px-5"
                            style={{
                              borderLeft: `5px solid ${DrawDataSession.BrandingColor1}`
                            }}
                          >
                            {DrawDataSession.subscription ? (
                              <div className="form-check form-switch mb-3">
                                <input
                                  className="form-check-input"
                                  onChange={updateSubscription}
                                  type="checkbox"
                                  id="flexSwitchCheckDefault"
                                />
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                                  Tick to make a one-off entry into the next draw
                                </label>
                              </div>
                            ) : (
                              ''
                            )}

                            <div className="total-price d-flex justify-content-between">
                              <div className="draw-price">
                                <h5 className="branding1 m-0 font-face-gb-semibold fs-5">Total</h5>
                                <div
                                  className="branding1 price-amount fs-2"
                                  style={{
                                    lineHeight: '1'
                                  }}
                                >
                                  £{AmountText}
                                </div>
                              </div>

                              {Moment() < Moment(DrawDataSession.drawStartDate) ? (
                                <button
                                  onClick={() => alert('Draw not started')}
                                  className="buy-now-btn border-0 rounded-pill btn pfw-button-red secbutton py-2 px-5 font-face-gb-semibold"
                                  style={{
                                    backgroundColor: `${DrawDataSession.BrandingColor2}`,
                                    fontSize: 18
                                  }}
                                >
                                  {DrawDataSession.subscription && isSubscription ? 'Subscribe' : 'Buy Now'}
                                </button>
                              ) : (
                                <button
                                  onClick={() => BuyNow(DrawDataSession)}
                                  className="buy-now-btn border-0 rounded-pill btn pfw-button-red secbutton py-2 px-5 font-face-gb-semibold"
                                  style={{
                                    backgroundColor: `${DrawDataSession.BrandingColor2}`,
                                    fontSize: 18
                                  }}
                                >
                                  {DrawDataSession.subscription && isSubscription ? 'Subscribe' : 'Buy Now'}
                                </button>
                              )}
                            </div>
                            <div className="terms-cond mt-3">
                              <p className="m-0 branding1">
                                Alternative entry routes available,{' '}
                                <Link
                                  className="text-decoration-none"
                                  to={`/${DrawDataSession.termsUrl}`}
                                  style={{
                                    color: DrawDataSession.BrandingColor2
                                  }}
                                >
                                  click here
                                </Link>{' '}
                                for terms.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <WinnerSelect
                      drawID={DrawDataSession.drawID}
                      winnerData={DrawDataSession.winnerData}
                      template="2"
                    />

                    <div className="raising-funds">
                      <div
                        className="raising-funds-for-below-price text-center fs-5 font-face-gb-semibold"
                        style={{
                          color: DrawDataSession.BrandingColor2
                        }}
                      >
                        {DrawDataSession.RaisingFundText}
                      </div>
                      <div className="raising-funds-images d-flex justify-content-center mt-4 text-center">
                        <div className="w-50 text-center">
                          {DrawDataSession.RaisingFundImage !== '' ? (
                            <img src={DrawDataSession.RaisingFundImage} />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="about-draw-section about-draw-section-template2 font-face-gb py-7 mb-5 custopmSpaceAbout">
              <div className="container mb-5">
                <div className="about-draw-title text-center mb-5 pt-5">
                  <h2 className="branding1 display-5 font-face-gb-semibold">About The Draw</h2>
                </div>
                <div className="row">
                  <div className="about-draw-left col-lg-6 col-md-6 col-sm-12 pe-5">
                    {/* {} */}
                    {/* <div
                                            className="draw-description text-center mx-auto pe-5"
                                            style={{ width: "87%" }}
                                            // dangerouslySetInnerHTML={{
                                            //     __html: `${DrawDataSession.content}`,
                                            // }}
                                        > */}
                    {
                      //$hasPTags = /<p>(.*?)<\/p>/i.test(DrawDataSession.content)
                    }
                    {/<p>(.*?)<\/p>/i.test(DrawDataSession.content) ? (
                      <div
                        className="draw-description text-center mx-auto pe-5"
                        style={{ width: '87%' }}
                        dangerouslySetInnerHTML={{
                          __html: `${DrawDataSession.content}`
                        }}
                      />
                    ) : (
                      <div className="draw-description text-center mx-auto pe-5" style={{ width: '87%' }}>
                        {DrawDataSession.content
                          .replace(/&nbsp;/g, '')
                          .replace(/&#10;/g, '\n')
                          .replace(/&#39;/g, "'")
                          .replace(/&lsquo;/g, "'")
                          .replace(/&rsquo;/g, "'")
                          .replace(/&#8239;/g, ' ')
                          .replace(/&lt;p&gt;/g, '')
                          .replace(/&lt;\/p&gt;/g, '\n')
                          .split('\n')
                          .map((paragraph, index) => (
                            <p key={index}>
                              {paragraph.trim()}
                              {/* 
                                                        &lsquo; &rsquo;
                                                        {index !== DrawDataSession.content.length - 1 && '.'} */}
                            </p>
                          ))}
                      </div>
                    )}
                    {/* </div> */}
                    {aboutImages.length >= 1 ? (
                      <div className="event-sponsors mt-4">
                        <h4 className="branding1 text-center mb-3">Our Event Sponsors</h4>
                        <div className="event-sponsors-logo px-md-0 px-4 d-flex align-items-center justify-content-center gap-4">
                          {aboutImages.map((item) => {
                            if (item) {
                              return <img src={item} />;
                            }
                          })}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="about-draw-right col-lg-6 col-md-6 col-sm-12 ps-0">
                    <div className="draw-featured-img position-relative">
                      {DrawDataSession.FeaturedUrl != '' &&
                      DrawDataSession.FeaturedVideoUrl &&
                      DrawDataSession.FeaturedVideoUrl != '' ? (
                        <>
                          <div
                            className="video-holder position-relative"
                            onClick={() => setModalShow(true)}
                            style={{
                              cursor: 'pointer'
                            }}
                          >
                            <img
                              className="position-absolute top-0 bottom-0 start-0 end-0 m-auto"
                              src={require('../assets/images/Group-8048.png')}
                              alt="Play Vide0"
                              style={{
                                width: '15%'
                              }}
                            />
                            <img className="rounded-5 w-100" src={DrawDataSession.FeaturedUrl} />
                          </div>

                          <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg" centered>
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body>
                              <iframe
                                src={DrawDataSession.FeaturedVideoUrl}
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                                width={'100%'}
                                height={'400px'}
                              ></iframe>
                            </Modal.Body>
                          </Modal>
                        </>
                      ) : (
                        ''
                      )}
                      {!DrawDataSession.FeaturedVideoUrl && DrawDataSession.FeaturedUrl != '' ? (
                        <ImageLoader src={DrawDataSession.FeaturedUrl} />
                      ) : (
                        // <img className='rounded-5 w-100' src={require(`../assets/images/draws/${DrawDataSession.FeaturedUrl}`)} />

                        ''
                      )}
                      {DrawDataSession.LeftAboutusImage && DrawDataSession.RightAboutusImage != '' ? (
                        <div className="row pt-5">
                          <div className="col-md-6 p-2">
                            <ImageLoader src={DrawDataSession.LeftAboutusImage} />
                          </div>
                          <div className="col-md-6 p-2">
                            <ImageLoader src={DrawDataSession.RightAboutusImage} />
                          </div>
                        </div>
                      ) : (
                        // <img className='rounded-5 w-100' src={require(`../assets/images/draws/${DrawDataSession.FeaturedUrl}`)} />

                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {DrawDataSession.BottomLeftContent || DrawDataSession.BottomRightContent ? (
              <section className="bottom-section font-face-gb py-5 mb-5 custopmSpaceAbout">
                <div className="container mb-5">
                  <div className="row">
                    <div className="about-draw-left col-lg-6 col-md-6 col-sm-12 pe-5">
                      {DrawDataSession.BottomLeftContent ? (
                        <div
                          className="draw-description mx-auto pe-5 star-before"
                          dangerouslySetInnerHTML={{
                            __html: parser.parseFromString(`${DrawDataSession.BottomLeftContent}`, 'text/html').body
                              .textContent
                          }}
                        ></div>
                      ) : null}
                    </div>
                    <div className="about-draw-right col-lg-6 col-md-6 col-sm-12 ps-0">
                      {DrawDataSession.BottomRightContent ? (
                        <div
                          className="draw-description mx-auto pe-5 star-before"
                          dangerouslySetInnerHTML={{
                            __html: parser.parseFromString(`${DrawDataSession.BottomRightContent}`, 'text/html').body
                              .textContent
                          }}
                        ></div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              ''
            )}

            <section
              className="container-fluid py-5 px-0 mt-5 how-it-works draw-template fon-face-gb"
              style={{
                backgroundColor: `${DrawDataSession.BrandingColor1}`
              }}
            >
              <div className="container py-5">
                {/* {`template-2 draw-id-${DrawDataSession.drawID}`} */}
                <h2
                  className={`hiw-title ${
                    DrawDataSession.HowitWorksColor ? DrawDataSession.HowitWorksColor : `branding1`
                  } text-center font-face-gb-semibold display-4 mb-5`}
                  style={{ color: `${DrawDataSession.HowitWorksColor}` }}
                >
                  How it works
                </h2>
                <div className="row ">
                  <div className="col-md-4 px-5">
                    <div className="py-3 d-flex align-items-center justify-content-center mb-4">
                      <img src={require('../assets/images/Group-1462.webp')} />
                      <h2
                        className={`${
                          DrawDataSession.HowitWorksColor ? DrawDataSession.HowitWorksColor : `branding1`
                        } mn-0 ms-4 font-face-gb-semibold display-4`}
                        style={{ color: `${DrawDataSession.HowitWorksColor}` }}
                      >
                        play
                      </h2>
                    </div>
                    <p
                      className={`text-center ${
                        DrawDataSession.HowitWorksColor ? DrawDataSession.HowitWorksColor : `branding1`
                      }`}
                      style={{ color: `${DrawDataSession.HowitWorksColor}` }}
                    >
                      The {DrawDataSession.drawName}
                    </p>
                  </div>
                  <div className="col-md-4 px-5">
                    <div className="py-3 d-flex align-items-center justify-content-center mb-4">
                      <img src={require('../assets/images/Group-1463.webp')} />
                      <h2
                        className={`text-left ${
                          DrawDataSession.HowitWorksColor ? DrawDataSession.HowitWorksColor : `branding1`
                        } mb-0 ms-4 font-face-gb-semibold display-4`}
                        style={{ color: `${DrawDataSession.HowitWorksColor}` }}
                      >
                        fund
                      </h2>
                    </div>
                    <p
                      className={`text-center ${
                        DrawDataSession.HowitWorksColor ? DrawDataSession.HowitWorksColor : `branding1`
                      }`}
                      style={{ color: `${DrawDataSession.HowitWorksColor}` }}
                    >
                      {/* {DrawDataSession.FundContent} */}
                      Fund a good cause with 100% net proceeds of all entry fees directly to{' '}
                      {causeName ? causeName : ''}
                    </p>
                  </div>
                  <div className="col-md-4 px-5">
                    <div className="py-3 d-flex align-items-center justify-content-center mb-4">
                      <img src={require('../assets/images/Group-1464.webp')} />
                      <h2
                        className={`text-left ${
                          DrawDataSession.HowitWorksColor ? DrawDataSession.HowitWorksColor : `branding1`
                        } mb-0 ms-4 font-face-gb-semibold display-4`}
                        style={{ color: `${DrawDataSession.HowitWorksColor}` }}
                      >
                        win
                      </h2>
                    </div>
                    <p
                      className={`text-center ${
                        DrawDataSession.HowitWorksColor ? DrawDataSession.HowitWorksColor : `branding1`
                      }`}
                      style={{ color: `${DrawDataSession.HowitWorksColor}` }}
                    >
                      {DrawDataSession.WinContent}
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="container-fluid cta-section-full secbutton font-face-gb position-relative"
              style={{
                backgroundColor: `${DrawDataSession.BrandingColor2}`
              }}
            >
              <div className="container py-lg-4 text-center">
                <div className="py-5">
                  <h2 className="text-white display-4 font-face-gb-semibold pt-2">
                    Want to fundraise for your Community Cause?
                  </h2>
                  <p className="text-white my-4">
                    Any good cause can a host a Play Fund Win draw, raising money for themselves or
                    <br />
                    an associated good cause they support - click below for more information
                  </p>
                  <Link
                    to="/host"
                    className="border-0 rounded-pill btn pfw-button-white py-2 px-5 my-2 mx-2 font-face-gb-semibold"
                    style={{ lineHeight: 2 }}
                  >
                    Start a draw
                  </Link>
                </div>
              </div>
            </section>
          </div>
        ) : template === 3 ? (
          <div className={`template-3 draw-id-${DrawDataSession.drawID}`}>
            {/* <section className='header-top bg-branding3 text-center p-2'>
                <p className='text-white fs-4 p-0 m-0'>New cash & club prizes to be won every month. Winners informed via email</p>
                </section>           */}
            <section
              className="hero-section herosec-img font-face-sh py-sm-"
              style={{
                marginTop: '0px',
                padding: '100px 0 200px',
                backgroundImage: `linear-gradient(to top, #0e3160, rgba(14, 49, 96, 0.79)) , ${
                  DrawDataSession.HeaderImage.includes('http')
                    ? `url(${DrawDataSession.HeaderImage})`
                    : `url(${require('../assets/images/draws/' + DrawDataSession.HeaderImage)})`
                }`,
                backgroundSize: 'cover'
              }}
            >
              <div className="container">
                <div className="row titles">
                  <div className="col-sm-7 col-md-7 col-lg-7 text-left col-sm-12">
                    <img className="mb-3" src={require('../assets/images/branding.png')} />
                    {DrawDataSession.drawID === 'd97834e5-159f-4ffb-9ea2-6382854dfe71' ? (
                      <h4 className="text-white font-face-sh-bold display-3" style={{ fontWeight: 'bold' }}>
                        <strong className="color-yellow">WIN </strong>a once in a lifetime experience !
                      </h4>
                    ) : (
                      <h4 className="branding2 font-face-sh-bold fs-3">
                        <strong>WIN BIG WITH PLAY FUND WIN</strong>
                      </h4>
                    )}
                    <h2 className="text-white title-managed position-relative lh-4 w-auto mt-4 mb-0 display-4 font-face-sh-bold text-uppercase">
                      <strong className="strokes">{DrawDataSession.HeaderTitleTemp}</strong>
                    </h2>
                    {/* <h1 className="text-white title-managed position-relative lh-4 w-auto mt-4 mb-0 display-4 font-face-sh-bold text-uppercase" style={{ zIndex: 9, paddingBottom: 20 }}>{DrawDataSession.drawName}</h1> */}
                    <h1
                      className="text-white position-relative lh-4 w-auto mb-0 display-2 font-face-sh-bold text-uppercase"
                      style={{
                        zIndex: 9,
                        paddingBottom: 20
                      }}
                    >
                      {DrawDataSession.HeaderSubTemp}
                    </h1>

                    {DrawDataSession.drawID === 'd38d1b52-4880-4754-a628-394feeb187aa' ||
                    DrawDataSession.drawID === 'e8400ab7-1dc8-4103-af43-3778ab40593f' ? (
                      <p className="text-white fs-5">
                        Winner Announced {DrawDataSession.MatchDate}{' '}
                        <img
                          className="mb-0"
                          style={{
                            transform: 'rotate(90deg)',
                            width: '18px'
                          }}
                          src={require('../assets/images/branding.png')}
                        />
                      </p>
                    ) : (
                      <p className="text-white fs-5">
                        Winner Announced{' '}
                        <strong style={{ marginRight: 10 }}>
                          {new Date(DrawDataSession.drawEndDate).toLocaleString('en-GB', {
                            weekday: 'long',
                            day: '2-digit',
                            month: 'short',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: '2-digit',
                            hour12: true
                          })}
                        </strong>{' '}
                        <img
                          className="mb-0"
                          style={{
                            transform: 'rotate(90deg)',
                            width: '18px'
                          }}
                          src={require('../assets/images/branding.png')}
                        />
                      </p>
                    )}

                    <div className="border-bottom mb-4 w-75 branding2"></div>

                    {DrawDataSession.drawID === 'efb3ab56-172c-4ecc-a3a5-0d521aec76c6' ? (
                      <>
                        <p className="fs-1 text-white">
                          <span className="color-yellow">WIN</span> a VIP Experience for{' '}
                          {DrawDataSession.drawID === '9c25d3a4-763b-40e3-bffd-681bcad8e7c6'
                            ? DrawDataSession.HeaderSubTempBottom
                            : DrawDataSession.HeaderSubTemp}{' '}
                          on {DrawDataSession.MatchDate}
                        </p>

                        <div className="row py-3">
                          <div className="col-md-3">
                            <div className="supprts-logo text-center">
                              <img className="mx-auto w-100" src={require('../assets/images/harrogateHospital.png')} />
                            </div>
                          </div>
                          <div
                            className="col-md-9 support-logos"
                            style={{
                              paddingRight: 40,
                              paddingLeft: 0
                            }}
                          >
                            <p className="fs-5 text-white">{DrawDataSession.proceedgoesto}</p>
                          </div>
                        </div>
                      </>
                    ) : DrawDataSession.drawID === '9c25d3a4-763b-40e3-bffd-681bcad8e7c6' ? (
                      <>
                        <p className="fs-1 text-white">
                          <span className="color-yellow">WIN</span> a VIP Experience for{' '}
                          {DrawDataSession.drawID === '9c25d3a4-763b-40e3-bffd-681bcad8e7c6'
                            ? DrawDataSession.HeaderSubTempBottom
                            : DrawDataSession.HeaderSubTemp}{' '}
                          on {DrawDataSession.MatchDate}
                        </p>

                        <div className="row py-3">
                          <div className="col-md-3">
                            <div className="supprts-logo text-center">
                              <img className="mx-auto w-100" src={require('../assets/images/harrogateHospital.png')} />
                            </div>
                          </div>
                          <div
                            className="col-md-9 support-logos"
                            style={{
                              paddingRight: 40,
                              paddingLeft: 0
                            }}
                          >
                            <p className="fs-5 text-white">{DrawDataSession.proceedgoesto}</p>
                          </div>
                        </div>
                      </>
                    ) : DrawDataSession.DrawManageType === '2' ? (
                      <>
                        <p className="fs-1 text-white">
                          <span className="color-yellow">WIN</span> <span className="yellow-a">a</span>{' '}
                          {DrawDataSession.ShortTitle}
                        </p>

                        <div className="row py-3 align-items-center">
                          {DrawDataSession.proceedgoesto ? (
                            <div className="col-md-3">
                              <div className="supprts-logo text-center">
                                <ImageLoader
                                  className="mx-auto"
                                  style={{
                                    height: '100px'
                                  }}
                                  src={DrawDataSession.CharityImage}
                                />
                              </div>
                            </div>
                          ) : (
                            // <img className='mx-auto w-100' src={require('../assets/images/harrogateHospital.png')}  />
                            <></>
                          )}

                          <div
                            className="col-md-9 support-logos px-3"
                            style={{
                              paddingRight: 40,
                              paddingLeft: 0
                            }}
                          >
                            <p className="fs-5 text-white">{DrawDataSession.proceedgoesto}</p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <p className="fs-5 text-white">
                        Money raised will be donated to the Grassroots clubs supported by Play Fund Win
                      </p>
                    )}
                    {/* <h3 className="fs-5 branding-2 bg-white lh-2 p-3 fw-normal mr-0" style={{ width: '50%', margin: '0 auto', marginTop: -20, zIndex: 99, position: 'relative' }}>{DrawDataSession.ShortTitle}</h3> */}
                  </div>
                  <div
                    className="prizes-sliders mb-5 pb-3 col-sm-12 col-md-5 col-lg-5 position-relative"
                    style={{ zIndex: 9, marginTop: -20 }}
                  >
                    {/* <PrizeSlider/> */}
                    {DrawDataSession.prizeSlider != '' ? (
                      <>
                        {/* <div className='prizes-section-data text-center mb-4 d-flex border-white border-bottom rounded-top' style={{ justifyContent: 'center' }}>
                            <div className='prizes-section-subdata'>
                              <h3 className="w-100 fs-5 lh-2 p-3 fw-normal mb-0 mr-0 mt-5 branding-1 bg-white" style={{ borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>VIP Package Includes</h3>
                            </div>
                          </div> */}

                        {DrawDataSession.DrawManageType === '2' ? (
                          <div className="fixtures">
                            <div
                              className="team-fixtures bg-white py-2 px-4"
                              style={{
                                borderTopLeftRadius: 20,
                                borderTopRightRadius: 20,
                                marginTop: 0
                              }}
                            >
                              <div className="row align-items-center">
                                <div className="col-md-2 text-center p-3 left-logo">
                                  <img className="start-0 w-100" alt="Favicon" src={DrawDataSession.CharityImage} />
                                </div>
                                <div className="col-md-10 text-left">
                                  <div className="col-md-12 text-left">
                                    <h4 className="branding1">{DrawDataSession.proceedgoesto}</h4>
                                  </div>
                                  {/* <p className="branding1 fs-4">Winner next announced</p> */}
                                  {/* <div className="row" style={{ alignItems: 'center', marginTop: 30, marginBottom: 10 , borderBottom : '1px solid #eee' , paddingBottom : 10 }}>
                                
                                <div className="col-md-5 text-right"><h2 className="branding2 lh-1 font-face-sh-bold text-right" style={{ textAlign: 'right', color: '#2756ae' }}>{DrawDataSession.FixtureleftTeam}</h2></div>
                                
                                <div className="col-md-3 text-center"><h2 className="branding3 lh-1 font-face-sh-bold">VS</h2>
                              </div><div className="col-md-4 text-center"><h2 className="branding2 lh-1 font-face-sh-bold text-left" style={{ textAlign: 'center', color: '#e50027' }}>{DrawDataSession.FixturerightTeam}</h2></div> 


                              </div>*/}
                                  {/* <p className="branding3 fs-4">VIP Package prizes Include:</p> */}
                                </div>
                                {/* <div className="col-md-2 text-center p-3 right-logo" style={{ borderLeft : '1px solid #eee' }}><img className="start-0 w-100" alt="vsLogo" src={DrawDataSession.FixturerightImage} /></div> */}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="fixtures">
                            <div
                              className="team-fixtures bg-white py-2 px-4"
                              style={{
                                borderTopLeftRadius: 20,
                                borderTopRightRadius: 20,
                                marginTop: 0
                              }}
                            >
                              <div className="row align-items-center">
                                <div
                                  className="col-md-2 text-center p-3 left-logo"
                                  style={{
                                    borderRight: '1px solid #eee'
                                  }}
                                >
                                  <img className="start-0 w-100" alt="Favicon" src={DrawDataSession.FixtureleftImage} />
                                </div>
                                <div className="col-md-8 text-center">
                                  {/* <p className="branding1 fs-4">Winner next announced</p> */}
                                  <div
                                    className="row"
                                    style={{
                                      alignItems: 'center',
                                      marginTop: 30,
                                      marginBottom: 10,
                                      borderBottom: '1px solid #eee',
                                      paddingBottom: 10
                                    }}
                                  >
                                    <div className="col-md-5 text-right">
                                      <h2
                                        className="branding2 lh-1 font-face-sh-bold text-right"
                                        style={{
                                          textAlign: 'right',
                                          color: '#2756ae'
                                        }}
                                      >
                                        {DrawDataSession.FixtureleftTeam}
                                      </h2>
                                    </div>
                                    <div className="col-md-3 text-center">
                                      <h2 className="branding3 lh-1 font-face-sh-bold">VS</h2>
                                    </div>
                                    <div className="col-md-4 text-center">
                                      <h2
                                        className="branding2 lh-1 font-face-sh-bold text-left"
                                        style={{
                                          textAlign: 'center',
                                          color: '#e50027'
                                        }}
                                      >
                                        {DrawDataSession.FixturerightTeam}
                                      </h2>
                                    </div>
                                  </div>
                                  <p className="branding3 fs-4">VIP Package prizes Include:</p>
                                </div>
                                <div
                                  className="col-md-2 text-center p-3 right-logo"
                                  style={{
                                    borderLeft: '1px solid #eee'
                                  }}
                                >
                                  <img className="start-0 w-100" alt="vsLogo" src={DrawDataSession.FixturerightImage} />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* <pre>{JSON.stringify(DrawDataSession.prizeSlider)}</pre> */}
                        {/* {DrawDataSession.prizeName} */}
                        {DrawDataSession.topPrizeSlider ? (
                          <PrizeSliderTemp3 className="" prizeData={DrawDataSession.prizeSlider} />
                        ) : (
                          <PrizeSliderTemp3Play className="" prizeData={DrawDataSession.prizeSlider} />
                        )}
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </section>

            <section className="ticket-data" style={{ marginTop: -210, paddingBottom: 10 }}>
              <div className="container">
                <div className="ticket-section pb-5">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="py-1 d-flex align-items-center justify-content-center mb-4">
                        <div
                          className="border-bottom mb-4"
                          style={{
                            width: '40%',
                            margin: '20px 20px'
                          }}
                        ></div>
                        <img src={require('../assets/images/playicon.png')} width="24px" />
                        <h5 className="mt-2 mx-3">
                          <span className="position-relative fs-5 branding-4">ENTER THE DRAW</span>
                        </h5>
                        <div
                          className="border-bottom mb-4"
                          style={{
                            width: '40%',
                            margin: '20px 10px'
                          }}
                        ></div>
                      </div>
                    </div>
                    {/* <div className='col-md-5'>
                          {drawisActive ? "" :
                            <h2 className="branding1 fs-1 font-face-gb-semibold mb-4 pb-2 pt-3">Draw Finished</h2>}
                          <div className={`draw-main-ticket-sec top-title-countdown row ${drawisActive ? "" : "d-none"}`}>
                            <div className='col-lg-7 col-md-7'>
                              {/* <h2 className="branding1 display-5 font-face-gb-semibold mb-4" style={{ color: DrawDataSession.BrandingColor1 }}>Enter the Draw</h2> 
                            </div>
                            <div className='col-lg-5 col-md-5'>
                              <div className="draw-end-date">
                                <p className='fs-5 m-0' style={{ color: '#99aabe' }}>Draw Countdown</p>
                                <div className="draw-date fs-4 branding3">{Moment(DrawDataSession.drawEndDate).format('DD MMMM YYYY')}</div>
                                {DrawDataSession.subscription == false ?
                                  <div className='draw-countdown branding1 fs-4'><CountdownTimer time={DrawDataSession.drawEndDate}></CountdownTimer></div>
                                  : ''}
                              </div>
                            </div>
                          </div>

                        </div> */}
                  </div>

                  <p className="fs-5 pt-0 text-center text-white pb-4">
                    How many tickets would you like to buy?
                    {/* <span className="price-per-ticket" style={{ color: DrawDataSession.BrandingColor2 }}>£{ProductAmount} {DrawDataSession.subscription ? isSubscription ? "per ticket/Month" : "per ticket" : "per ticket"}</span> */}
                  </p>

                  {/* <div className="price-per-ticket" style={{  color: DrawDataSession.BrandingColor2 }}>£{ProductAmount} {DrawDataSession.subscription ? isSubscription ? "per ticket/Month" : "per ticket" : "per ticket"} </div> */}
                  <div className="step-subscription-plan-selector pt-4">
                    <ul className="d-flex unstyled list-unstyled gap-3">
                      {drawTicketLabel.slice(0, 4).map((item) => {
                        // //console.log(
                        //     "my count : ",
                        //     drawTicketLabel
                        // );

                        if (MyCart.Quantity) {
                          return (
                            <>
                              <li
                                className={`ticket-title-sec px-2 py-5 position-relative me-5 map text-center ${
                                  (isCampaign ? MyCart.Quantity == item * 2 : MyCart.Quantity == item) &&
                                  (isCampaign ? item * 2 !== drawTicketLabel : item !== drawTicketLabel)
                                    ? 'active'
                                    : ''
                                } `}
                                onClick={() => handleClick(ProductAmount * item)}
                                key={'ticket' + item}
                              >
                                {ProductAmount * item === 25 ? (
                                  <>
                                    <div
                                      className="subscription-plan-emphasis text-white bg-branding3 d-inline-block lh-normal position-absolute start-0 end-0 m-auto"
                                      style={{
                                        fontSize: 14,
                                        padding: '7px 6px 7px 7px',
                                        width: 150,
                                        top: -40,
                                        zIndex: 999,
                                        textAlign: 'center',
                                        borderTopLeftRadius: 20,
                                        borderTopRightRadius: 20
                                      }}
                                    >
                                      Popular
                                    </div>
                                  </>
                                ) : (
                                  ''
                                )}
                                {isCampaign ? (
                                  <p className="mb-0 top-promotion fs-4 branding2">
                                    {' '}
                                    <img
                                      src={require('../assets/images/playicon.png')}
                                      style={{ marginTop: -3, marginRight: 3 }}
                                      width="18px"
                                    />{' '}
                                    2for1 Offer
                                  </p>
                                ) : (
                                  ''
                                )}
                                <h4
                                  className="fs-4 text-center mb-2 mb-0"
                                  style={{
                                    color: 'rgb(48, 45, 81)',
                                    borderBottom: '2px solid rgb(230, 70, 98)',
                                    paddingBottom: 15
                                  }}
                                >
                                  {isCampaign ? item * 2 : item}
                                  {item === '1' ? ' ticket' : ' tickets'}
                                </h4>
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: 'unset',
                                    border: '0px'
                                  }}
                                  className={'p-3 buy-ticket-button font-face-gb-bold add-ticket'}
                                >
                                  <div
                                    className="price text-center posiion-relative d-flex"
                                    style={{
                                      color: 'rgb(23, 61, 107)'
                                    }}
                                  >
                                    <span className="display-3">£</span>
                                    <span className="display-1">{ProductAmount * item}</span>
                                  </div>
                                </button>
                              </li>
                            </>
                          );
                        } else {
                          return (
                            <>
                              <li
                                className="ticket-title-sec px-3 py-5 position-relative m-2 map text-center"
                                key={'ticket' + item}
                              >
                                <h4
                                  className="fs-5 text-center mb-2 mb-0"
                                  style={{
                                    color: 'rgb(48, 45, 81)',
                                    borderBottom: '2px solid rgb(230, 70, 98)',
                                    paddingBottom: 15
                                  }}
                                >
                                  {item} tickets
                                </h4>
                                <button
                                  type="button"
                                  onClick={() => handleClick(ProductAmount * item)}
                                  className={'p-3 buy-ticket-button font-face-gb-bold add-ticket'}
                                >
                                  <div
                                    className="price text-center posiion-relative d-flex"
                                    style={{
                                      color: 'rgb(89, 184, 178)'
                                    }}
                                  >
                                    <span className="display-6">£</span>
                                    <span className="display-4">{ProductAmount * item}</span>
                                  </div>
                                </button>
                              </li>
                            </>
                          );
                        }
                      })}
                    </ul>
                    <p
                      className="m-0 event-entry-fee-txt-second"
                      style={{
                        fontSize: 13,
                        color: DrawDataSession.BrandingColor2
                      }}
                    >
                      {DrawDataSession.ProfitGoesTo}
                    </p>
                  </div>

                  <div className="buy-now-wrap rounded-4 text-center justify-content-center row md-3 py-3 px-2 mx-0">
                    {/* <div className='col-md-3 px-0'>
        <h2 className="step-txt display-6 px-5 m-0" style={{ color: DrawDataSession.BrandingColor1 }}>Step <br /><strong className='font-face-gb-semibold display-5'>02</strong></h2>
      </div> */}
                    <div className="col-md-12 px-5" style={{}}>
                      {/* {DrawDataSession.subscription ?
                          <div className="form-check form-switch mb-3">
                            <input className="form-check-input" onChange={updateSubscription} type="checkbox" id="flexSwitchCheckDefault" />
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Tick to make a one-off entry into the next draw</label>
                          </div>
                          : ""} */}

                      {/* <div className="total-price d-flex justify-content-between">
                          <div className="draw-price">
                            <h5 className='branding1 m-0 font-face-gb-semibold fs-5'>Total</h5>
                            <div className='branding1 price-amount fs-2' style={{ lineHeight: '1' }}>£{AmountText}</div>
                          </div>

                          {Moment() < Moment(DrawDataSession.drawStartDate) ?
                            <button onClick={() => alert("Draw not started")} className='buy-now-btn border-0 rounded-pill btn pfw-button-red secbutton py-2 px-5 font-face-gb-semibold' style={{ backgroundColor: `${DrawDataSession.BrandingColor2}`, fontSize: 18 }}>{DrawDataSession.subscription && isSubscription ? "Subscribe" : "Buy Now"}</button>
                            :
                            <button onClick={() => BuyNow(DrawDataSession)} className='buy-now-btn border-0 rounded-pill btn pfw-button-red secbutton py-2 px-5 font-face-gb-semibold' style={{ backgroundColor: `${DrawDataSession.BrandingColor2}`, fontSize: 18 }}>{DrawDataSession.subscription && isSubscription ? "Subscribe" : "Buy Now"}</button>
                          }

                        </div> */}
                      {/* <div className='terms-cond mt-3'>
                          <p className='m-0 branding1'>Alternative entry routes available, <Link className='text-decoration-none' to={'/pool-draw-terms-conditions/'} style={{ color: DrawDataSession.BrandingColor2 }}>click here</Link> for terms.</p>
                        </div> */}
                      {/* ref={buttonRef} */}
                      {Moment() < Moment(DrawDataSession.drawStartDate) ? (
                        <button
                          ref={buttonRef}
                          onClick={() => alert('Draw not started')}
                          className="buy-now-btn border-0 rounded-pill btn pfw-button-red secbutton py-3 px-3"
                          style={{ fontSize: 18 }}
                        >
                          {DrawDataSession.subscription && isSubscription ? 'Subscribe' : 'Buy Now'}
                        </button>
                      ) : (
                        <button
                          ref={buttonRef}
                          onClick={() => BuyNow(DrawDataSession)}
                          className="buy-now-btn border-0 rounded-pill btn pfw-button-red secbutton py-3 px-3"
                          style={{ fontSize: 18 }}
                        >
                          {DrawDataSession.subscription && isSubscription ? 'Subscribe' : 'Buy Now'}
                        </button>
                      )}
                    </div>
                  </div>

                  <p className="fs-5 branding1 text-center alternative-text" style={{ fontSize: 22 }}>
                    Alternative entry routes available,{' '}
                    <Link className="branding2 text-decoration-none green-hover" to={`/${DrawDataSession.termsUrl}`}>
                      click here
                    </Link>{' '}
                    for terms.
                  </p>
                </div>
              </div>
            </section>

            <section className="banners-nabs" style={{ height: 700, overflow: 'hidden' }}>
              <div className="row ">
                <div className="col-lg-12 p-0 m-0">
                  <ImageLoader className="mx-auto" src={DrawDataSession.BottomBanner} />
                </div>
              </div>
            </section>

            <section className="draw-main-section font-face-gb" style={{ backgroundColor: '#eee', display: 'none' }}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 py-5 pe-5 draw-col-left position-relative">
                    {isCampaign ? (
                      <p className="mb-0 top-promotion-checkout-float fs-6 text-white">
                        {' '}
                        <img
                          src={require('../assets/images/playicon.png')}
                          style={{ marginTop: -3, marginRight: 3 }}
                          width="16px"
                        />{' '}
                        2for1 Offer
                      </p>
                    ) : (
                      ''
                    )}
                    <div className={`pe-5 buy-tickets-wrap ${drawisActive ? '' : 'd-none'}`}>
                      <h2 className="branding1 fs-1 font-face-gb-semibold mb-4 pb-2 pt-3">Enter the Draw</h2>

                      <div
                        className="tickets-wrapper border-top pt-3"
                        style={{
                          borderColor: '#c8c8c8'
                        }}
                      >
                        <h4 className="branding1 fs-5 font-face-gb-semibold m-0">
                          How many tickets would you like to buy?
                        </h4>
                        {/* <div className="price-per-ticket branding1" style={{ fontSize: 13 }}>£{ProductAmount} {DrawDataSession.subscription && isSubscription ? "per ticket/Month" : "per ticket"}</div> */}
                        <ul className="ticket-qty-btns d-flex unstyled list-unstyled mb-1">
                          {drawTicketLabel.map((item) => {
                            if (MyCart.Quantity) {
                              return (
                                <li className="m-2 map" key={'ticket' + item}>
                                  <button
                                    type="button"
                                    onClick={() => handleClicks(ProductAmount * item)}
                                    className={`p-3 pfw-button-red rounded-circle buy-ticket-button font-face-gb-bold add-ticket ${
                                      (isCampaign ? MyCart.Quantity == item * 2 : MyCart.Quantity == item) &&
                                      (isCampaign ? item * 2 !== drawTicketLabel : item !== drawTicketLabel)
                                        ? 'active'
                                        : ''
                                    } `}
                                    // className={
                                    //     MyCart.Quantity ==
                                    //     item
                                    //         ? "p-3 pfw-button-red rounded-circle buy-ticket-button font-face-gb-bold add-ticket active"
                                    //         : "p-3 pfw-button-red rounded-circle buy-ticket-button font-face-gb-bold add-ticket"
                                    // }
                                    style={
                                      (isCampaign ? MyCart.Quantity == item * 2 : MyCart.Quantity == item) &&
                                      (isCampaign ? item * 2 !== drawTicketLabel : item !== drawTicketLabel)
                                        ? {
                                            backgroundColor: `${DrawDataSession.BrandingColor2}`,
                                            width: 60,
                                            height: 60
                                          }
                                        : {
                                            backgroundColor: '#bdc5cf',
                                            width: 60,
                                            height: 60
                                          }
                                    }
                                  >
                                    {isCampaign ? item * 2 : item}
                                  </button>
                                </li>
                              );
                            } else {
                              return (
                                <li className="m-2 map" key={'ticket' + item}>
                                  <button
                                    type="button"
                                    onClick={() => handleClicks(ProductAmount * item)}
                                    className={
                                      'p-3 pfw-button-red rounded-circle buy-ticket-button font-face-gb-bold add-ticket'
                                    }
                                    style={
                                      MyCart.Quantity == item
                                        ? {
                                            backgroundColor: `${DrawDataSession.BrandingColor2}`,
                                            width: 60,
                                            height: 60
                                          }
                                        : item != drawTicketLabel[0]
                                        ? {
                                            backgroundColor: '#bdc5cf',
                                            width: 60,
                                            height: 60
                                          }
                                        : {
                                            backgroundColor: `${DrawDataSession.BrandingColor2}`,
                                            width: 60,
                                            height: 60
                                          }
                                    }
                                  >
                                    {item}
                                  </button>
                                </li>
                              );
                            }
                          })}
                          {/* <li className='ticket-input'>
                            <input type="number" min={1} value={MyCart.Quantity} onChange={(e) => { handleClick(ProductAmount * e.target.value) }} />
                          </li> */}
                        </ul>
                        {DrawDataSession.subscription ? (
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              onChange={updateSubscription}
                              type="checkbox"
                              id="flexSwitchCheckDefault"
                            />
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                              Tick to make a one-off entry into the next draw
                            </label>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="buy-tickets-sec d-flex justify-content-between mt-4 pt-2 pb-5">
                        <div className="total-price d-flex align-items-center border thirbutton-border rounded-pill bg-white px-4">
                          <h5 className="branding1 m-0 fs-2 me-3">Total</h5>
                          <div className="draw-price d-flex">
                            <div className="price-amount fs-2">£{AmountText}</div>
                            <div className="subscription-duration mt-3 ms-1">
                              {DrawDataSession.subscription && isSubscription ? 'per month' : ''}
                            </div>
                          </div>
                        </div>
                        {Moment() < Moment(DrawDataSession.drawStartDate) ? (
                          <button
                            onClick={() => alert('draw Not started')}
                            className="buy-now-btn border-0 rounded-pill btn pfw-button-red secbutton py-2 px-5 font-face-gb-semibold"
                            style={{
                              backgroundColor: `${DrawDataSession.BrandingColor2}`,
                              fontSize: 18
                            }}
                          >
                            {DrawDataSession.subscription && isSubscription ? 'Subscribe' : 'Buy Now'}
                          </button>
                        ) : (
                          <button
                            onClick={() => BuyNow(DrawDataSession)}
                            className="buy-now-btn border-0 rounded-pill btn pfw-button-red secbutton py-2 px-5 font-face-gb-semibold"
                            style={{
                              backgroundColor: `${DrawDataSession.BrandingColor2}`,
                              fontSize: 18
                            }}
                          >
                            {DrawDataSession.subscription && isSubscription ? 'Subscribe' : 'Buy Now'}
                          </button>
                        )}
                      </div>
                    </div>
                    <WinnerSelect
                      drawID={DrawDataSession.drawID}
                      winnerData={DrawDataSession.winnerData}
                      template="1"
                    />
                    <div className="terms-cond mt-3">
                      <p className="m-0 branding1">
                        Alternative entry routes available,{' '}
                        <Link
                          className="text-decoration-none"
                          to={'/pool-draw-terms-conditions/'}
                          style={{ color: '#e64662' }}
                        >
                          click here
                        </Link>{' '}
                        for terms.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 draw-col-right px-5 py-5">
                    <div className="prizestobewon text-center pt-4">
                      {/* <h3 className='fs-3 branding1'>Prizes to be won</h3> */}
                      {/* <h2 className='display-1 branding1 font-face-gb-semibold mt-3 mb-3'>£27.20</h2> */}
                      {/* <h6 className='fs-4 pb-4 mb-4' style={{ color: '#93a4ba' }}>pot increases with every entry</h6> */}
                      <h6 className="fs-1 pb-4 mb-4 branding1">Prize pot increases with every entry</h6>
                      <div
                        className="mx-auto"
                        style={{
                          backgroundColor: '#59b8b2',
                          width: 200,
                          height: 3
                        }}
                      ></div>
                    </div>
                    <div className="hihglights-draw-winner pt-5" style={{ paddingLeft: 200 }}>
                      <ul className="branding1 list-unstyled" style={{ lineHeight: 2 }}>
                        <li className="position-relative">Auto-entry into every draw</li>
                        <li className="position-relative">Guaranteed winner in every draw</li>
                        <li className="position-relative">Regular support for our cause</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="mission-section font-face-sh"
              style={{
                paddingTop: 50,
                zIndex: 99,
                position: 'relative'
              }}
            >
              <div className="containers">
                {/* {DrawDataSession.FixtureleftImage ?

                      <div className="team-fixtures bg-white rounded-4 py-5 px-4" style={{ marginBottom: 80, marginTop: 0 }}><div className="row align-items-center"><div className="col-md-2 text-center"><img className="start-0 w-100" alt="Favicon" src={DrawDataSession.FixtureleftImage} /></div><div className="col-md-8 text-center">
                        <p className="branding1 fs-4">Winner next announced</p>
                        <div className="row" style={{ alignItems: 'center', marginTop: 30, marginBottom: 30 }}><div className="col-md-5 text-right"><h2 className="branding2 lh-1 font-face-sh-bold text-right" style={{ textAlign: 'right', color: '#2756ae' }}>England</h2></div><div className="col-md-2 text-center"><h2 className="branding3 lh-1 font-face-sh-bold">VS</h2>
                        </div><div className="col-md-5 text-left"><h2 className="branding2 lh-1 font-face-sh-bold text-left" style={{ textAlign: 'left', color: '#e50027' }}>Italy</h2></div>


                        </div>
                        <p className="branding3 fs-4">October 17, 2023 3:30 PM</p>
                      </div><div className="col-md-2 text-center"><img className="start-0 w-100" alt="vsLogo" src={DrawDataSession.FixturerightImage} /></div></div></div>

                      : ""
                    } */}

                <div className="row">
                  <div
                    className="col-lg-6"
                    style={{
                      paddingTop: 50,
                      paddingLeft: 300,
                      paddingBottom: 50
                    }}
                  >
                    <img className="mb-3" src={require('../assets/images/branding.png')} />
                    <h2 className="display-6 branding1 pb-3">
                      <strong>{DrawDataSession.AboutUsTitleTemp}</strong>
                    </h2>
                    {DrawDataSession.drawID === 'e31bc942-ac7d-4255-b0a3-9854d6c32fc4' ? (
                      <h5 className="branding2">Supporting and helping the {DrawDataSession.Supporting} Charity.</h5>
                    ) : DrawDataSession.DrawManageType === '2' &&
                      DrawDataSession.drawID != 'e31bc942-ac7d-4255-b0a3-9854d6c32fc4' ? (
                      <h5 className="branding2">{DrawDataSession.Supporting}</h5>
                    ) : (
                      <h5 className="branding2">Supporting & Driving Community Sports - One Club at a Time</h5>
                    )}
                    <div className="description-data py-4" style={{ position: 'relative' }}>
                      {/<p>(.*?)<\/p>/i.test(DrawDataSession.content) ? (
                        <div
                          className="draw-description text-left mx-auto"
                          style={{ width: '100%' }}
                          dangerouslySetInnerHTML={{
                            __html: `${DrawDataSession.content}`
                          }}
                        />
                      ) : (
                        <div className="draw-description text-left mx-auto" style={{ width: '100%' }}>
                          {DrawDataSession.content
                            .replace(/&nbsp;/g, '')
                            .replace(/&#10;/g, '\n')
                            .replace(/&#39;/g, "'")
                            .replace(/&lsquo;/g, "'")
                            .replace(/&rsquo;/g, "'")
                            .replace(/&#8239;/g, ' ')
                            .replace(/&lt;p&gt;/g, '')
                            .replace(/&lt;\/p&gt;/g, '\n')
                            .split('\n')
                            .map((paragraph, index) => (
                              <p key={index}>
                                {paragraph.trim()}
                                {/* 
                                                        &lsquo; &rsquo;
                                                        {index !== DrawDataSession.content.length - 1 && '.'} */}
                              </p>
                            ))}
                        </div>
                      )}

                      <Link
                        to="/host"
                        className="border-0 text-white rounded-pill btn bg-branding3 py-3 px-3 my-3 mx-0 font-face-gb-semibold"
                        style={{ lineHeight: 2 }}
                      >
                        Find out more
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="draw-featured-img position-relative" style={{ marginTop: -180 }}>
                      {DrawDataSession.FeaturedUrl !== '' ? (
                        <ImageLoader src={DrawDataSession.FeaturedUrl} />
                      ) : (
                        // <img className='rounded-5 w-100' src={require(`../assets/images/draws/${DrawDataSession.FeaturedUrl}`)} />
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {DrawDataSession.drawID === 'efb3ab56-172c-4ecc-a3a5-0d521aec76c6' ? (
              <section className="prizebottom bot">
                <div className="container py-4 pb-3 mb-4" style={{}}>
                  <div className="row align-items-center">
                    <div className="col-md-6 draw-title-data">
                      <div className="row align-items-center">
                        <div className="col-md-1">
                          <img
                            src={require('../assets/images/Win_icon_thank.png')}
                            style={{
                              marginRight: 15,
                              width: '54px',
                              height: '54px'
                            }}
                          />
                        </div>
                        <div className="col-md-10 text-white" style={{ marginLeft: 10 }}>
                          <h3 className="m-0 p-0">
                            <span className="branding1 font-face-gb-semibold">VIP Package prizes Include:</span>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className='container'> */}

                {DrawDataSession.prizeSlider != '' ? (
                  <>
                    <PrizeSliderTemp3Bottom
                      className=""
                      prizeData={DrawDataSession.bottomSlider}
                      drawID={DrawDataSession.drawID}
                    />
                  </>
                ) : (
                  ''
                )}
                {/* </div> */}
                {/* :
                  <>
                  {DrawDataSession.prizeSlider != '' ? <>

                  <PrizeSliderTemp3Bottom className="" prizeData={DrawDataSession.prizeSlider} /></> : ""}
                  </>
                } */}

                <div className="bottomsliderimages" style={{ marginTop: '-200px' }}>
                  {DrawDataSession.PrizeBanner !== '' ? <ImageLoader src={DrawDataSession.PrizeBanner} /> : ''}
                </div>
              </section>
            ) : DrawDataSession.DrawManageType === '2' ? (
              <section className="prizebottom">
                <div className="container py-4 pb-3 mb-2" style={{}}>
                  <div className="row align-items-center">
                    <div className="col-md-6 draw-title-data">
                      <div className="row align-items-center">
                        <div className="col-md-1">
                          <img
                            src={require('../assets/images/Win_icon_thank.png')}
                            style={{
                              marginRight: 15,
                              width: '54px',
                              height: '54px'
                            }}
                          />
                        </div>
                        <div className="col-md-10 text-white" style={{ marginLeft: 10 }}>
                          <h3 className="m-0 p-0">
                            <span className="branding1 font-face-gb-semibold">VIP Package prizes Include:</span>
                          </h3>
                        </div>
                      </div>
                    </div>
                    {/* <div className='col-md-6 text-right d-flex view-all-button' style={{justifyContent : "right"}}><Link to="/find-a-draw/" className="border-0 rounded-pill btn pfw-button-red secbutton py-2 px-3 my-4 font-face-gb-semibold">View all</Link></div> */}
                  </div>
                </div>

                {/* {DrawDataSession.DrawManageType === "2" ?  */}
                {DrawDataSession.drawID === 'd97834e5-159f-4ffb-9ea2-6382854dfe71' ? (
                  <>
                    <PrizeSliderTemp3Bottom
                      className=""
                      prizeData={DrawDataSession.bottomSlider}
                      drawID={DrawDataSession.drawID}
                    />
                  </>
                ) : DrawDataSession.prizeSlider != '' ? (
                  <>
                    <div className="container">
                      <PrizeSliderTemp3BottomSlide
                        className=""
                        prizeData={DrawDataSession.bottomSlider}
                        drawID={DrawDataSession.drawID}
                      />
                    </div>
                  </>
                ) : (
                  ''
                )}

                {/* :
                  <>
                  {DrawDataSession.prizeSlider != '' ? <>

                  <PrizeSliderTemp3Bottom className="" prizeData={DrawDataSession.prizeSlider} /></> : ""}
                  </>
                } */}

                <div className="bottomsliderimages" style={{ marginTop: '-200px' }}>
                  {DrawDataSession.PrizeBanner !== '' ? (
                    <ImageLoader src={DrawDataSession.PrizeBanner} />
                  ) : (
                    // <img className='rounded-5 w-100' src={require(`../assets/images/draws/${DrawDataSession.FeaturedUrl}`)} />
                    ''
                  )}

                  {/* <img src={require('../assets/images/bottombannersslider.png')} style={{marginRight : 15, width : '100%'}}/> */}
                </div>
              </section>
            ) : (
              <section className="prizebottom bot">
                <div className="container py-4 pb-3 mb-2" style={{}}>
                  <div className="row align-items-center">
                    <div className="col-md-6 draw-title-data">
                      <div className="row align-items-center">
                        <div className="col-md-1">
                          <img
                            src={require('../assets/images/Win_icon_thank.png')}
                            style={{
                              marginRight: 15,
                              width: '54px',
                              height: '54px'
                            }}
                          />
                        </div>
                        <div className="col-md-10 text-white" style={{ marginLeft: 10 }}>
                          <h3 className="m-0 p-0">
                            <span className="branding1 font-face-gb-semibold">VIP Package prizes Include:</span>
                          </h3>
                        </div>
                      </div>
                    </div>
                    {/* <div className='col-md-6 text-right d-flex view-all-button' style={{justifyContent : "right"}}><Link to="/find-a-draw/" className="border-0 rounded-pill btn pfw-button-red secbutton py-2 px-3 my-4 font-face-gb-semibold">View all</Link></div> */}
                  </div>
                </div>

                {/* {DrawDataSession.DrawManageType === "2" ?  */}
                <div className="container">
                  {DrawDataSession.prizeSlider != '' ? (
                    <>
                      <PrizeSliderTemp3BottomSlide className="" prizeData={DrawDataSession.prizeSlider} />
                    </>
                  ) : (
                    ''
                  )}
                </div>
                {/* :
                  <>
                  {DrawDataSession.prizeSlider != '' ? <>

                  <PrizeSliderTemp3Bottom className="" prizeData={DrawDataSession.prizeSlider} /></> : ""}
                  </>
                } */}

                <div className="bottomsliderimages" style={{ marginTop: '-200px' }}>
                  {DrawDataSession.PrizeBanner !== '' ? (
                    <ImageLoader src={DrawDataSession.PrizeBanner} />
                  ) : (
                    // <img className='rounded-5 w-100' src={require(`../assets/images/draws/${DrawDataSession.FeaturedUrl}`)} />
                    ''
                  )}

                  {/* <img src={require('../assets/images/bottombannersslider.png')} style={{marginRight : 15, width : '100%'}}/> */}
                </div>
              </section>
            )}

            {DrawDataSession.drawID === 'efb3ab56-172c-4ecc-a3a5-0d521aec76c6' ? (
              <>
                <section className="prize-to-be-won pt-5 bg-branding1" style={{ paddingBottom: '400px' }}>
                  <div className="text-center px-md-0 px-3">
                    <h3 className="branding2 display-4 py-4">
                      <strong>We have many more Fantastic Prizes to be won.</strong>
                    </h3>
                    <p className="fs-5 text-white">
                      Follow us on social media to find out how to enter & be in with a chance to win.
                    </p>
                    <div className="container">
                      <div className="socials py-4">
                        <div className="py-3">
                          <a href="https://facebook.com/PlayFundWin" target="_blank">
                            <img src={require('../assets/images/facebookpfw.png')} className="px-3" />
                          </a>
                          <a href="https://twitter.com/PlayFundWin" target="_blank">
                            <img src={require('../assets/images/twitterpfw.png')} className="px-3" />
                          </a>
                          <a href="https://www.instagram.com/playfundwin/" target="_blank" rel="noopener">
                            <img src={require('../assets/images/instagrampfw.png')} className="px-3" />
                          </a>
                          <a href="https://www.linkedin.com/company/playfundwin/" target="_blank" rel="noopener">
                            <img src={require('../assets/images/linkedinpfw.png')} className="px-3 " />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="coming-soon position-relative" style={{ marginTop: '-400px' }}>
                  <div className="container">
                    <div
                      className="row my-5 mb-0 align-items-center bg-white"
                      style={{
                        boxShadow: '0px 0px 2px rgb(244, 244, 244)',
                        borderRadius: 25
                      }}
                    >
                      <div className="col-md-6 col-lg-6 p-0 left-coming-soon">
                        {DrawDataSession.LeftComingSoon !== '' ? (
                          <ImageLoader src={DrawDataSession.LeftComingSoon} />
                        ) : (
                          // <img className='rounded-5 w-100' src={require(`../assets/images/draws/${DrawDataSession.FeaturedUrl}`)} />
                          ''
                        )}

                        {/* <img src={require('../assets/images/left-coming-soon.png')} className="px-0" style={{width : '100%' , objectFit : 'cover' , height : '600px' , objectPosition : 'left'}}/> */}
                      </div>
                      <div className="col-md-6 col-lg-6 p-0">
                        <div className="coming-soon-desc px-5">
                          <h4 className="branding3 font-face-sh-bold fs-3">
                            <img className="mb-3 mx-2" src={require('../assets/images/branding.png')} />
                            <strong>COMING SOON</strong>
                          </h4>
                          <h2 className="display-4 branding1 lh-5 mb-5">
                            <strong> {DrawDataSession.ComingSoonTitle}</strong>
                          </h2>
                          <h2 className="branding3 position-relative lh-4 w-auto mt-2 mb-0 display-4 font-face-sh-bold text-uppercase">
                            <strong>VIP DRAW</strong>
                          </h2>
                          <p className="fs-3 branding1 my-0">
                            <strong>WIN</strong> VIP Experience Package for {DrawDataSession.ComingSoonTitle}!{' '}
                            <img
                              className="mb-3"
                              style={{
                                transform: 'rotate(90deg)'
                              }}
                              src={require('../assets/images/branding.png')}
                            />
                          </p>

                          <Link
                            to="/host"
                            className="border-0 text-white rounded-pill btn bg-branding3 py-3 px-4 my-2 mx-0 font-face-gb-semibold"
                            style={{
                              lineHeight: 2
                            }}
                          >
                            Find out more
                          </Link>
                          {/* <img className="mb-3 mx-2" style={{float : 'right'}} src={require('../assets/images/euro2024.png')} /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </>
            ) : (
              <>
                <section className="prize-to-be-won pt-5 pb-5 bg-branding1">
                  <div className="text-center">
                    <h3 className="branding2 display-4 py-4">
                      <strong>We have many more Fantastic Prizes to be won.</strong>
                    </h3>
                    <p className="fs-5 text-white">
                      Follow us on social media to find out how to enter & be in with a chance to win.
                    </p>
                    <div className="container">
                      <div className="socials py-4">
                        <div className="py-3">
                          <a href="https://facebook.com/PlayFundWin" target="_blank">
                            <img src={require('../assets/images/facebookpfw.png')} className="px-3" />
                          </a>
                          <a href="https://twitter.com/PlayFundWin" target="_blank">
                            <img src={require('../assets/images/twitterpfw.png')} className="px-3" />
                          </a>
                          <a href="https://www.instagram.com/playfundwin/" target="_blank" rel="noopener">
                            <img src={require('../assets/images/instagrampfw.png')} className="px-3" />
                          </a>
                          <a href="https://www.linkedin.com/company/playfundwin/" target="_blank" rel="noopener">
                            <img src={require('../assets/images/linkedinpfw.png')} className="px-3 " />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </>
            )}

            <div
              className="container-fluid pt-5 pb-4 px-0 how-it-works fon-face-gb"
              style={{ backgroundColor: '#f2f2f2' }}
            >
              <div className="container py-5">
                <div className="logo-bottom text-center py-5">
                  <Link to={'/'}>
                    <img src={LogoBottom} style={{ width: '450px' }} />
                  </Link>
                </div>

                <div className="text-center d-flex" style={{ justifyContent: 'center' }}>
                  <div className="data-title w-75">
                    <h2 className="hiw-title branding1 text-center font-face-gb-semibold fs-2 mb-5">
                      The revolutionary digital fundraising platform that makes raising money simple for sports clubs,
                      events and charities.
                    </h2>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-4 px-5">
                    <div className="py-3 d-flex align-items-center justify-content-center">
                      <img src={require('../assets/images/Artboard-1.png')} />
                      <h2 className="branding1 mn-0 ms-4 font-face-gb-semibold display-4">play</h2>
                    </div>
                    <p className="text-center">
                      Play a draw by visiting Play Fund Win and finding your favourite cause.
                    </p>
                  </div>
                  <div className="col-md-4 px-5">
                    <div className="py-3 d-flex align-items-center justify-content-center">
                      <img src={require('../assets/images/Artboard-2.png')} />
                      <h2 className="text-left branding1 mb-0 ms-4 font-face-gb-semibold display-4">fund</h2>
                    </div>
                    <p className="text-center">
                      Fund good causes and grassroots sports, as minimum 50% of net proceeds go to charity or good
                      causes.
                    </p>
                  </div>
                  <div className="col-md-4 px-5">
                    <div className="py-3 d-flex align-items-center justify-content-center">
                      <img src={require('../assets/images/logoMark-CompoundPathItem.png')} />
                      <h2 className="text-left branding1 mb-0 ms-4 font-face-gb-semibold display-4">win</h2>
                    </div>
                    <p className="text-center">
                      The draw host determines what % of the entry fee forms the jackpot prize which is guaranteed to be
                      won on every draw
                    </p>
                  </div>
                </div>
                <p className="text-center mt-4 pt-2">
                  Based on the traditional 50/50 model, contact us to discuss the best split for your fundraising.
                </p>
                <div className="text-center">
                  <Link
                    to={'/help-centre'}
                    className="border-0 rounded-pill btn pfw-button-red secbutton py-3 px-3 mt-4 mb-2 font-face-gb-semibold"
                    style={{ lineHeight: 2 }}
                  >
                    Speak to us
                  </Link>
                </div>
              </div>
            </div>

            {/* <section className="container-fluid cta-section-full bg-branding2 secbutton font-face-gb position-relative">
                  <div className="container py-lg-4 text-center">
                    <div className="py-5">
                      <h2 className="text-white display-4 font-face-gb-semibold pt-2">Want to fundraise for your Community Cause?</h2>
                      <p className="text-white my-4">Any good cause can a host a Play Fund Win draw, raising money for themselves or<br />
                        an associated good cause they support - click below for more information</p>
                      <Link to="/host" className="border-0 rounded-pill btn pfw-button-white py-2 px-5 my-2 mx-2 font-face-gb-semibold" style={{ lineHeight: 2 }}>Start a draw</Link>
                    </div>
                  </div>
                </section> */}
          </div>
        ) : (
          'Page Not Found'
        )}
        <SiteFooter />
        <ToastContainer autoClose={12000} />
      </>
    );
  } else {
    return (
      <div className="container">
        <div className="text-center ">
          {/* <p>Loading..</p> */}
          <img src={loading} className="loader" alt="Loading..." />
        </div>
      </div>
    );
  }
}

export default function () {
  return (
    <>
      <div className="draw-headers">
        <CartProvider>
          <DraftDrawPage />
        </CartProvider>
      </div>
    </>
  );
}

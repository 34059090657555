import React, { useEffect, useState, Component } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
//import { DrawData } from "../Data/Draw-data";
import { DrawDataSession } from './Cart';
import { GetDrawList } from '../Data/actionMethods';
import Moment from 'moment';
import CountdownTimer from '../components/CountdownTimer';
import { LogoLoader } from './imageLoader';

function SetDrawDetails(p) {
  DrawDataSession.data = p;
}

export default function CauseGrid() {
  const [DrawData, SetDrawData] = useState([]);
  const [drawisActive, setDrawisActive] = useState(false);
  const Redirectable = {
    '744cc8c0-f0d7-4417-be01-3c21a9d65b0b': 'https://beescommunitydraw.com',
    '857148fe-20e1-456f-b6b0-335dfc04aad1': 'https://supportersdraw.com',
    '1cde020d-0a33-4cb4-b6d7-167e7c6c1471': 'https://superhoopsdraw.com',
    'f8ec196d-3ce4-42ab-9bd4-c9c7669fe913': 'https://chelsealuckyblues.com',
    'acbb97ec-5fba-490d-83b2-c1dfd6b4f959': 'https://themightydraw.com',
    '642b4dd2-b393-4144-ab3e-62d2940f70e6': 'https://parkrunforever.com',
    'fc52bb5a-c0d0-479f-b4ed-abd1833d0319': 'https://fulhamflutter.com',
    'a2f3c98f-e60e-4766-b9af-aa00682a1f2b': 'https://nufoundationdraw.com',
    '4ebdcd53-3a47-4562-b848-0c98eb3dfde6': 'https://palacesuperdraw.com',
    '786ba4b3-c961-453d-b665-a9d4aa5fe402': 'https://saintssuperdraw.com'
  };
  const FunderData = {
    '744cc8c0-f0d7-4417-be01-3c21a9d65b0b': ['bees-community-draw-Logo .webp', '#c61d23', '#000'],
    '857148fe-20e1-456f-b6b0-335dfc04aad1': ['supporters-draw-logo.webp', '#cc0000', '#cc0000'],
    '1cde020d-0a33-4cb4-b6d7-167e7c6c1471': ['super-hoops-draw-logo.webp', '#082d53', '#082d53'],
    'f8ec196d-3ce4-42ab-9bd4-c9c7669fe913': ['chelsea-fc-foundation-draw-logo.webp', '#001489', '#001489'],
    'acbb97ec-5fba-490d-83b2-c1dfd6b4f959': ['the-mighty-draw-logo.webp', '#c80550', '#130944'],
    '642b4dd2-b393-4144-ab3e-62d2940f70e6': ['parkrunforever-logo.webp', '#2b233d', '#ffa304'],
    'fc52bb5a-c0d0-479f-b4ed-abd1833d0319': ['Fulham-Foundation_logo.webp', '#c4122e', '#000'],
    'a2f3c98f-e60e-4766-b9af-aa00682a1f2b': ['newcastle-united-foundation-logoTransparent.png', '#1b88c5', '#000000'],
    '4ebdcd53-3a47-4562-b848-0c98eb3dfde6': ['palace-for-life.png', '#db2b27', '#0a417a'],
    '786ba4b3-c961-453d-b665-a9d4aa5fe402': ['SFC_Saints_Foundation_Stacked_White.png', '#ff0028', '#ff0028']
  };

  useEffect(() => {
    GetDrawList().then((res) => {
      // console.log(res.data.content);
      let dt = [];

      if (res.status && res.data && res.data.content) {
        let data = res.data.content;
        for (let i = 0; i < data.length; i++) {
          data[i].drawContent = data[i].drawContent.length == 0 ? [] : JSON.parse(data[i].drawContent);
          // console.log("data i",data[i].drawContent);
          if (data[i].drawContent.length == 0) {
            // console.log("Here will be undefined",data[i])
            let elm = {};
            //please create object here
            elm.id = i;
            elm.drawID = data[i].drawID;
            elm.name = data[i].drawName;
            elm.price = 5;
            elm.slug = data[i].slug;
            elm.logoUrl = FunderData[data[i].drawID] ? FunderData[data[i].drawID][0] : 'admin.png';
            elm.HeaderImage = data[i].HeaderImage;
            elm.FeaturedUrl = data[i].FeaturedUrl;
            elm.HeaderLeftImage = '';
            elm.BrandingColor1 = FunderData[data[i].drawID] ? FunderData[data[i].drawID][1] : data[i].drawBrandPrimary;
            elm.BrandingColor2 = FunderData[data[i].drawID]
              ? FunderData[data[i].drawID][2]
              : data[i].drawBrandSecondary;
            elm.content = '';
            elm.PlayContent = '-';
            elm.drawEndDate = data[i].drawEndDate;
            elm.drawStartDate = data[i].drawStartDate;
            elm.FundContent = data[i].FundContent;
            elm.WinContent = data[i].WinContent;
            elm.ticketCountLabel = data[i].ticketCountLabel;
            elm.subscription = data[i].subscription;
            elm.template = data[i].template;
            elm.prizeSlider = data[i].prizeSlider;
            dt.push(elm);
          } else {
            data[i].drawContent.drawHostID = data[i].drawHostID;
            data[i].drawContent.drawHostCauseID = data[i].drawHostCauseID;
            data[i].drawContent.drawStartDate = data[i].drawStartDate;
            data[i].drawContent.drawEndDate = data[i].drawEndDate;
            data[i].drawContent.drawIsActive = data[i].drawIsActive;
            data[i].drawContent.drawIsActive = data[i].drawIsActive;
            dt.push(data[i].drawContent);
          }
        }
      }
      let activeDraw = dt.filter(
        (item) => Moment() < Moment(item.drawEndDate) && Moment() > Moment(item.drawStartDate)
      );
      console.log('DT', dt);
      console.log('Active Draw', activeDraw);
      SetDrawData(activeDraw);

      // console.log("Draw Data",dt)
    });
  }, []);

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div>
      {/* <div className="container logo-slider" style={{ width: 960 }}> */}
      <div className="row">
        {/* <Slider {...settings}> */}
        {DrawData.map((p) => {
          // const targetTime = new Date(DrawDataSession.data.drawEndDate).getTime()
          // const currentTime = Date.now();
          // const timeBetween = targetTime - currentTime;
          // if (timeBetween < 0) {
          //   // setDrawisActive(false);
          // } else {
          //   // setDrawisActive(true);
          // }
          console.log(p);
          return (
            <div className="col-lg-4 col-md-4 col-sm-12 font-face-gb px-2" key={p.id}>
              <div
                className={`slider-sec slider-sec-tag mt-5 text-center mb-5 bg-white m-1 shadow-sm draw-id-${p.drawID}`}
                style={{ borderRadius: 12 }}
              >
                {/* <div className="banner-draw"><img src={require(`../assets/images/draws/${p.FeaturedUrl}`)}></img></div> */}
                <div className="banner-draw banner-draw-headerimage">
                  <img src="https://s3.eu-west-2.amazonaws.com/pfw.storage.bucket/images/From-sunday-league-to-champions-league.jpg"></img>
                </div>
                <div
                  className="colour-left-right"
                  style={{
                    background: `linear-gradient( to right, ${p.BrandingColor1} 50%, ${p.BrandingColor2} 50%)`,
                    height: 23,
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10
                  }}
                />

                <div
                  className="draw-logo draw-logo-tags mx-auto rounded-pill"
                  style={{
                    marginTop: -70,
                    width: 120,
                    height: 120,
                    background: `linear-gradient( to right, ${p.BrandingColor1} 50%, ${p.BrandingColor2} 50%)`,
                    padding: 10
                  }}
                >
                  <div
                    className="draw-logo-wrap p-2 rounded-pill w-100 h-100"
                    style={{ backgroundColor: `${p.BrandingColor1}`, border: `3px solid #fff` }}
                  >
                    <LogoLoader
                      className="rounded-circle"
                      src={p.logoUrl}
                      style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                    {/* <img className="rounded-circle" src={require(`../assets/images/logo/${p.logoUrl}`)} style={{ width: '100%', height: '100%', objectFit: 'contain' }}></img> */}
                  </div>
                </div>
                <div className="px-3 pb-2">
                  <div className="price-range-mid d-flex">
                    <div className="price-range-left text-start">
                      <p>category</p>
                    </div>
                    <div className="price-range-right">
                      <p className="max-price">draw-date</p>
                    </div>
                  </div>
                  {Redirectable[p.drawID] ? (
                    <a href={Redirectable[p.drawID]} className="text-decoration-none" target={'_blank'}>
                      <h4
                        className="pt-3 fs-6 font-face-gb-semibold branding1 text-start w-75"
                        style={{ minHeight: 64 }}
                      >
                        {p.name}
                      </h4>
                    </a>
                  ) : (
                    <Link to={'/draw/' + p.slug} onClick={() => SetDrawDetails(p)} className="text-decoration-none">
                      <h4
                        className="pt-3 fs-6 font-face-gb-semibold branding1 text-start w-75"
                        style={{ minHeight: 64 }}
                      >
                        {p.name}
                      </h4>
                    </Link>
                  )}

                  <div className="draw-supporting text-strat">
                    <p className="branding1">Draw Countdown</p>
                    <div className="date-countdown d-md-flex flex-column gap-3 justify-content-center">
                      <div className="draw-date branding1 m-md-0 m-3">
                        {Moment(p.drawEndDate).format('DD MMMM YYYY')}
                      </div>
                      {p.subscription == false ? (
                        <div className="countdown-timer">
                          <div className="draw-counts speed1" data-format="dhm">
                            <span
                              className={`demo12337 branding1 px-3 py-2 rounded-pill border ${
                                Moment() > Moment(p.drawEndDate) ? 'secbutton-border' : 'thirbutton-border'
                              } `}
                            >
                              {Moment() > Moment(p.drawEndDate) ? (
                                <span className="branding3">Draw Finished</span>
                              ) : (
                                <CountdownTimer time={p.drawEndDate} />
                              )}
                            </span>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  {Redirectable[p.drawID] ? (
                    <a
                      href={Redirectable[p.drawID]}
                      target={'_blank'}
                      className="border-0 rounded-pill btn pfw-button-red secbutton py-2 px-3 my-4 w-100 font-face-gb-semibold"
                      style={{ lineHeight: 2 }}
                    >
                      {Moment() > Moment(p.drawEndDate) ? 'View Draw' : 'Enter Draw'}
                    </a>
                  ) : (
                    <Link
                      to={'/draw/' + p.slug}
                      onClick={() => SetDrawDetails(p)}
                      className="border-0 rounded-pill btn pfw-button-red secbutton py-2 px-3 my-4 w-100 font-face-gb-semibold"
                      style={{ lineHeight: 2 }}
                    >
                      {Moment() > Moment(p.drawEndDate) ? 'View Draw' : 'Enter Draw'}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {/* </Slider> */}
      </div>
      {/* </div> */}
    </div>
  );
}

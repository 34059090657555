import React, {  useEffect, useState, Component } from 'react'
import Siteheader from './header';
import SiteFooter from './footer';
import { Inner_Header_Images } from './images';
import FooterCta from './footer-cta';
import Accordion from 'react-bootstrap/Accordion';
import MonthlySlider from "./monthlySlider";
import { Helmet } from 'react-helmet';
import HostSliderSection from "./hostSlider-section";
import MarketingSupport from './marketingSupport';
import ActiveSlider from './activeSlider';
import { toast, Toast, ToastContainer } from 'react-toastify'
import moment from 'moment';
import axios from 'axios';
import HostImgSlider from './hostSlider-Img';

import Slider from "react-slick";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { MyCart, Cart, HomeDrawDataSession, DrawDataSession } from './Cart';
import { CartProvider, useCart } from "react-use-cart";
//import { DrawData } from "../Data/Draw-data";
import { GetDrawList } from '../Data/actionMethods'
import Moment from "moment";
import CountdownTimer from './CountdownTimer';
import { LogoLoader } from "./imageLoader";
import SkeletonLoader from "./SkeletonLoader";

function SetDrawDetails(p) {
  DrawDataSession.data = p;
}

function GrassrootSlider() {

  const [DrawData, SetDrawData] = useState([]);
    const [drawisActive, setDrawisActive] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [Fetched, setFetched] = useState(false);
    const params = useParams();
    const [ItemCount, setItemCount] = useState();
    const [AmountText, setAmountText] = useState();
    const [isSubscription, setSubscription] = useState(true);
    const { AddInCart, addItem } = useCart();
    const Navigate = useNavigate()
    const { isEmpty, items } = useCart();
  
    function SampleNextArrow(props) {
      const { className, style, onClick } = props;
      return (
        <div
          className="custom-arrow next-arrow"
          style={{ ...style, display: "block" }}
          onClick={onClick}
        />
      );
    }
  
    function SamplePrevArrow(props) {
      const { className, style, onClick } = props;
      return (
        <div
          className="custom-arrow prev-arrow"
          style={{ ...style, display: "block" }}
          onClick={onClick}
        />
      );
    }


    useEffect(() => {

        const DrawName = params.name;
    
        if(DrawName === "petrus-subscription-draw"){
          window.location.replace("https://playfundwin.com/draw/petrus-community-draw")
        }
    
    
        
        GetDrawList().then(res => {
          // console.log(res.data.content);
          let dt = []
    
          if (res.status && res.data && res.data.content) {
            let data = res.data.content;
    
            for (let i = 0; i < data.length; i++) {
              data[i].drawContent = data[i].drawContent.length == 0 ? [] : JSON.parse(data[i].drawContent);
              // console.log("data i",data[i].drawContent);
              if (data[i].drawContent.length == 0) {
                // console.log("Here will be undefined",data[i])
                let elm = {}
                //please create object here
                elm.id = i
                elm.drawID = data[i].drawID
                elm.name = data[i].drawName;
                elm.price = 5
                elm.slug = data[i].slug
                elm.logoUrl = data[i].logoUrl ? data[i].logoUrl : 'admin.png'
                elm.HeaderImage = data[i].HeaderImage
                elm.FeaturedUrl = data[i].FeaturedUrl
                elm.HeaderLeftImage = ""
                elm.BrandingColor1 = data[i].drawBrandPrimary
                elm.BrandingColor2 = data[i].drawBrandSecondary
                elm.content = ""
                elm.PlayContent = '-'
                elm.drawEndDate = data[i].drawEndDate
                elm.drawStartDate = data[i].drawStartDate
                elm.FundContent = data[i].FundContent
                elm.WinContent = data[i].WinContent
                elm.ticketCountLabel = data[i].ticketCountLabel
                elm.subscription = data[i].subscription
                elm.Redirection = data[i].Redirection
                elm.RedirectionUrl = data[i].RedirectionUrl
                elm.template = data[i].template
                elm.prizeSlider = data[i].prizeSlider
                dt.push(elm)
    
              } else {
                data[i].drawContent.drawHostID = data[i].drawHostID;
                data[i].drawContent.drawHostCauseID = data[i].drawHostCauseID;
                data[i].drawContent.drawStartDate = data[i].drawStartDate;
                data[i].drawContent.drawEndDate = data[i].drawEndDate;
                data[i].drawContent.drawIsActive = data[i].drawIsActive;
                data[i].drawContent.drawIsActive = data[i].drawIsActive;
                data[i].drawContent.winnerData = data[i].drawWinners ? data[i].drawWinners : [];
                dt.push(data[i].drawContent);
              }
              console.log("Check dt",dt);
            }
          }
          let activeDraw = dt.filter(item => Moment() < Moment(item.drawEndDate) && Moment() > Moment(item.drawStartDate) && (item.template === 1 || item.template === 2) && (item.RedirectionUrl == null))
          console.log("Active Draw", activeDraw)
          SetDrawData(activeDraw);
          setIsLoading(false);
          // console.log("Draw Data",dt)
    
          if (HomeDrawDataSession.data === undefined) {
            dt.forEach(element => {
              console.log("element", DrawName);
              const string1 = element.slug.toLocaleLowerCase();
              const string2 = element.slug.toLocaleLowerCase();
             // console.log("element", element);
              console.log("string1", string1);
             // console.log("string1", string2);
              if (string1 === string2) {
                HomeDrawDataSession.data = element;
      
                setSubscription(HomeDrawDataSession.data.subscription ? true : false);
                const targetTime = new Date(HomeDrawDataSession.data.drawEndDate).getTime()
                const currentTime = Date.now();
                const timeBetween = targetTime - currentTime;
                if (timeBetween < 0) {
                  setDrawisActive(false);
                } else {
                  setDrawisActive(true);
                }
                MyCart.ProductCost = HomeDrawDataSession.data.price;
                let drawTicketLabel = HomeDrawDataSession.data.ticketCountLabel ? HomeDrawDataSession.data.ticketCountLabel.split(",") : [1, 3, 5];
                setAmountText(drawTicketLabel[0] * HomeDrawDataSession.data.price);
                let qty = drawTicketLabel[0];
                MyCart.Quantity = parseInt(qty);
              }
            });
            setFetched(true);
            } else {
            // setAmountText(HomeDrawDataSession.data.price);
            setSubscription(HomeDrawDataSession.data.subscription ? true : false);
            const targetTime = new Date(HomeDrawDataSession.data.drawEndDate).getTime()
            const currentTime = Date.now();
            const timeBetween = targetTime - currentTime;
            if (timeBetween < 0) {
              setDrawisActive(false);
            } else {
              setDrawisActive(true);
            }
            MyCart.ProductCost = HomeDrawDataSession.data.price;
            let drawTicketLabel = HomeDrawDataSession.data.ticketCountLabel ? HomeDrawDataSession.data.ticketCountLabel.split(",") : [1, 3, 5];
            let BundleAmounts = HomeDrawDataSession.data.bundlePrice ? HomeDrawDataSession.data.bundlePrice.split(",") : [5, 15, 25];
            let aboutImages = HomeDrawDataSession.data.aboutImages ? HomeDrawDataSession.data.aboutImages.split(",") : [];
            let qty = drawTicketLabel[0];
            MyCart.Quantity = parseInt(qty);
            setFetched(true);
            setAmountText(drawTicketLabel[0] * HomeDrawDataSession.data.price);
          }
          
          
          
        })
      }, [])

      console.log("Draw Data", DrawData);

      const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 3000,
        speed: 500,
        centerMode: false,
        centerPadding: '120px',
        slidesToShow: 6,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
          
          {
            breakpoint: 1900,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 1500,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: false,
            }
          },
    
     
        ]
      };
  return (
    <div className='container pb-5'>
      <h2 className='text-center font-face-gb-semibold branding1'>Who we've worked with</h2>
      <div className='row'>
        <Slider {...settings}>

        {DrawData.map((p) => {

          return (

          <Link to={"/draw/" + p.slug} onClick={() => SetDrawDetails(p)} target="_blank">  
            <div className={`my-3 px-3 d-flex align-items-center grassroot-logo justify-content-center draw-id-${p.drawID}`}>
              {/* {p.logoUrl} */}
              <LogoLoader src={p.logoUrl} style={{ width: '100%', objectFit: 'contain' }} />
            </div>
          </Link>

         
          );
 })}
        </Slider>
      </div>
    </div>
  )
}
export default function () {
  return (
    <>

      
      <CartProvider>
        <GrassrootSlider />
      </CartProvider>
    </>
  )
}
import React, { useState, useEffect } from 'react'
import SiteFooter from '../components/footer'
import Siteheader from '../components/header'
import { Helmet } from 'react-helmet'
import { toast, ToastContainer } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage'
import axios from 'axios'
import apiClient from '../api.config';
import { Link, useNavigate } from 'react-router-dom'
// import moment from 'moment';
// import { size } from 'lodash';
import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
import Breadcrumb from '../components/breadcrum';


const luhn = require('luhn');
let header = { withCredentials: true, credentials: "same-origin" }

export default function BillingSubscriptions() {

  const userData = secureLocalStorage.getItem('LogObject');
  const navigate = useNavigate();
  let APIURL = process.env.REACT_APP_API_URL;

  const [userSubscriptionList, SetUserSubscriptionList] = useState([]);
  const [subscriptioniChange, SetSubscriptionChange] = useState(1);
  const [modalCancelHost, SetModalCancelHost] = useState(false);
  const [modalCancelDraw, SetModalCancelDraw] = useState(false);
  const [modalEditHost, SetModalEditHost] = useState(false);
  const [modalEditDraw, SetModalEditDraw] = useState(false);
  const [userSubscriptionID, SetUserSubscriptionID] = useState("");
  const [editQuantity, SetEditQuantity] = useState(1);
  const [selectHost, SetSelectHost] = useState(null);
  const [selectDraw, SetSelectDraw] = useState(null);
  const [showDetails, SetShowDetails] = useState({});
  const [clickProcessBtn, SetClickProcessBtn] = useState(false);

  //----------------------------- apis ---------------------------
  async function getUserSubscriptionList(userID) {
    try {
      const res = await axios.get(APIURL + `/users/${userID}/subscription`, header);
      console.log("getUserSubscriptionList", res);
      let host_list = [];

      if (Array.isArray(res.data?.content)) {
        for (let i = 0; i < res.data.content.length; i++) {
          let selected_host = null;
          for (let iHost = 0; iHost < host_list.length; iHost++) {
            if (host_list[iHost].hostID == res.data.content[i].userSubscriptionHostID) {
              selected_host = host_list[iHost];
              break;
            }
          }
          if (selected_host == null) {
            selected_host = {
              hostName: res.data.content[i].hostName,
              hostID: res.data.content[i].userSubscriptionHostID,
              amount: 0,
              subscriptionType: "Monthly",
              active: [],
              cancel: [],
              other: [],
            }
            host_list.push(selected_host);
          }
          if (res.data.content[i].userSubscriptionStatusID == 3) { // SUBSCRIPTION_ACTIVE
            selected_host.active.push(res.data.content[i]);
            selected_host.amount += res.data.content[i].userSubscriptionAmount;
          } else if (res.data.content[i].userSubscriptionStatusID == 6) { // SUBSCRIPTION_CANCELLED
            selected_host.cancel.push(res.data.content[i]);
          } else {
            selected_host.other.push(res.data.content[i]);
          }
        }
        return host_list;
      }
      return [];
    } catch (err) {
      console.log("result error:", err)
      return err;
    }
  }

  async function cancelUserSubscriptionDraw(body) { //userSubscriptionID
    try {
      const res = await apiClient.post(APIURL + `/transactions/stripe/cancelSubscriptionDraw`, body);
      return res;
    } catch (err) {
      console.log("result error:", err)
      return err;
    }
  }

  async function cancelUserSubscriptionHost(body) { //hostID, UserID
    try {
      const res = await apiClient.post(APIURL + `/transactions/stripe/cancelSubscriptionHost`, body);
      return res;
    } catch (err) {
      console.log("result error:", err)
      return err;
    }
  }

  async function editUserSubscription(body) { //quantity
    try {
      const res = await apiClient.post(APIURL + `/transactions/stripe/editSubscription`, body);
      return res;
    } catch (err) {
      console.log("result error:", err)
      return err;
    }
  }

  // ------------------------------ apis end -------------------------------

  function updateSubscription() {
    SetSubscriptionChange(subscriptioniChange + 1);
  }

  async function handleCancelHost() {
    let userID = userData.userID;
    let hostID = selectHost.hostID;
    SetClickProcessBtn(true);
    let res = await cancelUserSubscriptionHost({ userID, hostID });
    if (res.status) {
      toast.success(`Host Subscription successfully cancelled.`);
      updateSubscription();
      SetModalCancelHost(false);
    } else {
      if (res.response.status === 403) {
        toast.error(`${res.response.data.content.message}`);
      } else {
        toast.error(`${res.message}`);
      }
      SetModalCancelHost(false);
    }
    SetClickProcessBtn(false);
  }

  async function handleCancelDraw() {
    console.log("cancel draw subscription process ...");
    let userSubscriptionID = selectDraw.userSubscriptionID;
    let res = await cancelUserSubscriptionDraw({ userSubscriptionID });
    if (res.status) {
      toast.success(`Draw subscription successfully cancelled.`);
      updateSubscription();
      SetModalCancelDraw(false);
    } else {
      if (res.response.status === 403) {
        toast.error(`${res.response.data.content.message}`);
      } else {
        toast.error(`${res.message}`);
      }
      SetModalCancelDraw(false);
    }
    SetClickProcessBtn(false);
  }

  async function handleEditDraw() {
    console.log("edit draw subscription process ...");
    SetClickProcessBtn(true)

    if (selectDraw.userSubscriptionQuantity === editQuantity) {
      toast.error(`Please enter the changed value.`);
      return;
    }

    if (editQuantity === "" || editQuantity < 1) {
      toast.error(`Please enter a valid value.`);
      return;
    }

    let res = await editUserSubscription({
      type: 2, //Draw Subscription
      userSubscriptionID: selectDraw.userSubscriptionID,
      newQuantity: editQuantity
    });
    console.log("edit draw res:", res);
    SetClickProcessBtn(false)
    if (res.status) {
      toast.success(`Draw subscription successfully updated.`);
      updateSubscription();
    } else {
      if (res.response.status === 403) {
        toast.error(`${res.response.data.content.message}`);
      } else {
        toast.error(`${res.message}`);
      }
    }
    SetModalEditDraw(false);
  }

  const handleQuantityChange = (event) => {
    let value = event.target.value;
    value = parseInt(value);
    if (isNaN(value)) {
      value = "";
    }
    SetEditQuantity(value);
  };

  function showDetailsHandler(hostID) {
    let cloneShowDetails = { ...showDetails };
    let key = 'key_' + hostID;
    if (cloneShowDetails[key] === false) {
      cloneShowDetails[key] = true;
    } else if (cloneShowDetails[key] === true) {
      cloneShowDetails[key] = false;
    } else {
      cloneShowDetails[key] = true;
    }
    SetShowDetails(cloneShowDetails);
  }

  useEffect(() => {
    if (!userData) {
      navigate('/login');
    } else {
      getUserSubscriptionList(userData.userID).then((res) => {
        if (Array.isArray(res)) {
          SetUserSubscriptionList(res);
        } else {
          SetUserSubscriptionList([]);
        }
      });
    }
  }, [subscriptioniChange]);

  return (
    <>
      <Helmet>‍
        <title>Billing Subscriptions | Play Fund Win</title>‍
        <meta name="description" content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities." />
      </Helmet>
      <Siteheader />
      <ToastContainer />
      <section className="register-section h-100 w-100">
        <div className="container h-100 p-xl-5 py-5 bg-f2 position-relative bg-before-after" style={{ borderRadius: 10 }}>
          <Breadcrumb />
          <div className="d-flex align-items-center justify-content-center h-100 pb-5 pt-4">
            <div className="d-flex flex-column col-xl-9 col-lg-9 col-md-12 col-12 row">
              <div className='align-items-center'>
                <h1 className='branding1 display-4 font-face-gb-semibold text-center mb-4'>Subscriptions</h1>
              </div>
              {
                selectHost && <Modal show={modalCancelHost} onHide={() => SetModalCancelHost(false)} size="md" centered>
                  <Modal.Header closeButton style={{ position: 'relative' }} className="payment-card-modal-header">
                    <Modal.Title>
                      <div className="px-3 fw-bold branding1">Cancel Host</div>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="payment-card-modal-body px-md-4 px-3">
                    <div>
                      <div className="row">
                        <h5>
                          Are you sure you want to cancel this host subscription?
                        </h5>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="payment-card-modal-footer">
                    <div className="px-3 pb-3">
                      <div className="row">
                        <div style={{
                          display: 'flex'
                        }}>
                          <div className="d-flex">
                            <div>
                              <input
                                className="btn pfw-button-red rounded-pill align-self-center px-2 py-2 fs-6 fw-bold"
                                type="button"
                                name="submit"
                                value="Cancel"
                                onClick={() => { SetModalCancelHost(false) }}
                                style={{ lineHeight: 2, width: '120px' }} />
                            </div>
                            <div className="ms-3">
                              <input
                                className="btn pfw-button-green rounded-pill align-self-center px-2 py-2 fs-6 fw-bold"
                                type="button"
                                name="submit"
                                value="Yes"
                                disabled={clickProcessBtn}
                                onClick={() => {
                                  handleCancelHost();
                                }}
                                style={{ lineHeight: 2, width: '120px' }} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Footer>
                </Modal>
              }

              {
                selectHost && selectDraw && <Modal show={modalCancelDraw} onHide={() => SetModalCancelDraw(false)} size="md" centered>
                  <Modal.Header closeButton style={{ position: 'relative' }} className="payment-card-modal-header">
                    <Modal.Title>
                      <div className="px-3 fw-bold branding1">Cancel Draw</div>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="payment-card-modal-body px-md-4 px-3">
                    <div>
                      <div className="row">
                        <h5>
                          Are you sure you want to cancel this draw subscription?
                        </h5>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="payment-card-modal-footer">
                    <div className="px-3 pb-3">
                      <div className="row">
                        <div style={{
                          display: 'flex'
                        }}>
                          <div className="d-flex">
                            <div>
                              <input
                                className="btn pfw-button-red rounded-pill align-self-center px-2 py-2 fs-6 fw-bold"
                                type="button"
                                name="submit"
                                value="Cancel"
                                onClick={() => { SetModalCancelDraw(false) }}
                                style={{ lineHeight: 2, width: '120px' }} />
                            </div>
                            <div className="ms-3">
                              <input
                                className="btn pfw-button-green rounded-pill align-self-center px-2 py-2 fs-6 fw-bold"
                                type="button"
                                name="submit"
                                value="Yes"
                                disabled={clickProcessBtn}
                                onClick={() => {
                                  handleCancelDraw();
                                }}
                                style={{ lineHeight: 2, width: '120px' }} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Footer>
                </Modal>
              }

              {
                selectHost && selectDraw && <Modal show={modalEditDraw} onHide={() => SetModalEditDraw(false)} size="md" centered>
                  <Modal.Header closeButton style={{ position: 'relative' }} className="payment-card-modal-header">
                    <Modal.Title>
                      <div className="px-3 fw-bold branding1">Edit Host/Draw</div>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="payment-card-modal-body px-md-4 px-3">
                    <div className='d-flex flex-column justify-content-start'>
                      <div className="">
                        <label>Price:</label> <strong className='fs-5'>£{selectDraw.userSubscriptionPrice}</strong>
                      </div>
                      <div className="mt-3 d-flex flex-column">
                        <label className='mb-2' style={{ width: '70px' }}>Quantity:</label>
                        <input
                          value={editQuantity}
                          onChange={handleQuantityChange}
                          placeholder="quantity"
                          type='number'
                          maxLength={10}
                          className='rounded-pill'
                          style={{ borderRadius: '5px', border: '1px solid #C9C9C9', padding: '13px 20px' }}
                        />
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="payment-card-modal-footer">
                    <div className="px-3 pb-3">
                      <div className="row">
                        <div style={{
                          display: 'flex'
                        }}>
                          <div className="d-flex">
                            <div>
                              <input
                                className="btn pfw-button-red rounded-pill align-self-center px-2 py-2 fs-6 fw-bold"
                                type="button"
                                name="submit"
                                value="Cancel"
                                onClick={() => {
                                  SetModalEditDraw(false)
                                }}
                                style={{ lineHeight: 2, width: '120px' }} />
                            </div>
                            <div className="ms-3">
                              <input
                                className="btn pfw-button-green rounded-pill align-self-center px-2 py-2 fs-6 fw-bold"
                                type="button"
                                name="submit"
                                value="Yes"
                                disabled={clickProcessBtn}
                                onClick={() => {
                                  handleEditDraw();
                                }}
                                style={{ lineHeight: 2, width: '120px' }} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Footer>
                </Modal>
              }

              {
                userSubscriptionList.reduce((totalActive, userSubscription) => totalActive + userSubscription.active.length, 0) == 0 &&
                <div className="row mt-4 bg-white w-50 m-auto pt-4 pb-2" style={{ borderRadius: 10 }}>
                  <div className='text-center'>
                    <p class="branding1 fs-5 text-center mb-0 fw-bold">You don't have any subscription yet!</p>
                    <Link to={"/find-a-draw"} className="border-0 rounded-pill btn pfw-button-red secbutton py-2 px-5 my-4 font-face-gb-semibold" style={{ lineHeight: 2 }}>Find a Draw</Link>
                  </div>
                </div>
              }

              {
                userSubscriptionList.map((row, index) => {
                  return (
                    row.amount > 0 && <React.Fragment key={row.hostID}>
                      <div className="row mx-0 mb-3 subs-inner" style={{ borderRadius: '10px', backgroundColor: '#FFF', marginTop: '10px', padding: '15px 10px 20px 10px' }}>
                        <div className="col-12">
                          <label htmlFor="" style={{ 'fontSize': '18px', 'fontWeight': 'bold' }}> Host: {row.hostName} </label>
                        </div>
                        <div className="col-12">
                          <label htmlFor="">Amount: {row.amount}</label>
                        </div>
                        <div className="col-md-6 col-12">
                          <label htmlFor="">Subscription Type: {row.subscriptionType}</label>
                        </div>
                        <div className="col-6 my-md-0 my-3 d-flex justify-content-end">
                          <input
                            className="btn pfw-button-red rounded-pill align-self-center px-1 py-2 fs-6 fw-bold"
                            type="button"
                            name="submit"
                            value="Cancel"
                            onClick={() => {
                              SetSelectHost(row);
                              SetModalCancelHost(true);
                            }}
                            style={{ lineHeight: 2, width: '150px' }} />
                          {/* <input
                            className="btn pfw-button-green rounded-pill align-self-center px-1 py-1 fs-6 fw-bold"
                            type="button"
                            name="submit"
                            value="Edit"
                            onClick={() => {
                            }}
                            style={{ lineHeight: 1.5, width: '150px' }} /> */}
                        </div>
                        <div className="col-12 mb-2 text-md-start text-center">
                          <button style={{ border: 'none', padding: '8px 10px 11px 10px', backgroundColor: '#0e3160', borderRadius: '5px' }}
                            className='text-white'
                            onClick={() => {
                              showDetailsHandler(row.hostID)
                            }}>
                            {
                              showDetails['key_' + row.hostID] ?
                                <i className='fa fa-minus-square'></i> :
                                <i className='fa fa-plus-square'></i>
                            }  View details </button>
                        </div>
                        {
                          showDetails['key_' + row.hostID] &&
                          <div className="col-12">
                            <div style={{
                              // padding: '0px 30px 30px 30px',
                              background: 'buttonface',
                              borderRadius: '10px'
                            }}>
                              {
                                row.active.map((row2, index) => {
                                  return (
                                    <React.Fragment key={row2.userSubscriptionID}>
                                      <div className="row py-4 px-md-4 px-2" style={{ borderBottom: '3px solid #FFF' }}>
                                        <div className="col-md-6 col-12">
                                          <div className="row">
                                            <div className="col-12" style={{}}>
                                              <strong>Draw Name:</strong> <span style={{ wordBreak: 'break-all' }}>{row2.drawName}</span>
                                            </div>
                                            <div className="col-12" style={{}}>
                                              <strong>Price:</strong> {row2.userSubscriptionPrice}
                                            </div>
                                            <div className="col-12" style={{}}>
                                              <strong>Quantity:</strong> {row2.userSubscriptionQuantity}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6 col-12 gap-3 mt-md-0 mt-3"
                                          style={{ paddingTop: '0px', display: 'flex', justifyContent: 'end' }}>
                                          <input
                                            className="btn pfw-button-red rounded-pill align-self-center px-1 py-2 fs-6 fw-bold"
                                            type="button"
                                            name="submit"
                                            value="Cancel"
                                            onClick={() => {
                                              SetSelectHost(row);
                                              SetSelectDraw(row2);
                                              SetModalCancelDraw(true);
                                            }}
                                            style={{ lineHeight: 2, width: '150px' }} />
                                          <input
                                            className="btn pfw-button-green rounded-pill align-self-center px-1 py-2 fs-6 fw-bold"
                                            type="button"
                                            name="submit"
                                            value="Edit"
                                            onClick={() => {
                                              SetSelectHost(row);
                                              SetSelectDraw(row2);
                                              SetEditQuantity(row2.userSubscriptionQuantity);
                                              SetModalEditDraw(true);
                                            }}
                                            style={{ lineHeight: 2, width: '150px' }} />
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  )
                                })
                              }
                            </div>
                          </div>

                        }
                      </div>
                    </React.Fragment>
                  );
                })
              }
            </div>
          </div>
        </div>
      </section >


      <SiteFooter />
    </>
  )
}

import React, { useState } from 'react';
import Siteheader from '../components/header';
import SiteFooter from '../components/footer';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { toast, Toast, ToastContainer } from 'react-toastify';
import moment from 'moment';
import { contact } from '../utils/meta/metaPixelEvent';

export default function FindDrawForm() {
  const [drawcategory, SetDrawCategory] = useState('');
  const [organisationname, SetOrganisationName] = useState('');
  const [website, SetWebsite] = useState('');
  const [contactname, SetContactName] = useState('');
  const [contactemail, SetContactEmail] = useState('');
  const [description, SetDescription] = useState('');

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  //const navigate =useNavigate();
  let APIURL = process.env.REACT_APP_API_URL;
  let siteID = process.env.REACT_APP_API_SITE_ID;

  async function findDrawFormHandler() {
    if (contactemail.trim().length === 0) {
      toast.error(`Please Enter Your Email`);
    } else if (!/^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9-]+(?:\.[a-z0-9-]+)+(?:\.[a-z0-9-]+)*$/.test(contactemail)) {
      toast.error(`Please Enter Your valid Email Address`);
    } else if (drawcategory.trim().length === 0) {
      toast.error(`Please Select Club Category`);
    } else if (organisationname.trim().length === 0) {
      toast.error(`Please Enter Organisation Name`);
    } else if (website.trim().length === 0) {
      toast.error(`Please Enter Website or Facebook URL`);
    } else if (contactname.trim().length === 0) {
      toast.error(`Please Enter Contact Name`);
    } else if (description.trim().length === 0) {
      toast.error(`Please Enter the Details `);
    } else {
      try {
        const response = await toast.promise(
          axios.post(
            `${APIURL}/form/finddraw`,
            {
              siteID: siteID,
              drawCategory: drawcategory,
              organisationName: organisationname,
              websiteURLorFacebook: website,
              contactName: contactname,
              contactEmail: contactemail,
              contactDescription: description,
              entryDate: moment().format('YYYY-MM-DD hh:mm:ss'),
              userMeta: {
                website: 'PlayFundWin'
              }
            },
            {
              withCredentials: true,
              credentials: 'same-origin'
            }
          ),
          {
            pending: 'Please wait...'
          }
        );
        console.log(response);

        if (response.data.status === true && response.data.error === null) {
          toast('Thank you for your message, it has been sent, we will contact you shortly', { type: 'success' });
          contact();
          await timeout(1000);

          window.location.replace('/thankyou');
        } else {
          toast('Something went wrong While authenticating', { type: 'error' });
        }
      } catch (error) {
        console.log(error);
        if (error.response.status === 403) {
          toast.error(`${error.response.data.content.message}`);
        } else {
          toast.error(`${error.message}`);
        }
      }
    }
  }

  // const [NameOfOrg,SetNameOfOrg ] = useState('')
  // const [LinkOfWeb,SetLinkOfWeb ] = useState('')
  // const [SportEvent,SetSportEvent ] = useState('')
  // const [Contact,SetContact ] = useState('')
  // const [Email,SetEmail ] = useState('')
  // const [Message,SetMessage ] = useState('')

  // function SubmitIt(){
  //   if(NameOfOrg.trim().length === 0 || LinkOfWeb.trim().length === 0){
  //     toast.error('Form Incomplete');
  //   }else{
  //     alert('Success')
  //   }
  //   if(NameOfOrg.trim().length === 0){
  //       toast.error('Please Enter Name of Organisation');
  //   } else if(LinkOfWeb.trim().length === 0) {
  //     toast.error('Please Enter Link of Website');

  //   }

  //   if(LinkOfWeb.trim().length === 0) {
  //     toast.error('Please Enter Link of Website');

  //   }
  //   if(SportEvent.trim().length === 0) {
  //     toast.error('Please Select the Event');

  //   }
  //   if(Contact.trim().length === 0) {
  //     toast.error('Please Enter the Conact Name');

  //   }
  //   if(Email.trim().length === 0) {
  //     toast.error('Please Enter the Email');

  //   }
  //   if(Message.trim().length === 0) {
  //     toast.error('Please Enter the Message');

  //   }

  //   if (Email.trim().length === 0) {
  //     toast.error(`Please Enter Your Email`)
  //   } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(Email)) {
  //     toast.error(`Invalid Email`)
  //   }
  // }

  return (
    <>
      <Helmet>
        ‍<title>Find a Draw Form | Play Fund Win</title>‍
        <meta
          name="description"
          content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities."
        />
      </Helmet>
      <Siteheader />
      <ToastContainer />
      <section className="h-100 w-100 bg-f2 container px-lg-5 py-5 position-relative bg-before-after rounded-4">
        <div className="container w-75 h-100 p-lg-5 ">
          <div className="find-draw-form text-center">
            <h2 className="branding1 display-4">Suggest a Club, Event or Cause</h2>
            <p>
              Please let us know the name and details of the club, event or cause you couldn't find on our platform and
              we will look to get in touch with them.
            </p>
          </div>

          <div className="my-3">
            <select
              className="form-control rounded-pill my-md-2 p-3"
              onChange={(e) => {
                SetDrawCategory(e.target.value);
              }}
              value={drawcategory}
            >
              <option value="-">Select Category</option>
              <option value="Sports Club">Sports Club</option>
              <option value="Event">Event</option>
              <option value="Charity">Charity</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <div className="my-3 ">
            <input
              type="text"
              className="form-control rounded-pill my-md-2 p-3"
              onChange={(e) => {
                SetOrganisationName(e.target.value);
              }}
              value={organisationname}
              placeholder="Name of organisation"
            ></input>
          </div>

          <div className="my-3">
            <input
              type="text"
              className="form-control rounded-pill my-md-2 p-3"
              onChange={(e) => {
                SetWebsite(e.target.value);
              }}
              value={website}
              placeholder="Link to website/Facebook"
            ></input>
          </div>

          <div className="my-3">
            <input
              type="text"
              className="form-control rounded-pill my-md-2 p-3"
              onChange={(e) => {
                SetContactName(e.target.value);
              }}
              value={contactname}
              placeholder="Contact Name"
            ></input>
          </div>

          <div className="my-3">
            <input
              type="text"
              className="form-control rounded-pill my-md-2 p-3"
              onChange={(e) => {
                SetContactEmail(e.target.value);
              }}
              value={contactemail}
              placeholder="Contact Email"
            ></input>
          </div>

          <div className="my-3">
            <textarea
              rows="7"
              onChange={(e) => {
                SetDescription(e.target.value);
              }}
              value={description}
              className="form-control rounded-3 w-100 p-3"
              placeholder="Please provide any details"
            ></textarea>
          </div>

          <div className="my-3 w-100 text-center">
            <input
              onClick={findDrawFormHandler}
              type="submit"
              value="Submit"
              className="border-0 rounded-pill btn pfw-button-red pributton py-2 px-4 my-2 mx-2"
            />
          </div>
        </div>
      </section>

      <ToastContainer />
      <SiteFooter />
    </>
  );
}

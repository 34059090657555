import {React,useState} from 'react'
import Siteheader from '../components/header';
import SiteFooter from '../components/footer';
import HostSlider from '../components/hostSlider';
import HostImgSlider from '../components/hostSlider-Img';
import HostBg from '../assets/HostBg.svg';
import HostSliderSection from '../components/hostSlider-section';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios'
import { toast, Toast, ToastContainer } from 'react-toastify'
import moment from 'moment';
import HostSliderSection2 from '../assets/HostSliderSection2.png';
import HostSliderSection2_1 from '../assets/HostSliderSection2.1.png';
import HostSliderSection2_2 from '../assets/HostSliderSection2.2.png';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { contact } from '../utils/meta/metaPixelEvent';

export default function Host() {

  const [fullname,SetFullName] = useState('')
  const [causename,SetCauseName] = useState('')
  const [email,SetEmail] = useState('')
  const [message,SetMessage] = useState('')
  const [ageconsent,SetAgeConsent] = useState(false)

  function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  //const navigate =useNavigate();
  let APIURL = process.env.REACT_APP_API_URL;
  let siteID = process.env.REACT_APP_API_SITE_ID;

  async function hostFormHandler(){
    if (email.trim().length === 0  ) {
      toast.error(`Please Enter Your Email`);
    } else if (!/^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9-]+(?:\.[a-z0-9-]+)+(?:\.[a-z0-9-]+)*$/.test(email)) {
      toast.error(`Please Enter Your valid Email Address`);
    }
    else if (fullname.trim().length === 0  ) {
      toast.error(`Please Enter Full Name`);
    }
    else if (causename.trim().length === 0  ) {
      toast.error(`Please Enter Cause or Club Name`);
    }
    else if (message.trim().length === 0  ) {
      toast.error(`Please Enter about your Cause and your fundraising`);
    }
    else if (!ageconsent ) {
      console.log(ageconsent);
      toast.error(`Please Confirm you are 18 years or over`);
    }
    else{
try {
  const response = await toast.promise(axios.post(
    `${APIURL}/form/host`,
    {
      "siteID":siteID,
      "hostFullName": fullname,
      "entryDate":moment().format("YYYY-MM-DD hh:mm:ss"),
      "hostCauseOrClubName": causename,
      "hostEmail": email,
      "hostAboutCauseDetails": message,
      "hostAgeVerified": ageconsent,
      "userMeta": {
          "website": "PlayFundWin"
      }
  },
      {
        withCredentials: true,
        credentials: "same-origin"
      }
    ),
    {
      pending: 'Please wait...',
    }
  );
  console.log(response);

  if (response.data.status === true && response.data.error === null) {
    toast("Thank you for your message, it has been sent, we will contact you shortly", { type: "success" });
    contact();
    await timeout(1000);
    
      window.location.replace('/host-thank-you');

  } else {
    toast("Something went wrong While authenticating", { type: "error" });
  }
} catch (error) {
  console.log(error);
  if(error.response.status === 403){
    toast.error(`${error.response.data.content.message}`);
  }else{
    toast.error(`${error.message}`);
  }
}



    }
  }

  return (
    <>
    <Helmet>‍
      <title>Host a Draw | Play Fund Win</title>‍
      <meta name="description" content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities." />
    </Helmet>
    <div className='host-header'>
    <Siteheader/>
    </div>
    
    <section className='w-100 background-image position-relative py-5 host-banner' style={{backgroundImage: `url(${HostBg})`}}>
      <div className="container-lg" >
        <div className='row text-start py-5'>
            <div className='col-md-6 px-md-3 px-4 mb-md-0 mb-3'>
                <div>
                    <p className='text-light m-0 fs-1 fw-bold'>Join the revolutionary</p>
                    <p className=' text-light m-0 p-0 fw-bolder display-3'>Fundraising platform</p>
                    <p className='pt-4 fs-5 fw-bolder branding2'>The revolutionary digital fundraising platform that makes raising money simple for sports clubs, events and charities.</p>
                    <p className='pt-2 fs-5  color-white'>Play Fund Win make fundraising easy by taking the hassle out of licensing, allowing sports clubs of all sizes to raise money easily.</p>
                    <p className='pt-2 fs-5  color-white'>Our draws ensure a minimum of half of the net proceeds go to good causes, with the remainder won as a cash prize, plus our platform encourages subscriptions so sports clubs can create regular income.</p>
                  </div>
            </div>
            <div className='col-md-6 '>
                <div className='container'>
                    <div className='row d-flex justify-content-end  '>
                        <div className='col-lg-9 col-md-12 col-sm-12 p-lg-5 p-3 bg-f2 rounded-3'>
                        <p className='display-6 fw-bolder branding1'>Host a Draw</p>
                        <form className="">
                            <div className='my-3'>
                                <label className='w-100 mb-2 fw-bold form-label-group branding1'> Full Name</label>
                                <input type="text" className='w-100 form-control  border-0 py-2' name="fullname" value={fullname} onChange={(e)=>SetFullName(e.target.value)} />
                            </div>
                            <div className='my-3'>
                                <label className='w-100 mb-2 fw-bold form-label-group branding1'>Email Address</label>
                                <input type="text" className='w-100 form-control  border-0 py-2' name="email" value={email} onChange={(e)=>SetEmail(e.target.value)} />
                            </div>
                            <div className='my-3'>
                                <label className='w-100 mb-2 fw-bold form-label-group branding1'> Cause or Club Name</label>
                                <input type="text" className='w-100 form-control  border-0 py-2' name="cause_name" value={causename} onChange={(e)=>SetCauseName(e.target.value)} />
                            </div>
                            <div className='my-3'>
                                <label className='w-100 mb-2 fw-bold form-label-group branding1'>Tell us about your Cause and your fundraising needs?</label>
                                <textarea type="textarea" className='w-100 form-control  border-0 py-2' name="message" value={message} onChange={(e)=>SetMessage(e.target.value)} style={{height: 100}} />
                            </div>
                            <div className='my-3 d-flex align-items-start'>
                                <input type="radio" className='mt-2 border-0 ' name="tandc" checked={ageconsent} onChange={()=>SetAgeConsent(!ageconsent)} value="10" />
                                <label className='mx-2  px-2 form-label-group branding1'>I am aged 18 years or over and I am happy to be contacted by Play Fund Win</label>
                            </div>
                            <div className='my-3 '>
                                <Link to={'/privacy-policy'}className='branding2 fw-bold px-4'>Privacy Policy</Link>
                            </div>
                            <div className='my-3 w-100 text-center'>
                                {/* <input type="submit" className='pfw-button-yellow rounded-pill p-2 px-3' /> */}
                                <button className="btn pfw-button-yellow rounded-pill align-self-center px-5 p-2 fs-6 fw-bold" type="button" name="button" onClick={hostFormHandler}>Submit</button>
                            </div>
                        </form>
                    </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </section>
    
    <section className='logo-section pt-5 pb-5'>
        <div className='container'>
            <div className='row'>
                <h2 className='display-4 branding1 fw-normal text-center'>Who we've <strong>worked with</strong></h2>
            </div>
            <div className='row'>
                <HostImgSlider/>
            </div>
            <div className='row'>
                <HostSliderSection/>
            </div>
            
            <section className='found-out-more pt-4 pb-5'>
            <div className=' row py-5'>
            <h2 className='display-4 branding1 fw-normal text-center'>Find out <strong>More</strong></h2>
            </div>

            <div className='row '>
                <div className='col-md-4 col-xl-4 col-sm-12 mb-4  '>
                <Link className='text-decoration-none' to={'/grassroots-sports-clubs'}><div className='pfw-button-red p-4 m-0 rounded-3'><p className='fs-4 m-0 color-white'>Grassroots sports clubs<i className="fa fa-arrow-circle-right float-end pt-2" aria-hidden="true"></i></p> </div></Link>
                </div>
                <div className='col-md-4 col-xl-4 col-sm-12 mb-4  '>
                <div className='pfw-button-red p-4 m-0 rounded-3'><p className='fs-4 m-0 color-white'>Professional club foundation<i className="fa fa-arrow-circle-right float-end pt-2" aria-hidden="true"></i></p> </div>
                </div>
                <div className='col-md-4 col-xl-4 col-sm-12 mb-4  '>
                <Link className='text-decoration-none' to={'/charities'}><div className='pfw-button-red p-4 m-0 rounded-3'><p className='fs-4 m-0 color-white'>Charities<i className="fa fa-arrow-circle-right float-end pt-2" aria-hidden="true"></i></p> </div></Link>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-4 col-xl-4 col-sm-12 mb-4  '>
                <div className='pfw-button-red p-4 m-0 rounded-3'><p className='fs-4 m-0 color-white'>Venues<i className="fa fa-arrow-circle-right float-end pt-2" aria-hidden="true"></i></p> </div>
                </div>
                <div className='col-md-4 col-xl-4 col-sm-12 mb-4  '>
                <div className='pfw-button-red p-4 m-0 rounded-3'><p className='fs-4 m-0 color-white'>Artists<i className="fa fa-arrow-circle-right float-end pt-2" aria-hidden="true"></i></p> </div>
                </div>
                <div className='col-md-4 col-xl-4 col-sm-12 mb-4  '>
                <div className='pfw-button-red p-4 m-0 rounded-3'><p className='fs-4 m-0 color-white'>Influencers<i className="fa fa-arrow-circle-right float-end pt-2" aria-hidden="true"></i></p> </div>
                </div>
            </div>
            </section>

           

            <div className='row'>
                <div className='col-sm-12 col-md-12 col-lg-6 '>
                    <div>
                      <LazyLoadImage
                        width={'100%'}
                        src={HostSliderSection2}
                        alt='host page image'
                      />
                {/* <img width={'100%'} src={require('../assets/HostSliderSection2.png')}/> */}
                    </div>
                </div>
                <div className='col-sm-12 col-md-12 col-lg-6 mt-lg-0 mt-4'>
                    <div className='row'>
                        <div className='col-12'>
                        <h2 className='display-4 branding1 fw-normal text-start'>Who has <strong>Benefitted</strong></h2>
                        <p className='branding1 fw-normal text-start'>Our revolutionary digital platform has benefited both hosts and players, by enabling teams, charities and events to raise money for great causes while players reap the rewards of amazing prizes in both gifts and experiences. We pride ourselves in helping local communities and charities, with a minimum of 50% of money raised going directly to the cause, meaning that every ticket purchased for our draws has a direct impact.</p>

                        <p className='branding1 fw-normal text-start'>Working alongside sports clubs, charities and other good cause organisations, we have created a comprehensive digital platform which is simple to use for hosts and players.</p>

                        <p className='branding1 fw-normal text-start'>We have also revolutionised the way players can buy tickets by simplifying the process safely and enabling both hosts and players to hold a digital wallet into which funds are released after draws. This has made it easier for players to purchase tickets to support their cause of choice and also increased the number of players involved in draws.</p>

                        <p className='branding1 fw-normal text-start'>Our main objective is to enable organisations, events, clubs, and charities to raise money for amazing causes and improve communities nationwide, however; we understand that this is not possible without players purchasing tickets. Therefore, we continue to maintain amazing cash, club and experience prizes to motivate players into supporting their causes and increase their chances of winning. Our platform has benefited local communities, foundations, grassroots teams, and charities for years, and continuously improves accessibility in order to benefit fundraising further. Working alongside huge teams such as Manchester City, Newcastle United, Chelsea, Crystal Palace and Brentford but also non-league teams, our reputation is cemented as a fundraising platform for charitable causes of all sizes, this includes yours!</p>
                        </div>
                    </div>
                        <div className='p-lg-4 p-2'></div>
                    
                </div>
                
            </div>
            <div className='row'>
                    <div className='col-lg-6 col-md-6 col-sm-12 mb-3'>
                            <div>
                              <LazyLoadImage
                                width={'100%'}
                                src={HostSliderSection2_1}
                                alt='host page image'
                              />
                            {/* <img width={'100%'} src={require('../assets/HostSliderSection2.1.png')}/> */}
                            </div>  
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 mb-2'>
                            <div>
                              <LazyLoadImage
                                width={'100%'}
                                src={HostSliderSection2_2}
                                alt='host page image'
                              />
                            {/* <img width={'100%'} src={require('../assets/HostSliderSection2.2.png')}/> */}
                            </div>  
                        </div>
                    </div>
            </div>
    </section>

    <section className='host-slider-sec'>
    <div className=' bg-branding1 p-md-5 py-5 rounded-3 '>
        <div className='row py-4 '>
        <h2 className=' pb-2 px-3 fw-normal text-center display-4 color-white'>Who can host <strong>Play Fund Win</strong> draws?</h2>
        </div>
        <div className='row justify-content-center pb-5'>
            <div className='col-sm-12 col-md-12 col-lg-12'>
            <HostSlider/>
            </div>
        </div>
    </div>

      <div className='container pt-5'>
      <div className='py-md-2 my-md-2 text-center row justify-content-center'  align="center">
        <h1 className='branding1'>The benefits of <span className='fw-bolder'>Play Fund Win</span> draws</h1>
        <p align="center" className='text-muted fw-normal py-1 fs-6 px-5 col-xs-1'>Any organisation can stage Play Fund Win draws, providing the proceeds are not being used for personal or commercial gain.</p>
      </div>
      </div>

      <div className='container'>
        <div className='row py-md-3' align="center">
          <div className='col-sm-4 p-md-0 p-3'>
            <div className='shadow-lg m-3 mx-md-5 p-3 pt-5 h-100 ' style={{borderBottom: '3px solid #0e3160'}}>
            <img src={require('../assets/images/Group-79-1.png')} alt="host page image" />
            <h5 className='branding1 p-3 '>Sign-up and get your own draw page</h5>
            <p className='text-muted fw-semibold'>Help to raise essential funds, with a minimum of 50% of net proceeds from all draws going directly to charities, sports clubs or other good causes – more than any other fundraising draw platform.</p>
            </div>
          </div>
          <div className='col-sm-4 p-md-0 p-3'>
          <div className='shadow-lg m-3 mx-md-5 p-3 pt-5 h-100' style={{borderBottom: '3px solid #0e3160'}}>
          <img src={require('../assets/images/Group-7947.png')} alt="host page image" />
          <h5 className='branding1 p-3'>No setup cost</h5>
          <p className='text-muted fw-semibold'>It is free to host a draw, we have no hidden charges, no minimum player numbers and no set-up costs. We charge a small operating fee to cover our own costs.</p>
          </div>
          </div>
          <div className='col-sm-4 p-md-0 p-3'>
          <div className='shadow-lg m-3 mx-md-5 p-3 pt-5 h-100' style={{borderBottom: '3px solid #0e3160'}}>
          <img src={require('../assets/images/Group-794.png')} alt="host page image" />
          <h5 className='branding1 p-3'>Raise money</h5>
          <p className='text-muted fw-semibold'>Through our simple sign-up process you can create your own draw page hosted on our platform. We’re always available via chat if you have any questions. </p>
          </div>
          </div>
        </div>

        <div className='row py-md-3' align="center">
          <div className='col-sm-4 p-md-0 p-3'>
          <div className='shadow-lg m-3 mx-md-5 p-3 pt-5 h-100' style={{borderBottom: '3px solid #0e3160'}}>
          <img src={require('../assets/images/telephone-100x100.webp')} alt="host page image" />
            <h5 className='branding1 p-3'>Great prizes for your supporters</h5>
            <p className='text-muted fw-semibold'>Our team can create bespoke marketing materials to assist in promoting your fundraising draw, free of charge.</p>
          </div>
          </div>
          <div className='col-sm-4 p-md-0 p-3'>
          <div className='shadow-lg m-3 mx-md-5 p-3 pt-5 h-100' style={{borderBottom: '3px solid #0e3160'}}>
            <img src={require('../assets/images/Group-7947.png')} alt="host page image" />
          <h5 className='branding1 p-3'>Marketing help</h5>
          <p className='text-muted fw-semibold'>We take the hassle out of communicating with entrants by Notifying the winners and distributing the prizes for you.</p>
          </div>
          </div>
          <div className='col-sm-4 p-md-0 p-3'>
          <div className='shadow-lg m-3 mx-md-5 p-3 pt-5 h-100' style={{borderBottom: '3px solid #0e3160'}}>
          <img src={require('../assets/images/Group-7950.png')} alt="host page image" />
          <h5 className='branding1 p-3'>Reporting</h5>
          <p className='text-muted fw-semibold'>We provide detailed reporting to draw hosts, allowing them to monitor the success of their draw and associated marketing campaigns. </p>
          </div>
          </div>
        </div>

        <div className='row py-3 justify-content-center'>
        <p align="center" className='text-danger fw-semibold w-100 p-3 px-md-5 col-xs-1'>Still have more questions? <Link to={'/help-centre'}  className='text-decoration-none fw-bold branding1'>Read our FAQ</Link></p>
        </div>
      </div>

      <div className='container bg-branding3 p-3 rounded-3 bottom-form-sec'>
        <div className='row pt-5 '>
        <h1 className='fw-bolder  text-center display-5 color-white'>Thinking of starting a draw?</h1>
        </div>
        <div className='row '>
          <div className='row justify-content-center'>
        <p align="center" className='fw-normal p-2 w-75 text-center color-white col-md-5'>Complete this form and we will be in touch to help you get started on your fundrising journey.</p>
          </div>
          <form>

            <div className='row justify-content-center w-75 m-auto mb-3'>
                
                <div className='col-sm-12 col-md-5 p-md-2'>
                    <div className='w-100'>
                    <label className='py-2 w-100 color-white fw-normal'>Full Name</label>
                    <input type="text" className='p-2 rounded-3 border-0 form-control' value={fullname} onChange={(e)=>SetFullName(e.target.value)} />
                    </div>
                </div>
                <div className='col-sm-12 col-md-5 p-md-2'>
                    <div className='w-100'>
                    <label className='py-2 w-100 color-white fw-normal'>Email Address</label>
                    <input type="text" className='p-2 rounded-3 border-0 form-control' value={email} onChange={(e)=>SetEmail(e.target.value)} />
                    </div>
                </div>
                <div className='col-sm-12 col-md-10 p-md-2'>
                    <div className='w-100'>
                    <label className='py-2 w-100 color-white .'>Cause or Club Name</label>
                    <input type="text" className='p-2 rounded-3 border-0 form-control' value={causename} onChange={(e)=>SetCauseName(e.target.value)} />
                    </div>
                </div>
                <div className='col-sm-12 col-md-10 p-md-2'>
                    <div className='w-100'>
                    <label className='py-2 w-100 color-white fw-normal'>Tell us about your Cause and your fundraising needs?</label>
                    <textarea type="textarea" className='p-2 rounded-3 border-0 form-control' value={message} onChange={(e)=>SetMessage(e.target.value)} style={{height: 100}} />
                    </div>
                </div>
                <div className='col-sm-12 col-md-10 p-md-2'>
                    <div className='w-100 d-flex align-items-start'>
                    <input type="radio" className='p-3 mt-4' checked={ageconsent} onChange={()=>SetAgeConsent(!ageconsent)} value="10" />
                    <label className='p-3 color-white fw-normal'>I am aged 18 years or over and I am happy to be contracted by Play Fund Win <Link to={'/privacy-policy'} className='branding2 fw-bold text-decoration-none'>Privacy Policy</Link></label>
                    </div>
                </div>
                
                
            </div>
                <div className='row justify-content-center  pb-5'>
                <div className='w-100 col-md-5 text-center'>
                {/* <button type='submit' className='pfw-button-white px-5 p-3 rounded-pill fw-bold'>Start a draw</button> */}
                <button className="pfw-button-white px-5 p-3 rounded-pill fw-bold" type="button" name="button" onClick={hostFormHandler}>Start a draw</button>
                </div>
            </div>

          </form>
        </div>
      </div>
      
    </section>
    <ToastContainer />
    <SiteFooter/>
    </>
  )
}

import React from 'react'
import { Link } from "react-router-dom";
import Siteheader from '../components/header';
import SiteFooter from '../components/footer';
import { UseEffect } from 'react';
import FooterCta from '../components/footer-cta';
import Innerheader from '../components/inner-header';
import { Helmet } from 'react-helmet';

export default function HostTermsConditions(props) {
 
  return (
    <>
    <Helmet>‍
      <title>Host Terms and Conditions | Play Fund Win</title>‍
      <meta name="description" content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities." />
    </Helmet>
    <Siteheader/>
    <Innerheader title="Host Terms and Conditions"/>

  <div className='common-content-area anchor-hover-grn container pt-5 pb-5 my-3 px-lg-5 rounded-4 w-92' style={{backgroundColor: '#f2f2f2'}}>
    <div className="row px-lg-5">
       <div className="col-md-12 align-middle text-left px-lg-5 mt-lg-0 mt-3">
            <p><span data-doc-id={5180277000000107001} data-doc-type="writer">These terms </span>set out the basis upon which you may use <strong><Link to={'/'}>playfundwin.com</Link> </strong>and explains how our &nbsp;charity prize draws (draws) are operated.</p>
            <h4><span data-doc-id={5180277000000107001} data-doc-type="writer">1. Who we are and how to contact </span>us</h4>
            <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">1.1. </span>{/*Zoho writer list char starts ends*/}<Link to={'/'}>www.playfundwin.com</Link> is a site operated by PFW Holdings Ltd (we, us), a company registered in England and Wales under company number 12267104 and have our registered office at PFW Holdings Ltd 2 & 3 Westfield House Millfield Lane Nether Poppleton York YO26 6GA. Our VAT number is 359 7319 55.</p>
            
            <p>1.2. To contact us, please email <a href="mailto:hello@playfundwin.com">hello@playfundwin.com</a>.</p>
            <h4 className="zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:0}">2. By using our site you accept these terms</h4>
            <p>By hosting a draw on our site, you confirm that you accept these terms of use, and that you agree to comply with them. If you do not agree with any of these terms then you may not access or use the website, register for an account, or use any of the services. We recommend that you print a copy of these terms for future reference.</p>
            <h4 className="zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:0}"><span data-range-char-type="start" data-bookmark-info="{&quot;name&quot;:&quot;a474040&quot;,&quot;id&quot;:&quot;zw_bm_3&quot;,&quot;type&quot;:&quot;bookmark&quot;}" data-bookmark-id="zw_bm_3">3. </span>There are other terms that may apply to you</h4>
            <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">3.1. </span>{/*Zoho writer list char starts ends*/}These terms of use refer to the following additional terms, which also apply to your use of our site:</p>
            <p>3.2. Our Privacy &amp; Cookie Policy <Link to={"/privacy-policy"}>https://playfundwin.com/privacy-policy/</Link>. See further under ‘How we may use your personal information’.</p>
            <h4 className="zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:0}"><span data-range-char-type="start" data-bookmark-info="{&quot;name&quot;:&quot;a671911&quot;,&quot;id&quot;:&quot;zw_bm_5&quot;,&quot;type&quot;:&quot;bookmark&quot;}" data-bookmark-id="zw_bm_5">4. </span>We may make changes to these terms</h4>
            <p>We amend these terms from time to time without notice. Every time you wish to use our site, please check these terms to ensure you understand the terms that apply at that time.</p>
            <h4 className="zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:0}">5. We may make changes to our site</h4>
            <p>We may update and change our site from time to time to reflect changes to our products, our users’ needs and our business priorities or as a result of regulatory requirements.</p>
            <h4 className="zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:0}">6. Our site is only for users in the UK</h4>
            <p>Our site is directed and targeted at people residing in the United Kingdom. It is not intended to encourage people outside of the UK or Isle of Man to participate in draws on playfundwin.com and nothing on the site is intended as an offer or invitation by us to any person to do so. You must only access your account when you are physically located in the UK. We do not represent that content available on or through our site is appropriate for use or available in other locations.</p>
            <h4 className="zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:0}">7. Registration and account creation</h4>
            <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">7.1. </span>{/*Zoho writer list char starts ends*/}In order to use our services and create a draw, you will need to register for a host account. You can do this through direct contact with our team or via our website by following the on-screen instructions that are provided after clicking the “create account” button or link.</p>
            <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">7.2. </span>{/*Zoho writer list char starts ends*/}To open a host account, you must be over the age of 18. If you are under the age of 18 and you open an account and/or create a draw you may be committing a criminal offence. Prior to making any payment to you, your age will be verified by one of our intermediaries on our behalf. In the event that a person under the age of 18 registers an account and hosts a draw we may withhold any payments which would otherwise have been due to that person and instead pay them to a registered charity or social enterprise of our choice.</p>
            <p>7.3. By opening a host account, you represent and warrant to us that you are an authorised representative of the society (as defined at paragraph 14) that you hold yourself out as representing. You confirm that you have been duly appointed by that society in accordance with its constitution and/or rules of governance to create and run draws for it on its behalf.</p>
            <h4 className="zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:0}">8. You must keep your account details safe</h4>
            <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">8.1. </span>{/*Zoho writer list char starts ends*/}If you create a host account you will be required to choose a user identification code and a password as part of our security procedures. You must treat such information as confidential and you must not disclose it to any third party.</p>
            <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">8.2. </span>{/*Zoho writer list char starts ends*/}We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these terms of use.</p>
            <p>8.3. If you know or suspect that anyone other than you learns of your user identification code or password, you must promptly notify us at <a href="mailto:hello@playfundwin.com">hello@playfundwin.com</a>.</p>
            <h4>9. KYC checks</h4>
            <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">9.1. </span>{/*Zoho writer list char starts ends*/}We are obliged by our regulators and by law to carry out identity verification checks before a draw can be started and/or when transferring any payment to a winner or to a host. These checks are undertaken for the purpose of preventing money laundering and terrorist financing. We take these obligations very seriously. In the event that any host is unable to satisfy our anti-money laundering and terrorist financing checks we will be obliged by law to withhold any payments which would otherwise have been due to that host and we will instead pay them to a registered charity or social enterprise of our choice.</p>
            <p>9.2. The first time a host creates a draw or the first time that a player wins a prize on our site they may be contacted by us or our verification partner to check the player’s identity and to cross reference their identity against various national crime databases. Our verification partner will provide a copy of their privacy policy and will process all personal data in accordance with the GDPR and all relevant data privacy laws. We will not be sent any additional personal information about the host or player in question by our verification partner, other than a statement as to whether the player in question passed or failed the verification process and anti-money laundering checks.</p>
            <h4 className="zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:0}">10. Creating draws</h4>
            <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">10.1. </span>{/*Zoho writer list char starts ends*/}Hosts are wholly and solely responsible for any draws created by or for them, including ensuring that they are conducted and marketed in a lawful way.</p>
            <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">10.2. </span>{/*Zoho writer list char starts ends*/}Hosts may create a new draw by contacting our team, or by logging into their user area, clicking the “Create A Draw” tab and following the process. Through the draw creation process, hosts will set the draw parameters, including the duration of the draw period; the name of the draw; the categorisation of the draw; the description; the ticket price (within any limits that we may set from time to time); the percentages of the ticket sale proceeds which will be distributed as a prize or retained by the host, subject to the deduction of our fee; and any fundraising ceiling (within any limits that we or any relevant local authority may set from time to time). All of these parameters are subject to any limitations that we may create from time to time in our absolute discretion.</p>
            <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">10.3. </span>{/*Zoho writer list char starts ends*/} After a draw is created, it must be approved by us, and in the case of licenced draws (Lotteries), the relevant local authority (or, in the case of a large society lottery licence holder, the Gambling Commission). For licenced draws, a fee may be payable by a host in relation to this approval, depending upon which local authority the society is located in. We will manage this process on the behalf of the hosts based upon the information the host provided when creating the draw.</p>
            <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">10.4. </span>{/*Zoho writer list char starts ends*/}Creating a draw does not create any binding obligation on us to keep any draws live or on our website. We may, at any time where in our opinion it is reasonable to do so (including but not limited the refusal of any necessary local authority registration or approval of the draw of your society, or in the event of the withdrawal or cancellation by the local authority of such registration or approval), cancel any draw and cancel, return or otherwise unwind any transaction or ticket purchase that has been made in respect of that draw.</p>
            <p>10.5. When a draw is created you will be provided with a unique URL linking to your draw page. Subject to paragraph 10.1 above you may disseminate this URL to such persons or in such manner as you see fit in order to market and promote your draw.</p>
            <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">10.6. </span>{/*Zoho writer list char starts ends*/}Our only binding obligations in respect of draws is limited to:</p>
            <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="0.492in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:2}">{/*Zoho writer list char starts*/}</p>
            <div className="bollet-event">
                <p><span className="zw-list-char">10.6.1. </span>{/*Zoho writer list char starts ends*/}conducting checks into the identity of hosts and players as set out at paragraph 9;</p>
                <p><span className="zw-list-char">10.6.2. </span>arranging payments pursuant to paragraph 12 to the Host and to any winners of a successfully completed draw, provided that such winners and/or host have passed any KYC checks required as is set out at paragraph 9.</p>
                <h4 className="zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:0}">11. How we run draws</h4>
                <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">11.1. </span>{/*Zoho writer list char starts ends*/}When a draw goes live on our site any individual with a player account may purchase a ticket or tickets to the draw, subject to the terms of the draw and any limits set by a host during its creation or that we place upon players. Tickets will be available to purchase until the earlier of (i) the end of the draw period; or (ii) such time as the draw limit is reached. Once a ticket is purchased by a player it is entered into the draw immediately and is not refundable. For unlicensed Prize Draws a free entry route will also be made available.</p>
                <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">11.2. </span>{/*Zoho writer list char starts ends*/}The proceeds of all ticket sales will be held by us in a separate client account. This client account is used for holding the proceeds of ticket sales only and will not be used by us for any day-to-day business expenses.</p>
                <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">11.3. </span>{/*Zoho writer list char starts ends*/}Within 14 days of the end of a draw period, we will select the winner(s) of that draw at random from all of the tickets that were purchased in respect of that draw. One ticket gives a player one entry within that draw. The winner(s) are selected entirely at random using an encrypted random number generator. The result of a draw is final and may not be appealed or petitioned against. If a draw is interrupted due to equipment failure or for any other reason then it will be rescheduled and take place as soon as reasonably possible after the originally scheduled date. We may, where we consider it reasonable to do so, postpone a draw. If such a delay occurs, we have no obligation to notify the host or any players.</p>
                <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">11.4. </span>{/*Zoho writer list char starts ends*/}The selected winner(s) will be contacted by us via email. The selected winner(s) will be contacted by us via email. The selected winner(s) must respond to the email and complete the relevant form, including, where appropriate, providing their bank account details or debit card details. If this step is not completed, the prize will be deemed to be unclaimed and that winner will be treated as a non-responsive winner and the draw will be re-run in accordance with paragraph 11.5 below. If any winner(s) does not respond to our initial email notification, we will make at least two further attempts to reach them using the contact details that they have provided.</p>
                <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">11.5. </span>{/*Zoho writer list char starts ends*/}If we are still unable to contact a winner(s), or if any given winner(s) fails to pass our identity and anti-money laundering verification checks as detailed at paragraph 9 or fails to complete the process set out at paragraph 11.4 above within 14 days of being notified we will remove all tickets purchased by that/those winner(s) and re-run the draw in respect of that prize to select a new winner(s). This process will be reiterated until a qualifying winner is found. If a re-run becomes impossible due to all of the tickets having been removed due to failure(s) to pass our anti-money laundering checks or the winners being uncontactable then any payment that would have been made to a winner(s) will instead be paid to a registered charity or social enterprise of our choice.</p>
                <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">11.6. </span>{/*Zoho writer list char starts ends*/}Non-cash prizes are the responsibility of the host to distributed. The prize can be delivered to the winner(s) or collected from the host, at the winner’s election. Collection of the prize is the sole responsibility of the winner(s). Unless alternative arrangements are agreed between the parties, the prize must be collected from the host’s address. The host will pay for the cost of delivering the prize to the winner(s) at the winner’s address in the United Kingdom and will insure the prize until it is delivered to winner’s address.</p>
                <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">11.7. </span>{/*Zoho writer list char starts ends*/}In the event that a winner dies before they are able to claim their prize, a person can claim on the behalf their estate if they can prove that they are entitled to make the claim. A grant of probate may be required by us as proof. The person claiming will need to submit any reasonably required documents to us within 14 days of the draw winning email notification being sent.</p>
                <p>11.8. We do not represent or warrant that any number of tickets will be sold or that we will market or do anything to present your draw in any way.</p>
                <h4><span data-range-char-type="start" data-bookmark-info="{&quot;name&quot;:&quot;_Ref52202157&quot;,&quot;id&quot;:&quot;zw_bm_14&quot;,&quot;type&quot;:&quot;bookmark&quot;}" data-bookmark-id="zw_bm_14">12. </span>Our fees and payments on completion of draws</h4>
                <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">12.1. </span>{/*Zoho writer list char starts ends*/}Upon the successful completion of a draw:</p>
                <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="0.492in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:2}">{/*Zoho writer list char starts*/}</p>
                <div className="bollet-event">
                    <p><span className="zw-list-char">12.1.1. </span>{/*Zoho writer list char starts ends*/}subject to the completion of any necessary KYC, verification and identity checks as are set out in paragraph 9, a sum calculated in accordance with the ‘Draw Beneficiary Percentage’ value set when the host created the draw (which must be set at a minimum of 20%) (being the net value of the ticket sales multiplied by the host percentage, less the cost of any KYC checks which must be carried out in respect of the Host) will be sent to the bank account that the host provided within its user profile area;</p>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="0.492in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:2}">{/*Zoho writer list char starts*/}<span className="zw-list-char">12.1.2. </span>{/*Zoho writer list char starts ends*/}subject to the completion of any necessary KYC, verification and identity checks as are set out in paragraph 9, a sum calculated in accordance with the ‘Prize Percentage’ value set when the host created the draw (being the gross value of the ticket sales multiplied by the winner’s percentage, less the cost of any KYC checks which must be carried out in respect of the relevant winning player) will be sent to the debit card or bank account that the winning player provided; and</p>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="0.492in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:2}">{/*Zoho writer list char starts*/}<span className="zw-list-char">12.1.3. </span>{/*Zoho writer list char starts ends*/}A sum equal to 15% of the total gross proceeds of the draw (being the gross value of the ticket sales, less the cost of any KYC checks which must be carried out in respect of the relevant winning player) will be transferred to us.</p>
                </div>
                <p className="zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="0.0in" data-text-indent="-0.5in" data-padding-left="0.5in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true">For regulatory purposes, our systems are configured such that the minimum that the ‘host percentage’ value can be set to is 20%. If, for any reason including but not limited to a technical error, a host finds that it is able to set the ‘host percentage’ at a value lower than 20% when creating a draw then the host must inform us immediately and must not set a ‘host percentage’ value below 20%.</p>
                <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">12.2. </span>{/*Zoho writer list char starts ends*/}All payments will be sent to the debit card or bank account that you have provided. It is your responsibility to ensure that your bank details are correct and kept up to date. We do not accept any liability for any loss that may arise from your failure to do so.</p>
                <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">12.3. </span>{/*Zoho writer list char starts ends*/}Any debit card details that are provided by hosts or players will be held by a secure SSL encrypted third party payment processor. We will not have access to this information, other than the name on the card and the last four digits of the card number which we may use for the purpose of identity confirmation.</p>
                <p>12.4. Any payments may also be subject to any restrictions that are placed on us by law or the Gambling Commission. We will inform any you if any of these restrictions will affect any payments which relate to a draw that you have created.</p>
                <h4 className="zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:0}">13. Cancelled draws</h4>
                <p className="zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="0.394in" data-text-indent="-0.5in" data-padding-left="0.5in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true">Further to paragraph 10.3 above, we may from time to time have to cancel a draw. In the event that a draw is cancelled we will refund any ticket purchase that has been made in respect of that draw. Any such refund given will be sent directly back to the bank account or card that was used to make the payment. No interest will be paid on any refunds made.</p>
                <h4 className="zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:0}"><span data-range-char-type="start" data-bookmark-info="{&quot;name&quot;:&quot;a107208&quot;,&quot;id&quot;:&quot;zw_bm_15&quot;,&quot;type&quot;:&quot;bookmark&quot;}" data-bookmark-id="zw_bm_15">14. </span>Our responsibility for loss or damage suffered by you</h4>
                <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">14.1. </span>{/*Zoho writer list char starts ends*/}In creating a host account, you represent and warrant to us that you represent a sports club, charity, charitable association or a similar unincorporated body (a society) and you accept that you are not accessing our site as a consumer.</p>
                <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">14.2. </span>{/*Zoho writer list char starts ends*/}We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.</p>
                <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">14.3. </span>{/*Zoho writer list char starts ends*/}You are responsible for any decision made by you based on information on this site. We accept no liability for loss or damage (including, without limitation, any special, direct, indirect or consequential loss or damage or other losses or damage of whatever kind) that arise out of or relate to the use of this site or its contents.</p>
                <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">14.4. </span>{/*Zoho writer list char starts ends*/}We exclude all implied conditions, warranties, representations or other terms that may apply to our site or any content on it to the maximum extent permissible by law.</p>
                <p>14.5. We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with use of, or inability to use, our site, or use of or reliance on any content displayed on our site. In particular, we will not be liable to your society for loss of profits; sales; business; revenue; any interruption to the operation of your society; loss of anticipated savings; loss of opportunity; loss of goodwill; or loss of reputation.</p>
                <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">14.6. </span>{/*Zoho writer list char starts ends*/}We will not be liable to any person for:</p>
                <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="0.492in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:2}">{/*Zoho writer list char starts*/}</p>
                <div className="bollet-event">
                    <p><span className="zw-list-char">14.6.1. </span>{/*Zoho writer list char starts ends*/}the failure or destruction of, or damage to, all or part of our IT systems or records, including any systems operated by third parties upon which we rely;</p>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="0.492in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:2}">{/*Zoho writer list char starts*/}<span className="zw-list-char">14.6.2. </span>{/*Zoho writer list char starts ends*/}any refusal to allow a person to enter a draw for any reason;</p>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="0.492in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:2}">{/*Zoho writer list char starts*/}<span className="zw-list-char">14.6.3. </span>{/*Zoho writer list char starts ends*/}any errors caused by incorrect or inaccurate automatic processing, including the selection of a winner of a draw, or our records or any third party records upon which we rely;</p>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="0.492in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:2}">{/*Zoho writer list char starts*/}<span className="zw-list-char">14.6.4. </span>{/*Zoho writer list char starts ends*/}any events beyond our reasonable control (including but not limited to terrorism, fire, flood, drought, power cut and/or failure or obstruction of any network, pandemic, epidemic, any public health emergency, broadcasting or telecommunications service, war, strike, lockout, industrial action); or</p>
                    <p><span className="zw-list-char">14.6.5. </span>delays, losses or mistakes on the part of any email system, courier, the postal service or any bank.</p>
                    <h4 className="zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:0}"><span data-range-char-type="start" data-bookmark-info="{&quot;name&quot;:&quot;a641274&quot;,&quot;id&quot;:&quot;zw_bm_16&quot;,&quot;type&quot;:&quot;bookmark&quot;}" data-bookmark-id="zw_bm_16">15. </span> Do not rely on information on this site</h4>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">15.1. </span>{/*Zoho writer list char starts ends*/}The content on our site is provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on our site.</p>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">15.2. </span>{/*Zoho writer list char starts ends*/}Although we make reasonable efforts to update the information on our site, we make no representations, warranties or guarantees, whether express or implied, that the content on our site is accurate, complete or up to date.</p>
                    <h4 className="zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:0}">16. How we may use your personal information</h4>
                    <p>We will only such personal information as you provide to us in accordance with our privacy policy <Link to={"/privacy-policy"}>https://playfundwin.com/privacy-policy/</Link>.</p>
                    <h4>17. Acceptable use and uploading content to our site</h4>
                    <p>Whenever you make use of a feature that allows you to upload content to our site, to share content with our team (via email, fileshares or other data sharing platforms), or to make contact with other users of our site, you must comply with the following content standards (our Acceptable Use Policy):</p>
                    <p>17.1.1. You may not use our site in any way that breaches any applicable local, national or international law or regulation;</p>
                    <p>17.1.2. You may not use our site in any way that is unlawful or fraudulent or has any unlawful or fraudulent purpose or effect;</p>
                    <p>17.1.3. You may not use our site for the purpose of harming or attempting to harm minors in any way;</p>
                    <p>17.1.4. You may not use our site to bully, insult, intimidate or humiliate any person;</p>
                    <p>17.1.5. You may not use our site to send, knowingly receive, upload, download, use or re-use any material which:</p>
                    <div className="bollet-event-drop">
                        <p>a) is inaccurate (where it states facts);</p>
                        <p>b) is not genuinely held (where it states opinions);</p>
                        <p>c) is defamatory of any person;</p>
                        <p>d) is obscene, offensive, hateful or inflammatory;</p>
                        <p>e) promotes or includes any pornographic or sexually explicit material;</p>
                        <p>f) includes child sexual abuse material;</p>
                        <p>g) promotes violence;</p>
                        <p>h) promotes discrimination based on race, sex, religion, nationality, disability, sexual orientation or age;</p>
                        <p>i) infringes any copyright, database right or trade mark of any other person;</p>
                        <p>j) is likely to deceive any person;</p>
                        <p>k) breaches any legal duty owed to a third party, such as a contractual duty or a duty of confidence;</p>
                        <p>l) promotes any illegal content or activity;</p>
                        <p>m) is in contempt of court;</p>
                        <p>n) is threatening, abuse or invade another’s privacy, or cause annoyance, inconvenience or needless anxiety;</p>
                        <p>o) is likely to harass, upset, embarrass, alarm or annoy any other person;</p>
                        <p>p) impersonates any person or misrepresent your identity or affiliation with any person;</p>
                        <p>q) advocated, promote, incite any party to commit, or assist any unlawful or criminal act such as (by way of example only) copyright infringement or computer misuse;</p>
                        <p>r) contains a statement which you know or believe, or have reasonable grounds for believing, that members of the public to whom the statement is, or is to be, published are likely to understand as a direct or indirect encouragement or other inducement to the commission, preparation or instigation of acts of terrorism;</p>
                        <p>s) contains any advertising or promote any services or web links to other sites;</p>
                    </div>
                    <p>17.1.6. You may not use our site to transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam);</p>
                    <p>17.1.7. You may not use our site to knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware;</p>
                    <p>17.1.8. You agree not to reproduce, duplicate, copy or re-sell any part of our site except as is expressly permitted elsewhere within these terms of use; and</p>
                    <p>17.1.9. You agree not to access without authority, interfere with, damage or disrupt:</p>
                    <div className="bollet-event-drop">
                        <p>a) any part of our site;</p>
                        <p>b) any equipment or network on which our site is stored;</p>
                        <p>c) any software used in the provision of our site; or</p>
                        <p>d) any equipment or network or software owned or used by any third party.</p>
                    </div>
                    <p>17.2. You warrant that any such content contribution or uploaded material complies with these standards, and you will be liable to us and indemnify us in full on demand for any breach of this warranty, including our legal costs on an indemnity basis. This means you will be responsible for any loss or damage we suffer as a result of your breach of this warranty.</p>
                    <p>17.3. Any content you upload to our site or shared with our team will be considered non-confidential and non-proprietary. You retain all of your ownership rights in your content, but as a condition of uploading or sharing any content with us, you hereby agree to grant us a limited licence to use, store and copy that content and to distribute and make it available to third parties. The rights you license to us are described in Rights you are giving us to use material you upload.</p>
                    <p>17.4. We also have the right to disclose your identity to any third party who is claiming that any content posted or uploaded by you to our site constitutes a violation of their intellectual property rights, or of their right to privacy.</p>
                    <p>17.5. We have an absolute right to remove any postings or content uploaded to our site if, in our opinion, your post or content does not comply with the content standards set out above.</p>
                    <p>17.6. You are solely responsible for securing and backing up your content.</p>
                </div>
            </div>
            <div>
                <div className="bollet-event-drop" />
                <div className="ons">
                    <h4 className="zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:0}">18. Rights you are giving us to use material you upload</h4>
                    <p className="zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="0.394in" data-text-indent="-0.5in" data-padding-left="0.5in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true">When you upload or post content to our site, or share content with our team, you grant us a worldwide, perpetual, irrevocable, non-exclusive, royalty-free, transferable licence to use, reproduce, distribute, prepare derivative works of, display, and perform that user-generated content in connection with the service provided by the website and across different media including to promote the site or the service.</p>
                    <h4 className="zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:0}">19. How you may use material on our site</h4>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">19.1. </span>{/*Zoho writer list char starts ends*/}Except where otherwise indicated, we are the owner or the licensee of all intellectual property rights in our site, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.</p>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">19.2. </span>{/*Zoho writer list char starts ends*/}You may print off one copy, and may download extracts, of any page(s) from our site for your personal use and you may draw the attention of others within your organisation to content posted on our site.</p>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">19.3. </span>{/*Zoho writer list char starts ends*/}You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</p>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">19.4. </span>{/*Zoho writer list char starts ends*/}Our status (and that of any identified contributors) as the authors of content on our site must always be acknowledged.</p>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">19.5. </span>{/*Zoho writer list char starts ends*/}You must not use any part of the content on our site for commercial purposes without obtaining a licence to do so from us or our licensors.</p>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">19.6. </span>{/*Zoho writer list char starts ends*/}If you print off, copy or download any part of our site in breach of these terms of use, your right to use our site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.</p>
                    <h4 className="zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:0}">20. We are not responsible for viruses and you must not introduce them</h4>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">20.1. </span>{/*Zoho writer list char starts ends*/}We do not guarantee that our site will be secure or free from bugs or viruses. You are responsible for configuring your information technology, computer programmes and platform to access our site. You should use your own virus protection software.</p>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">20.2. </span>{/*Zoho writer list char starts ends*/}You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful. You must not attempt to gain unauthorised access to our site, the server on which our site is stored or any server, computer or database connected to our site. You must not attack our site via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our site will cease immediately.</p>
                    <h4 className="zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:0}">21. Rules about linking to our site</h4>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">21.1. </span>{/*Zoho writer list char starts ends*/}You may link to our home page, your host profile and the page for any draw hosted by you provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.</p>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">21.2. </span>{/*Zoho writer list char starts ends*/}You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists. You must not establish a link to our site in any website that is not owned by you.</p>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">21.3. </span>{/*Zoho writer list char starts ends*/}Our site must not be framed on any other site, nor may you create a link to any part of our site other than the home page.</p>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">21.4. </span>{/*Zoho writer list char starts ends*/}We reserve the right to withdraw linking permission without notice.</p>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">21.5. </span>{/*Zoho writer list char starts ends*/}Any website which you link to must comply in all respects with the content standards set out in our Acceptable Use Policy.</p>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">21.6. </span>{/*Zoho writer list char starts ends*/}If you wish to link to or make any use of content on our site other than that set out above, please contact <a href="mailto:hello@playfundwin.com">hello@playfundwin.com</a>.</p>
                    <h4 className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}"><span data-range-char-type="start" data-bookmark-info="{&quot;name&quot;:&quot;a1004439&quot;,&quot;id&quot;:&quot;zw_bm_24&quot;,&quot;type&quot;:&quot;bookmark&quot;}" data-bookmark-id="zw_bm_24">22. </span>We are not responsible for any websites we link to</h4>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">22.1. </span>{/*Zoho writer list char starts ends*/}Where our site contains links to other sites and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them.</p>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">22.2. </span>{/*Zoho writer list char starts ends*/}We have no control over the contents of those sites or resources.</p>
                    <h4 className="zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:0}">23. User-generated content is not approved by us</h4>
                    <p className="zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="0.394in" data-text-indent="-0.5in" data-padding-left="0.5in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true">This website may include information and materials uploaded by other users of the site, including to bulletin boards and chat rooms. This information and these materials have not been verified or approved by us. The views expressed by other users on our site do not represent our views or values.</p>
                    <h4 className="zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:0}">24. How to complain about content uploaded by other users</h4>
                    <p className="zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="0.394in" data-text-indent="-0.5in" data-padding-left="0.5in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true">If you wish to complain about content uploaded by other users, please contact us on <a href="mailto:hello@playfundwin.com">hello@playfundwin.com</a>.</p>
                    <h4 className="zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:0}">25. We may suspend or withdraw our site</h4>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">25.1. </span>{/*Zoho writer list char starts ends*/}You may use our site and log-in to your user area free of charge.</p>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">25.2. </span>{/*Zoho writer list char starts ends*/}We do not guarantee that our site, or any content on it, will always be available or be uninterrupted. We may suspend or withdraw or restrict the availability of all or any part of our site for business and operational reasons. We will try to give you reasonable notice of any suspension or withdrawal.</p>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">25.3. </span>{/*Zoho writer list char starts ends*/}You are also responsible for ensuring that all persons who access our site through your internet connection are aware of these terms of use and other applicable terms and conditions, and that they comply with them.</p>
                    <h4 className="zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:0}">26. Dormant accounts</h4>
                    <p className="zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="0.394in" data-text-indent="-0.5in" data-padding-left="0.5in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true">In the event that a host account is not accessed for 24 months, we will contact the holder of that account by email informing them that if they do not log-in within the next three months their account will be deactivated and/or deleted. If the account holder does not log in within the said three month period, their account will be duly deactivated and/or deleted.</p>
                    <h4 className="zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:0}"><span data-range-char-type="start" data-bookmark-info="{&quot;name&quot;:&quot;a974177&quot;,&quot;id&quot;:&quot;zw_bm_27&quot;,&quot;type&quot;:&quot;bookmark&quot;}" data-bookmark-id="zw_bm_27">27. </span>Our trademarks are registered</h4>
                    <p className="zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="0.394in" data-text-indent="-0.5in" data-padding-left="0.5in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true">PLAY FUND WIN, playfundwin.com and the Play Fund Win logo are the UK registered trademarks of PFW Holdings Ltd. You are not permitted to use them without our prior written approval, unless they are part of material you are using as permitted under How you may use material on our site.</p>
                    <h4 className="zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:0}">28. General</h4>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">28.1. </span>{/*Zoho writer list char starts ends*/}By using this website and/or creating a draw you agree to be bound by the provisions of any applicable legislation, these terms of use, our privacy policy and any other rules or procedures, statements or explanations we may issue.</p>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">28.2. </span>{/*Zoho writer list char starts ends*/}If any part of this agreement is ruled by a court of competent jurisdiction to be void and/or unenforceable, that decision will only affect the particular provision (or part of the provision) and will not make the other provisions void or unenforceable.</p>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">28.3. </span>{/*Zoho writer list char starts ends*/}We may transfer our rights and obligations under these terms to another organisation. We will always tell you in writing if this happens and we will ensure that the transfer will not affect your rights under the contract. You may not assign or otherwise transfer your obligations or your rights under these terms of use.</p>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">28.4. </span>{/*Zoho writer list char starts ends*/}Any breach of this agreement by you may result in us suspending or terminating your account and any draws or tickets related to it.</p>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">28.5. </span>{/*Zoho writer list char starts ends*/}A person who is not a party to these Rules has no rights under the Contracts (Rights of Third Parties) Act 1999 (as amended or re-enacted from time to time, and any subordinate legislation made under that Act) or otherwise to enforce any provision of these Rules.</p>
                    <p className="zw-special-list zw-list zw-paragraph heading2481119" data-linerule="atLeast" data-line-height={15} data-tabpoints="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-header={2481119} data-textformat="{&quot;fw&quot;:&quot;none&quot;}" data-margin-left="-0.099in" data-text-indent="-0.492in" data-padding-left="0.492in" data-margin-bottom="12.0pt" data-margin-top="12.0pt" data-hd-info={2481119} data-tab-info="[{&quot;leader&quot;:0,&quot;id&quot;:&quot;0&quot;,&quot;align&quot;:0,&quot;point&quot;:&quot;0.5in&quot;}]" data-keep-next-info="true" data-list-id={25} data-list-info="{&quot;id&quot;:25,&quot;l&quot;:1}">{/*Zoho writer list char starts*/}<span className="zw-list-char">28.6. </span>{/*Zoho writer list char starts ends*/}If you are a consumer, please note that these terms of use, their subject matter and their formation, are governed by English law. You and we both agree that the courts of England and Wales will have exclusive jurisdiction.</p>
                    <p>If you are a business, these terms of use, their subject matter and their formation (and any non-contractual disputes or claims) are governed by English law. We both agree to the exclusive jurisdiction of the courts of England and Wales.</p>
                </div>
            </div>

       </div>
    </div>
  </div>

  <FooterCta/>
  <SiteFooter/>
    </>
  )
}

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop(onload) {
  const { pathname } = useLocation();


  console.log(pathname);

  useEffect(() => {
  if(pathname === '/help-centre'){

  } else {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }
}, [pathname, onload]);
  return null;
}

import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from "react-router-dom";
import Siteheader from '../components/header';
import SiteFooter from '../components/footer';
import Innerheader from '../components/inner-header';
import { BlogData } from '../Data/Blog-Data';
import FooterCta from '../components/footer-cta';
import { BlogDataSession } from '../components/Cart';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import CountdownTimer from '../components/CountdownTimer';
import { LogoLoader } from '../components/imageLoader';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getPostByTag, getLiveDrawListDraftbyTaxanomyID } from '../Data/actionMethods';
import loading from '../assets/images/loading.gif';
import ScrollToTop from '../scrollToTop';

export default function BlogTag() {

  const [blogData, setBlogData] = useState([]);
  const [drawData, setDrawData] = useState([]);
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const taxanomySlug = location.pathname.split('/')[2];

  const decodeHTMLEntities = (input) => {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent.replace(/&#9;/g, ' ').replace(/\s+/g, ' ').trim();
  };

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  }

  useEffect(() => {
    getPostByTag(taxanomySlug).then((res) => {
      setBlogData(res.data.content ? res.data.content : []);
    });
  }, [taxanomySlug]);

  useEffect(() => {
    setLoader(true);
    getLiveDrawListDraftbyTaxanomyID(taxanomySlug).then((res) => {
      setDrawData(res.data.content.CategoryData ? res.data.content.CategoryData.filter((item) => item.drawIsActive == 1 && item?.drawIsClosed != 1) : []);
      console.log("Draw Data category", res.data.content.CategoryData);
      setLoader(false);
    });
  }, [taxanomySlug]);


  return (
    <>
      <Helmet>‍
        <title>{blogData[0]?.taxanomyName + ' - Blog Tag'} | Play Fund Win</title>‍
        <meta name="description" content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities." />
      </Helmet>
      <Siteheader />
      <Innerheader title={'Tag: ' + blogData[0]?.taxanomyName} />

      {blogData.length > 0 ?
        <div className='blog-overview-section container py-5 bg-f2 my-4' style={{ borderRadius: 10 }}>
          {/* <div className="sort-section d-flex justify-content-between mx-auto bg-light px-5 py-3 mt-3 mb-5 rounded-pill font-face-gb" style={{width: '81%'}}>
            <p className="m-0 text-black fw-bolder">Tags:</p>
            <ul className="d-flex list-unstyled m-0">
                <li className="mx-5"><a className="text-decoration-none text-muted" href="/category/our-thoughts-and-insights/">Our Thoughts and Insights</a></li>
                <li className="mx-5"><a className="text-decoration-none text-muted" href="/category/press-release/">Press Release</a></li>
                <li className="mx-5"><a className="text-decoration-none text-muted" href="/category/thoughts-insights/">Thoughts &amp; Insights</a></li>
            </ul>
            <select className="desc-asc-order bg-transparent border-0 height-0 lh-0">
                <option value="ASC">Sort A - Z</option>
                <option value="DESC">Sort Z - A</option>
            </select>
            <select className="desc-asc-order bg-transparent border-0 height-0 lh-0">
                <option value="post_date" defaultValue>Order By Date</option>
                <option value="post_title">Order By Title</option>
                <option value="rand">Random Order</option>
            </select>
        </div> */}

          {/* <div className='row mx-auto pt-4' style={{width:'82%'}}>
            {data.map((p) => (
                <div className='col-lg-4 col-md-6 col-sm-12 font-face-gb mb-5 d-flex flex-column' key={p.id}>
                    <div className='bg-branding1 h-100' style={{borderRadius: 10}}>
                        <Link to={"/blog/" + p.slug} onClick={() => SetBlogDetails(p)}><div className='post-image background-image' style={{backgroundImage: `url(${require(`../assets/images/blogs/${p.postImage}`)})`, borderTopLeftRadius: 10, borderTopRightRadius: 10, height: 196}}></div></Link>
                        <div className='post-short-content bg-branding1' style={{borderBottomLeftRadius: 10, borderBottomRightRadius: 10, padding:35}}>
                            <div className='publishdate text-light'>{p.publishdate}</div>
                            <Link className='text-decoration-none' to={"/blog/" + p.slug} onClick={() => SetBlogDetails(p)}><h3 className='post-title color-yellow fs-5 font-face-gb-semibold fw-normal my-3' style={{minHeight: 50}}>{p.name}</h3></Link>
                            <div className='post-excerpt text-white'>{p.excerpt}</div>
                            <Link to={"/blog/" + p.slug} onClick={() => SetBlogDetails(p)} className="border-0 rounded-pill btn pfw-button-red secbutton py-2 px-4 mt-4 mb-2 font-face-gb-semibold" style={{lineHeight: 2}}>Read More</Link>
                        </div>
                    </div>
                </div>
            ))}
        </div> */}
          <div className='row mx-auto pt-4' style={{ width: '82%' }}>
            {blogData.map((p) => {
              let postMeta;
              try {
                postMeta = JSON.parse(p.postMeta);
              } catch (error) {
                postMeta = "";
              }
              return (
                <div className={`col-lg-4 col-md-6 col-sm-12 font-face-gb mb-5 d-flex flex-column ${p.postID}`} key={p.postID}>
                  <div className='bg-branding1 h-100' style={{ borderRadius: 10, overflow: 'hidden' }}>
                    <Link
                      to={{
                        pathname: `/blog/${p.postSlug}`
                      }}
                      onClick={() => {
                        navigate(`/blog/${p.postSlug}`);
                      }}
                    >
                      {/* Use LazyLoadImage for the background-image */}
                      <LazyLoadImage
                        className='post-image background-image w-100 position-relative'
                        alt=''
                        effect='blur' // Add a loading effect if desired
                        src={postMeta.featuredImage}
                        style={{
                          borderTopLeftRadius: 10,
                          borderTopRightRadius: 10
                        }}
                      />
                    </Link>
                    <div className='text-end position-absolute d-flex' style={{ right: '28px', marginTop: '-13px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                      <Link
                        to={{
                          pathname: `/tag/${p.taxanomySlug}`
                        }}
                        onClick={() => {
                          navigate(`/tag/${p.taxanomySlug}`);
                        }}
                        className='text-decoration-none text-light pfw-button-green rounded-pill px-3'
                        style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}
                      >
                        {p.taxanomyName}
                      </Link>
                    </div>
                    <div className='post-short-content bg-branding1' style={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10, padding: 35 }}>
                      {/* <div className='text-light'>
                        <Link
                          to={{
                            pathname: `/category/${p.taxanomySlug}`
                          }}
                          onClick={() => {
                            navigate(`/category/${p.taxanomySlug}`);
                          }}
                          className='text-decoration-none branding2 branding2-hover'
                        >
                          {p.taxanomyName}
                        </Link>
                      </div> */}
                      <div className='publishdate text-light'>{moment(p.postPublishedDate).format('Do MMMM YYYY')}</div>

                      <Link
                        className='text-decoration-none'
                        to={{
                          pathname: `/blog/${p.postSlug}`
                        }}
                        onClick={() => {
                          navigate(`/blog/${p.postSlug}`);
                        }}
                      >
                        <h3 className='post-title color-yellow fs-5 font-face-gb-semibold fw-normal my-3' style={{ minHeight: 50 }}>
                          {truncateText(decodeHTMLEntities(p.postName), 50)}
                        </h3>
                      </Link>
                      <div className='post-excerpt text-white'>{truncateText(decodeHTMLEntities(postMeta.introductionText), 70)}</div>

                      <Link
                        to={{
                          pathname: `/blog/${p.postSlug}`
                        }}
                        onClick={() => {
                          navigate(`/blog/${p.postSlug}`);
                        }}
                        className='border-0 rounded-pill btn pfw-button-red secbutton py-2 px-4 mt-3 mb-2 font-face-gb-semibold'
                        style={{ lineHeight: 2 }}
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        :
        <div className='text-center '>
          <img className='w-50' src={loading} style={{ opacity: '0.7' }} alt="Loading..." />
        </div>
      }


      {drawData.length == 0 ? "" :
        <div className='blog-overview-section container py-5 bg-f2 my-4' style={{ borderRadius: 10 }}>
          {loader ?
            <div className='text-center '>
              <img className='w-50' src={loading} style={{ opacity: '0.7' }} alt="Loading.." />
            </div>
            :
            drawData.length == 0 ?
              <div className='text-center '>
                <h4 className='branding1 m-0'>Draw Not Found for this Tag!</h4>
              </div>
              :
              <div className='row mx-auto pt-3' style={{ width: '82%' }}>
                <h2 className="hiw-title branding1 text-center font-face-gb-semibold display-4 mb-4 pb-2">Find a Draw</h2>
                {drawData.map((p) => {
                  let drawContent;
                  try {
                    drawContent = JSON.parse(p.drawContent);
                  } catch (error) {
                    drawContent = "";
                  }
                  return (
                    <>
                      {p.TestDraw === 1 ? "" :
                        <div className='col-lg-4 col-md-4 col-sm-12 font-face-gb' key={p.drawID}>
                          <div className={`slider-sec mt-5 text-center mb-5 bg-white m-1 draw-id-${p.drawID}`} style={{ borderBottomLeftRadius: 12, borderBottomRightRadius: 12 }}>
                            <div className="colour-left-right" style={{ background: `linear-gradient( to right, ${drawContent.BrandingColor1} 50%, ${drawContent.BrandingColor2} 50%)`, height: 23, borderTopLeftRadius: 10, borderTopRightRadius: 10 }} />

                            <div className='draw-logo mx-auto rounded-pill' style={{ marginTop: -70, width: 120, height: 120, background: `linear-gradient( to right, ${drawContent.BrandingColor1} 50%, ${drawContent.BrandingColor2} 50%)`, padding: 10 }}>
                              <div className='draw-logo-wrap p-2 find-a-draw-logo rounded-pill w-100 h-100' style={{ backgroundColor: `${drawContent.BrandingColor1}`, border: `3px solid #fff` }}>
                                {/* <img className="rounded-circle" src={require(`../assets/images/logo/${p.logoUrl}`)} style={{ width: '100%', height: '100%', objectFit: 'contain' }}></img> */}
                                <LogoLoader className="rounded-circle" src={drawContent.logoUrl} style={{ width: '100% !important', height: '100%', objectFit: 'contain' }} />
                              </div>
                            </div>
                            <div className='px-4 pb-2'>
                              {drawContent.Redirection == true ?
                                <a href={drawContent.RedirectionUrl} target="_blank" className="text-decoration-none">
                                  <h4 className="pt-3 fs-5 font-face-gb branding1">{p.drawName}</h4>
                                </a>
                                :
                                <Link to={"/draw/" + drawContent.slug} className="text-decoration-none">
                                  <h4 className="pt-3 fs-5 font-face-gb branding1">{p.drawName}</h4>
                                </Link>
                              }
                              <hr className='bg-branding2 mx-auto my-3' style={{ width: 28, height: 3, opacity: 1 }} />
                              <div className="draw-countdown">
                                <p className='branding1'>Draw Countdown</p>
                                <div className='date-countdown d-md-flex gap-3 justify-content-center'>
                                  <div className="draw-date branding1 m-md-0 m-3">{moment(p.drawEndDate).format('DD MMMM YYYY')}</div>
                                  {drawContent.subscription == false ?
                                    <div className="countdown-timer">
                                      <div className="draw-counts speed1" data-time="1664578740" data-lotteryid="12337" data-format="dhm">
                                        <span className={`demo12337 branding1 px-2 py-2 rounded-pill border ${moment() > moment(p.drawEndDate) ? "secbutton-border" : "thirbutton-border"}`}>{moment() > moment(p.drawEndDate) ? <span className="branding3">Draw Finished</span> : <CountdownTimer time={p.drawEndDate} />}</span>
                                      </div>
                                    </div>
                                    : ''}
                                </div>
                              </div>
                              {drawContent.Redirection == true ?
                                <a href={drawContent.RedirectionUrl} target="_blank" className="border-0 rounded-pill btn pfw-button-red secbutton py-2 px-3 my-4 w-100 font-face-gb-semibold" style={{ lineHeight: 2 }}>{moment() > moment(p.drawEndDate) ? "View Draw" : "Enter Draw"}</a>
                                :
                                <Link to={"/draw/" + drawContent.slug} className="border-0 rounded-pill btn pfw-button-red secbutton py-2 px-3 my-4 w-100 font-face-gb-semibold" style={{ lineHeight: 2 }}>{moment() > moment(p.drawEndDate) ? "View Draw" : "Enter Draw"}</Link>
                              }
                            </div>
                          </div>
                        </div>
                      }
                    </>
                  )
                })}
              </div>
          }
        </div>
      }
      <FooterCta />
      <SiteFooter />
      <ScrollToTop onload={blogData.length} />
    </>
  )
}

import React, { useState } from 'react';
import Siteheader from '../components/header';
import { Link } from 'react-router-dom';
import SiteFooter from '../components/footer';
import { Find_a_Draw_Images } from '../components/images';
import FindDrawTabs from '../components/find-a-draw-tabs';
import FooterCta from '../components/footer-cta';
import { Helmet } from 'react-helmet';

export default function Find_a_Draw() {
  const [search, SetSearch] = useState('');

  return (
    <>
      <Helmet>
        ‍<title>Find a Draw | Play Fund Win</title>‍
        <meta
          name="description"
          content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities."
        />
      </Helmet>
      <Siteheader />
      <div
        className="inner-header container px-0 background-image position-relative bg-before-after"
        style={{
          backgroundImage: `url(${Find_a_Draw_Images.FindDrawBg2})`,
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10
        }}
      >
        <div
          className="py-5"
          style={{ backgroundColor: '#011f46cc', borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
        >
          <div className="row align-items-center py-5">
            <div className="col-xl-6 col-lg-6 col-md-6 col-12 align-middle text-center py-4 px-lg-5 m-auto font-face-gb">
              <h1 className="text-white display-3">
                Find a <strong className="font-face-gb-semibold">Draw</strong>
              </h1>
              <p className="text-white">
                To enter a draw simply start typing the name of the club, event or cause you want to support and select
                the relevant one when it comes up.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container draw-search-sec" style={{ backgroundColor: '#f2f2f2' }}>
        <div className="draw-search-bar d-flex">
          <div className="draw-search position-relative mx-auto" style={{ width: 800, marginTop: -36 }}>
            <input
              type="search"
              id="draw-search-field"
              className="search-field bg-white pe-4 py-3 rounded-pill border-0 fs-5 w-100"
              placeholder="Search by club name, event or cause"
              name="s"
              style={{ height: 72, paddingLeft: 70 }}
              onChange={(e) => SetSearch(e.target.value)}
              value={search}
            />
            <button type="submit" value="Search">
              Search
            </button>
            <input type="hidden" name="post_type" defaultValue="draw" />
          </div>
        </div>
        <p className="text-center branding1 mt-4 mb-0 pt-2">
          If you can’t find your Club, Event or Cause{' '}
          <a className="branding3 fw-bold" href="/find-a-draw-form/">
            please let us know
          </a>
        </p>
      </div>

      <div
        className="container draw-tabs-sec py-5"
        style={{ backgroundColor: '#f2f2f2', borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}
      >
        <div className="draw-tabs-inner mx-auto" style={{ width: '84%' }}>
          <FindDrawTabs search={search} />
        </div>
      </div>

      <div className="container-fluid pt-3 pb-4 px-0 find-a-draw how-it-works fon-face-gb">
        <div className="container py-5 px-md-5">
          <h2 className="hiw-title branding1 text-center font-face-gb-semibold display-4 mb-5">How it works</h2>
          <div className="row ">
            <div className="col-md-4 px-5">
              <div className="py-3 d-flex align-items-center">
                <img src={require('../assets/images/Group-1462.webp')} />
                <h2 className="branding1 mn-0 ms-4 font-face-gb-semibold display-4">play</h2>
              </div>
              <p className="text-left">
                Play a draw by visiting <strong>Play Fund Win</strong> and finding your favourite cause.
              </p>
            </div>
            <div className="col-md-4 px-5">
              <div className="py-3 d-flex align-items-center">
                <img src={require('../assets/images/Group-1463.webp')} />
                <h2 className="text-left branding1 mb-0 ms-4 font-face-gb-semibold display-4">fund</h2>
              </div>
              <p className="text-left">
                Fund good causes and grassroots sports, as minimum{' '}
                <strong>50% of net proceeds go to charity or good causes.</strong>
              </p>
            </div>
            <div className="col-md-4 px-5">
              <div className="py-3 d-flex align-items-center">
                <img src={require('../assets/images/Group-1464.webp')} />
                <h2 className="text-left branding1 mb-0 ms-4 font-face-gb-semibold display-4">win</h2>
              </div>
              <p className="text-left">
                The draw host determines what{' '}
                <strong>% of the entry fee forms the jackpot prize which is guaranteed to be won on every draw</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      <FooterCta />
      <SiteFooter />
    </>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';
import Siteheader from '../components/header';
import SiteFooter from '../components/footer';
import { UseEffect } from 'react';
import FooterCta from '../components/footer-cta';
import Innerheader from '../components/inner-header';
import { Helmet } from 'react-helmet';

export default function PrizeDrawTermsConditions(props) {
  return (
    <>
      <Helmet>
        ‍<title>Prize Draw Terms & Conditions | Play Fund Win</title>‍
        <meta
          name="description"
          content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities."
        />
      </Helmet>
      <Siteheader />
      <Innerheader title="Prize Draw Terms & Conditions" />

      <div className="container pt-5 pb-5 my-3 px-lg-5 rounded-4 w-92" style={{ backgroundColor: '#f2f2f2' }}>
        <div className="row px-lg-5">
          <div className="col-md-12 align-middle text-left px-lg-5 mt-lg-0 mt-3">
            <p>
              <strong>Prize Draw Terms & Conditions –</strong>{' '}
              <a className="branding3 fw-bold" href="https://playfundwin.com">
                (www.playfundwin.com )
              </a>
            </p>
            <p>
              See clause 3.3 for alternative free entry route.
              <br />
              These terms of use set out the rules for the draws which are promoted and managed by us on behalf of and
              for the benefit of various charities, non-commercial societies or local authorities (which we refer to as
              Hosts).
            </p>
            <p>
              <em>Closing Date:</em> The date on which all available Entries for each Draw have been sold, or when the
              date and time shown in each Draw as the closing date is reached, or where no date is stated, three (3)
              months from the start of the Draw.
            </p>
            <p>
              <em>Draw:</em> Any Draw operated by the Promoter to win a Prize.
            </p>
            <p>
              <em>Entrant:</em> Any person or individual (aged 18 or over and residing in the United Kingdom) who either
              enters the draw through the website by paying for it or enters it for free via the postal route.
            </p>
            <p>
              <em>Entry:</em>&nbsp;A validly submitted, completed Entry for a Draw (whether paid or via the postal
              route).
            </p>
            <p>
              <em>Prize:</em>&nbsp;The item(s) as stated in each Draw page on the Website.
            </p>
            <p>
              <em>Postal Entry:</em>&nbsp;Means a validly completed Postal Entry (see clause 3.3 below).
            </p>
            <p>
              <em>Privacy Policy:</em> The Promoter’s Privacy Policy which can be found at{' '}
              <a className="branding3 fw-bold" href="/privacy-policy/">
                www.playfundwin.com/privacy
              </a>
            </p>
            <p>
              <em>Promoter:</em>&nbsp;“Play Fund Win” which is a brand owned by PFW Holdings Ltd. (a company registered
              in England &amp; Wales with company number 12267104).
            </p>
            <p>
              <em>Host:</em> A sports club, charity, charitable association, or a similar unincorporated body who are
              the recipient of funds from a draw. A draw may also be hosted by a third party on behalf of a sports club,
              charity, non-commercial society, or local authority, in which case funds are transferred directly to the
              named cause.
            </p>
            <p>
              <em>Random Number Generator:</em>&nbsp;Certified Random Number Generator software which will be used by
              the Promoter to select the Winner of each Draw.
            </p>
            <p>
              <em>Website:</em>{' '}
              <a className="branding3 fw-bold" href="https://playfundwin.com">
                www.playfundwin.com
              </a>
            </p>
            <p>
              <em>Winner:</em> The Entrant (or, where multiple prizes are available, Entrants) chosen at random using
              the Random Number Generator who wins the Prize.
            </p>
            <p>
              <strong>1. General</strong>
            </p>
            <p>
              <strong>1.1</strong>&nbsp;By entering the Draw via the Website Entrants confirm that they have read and
              accept these Terms and Conditions and agree to be bound by them. By entering the Draw by Postal Entry,
              each Entrant must first confirm that they have read and accept these terms and conditions (see 3.3 below
              for free postal entry method).
            </p>
            <p>
              <strong>1.2</strong>&nbsp;When entering a Draw either via the Website using a Paid Entry, Free Entry or via Postal Entry, Entrants must submit their contact details as required by the Promoter which will be used for the following purposes:
            </p>
            <p>
              (a) to notify the Winner of the Draw that they have won a Prize; and
              <br />
              (b) to post the Winner’s first name, initial and town of residence on the Website;
              <br />
              (c) in any other way set out in the{' '}
              <a className="branding3 fw-bold" href="/privacy-policy/">
                Privacy Policy
              </a>
              .
            </p>
            <p>
              <strong>2. The Draw</strong>
            </p>
            <p>
              <strong>2.1</strong>&nbsp;A Draw is open only to individuals residing in the United Kingdom and over the
              age of 18. If an Entrant is found to be under the age of 18, any Prize allocation will be null and void.
            </p>
            <p>
              <strong>2.2</strong>&nbsp;Entrants acknowledge that payment to enter a Draw does not guarantee that they
              will win a Prize.
            </p>
            <p>
              <strong>2.3</strong>&nbsp;The Entrants, the Host and the Promoter recognise that the Draw, its
              administration and all associated activities are governed by the laws of England and Wales and acknowledge
              that the courts of England and Wales will have exclusive jurisdiction in the event of any dispute arising
              out of the Draw or its administration.
            </p>
            <p>
              <strong>2.4</strong>&nbsp;The Promoter reserves the right to refuse an Entry or to disqualify any Entrant
              if it has reasonable grounds for believing that an Entrant has contravened any of these terms and
              conditions or acted in a way that is reasonably considered by the Promoter to be illegal.
            </p>
            <p>
              <strong>3. Entry to the Draw</strong>
            </p>
            <p>
              <strong>3.1</strong>&nbsp;Where applicable, each Draw will state the maximum amount of Entries available.
            </p>
            <p>
              <strong>3.2</strong> <strong>Paid Entry route</strong>: To enter a Draw via the paid route, an Entrant
              must enter their details on the Website and provide their contact details including their postal address,
              e-mail address and a telephone number along with a valid card payment.
            </p>
            <p>
              <strong>3.3</strong>&nbsp;<strong>Free Postal Entry Route</strong>: – Entrants can enter any Prize Draw (
              <a className="branding3 fw-bold" href="/#/">
                Excluding Lotteries
              </a>
              ) for free by sending an unenclosed postcard via stamped first- or second-class pre-paid post stating the
              Draw name they would like to enter, along with the following:
            </p>
            <p>
              Full name
              <br />
              Postal address
              <br />
              E-mail address
              <br />
              Contact telephone number
              <br />
              Club or Team name chosen for the Sub-Draw (if applicable)
            </p>
            <p>
              All details must be either typed or clearly written, we cannot enter you into a prize draw if the writing
              on your postcard is not legible. If we receive one postcard containing multiple entries this will not
              comply with the above terms and conditions. Entrants must have an active account on the Website prior to
              entering a Draw by post and must confirm that they have read and accept these Terms and Conditions and the
              Privacy Policy when they create their account.
            </p>
            <p>
              <strong>3.4</strong>&nbsp;Postal Entries must be sent to: PFW Holdings Ltd Suite#300, 4 Blenheim Court,
              Peppercorn Close, Peterborough, PE1 2DU.
            </p>
            <p>
              <strong>3.5</strong> Postal Entries must be posted so that they are received by the Promoter within 72
              hours of the closing time of each Draw. Postal Entries received after this time will not be included in
              the Draw.
            </p>
            <p>
              <strong>3.6</strong>&nbsp;Bulk postal Entries will not be permitted. Entries delivered to this address in
              person will not be accepted.
            </p>
            <p>
              <strong>3.7</strong> Where a maximum number of entries applies, this will be displayed on the draw page,
              this applies equally to the Paid Entry and Free Entry methods, a maximum number of entries will be per
              individual or residential address.
            </p>
            <p>
              <strong>3.8</strong>&nbsp;All Entries to the Draw are final and no refunds shall be made at any time or
              for any reason, except in the case of Entries submitted after the Closing Date or if the Promoter cancels
              the Draw (see 8 below).
            </p>
            <p>
              <strong>3.9</strong>&nbsp;The Promoter’s decision is final and no correspondence will be entered into
              about the result of the Draw.
            </p>
            <p>
              <strong>3.10</strong>&nbsp;Entrants agree that the usual requirement under the Consumer Protection
              (Distance Selling) Regulations 2000 for any goods and services ordered online to be supplied within 30
              days will not apply to any Draw run by the Promoter.
            </p>
            <p>
              <strong>4. Closing date of the Draw</strong>
            </p>
            <p>
              Each Draw will close when either the stated number of Entries to each Draw have been reached, or the date
              and time shown in each Draw as the closing date is reached or where no date is stated, three (3) months
              from the start of the Draw.
            </p>
            <p>
              <strong>5. Selection of the Winner</strong>
            </p>
            <p>
              <strong>5.1</strong> The Winner will be selected at random from the list of paid and postal Entrants using
              the Random Number Generator Software.
            </p>
            <p>
              <strong>5.2</strong> Any Winner whose payment transaction has been cancelled, reversed or is subject to
              any chargeback (before or after a Draw) will not be permitted to claim a Prize.
            </p>
            <p>
              <strong>5.3</strong> There will only be one Winner per Prize, the number of prizes will be displayed on
              the Draw page.
            </p>
            <p>
              <strong>5.4</strong> The Promoter will try to contact each Winner by e-mail or telephone only, using the
              contact details held in the Entrant’s account.
            </p>
            <p>
              <strong>5.5</strong> If a Winner cannot be contacted by the Promoter within 14 days of a Draw, then the
              Winner’s Prize will be forfeited, and the Promoter reserves the right to select an alternative Winner. The
              alternative Winner will then be notified in the same way and will have 14 days to accept the Prize.
            </p>
            <p>
              <strong>5.6</strong> The original Winner will have no claim to a Prize once an alternative Winner has been
              selected.
            </p>
            <p>
              <strong>5.7</strong> The decision of the Promoter regarding any aspect of the Draw is final and binding
              and no correspondence will be entered into about it.
            </p>
            <p>
              <strong>6. Prize</strong>
            </p>
            <p>
              <strong>6.1</strong> Title to a Prize is retained by the Host until it is handed over to the Winner. Once
              the Winner takes possession of the Prize all risk and title in the Prize passes to the Winner.
            </p>
            <p>
              <strong>6.2</strong> The Host does not imply or give any warranty in relation to a Prize or warrant the
              value or condition of a Prize.
            </p>
            <p>
              <strong>6.3</strong> The Prize is as stated in each Draw. No cash alternative or alternative Prize will be
              offered by the Host or Promoter.
            </p>
            <p>
              <strong>6.4</strong>&nbsp;The Prize can be delivered to the Winner or collected from the Host, at the
              Winner’s election. Collection of the Prize is the sole responsibility of the Winner. Unless alternative
              arrangements are agreed between the parties, the Prize must be collected from the Host’s address. The Host
              will pay for the cost of delivering the Prize to the Winner at Winner’s address in the United Kingdom and
              will ensure the Prize until it is delivered to Winner’s address.
            </p>
            <p>
              <strong>6.5</strong>&nbsp;The Winner may be required to provide proof of their identity and/or age at the
              time the Prize is collected or delivered.
            </p>
            <p>
              <strong>6.6</strong>&nbsp;The Winner is responsible for all taxes, duties, and charges in relation to
              receipt of the Prize.
            </p>
            <p>
              <strong>7. Miscellaneous</strong>
            </p>
            <p>
              ​<strong>7.1</strong>&nbsp;The Promoter will publish information indicating that the Draw took place, and
              a Winner was awarded the Prize(s). The Winner will be given the option to consent to the Promoter
              displaying their full name and photograph or other personal details on the Website. Where consent is
              given, the Winner’s name, town and photo will be posted on the Website.
            </p>
            <p>
              <strong>7.2</strong>&nbsp;The Winner will be given the option to consent to photo(s) and or video(s) being
              taken for promotional purposes.
            </p>
            <p>
              <strong>7.3</strong>&nbsp;Entrants authorise the Promoter to enter their personal details into Promoter’s
              database and to use the information in accordance with the provisions of the Data Protection Act 2018 and
              subject to the terms of Privacy Policy.
            </p>
            <p>
              <strong>7.4</strong> The Promoter will not be liable for any loss (including economic loss) suffered to or
              sustained by any person or property as a result of any act or omission, nor will their servants or their
              agents in developing, planning and administrating the Draw, distributing the Prize to the Winner or
              following the distribution of the Prize.
            </p>
            <p>
              <strong>7.5</strong> The Promoter accepts no liability for errors or omissions contained within the Prize
              details, description or specification or any other part of the Website. It is the responsibility of each
              Entrant (and in particular the Winner) to satisfy him/herself as to the accuracy of any such details
              and/or any content of this Website.
            </p>
            <p>
              <strong>7.6</strong> The Promoter will not be liable for any loss suffered by an Entrant as a result of
              incomplete Entries or failed computer communications or for any loss suffered as a result of use of the
              Website.
            </p>
            <p>
              <strong>7.7</strong> The Promoter does not accept responsibility for any Entry which they do not receive
              due to failures in computer systems, other malfunctions, hardware failure, software failure, server faults
              or any other reason.
            </p>
            <p>
              <strong>7.8</strong> An Entry shall be declared void (without any refund being given) if the Entrant
              engages in: (a) any form of fraud (actual or apparent); (b) fraudulent misrepresentation; (c) fraudulent
              concealment; (d) hacking or interference with the proper functioning of the website; or (e) amending, or
              unauthorised use of, any of the code that constitutes the Website.
            </p>
            <p>
              <strong>7.9</strong> The Promoter reserves the right to amend these terms and conditions at any time. Any
              changes will be immediately published on the Website, and it is each Entrant’s responsibility to check the
              Website for any changes to these terms and conditions.
            </p>
            <p>
              <strong>8. Cancellation</strong>
            </p>
            <p>
              The Promoter reserves the right to cancel any Draw at any time either before or after Entries have been
              sold. If a Draw is cancelled, the Promoter will notify each Entrant at the email address they have
              provided and return any payment made by an Entrant by the same method as payment was made. Where a payment
              has been refunded, the Promoter will have no further liability to the Entrant or to any other person.
            </p>
            <p>
              <strong>9. Data and Privacy Policy</strong>
            </p>
            <p>
              <strong>9.1</strong>&nbsp;Each Entrant, the Host and the Promoter recognise that the Promoter may use
              contact details and other data including personal data as defined by the Data Protection Act 2018 supplied
              by an Entrant to the Promoter to both administer the Website and conduct the Draw in accordance with these
              Terms and Conditions and as set out in the{' '}
              <a className="branding3 fw-bold" href="/privacy-policy/">
                Privacy Policy
              </a>
              .
            </p>
            <p>
              <strong>9.2</strong>&nbsp;Each Entrant, the Host and the Promoter acknowledge that the Promoter may
              (either by choice or at the request of a third party) disclose this information to relevant third parties
              for the purposes of the prevention of fraud, money laundering, legal or other financial or regulatory
              reasons.
            </p>
            <p>
            <strong>9.3</strong>&nbsp;Terms of Entry and Communication: By participating in a prize draw, entrants acknowledge and consent to receive future communications via email about subsequent prize draws and promotions from Play Fund Win and associated entities within the PFW Family. These communications are considered a legitimate interest of the company to engage with its audience. Entrants have the right to unsubscribe from such communications at any time.
            </p>
          </div>
        </div>
      </div>

      <FooterCta />
      <SiteFooter />
    </>
  );
}

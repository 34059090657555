// HomeDrawSlider.js
import React from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { LogoLoader } from './imageLoader';
import { MyCart, Cart, HomeDrawDataSession, DrawDataSession } from '../components/Cart';
import { toast, ToastContainer } from 'react-toastify';
import SkeletonLoader from './SkeletonLoader';

function SetDrawDetails(p) {
  DrawDataSession.data = p;
}

function HomeDrawSlider({ slicedDataDraw, fetch }) {
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return <div className="custom-arrow next-arrow" style={{ ...style, display: 'block' }} onClick={onClick} />;
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return <div className="custom-arrow prev-arrow" style={{ ...style, display: 'block' }} onClick={onClick} />;
  }

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 500,
    centerMode: true,
    centerPadding: '120px',
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false
        }
      }
    ]
  };

  console.log('Fetched', fetch);
  console.log('slicedDataDraw', slicedDataDraw);
  //let Fetched = true;
  if (fetch == true) {
    return (
      <div className="position-relative" style={{ overFlow: 'visible' }}>
        <div className="container logo-slider pt-5 pb-0">
          <div className="row align-items-center mb-2">
            <div className="col-md-6 draw-title-data">
              <div className="row align-items-center">
                <div className="col-md-1">
                  <img src={require('../assets/images/playicon.png')} style={{ width: '100%' }} />
                </div>
                <div className="col-md-10 text-white px-0">
                  <h3 className="m-0 p-0 popular-title">
                    <span className="branding-4 font-face-gb-semibold">Popular</span> on Play Fund Win
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-md-6 text-right d-flex view-all-button" style={{ justifyContent: 'right' }}>
              <Link
                to="/find-a-draw/"
                className="border-0 rounded-pill btn pfw-button-red secbutton py-3 px-4 my-4 font-face-gb-semibold"
              >
                View all
              </Link>
            </div>
          </div>
        </div>
        <div className="" style={{ width: '100%' }}>
          <div className="transparent-item hover-slider">
            <Slider {...settings} className="py-2">
              {slicedDataDraw.map((p, index) => (
                // Your mapping logic here

                <div className="col-lg-3 col-md-3 col-sm-12 font-face-gb px-2 position-relative w-100" key={p.id}>
                  {!!p.HeaderImage ? (
                    <>
                      {
                        p.Redirection ? (
                          <a href={p.RedirectionUrl} className="text-decoration-none" target={'_blank'}>
                            <div className="shadow-sm bg-white draw-slider-data" style={{ borderRadius: 20 }}>
                              <div
                                className={`main-header animation-shine-draw text-center draw-id-${p.drawID}`}
                                style={{
                                  padding: '60px 0px',
                                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.62),${p.BrandingColor2}), ${
                                    p.HeaderImage.includes('http')
                                      ? `url(${p.HeaderImage})`
                                      : `url(${require('../assets/images/draws/' + p.HeaderImage)})`
                                  }`,
                                  backgroundSize: 'cover',
                                  borderTopLeftRadius: 15,
                                  borderTopRightRadius: 15
                                }}
                              >
                                <LogoLoader
                                  src={p.logoUrl}
                                  style={{ width: '100%', height: '100px', objectFit: 'contain' }}
                                />

                                {/* <img className="" src={require(`../assets/images/logo/${p.logoUrl}`)} style={{ width: '100%', height: '100px', objectFit: 'contain' }}></img> */}
                              </div>
                              <div
                                className="draws-title bg-white text-center"
                                style={{ borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}
                              >
                                <h4 className="pt-3 fs-5 font-face-gb branding1 pb-3" style={{ minHeight: 24 }}>
                                  {p.name}
                                </h4>
                                {/* <div className="d-none tickets-home">
                               <button className='buy-now-btn border-0 rounded-pill btn pfw-button-red secbutton py-2 px-5 font-face-gb-semibold' style={{ backgroundColor: `${p.BrandingColor2}`, fontSize: 18 }}>View Draw</button>
                            
                    </div>*/}
                              </div>
                            </div>
                          </a>
                        ) : (
                          // <Link to={"/draw/" + p.slug} onClick={() => SetDrawDetails(p)} className="text-decoration-none">
                          <div className="sites-draws">
                            <div className="shadow-sm bg-white draw-slider-data" style={{ borderRadius: 20 }}>
                              <Link
                                to={'/draw/' + p.slug}
                                onClick={() => SetDrawDetails(p)}
                                className="text-decoration-none"
                              >
                                <div className="middle-overlay">
                                  {/* <Link to={"/draw/" + p.slug} onClick={() => SetDrawDetails(p)} className="text-decoration-none">
                    <div className="border-0 rounded-pill btn pfw-button-red secbutton py-2 px-3 my-4 mb-0 font-face-gb-semibold text-overlay-view">View Draw</div>
                    </Link> */}
                                </div>
                                <div
                                  className={`main-header animation-shine-draw text-center draw-id-${p.drawID}`}
                                  style={{
                                    padding: '60px 0px',
                                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.62),${p.BrandingColor2}), ${
                                      p.HeaderImage.includes('http')
                                        ? `url(${p.HeaderImage})`
                                        : `url(${require('../assets/images/draws/' + p.HeaderImage)})`
                                    }`,
                                    backgroundSize: 'cover',
                                    borderTopLeftRadius: 15,
                                    borderTopRightRadius: 15
                                  }}
                                >
                                  <LogoLoader
                                    src={p.logoUrl}
                                    style={{ width: '100%', height: '100px', objectFit: 'contain' }}
                                  />

                                  {/* <img className="" src={require(`../assets/images/logo/${p.logoUrl}`)} style={{ width: '100%', height: '100px', objectFit: 'contain' }}></img> */}
                                </div>
                              </Link>
                              <div
                                className="draws-title bg-white text-center"
                                style={{ borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}
                              >
                                <h4 className="pt-3 fs-5 font-face-gb branding1 pb-3" style={{ minHeight: 24 }}>
                                  {p.name}
                                </h4>
                                <div className="d-block tickets-home"></div>
                              </div>
                            </div>
                          </div>
                        )
                        // </Link>
                      }
                    </>
                  ) : (
                    <>
                      {
                        p.Redirection ? (
                          <a href={p.RedirectionUrl} className="text-decoration-none" target={'_blank'}>
                            <div className="shadow-sm bg-white draw-slider-data" style={{ borderRadius: 20 }}>
                              <div
                                className={`main-header animation-shine-draw text-center draw-id-${p.drawID}`}
                                style={{
                                  padding: '60px 0px',
                                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.62),${
                                    p.BrandingColor2
                                  }),url(${require(`../assets/images/hero-banner.jpg`)})`,
                                  backgroundSize: 'cover',
                                  borderTopLeftRadius: 15,
                                  borderTopRightRadius: 15
                                }}
                              >
                                <LogoLoader
                                  src={p.logoUrl}
                                  style={{ width: '100%', height: '100px', objectFit: 'contain' }}
                                />
                                {/* <img className="" src={require(`../assets/images/logo/${p.logoUrl}`)} style={{ width: '100%', height: '100px', objectFit: 'contain' }}></img> */}
                              </div>
                              <div
                                className="draws-title bg-white text-center"
                                style={{ borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}
                              >
                                <h4 className="pt-3 fs-5 font-face-gb branding1 pb-3" style={{ minHeight: 24 }}>
                                  {p.name}
                                </h4>
                                <div className="d-none tickets-home">
                                  {/* <button onClick={() => BuyNow(p)} className='buy-now-btn border-0 rounded-pill btn pfw-button-red secbutton py-2 px-5 font-face-gb-semibold' style={{ backgroundColor: `${p.BrandingColor2}`, fontSize: 18 }}>View Draw</button> */}
                                </div>
                              </div>
                            </div>
                          </a>
                        ) : (
                          // <Link to={"/draw/" + p.slug} onClick={() => SetDrawDetails(p)} className="text-decoration-none">
                          <div className="sites-draws">
                            <div className="shadow-sm bg-white draw-slider-data" style={{ borderRadius: 20 }}>
                              <Link
                                to={'/draw/' + p.slug}
                                onClick={() => SetDrawDetails(p)}
                                className="text-decoration-none"
                              >
                                <div className="middle-overlay">
                                  {/* <Link to={"/draw/" + p.slug} onClick={() => SetDrawDetails(p)} className="text-decoration-none">
                    <div className="border-0 rounded-pill btn pfw-button-red secbutton py-2 px-3 my-1 mb-0 font-face-gb-semibold text-overlay-view">View Draw</div>
                    </Link> */}
                                </div>
                                <div
                                  className={`main-header animation-shine-drawtext-center draw-id-${p.drawID}`}
                                  style={{
                                    padding: '60px 0px',
                                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.62),${
                                      p.BrandingColor2
                                    }),url(${require(`../assets/images/hero-banner.jpg`)})`,
                                    backgroundSize: 'cover',
                                    borderTopLeftRadius: 15,
                                    borderTopRightRadius: 15
                                  }}
                                >
                                  <LogoLoader
                                    src={p.logoUrl}
                                    style={{ width: '100%', height: '100px', objectFit: 'contain' }}
                                  />
                                  {/* <img className="" src={require(`../assets/images/logo/${p.logoUrl}`)} style={{ width: '100%', height: '100px', objectFit: 'contain' }}></img> */}
                                </div>
                              </Link>
                              <div
                                className="draws-title bg-white text-center"
                                style={{ borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}
                              >
                                <h4 className="pt-3 fs-5 font-face-gb branding1 pb-3" style={{ minHeight: 24 }}>
                                  {p.name}
                                </h4>
                                <div className="d-block tickets-home">
                                  {/* <div className="d-none view-more-button"><Link to={"/draw/" + p.slug} onClick={() => SetDrawDetails(p)}  className='border-0 rounded-pill btn pfw-button-red secbutton py-2 px-3 my-1 font-face-gb-semibold'>View Draw</Link></div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                        // </Link>
                      }
                    </>
                  )}
                </div>
              ))}
            </Slider>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  } else {
    return (
      <div className="position-relative">
        <div className="container logo-slider pt-5 pb-0">
          <div className="row align-items-center mb-2">
            <div className="col-md-6 draw-title-data">
              <div className="row align-items-center">
                <div className="col-md-1">
                  <img src={require('../assets/images/playicon.png')} style={{ marginRight: 15 }} />
                </div>
                <div className="col-md-10 text-white" style={{ marginLeft: 10 }}>
                  <h3 className="m-0 p-0">
                    <span className="branding-4 font-face-gb-semibold">Popular</span> on Play Fund Win
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-md-6 text-right d-flex view-all-button" style={{ justifyContent: 'right' }}>
              <Link
                to="/find-a-draw/"
                className="border-0 rounded-pill btn pfw-button-red secbutton py-2 px-3 my-4 font-face-gb-semibold"
              >
                View all
              </Link>
            </div>
          </div>
        </div>
        <div className="" style={{ width: '100%' }}>
          <div className="transparent-item hover-slider">
            <Slider {...settings}>
              {['#e64662', '#59b8b2', '#f9bf00', '#a1194f', '#0e3160']
                .sort(() => Math.random() - 0.5)
                .map((p) => {
                  return (
                    <div className="col-lg-3 col-md-3 col-sm-12 font-face-gb px-2 position-relative" key={p.id}>
                      <SkeletonLoader width="100%" height="250px" borderRadius="10px" bgColor="#fff" fgColor={p} />{' '}
                      <div
                        className="draws-title bg-white text-center"
                        style={{ borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}
                      >
                        <h4 className="pt-3 fs-5 font-face-gb branding1 pb-3" style={{ minHeight: 24 }}></h4>
                      </div>
                    </div>
                  );
                })}
            </Slider>{' '}
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}

export default HomeDrawSlider;

import React, { useEffect, useState, Component } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
//import { DrawData } from "../Data/Draw-data";
import { DrawDataSession } from './Cart';
import { GetDrawList } from '../Data/actionMethods';
import Moment from 'moment';
import CountdownTimer from './CountdownTimer';
import { LogoLoader } from './imageLoader';
import SkeletonLoader from './SkeletonLoader';
import loading from '../assets/images/loading.gif';

function SetDrawDetails(p) {
  DrawDataSession.data = p;
}

function HomeDrawSlider({ heroData, isLoading, Fetched }) {
  const [DrawData, SetDrawData] = useState([]);
  const [drawisActive, setDrawisActive] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);

  

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return <div className="custom-arrow next-arrow" style={{ ...style, display: 'block' }} onClick={onClick} />;
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return <div className="custom-arrow prev-arrow" style={{ ...style, display: 'block' }} onClick={onClick} />;
  }

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false
        }
      }
    ]
  };
  console.log('HeroData', heroData);

  // const sortedHeroData = heroData.sort((a, b) => {
  //   if (a.drawID === "2e77b93a-a86a-4554-bdd3-fff96b76d350") {
  //     return -1;
  //   } else if (b.drawID === "2e77b93a-a86a-4554-bdd3-fff96b76d350") {
  //     return 1;
  //   } else {
  //     return 0;
  //   }
  // });
  let sortedHeroData = '';

  if (heroData) {
    sortedHeroData = heroData.sort((a, b) => {
      if (a.drawID === "2e77b93a-a86a-4554-bdd3-fff96b76d350") {
        return -1;
      } else if (b.drawID === "2e77b93a-a86a-4554-bdd3-fff96b76d350") {
        return 1;
      } else {
        return 0;
      }
    });
  } else {

  }
  

  return (
    <div>
     

      <div className="home-slider bg-branding1 mobile-slider-hero" style={{ width: '100%' }}>
        <div className="">
          {isLoading ? (
            
            <Slider {...settings}>
              
              {sortedHeroData.map((p) => {
                const opacityValue = 0.8; // Set opacity to 50%
                const backgroundColor = `${'#0e3160'}${Math.round(opacityValue * 255).toString(16)}`;
                const style = {
                  backgroundColor: `rgba(${backgroundColor})`
                };
              
                return (
                  <div className="hero-section">
                    {!!p.HeaderImage ? (
                      <div
                        className={`main-header draw-id-${p.drawID} ${p.slug}`}
                        style={{
                          height: '95vh',
                          padding: '115px 0px',
                          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.40), ${p.BrandingColor2} 100%),
                 ${
                   p.heroSliderImage
                     ? `url(${p.heroSliderImage})`
                     : p.HeaderImage.includes('http')
                     ? `url(${p.HeaderImage})`
                     : `url(${require('../assets/images/draws/' + p.HeaderImage)})`
                 }`,
                          backgroundSize: 'cover'
                        }}
                      >
                        <div className="container">
                          <div className="row position-relative">
                            <div
                              className="draws-details col-md-7 rounded-4 p-0"
                              style={{
                                marginTop: 280,
                                marginBottom: 250
                              }}
                            >
                            
                              <div className="draw-new-descr p-4">
                                {p.drawID === '64ad5f16-ce1a-4313-b5d7-17e198349e89' ? (
                                  <LogoLoader
                                    src={p.logoUrl}
                                    style={{
                                      width: '100%',
                                      height: '100px',
                                      objectFit: 'contain',
                                      objectPosition: 'left'
                                    }}
                                  />
                                ) : (
                                  <></>
                                )}
                                {p.SliderCharityName ? (
                                  <p className="fs-5 text-white mb-0">Supporting {p.SliderCharityName}</p>
                                ) : (
                                  <p className="fs-5 text-white mb-0"></p>
                                )}
                                {p.heroSliderTitle ? (
                                  <h4
                                    className="pt-2 display-6 font-face-gb branding1"
                                    style={{
                                      minHeight: 64
                                    }}
                                  >
                                    {p.heroSliderTitle
                                      .replace(/&nbsp;/g, '')
                                      .replace(/&#10;/g, '\n')
                                      .replace(/&#39;/g, "'")
                                      .replace(/&lsquo;/g, "'")
                                      .replace(/&rsquo;/g, "'")
                                      .replace(/&#8239;/g, ' ')
                                      .replace(/&lt;p&gt;/g, '')
                                      .replace(/&lt;\/p&gt;/g, '\n')
                                      .split('\n')
                                      .map((paragraph, index) => (
                                        <h4 key={index}>
                                          {paragraph.trim()}
                                          {/* 
                                                                  &lsquo; &rsquo;
                                                                  {index !== DrawDataSession.data.content.length - 1 && '.'} */}
                                        </h4>
                                      ))}
                                    {/* {p.heroSliderTitle} */}
                                  </h4>
                                ) : (
                                  <h4
                                    className="pt-2 display-6 font-face-gb branding1"
                                    style={{
                                      minHeight: 64
                                    }}
                                  >
                                    {p.name}
                                  </h4>
                                )}

                                {p.heroSliderTitle2 ? (
                                  <h4
                                    className="display-6 font-face-gb branding1"
                                    style={{
                                      minHeight: 64
                                    }}
                                  >
                                    {p.heroSliderTitle2}
                                  </h4>
                                ) : (
                                  ''
                                )}

                                {p.Redirection ? (
                                  <a
                                    href={p.RedirectionUrl}
                                    target={'_blank'}
                                    className="border-0 rounded-pill btn pfw-button-red secbutton py-2 px-3 my-4 mb-0 font-face-gb-semibold"
                                    style={{
                                      lineHeight: 2
                                    }}
                                  >
                                    {Moment() > Moment(p.drawEndDate) ? 'View Draw' : 'Enter Draw'}
                                  </a>
                                ) : (
                                  <Link
                                    to={'/draw/' + p.slug}
                                    onClick={() => SetDrawDetails(p)}
                                    className="border-0 rounded-pill btn pfw-button-red secbutton py-2 mb-0 px-3 my-4 font-face-gb-semibold"
                                    style={{
                                      lineHeight: 2
                                    }}
                                  >
                                    {Moment() > Moment(p.drawEndDate) ? 'View Draw' : 'Enter Draw'}
                                  </Link>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`main-header draw-id-${p.drawID}`}
                        style={{
                          padding: '130px 0px',
                          backgroundImage: `url(${require(`../assets/images/hero-banner.jpg`)})`,
                          backgroundSize: 'cover'
                        }}
                      >
                        <div className="container">
                          <div className="row">
                            <div
                              className="draws-details col-md-7 rounded-4 p-0"
                              style={{
                                marginTop: 120,
                                marginBottom: 100
                              }}
                            >
                              <div className="draw-prizes p-4">
                                <div className="row align-items-center">
                                  <div className="col-md-9">
                                    <h4
                                      className="pt-3 display-6 font-face-gb branding1"
                                      style={{
                                        minHeight: 64
                                      }}
                                    >
                                      {p.name}
                                    </h4>
                                  </div>
                                  <div className="col-md-3">
                                    <LogoLoader
                                      src={p.logoUrl}
                                      style={{
                                        width: '100%',
                                        height: '100px',
                                        objectFit: 'contain'
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="draw-descr p-4"
                                style={{
                                  backgroundColor: `${p.BrandingColor1}`
                                }}
                              >
                                <div
                                  className="draw-description mx-auto text-white"
                                  style={{
                                    width: '100%'
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: `${p.content.substring(0, 350)}`
                                  }}
                                ></div>

                                {p.Redirection ? (
                                  <a
                                    href={p.RedirectionUrl}
                                    target={'_blank'}
                                    className="border-0 rounded-pill btn pfw-button-red secbutton py-2 px-3 mt-4 mb-0 font-face-gb-semibold pb-0"
                                    style={{
                                      lineHeight: 2
                                    }}
                                  >
                                    {Moment() > Moment(p.drawEndDate) ? 'View Draw' : 'Enter Draw'}
                                  </a>
                                ) : (
                                  <Link
                                    to={'/draw/' + p.slug}
                                    onClick={() => SetDrawDetails(p)}
                                    className="border-0 rounded-pill btn pfw-button-red secbutton py-2 px-3 mt-4 mb-0 pb-0 font-face-gb-semibold"
                                    style={{
                                      lineHeight: 2
                                    }}
                                  >
                                    {Moment() > Moment(p.drawEndDate) ? 'View Draw' : 'Enter Draw'}
                                  </Link>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </Slider>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}

export default HomeDrawSlider;

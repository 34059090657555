import React from 'react'
import { Link } from "react-router-dom";
import Siteheader from '../components/header';
import SiteFooter from '../components/footer';
import { UseEffect } from 'react';
import FooterCta from '../components/footer-cta';
import Innerheader from '../components/inner-header';
import { Helmet } from 'react-helmet';

export default function IdentityVerificationPolicy(props) {
 
  return (
    <>
    <Helmet>‍
      <title>Identity Verification Policy | Play Fund Win</title>‍
      <meta name="description" content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities." />
    </Helmet>
    <Siteheader/>
    <Innerheader title="Identity Verification Policy"/>

  <div className='container pt-5 pb-5 my-3 px-lg-5 rounded-4 w-92' style={{backgroundColor: '#f2f2f2'}}>
    <div className="row px-lg-5">
       <div className="col-md-12 align-middle text-left px-lg-5 mt-lg-0 mt-3">
            <p><strong>Policy Detail –</strong> <a className='branding3 fw-bold' href="https://playfundwin.com">(www.playfundwin.com )</a></p>
            <p>As part of our commitment to best practice and in compliance with the Gambling Act and Anti-Money Laundering (AML) regulation, checks may be carried out to confirm age and verify the identity of winners and entrants. This is for the purpose of the prevention of fraud, money laundering, legal or other financial or regulatory reasons within our platform.</p>
            <p><strong>We carry out ID checks for:</strong></p>
            <ul>
                <li>Cash prizes over the value of £1,000</li>
                <li>Non-cash prizes over the value of £2,000</li>
                <li>Prizes involving age restrictions including, but not limited to, alcohol and events</li>
                <li>Where required by the prize provider</li>
            </ul>
            <p>Additional checks may be carried out at random for entries and prize winners as required.</p>
            <p><strong>Why do you need to provide ID?</strong></p>
            <ul>
                <li>To check you are old enough to participate in our draws</li>
                <li>To check whether you have self-excluded from taking part in our draws</li>
                <li>To confirm your identity</li>
            </ul>
            <p><strong>What type of document could you be asked to provide?</strong></p>
            <ul>
                <li>Passport</li>
                <li>Driving Licence</li>
            </ul>
            <p>For further information, please contact <a className='branding3 fw-bold' href="mailto:hello@playfundwin.com">hello@playfundwin.com</a> or visit our terms and conditions policy at <a className='branding3 fw-bold' href="http://www.playfundwin.com">www.playfundwin.com</a></p>
       </div>
    </div>
  </div>

  <FooterCta/>
  <SiteFooter/>
    </>
  )
}

import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios'
import secureLocalStorage from 'react-secure-storage'


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "Block", color: "#fff" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "Block", color: "#fff" }}
      onClick={onClick}
    />
  );
}


export default function UserActiveTicketsSlider() {

  let APIURL = process.env.REACT_APP_API_URL;
  let SITE_ID = process.env.REACT_APP_API_SITE_ID;
  const user = secureLocalStorage.getItem('LogObject');
  const [PurchaseData, SetPurchaseData] = useState([]);
  const [PurchaseDataDefault, SetPurchaseDataDefault] = useState([]);

  const [oneShow, setOneShow] = useState(false);
  const [twoShow, setTwoShow] = useState(false);
  const [threeShow, setThreeShow] = useState(false);
  const [fourShow, setFourShow] = useState(false);
  const [fiveShow, setFiveShow] = useState(false);
  const [sixShow, setSixShow] = useState(false);
  const [isLoaded, setisLoaded] = useState(false);

  var settings = {
    dots: false,
    infinite: true,
    limit: 7,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    // centerMode: true,
    // centerPadding: '100px',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          // centerMode: true,
          // centerPadding: '40px',
          slidesToShow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          // centerMode: true,
          // centerPadding: '40px',
          slidesToShow: 1
        }
      }
    ]
  };

  useEffect(() => {
    // if (!login) {
    //     navigate('/');
    // }
    fetchPurchaseData(user.userID);
  }, []);

  function fetchPurchaseData(userID) {
    axios.get(`${APIURL}/users/${userID}/data/${SITE_ID}`,
      {
        withCredentials: true,
        credentials: "same-origin"
      }).then(
        res => {
          console.log("res", res.data.content);
          res.data.content.drawEntries.forEach(element => {
            element.transactionEndDate = res.data.content.transactions.filter(el => el.transactionDrawEntriesID == element.drawEntryID).map((e) => e.transactionDateTime).join()
            element.transactionStatus = res.data.content.transactions.filter(el => el.transactionDrawEntriesID == element.drawEntryID).map((e) => e.transactionStatus).join()
          });
          setisLoaded(true);
          SetPurchaseData(res.data.content.drawEntries);
          console.log("entry", res.data.content.drawEntries);
          SetPurchaseDataDefault(res.data.content.drawEntries);
        }
      );
  }

  return (
    <>
      {isLoaded ?  <div className='row'>
        <div className="container px-5 py-0 active-tickets-slider font-face-gb">
          {PurchaseData.length > 2?<Slider {...settings}>
          {PurchaseData.map((p) => (
            <div>
              <div className="ticket-slide text-start shadow-md p-4 m-3 bg-white" style={{borderRadius: '20px'}}>
                {/* <img className="logoban w-100 rounded-4" src={require('../assets/HostSliderSection2.png')} ></img> */}
                <h4 className='fs-6 border-bottom border-1 pb-4 mb-4'>{p.drawName}</h4>
                <div className='d-flex justify-content-between'>
                <div className='d-flex flex-column'>
                  <span className='text-muted'>Total Entries</span>
                  <span className='branding1 fs-4'>{p.drawEntries}</span>
                </div>
                <div className='d-flex flex-column border-start border-2 ps-3'>
                  <span className='text-muted'>Total Entry Price</span>
                  <span className='branding1 fs-4'>£{p.drawEntryTotal}</span>
                </div>
                <div className='d-flex align-items-center'>
                {/* <Link to={"/draw/"} className="border-0 rounded-pill btn pfw-button-green secbutton py-2 px-4 font-face-gb-semibold" style={{ lineHeight: 2, fontSize: '14px' }}>View Draw</Link> */}
                </div>
                </div>
                {/* <Link to={"/draw/" + p.slug} onClick={() => SetDrawDetails(p)} className="border-0 rounded-pill btn pfw-button-red secbutton py-2 px-3 my-4 w-100 font-face-gb-semibold" style={{ lineHeight: 2 }}>View Draw</Link> */}
              </div>
            </div>

          ))}
          </Slider>:PurchaseData.length > 0?PurchaseData.map((p) => (
            <div className='text-center d-flex justify-content-center'>
              <div className="ticket-slide text-start shadow-md p-4 m-3 bg-white" style={{borderRadius: '20px', width: '33%'}}>
                {/* <img className="logoban w-100 rounded-4" src={require('../assets/HostSliderSection2.png')} ></img> */}
                <h4 className='fs-6 border-bottom border-1 pb-4 mb-4'>{p.drawName}</h4>
                <div className='d-flex justify-content-between'>
                <div className='d-flex flex-column'>
                  <span className='text-muted'>Total Entries</span>
                  <span className='branding1 fs-4'>{p.drawEntries}</span>
                </div>
                <div className='d-flex flex-column border-start border-2 ps-3'>
                  <span className='text-muted'>Total Entry Price</span>
                  <span className='branding1 fs-4'>£{p.drawEntryTotal}</span>
                </div>
                <div className='d-flex align-items-center'>
                {/* <Link to={"/draw/"} className="border-0 rounded-pill btn pfw-button-green secbutton py-2 px-4 font-face-gb-semibold" style={{ lineHeight: 2, fontSize: '14px' }}>View Draw</Link> */}
                </div>
                </div>
                {/* <Link to={"/draw/" + p.slug} onClick={() => SetDrawDetails(p)} className="border-0 rounded-pill btn pfw-button-red secbutton py-2 px-3 my-4 w-100 font-face-gb-semibold" style={{ lineHeight: 2 }}>View Draw</Link> */}
              </div>
            </div>

          )):
          <p className='branding1 fs-5 text-center mb-0'>You are not participating in live draws. <Link className='branding1 font-face-gb-semibold text-decoration-none red-hover' to={'/find-a-draw'}>Find a draw</Link></p>
          }
          
          <div className='text-center mt-4'>
            <Link to={'/purchase-history'} className="border-0 rounded-pill btn pfw-button-green secbutton py-2 px-4 font-face-gb-semibold" style={{ lineHeight: 2 }}> View All Purchase History</Link>
          </div>
        </div>
      </div> :<p className='branding1 fs-5 text-center mb-0'>Loading Data...</p>}
    </>
  );
}

import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';


export default function WinnerSelect(props) {

    const [value, setValue] = useState('');
    const [prizes, setPrizes] = useState('');

    const [isloaded, setloaded] = useState(false);

    const handleWinnerSelect = (e) => {
        console.log(e.target.value);
        setValue(e.target.value);
    }

    useEffect(() => {
        if(props.winnerData && props.winnerData.length>=1){
            setloaded(true);
                setPrizes(props.winnerData);
                setValue(props.winnerData[0].userName);
            }
            console.log("props",props);
        
    }, [props])

 



    return (
        <>
            {(isloaded) && prizes.length >= 1?
                <div className={props.template == 2? `winner-wrapper p-5 border-2 border-start`:`winner-wrapper border-2 `}>
                    <Form.Select onChangeCapture={handleWinnerSelect}>
                        {prizes.map(item => {
                            return(
                                <option key={"winner" + item.id} value={item.userName}>{item.prizeName}</option>
                            )
                        })
                        }
                    </Form.Select>
                    <div className='show-winner pt-4 branding1'>
                        {prizes.length> 0 && value!=''? prizes.filter(item=> item.userName == value).map(item=>{
                            return(<>
                                <h4 className='fs-5'><strong>Prize Won:</strong> {item.prizeName}</h4>    
                                <h4 className='fs-5 text-uppercase'><strong>Winner:</strong><span className='branding3'> {item.userName} </span></h4>    
                                {/* <h4 className='fs-5'><strong>Ticket Number:</strong> 132456</h4>     */}
                            </>)
                        }):""}
                    </div>
                </div>
                : <></>}
        </>

    )
}

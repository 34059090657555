import React from 'react'
import { Link } from "react-router-dom";
import Siteheader from '../components/header';
import SiteFooter from '../components/footer';
import { UseEffect } from 'react';
import { Inner_Header_Images } from '../components/images';
import FooterCta from '../components/footer-cta';
import HowItWorks from '../components/how-it-works';
import { Helmet } from 'react-helmet';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import cricket  from '../assets/images/Cricket.jpeg';
import DeanLynn from '../assets/images/Dean-Lynn.webp';
import SteveLightFoot from '../assets/images/Steve-Lightfoot.webp';
import SimonRutter from '../assets/images/SR-1x1-1.webp';
import DavidGray from '../assets/images/DG-1x1.jpg';
import DavidFribbins from '../assets/images/fribbins.png';
import CazCusumano from '../assets/images/caz.png';
import Sarfaraz from '../assets/images/sarfaraz.jpg';
import luke from '../assets/images/luketyler.jpg';
import Lizzie from '../assets/images/Lizzie.jpg';
import Raymond from '../assets/images/Raymond.jpg';
export default function aboutUs(props) {
 
  return (
    <>
    <Helmet>‍
      <title>About Us | Play Fund Win</title>‍
      <meta name="description" content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities." />
    </Helmet>
    <Siteheader/>
    <div className="container pt-5 pb-5 rounded-4 bg-image-np about-header position-relative" style={{backgroundImage: `url(${Inner_Header_Images.InnerHeaderBg})`}}>
   <div className='container pt-5 pb-5'>
    <div className="row align-items-center" >
      <div className="col-xl-6 col-lg-6 col-md-6 col-12 align-middle text-center pt-lg-5 px-lg-5 m-auto">
        <h1 className="text-white font-face-gb-semibold fw-normal display-3 mb-3">About Us</h1>
        <p className='text-white pb-5'>We are passionate about making a difference to people’s lives, and providing them with the tools
to raise the funds they need to make a positive impact.</p>
       </div>
    </div>
    </div>
  </div>
  <div className='about-content-area container pb-5 my-4 px-0 rounded-4 position-relative' style={{backgroundColor: '#f2f2f2'}}>
    <div className="row mx-auto" style={{width:'80%'}}>
      <div className="col-md-6 align-middle text-center about-left-img position-relative">
        <LazyLoadImage
        width={'100%'}
        style={{borderRadius: '1rem', marginTop: '-128px'}}
        src={cricket}
        alt="cricket"
        />
        {/* <img width="100%" style={{borderRadius: '1rem', marginTop: '-128px'}} src={require('../assets/images/Cricket.jpeg')} /> */}
       
       </div>
       <div className="col-md-6 align-middle text-left ps-lg-5 pt-5 mt-lg-0 mt-3 about-right-ctn position-relative">
        <h2 className="display-4 branding1 mb-3">Intro <strong className='font-face-gb-semibold'>about us</strong></h2>
        <p>Play Fund Win was started in 2019 by three friends with a passion for sport, events and tech, who are on a mission to raise millions of pounds for good causes and charities using our revolutionary digital fundraising platform. By digitising paper-based raffles, we make hosting a draw easy, and make taking part fun and engaging.
          </p><p>Play our draws. Fund good causes. Win amazing prizes.</p>
          {/* <p className='mt-5 mb-4'><strong className='branding1 fs-5 font-face-gb-semibold fw-normal'>“Play Fund Win has always been about opening doors. Opening doors and breaking down financial barriers to participation in sport. Opening doors by digitising manual processes and opening doors in areas that previously haven’t been capitalised on for fundraising (such as crowds at events). Playing sport as a kid, and working in events, has given me so much. Being able to give something back in both these areas is a huge passion which drives all of what we do with Play Fund Win. Play our draws. Fund good causes. Win cash prizes”</strong></p>
      <p className='branding2 fs-5'>Dan, CEO</p> */}
       </div>
    </div>
  </div>
  <div className='team-section'>
  <div className='container pt-5 pb-lg-5 text-center rounded-bottom'>
  <h2 className="branding1 display-4">Meet the <strong className='font-face-gb-semibold'>team</strong></h2>
  <p>We’re all about sports, events, charity and technology, so we thought we’d tell you a bit about ourselves and our favourite things in those areas:</p>
  </div>
  <div className='container pb-5'>
    <div className='row mx-auto' style={{width: '80%'}}>
    <div className="col-md-3 text-center p-3">        
      <Link to={'/dean-lynn'}>
        <LazyLoadImage
        width={'100%'}
        style={{height: 250, objectFit: 'cover', borderRadius: 10}}
        src={DeanLynn}
        alt="Dean Lynn"
        />
        {/* <img width="100%" style={{height: 250, objectFit: 'cover', borderRadius: 10}} src={require('../assets/images/Dean-Lynn.webp')} /> */}
        </Link>
      <p style={{marginTop: '-15px'}}><a className='pributton text-decoration-none font-face-gb-semibold fs-5 rounded-circle text-white d-block position-relative mx-auto' style={{padding: '8px', width:35, height:35, lineHeight:'21px'}}  href="http://linkedin.com/in/dean-lynn" target="_blank" rel="noopener">in</a>
      </p><h4 className='branding1 font-face-gb-semibold fs-5'>Dean Lynn</h4>
      <p className='branding3 fs-6'>CEO | Co-Founder</p>
</div>
      <div className="col-md-3 text-center p-3">        
      <Link to={'/steve-lightfoot'}>
        <LazyLoadImage
        width={'100%'}
        style={{height: 250, objectFit: 'cover', borderRadius: 10}}
        src={SteveLightFoot}
        alt="Steve Lightfoot"
        />
        {/* <img width="100%" style={{height: 250, objectFit: 'cover', borderRadius: 10}} src={require('../assets/images/Steve-Lightfoot.webp')} /> */}
        </Link>
      <p style={{marginTop: '-15px'}}><a className='pributton text-decoration-none font-face-gb-semibold fs-5 rounded-circle text-white d-block position-relative mx-auto' style={{padding: '8px', width:35, height:35, lineHeight:'21px'}} href="https://www.linkedin.com/in/steve-l-bb2b91121" target="_blank" rel="noopener">in</a>
      </p><h4 className='branding1 font-face-gb-semibold fs-5'>Steve Lightfoot</h4>
      <p className='branding3 fs-6'>CRO | Co-Founder</p>
</div>
      
      
      {/* <div className="col-md-3 text-center p-3">
      <Link to={'/david-broadbent'}><img width="100%" style={{height: 250, objectFit: 'cover', borderRadius: 10}} src={require('../assets/images/David-Broadbent.webp')} /></Link>
      <p style={{marginTop: '-15px'}}><a className='pributton text-decoration-none font-face-gb-semibold fs-5 rounded-circle text-white d-block position-relative mx-auto' style={{padding: '8px', width:35, height:35, lineHeight:'21px'}}  href="https://www.linkedin.com/in/david-broadbent-7847362a/" target="_blank" rel="noopener">in</a>
      </p><h4 className='branding1 font-face-gb-semibold fs-5'>David Broadbent</h4>
      <p className='branding3 fs-6'>Business Development Manager – Sport</p>
      </div> */}
      
      <div className="col-md-3 text-center p-3">
      <Link to={'/simon-rutter'}>
        <LazyLoadImage
        width={'100%'}
        style={{height: 250, objectFit: 'cover', borderRadius: 10}}
        src={SimonRutter}
        alt="Simon Rutter"
        />
        {/* <img width="100%" style={{height: 250, objectFit: 'cover', borderRadius: 10}} src={require('../assets/images/SR-1x1-1.webp')} /> */}
        </Link>
      <p style={{marginTop: '-15px'}}><a className='pributton text-decoration-none font-face-gb-semibold fs-5 rounded-circle text-white d-block position-relative mx-auto' style={{padding: '8px', width:35, height:35, lineHeight:'21px'}}  href="#" target="_blank" rel="noopener">in</a>
      </p><h4 className='branding1 font-face-gb-semibold fs-5'>Simon Rutter</h4>
      <p className='branding3 fs-6'>CTO</p>
      </div>

      <div className="col-md-3 text-center p-3">
      <Link to={'/david-gray'}>
        <LazyLoadImage
        width={'100%'}
        style={{height: 250, objectFit: 'cover', borderRadius: 10}}
        src={DavidGray}
        alt="David Gray"
        />
        {/* <img width="100%" style={{height: 250, objectFit: 'cover', borderRadius: 10}} src={require('../assets/images/DG-1x1.jpg')} /> */}
      </Link>
      <p style={{marginTop: '-15px'}}><a className='pributton text-decoration-none font-face-gb-semibold fs-5 rounded-circle text-white d-block position-relative mx-auto' style={{padding: '8px', width:35, height:35, lineHeight:'21px'}}  href="#" target="_blank" rel="noopener">in</a>
      </p><h4 className='branding1 font-face-gb-semibold fs-5'>David Gray</h4>
      <p className='branding3 fs-6'>Business Development Director</p>
      </div>

      <div className="col-md-3 text-center p-3">
      <Link to={'#'}>
        {/* <img width="100%" style={{height: 250, objectFit: 'cover', borderRadius: 10}} src={require('../assets/images/fribbins.png')} /> */}
       <LazyLoadImage
        width={'100%'}
        style={{height: 250, objectFit: 'cover', borderRadius: 10}}
        src={DavidFribbins}
        alt="David Fribbins"
        />

        </Link>
      <p style={{marginTop: '-15px'}}><a className='pributton text-decoration-none font-face-gb-semibold fs-5 rounded-circle text-white d-block position-relative mx-auto' style={{padding: '8px', width:35, height:35, lineHeight:'21px'}}  href="#" target="_blank" rel="noopener">in</a>
      </p><h4 className='branding1 font-face-gb-semibold fs-5'>David Fribbins</h4>
      <p className='branding3 fs-6'>Finance Director</p>
      </div>

      <div className="col-md-3 text-center p-3">
      <Link to={'#'}>
        <LazyLoadImage
        width={'100%'}
        style={{height: 250, objectFit: 'cover', borderRadius: 10}}
        src={CazCusumano}
        alt="Caz Cusumano"
        />

        {/* <img width="100%" style={{height: 250, objectFit: 'cover', borderRadius: 10}} src={require('../assets/images/caz.png')} /> */}
        </Link>
      <p style={{marginTop: '-15px'}}><a className='pributton text-decoration-none font-face-gb-semibold fs-5 rounded-circle text-white d-block position-relative mx-auto' style={{padding: '8px', width:35, height:35, lineHeight:'21px'}}  href="#" target="_blank" rel="noopener">in</a>
      </p><h4 className='branding1 font-face-gb-semibold fs-5'>Caz Cusumano</h4>
      <p className='branding3 fs-6'>Creative Director</p>
      </div>

      {/* <div className="col-md-3 text-center p-3">
      <Link to={'#'}><img width="100%" style={{height: 250, objectFit: 'cover',objectPosition : 'top', borderRadius: 10}} src={require('../assets/images/deantaylor.jpg')} /></Link>
      <p style={{marginTop: '-15px'}}><a className='pributton text-decoration-none font-face-gb-semibold fs-5 rounded-circle text-white d-block position-relative mx-auto' style={{padding: '8px', width:35, height:35, lineHeight:'21px'}}  href="#" target="_blank" rel="noopener">in</a>
      </p><h4 className='branding1 font-face-gb-semibold fs-5'>Dean Taylor</h4>
      <p className='branding3 fs-6'>Business Development (Grassroots)</p>
      </div> */}

<div className="col-md-3 text-center p-3">
      <Link to={'/raymond-grant'}>
        <LazyLoadImage
        width={'100%'}
        style={{height: 250, objectFit: 'cover', borderRadius: 10 , objectPosition :'top'}}
        src={Raymond}
        alt="Raymond"
        />
        {/* <img width="100%" style={{height: 250, objectFit: 'cover', borderRadius: 10}} src={require('../assets/images/Lizzie.jpg')} /> */}
        </Link>
      <p style={{marginTop: '-15px'}}><a className='pributton text-decoration-none font-face-gb-semibold fs-5 rounded-circle text-white d-block position-relative mx-auto' style={{padding: '8px', width:35, height:35, lineHeight:'21px'}}  href="#" target="_blank" rel="noopener">in</a>
      </p><h4 className='branding1 font-face-gb-semibold fs-5'>Raymond Grant</h4>
      <p className='branding3 fs-6'>Business Development Manager</p>
      </div>

      <div className="col-md-3 text-center p-3">
      <Link to={'/luke-tyler'}>
        <LazyLoadImage
        width={'100%'}
        style={{height: 250, objectFit: 'cover', borderRadius: 10, objectPosition :'top'}}
        src={luke}
        alt="Luke Tyler"
        />
        {/* <img width="100%" style={{height: 250, objectFit: 'cover',objectPosition : 'top', borderRadius: 10}} src={require('../assets/images/sarfaraz.jpg')} /> */}
        </Link>
      <p style={{marginTop: '-15px'}}><a className='pributton text-decoration-none font-face-gb-semibold fs-5 rounded-circle text-white d-block position-relative mx-auto' style={{padding: '8px', width:35, height:35, lineHeight:'21px'}}  href="#" target="_blank" rel="noopener">in</a>
      </p><h4 className='branding1 font-face-gb-semibold fs-5'>Luke Tyler</h4>
      <p className='branding3 fs-6'>Chief Operating Officer</p>
      </div>

      {/* <div className="col-md-3 text-center p-3">
      <Link to={'#'}>
        <LazyLoadImage
        width={'100%'}
        style={{height: 250, objectFit: 'cover', borderRadius: 10, objectPosition :'top'}}
        src={Sarfaraz}
        alt="Sarfaraz Ansari"
        />
        {/* <img width="100%" style={{height: 250, objectFit: 'cover',objectPosition : 'top', borderRadius: 10}} src={require('../assets/images/sarfaraz.jpg')} /> 
        </Link>
      <p style={{marginTop: '-15px'}}><a className='pributton text-decoration-none font-face-gb-semibold fs-5 rounded-circle text-white d-block position-relative mx-auto' style={{padding: '8px', width:35, height:35, lineHeight:'21px'}}  href="#" target="_blank" rel="noopener">in</a>
      </p><h4 className='branding1 font-face-gb-semibold fs-5'>Sarfaraz Ansari</h4>
      <p className='branding3 fs-6'>Tech Development Manager</p>
      </div> */}

      <div className="col-md-3 text-center p-3">
      <Link to={'#'}>
        <LazyLoadImage
        width={'100%'}
        style={{height: 250, objectFit: 'cover', borderRadius: 10, objectPosition :'top'}}
        src={Lizzie}
        alt="Lizzie Benton"
        />
        {/* <img width="100%" style={{height: 250, objectFit: 'cover', borderRadius: 10}} src={require('../assets/images/Lizzie.jpg')} /> */}
        </Link>
      <p style={{marginTop: '-15px'}}><a className='pributton text-decoration-none font-face-gb-semibold fs-5 rounded-circle text-white d-block position-relative mx-auto' style={{padding: '8px', width:35, height:35, lineHeight:'21px'}}  href="#" target="_blank" rel="noopener">in</a>
      </p><h4 className='branding1 font-face-gb-semibold fs-5'>Lizzie Benton</h4>
      <p className='branding3 fs-6'>Company Coach</p>
      </div>

     


     

      
      {/* <div className="col-md-3 text-center p-3">
      <Link to={'/olivia-cork'}><img width="100%" style={{height: 250, objectFit: 'cover', borderRadius: 10}} src={require('../assets/images/LC-1x1-1.webp')} /></Link>
      <p style={{marginTop: '-15px'}}><a className='pributton text-decoration-none font-face-gb-semibold fs-5 rounded-circle text-white d-block position-relative mx-auto' style={{padding: '8px', width:35, height:35, lineHeight:'21px'}}  href="https://www.linkedin.com/in/olivia-cork-79a896110/" target="_blank" rel="noopener">in</a>
      </p><h4 className='branding1 font-face-gb-semibold fs-5'>Olivia Cork</h4>
      <p className='branding3 fs-6'>Operations Manager</p>
      </div> */}
    </div>
    {/* <div className='row mx-auto' style={{width: '80%'}}>
    <div className="col-md-3 text-center p-3">
      <Link to={'#'}><img width="100%" style={{height: 250, objectFit: 'cover', borderRadius: 10}} src={require('../assets/images/ayeisha_smith.jpg')} /></Link>
      <p style={{marginTop: '-15px'}}><a className='pributton text-decoration-none font-face-gb-semibold fs-5 rounded-circle text-white d-block position-relative mx-auto' style={{padding: '8px', width:35, height:35, lineHeight:'21px'}}  href="#" target="_blank" rel="noopener">in</a>
      </p><h4 className='branding1 font-face-gb-semibold fs-5'>Ayeisha Smith</h4>
      <p className='branding3 fs-6'></p>
      </div> */}
     
      
      {/* <div className="col-md-3 text-center p-3">
      <Link to={'/eleanor-forbes'}><img width="100%" style={{height: 250, objectFit: 'cover', borderRadius: 10}} src={require('../assets/images/EF-1x1-2.webp')} /></Link>
      <p style={{marginTop: '-15px'}}><a className='pributton text-decoration-none font-face-gb-semibold fs-5 rounded-circle text-white d-block position-relative mx-auto' style={{padding: '8px', width:35, height:35, lineHeight:'21px'}}  href="https://www.linkedin.com/in/eleanor-forbes-79b740145/" target="_blank" rel="noopener">in</a>
      </p><h4 className='branding1 font-face-gb-semibold fs-5'>Eleanor Forbes</h4>
      <p className='branding3 fs-6'>Fundraising Business Administrator</p>
      </div> */}
    {/* </div> */}
    <div className='row mx-auto' style={{width: '80%'}}>
      
    </div>
  </div>
  </div>
  
    <HowItWorks/>
    <FooterCta/>
  <SiteFooter/>
    </>
  )
}

import axios from 'axios';

let APIURL = process.env.REACT_APP_API_URL;
let SITEID = process.env.REACT_APP_API_SITE_ID;

export async function GetDrawList() {
  return axios
    .get(`${APIURL}/draws/get/visible`)
    .then((res) => res)
    .catch((err) => err);
}

export async function GetDrawListLimit() {
  return axios
    .get(`${APIURL}/draws/get/visible?limit=15`)
    .then((res) => res)
    .catch((err) => err);
}

export async function GetDrawListHeroSlider() {
  return axios
    .get(`${APIURL}/draws/get/visible?isHeroSlider=true`)
    .then((res) => res)
    .catch((err) => err);
}

//http://localhost:5000/api/v1/draws/draft/hadleigh-goldstar-athletic-draws
export async function GetDraftDrawBySlug(slug) {
  return axios
    .get(`${APIURL}/draws/draft/${slug}`)
    .then((res) => res)
    .catch((err) => err);
}

export async function getAllPosts() {
  return axios
    .get(`${APIURL}/posts`)
    .then((res) => res)
    .catch((err) => err);
}

export async function getPostByID(postID) {
  return axios
    .get(`${APIURL}/posts/single-post?postID=${postID}`)
    .then((res) => res)
    .catch((err) => err);
}

export async function getPostByCategory(taxonomyID) {
  return axios
    .get(`${APIURL}/posts/single-category?taxonomyID=${taxonomyID}`)
    .then((res) => res)
    .catch((err) => err);
}

export async function getPostByTag(taxonomyID) {
  return axios
    .get(`${APIURL}/posts/single-tag?taxonomyID=${taxonomyID}`)
    .then((res) => res)
    .catch((err) => err);
}

export async function getPostByAuthor(postUserID) {
  return axios
    .get(`${APIURL}/posts/single-author/${postUserID}`)
    .then((res) => res)
    .catch((err) => err);
}

export async function getLiveDrawListDraftbyTaxanomyID(taxanomySlug, page = 1, limit = 10, search = '', sort = 'DESC', sortBy = 'id') {
  return axios
    .get(`${APIURL}/category/livedrawlist/taxanomy/slug/${taxanomySlug}/${page}/${limit}/?search=${search}&sort=${sort}&sortBy=${sortBy}`)
    .then((res) => res)
    .catch((err) => err);
}
import React, { Component } from 'react'
import Slider from "react-slick";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Image1 from '../assets/MicrosoftTeams-image__10_.png';
import Image2 from '../assets/MicrosoftTeams-image__12_.png';
import Image3 from '../assets/Chadderton-FC-logo.0cd94df6b051c3c19670-1.webp';
import Image4 from '../assets/palace-for-life.4610e10c80a24107bb52.webp';
import Image5 from '../assets/City-in-the-Commuinity.png';
import Image6 from '../assets/great-northern.png';
import Image7 from '../assets/Herefordshire.png';
import Image8 from '../assets/lymphoma.png';
import Image9 from '../assets/somethingtolookforwardto.jpeg';
import Image10 from '../assets/st-albans-city-football.webp';


export default function HostImgSlider() {
  var settings = {
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay : true,
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          infinite: true,

        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          infinite: true,

        }
      }

    ]
  };
  return (
    <div className='container-lg pb-5'>
      <div className='row'>
        <Slider {...settings}>

          <div className='col-md-2 col-sm-3 my-3 px-3 d-flex align-items-center justify-content-center'>
            <LazyLoadImage
              width={'85%'}
              height={'100%'}
              src={Image1}
              alt='logo'
            />
            {/* <img width={'85%'} height={'100%'} src={require('../assets/MicrosoftTeams-image__10_.png')} /> */}
          </div>

          <div className='col-md-2 col-sm-3 my-3 px-3 d-flex align-items-center justify-content-center'>
           <LazyLoadImage
              width={'85%'}
              height={'100%'}
              src={Image2}
              alt='logo'
            />
            {/* <img width={'85%'} height={'100%'} src={require('../assets/MicrosoftTeams-image__12_.png')} /> */}
          </div>

          <div className='col-md-2 col-sm-3 my-3 px-3 d-flex align-items-center justify-content-center'>
            <LazyLoadImage
              width={'85%'}
              height={'100%'}
              src={Image3}
              alt='logo'
            />
            {/* <img width={'85%'} height={'100%'} src={require('../assets/Chadderton-FC-logo.0cd94df6b051c3c19670-1.webp')} /> */}
          </div>

          <div className='col-md-2 col-sm-3 my-3 px-3 d-flex align-items-center justify-content-center'>
            <LazyLoadImage
              width={'85%'}
              height={'100%'}
              src={Image4}
              alt='logo'
            />

            {/* <img width={'90%'} height={'100%'} src={require('../assets/palace-for-life.4610e10c80a24107bb52.webp')} /> */}
          </div>

          <div className='col-md-2 col-sm-3 my-3 px-3 d-flex align-items-center justify-content-center'>
            <LazyLoadImage
            width={'85%'}
            height={'100%'}
            src={Image5}
            alt='logo'
          />

            {/* <img width={'85%'} height={'100%'} src={require('../assets/City-in-the-Commuinity.png')} /> */}
          </div>

          <div className='col-md-2 col-sm-3 my-md-3 px-3 d-flex align-items-center justify-content-center'>
          <LazyLoadImage
            width={'85%'}
            height={'100%'}
            src={Image6}
            alt='logo'
          />
            {/* <img width={'85%'} src={require('../assets/great-northern.png')} /> */}
          </div>

          <div className='col-md-2 col-sm-3 my-md-3 px-3 d-flex align-items-center justify-content-center'>
           <LazyLoadImage
            width={'85%'}
            height={'100%'}
            src={Image7}
            alt='logo'
          />
            {/* <img width={'85%'} src={require('../assets/Herefordshire.png')} /> */}
          </div>

          <div className='col-md-2 col-sm-3 my-md-3 px-3 d-flex align-items-center justify-content-center'>
            <LazyLoadImage
            width={'85%'}
            height={'100%'}
            src={Image8}
            alt='logo'
          />
            {/* <img width={'85%'} src={require('../assets/lymphoma.png')} /> */}
          </div>

          <div className='col-md-2 col-sm-3 my-md-3 px-3 d-flex align-items-center justify-content-center'>
            <LazyLoadImage
            width={'85%'}
            height={'100%'}
            src={Image9}
            alt='logo'
          />
            {/* <img width={'85%'} src={require('../assets/somethingtolookforwardto.jpeg')} /> */}
          </div>

          <div className='col-md-2 col-sm-3 my-md-3 px-3 d-flex align-items-center justify-content-center'>
            <LazyLoadImage
            width={'85%'}
            height={'100%'}
            src={Image10}
            alt='logo'
          />
            {/* <img width={'85%'} src={require('../assets/st-albans-city-football.webp')} /> */}
          </div>

        </Slider>
      </div>
    </div>
  )
}

import Slider from 'react-slick';
import ImageLoader from './imageLoader';
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import axios from 'axios';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return <div className="custom-arrow next-arrow" style={{ ...style, display: 'block' }} onClick={onClick} />;
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return <div className="custom-arrow prev-arrow" style={{ ...style, display: 'block' }} onClick={onClick} />;
}

export default function TemplateSlider(props) {
  const APIURL = process.env.REACT_APP_API_URL;

  const [DrawData, setDrawData] = useState([]);
  const [PrizeData, setPrizeData] = useState([]);
  const [PrizeDataMobile, setPrizeDataMobile] = useState([]);

  const [totalQuantities, setTotalQuantities] = useState(0);

  const [open, setOpen] = useState(false);

  const [openCollapseId, setOpenCollapseId] = useState(null);

  const [openSlider, setOpenSlider] = useState(false);

  const [isMobile, setIsMobile] = useState(false);

  const [defaultPrizeData, setDefaultPrizeData] = useState([]);

  const mobileWidth = 768;
  const currentWidth = window.innerWidth;

  useEffect(() => {
    function handleResize() {
      // Check if the screen width is less than a certain value (e.g., 768 pixels for typical mobile devices)

      setIsMobile(currentWidth < mobileWidth);
    }

    console.log('mobileWidth', mobileWidth);
    console.log('currentWidth', currentWidth);
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);
    // Call handleResize once to set the initial value
    handleResize();
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [mobileWidth, currentWidth]);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    centerMode: false,
    centerPadding: '120px',
    slidesToShow: PrizeData.length,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      console.log({ PrizeData });
      console.log(`Before change: current=${current}, next=${next}`);
    },
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: '120px'
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          centerPadding: '120px'
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          arrows: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: true
        }
      }
    ]
  };

  const parser = new DOMParser();
  console.log('padding', props.isPadding);

  function calculateTotalQuantity(PrizeData) {
    let sum = 0;
    {
      PrizeData.forEach((item) => {
        sum += item.prizeQuantity;
      });
    }
    return sum;
  }

  useEffect(() => {
    axios
      .get(APIURL + `/draws/${props.drawID}/prizes`, {
        withCredentials: true,
        credentials: 'same-origin'
      })
      .then((res) => {
        const prizeContent = res?.data?.content;
        console.log('Original prizeContent:', prizeContent);
        setPrizeData(prizeContent);
        setTotalQuantities(calculateTotalQuantity(prizeContent));
      });
  }, [props.drawID, APIURL]);

  return (
    <div className={`prize-block-inner ${PrizeData.length > 1 ? 'slide-more' : 'slide-less'} px-0 pt-0`}>
      <div className="row">
        <Slider {...settings} initialSlide={0}>
          {PrizeData.map((item, index) => (
            <div
              className={`prize-block template-slider ${PrizeData.length > 1 ? 'greater-slider' : 'small-slider'}`}
              key={item.prizeID} // Use a unique identifier instead of index
            >
              <div>
                <div
                  className="prize-photo bg-white col-sm-12 col-md-12 col-lg-12 text-center p-5 position-relative"
                  style={{
                    height: 330,
                    backgroundImage: `url(${item.prizeImageURL})`,
                    backgroundSize: 'cover',
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10
                  }}
                />
                <div
                  className="prize-block-testimonial single-inner text-left py-3 col-sm-12 px-2 col-md-12 col-lg-12"
                  style={{
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    backgroundColor: '#ffffff',
                    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 3px',
                    minHeight: PrizeData.length > 1 ? 100 : 120
                  }}
                >
                  <h4
                    className="font-face-sh-bold title-slider text-center py-2 fs-5 text-dark mt-0 mb-2 px-3"
                    dangerouslySetInnerHTML={{ __html: item.prizeName }}
                  />
                  {item.prizeDrawID !== 'e2826ee4-a54d-4815-af5c-35ce2f2f440d' && (
                    <div
                      id={`collapse-${item.prizeID}`}
                      className={`prizeSlidertemp ${openCollapseId === item.prizeID ? 'active' : ''}`}
                    >
                      {item.prizeDescription
                        .replace(/&nbsp;/g, '')
                        .replace(/&#10;/g, '\n')
                        .replace(/&#39;/g, "'")
                        .replace(/&lsquo;/g, "'")
                        .replace(/&rsquo;/g, "'")
                        .replace(/&#8239;/g, ' ')
                        .split('\n')
                        .map((paragraph, idx) => {
                          const trimmedParagraph = paragraph.trim();
                          if (trimmedParagraph !== '') {
                            return (
                              <li key={idx} className={`fw-normal fs-5 branding1 mt-0 mb-0 ms-5 ${idx}`}>
                                <img
                                  className="temp-icon-slider"
                                  src={require(`../assets/images/logoMark-CompoundPathItem.png`)}
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    objectFit: 'contain',
                                    marginRight: 10,
                                    marginTop: 3
                                  }}
                                  alt=""
                                />
                                {trimmedParagraph}
                              </li>
                            );
                          }
                          return null;
                        })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

import React from 'react';
import '../assets/addressLoader.css'
const AddressLoader = () => {
  return (
    <div className="address-loader-container">
      <div className="a-loader"></div>
    </div>
  );
};

export default AddressLoader;

import React from 'react'
import Siteheader from '../components/header'
import SiteFooter from '../components/footer'
import { Helmet } from 'react-helmet'

export default function ClubViewDraws() {
  return (
    <>
    <Helmet>‍
      <title>View Draws | Play Fund Win</title>‍
      <meta name="description" content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities." />
    </Helmet>
      <Siteheader />

      <section className="h-100 w-100 bg-f2 container px-lg-5 py-5 position-relative bg-before-after rounded-4">
        <div className="container h-100 p-lg-5 ">
            <div className="find-draw-form text-center">
                <h2 className='branding1 display-4 font-face-gb-semibold'>View Draws</h2>
            </div>
        </div>
      </section>

      <SiteFooter />
    </>
  )
}

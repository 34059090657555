import { DrawDataSession } from './Cart';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { GetDrawList } from '../Data/actionMethods';
import loading from '../assets/images/loading.gif';
import Moment from 'moment';
import CountdownTimer from './CountdownTimer';
import { LogoLoader } from './imageLoader';

function SetDrawDetails(p) {
  DrawDataSession.data = p;
}

function FindDrawTabs(props) {
  const [displayDataActive, SetDisplayData] = useState([]);
  const [DrawData, SetDrawData] = useState([]);

  useEffect(() => {
    GetDrawList().then((res) => {
      let dt = [];
      let data = res.data.content;
      for (let i = 0; i < data.length; i++) {
        if (!data[i].drawContent) {
          console.log(`Draw with ID ${data[i].drawID} is missing content.`);
          continue;
        }
        data[i].drawContent = data[i].drawContent.length == 0 ? [] : JSON.parse(data[i].drawContent);
        if (data[i].drawContent.length != 0) {
          data[i].drawContent.drawHostID = data[i].drawHostID;
          data[i].drawContent.drawHostCauseID = data[i].drawHostCauseID;
          data[i].drawContent.drawStartDate = data[i].drawStartDate;
          data[i].drawContent.drawEndDate = data[i].drawEndDate;
          data[i].drawContent.drawIsActive = data[i].drawIsActive;
          data[i].drawContent.drawIsActive = data[i].drawIsActive;
          dt.push(data[i].drawContent);
        } else {
          let elm = {};
          elm.id = i;
          elm.drawID = data[i].drawID;
          elm.name = data[i].drawName;
          elm.price = 5;
          elm.slug = data[i].slug;
          elm.logoUrl = data[i].logoUrl ? data[i].logoUrl : 'admin.png';
          elm.HeaderImage = data[i].HeaderImage;
          elm.FeaturedUrl = data[i].FeaturedUrl;
          elm.HeaderLeftImage = '';
          elm.BrandingColor1 = data[i].drawBrandPrimary;
          elm.BrandingColor2 = data[i].drawBrandSecondary;
          elm.content = '';
          elm.PlayContent = '-';
          elm.drawEndDate = data[i].drawEndDate;
          elm.drawStartDate = data[i].drawStartDate;
          elm.FundContent = data[i].FundContent;
          elm.WinContent = data[i].WinContent;
          elm.ticketCountLabel = data[i].ticketCountLabel;
          elm.subscription = data[i].subscription;
          elm.Redirection = data[i].Redirection;
          elm.RedirectionUrl = data[i].RedirectionUrl;
          elm.template = data[i].template;
          elm.prizeSlider = data[i].prizeSlider;
          dt.push(elm);
        }
      }
      let activeDraw = dt.filter(
        (item) => Moment() < Moment(item.drawEndDate) && Moment() > Moment(item.drawStartDate)
      );
      SetDrawData(activeDraw);
      SetDisplayData(activeDraw);
    });
  }, []);

  useEffect(() => {
    if (props.search.trim().length >= 1 || props.search.trim().length == 0) {
      SetDisplayData(
        DrawData.filter((item) => {
          if (item.name.toLowerCase().includes(props.search.toLowerCase())) {
            return item;
          }
        })
      );
    }
  }, [props.search]);

  return (
    <>
      {DrawData.length != 0 ? (
        <div>
          <div className="row mt-5">
            {displayDataActive.map((p) => (
              <>
                {p.TestDraw === 1 ? (
                  ''
                ) : (
                  <div className="col-lg-4 col-md-4 col-sm-12 font-face-gb" key={p.drawID}>
                    <div
                      className={`slider-sec mt-5 text-center mb-5 bg-white m-1 draw-id-${p.drawID}`}
                      style={{ borderBottomLeftRadius: 12, borderBottomRightRadius: 12 }}
                    >
                      <div
                        className="colour-left-right"
                        style={{
                          background: `linear-gradient( to right, ${p.BrandingColor1} 50%, ${p.BrandingColor2} 50%)`,
                          height: 23,
                          borderTopLeftRadius: 10,
                          borderTopRightRadius: 10
                        }}
                      />

                      <div
                        className="draw-logo mx-auto rounded-pill"
                        style={{
                          marginTop: -70,
                          width: 120,
                          height: 120,
                          background: `linear-gradient( to right, ${p.BrandingColor1} 50%, ${p.BrandingColor2} 50%)`,
                          padding: 10
                        }}
                      >
                        <div
                          className="draw-logo-wrap p-2 find-a-draw-logo rounded-pill w-100 h-100"
                          style={{ backgroundColor: `${p.BrandingColor1}`, border: `3px solid #fff` }}
                        >
                          <LogoLoader
                            className="rounded-circle"
                            src={p.logoUrl}
                            style={{ width: '100% !important', height: '100%', objectFit: 'contain' }}
                          />
                        </div>
                      </div>
                      <div className="px-4 pb-2">
                        {p.Redirection == true ? (
                          <a href={p.RedirectionUrl} target="_blank" className="text-decoration-none">
                            <h4 className="pt-3 fs-5 font-face-gb branding1">{p.name}</h4>
                          </a>
                        ) : (
                          <Link
                            to={'/draw/' + p.slug}
                            onClick={() => SetDrawDetails(p)}
                            className="text-decoration-none"
                          >
                            <h4 className="pt-3 fs-5 font-face-gb branding1">{p.name}</h4>
                          </Link>
                        )}
                        <hr className="bg-branding2 mx-auto my-3" style={{ width: 28, height: 3, opacity: 1 }} />
                        <div className="draw-countdown">
                          <p className="branding1">Draw Countdown</p>
                          <div className="date-countdown d-md-flex gap-3 justify-content-center">
                            <div className="draw-date branding1 m-md-0 m-3">
                              {Moment(p.drawEndDate).format('DD MMMM YYYY')}
                            </div>
                            {p.subscription == false ? (
                              <div className="countdown-timer">
                                <div
                                  className="draw-counts speed1"
                                  data-time="1664578740"
                                  data-lotteryid="12337"
                                  data-format="dhm"
                                >
                                  <span
                                    className={`demo12337 branding1 px-2 py-2 rounded-pill border ${
                                      Moment() > Moment(p.drawEndDate) ? 'secbutton-border' : 'thirbutton-border'
                                    }`}
                                  >
                                    {Moment() > Moment(p.drawEndDate) ? (
                                      <span className="branding3">Draw Finished</span>
                                    ) : (
                                      <CountdownTimer time={p.drawEndDate} />
                                    )}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        {p.Redirection == true ? (
                          <a
                            href={p.RedirectionUrl}
                            target="_blank"
                            className="border-0 rounded-pill btn pfw-button-red secbutton py-2 px-3 my-4 w-100 font-face-gb-semibold"
                            style={{ lineHeight: 2 }}
                          >
                            {Moment() > Moment(p.drawEndDate) ? 'View Draw' : 'Enter Draw'}
                          </a>
                        ) : (
                          <Link
                            to={'/draw/' + p.slug}
                            onClick={() => SetDrawDetails(p)}
                            className="border-0 rounded-pill btn pfw-button-red secbutton py-2 px-3 my-4 w-100 font-face-gb-semibold"
                            style={{ lineHeight: 2 }}
                          >
                            {Moment() > Moment(p.drawEndDate) ? 'View Draw' : 'Enter Draw'}
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      ) : (
        <div className="text-center ">
          <img className="w-50" src={loading} style={{ opacity: '0.7' }} alt="Loading.." />
        </div>
      )}
    </>
  );
}

export default FindDrawTabs;

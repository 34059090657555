import React from 'react';
import { Link } from "react-router-dom";
import Siteheader from '../components/header';
import SiteFooter from '../components/footer';
import { Inner_Header_Images } from '../components/images';
import FooterCta from '../components/footer-cta';
import Accordion from 'react-bootstrap/Accordion';
import MonthlySlider from "../components/monthlySlider";
import { Helmet } from 'react-helmet';
import HostSliderSection from "../components/hostSlider-section";
import MarketingSupport from '../components/marketingSupport';
import ActiveSlider from '../components/activeSlider';
import CauseGrid from '../components/causeGrid';

export default function DrawCategory() {

    return (
        <>
            <Helmet>‍
                <title>Tag/Category | Play Fund Win</title>‍
                {/* <meta name="description" content="Play Fund Win’s digital fundraising platform is a great way for sports clubs to raise money, through prize draws or offering cash prizes to supporters" /> */}
            </Helmet>
            <Siteheader />
<div className='grassroots'>

            <div className="blog-header-section tag-category-sec container font-face-gb px-5 py-1 bg-branding1" style={{ marginBottom: '120px' }}>
                <div className="row align-items-center" style={{marginBottom: '-50px' }}>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12 align-middle header-left-ctn" style={{marginTop: '-50px' }}>
                        {/* <div className='publishdate color-yellow text-uppercase fw-bolder fs-5'>PlayFundWin</div> */}
                        <h1 className="post-title text-white font-face-gb-semibold display-1 fw-normal mt-2 mb-4">Tag/Category</h1>
                        <div className='short-desc text-light fs-5'>Our draws ensure a minimum of half of the net proceeds go to good causes, with the remainder won as a cash prize, plus our platform encourages subscriptions so sports clubs can create regular income.</div>
                        {/* <a className="border-0 rounded-pill bg-branding3 text-white btn pfw-button-white py-2 px-5 font-face-gb-semibold mt-4" style={{lineHeight : 2}} href="/host">Host a Draw</a> */}
                    </div>
                    <div className="col-md-6 align-middle p-md-5 p-2 text-center about-left-grass-cause position-relative">
                        <div className='post-image star-before-cause background-image w-100 position-relative' style={{backgroundImage: `url(${require(`../assets/images/people-stacking-hands-together-park.png`)})`, borderRadius: 10, height: 360, top: 60, marginTop: '-40px', zIndex : 9}}></div>
                    </div>
                </div>
            </div>

            <div className='main-content-area container rounded-5 p-5 my-5' style={{backgroundColor : '#eee'}}>
            <div className='tag-category-main d-flex'>
            <div className='tag-category-left'>
            <div className='filter-section row pb-5'>   
            <div className='sub-filter col-sm category-top-accordion'>
                        <p>All Category</p>
                        <Accordion defaultActiveKey="0">
                     <div className='row'>
                     <div className='category-accordion'>
                     <Accordion.Item eventKey="2">
                     <Accordion.Header className='title-text'>Sports</Accordion.Header>
                     <Accordion.Body>
                     <Link className='branding1 text-decoration-none red-hover w-100 d-block mb-3' to={"/user-edit-contact-details"}>Football</Link>
                     <Link className='branding1 text-decoration-none red-hover w-100 d-block mb-3' to={"/user-edit-contact-details"}>Soccer</Link>
                     <Link className='branding1 text-decoration-none red-hover w-100 d-block mb-3' to={"/user-edit-contact-details"}>Rugby</Link>
                     <Link className='branding1 text-decoration-none red-hover w-100 d-block mb-3' to={"/user-edit-contact-details"}>Cricket</Link>
                     <Link className='branding1 text-decoration-none red-hover w-100 d-block mb-3' to={"/user-edit-contact-details"}>Charity</Link>
                    </Accordion.Body>
                    </Accordion.Item>
                    </div>   
                    </div>
                    </Accordion>                    
                    </div>
             </div>
             <div className="dashboard-nav tag-category-accordion font-face-gb pb-5">
                    <Accordion defaultActiveKey="0">
                     <div className='row'>
                     <div className='category-accordion'>
                     <Accordion.Item eventKey="2">
                     <Accordion.Header className='font-face-gb-semibold'>Entertainment</Accordion.Header>
                     <Accordion.Body>
                     <Link className='branding1 text-decoration-none red-hover w-100 d-block mb-3' to={"/user-edit-contact-details"}>Our draws ensure a minimum of half of the net proceeds go to good causes</Link>
                    </Accordion.Body>
                    </Accordion.Item>
                    </div>   
                    </div>
                    </Accordion>
                    <Accordion defaultActiveKey="0">
                     <div className='row'>
                     <div className='category-accordion'>
                     <Accordion.Item eventKey="2">
                     <Accordion.Header className='font-face-gb-semibold'>Music</Accordion.Header>
                     <Accordion.Body>
                     <Link className='branding1 text-decoration-none red-hover w-100 d-block mb-3' to={"/user-edit-contact-details"}>Our draws ensure a minimum of half of the net proceeds go to good causes</Link>
                    </Accordion.Body>
                    </Accordion.Item>
                    </div>   
                    </div>
                    </Accordion>
                    
                    <Accordion defaultActiveKey="0">
                     <div className='row'>
                     <div className='category-accordion'>
                     <Accordion.Item eventKey="2">
                     <Accordion.Header className='font-face-gb-semibold'>Fashion</Accordion.Header>
                     <Accordion.Body>
                     <Link className='branding1 text-decoration-none red-hover w-100 d-block mb-3' to={"/user-edit-contact-details"}>Our draws ensure a minimum of half of the net proceeds go to good causes</Link>
                    </Accordion.Body>
                    </Accordion.Item>
                    </div>   
                    </div>
                    </Accordion>
                    <Accordion defaultActiveKey="0">
                     <div className='row'>
                     <div className='category-accordion'>
                     <Accordion.Item eventKey="2">
                     <Accordion.Header className='font-face-gb-semibold'>Art</Accordion.Header>
                     <Accordion.Body>
                     <Link className='branding1 text-decoration-none red-hover w-100 d-block mb-3' to={"/user-edit-contact-details"}>Our draws ensure a minimum of half of the net proceeds go to good causes</Link>
                    </Accordion.Body>
                    </Accordion.Item>
                    </div>   
                    </div>
                    </Accordion>
                    <Accordion defaultActiveKey="0">
                     <div className='row'>
                     <div className='category-accordion'>
                     <Accordion.Item eventKey="2">
                     <Accordion.Header className='font-face-gb-semibold'>Collectibles</Accordion.Header>
                     <Accordion.Body>
                     <Link className='branding1 text-decoration-none red-hover w-100 d-block mb-3' to={"/user-edit-contact-details"}>Our draws ensure a minimum of half of the net proceeds go to good causes</Link>
                    </Accordion.Body>
                    </Accordion.Item>
                    </div>   
                    </div>
                    </Accordion>
                    <Accordion defaultActiveKey="0">
                     <div className='row'>
                     <div className='category-accordion'>
                     <Accordion.Item eventKey="2">
                     <Accordion.Header className='font-face-gb-semibold'>Travel</Accordion.Header>
                     <Accordion.Body>
                     <Link className='branding1 text-decoration-none red-hover w-100 d-block mb-3' to={"/user-edit-contact-details"}>Our draws ensure a minimum of half of the net proceeds go to good causes</Link>
                    </Accordion.Body>
                    </Accordion.Item>
                    </div>   
                    </div>
                    </Accordion>
                    <Accordion defaultActiveKey="0">
                     <div className='row'>
                     <div className='category-accordion'>
                     <Accordion.Item eventKey="2">
                     <Accordion.Header className='font-face-gb-semibold'>Wellness and Charity</Accordion.Header>
                     <Accordion.Body>
                     <Link className='branding1 text-decoration-none red-hover w-100 d-block mb-3' to={"/user-edit-contact-details"}>Our draws ensure a minimum of half of the net proceeds go to good causes</Link>
                    </Accordion.Body>
                    </Accordion.Item>
                    </div>   
                    </div>
                    </Accordion>
                   </div>
            </div>


            <div className='tag-category-right'>
                <div className='filter-section row pb-5'>
                <div className='sub-filter col-sm category-top-accordion category-price-range'>
                        <p>Price Range</p>
                        <Accordion defaultActiveKey="0">
                     <div className='row'>
                     <div className='category-accordion'>
                     <Accordion.Item eventKey="2">
                     <Accordion.Header className='price-range'>£1 - £10,000+</Accordion.Header>
                     <Accordion.Body>   
                     <div className='price-range-border'>
                    
                        </div>

                     <div className='price-range-mid d-flex'>

                     <div className='price-range-left'>
                      <p>Min.price</p>
                      <p className='price-range-number'>1</p>
                      <p className='Price-reset-sec'>Reset</p>
                        </div>

                        <div className='price-range-right'>
                        <p className='max-price'>Max.price</p>
                        <p className='price-range-number'>10,000</p>
                        <p className='Price-apply-sec'>Apply</p>
                        </div>

                     </div>
                    </Accordion.Body>
                    </Accordion.Item>
                    </div>   
                    </div>
                    </Accordion>                    
                    </div>
                    <div className='sub-filter col-sm'>
                        <p>Filter by</p>
                        <select className='w-100 rounded-5 bg-white py-3 px-4 border-0 form-control form-select' style={{color : 'gray'}}>
                            <option>All</option>
                            <option>New</option>
                            <option>Popular</option>
                            <option>Closing</option>
                            <option>Coming Soon</option>
                        </select>
                    </div>
                    <div className='sub-filter col-sm'>
                        <p>Sort by</p>
                        <select className='w-100 rounded-5 bg-white py-3 px-4 border-0 form-control form-select' style={{color : 'gray'}}>
                            <option>All</option>
                            <option>Suggested</option>
                            <option>High to Low</option>
                            <option>Low to High</option>
                            <option>Closing Date</option>
                        </select>
                    </div>
                    
                </div>
                <div className='border-bottom'></div>

                <div className='listing-data pt-5'>
                     <div className='row'>
                            {/* <div className='col-sm'>
                                <div className='sub-data-values'>
                                    <img src={require('../assets/images/Whitstable-secondary.jpeg')} width="100%"></img>
                                </div>
                            </div>
                            <div className='col-sm'>
                                <div className='sub-data-values'>
                                    <img src={require('../assets/images/Whitstable-secondary.jpeg')} width="100%"></img>
                                </div>
                            </div>
                            <div className='col-sm'>
                                <div className='sub-data-values'>
                                    <img src={require('../assets/images/Whitstable-secondary.jpeg')} width="100%"></img>
                                </div>
                            </div> */}
                            <CauseGrid/>
                    </div> 
                </div>
               
              </div>
            </div>

            {/* <div className="container pt-5 pb-5 rounded-4 bg-image-np about-header position-relative" style={{ backgroundImage: `url(${Inner_Header_Images.InnerHeaderBg})` }}>
                <div className='container pt-5 pb-5'>
                    <div className="row align-items-center mb-5" >
                        <div className="col-xl-8 col-lg-8 col-md-8 col-12 align-middle text-center pt-lg-5 px-lg-5 m-auto">
                            <h1 className="text-white font-face-gb-semibold fw-normal display-3 mb-3">Grassroots Sports Clubs</h1>
                            <p className='text-white mb-5 px-lg-5 fs-5'>Grassroots sports clubs can benefit from hosting regular fundraising draws through our digital fundraising platform.</p>
                        </div>
                    </div>
                </div>
            </div> */}
              </div>

            </div>

            <FooterCta />
            <SiteFooter />
        </>
    )
}

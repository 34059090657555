import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from "react-router-dom";
import Siteheader from '../components/header';
import SiteFooter from '../components/footer';
import { Inner_Header_Images } from '../components/images';
import FooterCta from '../components/footer-cta';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SteveLightfootImg from '../assets/images/Steve-Lightfoot.webp';
import { getPostByAuthor } from '../Data/actionMethods';
import loading from '../assets/images/loading.gif';
import ScrollToTop from '../scrollToTop';

export default function SteveLightfoot(props) {

  const [blogData, setBlogData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const taxanomySlug = location.pathname.split('/')[2];
  const postUserID = '31fde39f-a931-419c-95b0-acf69c8d6e84';

  const decodeHTMLEntities = (input) => {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent.replace(/&#9;/g, ' ').replace(/\s+/g, ' ').trim();
  };

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  }

  useEffect(() => {
    getPostByAuthor(postUserID).then((res) => {
      setBlogData(res.data.content ? res.data.content : []);
    });
  }, [postUserID]);

  return (
    <>
      <Helmet>‍
        <title>Steve Lightfoot | Play Fund Win</title>‍
        <meta name="description" content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities." />
      </Helmet>
      <Siteheader />
      <div className="container pt-5 pb-5 rounded-4 bg-image-np about-header position-relative" style={{ backgroundImage: `url(${Inner_Header_Images.InnerHeaderBg})` }}>
        <div className='container pt-5 pb-5'>
          <div className="row align-items-center" >
            <div className="col-xl-6 col-lg-6 col-md-6 col-12 align-middle text-center pt-lg-5 px-lg-5 m-auto">
              <h1 className="text-white font-face-gb-semibold fw-normal display-3 mb-3">Steve Lightfoot</h1>
              <p className='text-white m-0'>CRO | Co-Founder</p>
            </div>
            <div className="team-breads text-light text-left mx-auto my-5" style={{ width: '80%' }}><Link className='branding3 font-face-gb-semibold green-hover' to={'/about-us'} rel="nofollow">About Us</Link>&nbsp;&nbsp;»&nbsp;&nbsp;Steve Lightfoot</div>
          </div>
        </div>
      </div>
      <div className='about-content-area container pb-5 my-4 px-0 rounded-4 position-relative' style={{ backgroundColor: '#f2f2f2' }}>
        <div className="row mx-auto" style={{ width: '80%' }}>
          <div className="col-md-6 align-middle text-center about-left-img position-relative">
            <LazyLoadImage width="100%" style={{ borderRadius: '1rem', marginTop: '-128px' }} src={SteveLightfootImg} alt='Steve Lightfoot' />
            {/* <img width="100%" style={{borderRadius: '1rem', marginTop: '-128px'}} src={require('../assets/images/Steve-Lightfoot.webp')} /> */}
          </div>

          <div className="col-md-6 align-middle text-left ps-lg-5 pt-5 mt-lg-0 mt-3 about-right-ctn position-relative">
            <h3 className='font-face-gb-semibold text-black fs-5 mb-4'>Steve Lightfoot</h3>
            <p><strong>CRO | Co-Founder</strong></p>
            <p><strong>Favourite Sport:</strong><br />
              Huge lover of sports in general but, if I had to choose, it would be football.</p>
            <p><strong>Favourite Sports Team:</strong><br />
              Liverpool FC.</p>
            <p><strong>Favourite Event:</strong><br />
              I got to run the London Marathon a few years ago and the whole occasion was one of the most uplifting of my life, especially the beer afterwards, I would say it was definitely deserved!</p>
            <p><strong>Favourite Artist:</strong><br />
              Like most, it changes quite often but at the moment I’m currently enjoying George Ezra.</p>
            <p><strong>Favourite Charity:</strong><br />
              Quite a few that I support but one particularly close to my heart is Bliss.</p>
            <p><strong>Favourite Tech Development:</strong><br />
              I recently watched football via Oculus Quest which is an absolute game changer!</p>
          </div>
        </div>
      </div>

      {blogData.length == 0 ? "" :
        <div className='blog-overview-section container py-5 bg-f2 my-4' style={{ borderRadius: 10 }}>
          {blogData.length > 0 ?
            <div className='row mx-auto pt-4' style={{ width: '82%' }}>
              <h2 className="hiw-title branding1 text-center font-face-gb-semibold display-4 mb-5">Thoughts and Insights</h2>

              {
                blogData.map((p, index) => {
                  let postMeta;
                  try {
                    postMeta = JSON.parse(p.postMeta);
                  } catch (error) {
                    postMeta = "";
                  }
                  return (
                    <div className={`col-lg-4 col-md-6 col-sm-12 font-face-gb mb-5 d-flex flex-column ${p.postID}`} key={`${p.postID}-${index}`}>
                      <div className='bg-branding1 h-100' style={{ borderRadius: 10, overflow: 'hidden' }}>
                        <Link
                          to={{
                            pathname: `/blog/${p.postSlug}`
                          }}
                          onClick={() => {
                            navigate(`/blog/${p.postSlug}`);
                          }}
                        >
                          {/* Use LazyLoadImage for the background-image */}
                          <LazyLoadImage
                            className='post-image background-image w-100 position-relative'
                            alt=''
                            effect='blur' // Add a loading effect if desired
                            src={postMeta.featuredImage}
                            style={{
                              borderTopLeftRadius: 10,
                              borderTopRightRadius: 10
                            }}
                          />
                        </Link>
                        <div className='text-end position-absolute d-flex' style={{ right: '28px', marginTop: '-13px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                          <Link
                            to={{
                              pathname: `/category/${p.taxanomySlug}`
                            }}
                            onClick={() => {
                              navigate(`/category/${p.taxanomySlug}`);
                            }}
                            className='text-decoration-none text-light pfw-button-green rounded-pill px-3'
                            style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}
                          >
                            {p.taxanomyName}
                          </Link>
                        </div>
                        <div className='post-short-content bg-branding1' style={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10, padding: 35 }}>

                          <div className='publishdate text-light'>{moment(p.postPublishedDate).format('Do MMMM YYYY')}</div>

                          <Link
                            className='text-decoration-none'
                            to={{
                              pathname: `/blog/${p.postSlug}`
                            }}
                            onClick={() => {
                              navigate(`/blog/${p.postSlug}`);
                            }}
                          >
                            <h3 className='post-title color-yellow fs-5 font-face-gb-semibold fw-normal my-3' style={{ minHeight: 50 }}>
                              {truncateText(decodeHTMLEntities(p.postName), 50)}
                            </h3>
                          </Link>
                          <div className='post-excerpt text-white'>{truncateText(decodeHTMLEntities(postMeta.introductionText), 70)}</div>

                          <Link
                            to={{
                              pathname: `/blog/${p.postSlug}`
                            }}
                            onClick={() => {
                              navigate(`/blog/${p.postSlug}`);
                            }}
                            className='border-0 rounded-pill btn pfw-button-red secbutton py-2 px-4 mt-3 mb-2 font-face-gb-semibold'
                            style={{ lineHeight: 2 }}
                          >
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>
            :
            <div className='text-center '>
              <img className='w-50' src={loading} style={{ opacity: '0.7' }} alt="Loading..." />
            </div>
          }
        </div>
      }

      <FooterCta />
      <SiteFooter />
      <ScrollToTop onload={blogData.length} />
    </>
  )
}

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Siteheader from '../components/header';
import SiteFooter from '../components/footer';
import FooterCta from '../components/footer-cta';
import Innerheader from '../components/inner-header';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { toast, Toast, ToastContainer } from 'react-toastify';
import moment from 'moment';
import { contact } from '../utils/meta/metaPixelEvent';

export default function Help_centre() {
  React.useEffect(() => {
    (function loadHubSpot() {
      const d = document;
      const s = d.createElement('script');

      s.src = '//js-na1.hs-scripts.com/8551597.js';
      s.async = true;
      d.getElementsByTagName('body')[0].appendChild(s);
    })();
  }, []);

  const [email, SetEmail] = useState('');
  const [fname, SetFname] = useState('');
  const [lname, SetLname] = useState('');
  const [mobile, SetMobile] = useState('');
  const [message, SetMessage] = useState('');
  const [termsconsent, SetTermsConsent] = useState(false);

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  function loadHubSpot() {
    // Load the HubSpot script asynchronously
    const s = document.createElement('script');
    s.src = '//js.hs-scripts.com/8551597.js';
    s.async = true;
    document.getElementsByTagName('body')[0].appendChild(s);

    // Add a listener to open the HubSpot chat widget after the script loads
    s.onload = function () {
      if (window.HubSpotConversations) {
        window.HubSpotConversations.widget.open();
      }
    };
  }

  //const navigate =useNavigate();
  let APIURL = process.env.REACT_APP_API_URL;
  let siteID = process.env.REACT_APP_API_SITE_ID;

  async function contactFormHandler() {
    if (email.trim().length === 0) {
      toast.error(`Please Enter Your Email`);
    } else if (!/^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9-]+(?:\.[a-z0-9-]+)+(?:\.[a-z0-9-]+)*$/.test(email)) {
      toast.error(`Please Enter Your valid Email Address`);
    } else if (fname.trim().length === 0) {
      toast.error(`Please Enter First Name`);
    } else if (lname.trim().length === 0) {
      toast.error(`Please Enter Last Name`);
    } else if (mobile.trim().length === 0) {
      toast.error(`Please Enter Phone Number`);
    } else if (message.trim().length === 0) {
      toast.error(`Please Enter Message`);
    } else if (!termsconsent) {
      console.log(termsconsent);
      toast.error(`Please Accept Terms and Conditions`);
    } else {
      try {
        console.log(termsconsent);
        const response = await toast.promise(
          axios.post(
            `${APIURL}/form/contact`,
            {
              siteID: siteID,
              userFirstName: fname,
              userLastName: lname,
              userEmail: email,
              userPhone: mobile,
              entryDate: moment().format('YYYY-MM-DD hh:mm:ss'),
              userMessage: message,
              userTermsConsent: termsconsent,
              userMeta: {
                website: 'PlayFundWin'
              }
            },
            {
              withCredentials: true,
              credentials: 'same-origin'
            }
          ),
          {
            pending: 'Please wait...'
          }
        );
        console.log(response);

        if (response.data.status === true && response.data.error === null) {
          toast('Thank you for your message, it has been sent, we will contact you shortly', { type: 'success' });
          contact();
          await timeout(1000);

          window.location.replace('/thankyou');
        } else {
          toast('Something went wrong While authenticating', { type: 'error' });
        }
      } catch (error) {
        console.log(error);
        if (error.response.status === 403) {
          toast.error(`${error.response.data.content.message}`);
        } else {
          toast.error(`${error.message}`);
        }
      }
    }
  }

  return (
    <>
      <Helmet>
        ‍<title>Help Center | Play Fund Win</title>‍
        <meta
          name="description"
          content="We are. Play Fund Win. #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities."
        />
      </Helmet>
      <Siteheader />

      <div className="container px-0 light-bg ">
        <div className="container pt-5 pb-5 my-3 rounded-4 w-92" style={{ backgroundColor: '#f2f2f2' }}>
          <div className="row align-items-center">
            <div className="col-md-12 align-middle text-center">
              <h2 className="branding1 font-face-gb-semibold display-4 mb-4">Contact us</h2>
              <p>Got a question? We’d love to help, get in touch with us by:</p>
            </div>
          </div>
          <div
            className="contact-detail-sec row px-lg-0 px-3 mx-auto pt-4 justify-content-center"
            style={{ maxWidth: '73%' }}
          >
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 align-middle text-center bg-white mb-4 p-5 rounded-4 w-100">
              <img src={require('../assets/images/Message-Icon.png')}></img>
              <p>Email us</p>
              <a className="text-decoration-none" href="mailto:hello@playfundwin.com">
                <strong className="branding1">hello@playfundwin.com</strong>
              </a>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 align-middle text-center bg-white mb-4 p-5 rounded-4 w-100">
              <img src={require('../assets/images/location.png')}></img>
              <p>Address</p>
              <a
                className="text-decoration-none"
                href="https://www.google.co.in/maps/search/Unit+D228,+Parkhill+Business+Centre,+62+Tritton+Road,+London+SE21+8DE/@51.4328719,-0.0967672,17z"
              >
                <strong className="branding1">
                  PFW Holdings Ltd Suite#300, 4 Blenheim Court, Peppercorn Close, Peterborough, PE1 2DU
                </strong>
              </a>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 align-middle text-center bg-white mb-4 p-5 rounded-4 w-100">
              <img src={require('../assets/images/Live-Chat-Icon.png')}></img>
              <p>Start a Chat</p>
              <a className="text-decoration-none" onClick={loadHubSpot}>
                <strong className="branding1">Chat to one of team now</strong>
              </a>
            </div>
          </div>
          <form>
            <div
              className="contact-form-sec row align-middle text-center mx-auto pt-5 pb-4"
              style={{ maxWidth: '80%' }}
            >
              <h2 className="branding1 font-face-gb-semibold display-4">Send us a message</h2>
              <div className="col-md-6  align-middle text-center">
                <input
                  type="text"
                  name="firstname"
                  value={fname}
                  onChange={(e) => SetFname(e.target.value)}
                  className="form-control rounded-pill my-md-5 my-3 px-4 py-3"
                  placeholder="First Name"
                />
                <input
                  type="text"
                  name="email"
                  value={email}
                  onChange={(e) => SetEmail(e.target.value)}
                  className="form-control rounded-pill my-md-5 my-3 px-4 py-3"
                  placeholder="Email Address"
                />
              </div>
              <div className="col-md-6  align-middle text-center">
                <input
                  type="text"
                  name="lastname"
                  value={lname}
                  onChange={(e) => SetLname(e.target.value)}
                  className="form-control rounded-pill mt-md-5 mt-0 px-4 py-3"
                  placeholder="Last Name"
                />
                <input
                  type="text"
                  name="mobile"
                  value={mobile}
                  onChange={(e) => SetMobile(e.target.value)}
                  className="form-control rounded-pill my-md-5 my-3 px-4 py-3"
                  placeholder="Phone Number"
                />
              </div>
              <div className="col-md-12">
                <textarea
                  type="text"
                  name="message"
                  value={message}
                  onChange={(e) => SetMessage(e.target.value)}
                  className="form-control rounded-4 w-100 px-4 py-3"
                  style={{ borderRadius: '1rem', width: '100%', padding: '1rem' }}
                  placeholder="Message"
                  rows="7"
                />
                <div className="form-check text-left mt-3 mb-md-3 mb-5">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="termsconsent"
                    checked={termsconsent}
                    onChange={() => SetTermsConsent(!termsconsent)}
                    value="10"
                    id="defaultCheck1"
                  />
                  <label className="form-check-label" style={{ float: 'left' }}>
                    I have read and accept the terms and conditions
                  </label>
                </div>
                <div className="text-center align-middle">
                  {/* <a href='/#' className="border-0 rounded-pill btn pfw-button-red pributton py-2 px-4 my-2 mx-2">Send</a> */}
                  <button
                    className="btn pfw-button-red rounded-pill align-self-center px-5 p-2 fs-5 fw-bold"
                    type="button"
                    name="button"
                    onClick={contactFormHandler}
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="https://js-na1.hs-scripts.com/8551597.js"
      ></script>

      <FooterCta />
      <ToastContainer />
      <SiteFooter />
    </>
  );
}
